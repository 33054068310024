import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InitializationService } from '../services/utility/initialization/initialization.service';

@Injectable({
	providedIn: 'root',
})
export class NoAuthGuard {
	constructor(
		private initializationService: InitializationService,
		private router: Router,
	) {}

	canActivate(
		_route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		if (this.initializationService.loggedIn) {
			return this.router.createUrlTree(['/empty']);
		}
		return true;
	}
}
