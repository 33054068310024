import { Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	recordTransformerFactory,
} from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class MissingDocumentTranscriptModel {
	@Transform(dateTransformer)
	uploaded_date: Date;
	file_url: string;
	waived?: boolean;
	field?: string;
}

export function MissingDocumentTranscriptModel_ClassTransform_Type() {
	return MissingDocumentTranscriptModel;
}

export class MissingDocumentTrackerClassModel {
	uid: number;
	first_name: string;
	last_name: string;
	state_id: string;
	program: string;
	parent_program: string;
	@Transform(dateTransformer)
	enrolled: Date;
	@Type(MissingDocumentTranscriptModel_ClassTransform_Type)
	transcript: MissingDocumentTranscriptModel;
	completed: string;
	@Transform(
		recordTransformerFactory(
			MissingDocumentTranscriptModel_ClassTransform_Type,
		),
	)
	requirements: Record<string, MissingDocumentTranscriptModel>;
}

export function MissingDocumentTrackerClassModel_ClassTransform_Type() {
	return MissingDocumentTrackerClassModel;
}

export class MissingDocumentTrackerResponseModel {
	count: number;
	@Type(MissingDocumentTrackerClassModel_ClassTransform_Type)
	results: MissingDocumentTrackerClassModel[];
}

export interface MissingDocumentsTrackerSearchArgument {
	uid: PagingSearchFieldArgument;
	first_name: PagingSearchFieldArgument;
	last_name: PagingSearchFieldArgument;
	state_id: PagingSearchFieldArgument;
	program: PagingSearchFieldArgument;
	parent_program: PagingSearchFieldArgument;
	enrolled: PagingSearchFieldArgument;
	transcript_uploaded: PagingSearchFieldArgument;
}
