import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	CommunicationAnnouncementListModel_ClassTransform_Type,
	CommunicationAnnouncementModel,
} from './communication-announcement.model';

export class CommunicationAnnouncementSearchResponseModel extends AbstractPagingResponseModel<CommunicationAnnouncementModel> {
	@Type(CommunicationAnnouncementListModel_ClassTransform_Type)
	results: CommunicationAnnouncementModel[];
}
