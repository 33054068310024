<div class="modal-header">
	<h1 class="modal-title">
		{{ title ?? 'Alert' }}
	</h1>
	@if (mover) {
		<ae-modal-mover />
	}
	<button
		class="btn btn-outline-dark"
		title="Close"
		type="button"
		(click)="close()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	@if (template == null) {
		<div class="text-center">
			{{ message }}
		</div>
	} @else {
		<ng-container [ngTemplateOutlet]="template" />
	}
</div>
