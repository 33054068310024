import { Type } from 'class-transformer';

export class NotificationConditionOptionModel {
	id: string | number;
	title: string;
}

export function NotificationConditionOptionModel_ClassTransform_Type() {
	return NotificationConditionOptionModel;
}

export class NotificationConditionOptionsModel {
	title: string;
	description: string;
	id: string;
	@Type(NotificationConditionOptionModel_ClassTransform_Type)
	options: NotificationConditionOptionModel[];
	type: string;
}

export function NotificationConditionOptionsModel_ClassTransform_Type() {
	return NotificationConditionOptionsModel;
}

export class NotificationTriggerOptionModel {
	name: string;
	description: string;
	id: string;
	@Type(NotificationConditionOptionsModel_ClassTransform_Type)
	conditions: NotificationConditionOptionsModel[];
	category: string;
	module: string;
}

export function NotificationTriggerOptionModel_ClassTransform_Type() {
	return NotificationTriggerOptionModel;
}

export class NotificationComparisonOptionModel {
	key: number;
	name: string;
}

export function NotificationComparisonOptionModel_ClassTransform_Type() {
	return NotificationComparisonOptionModel;
}

export class NotificationSetOptionsModel {
	@Type(NotificationTriggerOptionModel_ClassTransform_Type)
	triggers: NotificationTriggerOptionModel[];
	@Type(NotificationComparisonOptionModel_ClassTransform_Type)
	comparisons: NotificationComparisonOptionModel[];
}
