import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeTrackerEntryResponseModel } from 'src/lib/services/api/students/time-tracker/time-tracker.model';
import { TimeTrackerService } from 'src/lib/services/api/students/time-tracker/time-tracker.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentTimeTrackerStoreService {
	private _timeTrackerEntriesCache = new BehaviorCache<
		// endDate formatted {year}-{iso-month}-{day-pad}
		// duration number of days back from endDate
		[uid: number, endDate: string, duration: number],
		TimeTrackerEntryResponseModel
	>(
		([uid, endDate, duration]) => {
			return this.timeTrackerService.getTimeTrackerEntries(
				uid,
				endDate,
				duration,
			);
		},
		'StudentTimeTrackerStoreService TimeTrackerEntriesCache',
		() => null,
		15 * 60,
	);

	constructor(private timeTrackerService: TimeTrackerService) {}

	public entries$ = (
		uid: number,
		endDate: string,
		duration: number,
	): Observable<TimeTrackerEntryResponseModel> => {
		return this._timeTrackerEntriesCache.getCache([uid, endDate, duration]);
	};

	public refreshEntries = (
		uid: number,
		endDate: string,
		duration: number,
	): Promise<boolean> => {
		return this._timeTrackerEntriesCache.fetchData(
			[uid, endDate, duration],
			true,
		);
	};
}
