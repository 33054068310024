import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class AbDrillDownGradChatMessageModel {
	body: string;
	sender_uid: number;
	sender_name: string;
	@Expose({ name: 'message_timestamp' })
	@Transform(dateTransformer)
	message_date: Date;
}

export function AbDrillDownGradChatMessageModel_ClassTransformType() {
	return AbDrillDownGradChatMessageModel;
}

export class AbDrillDownGradChatModel {
	student_uid: number;
	student_name: string;
	@Type(AbDrillDownGradChatMessageModel_ClassTransformType)
	last_message: AbDrillDownGradChatMessageModel;
	sent_count: number;
	received_count: number;
}
