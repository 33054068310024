import { Type } from 'class-transformer';

export class ActivityBoardAdminProgramModel {
	id: number;
	title: string;
}

export function ActivityBoardAdminProgramModel_ClassTransformType() {
	return ActivityBoardAdminProgramModel;
}

export class ActivityBoardAdminItemModel {
	admin_uid: number;
	admin_name: string;
	@Type(ActivityBoardAdminProgramModel_ClassTransformType)
	programs: ActivityBoardAdminProgramModel[];
	include_count: number;
}

export function ActivityBoardAdminItemModel_ClassTransformType() {
	return ActivityBoardAdminItemModel;
}
