import { Expose, Transform, Type } from 'class-transformer';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from 'src/lib/services/utility/utility-models/address.model';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class MeetupHostModel {
	uid: number;
	name: string;
}

export function MeetupHostModel_ClassTransform_Type() {
	return MeetupHostModel;
}

export class AttendeeAssignmentsModel {
	total: number;
	completed: number;
}

export function AttendeeAssignmentsModel_ClassTransform_Type() {
	return AttendeeAssignmentsModel;
}

export class MeetupGroupModel {
	id: string;
	title: string;
}

export function MeetupGroupModel_ClassTransform_Type() {
	return MeetupGroupModel;
}

export class FutureMeetupGroupModel {
	id: number;
	start_date: Date;
	end_date: Date;
}

export function FutureMeetupGroupModel_ClassTransform_Type() {
	return FutureMeetupGroupModel;
}

export class AttendeeOutstandingFinalsModel {
	@Type(AttendeeAssignmentsModel_ClassTransform_Type)
	assignments: AttendeeAssignmentsModel;
	registration_id: number;
	title: string;
	@Transform(dateTransformer)
	target_end_date: Date;
	current_grade_percent: number;
	proctor_password: string;
}

export function AttendeeOutstandingFinalsModel_ClassTransform_Type() {
	return AttendeeOutstandingFinalsModel;
}

export class MeetupRecurringModel {
	future_meetup_start_id: number;
	scheduled_meetup_id: number;
	uid: number;
}

export function MeetupRecurringModel_ClassTransform_Type() {
	return MeetupRecurringModel;
}

export class MeetupAttendeeModel {
	uid: number;
	status: MeetupAttendeeStatus;
	name: string;
	email: string;
	@Expose({ name: 'arrival_timestamp' })
	@Transform(dateTransformer)
	arrival_time: Date;
	@Expose({ name: 'departure_timestamp' })
	@Transform(dateTransformer)
	departure_time: Date;
	context: string;
	purpose: number;
	study_plan: string;
	study_plan_accomplished: boolean;
	study_plan_not_accomplished_reason: string;
	barriers: string;
	resources: string;
	additional_notes: string;
	@Expose({ name: 'planned_arrival_timestamp' })
	@Transform(dateTransformer)
	planned_arrival_time: Date;
	@Expose({ name: 'planned_departure_timestamp' })
	@Transform(dateTransformer)
	planned_departure_time: Date;
	meetup_gate: boolean;
	meetup_minutes: number;
	@Type(AttendeeOutstandingFinalsModel_ClassTransform_Type)
	courses_outstanding_finals: AttendeeOutstandingFinalsModel[];
	student_plan: string;
	institute_title: string;
	institute_id: number;
}

export function MeetupAttendeeModel_ClassTransform_Type() {
	return MeetupAttendeeModel;
}

export class MeetupAttendanceModel {
	@Type(MeetupAttendeeModel_ClassTransform_Type)
	planned_attendee: MeetupAttendeeModel;
	@Type(MeetupRecurringModel_ClassTransform_Type)
	recurring_plan: MeetupRecurringModel;
}

export class MeetupManagementItemModel {
	id: number;
	scheduled_meetup_id: number;
	active: boolean;
	@Expose({ name: 'start_timestamp' })
	@Transform(dateTransformer)
	start_date: Date;
	@Expose({ name: 'end_timestamp' })
	@Transform(dateTransformer)
	end_date: Date;
	@Type(MeetupHostModel_ClassTransform_Type)
	host: MeetupHostModel;
	frequency: string;
	link: string;
	@Type(UserAddressModel_ClassTransform_Type)
	address: UserAddressModel;
	type: 'virtual' | 'physical';
	gathering_type: string;
	@Type(MeetupGroupModel_ClassTransform_Type)
	groups: MeetupGroupModel[];
	finalized: boolean;
	description: string;
	@Expose({ name: 'planned_arrival_timestamp' })
	@Transform(dateTransformer)
	planned_arrival_date?: Date;
	@Expose({ name: 'planned_departure_timestamp' })
	@Transform(dateTransformer)
	planned_departure_date?: Date;
	rsvp?: boolean;
	attendee_status: MeetupAttendeeStatus;
	@Expose({ name: 'actual_arrival_timestamp' })
	@Transform(dateTransformer)
	actual_arrival_date: Date;
	@Expose({ name: 'actual_departure_timestamp' })
	@Transform(dateTransformer)
	actual_departure_date: Date;
	@Type(FutureMeetupGroupModel_ClassTransform_Type)
	future_meetups: FutureMeetupGroupModel[];
}

export class MeetupManagementListItemModel extends MeetupManagementItemModel {
	attendees_count: number;
	log_count: number;
}

export class MeetupManagementViewItemModel extends MeetupManagementItemModel {
	@Type(MeetupAttendeeModel_ClassTransform_Type)
	attendees: MeetupAttendeeModel[];
}

export class MeetupPlannedAttendeeModel {
	uid: number;
	status: number;
	name: string;
	@Expose({ name: 'planned_arrival_timestamp' })
	@Transform(dateTransformer)
	planned_arrival_date: Date;
	@Expose({ name: 'planned_departure_timestamp' })
	@Transform(dateTransformer)
	planned_departure_date: Date;
	student_plan: string;
}

export enum MeetupGatheringTypes {
	community_event = 'community_event',
	meetup = 'meetup',
	study_session = 'study_session',
	tutoring = 'tutoring',
}

export enum MeetupAttendeeStatus {
	removed_from_attendance = -2,
	not_planning_to_attend = -1,
	did_not_attend = 0,
	planning_to_attend = 1,
	attended = 2,
}

export enum CancelTypes {
	'currentOnly' = 1,
	'currentAndFuture' = 2,
}
