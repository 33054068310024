import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
	forwardRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import {
	Editor,
	NgxEditorModule,
	Toolbar,
	ToolbarItem,
	nodes as basicNodes,
	marks,
	toDoc,
	toHTML,
} from 'ngx-editor';
import { DOMOutputSpec, Mark, MarkSpec, Schema } from 'prosemirror-model';
import { AsyncSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isString } from 'src/lib/utilities/compare';
import { noop } from 'src/lib/utilities/noop';
import { NgxFontSize } from './ngx-editor-size-menu/FontSizeMark';
import { NgxEditorSizeMenuComponent } from './ngx-editor-size-menu/ngx-editor-size-menu.component';

const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];
const sizes: NgxFontSize[] = ['small', 'large', 'huge'];

@Component({
	selector: 'ae-ngx-editor',
	templateUrl: './ngx-editor.component.html',
	styleUrls: ['./ngx-editor.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NgxEditorComponent),
			multi: true,
		},
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgxEditorModule,
		FormsModule,
		ReactiveFormsModule,
		NgxEditorSizeMenuComponent,
	],
})
export class NgxEditorComponent
	implements
		ControlValueAccessor,
		OnDestroy,
		OnInit,
		OnChanges,
		AfterViewChecked
{
	private _unsubscribe$ = new AsyncSubject<null>();

	editor: Editor;
	toolbar: Toolbar = [
		['text_color', 'background_color'],
		['bold', 'italic', 'underline'],
		['ordered_list', 'bullet_list'],
		['link', 'image'],
		['format_clear'],
	];

	@ViewChild('editor', { static: true }) slate: any;

	@Input() editorID: string;
	@Input() height: string = '17.5em';
	@Input() resizable: boolean = false;
	@Input() asciiChars: boolean = true;
	@Input() disableImages: boolean = false;
	@Input() format: 'object' | 'html' | 'text' | 'json' = 'html';

	@Input() includeControls: ToolbarItem[];
	@Input() excludeControls: ToolbarItem[];

	contentControl: FormControl<any>;

	private _touchedFunction: () => void;
	private _changeFunction: (value: string) => void = noop;
	private _changeWatcher: Subscription;
	private _blockTouch = true;

	private schema: Schema;

	constructor(
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
	) {}

	update = () => {
		this.cdr.detectChanges();
	};

	ngOnInit(): void {
		this.contentControl = this.fb.control<any>(null);

		const textColor: MarkSpec = {
			attrs: {
				class: {
					default: null,
				},
				color: {
					default: null,
				},
			},
			parseDOM: this.parseColorRuleBuilder('color'),
			toDOM(mark: any): DOMOutputSpec {
				const color = mark.attrs.class ?? `ql-color-${mark.attrs.color}`;
				return ['span', { class: `${color}` }, 0];
			},
		};

		const textBackgroundColor: MarkSpec = {
			attrs: {
				class: {
					default: null,
				},
				backgroundColor: {
					default: null,
				},
			},
			parseDOM: this.parseColorRuleBuilder('bg'),
			toDOM(mark: Mark): DOMOutputSpec {
				const color = mark.attrs.class ?? `ql-bg-${mark.attrs.backgroundColor}`;
				return ['span', { class: `${color}` }, 0];
			},
		};

		const fontSizeMark: MarkSpec = {
			attrs: {
				class: {
					default: null,
				},
				fontSize: {
					default: null,
				},
			},
			parseDOM: this.parseSizeRuleBuilder(),
			toDOM(mark: Mark): DOMOutputSpec {
				const size = mark.attrs.class ?? `ql-size-${mark.attrs.fontSize}`;
				return ['span', { class: `${size}` }, 0];
			},
		};

		marks.text_color = textColor;
		marks.text_background_color = textBackgroundColor;
		marks['font_size'] = fontSizeMark;

		const nodes = Object.assign({}, basicNodes, {});

		this.schema = new Schema({
			nodes,
			marks,
		});

		this.editor = new Editor({
			schema: this.schema,
		});
		this.watchForChange();

		this.editor.update.subscribe(() => {
			if (this._touchedFunction && !this._blockTouch) {
				this._touchedFunction();
			}
		});
	}

	ngAfterViewChecked(): void {
		this._blockTouch = false;
	}

	ngOnChanges(_changes: SimpleChanges) {
		if (this.includeControls) {
			this.toolbar = [this.includeControls];
		}
		if (this.excludeControls) {
			this.excludeControls.forEach((c) => {
				this.toolbar.forEach((tbItem, index) => {
					this.toolbar[index] = tbItem.filter((t) => {
						return t !== c;
					});
				});
			});
		}
	}

	insertAtSelection(text: string): void {
		this.editor.commands.insertText(text).exec();
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.contentControl.valueChanges
			.pipe(distinctUntilChanged(), takeUntil(this._unsubscribe$))
			.subscribe((x) => {
				if (!isString(x)) {
					x = toHTML(x, this.schema);
				} else if (this.format === 'json') {
					x = toDoc(x, this.schema);
				}
				this._changeFunction(x);
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	ngOnDestroy() {
		this.editor.destroy();

		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	writeValue(obj: any): void {
		try {
			this.stopWatchForChange();
			if (obj != null) {
				if (this.format === 'json') {
					obj = JSON.parse(obj);
				} else if (!isString(obj)) {
					obj = toHTML(obj, this.schema);
				}
			}

			this._blockTouch = true;
			this.contentControl?.patchValue(obj);
		} finally {
			this.watchForChange();
		}
	}

	registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		if (isDisabled) {
			this.contentControl.disable();
			this.editor.view.editable = false;
		} else {
			this.contentControl.enable();
			this.editor.view.editable = true;
		}

		this.watchForChange();
	}

	public jsonConvert = () => {
		if (isString(this.contentControl.value)) {
			return toDoc(this.contentControl.value, this.schema);
		}
		return this.contentControl.value;
	};

	public htmlConvert = (val) => {
		return toHTML(val, this.schema);
	};

	public htmlValue = () => {
		return this.contentControl?.value
			? toHTML(this.contentControl.value, this.schema)
			: null;
	};

	private parseColorRuleBuilder = (type: string) => {
		return colors.map((color) => {
			return {
				tag: `span[class="ql-${type}-${color}"]`,
				getAttrs: (value: HTMLElement): Record<string, any> => {
					return { class: `${value.className}` };
				},
			};
		});
	};

	private parseSizeRuleBuilder = () => {
		return sizes.map((size) => {
			return {
				tag: `span[class="ql-size-${size}"]`,
				getAttrs: (value: HTMLElement): Record<string, any> => {
					return { class: `${value.className}`, fontSize: size };
				},
			};
		});
	};
}
