import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GabbySettingsModel } from './gabby-settings.model';

@Injectable({
	providedIn: 'root',
})
export class GabbySettingsService {
	constructor(private httpClient: HttpClient) {}

	public getSettings = (): Observable<GabbySettingsModel> => {
		return this.httpClient
			.get<GabbySettingsModel>(`/api/v1/gradchat/settings`)
			.pipe(map((r) => plainToInstance(GabbySettingsModel, r)));
	};

	public saveSettings = (args: GabbySettingsModel): Observable<any> => {
		return this.httpClient
			.post<GabbySettingsModel>(`/api/v1/gradchat/settings`, args)
			.pipe(map((r) => plainToInstance(GabbySettingsModel, r)));
	};
}
