import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { ActivityBoardAdminItemModel } from '../activity-board-admin.model';
import { ActivityBoardAvailableItemModel } from '../activity-board-available.model';
import { ActivityBoardChangeArgument } from '../activity-board-change.model';
import { ActivityBoardAcResponseModel } from './activity-board-ac.model';

@Injectable({
	providedIn: 'root',
})
export class ActivityBoardsAcService {
	constructor(private httpClient: HttpClient) {}

	public getActivityBoard = (
		currentUserUid: number,
	): Observable<ActivityBoardAcResponseModel> => {
		return this.httpClient
			.get<ActivityBoardAcResponseModel>(
				`/api/v1/activity-boards/academic_coach/${currentUserUid}`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(ActivityBoardAcResponseModel, r), {
					errorCode: '0CA328EC',
					validateSuccess: false,
					checkPreMap: false,
				}),
			);
	};

	public getAvailableAcademicCoaches = (
		uid: number,
	): Observable<ActivityBoardAvailableItemModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/academic_coach/${uid}/manage/available`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAvailableItemModel,
							r.academic_coaches as any[],
						),
					{ errorCode: '9DB7F200' },
				),
			);
	};

	public changeAcademicCoaches = (
		uid: number,
		args: ActivityBoardChangeArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/activity-boards/academic_coach/${uid}/manage`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'ACA35C7A',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getAcAdminList = (): Observable<ActivityBoardAdminItemModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/academic_coach/all`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAdminItemModel,
							r.activity_boards as any[],
						),
					{ errorCode: '9DB7F200' },
				),
			);
	};
}
