// web/modules/custom/ga_student/src/Model/Requirement/ProgramEventType.php
export enum PerformanceRequirementProofType {
	EventLogTwoWay = 3,

	Milestone = 11,

	CourseCompleted = 12,
	CourseCounted = 13,

	MeetupAttendance = 14,
	MeetupMinutes = 15,

	WrittenStudentLearningPlan = 20,

	Activation = 9999,
}

export interface PerformanceRequirementProofInterface {
	id: number;
	type: PerformanceRequirementProofType;
}

export abstract class AbstractPerformanceRequirementProof
	implements PerformanceRequirementProofInterface
{
	public id: number;
	public type: PerformanceRequirementProofType;
}
