<section class="billboard">
	<div class="billboard-header">
		<h3 class="billboard-title">Agreements</h3>
	</div>
	<div class="billboard-body">
		@for (agreement of agreements; track agreement) {
			<div>
				<button
					class="btn btn-link text-decoration-none"
					type="button"
					(click)="openAgreementModal(agreement)"
				>
					{{ agreement.title }}
				</button>
			</div>
		}
	</div>
</section>
