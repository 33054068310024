<div
	class="ribbon-container"
	style.height="{{ getDiameter() }}px"
	style.width="{{ getDiameter() }}px"
>
	<span class="screen-reader-text"
		>{{ percentage * 100 | number: '1.0-0' }}% Complete</span
	>
	<svg
		aria-hidden="true"
		[attr.height]="getDiameter()"
		[attr.width]="getDiameter()"
	>
		<circle
			class="progress-circle"
			fill="transparent"
			stroke="var(--gray-light)"
			[attr.cx]="getDiameter() / 2"
			[attr.cy]="getDiameter() / 2"
			[attr.r]="getR()"
			[attr.stroke-width]="stroke - 1"
			[ngClass]="{ 'progress-circle-display': showCircles }"
		/>
		<circle
			#circle
			class="progress-circle"
			fill="transparent"
			[attr.cx]="getDiameter() / 2"
			[attr.cy]="getDiameter() / 2"
			[attr.r]="getR()"
			[attr.stroke]="getStrokeColor()"
			[attr.stroke-width]="stroke"
			[ngClass]="{ 'progress-circle-display': showCircles }"
		/>
	</svg>

	<div
		aria-hidden="true"
		class="ribbon-container-center"
		[style.color]="getStrokeColor()"
	>
		<i [ngClass]="getIcon()" style.font-size="{{ rem / 2.25 }}rem"></i>
	</div>
</div>
