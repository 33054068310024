import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	StudentCourseActivityModel,
	StudentCourseActivityModel_ClassTransformType,
} from './student-course-activity.model';

export class StudentCourseActivityResponseModel {
	count: number;
	@Transform(dateTransformer)
	last_login: Date;
	timezone: string;
	@Type(StudentCourseActivityModel_ClassTransformType)
	results: StudentCourseActivityModel[];
}
