import { DecimalPipe, NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	ViewChild,
} from '@angular/core';
import { hasValue } from 'src/lib/utilities/compare';

@Component({
	selector: 'ae-progress-circle',
	templateUrl: './progress-circle.component.html',
	styleUrls: ['./progress-circle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, DecimalPipe],
})
export class ProgressCircleComponent implements OnInit, OnChanges {
	@Input() percentage: number;
	@Input() rem: number = 3;
	@Input() stroke: number = 5;
	@Input() color: string = null;
	@Input() icon: string = null;

	@ViewChild('circle', {
		static: true,
	})
	circle: ElementRef<any>;

	public showCircles: boolean = false;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		// This was added because the circles kept rendering outside of the SVG area. Delaying the transition fixes that
		setTimeout(() => {
			this.showCircles = true;
			this.cdr.detectChanges();
		}, 50);
	}

	public getDiameter = () => this.rem * 16;
	public getR = () => (this.rem * 16) / 2 - this.stroke;
	public getStrokeColor = () => {
		if (hasValue(this.color)) {
			return `var(--${this.color})`;
		} else {
			return this.percentage >= 1 ? 'var(--success)' : 'var(--info)';
		}
	};
	public getIcon = () => {
		return hasValue(this.icon) ? this.icon : 'far fa-trophy-star';
	};

	ngOnChanges(): void {
		this.calculateProgress();
	}

	private calculateProgress = () => {
		const circumference = this.getR() * 2 * Math.PI;

		this.circle.nativeElement.style.strokeDasharray = `${circumference} ${circumference}`;
		this.circle.nativeElement.style.strokeDashoffset = `${circumference}`;

		const clampedPercentage = Math.min(Math.max(0, this.percentage), 1);
		const offset = circumference - clampedPercentage * circumference;
		this.circle.nativeElement.style.strokeDashoffset = offset;
	};
}
