import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PermissionUserRolesModel } from 'src/lib/services/api/permission-manager/permission-user-roles.model';
import { RoleEditModalComponent } from './role-edit-modal.component';

@Injectable({
	providedIn: 'root',
})
export class RoleEditModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(item: PermissionUserRolesModel) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				scrollable: true,
			};
			const upsertModal = this.modalService.open(
				RoleEditModalComponent,
				ngbModalOptions,
			);

			(upsertModal.componentInstance as RoleEditModalComponent).bindModalData({
				item: item,
			});

			upsertModal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
