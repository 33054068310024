<section class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Sitemap</h2>
	</div>
	<div class="billboard-body">
		<div *aeSpinUntil="sitemap$ | async as sitemap">
			<ul>
				@for (item of sitemap; track item) {
					<li>
						<a [routerLink]="item.route">{{ item.name }}</a>
						<div class="ms-3">{{ item.description }}</div>
					</li>
				}
			</ul>
		</div>
	</div>
</section>
