import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from 'src/lib/utilities/api/patterns/pagination/abstract-paging-response.model';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	StudentReminderModel,
	StudentReminderModel_ClassTransform_Type,
} from './student-reminder.model';

export class StudentRemindersResponseModel extends AbstractPagingResponseModel<any> {
	@Type(StudentReminderModel_ClassTransform_Type)
	results: StudentReminderModel[];
}

export interface StudentRemindersFilter {
	message: PagingSearchFieldArgument;
	message_creation: PagingSearchFieldArgument;
	recipient_phone_number: PagingSearchFieldArgument;
	sender_phone_number: PagingSearchFieldArgument;
	message_topic: PagingSearchFieldArgument;
	sender: PagingSearchFieldArgument;
}
