import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { HelpDeskInfoModel } from './help-desk-info.model';
import {
	LoginProgramModel,
	LoginProgramSearchArgument,
} from './login-program.model';
import { LoginSsoOptionsModel } from './login-sso-options.model';
import { LoginStatusModel } from './login-status.model';
import { LoginModel } from './login.model';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(private httpClient: HttpClient) {}

	public getLoginStatus = (): Observable<LoginStatusModel> => {
		return this.httpClient
			.get<LoginStatusModel>(`/api/v1/login_status?_format=json`)
			.pipe(
				mapResponse((r) => plainToInstance(LoginStatusModel, r), {
					errorCode: 'DD1C070C',
				}),
			);
	};

	public login = (
		username: string,
		password: string,
	): Observable<LoginModel> => {
		return this.httpClient
			.post<LoginModel>(`/user/login?_format=json`, {
				name: username,
				pass: password,
			})
			.pipe(
				mapResponse((r) => plainToInstance(LoginModel, r), {
					errorCode: '86C90D5F',
				}),
			);
	};

	public logout = (): Observable<void> => {
		return this.httpClient.get<any>(`/user/logout?_format=json`).pipe(
			finalize(() => {
				globalThis.location.reload();
			}),
		);
	};

	public getSsoOptions = (
		destination?: string,
		program_unique_id?: string,
	): Observable<LoginSsoOptionsModel> => {
		const qs = buildQueryString({
			program_unique_id,
			destination,
		});

		return this.httpClient.get<any>(`/api/v1/openid/sso${qs}`).pipe(
			mapResponse((r) => plainToInstance(LoginSsoOptionsModel, r), {
				errorCode: 'DD1C070C',
			}),
		);
	};

	public requestPasswordReset = (email: string): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/users/reset_password`, { email })
			.pipe(
				mapResponse((r) => r, {
					errorCode: '659A064E',
				}),
			);
	};

	public getHelpDeskInfo = (): Observable<HelpDeskInfoModel> => {
		return this.httpClient.get<LoginStatusModel>(`/api/v1/help_desk`).pipe(
			mapResponse((r) => plainToInstance(HelpDeskInfoModel, r), {
				errorCode: '2966F2FE',
			}),
		);
	};

	// POSTMAN: Anonymous Organizations Search
	public getPrograms = (
		args: Partial<LoginProgramSearchArgument>,
	): Observable<LoginProgramModel[]> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/search/anonymous`, { filters: args })
			.pipe(
				mapResponse(
					(r) => plainToInstance(LoginProgramModel, r.results as any[]),
					{
						errorCode: 'E660FB97',
					},
				),
			);
	};
}
