export class OrganizationInfoModel {
	title: string;
	parent_title: string;
	subdetails: string;
	contact: {
		document_request: string | number;
		iep504_request: string | number;
		phone: string;
		website: string;
	};
	contacts: {
		registrar: string;
		registrar_users: {
			name: string;
			email: string;
			phone: string;
			uid: number;
		}[];
		specialed: string;
		specialed_users: {
			email: string;
			name: string;
			phone: string;
			uid: number;
		}[];
	};
	other: {
		age_restrictions: string;
		diploma_from: string;
		enrollment_paperwork: string;
		enrollment_paperwork_file: [];
		extracurricular: number;
		graduation: number;
		lenient: number;
		meetup_information: string;
		other_specifics: string;
		paperwork_instructions: string;
		contact_notes: string;
		state_notes: string;
		skip_final_settings: {
			current_grade: number;
			meetup_minutes: number;
		};
	};
	transcript: {
		special_instructions: string;
	};
}
