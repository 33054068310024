<div *aeSpinUntil="orgControl != null" class="half-width float-end">
	<div class="d-flex">
		<ae-organization-multi-select
			#orgMultiSC
			class="width"
			id="dash.org.select.program"
			[formControl]="orgControl"
			[maxSelectedItems]="10"
			(loadingComplete)="loadingComplete($event)"
			(update)="updateOrgControl()"
		/>
		@if (allowSubmit) {
			<button
				class="btn btn-primary"
				type="button"
				[disabled]="loading"
				(click)="submit()"
			>
				Submit
			</button>
		}
	</div>
	<div class="float-end">
		<button
			class="btn btn-link text-nowrap"
			type="button"
			[disabled]="loading"
			(click)="orgMultiSC.advancedSearch()"
		>
			Advanced Search
		</button>
		<div
			class="d-inline-block"
			container="body"
			ngbDropdown
			placement="bottom-right"
		>
			<button
				class="btn"
				id="dashboard.configcontrols.search.options.open"
				ngbDropdownToggle
				title="Saved Programs"
				type="button"
				[disabled]="loading"
			>
				<i aria-hidden="true" class="fas fa-search"></i>
				<span> Saved Programs </span>
			</button>
			<ul ngbDropdownMenu>
				@if (userPreferences?.savedSearches?.length > 0) {
					@for (
						savedSearch of userPreferences.savedSearches;
						track savedSearch
					) {
						<li>
							<button
								class="dropdown-item d-flex"
								title="Load Search"
								type="button"
								(click)="loadSearch(savedSearch)"
							>
								<div class="flex-grow-1">
									{{ savedSearch.name }}
								</div>
								<button
									class="btn btn-icon"
									title="Delete Search"
									type="button"
									(click)="deleteSearch(savedSearch, $event)"
								>
									<i aria-hidden="true" class="fas fa-trash"></i>
								</button>
							</button>
						</li>
					}
					<li>
						<hr class="dropdown-divider" />
					</li>
				}
				<li>
					<button class="dropdown-item" type="button" (click)="openModal()">
						<i aria-hidden="true" class="fa-regular fa-floppy-disk fa-fw"></i>
						Save Current Selection
					</button>
				</li>
				<li>
					<button class="dropdown-item" type="button" (click)="reset()">
						<i
							aria-hidden="true"
							class="fa-regular fa-arrow-rotate-left fa-fw"
						></i>
						Clear Current Selection
					</button>
				</li>
			</ul>
		</div>
	</div>
</div>
