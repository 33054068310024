<div class="modal-header">
	<h1 class="modal-title">Export Table</h1>
	<ae-modal-mover />
	<button
		class="btn btn-outline-dark"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
@if (configForm) {
	<div class="modal-body d-flex flex-column" [formGroup]="configForm">
		@switch (exportConfig == null) {
			<!-- Export Selection -->
			@case (true) {
				<h2 class="h5 w-100">Select Columns To Export</h2>
				<div class="mb-2">
					<button
						class="btn btn-outline-primary btn-sm me-1"
						type="button"
						(click)="select('all')"
					>
						Select All
					</button>
					<button
						class="btn btn-outline-primary btn-sm"
						type="button"
						(click)="select('none')"
					>
						Deselect All
					</button>
				</div>
				<div class="flex-grow-1 scroll-y-auto mb-3">
					@for (
						item of configForm.controls.columns.controls;
						track item;
						let i = $index;
						let last = $last
					) {
						<div class="form-check" formArrayName="columns">
							<input
								class="form-check-input"
								type="checkbox"
								[formControl]="item"
								id="pagination.table.export.modal.component.column.{{ i }}"
							/>
							<label
								class="form-check-label unselectable"
								for="pagination.table.export.modal.component.column.{{ i }}"
							>
								<ng-container
									[ngTemplateOutlet]="
										exportableColumns[i].column.headerTemplate.template
									"
								/>
								<div class="fst-italic fs-sm">
									{{ exportableColumns[i].column.headerTemplate.description }}
								</div>
							</label>
						</div>
					}
				</div>
				<h2 class="h5">Select Rows To Export</h2>
				<div class="form-check">
					<input
						class="form-check-input"
						formControlName="allItems"
						id="pagination.table.export.modal.component.range.all-items"
						type="checkbox"
					/>
					<label
						class="form-check-label unselectable"
						for="pagination.table.export.modal.component.range.all-items"
					>
						Export all table rows
					</label>
				</div>
				<div class="row">
					<div aeGroupValidation class="col-6">
						<label
							for="pagination.table.export.modal.component.range.start-index"
						>
							Start Row
						</label>
						<input
							class="form-control"
							formControlName="startIndex"
							id="pagination.table.export.modal.component.range.start-index"
							min="0"
							type="number"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								Start Row is required
							</ng-template>
							<ng-template aeValidationError="min">
								Must be greater than 0
							</ng-template>
						</ae-group-validation-display>
					</div>
					<div aeGroupValidation class="col-6">
						<label
							for="pagination.table.export.modal.component.range.end-index"
						>
							End Row
						</label>
						<input
							class="form-control"
							formControlName="endIndex"
							id="pagination.table.export.modal.component.range.end-index"
							min="0"
							type="number"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								End Row is required
							</ng-template>
							<ng-template aeValidationError="min">
								Must be greater than 0
							</ng-template>
						</ae-group-validation-display>
					</div>
				</div>
			}
			<!-- Export Progress -->
			@case (false) {
				@switch (downloadReady) {
					@case (true) {
						<div>Export is ready</div>
					}
					@default {
						<div>Export is in progress</div>
					}
				}
				<div class="progress">
					<div
						class="progress-bar bg-info"
						role="progressbar"
						[attr.aria-valuenow]="exportProgressPercent"
						[ngClass]="
							downloadReady ? '' : 'progress-bar-striped progress-bar-animated'
						"
						[ngStyle]="{ width: exportProgressPercent + '%' }"
					>
						{{ exportProgressPercent }}%
					</div>
				</div>
				<div class="text-center mt-3">
					<a #downloadLink class="d-none">Download Results</a>
				</div>
				<ae-pagination-table-exporter
					[exportConfig]="exportConfig"
					(exportComplete)="exportComplete($event)"
					(exportProgress)="exportProgress($event)"
				/>
			}
		}
	</div>
}
<div class="modal-footer">
	<button class="btn btn-secondary" type="button" (click)="cancel()">
		@switch (downloadReady) {
			@case (true) {
				Close
			}
			@default {
				Cancel
			}
		}
	</button>
	@if (exportConfig == null) {
		<button
			class="btn btn-primary"
			type="button"
			[disabled]="!configForm?.valid"
			(click)="start()"
		>
			Export
		</button>
	}
</div>
