import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class SurveyPeriodInstituteModel {
	id: number;
	title: string;
}

export function SurveyPeriodInstituteModel_ClassTransform_Type() {
	return SurveyPeriodInstituteModel;
}

export class SurveyPeriodGroupModel {
	key: string;
	title: string;
	@Type(SurveyPeriodInstituteModel_ClassTransform_Type)
	institutes?: SurveyPeriodInstituteModel[];
	deactive?: boolean;
}

export function SurveyPeriodGroupModel_ClassTransform_Type() {
	return SurveyPeriodGroupModel;
}

export class SurveyPeriodGroupOptionsModel {
	@Type(SurveyPeriodGroupModel_ClassTransform_Type)
	institute_groups: SurveyPeriodGroupModel[];
	@Type(SurveyPeriodGroupModel_ClassTransform_Type)
	itype_groups: SurveyPeriodGroupModel[];
}

export class SurveyPeriodUserModel {
	uid: number;
	name: string;
}

export function SurveyPeriodUserModel_ClassTransform_Type() {
	return SurveyPeriodUserModel;
}

export class SurveyPeriodModel {
	id: number;
	title: string;
	state: string;
	program_select_type: number;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	end_date: Date;
	finalized: boolean;
	@Transform(dateTransformer)
	finalized_on: Date;
	@Transform(dateTransformer)
	created_on: Date;
	@Type(SurveyPeriodUserModel_ClassTransform_Type)
	created_by: SurveyPeriodUserModel;
	@Transform(dateTransformer)
	updated_on: Date;
	@Type(SurveyPeriodUserModel_ClassTransform_Type)
	updated_by: SurveyPeriodUserModel;
	groups: SurveyPeriodGroupModel[];
	program_count: number;
}

export interface SurveyPeriodUpsertArgument {
	title: string;
	state: string;
	program_select_type: SurveyPeriodSelectType;
	start_date: string;
	end_date: string;
	groups: string[];
}

export enum SurveyPeriodSelectType {
	'program' = 0,
	'type' = 1,
}
