import { Pipe, PipeTransform } from '@angular/core';
import spacetime, { Spacetime } from 'spacetime';

@Pipe({
	name: 'spaceMap',
	standalone: true,
})
export class SpaceMapPipe implements PipeTransform {
	transform(value: any, timezone?: string): Spacetime {
		if (value == null) return null;

		const st = spacetime(value, timezone);
		if (st.epoch > 0) return st;
		else return null;
	}
}
