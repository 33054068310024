import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class SurveyModel {
	id: number;
	active: boolean;
	name: string;
	@Transform(dateTransformer)
	created: Date;
	@Type(SurveyQuestionModel_ClassTransform_Type)
	questions: SurveyQuestionModel[];
}

export class SurveyResponseQuestionModel {
	question_id: number;
	question_text: string;
	question_category: string;
	question_weight: number;
	answer: boolean;
}

export class SurveyQuestionModel {
	id: number;
	text: string;
	category: string;
	weight: number;
	active: boolean;
}

export function SurveyQuestionModel_ClassTransform_Type() {
	return SurveyQuestionModel;
}

export class SurveyResponseModel {
	survey: SurveyModel;
}

export class SurveyResponseArgument {
	survey_id?: number;
	assigned_on_timestamp?: number;
	completed_on_timestamp?: number;
	questions: { question_id: number; answer: boolean }[];
}

export class SurveyStudentResponseModel {
	survey_id: number;
	response_id: number;
	student_uid: number;
	survey_name: string;
	@Transform(dateTransformer)
	assigned_on_timestamp: Date;
	@Transform(dateTransformer)
	completed_on_timestamp: Date;
	@Type(SurveyStudentResponseQuestionModel_ClassTransform_Type)
	questions: SurveyStudentResponseQuestionModel[];
}

export class SurveyStudentResponseQuestionModel {
	question_id: number;
	question_text: string;
	question_category: string;
	question_weight: number;
	answer: boolean;
}

export function SurveyStudentResponseQuestionModel_ClassTransform_Type() {
	return SurveyStudentResponseQuestionModel;
}
