import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { StateOptionModel } from 'src/lib/services/api/generic/options/state-option.model';
import { buildFormData } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	CurriculumMappingCertificationListResponseModel,
	CurriculumMappingCertificationModel,
	CurriculumMappingCertificationsPagingArgument,
	CurriculumMappingCertificationsUpsertArgument,
} from './curriculum-mapping-certification.model';

@Injectable({
	providedIn: 'root',
})
export class CurriculumMappingCertificationsService {
	constructor(private httpClient: HttpClient) {}

	/** Certifications */
	public getAllTeacherCertifications = (
		args: PagingArgument<CurriculumMappingCertificationsPagingArgument>,
	): Observable<CurriculumMappingCertificationListResponseModel> => {
		return this.httpClient
			.post<CurriculumMappingCertificationListResponseModel>(
				`/api/v1/teacher/certifications`,
				args,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingCertificationListResponseModel, r),
					{ errorCode: '2BEE59AB' },
				),
			);
	};

	public createCertification = (
		args: CurriculumMappingCertificationsUpsertArgument,
		file?: File,
	): Observable<CurriculumMappingCertificationModel> => {
		const bodyArgs = {};
		if (file) {
			bodyArgs['certification_file'] = file;
		}
		bodyArgs['jsonBody'] = args;

		const formData = buildFormData(bodyArgs);
		return this.httpClient
			.post<any>(`/api/v1/teacher/certifications/create`, formData)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingCertificationModel,
							r.certification,
						),
					{
						errorCode: '63FBD11E',
						errorMessage: 'There was an issue adding the certificate',
					},
				),
			);
	};

	public editCertification = (
		id: number,
		args: CurriculumMappingCertificationsUpsertArgument,
		file?: File,
	): Observable<CurriculumMappingCertificationModel> => {
		const bodyArgs = {};
		if (file) {
			bodyArgs['certification_file'] = file;
		}
		bodyArgs['jsonBody'] = args;

		const formData = buildFormData(bodyArgs);
		return this.httpClient
			.post<any>(`/api/v1/teacher/certifications/${id}/edit`, formData)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingCertificationModel,
							r.certification,
						),
					{
						errorCode: 'DC56B6F5',
						errorMessage: 'There was an issue updating the certificate',
					},
				),
			);
	};

	public deleteCertification = (id: number): Observable<boolean> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/certifications/${id}/delete`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D06814B2',
					errorMessage: 'There was an issue removing the certificate',
				}),
			);
	};

	public getCertificateOptions = (): Observable<StateOptionModel[]> => {
		return this.httpClient
			.get<any>('/api/v1/teacher/certifications/options')
			.pipe(
				mapResponse(
					(r) => plainToInstance(StateOptionModel, r.states as any[]),
					{
						errorCode: 'F925223B',
					},
				),
			);
	};
}
