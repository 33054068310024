import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	StudentCareerPlanArgument,
	StudentCareerPlanModel,
} from './student-career-plan.model';
import {
	StudentGraduatePlacementArgument,
	StudentGraduatePlacementModel,
} from './student-graduate-placement.model';

@Injectable({
	providedIn: 'root',
})
export class StudentCareerService {
	constructor(private httpClient: HttpClient) {}

	public getStudentCareerPlan = (
		studentId: number,
	): Observable<StudentCareerPlanModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/career_plan`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCareerPlanModel, r), {
					errorCode: 'C5BB602D',
				}),
			);
	};

	public getStudentGraduatePlacement = (
		studentId: number,
	): Observable<StudentGraduatePlacementModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/grad_placement`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentGraduatePlacementModel, r), {
					errorCode: '74C4BD2F',
				}),
			);
	};

	public upsertStudentCareerPlan = (
		studentId: number,
		args: StudentCareerPlanArgument,
	): Observable<StudentCareerPlanModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/career_plan`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentCareerPlanModel, r), {
					errorCode: '9BC71CD9',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public upsertStudentGraduatePlacement = (
		studentId: number,
		args: StudentGraduatePlacementArgument,
	): Observable<StudentGraduatePlacementModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/grad_placement`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentGraduatePlacementModel, r), {
					errorCode: 'E963AAA3',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
