import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class WorkQueueEntityModel {
	queue_id: number;
	entity_type: string;
	entity_id: number;
	weight: number;
	@Transform(dateTransformer)
	completed: Date;
	@Transform(dateTransformer)
	created: Date;
	@Transform(dateTransformer)
	updated: Date;
	label: string;
	withdrawn: boolean;
	reassigned: boolean;
}

export interface WorkQueueEntityArgument {
	entity_type: string;
	entity_id: number;
	weight: number;
}

export class WorkQueueTrackerResponseModel {
	name: string;
	color: string;
	purpose: string;
	entity_id: number;
	queue_id: number;
	@Transform(dateTransformer)
	completed: Date;
}

export function WorkQueueTrackerResponseModel_ClassTransform_Type() {
	return WorkQueueTrackerResponseModel;
}
