import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentCourseActivityModel {
	registration_id: number;
	itemid: string;
	@Transform(dateTransformer)
	timestamp: Date;
	seconds: number;
	registration_name: string;
	item_name: string;
}

export function StudentCourseActivityModel_ClassTransformType() {
	return StudentCourseActivityModel;
}
