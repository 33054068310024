import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export type BulkExportEnrollmentFileType = 'all' | 'none' | 'required_only';

export class PaperworkManagerHistoryFileModel {
	file_id: { d6_id: string; file_uuid: string };
	file_type: string;
	location_details: string;
	status: string;
	uid: number;
}

export class PaperworkManagerBulkExportGroupModel {
	group_id: string;
	group_name: string;
}

export function PaperworkManagerBulkExportGroupModel_ClassTransform_Type() {
	return PaperworkManagerBulkExportGroupModel;
}

export class PaperworkManagerBulkExportModel {
	id: number;
	status: string;
	status_data?: {
		collect_count: number;
		collect_skip: number;
		export_id: number;
		upload_count: number;
		upload_skip: number;
	};
	@Type(PaperworkManagerBulkExportGroupModel_ClassTransform_Type)
	groups: PaperworkManagerBulkExportGroupModel[];
	@Transform(dateTransformer)
	creation_date: Date;
	@Transform(dateTransformer)
	completion_date: Date;
	estimated_student_count: number;
	@Transform(dateTransformer)
	estimated_completion_time: Date;
	maximum_pdf_generations_hour: number;
	files?: PaperworkManagerHistoryFileModel[];
}

export function PaperworkManagerBulkExportModel_ClassTransform_Type() {
	return PaperworkManagerBulkExportModel;
}

export class PaperworkManagerBulkExportResponseModel {
	@Type(PaperworkManagerBulkExportModel_ClassTransform_Type)
	results: PaperworkManagerBulkExportModel[];
	count: number;
}

export interface PaperworkManagerBulkExportCreateArgument {
	groups: string[];
	enrollment_files: BulkExportEnrollmentFileType;
	include_wslp: boolean;
	include_uploaded_transcripts: boolean;
	include_ga_transcript: boolean;
	include_learning_plan: boolean;
}
