import type { Node as ProseMirrorNode } from 'prosemirror-model';
import type { Command, EditorState, Transaction } from 'prosemirror-state';
import { applyMark } from '../prosemirror-additional-commands';

import { getSelectionNodes } from 'ngx-editor/helpers';

export type NgxFontSize = 'small' | 'large' | 'huge';

export default class FontSizeMark {
	size: NgxFontSize | null;

	constructor(size: NgxFontSize | null) {
		this.size = size;
	}

	apply(): Command {
		return (
			state: EditorState,
			dispatch?: (tr: Transaction) => void,
		): boolean => {
			const { schema } = state;

			return applyMark(schema.marks['font_size'], {
				fontSize: this.size,
			})(state, dispatch);
		};
	}

	toggle(): Command {
		return (
			state: EditorState,
			dispatch?: (tr: Transaction) => void,
		): boolean => {
			const { schema } = state;

			return applyMark(schema.marks['font_size'], {
				fontSize: this.size,
			})(state, dispatch);
		};
	}

	isActive(state: EditorState): boolean {
		const { schema } = state;
		const nodesInSelection = getSelectionNodes(state);

		const supportedNodes = [
			schema.nodes['heading'],
			schema.nodes['text'],
			schema.nodes['blockquote'],
		];

		const nodes = nodesInSelection.filter((node) => {
			return supportedNodes.includes(node.type);
		});

		const activeNode = nodes.find((node: ProseMirrorNode) => {
			return node.marks.find((m) => m.attrs.fontSize === this.size);
		});

		return Boolean(activeNode);
	}

	canExecute(state: EditorState): boolean {
		return this.toggle()(state);
	}
}
