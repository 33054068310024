import { Clipboard } from '@angular/cdk/clipboard';
import { NgClass } from '@angular/common';
import {
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PasswordGeneratorService } from 'src/lib/services/utility/password/password-generator.service';
import { noop } from 'src/lib/utilities/noop';

@Component({
	selector: 'ae-password-randomizer',
	templateUrl: './password-randomizer.component.html',
	styleUrls: ['./password-randomizer.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PasswordRandomizerComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NgbTooltip, NgClass],
})
export class PasswordRandomizerComponent
	implements OnInit, OnChanges, OnDestroy
{
	@HostBinding('class.custom-form-control') customFormControl = true;
	private _unsubscribe$ = new AsyncSubject<null>();

	private _changeFunction: (value: string) => void = noop;
	private _touchedFunction: () => void = noop;
	private _changeWatcher: Subscription;

	@Input() placeholder: string = '';
	@Input() title: string = '';
	@Input() hidePassword: boolean = false;
	@Input() id: any = null;

	protected fieldType: 'text' | 'password' = 'text';
	public ctrl: FormControl<string>;

	constructor(
		private ele: ElementRef<HTMLElement>,
		private passwordService: PasswordGeneratorService,
		private fb: FormBuilder,
		private clipboard: Clipboard,
		private toastr: ToastrService,
	) {}

	ngOnInit(): void {
		this.ctrl = this.fb.control(null);
		this.watchForChange();
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;
		this._changeWatcher = this.ctrl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (this._changeFunction) {
					this._changeFunction(val);
				}
				if (this._touchedFunction) {
					this._touchedFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	public generatePassword = () => {
		const password = this.passwordService.generate(10, false);

		this.ctrl.setValue(password);
		this.clipboard.copy(password);
		this.toastr.info('Password copied to clipboard');

		this.fieldType = 'text';
	};

	public toggleFieldType = () => {
		if (this.fieldType === 'password') {
			this.fieldType = 'text';
		} else {
			this.fieldType = 'password';
		}
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.hidePassword) {
			if (this.hidePassword) {
				this.fieldType = 'password';
			} else {
				this.fieldType = 'text';
			}
		}

		if (changes.id) {
			this.ele.nativeElement.removeAttribute('id');
		}
	}

	// ControlValueAccessor
	public writeValue(password: string): void {
		try {
			this.stopWatchForChange();
			this.ctrl.setValue(password);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		if (isDisabled) {
			this.ctrl.disable();
		} else {
			this.ctrl.enable();
		}

		this.watchForChange();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
