import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[aeAnchorLink]',
	standalone: true,
})
export class AnchorLinkDirective implements OnInit {
	@Input() aeAnchorLink: string;

	constructor(private element: ElementRef<HTMLLinkElement>) {}

	ngOnInit(): void {
		this.element.nativeElement.onclick = (event) => {
			event?.preventDefault();
			this.go();
		};
	}

	//https://stackoverflow.com/a/13736194
	private go = () => {
		const url = location.href.split('#')[0];
		location.href = `${url}#${this.element.nativeElement.href.split('#')[1]}`;
		history.replaceState(null, null, url);
	};
}
