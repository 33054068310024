<div class="modal-header">
	<h1 class="modal-title">
		<div>{{ title }}</div>
	</h1>

	@if (passwordForm) {
		<ae-modal-close-timeout
			id="prompt.password.modal.component.times.close"
			[countdown]="data?.type === 'Gate'"
			delayMessage="You can close but the {{
				title
			}} will continue to prompt again until password is updated"
			[uid]="uid"
			(closeAction)="activeModal.dismiss()"
		/>
	}
</div>
<div *aeSpinWhile="passwordForm == null" class="modal-body">
	<div class="h5 fw-normal">
		Hi <span class="fw-bold">{{ uid | userName }}</span> your password needs to
		be updated
	</div>
	<div [formGroup]="passwordForm">
		<div aeGroupValidation class="form-group">
			<label for="prompt.password.modal.component.current"
				>Current Password</label
			>
			<input
				class="form-control"
				formControlName="current"
				id="prompt.password.modal.component.current"
				type="password"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="passwordNotValid" let-error>
					Incorrect Password
				</ng-template>
				<ng-template aeValidationError="required">
					Current Password Required
				</ng-template>
			</ae-group-validation-display>
		</div>

		<div aeGroupValidation class="form-group">
			<label for="prompt.password.modal.component.updated">New Password</label>
			<ae-password-randomizer
				id="prompt.password.modal.component.updated"
				[formControl]="passwordForm.controls.updated"
				[hidePassword]="true"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					New Password Required
				</ng-template>
				<ng-template aeValidationError="forbiddenValue">
					New Password cannot be that value
				</ng-template>
				<ng-template aeValidationError="minlength">
					New Password must be at least 8 characters
				</ng-template>
				<ng-template aeValidationError="maxlength">
					New Password cannot be more than 1000 characters
				</ng-template>
			</ae-group-validation-display>

			@if (passwordForm.hasError('sameAsCurrent')) {
				<div class="text-danger">
					{{ passwordForm.getError('sameAsCurrent') }}
				</div>
			}
		</div>

		<div aeGroupValidation class="form-group">
			<label for="prompt.password.modal.component.confirmed"
				>Confirm New Password</label
			>
			<input
				class="form-control"
				formControlName="confirmed"
				id="prompt.password.modal.component.confirmed"
				type="password"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Confirm Password Required
				</ng-template>
			</ae-group-validation-display>

			@if (passwordForm.hasError('passwordMismatch')) {
				<div class="text-danger" role="alert">Passwords don't match</div>
			}
		</div>
	</div>
</div>

<div class="modal-footer">
	<button
		class="btn btn-primary"
		type="button"
		[disabled]="!passwordForm?.valid"
		(click)="save()"
	>
		<ae-wait-spinner [saving]="saving"> Save </ae-wait-spinner>
	</button>
</div>
