import { Transform, Type } from 'class-transformer';
import {
	IStudentPerformanceHistoryModel,
	IStudentPerformanceHistoryPeriodModel,
	StudentPerformanceStatus,
	StudentPerformanceTrend,
} from 'src/lib/constants/compliance';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentPerformanceHistoryPeriodModel
	implements IStudentPerformanceHistoryPeriodModel
{
	@Transform(dateTransformer)
	start: Date;
	@Transform(dateTransformer)
	end: Date;
}

export class StudentPerformanceHistoryModel
	implements IStudentPerformanceHistoryModel
{
	id: number;
	title: string;
	short_title: string;
	performance: StudentPerformanceStatus;
	billable: StudentPerformanceStatus;
	finalized: boolean;
	trend: StudentPerformanceTrend;
	drop_expected: boolean;
	months_remain?: number;
	@Type(StudentPerformanceHistoryPeriodModel_ClassTransform_Type)
	period: StudentPerformanceHistoryPeriodModel;
}

export function StudentPerformanceHistoryPeriodModel_ClassTransform_Type() {
	return StudentPerformanceHistoryPeriodModel;
}
