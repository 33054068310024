import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GabbyUserModel } from 'src/lib/services/api/gabby/users/gabby-user.model';
import { GabbyUsersService } from 'src/lib/services/api/gabby/users/gabby-users.service';

interface GabbyUserStorageSet {
	user: BehaviorSubject<GabbyUserModel>;
	stateSubscriptions: Subscription[];
	lastRefresh?: Date;
}

@Injectable({
	providedIn: 'root',
})
export class GabbyUserStoreService {
	private _storageSets = new Map<number, GabbyUserStorageSet>();

	constructor(private gabbyUsersService: GabbyUsersService) {}

	public user$ = (userLinkId: number): Observable<GabbyUserModel> => {
		this.refresh(userLinkId);

		return this.getStorageSet(userLinkId)
			.user.asObservable()
			.pipe(filter((x) => x !== undefined));
	};

	private refresh = (userLinkId: number, force: boolean = false): void => {
		const set = this.getStorageSet(userLinkId);

		if (userLinkId == null) {
			set.user.next(null);
		} else if (set.lastRefresh == null || force) {
			set.lastRefresh = new Date();

			this.gabbyUsersService.getUser(userLinkId).subscribe({
				next: (user) => {
					set.user.next(user);
				},
				error: (err) => {
					console.error(`Failure while trying to load gabby user`, err);
					set.user.next(null);
				},
			});
		}
	};

	private getStorageSet = (userLinkId: number) => {
		if (!this._storageSets.has(userLinkId)) {
			this._storageSets.set(userLinkId, {
				user: new BehaviorSubject<GabbyUserModel>(undefined),
				stateSubscriptions: [],
			} as GabbyUserStorageSet);
		}

		return this._storageSets.get(userLinkId);
	};
}
