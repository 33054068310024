import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class GraderTrackerLinksModel {
	student_gradebook: string;
	course_gradebook: string;
	assignment_gradebook: string;
}

export function GraderTrackerLinksModel_ClassTransform_Type() {
	return GraderTrackerLinksModel;
}

export class GraderTrackerItemModel {
	student_id: number;
	user_link_id: number;
	profile_first_name: string;
	profile_last_name: string;
	profile_preferred_name: string;
	teacher_id: number;
	teacher_name: string;
	days_ungraded: number;
	registration_id: number;
	registration_title: string;
	assignment_id: number;
	assignment_title: string;
	@Expose({ name: 'submitted_timestamp' })
	@Transform(dateTransformer)
	submitted_date: Date;
	link_id: number;
	@Type(GraderTrackerLinksModel_ClassTransform_Type)
	links: GraderTrackerLinksModel;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
}
