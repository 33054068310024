import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	CoursePacingTypes,
	StudentCourseOptionModel,
	StudentCourseOptionModel_ClassTransformType,
} from '../students/classes/student-course-options.model';

export class OrganizationCourseOptionModel {
	@Type(StudentCourseOptionModel_ClassTransformType)
	course_list: StudentCourseOptionModel[];
	pacing_type: CoursePacingTypes;
	@Expose({ name: 'default_pacing_start_timestamp' })
	@Transform(dateTransformer)
	default_pacing_start_date: Date;
	default_pacing_weeks: number;
	@Expose({ name: 'default_target_end_timestamp' })
	@Transform(dateTransformer)
	default_target_end_date: Date;
	pacing_required: boolean;
	pacing_start_allowed_days_of_week: number[];
	pacing_specific_dates: number[][];
	timezone: string;
}
