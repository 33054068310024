<div class="modal-header">
	<h1 class="modal-title">
		{{ title }}
	</h1>

	<ae-modal-mover />
	<button
		class="btn btn-outline-dark"
		id="external.account.creation.modal.times.cancel"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
<div *aeSpinWhile="loading" class="modal-body">
	<div [formGroup]="externalAccountFG">
		<div aeGroupValidation class="form-group">
			<label for="external.account.creation.modal.add">External Account</label>
			<ng-select
				bindLabel="title"
				formControlName="account"
				labelForId="external.account.creation.modal.add"
				[clearable]="false"
				[items]="options"
			/>

			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Account is required
				</ng-template>
			</ae-group-validation-display>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="external.account.creation.modal.cancel"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Cancel
	</button>
	<button
		class="btn btn-primary"
		id="external.account.creation.modal.save"
		type="button"
		[disabled]="loading || saving || !externalAccountFG?.valid"
		(click)="save()"
	>
		Save
	</button>
</div>
