import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BehaviorCache } from 'src/lib/utilities/cache';
import { OptionsService } from '../../api/generic/options/options.service';
import { ProgramTypeOptionModel } from '../../api/generic/options/program-type-option.model';
import { StateOptionModel } from '../../api/generic/options/state-option.model';

@Injectable({
	providedIn: 'root',
})
export class OptionsStoreService {
	private _statesCache = new BehaviorCache<boolean, StateOptionModel[]>(
		() => this.optionsService.getStateOptions().pipe(map((orgs) => orgs)),
		'OptionsStoreService OptionsStateService',
	);

	private _typesCache = new BehaviorCache<boolean, ProgramTypeOptionModel[]>(
		() => this.optionsService.getProgramTypeOptions().pipe(map((orgs) => orgs)),
		'OptionsStoreService OptionsProgramTierService',
	);

	constructor(private optionsService: OptionsService) {}

	/**
	 * States
	 */
	public states$ = (): Observable<StateOptionModel[]> => {
		return this._statesCache.getCache(true);
	};

	public refreshStates = (): Promise<boolean> => {
		return this._statesCache.fetchData(true);
	};

	/**
	 * Program Types
	 */
	public types$ = (): Observable<ProgramTypeOptionModel[]> => {
		return this._typesCache.getCache(true);
	};

	public refreshTypes = (): Promise<boolean> => {
		return this._typesCache.fetchData(true);
	};
}
