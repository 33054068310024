<div class="modal-header">
	<h1 class="modal-title">Complete Task</h1>
	<button
		class="btn btn-outline-dark"
		id="lunchbox.pages.events.task.completion.modal.close.times"
		title="Close Add Class"
		type="button"
		(click)="close(false)"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<div class="form-group">
		<label for="lunchbox.pages.events.task.completion.modal.subject"
			>Subject
		</label>
		<span class="ms-2">{{ selectedEvent?.subject }}</span>
	</div>
	<div class="form-group">
		<label for="lunchbox.pages.events.task.completion.modal.comment"
			>Completion Comment</label
		>
		<textarea
			class="form-control task-completion-comment"
			id="lunchbox.pages.events.task.completion.modal.comment"
			[(ngModel)]="completionComment"
		></textarea>
	</div>
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="lunchbox.pages.events.task.completion.modal.close.button"
		type="reset"
		(click)="close(false)"
	>
		Cancel
	</button>
	<button
		class="btn btn-primary"
		id="lunchbox.pages.events.task.completion.modal.save.button"
		type="submit"
		(click)="save()"
	>
		Complete Task
	</button>
</div>
