import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { MessageDeliveryModel } from 'src/lib/services/api/gabby/messages/message-delivery.model';
import { MessageModel } from 'src/lib/services/api/gabby/messages/message.model';
import { MessagesArgument } from 'src/lib/services/api/gabby/messages/messages.argument';
import { MessagesService } from 'src/lib/services/api/gabby/messages/messages.service';
import { GabbyWampEventFactoryService } from 'src/lib/services/wamp/wamp-event-factory/gabby/gabby-wamp-event-factory.service';
import { subscribeAndPromise } from 'src/lib/utilities/cache';
import { UserStoreService } from '../../users/user/user-store.service';

export interface MessageStorageSet {
	messages: BehaviorSubject<MessageModel[]>;
	directoryId: string;
	channelId: string | number;
}

@Injectable({
	providedIn: 'root',
})
export class MessageStoreService {
	private _storageSets = new Map<
		string,
		Map<string | number, MessageStorageSet>
	>();

	constructor(
		private messagesService: MessagesService,
		private wefs: GabbyWampEventFactoryService,
		private userStore: UserStoreService,
	) {
		this.wefs.newMessage$().subscribe((messageEvent) => {
			const message = messageEvent.message;

			if (message != null) {
				this.pushNewMessages(message.directory, message.channel_id, [message]);
			}
		});

		this.wefs.readMessages$().subscribe((readEvent) => {
			const set = this.getStorageSet(
				readEvent.directoryId,
				readEvent.channelId,
			);

			set.messages.pipe(take(1)).subscribe((messages) => {
				if (!messages) return;

				for (const message of messages) {
					if (readEvent.readMessageIds.indexOf(message.message_id) !== -1) {
						if (
							readEvent.readerLinkId === this.userStore.currentUserLinkId ||
							readEvent.readerLinkId === 0
						) {
							message.message_read = true;
						}

						message.delivery_data = message.delivery_data || [];

						let j = 0;
						for (; j < message.delivery_data.length; j++) {
							if (
								message.delivery_data[j].participant_link_id ===
								readEvent.readerLinkId
							) {
								message.delivery_data[j].read = true;
							}
						}

						if (j < message.delivery_data.length) {
							const mdm = new MessageDeliveryModel();
							mdm.delivery_methods = [];
							mdm.participant_link_id = readEvent.readerLinkId;
							mdm.read = true;

							message.delivery_data.push(mdm);
						}
					}
				}

				set.messages.next(messages);
			});
		});

		this.wefs.updateMessage$().subscribe((updateEvent) => {
			const message = updateEvent.message;

			if (message != null) {
				this.pushNewMessages(message.directory, message.channel_id, [message]);
			}
		});
	}

	public messages$ = (
		directoryId: string,
		channelId: string | number,
	): Observable<MessageModel[]> => {
		this.loadMoreMessages(directoryId, channelId);

		return this.getStorageSet(directoryId, channelId)
			.messages.asObservable()
			.pipe(filter((x) => x !== undefined));
	};

	public message$ = (
		directoryId: string,
		channelId: string | number,
		messageId: number,
	): Observable<MessageModel> => {
		return this.messages$(directoryId, channelId).pipe(
			map((x) => x.find((c) => c.message_id === messageId)),
		);
	};

	public loadMoreMessages = (
		directoryId: string,
		channelId: string | number,
		messageIndex?: number,
	): Promise<boolean> => {
		const set = this.getStorageSet(directoryId, channelId);

		if (directoryId == null || channelId == null) {
			set.messages.next([]);
			return Promise.resolve(true);
		} else {
			return subscribeAndPromise(
				set.messages.pipe(
					take(1),
					switchMap((messages) => {
						if (
							messages == null ||
							messages.length === 0 ||
							messages[0].message_id >= messageIndex ||
							messages.find((m) => m.message_read) == null
						) {
							let args: MessagesArgument;
							if (messageIndex != null && messageIndex > -1) {
								args = {
									message_id: messageIndex,
									message_count_back:
										getEnvironment().settings.services.store.message.loadcount,
								};
							}

							return this.messagesService.getMessages(
								directoryId,
								channelId,
								args,
							);
						} else {
							return of([]);
						}
					}),
				),
				{
					next: (x) => {
						set.messages.pipe(take(1)).subscribe((mergeMessages) => {
							const [newMessages, changed] = this.mergeMessages(
								mergeMessages,
								x,
							);

							if (changed) {
								set.messages.next(newMessages);
							}
						});
					},
				},
			);
		}
	};

	public addFakeMessage = (
		directoryId: string,
		channelId: string | number,
		message: MessageModel,
	) => {
		this.pushNewMessages(directoryId, channelId, [message]);
	};

	public refreshMessages = (
		directoryId: string,
		channelId: number | string,
	) => {
		const set = this.getStorageSet(directoryId, channelId);
		set.messages.next(undefined);
		return this.loadMoreMessages(directoryId, channelId);
	};

	public resetMessages = () => {
		this._storageSets.clear();
	};

	private getStorageSet = (
		directoryId: string,
		channelId: string | number,
	): MessageStorageSet => {
		if (!this._storageSets.has(directoryId)) {
			this._storageSets.set(
				directoryId,
				new Map<string | number, MessageStorageSet>(),
			);
		}

		const directoryMap = this._storageSets.get(directoryId);

		if (!directoryMap.has(channelId)) {
			directoryMap.set(channelId, {
				messages: new BehaviorSubject<MessageModel[]>(undefined),
				directoryId: directoryId,
				channelId: channelId,
			});
		}

		return directoryMap.get(channelId);
	};

	private pushNewMessages = (
		directoryId: string,
		channelId: string | number,
		messages: MessageModel[],
	) => {
		const set = this.getStorageSet(directoryId, channelId);

		set.messages.pipe(take(1)).subscribe((m) => {
			const [merged, changed] = this.mergeMessages(m, messages);
			if (changed) {
				set.messages.next(merged);
			}
		});
	};

	private mergeMessages = (
		original: MessageModel[],
		newMessages: MessageModel[],
	): [MessageModel[], boolean] => {
		let changed = false;

		if (original == null) {
			original = newMessages.slice();
			changed = true;
		} else {
			original = original.slice();

			newMessages.forEach((newMessage) => {
				const foundMessageIndex = original.findIndex(
					(currentMessage) =>
						currentMessage.message_id === newMessage.message_id,
				);

				// New message, just add it
				if (foundMessageIndex === -1) {
					original.push(newMessage);
					changed = true;
				}
				// Check if the new message is different then add if true
				else if (
					JSON.stringify(original[foundMessageIndex]) !==
					JSON.stringify(newMessage)
				) {
					original.splice(foundMessageIndex, 1, newMessage);
					changed = true;
				}
			});
		}

		return [original, changed];
	};
}
