<section #rootElement class="billboard" tabindex="-1">
	<div class="billboard-header">
		<h2 class="billboard-title">Family and Support</h2>
		<div class="billboard-actions d-flex">
			<div class="text-center flex-grow-0 mx-2 align-self-center">
				@if (existingPendingChange) {
					<ae-icon-tooltip
						message="A request to change this info is pending"
						type="pending"
					/>
				}
			</div>
			@if (canEditContact) {
				<button
					class="btn btn-default"
					id="profile.contact.edit.enable"
					type="button"
					[disabled]="loading"
					(click)="enableEdit()"
				>
					Edit
				</button>
			}
		</div>
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading">
			@for (personal of contactData; track personal) {
				<div class="mb-3">
					<!-- Contact Info -->
					<div class="fw-bold">{{ personal.name }}</div>
					<!-- Relationship -->
					@if (personal.relationship) {
						<div class="tabbed-items">
							@if (
								hasAttribute(personal, 'primary') ||
								hasAttribute(personal, 'guardian')
							) {
								<span class="tab-icon">
									@if (hasAttribute(personal, 'primary')) {
										<span ngbTooltip="Primary Parent/Guardian">
											<span class="screen-reader-text"
												>Primary Parent/Guardian</span
											>
											<i aria-hidden="true" class="fas fa-star-exclamation"></i>
										</span>
									}
									@if (!hasAttribute(personal, 'primary')) {
										<span ngbTooltip="Parent/Guardian">
											<span class="screen-reader-text">Parent/Guardian</span>
											<i aria-hidden="true" class="fas fa-star"></i>
										</span>
									}
								</span>
							}
							<div>
								{{ personal.relationship }}
							</div>
						</div>
					}
					<!-- Phone Numbers -->
					<div class="tabbed-items">
						<span class="tab-icon" ngbTooltip="Phone Numbers">
							<i aria-hidden="true" class="fas fa-phone"></i>
						</span>
						@if (personal?.phones.length === 0) {
							<div>No phone numbers</div>
						}
						@if (personal.phones?.length > 0) {
							@for (phone of personal.phones; track phone) {
								<div>
									<a href="tel:{{ phone.phone | phone }}">{{
										phone.phone | phone
									}}</a>
									@if (phone.type) {
										<span class="text-muted text-lowercase phone-type"
											>[{{ phone.type }}]</span
										>
									}
									@if (phone.sms_blocked || phone.sms) {
										<span class="fa-stack sms-stack">
											@if (phone.sms_blocked) {
												<i
													aria-hidden="true"
													class="fa-slash fa-stack-1x fas"
												></i>
											}
											<i aria-hidden="true" class="fa-sms fa-stack-1x far"></i>
										</span>
									}
								</div>
							}
						}
					</div>
					<!-- Emails -->
					<div class="tabbed-items">
						<span class="tab-icon" ngbTooltip="Emails">
							<i aria-hidden="true" class="fas fa-envelope"></i>
						</span>
						@if (personal?.emails.length === 0) {
							<div>No email addresses</div>
						}
						<div class="email-container">
							@if (personal.emails?.length > 0) {
								@for (email of personal.emails; track email) {
									<div class="email-container" [title]="email">
										<a target="_blank" href="mailto:{{ email }}">
											{{ email }}
										</a>
									</div>
								}
							}
						</div>
					</div>
				</div>
			}
			@if (contactData?.length === 0) {
				<div>
					<div class="text-center text-muted my-3">
						User has no family contacts
					</div>
				</div>
			}
		</div>
	</div>
</section>
