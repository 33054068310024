import { Transform } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';

export class StudentAssignmentModel {
	assignment_id: string;
	assignment_title: string;
	class_title: string;
	@Transform(integerTransformer)
	registration_id: number;
	score: number;
	@Transform(dateTransformer)
	scored_date: Date;
	@Transform(dateTransformer)
	submission_date: Date;
	teacher_feedback: boolean;
}
