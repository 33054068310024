import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentAssignmentModel } from 'src/lib/services/api/students/assignments/student-assignment.model';
import { StudentAssignmentService } from 'src/lib/services/api/students/assignments/student-assignment.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentAssignmentStoreService {
	private _recentlyGradedCache = new BehaviorCache<
		number,
		StudentAssignmentModel[]
	>(
		(uid) => this.studentAssignmentService.getRecentlyGradedAssignments(uid),
		'StudentAssignmentStoreService RecentlyGradedCache',
	);

	constructor(private studentAssignmentService: StudentAssignmentService) {}

	/**
	 * Recently Graded
	 */
	public recentlyGradedAssignments$ = (
		uid: number,
	): Observable<StudentAssignmentModel[]> => {
		return this._recentlyGradedCache.getCache(uid);
	};

	public refreshRecentlyGradedAssignments = (uid: number): Promise<boolean> => {
		return this._recentlyGradedCache.fetchData(uid, true);
	};

	public clearRecentlyGradedAssignments = (uid: number): void => {
		return this._recentlyGradedCache.clearData(uid);
	};
}
