export class CommonSettings {
	startup = {
		student: {
			activeStudentSettingID: null,
			activeStudentGuardFallbackRoute: '/invalid-state',
			noStudentGuardFallbackRoute: '/',
		},
	};

	templates = {
		global: {
			currentTime: {
				intervalTime: 250,
			},
		},
	};

	views = {
		student: {
			gateLockoutDelaySeconds: 5,
			speedbumpHideSeconds: 60 * 60 * 24 * 1 /* 1 Day */,
		},
		gabby: {
			main: {
				titleNotificationDelay: 1000,
			},
			chat: {
				timestampThreshold: 15 * 60 * 1000,
				scrollEdgeBuffer: 500,
				autoResponseTimeout: 60 * 60 * 1000,
			},
			message: {
				maxBodyLength: 2000,
				maxAttachmentSize: 1048576 * 2, // 2MB
				maxAttachmentCount: 5,
				attachmentExtensionFilter: '.png,.jpg,.jpeg,.gif,image/*',
			},
		},
	};

	services = {
		store: {
			message: {
				loadcount: 15,
			},
			channel: {
				pageSize: 20,
			},
		},
		techBanners: {
			buildVersion: {
				checkInterval: 60000 * 30 /* 30 minutes */,
			},
		},
		utilities: {
			userActive: {
				inactiveDelay: 10 * 1000 /* 10 seconds */,
				awayDelay: 10 * 60 * 1000 /* 10 minutes */,
			},
			profileFileSize: 1048576 * 2, // 1MB
			studentUploadSize: 1048576 * 20, // 20MB
		},
		wamp: {
			scaledRetryDelay: 1000,
			hotRetryDelay: 5000,
			gabby: {
				readChannelDelay: 2.5 * 1000 /* 2.5 seconds */,
				unreadDelay: 5 * 1000 /* 5 seconds */,
				typingActivityTimeout: 15 * 1000 /* 5 seconds */,
			},
		},
	};
}
