<div
	class="input-group validate-include-children flex-nowrap overflow-hidden"
	container="body"
	ngbDropdown
>
	<button
		class="btn btn-gray"
		ngbDropdownToggle
		type="button"
		[disabled]="disabled"
		[id]="id + '.equalitycomparer.operators'"
		[ngClass]="{ 'flex-grow-1 rounded': hideInput }"
	>
		<ae-search-operator-icon [operator]="ecForm.controls.operator.value" />
		<ng-template #spacer />
	</button>
	<div aria-label="Operator" ngbDropdownMenu role="listbox">
		@for (op of operators; track op) {
			<button
				ngbDropdownItem
				role="option"
				type="button"
				[attr.aria-selected]="ecForm.controls.operator.value === op"
				(click)="selectOperator(op)"
				(keydown.enter)="selectOperator(op)"
			>
				<ae-search-operator-icon [operator]="op" />
			</button>
		}
	</div>
	<input
		#numberInput
		class="form-control flex-shrink-1"
		type="number"
		[formControl]="ecForm.controls.operand"
		[id]="id"
		[max]="max"
		[min]="min"
		[ngClass]="{ 'd-none': hideInput }"
		[step]="step"
	/>
</div>
