import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AsyncSubject, combineLatest, of, timer } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { MessagePlatform } from 'src/lib/services/api/gabby/messages/message-platform.enum';
import { ChannelStoreService } from 'src/lib/services/stores/gabby/channel-store/channel-store.service';
import { DirectoryStoreService } from 'src/lib/services/stores/gabby/directory-store/directory-store.service';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';
import { WebNotificationService } from 'src/lib/services/utility/web-notification/web-notification.service';
import { MessageEventService } from 'src/lib/services/wamp/gabby/message-event.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';

@Injectable({
	providedIn: 'root',
})
export class GabbyNotificationService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private _hasInitted: boolean = false;

	private notificationAudio = new Audio(
		`${getEnvironment().assets}/audio/gabby-notification.mp3`,
	);

	constructor(
		private directoryStore: DirectoryStoreService,
		private channelStore: ChannelStoreService,
		private userStore: UserStoreService,
		private webNotifications: WebNotificationService,
		private messageEventService: MessageEventService,
		private localStore: LocalStoreService,
		private title: Title,
	) {}

	public init = (loadChannel: (channelId: string | number) => void) => {
		if (this._hasInitted) {
			throw new Error('GabbyNotificationService has already been initialized');
		}
		this._hasInitted = true;

		// Notification tracking
		const deliveredNotifications = new Map<string, () => void>();
		const getNotificationKey = (d, c, m) => [d, c, m].join(':');

		// Send Web Notifications
		this.directoryStore
			.directories$(this.userStore.currentUserUid)
			.pipe(
				map((directories) =>
					directories.filter((d) => d.is_primary).map((d) => d.id),
				),
				switchMap((dIds) =>
					combineLatest([this.messageEventService.unreadMessages$(), of(dIds)]),
				),
				filter(([e, dIds]) => {
					return (
						e.message_sender_link_id !== this.userStore.currentUserLinkId &&
						dIds.indexOf(e.directory) !== -1 &&
						e.message_send_medium !== MessagePlatform.system
					);
				}),
				map(([e, _dIds]) => e),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((m) => {
				// If we don't have notifications, just play audio
				if (!this.webNotifications.canCreate()) {
					this.playNotificationAudio();
				}
				// We need to coordinate the audio with the notification
				else {
					this.channelStore
						.channel$(m.channel_id, m.directory)
						.pipe(take(1), takeUntil(this._unsubscribe$))
						.subscribe((c) => {
							if (c != null) {
								SessionLogger.log(
									'GabbyNotificationServices',
									'webNotification',
									m,
								);

								let messageTitle = '';
								if (c.unknown) {
									messageTitle = `Unknown - ${c.title}`;
								} else {
									const sender = c.participants.find(
										(x) => x.link_id === m.message_sender_link_id,
									);

									if (sender != null) {
										messageTitle = sender.name;
									} else if (m.message_send_medium === MessagePlatform.system) {
										messageTitle = 'GradBot';
									}
								}

								this.playNotificationAudio();
								const notification = this.webNotifications.create(
									messageTitle,
									{
										body: m.message,
										badge: `${
											getEnvironment().assets
										}/images/gabby-notification-logo.png`,
										icon: `${
											getEnvironment().assets
										}/images/gabby-notification-logo.png`,
										silent: true,
									},
								);

								notification.onclick = (e) => {
									e.preventDefault();
									loadChannel(m.channel_id);
								};

								deliveredNotifications.set(
									getNotificationKey(m.directory, m.channel_id, m.message_id),
									notification.close.bind(notification),
								);
							}
						});
				}
			});

		// Listen for read messages and clear the notifications
		this.messageEventService
			.readMessages$()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((e) => {
				const close = deliveredNotifications.get(
					getNotificationKey(e.directoryId, e.channelId, e.readMessageId),
				);

				if (close != null) {
					// Close doesn't clear Win10 notification tray, that's a browser issue
					close();
				}
			});

		// Toggle the tab title if there's unread messages
		this.userStore.currentUserUid$
			.pipe(
				switchMap((u) => this.directoryStore.directories$(u)),
				map((directories) => {
					let sum = 0;
					if (directories != null) {
						directories
							.filter((x) => x.is_primary)
							.map((x) => x.unread_messages)
							.forEach((x) => {
								sum = sum + x;
							});
					}

					return sum;
				}),
				switchMap((sum) => {
					if (sum > 0) {
						return timer(
							0,
							getEnvironment().settings.views.gabby.main.titleNotificationDelay,
						).pipe(map((c) => [sum, c % 2]));
					} else {
						return of([sum, false]);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(([sum, show]) => {
				// \u{1f534} is 🔴
				if (!show) {
					this.title.setTitle(
						this.title
							.getTitle()
							.replace(/\u{1f534}\(\d*\)/gu, '')
							.trim(),
					);
				} else {
					this.title.setTitle(`\u{1f534}(${sum}) ${this.title.getTitle()}`);
				}
			});
	};

	public playNotificationAudio = () => {
		if (
			this.localStore.get(
				'gabby.main.notification.volume',
				TypedStoreType.NUMBER,
			)
		) {
			this.notificationAudio.volume =
				this.localStore.get(
					'gabby.main.notification.volume',
					TypedStoreType.NUMBER,
				) / 100;

			this.notificationAudio.play();
		}
	};

	get notificationVolume() {
		return this.localStore.get(
			'gabby.main.notification.volume',
			TypedStoreType.NUMBER,
		);
	}
	set notificationVolume(volume: number) {
		this.localStore.set('gabby.main.notification.volume', volume);
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
