import spacetime, { Spacetime } from 'spacetime';
import { firstBy } from 'thenby';
// eslint-disable-next-line no-restricted-imports
import { USA_TIMEZONES } from '../constants/constants';

/* Returns a spacetime object at the calendar date by the requested timezone */
export function calendarDate(st: Spacetime, timezone: string): Spacetime;
/* Returns a spacetime object at the calendar date by the browser timezone */
export function calendarDate(st: Spacetime): Spacetime;
export function calendarDate(st: Spacetime, timezone?: string): Spacetime {
	timezone = timezone || spacetime.now().timezone().name;

	return spacetime(
		[st.year(), st.month(), st.date(), st.hour(), st.minute(), st.second()],
		timezone,
	);
}

/* Returns a date object at the calendar date by the requested timezone */
export function localCalendarDate(d: Date, timezone: string): Date;
/* Returns a date object at the calendar date by the browser timezone */
export function localCalendarDate(d: Date): Date;
export function localCalendarDate(d: Date, timezone?: string): Date {
	return calendarDate(spacetime(d, timezone)).toNativeDate();
}

/* Returns a date object at the calendar date by the requested timezone */
export function localCalendarDateOrNull(d: Date, timezone: string): Date;
/* Returns a date object at the calendar date by the browser timezone */
export function localCalendarDateOrNull(d: Date): Date;
export function localCalendarDateOrNull(d: Date, timezone?: string): Date {
	if (d == null) return null;
	return calendarDate(spacetime(d, timezone)).toNativeDate();
}

export function hasOverlap(
	[d1_start, d1_end]: [start: Date | Spacetime, end: Date | Spacetime],
	[d2_start, d2_end]: [start: Date | Spacetime, end: Date | Spacetime],
	inclusive: boolean = false,
) {
	return (
		(spacetime(d1_start).isEqual(d2_start) &&
			spacetime(d1_end).isEqual(d2_end)) ||
		spacetime(d1_start).isBetween(d2_start, d2_end, inclusive) ||
		spacetime(d1_end).isBetween(d2_start, d2_end, inclusive) ||
		spacetime(d2_start).isBetween(d1_start, d1_end, inclusive) ||
		spacetime(d2_end).isBetween(d1_start, d1_end, inclusive)
	);
}

export function hasAnyOverlap(
	ranges: [start: Date | Spacetime, end: Date | Spacetime][],
	inclusive: boolean = false,
) {
	return ranges.some((r) =>
		ranges.some((rr) => {
			if (r === rr) return false;
			return hasOverlap(r, rr, inclusive);
		}),
	);
}

export function hasOverlapWithOthers(
	targetRange: [start: Date | Spacetime, end: Date | Spacetime],
	otherRanges: [start: Date | Spacetime, end: Date | Spacetime][],
	inclusive: boolean = false,
): boolean {
	return otherRanges.some((or) => {
		return hasOverlap(targetRange, or, inclusive);
	});
}

export function filterTimezoneOptions<T>(
	options: T[],
	getTz?: (i: T) => string,
): T[] {
	getTz = getTz ?? ((i) => i as string);

	const spacetimeTz = Object.keys(spacetime.timezones());

	// Filter to spacetime compatible values then sort by USA tzs first
	return options
		.filter(
			(v) =>
				spacetimeTz.find((sptz) => getTz(v).toLowerCase() === sptz) != null,
		)
		.sort(
			firstBy(
				(v) =>
					USA_TIMEZONES.find(
						(tz) => tz.toLowerCase() === getTz(v).toLowerCase(),
					) != null,
				-1,
			),
		);
}
