@if (messageForm) {
	<form class="gabby-message-builder" [formGroup]="messageForm">
		<div class="modal-header">
			<h1 class="modal-title">Message</h1>
			<button
				class="btn btn-outline-dark"
				title="Close"
				type="button"
				(click)="close()"
			>
				<i aria-hidden="true" class="far fa-times"></i>
			</button>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<label
					class="screen-reader-text"
					for="gabby.chat.messagebuilder.input.textarea"
					>Chat Message</label
				>
				<textarea
					#chatTextarea
					class="form-control"
					formControlName="message"
					id="gabby.chat.messagebuilder.input.textarea"
					placeholder="Enter a message"
					(paste)="messagePaste($event)"
				></textarea>
				<div class="text-end">
					<span
						[ngClass]="
							maxBodyLength -
								(messageForm.controls.message.value?.length || 0) <
							0
								? 'text-danger'
								: 'text-muted'
						"
						>{{
							maxBodyLength - (messageForm.controls.message.value?.length || 0)
						}}
						Remaining</span
					>
				</div>
			</div>
			<div class="form-group">
				<div
					class="gabby-message-builder-attachment-container d-flex flex-wrap"
				>
					@for (att of messageForm.controls.attachments.value; track att) {
						<button
							class="btn p-0 gabby-message-builder-attachment"
							type="button"
							[ngbTooltip]="att.name"
							(click)="removeAttachment(att)"
						>
							<span class="screen-reader-text">
								Remove attachment {{ att.name }}
							</span>
							<ae-display-file [file]="att" />
							<div class="gabby-message-builder-attachment-remover">
								<i aria-hidden="true" class="far fa-times fa-2x"></i>
							</div>
						</button>
					}
				</div>
				<div class="d-flex align-items-center">
					<button
						class="btn btn-link btn-text mt-2"
						id="gabby.chat.messagebuilder.input.addfiles"
						type="button"
						[disabled]="!messageForm.controls.attachments.valid"
						(click)="fileInput.click()"
					>
						<input
							#fileInput
							aria-label="Add Files"
							class="d-none"
							multiple
							type="file"
							[accept]="attachmentExtensionFilter"
							(change)="attachFiles($event, fileInput)"
						/>
						Add Files
					</button>
					<div class="text-danger flex-grow-1 text-end">
						@if (messageForm.controls.attachments.errors?.maxlength) {
							<div>
								Too many files, you can have up to
								{{
									messageForm.controls.attachments.errors?.maxlength
										.requiredLength
								}}
								files
							</div>
						}
						@if (messageForm.controls.attachments.errors?.size) {
							<div>
								{{ messageForm.controls.attachments.errors?.size }}
							</div>
						}
					</div>
				</div>
			</div>
			@if (messageForm.controls.sendSms.enabled) {
				<div class="form-group text-end m-0">
					<ae-input-checkbox formControlName="sendSms">
						Send SMS
					</ae-input-checkbox>
				</div>
			}
		</div>
		<div class="modal-footer">
			<button
				class="btn btn-secondary"
				id="gabby.chat.messagebuilder.cancel"
				type="button"
				(click)="close()"
			>
				Cancel
			</button>
			<button
				class="btn btn-primary"
				id="gabby.chat.messagebuilder.submit"
				type="button"
				[disabled]="!messageForm.valid"
				(click)="submit()"
			>
				Send
			</button>
		</div>
	</form>
}
