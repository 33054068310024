import {
	Directive,
	Input,
	TemplateRef,
	ViewContainerRef,
	ViewRef,
} from '@angular/core';
import { InitializationService } from 'src/lib/services/utility/initialization/initialization.service';

export class IfRoleDirectiveContext {
	public role: string;
	public $implicit: any = null;
}

// Directive
@Directive({
	selector: '[aeIfRole]',
	standalone: true,
})
export class IfRoleDirective {
	private _context: IfRoleDirectiveContext = new IfRoleDirectiveContext();
	private _viewRef: ViewRef;

	@Input() public set aeIfRole(role: string) {
		this._context.$implicit = this._context.role = role;
		this.refreshView();
	}

	constructor(
		private _viewContainer: ViewContainerRef,
		private _templateRef: TemplateRef<any>,
		private _initService: InitializationService,
	) {}

	private refreshView = () => {
		if (this._initService.roles[this._context.role.toLowerCase()]) {
			if (this._viewRef == null) {
				this._viewContainer.clear();

				this._viewRef = this._viewContainer.createEmbeddedView(
					this._templateRef,
					this._context,
				);
			}
		}
	};
}
