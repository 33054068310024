import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export abstract class AbstractActivityBoardResponseModel<T> {
	success: boolean;
	@Expose({ name: 'cached_timestamp' })
	@Transform(dateTransformer)
	cached_date: Date;

	abstract activity_board: T[];
}
