import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { RoleManagerAssignmentsResponseModel } from './role-manager-assignments.model';
import { RoleManagerProgramAssignmentsResponseModel } from './role-manager-program-assignments.model';
import { RoleManagerSpecialAssignmentsArgument } from './role-manager-special-assignments.argument';
import {
	RoleManagerMigrateStudentsArgument,
	RoleManagerStudentLoadModel,
} from './role-manager-student-load.model';
import {
	RoleManagerMemberOptionModel,
	RoleManagerRoles,
	RoleManagerTeamModel,
	RoleManagerTeamUpsertArgument,
} from './role-manager-team.model';

@Injectable({
	providedIn: 'root',
})
export class RoleManagerService {
	constructor(private httpClient: HttpClient) {}

	public viewAssignedGroup = (
		groupId: number,
	): Observable<RoleManagerTeamModel> => {
		return this.httpClient
			.get<any>(`/api/v1/groups/assigned_group/${groupId}/view`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RoleManagerTeamModel, r.assigned_group),
					{ errorCode: '6F0BB80A' },
				),
			);
	};

	public listAssignedGroups = (
		role: RoleManagerRoles,
	): Observable<RoleManagerTeamModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/groups/assigned_group/view_all/${role}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(RoleManagerTeamModel, r.assigned_groups as any[]),
					{ errorCode: '21AA4D95' },
				),
			);
	};

	public getAssignedGroupMemberOptions = (
		role: RoleManagerRoles,
		includeLoad: boolean = false,
		showDeactivate: boolean = false,
	): Observable<RoleManagerMemberOptionModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/groups/assigned_group/member_options/${role}?show_load=${includeLoad}${
					showDeactivate ? '&show_deactive=true' : ''
				}`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							RoleManagerMemberOptionModel,
							r.member_options as any[],
						),
					{ errorCode: 'EC92D583' },
				),
			);
	};

	public assignedGroupAddMembers = (
		groupId: number,
		args: number[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/add_members`, {
				members: args,
			})
			.pipe(mapResponse((r) => r, { errorCode: '5EB182A3' }));
	};

	public assignedGroupRemoveMembers = (
		groupId: number,
		args: number[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/remove_members`, {
				members: args,
			})
			.pipe(mapResponse((r) => r, { errorCode: '340B4AC6' }));
	};

	public assignedGroupAddInstitutes = (
		groupId: number,
		args: number[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/add_institutes`, {
				institutes: args,
			})
			.pipe(mapResponse((r) => r, { errorCode: 'A1764BFA' }));
	};

	public assignedGroupRemoveInstitutes = (
		groupId: number,
		args: number[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/remove_institutes`, {
				institutes: args,
			})
			.pipe(mapResponse((r) => r, { errorCode: '777150A7' }));
	};

	// Upsert group

	public createAssignedGroup = (
		role: RoleManagerRoles,
		args: RoleManagerTeamUpsertArgument,
	): Observable<RoleManagerTeamModel> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/create/${role}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RoleManagerTeamModel, r.assigned_group),
					{ errorCode: '947B8C51' },
				),
			);
	};

	public editAssignedGroup = (
		groupId: number,
		args: RoleManagerTeamUpsertArgument,
	): Observable<RoleManagerTeamModel> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/edit`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RoleManagerTeamModel, r.assigned_group),
					{ errorCode: 'A6400D92' },
				),
			);
	};

	public duplicateAssignedGroup = (
		groupId: number,
		args: RoleManagerTeamUpsertArgument,
	): Observable<RoleManagerTeamModel> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/duplicate`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RoleManagerTeamModel, r.assigned_group),
					{ errorCode: '30AC1D91' },
				),
			);
	};

	public deleteAssignedGroup = (groupId: number): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/groups/assigned_group/${groupId}/delete`, {})
			.pipe(mapResponse((r) => r, { errorCode: 'CA3ABE9A' }));
	};

	// AC Assignment Details
	public getTeamAssignments = (
		uid: number,
		role: RoleManagerRoles,
	): Observable<RoleManagerAssignmentsResponseModel> => {
		return this.httpClient
			.get<any>(`/api/v1/team/${role}/${uid}/list_teams`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RoleManagerAssignmentsResponseModel, r),
					{ errorCode: '6F0BB80A' },
				),
			);
	};

	// Program Assignment Details
	public getTeamInstituteAssignments = (
		id: number,
		role: RoleManagerRoles,
	): Observable<RoleManagerProgramAssignmentsResponseModel> => {
		return this.httpClient
			.get<any>(`/api/v1/team/organization/${id}/${role}/list_teams`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RoleManagerProgramAssignmentsResponseModel, r),
					{ errorCode: '6F0BB80A' },
				),
			);
	};

	// Manage Special Assignments
	public setSpecialAssignments = (
		role: RoleManagerRoles,
		args: RoleManagerSpecialAssignmentsArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/team/organization/${role}/special_assignments`, args)
			.pipe(mapResponse((r) => r, { errorCode: '6F0BB80A' }));
	};

	// Student Load
	public getStudentLoad = (
		id: number,
		role: RoleManagerRoles,
	): Observable<RoleManagerStudentLoadModel> => {
		return this.httpClient
			.get<any>(`/api/v1/team/${role}/${id}/student_load`)
			.pipe(
				mapResponse((r) => plainToInstance(RoleManagerStudentLoadModel, r), {
					errorCode: '00A09A8A',
				}),
			);
	};

	public migrateStudents = (
		role: RoleManagerRoles,
		args: RoleManagerMigrateStudentsArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/team/${role}/migrate_students`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '6F0BB80A',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
