import { OperatorFunction, pipe } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// eslint-disable-next-line no-restricted-imports
import { HTTP_NO_PERMISSION } from 'src/lib/constants/constants';
// eslint-disable-next-line no-restricted-imports
import { hasKey, isNullOrEmptyString } from '../compare';

export enum DefaultErrorMessage {
	Loading,
	Saving,
}

export type MapResponseErrors = string[];

export function mapResponse<T, R>(
	project: (value: T, index: number) => R,
	{
		errorMessage = DefaultErrorMessage.Loading,
		errorCode = '',
		validateSuccess = true,
		checkPreMap = true,
		checkPermission = false,
	}: {
		errorMessage?: DefaultErrorMessage | string;
		errorCode?: string;
		validateSuccess?: boolean;
		checkPreMap?: boolean;
		checkPermission?: boolean;
	} = {},
): OperatorFunction<T, R> {
	if (typeof project !== 'function') {
		throw new TypeError('mapResponse argument is not a function?');
	}

	// eslint-disable-next-line @typescript-eslint/no-this-alias
	const that = this;
	const getCode = () => {
		return isNullOrEmptyString(errorCode) ? '' : `(Error Code: ${errorCode})`;
	};

	switch (errorMessage) {
		case DefaultErrorMessage.Loading: {
			errorMessage = 'There was an issue loading data';
			break;
		}
		case DefaultErrorMessage.Saving: {
			errorMessage = 'There was an issue saving';
			break;
		}
	}

	let count = 0;
	errorMessage = `${errorMessage} ${getCode()}`.trim();

	return pipe(
		catchError((error) => {
			if (checkPermission && error.status === 403) {
				throw [HTTP_NO_PERMISSION];
			} else {
				throw gatherErrors(error, errorMessage as string);
			}
		}),
		map(function (value: T) {
			if (checkPreMap) {
				if (hasKey(value as any, 'success')) {
					if ((value as any).success === false) {
						throw gatherErrors(value, errorMessage as string);
					}
				}
			}

			let result: R;
			try {
				result = project.call(that, value, count++);
			} catch {
				throw [];
			}

			if (hasKey(result as any, 'success') && validateSuccess) {
				if ((result as any).success === false) {
					throw gatherErrors(result, errorMessage as string);
				}
			}
			return result;
		}),
	);
}

function gatherErrors(value: any, customErrorMsg: string): MapResponseErrors {
	const errors = [
		value?.error_messages ??
			value?.error?.error_messages ??
			value?.error?.error ??
			value?.errors ??
			value?.error?.message ??
			[],
	].flat(20);

	errors.unshift(customErrorMsg);
	return errors;
}
