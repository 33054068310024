import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WorkQueueEventsService {
	private _workQueueUpdated = new Subject<number[]>();
	private _workQueueEntitiesUpdated = new Subject<number[]>();
	private _notifyWorkQueueEntityCompleteUpdated = new Subject<
		[queueId: number, entityId: number, complete: Date | null]
	>();

	get workQueueUpdated$() {
		return this._workQueueUpdated;
	}
	get workQueueEntitiesUpdated$() {
		return this._workQueueEntitiesUpdated;
	}
	get notifyWorkQueueEntityCompleteUpdated$() {
		return this._notifyWorkQueueEntityCompleteUpdated;
	}

	public notifyWorkQueueUpdated(queueIds: number[]) {
		this._workQueueUpdated.next(queueIds);
	}
	public notifyWorkQueueEntitiesUpdated(queueIds: number[]) {
		this._workQueueEntitiesUpdated.next(queueIds);
	}
	public notifyWorkQueueEntityCompleteUpdated(
		queueId: number,
		entityId: number,
		complete: Date | null,
	) {
		this._notifyWorkQueueEntityCompleteUpdated.next([
			queueId,
			entityId,
			complete,
		]);
	}
}
