<div class="modal-header">
	<h1 class="modal-title">
		{{ isReschedule ? 'Reschedule' : 'RSVP to' }} Recurring Meetup
	</h1>
	<button
		class="btn btn-outline-white"
		id="sap.meetup.rsvp.recurring.modal.component.times.close"
		title="Close"
		type="button"
		(click)="close()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<ng-container *aeSpinWhile="loading">
		<div class="form-group">
			<label for="sap.meetup.rsvp.recurring.modal.component.recurring_type">
				{{ isReschedule ? "I can't attend" : 'Count me in for' }}</label
			>
			<div id="sap.meetup.rsvp.recurring.modal.component.recurring_type">
				<label
					class="fw-normal d-block"
					for="sap.meetup.rsvp.recurring.modal.component.recurring_type.one_time"
				>
					<input
						id="sap.meetup.rsvp.recurring.modal.component.recurring_type.one_time"
						type="radio"
						value="one_time"
						[formControl]="frequencyCtrl"
					/>
					Just this meetup
				</label>
				<label
					class="fw-normal d-block"
					for="sap.meetup.rsvp.recurring.modal.component.recurring_type.recurring"
				>
					<input
						id="sap.meetup.rsvp.recurring.modal.component.recurring_type.recurring"
						type="radio"
						value="recurring"
						[formControl]="frequencyCtrl"
					/>
					This and associated meetups
				</label>
			</div>
		</div>
	</ng-container>
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="student.meetup.rsvp.recurring.modal.component.cancel"
		type="button"
		(click)="close()"
	>
		Cancel
	</button>

	<button
		class="btn btn-primary"
		id="student.meetup.rsvp.recurring.modal.component.save"
		type="button"
		[disabled]="loading || saving || !frequencyCtrl?.valid"
		(click)="save()"
	>
		Save
	</button>
</div>
