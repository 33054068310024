<!-- Render -->
<div class="pgtable-container" [ngClass]="class">
	<div
		class="skip-links-container"
		pgTableSection="skipLinks"
		id="pgtable.{{ htmlId }}.skip-links"
	>
		<a aeAnchorLink href="#pgtable.{{ htmlId }}.search.table">
			Skip to {{ name }} table interactive controls
		</a>
		<a aeAnchorLink href="#pgtable.{{ htmlId }}.data.table">
			Skip to {{ name }} table data
		</a>
		@if (
			tableConfig.pagination.sizes !== 'infinite' &&
			currentState === TableState.presenting
		) {
			<a aeAnchorLink href="#pgtable.{{ htmlId }}.footer.controls">
				Skip to {{ name }} table pagination controls
			</a>
		}
		<a aeAnchorLink href="#pgtable.{{ htmlId }}.skip-past-table-anchor">
			Skip past {{ name }} table
		</a>
	</div>

	<ng-container [ngTemplateOutlet]="pgTableControls" />
	@if (
		tableConfig.selection.getAllKeys$ != null &&
		selectedItems?.length >= selectedPageSize
	) {
		<div class="alert alert-info text-center rounded mt-2 p-2">
			<span
				>{{ selectedItems.length }} records currently selected.
				@if (!allKeysSelected) {
					@if (tc_Selection_IsPageSelected()) {
						<span> This page is fully selected. </span>
					}
					<button
						class="btn btn-text text-decoration-underline no-color"
						type="button"
						(click)="tc_Selection_SelectAll()"
					>
						Select all filtered records
					</button>
				} @else {
					<span>All filtered records were selected.</span>
				}
				&nbsp;
			</span>
		</div>
	}

	@if (currentState !== TableState.loading && getPageActive && hasFirstResult) {
		<div class="alert alert-warning text-center rounded mt-2 p-2 d-print-none">
			Records are still loading. Showing partial results.<i
				class="far fa-spinner fa-spin ms-2"
			></i>
		</div>
	}

	@if (undersizedColumnsNotification) {
		<div
			class="alert alert-info text-center rounded mt-2 p-2 d-flex align-items-center btn-icon-highlight-zone d-print-none"
		>
			<div class="flex-grow-1">
				<div>Some columns may be too small to be visible.</div>
				<button
					class="btn text-decoration-underline"
					title="Column Settings"
					type="button"
					(click)="tc_Configuration_OpenModal()"
				>
					Adjust Columns
				</button>
			</div>
			<button
				class="btn btn-icon"
				title="Hide this message"
				type="button"
				(click)="suppressUndersizedColumnsNotification()"
			>
				<i class="far fa-close"></i>
			</button>
		</div>
	}

	<div class="table-sticky-wrapper">
		@if (tc_Search_Enabled()) {
			<form [formGroup]="tableConfig.search.form">
				<ng-container [ngTemplateOutlet]="pgTable" />
			</form>
		} @else {
			<ng-container [ngTemplateOutlet]="pgTable" />
		}
	</div>

	<!--  -->
	<!--  -->
	<!-- FOOTER FOOTER FOOTER -->
	<!--  -->
	<!--  -->

	<!-- Custom Footer Insert -->
	@if (customFooterTemplate.first != null) {
		<ng-container [ngTemplateOutlet]="customFooterTemplate.first.template" />
	}

	<!-- Selection Status Display -->
	@if (
		tc_Selection_Enabled() && tableConfig.pagination.selectorRange !== 'minimal'
	) {
		<div
			class="row align-items-center d-print-none"
			pgTableSection="selectionControls"
		>
			<div class="col-md-6 text-start">
				<span>{{ selectedItems.length }} Items Selected</span>
			</div>
			<div class="col-md-6 text-end">
				<button
					class="btn btn-primary btn-sm"
					type="button"
					id="pgtable.{{ htmlId }}.clearselection"
					[ngClass]="{ disabled: selectedItems.length === 0 }"
					(click)="tc_Selection_Reset()"
				>
					Clear Selection
				</button>
			</div>
		</div>
	}

	<!-- Pagination Controls -->
	@if (currentState === TableState.presenting) {
		@if (tableConfig.pagination.sizes !== 'infinite') {
			<div
				class="pagination-controls mt-3"
				pgTableSection="paginationControls"
				tabindex="-1"
				id="pgtable.{{ htmlId }}.footer.controls"
			>
				<div class="flex-grow-1">
					<nav aria-label="Pagination" class="d-print-none">
						<ul class="pagination mb-0" role="menu">
							@if (tableConfig.pagination.selectorRange !== 'minimal') {
								<li class="page-item" role="menuitem">
									<button
										class="page-link"
										title="First Page"
										type="button"
										id="pgtable.{{ htmlId }}.pagination.seek.first"
										(click)="seek(1)"
									>
										<i aria-hidden="true" class="far fa-chevron-left"></i>
										<i aria-hidden="true" class="far fa-chevron-left"></i>
									</button>
								</li>
							}
							<li class="page-item" role="menuitem">
								<button
									class="page-link"
									title="Previous Page"
									type="button"
									id="pgtable.{{ htmlId }}.pagination.seek.previous"
									(click)="seek(currentPage - 1)"
								>
									<i aria-hidden="true" class="far fa-chevron-left fa-fw"></i>
								</button>
							</li>
							@for (n of getPaginateButtons(); track n) {
								<li
									class="page-item"
									[attr.aria-selected]="n === currentPage"
									[ngClass]="n === currentPage ? 'active' : ''"
								>
									<button
										class="page-link"
										type="button"
										id="pgtable.{{ htmlId }}.pagination.seek.{{ n }}"
										(click)="seek(n)"
									>
										<span class="screen-reader-text">Page </span>{{ n }}
									</button>
								</li>
							}
							<li class="page-item" role="menuitem">
								<button
									class="page-link"
									title="Next Page"
									type="button"
									id="pgtable.{{ htmlId }}.pagination.seek.next"
									(click)="seek(currentPage + 1)"
								>
									<i aria-hidden="true" class="far fa-chevron-right fa-fw"></i>
								</button>
							</li>
							@if (tableConfig.pagination.selectorRange !== 'minimal') {
								<li class="page-item" role="menuitem">
									<button
										class="page-link"
										title="Last Page"
										type="button"
										id="pgtable.{{ htmlId }}.pagination.seek.last"
										(click)="seek(totalPages)"
									>
										<i aria-hidden="true" class="far fa-chevron-right"></i>
										<i aria-hidden="true" class="far fa-chevron-right"></i>
									</button>
								</li>
							}
						</ul>
					</nav>
				</div>
				<div class="flex-grow-0 flex-shrinkable page-count">
					<span
						[ngClass]="{
							'fs-sm': tableConfig.pagination.selectorRange === 'minimal',
						}"
						title="Viewing {{ viewingLow + 1 }}-{{ viewingHigh }} of {{
							totalCount
						}}"
					>
						Viewing {{ viewingLow + 1 }}-{{ viewingHigh }} of {{ totalCount }}
					</span>
				</div>
				@if (tableConfig.pagination.sizes.length > 1) {
					<div class="page-size-select ps-2 d-print-none">
						<label
							class="screen-reader-text"
							for="pgtable.{{ htmlId }}.pagination.pagesize"
							>Page Size</label
						>
						<select
							class="form-select form-select-sm"
							title="Selected Page Size"
							id="pgtable.{{ htmlId }}.pagination.pagesize"
							[(ngModel)]="selectedPageSize"
							(ngModelChange)="selectedPageSizeChanged()"
						>
							@for (size of tableConfig.pagination.sizes; track size) {
								<option [ngValue]="size">
									{{ size }}
								</option>
							}
						</select>
					</div>
				}
			</div>
		}
		<div class="skip-links-container skip-links-bottom-right">
			<a aeAnchorLink href="#pgtable.{{ htmlId }}.skip-links">
				Back to table skip links
			</a>
		</div>
	}
	<div tabindex="-1" id="pgtable.{{ htmlId }}.skip-past-table-anchor"></div>
</div>

<!-- Templates -->

<!--  -->
<!--  -->
<!-- TABLE CONTROLS -->
<!--  -->
<!--  -->
<ng-template #pgTableControls>
	<div
		class="pgtable-control-container d-print-none"
		pgTableSection="optionsControls"
	>
		<div class="pgtable-controls-custom">
			<!-- Custom Port -->
			@if (customControlsTemplate.first != null) {
				<ng-container
					[ngTemplateOutlet]="customControlsTemplate.first.template"
				/>
			}
		</div>
		<div class="pgtable-controls-configuration-container">
			<!-- Custom Filters -->
			<div class="pgtable-controls-filters">
				@for (filter of customFiltersTemplate; track filter) {
					<ng-container [ngTemplateOutlet]="filter.template" />
				}
			</div>

			<div class="pgtable-controls-configuration">
				<!-- Sort Dropdown -->
				@if (userPreferences.sortMinimized) {
					<div
						autoClose="outside"
						class="d-inline-block"
						container="body"
						ngbDropdown
						placement="bottom-right"
					>
						<button
							class="btn btn-primary"
							ngbDropdownToggle
							type="button"
							[disabled]="currentState === TableState.loading"
							id="pgtable.{{ htmlId }}.configcontrols.sort.open"
						>
							Change Sort
						</button>
						<ul ngbDropdownMenu>
							@for (column of columns_Sortable(); track column) {
								<li>
									<button
										class="dropdown-item"
										type="button"
										[ngClass]="{ active: column.columnId === sortKey }"
										(click)="setSort(column.columnId)"
									>
										<i
											aria-hidden="true"
											class="me-1"
											[ngClass]="
												column.columnId !== sortKey
													? 'fas fa-sort'
													: sortDescending
														? 'fas fa-sort-down'
														: 'fas fa-sort-up'
											"
										></i>
										<ng-container
											[ngTemplateOutlet]="column.headerTemplate.template"
										/>
									</button>
								</li>
							}
						</ul>
					</div>
				}

				<!-- Actions Dropdown -->
				@if (tableConfig.actions?.length > 0) {
					<div
						class="d-inline-block"
						container="body"
						ngbDropdown
						placement="bottom-right"
					>
						<button
							class="btn btn-outline-primary"
							ngbDropdownToggle
							type="button"
							[disabled]="currentState === TableState.loading"
							id="pgtable.{{ htmlId }}.configcontrols.actions.open"
							(click)="tc_Actions_CalculateTooltips()"
						>
							Bulk Actions
						</button>
						<ul ngbDropdownMenu>
							@for (
								actionDefinition of tc_Actions_GetVisibleActions();
								track actionDefinition
							) {
								<li
									container="body"
									[ngbTooltip]="tc_Actions_ActionTooltip(actionDefinition)"
								>
									<button
										class="dropdown-item"
										type="button"
										[disabled]="tc_Actions_ActionDisabled(actionDefinition)"
										(click)="tc_Actions_ExecuteAction(actionDefinition)"
									>
										{{ actionDefinition.name }}
									</button>
								</li>
							}
						</ul>
					</div>
				}

				<!-- Configuration Dropdown -->
				@if (
					tableConfig.controls.sticky.interactable ||
					tableConfig.controls.margins.interactable ||
					tableConfig.controls.sortMinimized.interactable ||
					tableConfig.controls.smallText.interactable ||
					tableConfig.controls.wrapText.interactable ||
					tableConfig.controls.sticky.interactable
				) {
					<div
						#configTableDropdown="ngbDropdown"
						class="d-inline-block"
						container="body"
						ngbDropdown
						placement="bottom-right"
						[autoClose]="'outside'"
					>
						<button
							#configTableBtnTooltip="ngbTooltip"
							class="btn"
							ngbDropdownToggle
							placement="left"
							title="Table Settings"
							tooltipClass="text-nowrap tooltip-info"
							triggers="manual"
							type="button"
							[autoClose]="false"
							id="pgtable.{{
								htmlId
							}}.configcontrols.table-configuration-editor"
							[ngClass]="[
								notification ? 'btn-info-flashing text-white' : '',
								tableConfig.controls.columns.class,
							]"
							[ngbTooltip]="newColumnNotice"
						>
							<i aria-hidden="true" class="fas fa-cog"></i>
							@if (tableConfig.controls.columns.showText) {
								<span>Table Settings</span>
							}
						</button>
						<ng-template #newColumnNotice>
							<div>New Columns</div>
						</ng-template>
						<ul ngbDropdownMenu>
							@if (
								tableConfig.controls.sticky.interactable ||
								tableConfig.controls.margins.interactable ||
								tableConfig.controls.sortMinimized.interactable ||
								tableConfig.controls.smallText.interactable ||
								tableConfig.controls.wrapText.interactable
							) {
								@if (activeRealmName) {
									<li>
										<div class="dropdown-header fs-5">
											{{ activeRealmName }}
										</div>
									</li>
								}
								<li>
									<h6 class="dropdown-header">Table Settings</h6>
								</li>
								@if (tableConfig.controls.margins.interactable) {
									<li>
										<div class="dropdown-item-text btn-group-sm">
											<label
												class="btn m-0"
												title="Large margins"
												[aeFocusTransfer]="largeMarginInput"
												[ngClass]="{ 'btn-primary': largeMarginInput.checked }"
											>
												<input
													#largeMarginInput
													class="screen-reader-text"
													type="radio"
													[formControl]="userPrefForm.controls.marginSize"
													[name]="htmlId + '.pref-controls.margin-size'"
													[value]="PaginationTableMarginSize.large"
													(keydown.enter)="largeMarginInput.click()"
												/>
												<span aria-hidden="true" class="fa-stack">
													<i
														class="fas fa-horizontal-rule fa-stack-1x"
														[style.top]="'-10%'"
													></i>
													<i
														class="fas fa-horizontal-rule fa-stack-1x"
														[style.top]="'10%'"
													></i>
												</span>
											</label>
											<label
												class="btn m-0"
												title="Normal margins"
												[aeFocusTransfer]="mediumMarginInput"
												[ngClass]="{ 'btn-primary': mediumMarginInput.checked }"
											>
												<input
													#mediumMarginInput
													class="screen-reader-text"
													type="radio"
													[formControl]="userPrefForm.controls.marginSize"
													[name]="htmlId + '.pref-controls.margin-size'"
													[value]="PaginationTableMarginSize.medium"
													(keydown.enter)="mediumMarginInput.click()"
												/>
												<span aria-hidden="true" class="fa-stack">
													<i
														class="far fa-horizontal-rule fa-stack-1x"
														[style.top]="'-15%'"
													></i>
													<i
														class="far fa-horizontal-rule fa-stack-1x"
														[style.top]="'0%'"
													></i>
													<i
														class="far fa-horizontal-rule fa-stack-1x"
														[style.top]="'15%'"
													></i>
												</span>
											</label>
											<label
												class="btn m-0"
												title="Condensed margins"
												[aeFocusTransfer]="smallMarginInput"
												[ngClass]="{ 'btn-primary': smallMarginInput.checked }"
											>
												<input
													#smallMarginInput
													class="screen-reader-text"
													type="radio"
													[formControl]="userPrefForm.controls.marginSize"
													[name]="htmlId + '.pref-controls.margin-size'"
													[value]="PaginationTableMarginSize.small"
													(keydown.enter)="smallMarginInput.click()"
												/>
												<span aria-hidden="true" class="fa-stack">
													<i
														class="fal fa-horizontal-rule fa-stack-1x"
														[style.top]="'-20%'"
													></i>
													<i
														class="fal fa-horizontal-rule fa-stack-1x"
														[style.top]="'-10%'"
													></i>
													<i
														class="fal fa-horizontal-rule fa-stack-1x"
														[style.top]="'0%'"
													></i>
													<i
														class="fal fa-horizontal-rule fa-stack-1x"
														[style.top]="'10%'"
													></i>
													<i
														class="fal fa-horizontal-rule fa-stack-1x"
														[style.top]="'20%'"
													></i>
												</span>
											</label>
											<label
												class="btn m-0"
												title="No margins"
												[aeFocusTransfer]="zeroMarginInput"
												[ngClass]="{ 'btn-primary': zeroMarginInput.checked }"
											>
												<input
													#zeroMarginInput
													class="screen-reader-text"
													type="radio"
													[formControl]="userPrefForm.controls.marginSize"
													[name]="htmlId + '.pref-controls.margin-size'"
													[value]="PaginationTableMarginSize.zero"
													(keydown.enter)="zeroMarginInput.click()"
												/>
												<span aria-hidden="true" class="fa-stack">
													<i
														class="fas fa-arrows-to-dotted-line fa-stack-1x"
													></i>
												</span>
											</label>
										</div>
									</li>
								}
								@if (tableConfig.controls.sticky.interactable) {
									<li>
										<label
											class="dropdown-item m-0"
											title="Floating Header"
											[aeFocusTransfer]="stickyHeaderInput"
											[ngClass]="{ active: stickyHeaderInput.checked }"
										>
											<input
												#stickyHeaderInput
												class="screen-reader-text"
												type="checkbox"
												[formControl]="userPrefForm.controls.stickyHeader"
												(keydown.enter)="stickyHeaderInput.click()"
											/>
											<span class="fa-stack fs-sm">
												<i
													aria-hidden="true"
													class="fas fa-arrow-alt-from-top fa-stack-1x"
												></i>
											</span>
											Floating Header
										</label>
									</li>
								}

								@if (
									tableConfig.controls.rowActions.interactable &&
									tc_RowActions_Enabled()
								) {
									<li>
										<label
											class="dropdown-item m-0"
											[aeFocusTransfer]="actionsColumnInput"
											[ngClass]="{ active: actionsColumnInput.checked }"
											title="Row Actions {{
												actionsColumnInput.checked ? 'Left' : 'Right'
											}}"
										>
											<input
												#actionsColumnInput
												class="screen-reader-text"
												type="checkbox"
												[formControl]="userPrefForm.controls.leftRowActions"
												(keydown.enter)="actionsColumnInput.click()"
											/>
											<span class="fa-stack fs-sm">
												<i
													aria-hidden="true"
													class="fas fa-list-ul fa-stack-1x"
													[ngClass]="{
														'fa-flip-horizontal': !actionsColumnInput.checked,
													}"
												></i>
											</span>
											Row Actions
											{{ actionsColumnInput.checked ? 'Left' : 'Right' }}
										</label>
									</li>
								}

								@if (tableConfig.controls.sortMinimized.interactable) {
									<li>
										<label
											class="dropdown-item m-0"
											title="Minimize Sort Controls"
											[aeFocusTransfer]="sortMinimizedInput"
											[ngClass]="{ active: sortMinimizedInput.checked }"
										>
											<input
												#sortMinimizedInput
												class="screen-reader-text"
												type="checkbox"
												[formControl]="userPrefForm.controls.sortMinimized"
												(keydown.enter)="sortMinimizedInput.click()"
											/>
											<span class="fa-stack fs-sm">
												<i
													aria-hidden="true"
													class="fa-solid fa-sort fa-stack-1x"
												></i>
												<i
													aria-hidden="true"
													class="fal fa-square fa-stack-2x"
												></i>
											</span>
											Minimize Sort Controls
										</label>
									</li>
								}
								@if (tableConfig.controls.smallText.interactable) {
									<li>
										<label
											class="dropdown-item m-0"
											title="Small Text"
											[aeFocusTransfer]="smallTextInput"
											[ngClass]="{ active: smallTextInput.checked }"
										>
											<input
												#smallTextInput
												class="screen-reader-text"
												type="checkbox"
												[formControl]="userPrefForm.controls.smallText"
												(keydown.enter)="smallTextInput.click()"
											/>
											<span class="fa-stack fs-sm">
												<i
													aria-hidden="true"
													class="fa-solid fa-text-size fa-stack-1x"
												></i>
											</span>
											Small Text
										</label>
									</li>
								}
								@if (tableConfig.controls.wrapText.interactable) {
									<li>
										<label
											class="dropdown-item m-0"
											title="Wrap Text"
											[aeFocusTransfer]="wrapTextInput"
											[ngClass]="{ active: wrapTextInput.checked }"
										>
											<input
												#wrapTextInput
												class="screen-reader-text"
												type="checkbox"
												[formControl]="userPrefForm.controls.wrapText"
												(keydown.enter)="wrapTextInput.click()"
											/>
											<span class="fa-stack fs-sm">
												<i
													aria-hidden="true"
													class="fa-solid fa-turn-down-left fa-stack-1x"
												></i>
											</span>
											Wrap Text
										</label>
									</li>
								}
								@if (tableConfig.controls.columns.interactable) {
									<li>
										<hr class="dropdown-divider" />
									</li>
								}
							}
							@if (userPreferences.savedConfigurations?.length > 0) {
								<li>
									<h6 class="dropdown-header">Saved Groups</h6>
								</li>
								<li>
									@for (
										config of userPreferences.savedConfigurations;
										track config
									) {
										<button
											class="dropdown-item"
											role="option"
											type="button"
											[attr.aria-selected]="
												config.configId ===
												userPreferences.activeConfiguration.configId
													? 'true'
													: 'false'
											"
											[ngClass]="{
												active:
													config.configId ===
													userPreferences.activeConfiguration.configId,
											}"
											(click)="load_SavedConfiguration(config)"
										>
											{{ config.name }}
										</button>
									}
								</li>
								<li>
									<hr class="dropdown-divider" />
								</li>
							}
							@if (tableConfig.controls.columns.interactable) {
								<li>
									<button
										class="dropdown-item"
										type="button"
										[ngClass]="
											notification ? 'btn-info-flashing text-white' : ''
										"
										(click)="
											tc_Configuration_OpenModal(); configTableDropdown.close()
										"
									>
										<i
											aria-hidden="true"
											class="fa-regular fa-table-columns fa-fw"
										></i>
										Column Settings
									</button>
								</li>
							}
						</ul>
					</div>
				}

				<!-- Search Dropdown -->
				@if (tc_Search_Enabled() && tableConfig.controls.search.interactable) {
					<div
						class="d-inline-block"
						container="body"
						ngbDropdown
						placement="bottom-right"
					>
						<button
							class="btn"
							ngbDropdownToggle
							title="Search Options"
							type="button"
							id="pgtable.{{ htmlId }}.configcontrols.search.options.open"
							[ngClass]="
								tc_Search_HasAutoLoad()
									? tableConfig.controls.search.pinnedSearchClass
									: tableConfig.controls.search.class
							"
						>
							<i aria-hidden="true" class="fas fa-search"></i>
							@if (tableConfig.controls.search.showText) {
								<span> Search Options </span>
							}
						</button>
						<ul ngbDropdownMenu>
							<li>
								<button
									class="dropdown-item"
									type="button"
									(click)="tc_Search_OpenModal()"
								>
									<i
										aria-hidden="true"
										class="fa-regular fa-folder-magnifying-glass fa-fw"
									></i>
									Advanced Search
								</button>
							</li>
							<li>
								<hr class="dropdown-divider" />
							</li>
							@if (userPreferences.savedSearches?.length > 0) {
								<li><h6 class="dropdown-header">Saved Searches</h6></li>
								@for (
									savedSearch of userPreferences.savedSearches;
									track savedSearch
								) {
									<li>
										<button
											class="dropdown-item d-flex"
											title="Load Search"
											type="button"
											(click)="tc_Search_Load(savedSearch)"
										>
											<div class="flex-grow-1">
												{{ savedSearch.name }}
											</div>
											<button
												class="btn btn-icon"
												title="Pin Saved Search"
												type="button"
												(click)="tc_Search_TogglePin(savedSearch, $event)"
											>
												@if (!savedSearch.autoLoad) {
													<i
														aria-hidden="true"
														class="fal fa-thumbtack fa-rotate-315 fa-fw"
													></i>
												}
												@if (savedSearch.autoLoad) {
													<i
														aria-hidden="true"
														class="fas fa-thumbtack fa-fw"
													></i>
												}
											</button>
											<button
												class="btn btn-icon"
												title="Delete Search"
												type="button"
												(click)="tc_Search_Delete(savedSearch, $event)"
											>
												<i aria-hidden="true" class="fas fa-trash"></i>
											</button>
										</button>
									</li>
								}
								<li>
									<hr class="dropdown-divider" />
								</li>
							}
							<li>
								<button
									class="dropdown-item"
									type="button"
									(click)="tc_Search_Save()"
								>
									<i
										aria-hidden="true"
										class="fa-regular fa-floppy-disk fa-fw"
									></i>
									Save Search
								</button>
							</li>
							<li>
								<button
									class="dropdown-item"
									type="button"
									(click)="tc_Search_Reset()"
								>
									<i
										aria-hidden="true"
										class="fa-regular fa-arrow-rotate-left fa-fw"
									></i>
									Reset Search
								</button>
							</li>
						</ul>
					</div>
				}

				<!-- Export Button -->
				@if (tableConfig.controls.export.interactable) {
					<button
						class="btn"
						title="Export to CSV"
						type="button"
						[disabled]="currentState !== TableState.presenting || getPageActive"
						[ngClass]="tableConfig.controls.export.class"
						(click)="tc_Export_OpenModal()"
					>
						<i aria-hidden="true" class="fas fa-file-export"></i>
						@if (tableConfig.controls.export.showText) {
							<span> Export to CSV </span>
						}
					</button>
				}

				<!-- Refresh Button -->
				@if (tableConfig.controls.refresh.interactable) {
					<button
						class="btn"
						title="Refresh Table"
						type="button"
						[disabled]="currentState === TableState.loading || getPageActive"
						[ngClass]="tableConfig.controls.refresh.class"
						(click)="tc_Refresh_Request()"
					>
						<i
							aria-hidden="true"
							class="fas fa-sync-alt"
							[ngClass]="{ 'fa-spin': getPageActive }"
						></i>
						@if (tableConfig.controls.refresh.showText) {
							<span> Refresh Table </span>
						}
					</button>
				}
			</div>
		</div>
	</div>
</ng-template>

<!--  -->
<!--  -->
<!-- TABLE -->
<!--  -->
<!--  -->

<ng-template #pgTable>
	<div
		class="table-sticky-container"
		[ngClass]="{ 'pagination-table-wrap-text': userPreferences.wrapText }"
	>
		<div
			#tableScrollContainer
			class="table-scroll-container"
			[ngClass]="{
				'sticky-header-table': userPreferences.stickyHeader,
			}"
		>
			<ng-container [ngTemplateOutlet]="pgTableHeader" />
		</div>
		<div #tableScrollContainer class="table-scroll-container">
			<ng-container [ngTemplateOutlet]="pgTableBody" />
		</div>
	</div>
</ng-template>

<!--  -->
<!--  -->
<!-- Header Table -->
<!--  -->
<!--  -->
<ng-template #pgTableHeader>
	<table
		class="pgtable-table table header-table table-border-bottom"
		pgTableSection="headerTable"
		tabindex="-1"
		id="pgtable.{{ htmlId }}.search.table"
		[ngClass]="{
			'table-md':
				userPreferences.marginSize === PaginationTableMarginSize.medium,
			'table-sm':
				userPreferences.marginSize === PaginationTableMarginSize.small,
			'table-no-margin':
				userPreferences.marginSize === PaginationTableMarginSize.zero,
			'table-bordered':
				userPreferences.marginSize === PaginationTableMarginSize.zero,
			'table-small-text': userPreferences.smallText,
		}"
		[style.min-width]="minWidth"
	>
		<caption class="screen-reader-text">
			Interactive Controls for
			{{
				name
			}}
		</caption>

		<!--  -->
		<!--  -->
		<!-- HEADER HEADER HEADER -->
		<!--  -->
		<!--  -->
		<thead>
			<tr pgTableSection="headerRow">
				<!-- Selection Combo -->
				@if (tc_Selection_Enabled()) {
					<th class="selection-column d-print-none" scope="col">
						<ng-container [ngTemplateOutlet]="pgTableSelectionDropdown" />
					</th>
				}

				<!-- Row Actions -->
				@if (tc_RowActions_Enabled() && userPreferences.leftRowActions) {
					<ng-container [ngTemplateOutlet]="rowActionsColumnHeader" />
				}

				<!-- Columns -->
				@for (
					staticColumn of staticColumns;
					track columns_StaticTrackBy($index, staticColumn)
				) {
					<th
						#headerCellElement
						scope="col"
						id="pgtable.{{ htmlId }}.header.column.{{
							staticColumn.col.columnId
						}}"
						[ngClass]="{ 'd-none': !staticColumn.visible }"
						[style.width]="columns_GetWidth(staticColumn.col)"
					>
						<div
							class="pagination-table-header"
							container="body"
							[ngbTooltip]="
								staticColumn.col.headerTemplate.description ||
								staticColumn.col.headerTemplate.template
							"
							[openDelay]="300"
						>
							<div
								class="header-template-cell"
								id="pgtable.{{ htmlId }}.header.column.{{
									staticColumn.col.columnId
								}}.title"
								[ngClass]="staticColumn.col.headerTemplate.cellClass"
							>
								<ng-container
									[ngTemplateOutlet]="staticColumn.col.headerTemplate.template"
								/>
							</div>
							@if (staticColumn.col.alternateViews) {
								<div class="header-control d-print-none">
									<span
										class="screen-reader-text"
										id="pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.alt-display-toggle-label"
									>
										{{
											columns_alternateDisplayActive(staticColumn.col.columnId)
												? staticColumn.col.alternateViews[1]
												: staticColumn.col.alternateViews[0]
										}}
									</span>
									<button
										class="btn btn-icon btn-link text-decoration-none"
										type="button"
										attr.aria-labelledby="pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.title pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.alt-display-toggle-label"
										id="pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.alt-display-toggle"
										title="{{
											columns_alternateDisplayActive(staticColumn.col.columnId)
												? staticColumn.col.alternateViews[1]
												: staticColumn.col.alternateViews[0]
										}}"
										(click)="
											columns_toggleAlternateDisplay(staticColumn.col.columnId)
										"
									>
										<i
											aria-hidden="true"
											[ngClass]="
												columns_alternateDisplayActive(
													staticColumn.col.columnId
												)
													? 'fas fa-toggle-on'
													: 'far fa-toggle-off'
											"
										></i>
									</button>
								</div>
							}
							@if (staticColumn.col.sortable) {
								<div
									class="header-control d-print-none"
									[ngClass]="{
										'd-none':
											userPreferences.sortMinimized &&
											staticColumn.col.columnId !== sortKey,
									}"
								>
									<span
										class="screen-reader-text"
										id="pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.sorttoggle-label"
									>
										{{
											staticColumn.col.columnId !== sortKey
												? 'Sort'
												: sortDescending
													? 'Sorted Descending'
													: 'Sorted Ascending'
										}}
									</span>
									<button
										class="btn btn-icon btn-link text-decoration-none"
										type="button"
										attr.aria-labelledby="pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.title pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.sorttoggle-label"
										id="pgtable.{{ htmlId }}.header.column.{{
											staticColumn.col.columnId
										}}.sorttoggle"
										title="{{
											staticColumn.col.columnId !== sortKey
												? 'Sort'
												: sortDescending
													? 'Sorted Descending'
													: 'Sorted Ascending'
										}}"
										(click)="setSort(staticColumn.col.columnId)"
									>
										<i
											aria-hidden="true"
											[ngClass]="
												staticColumn.col.columnId !== sortKey
													? 'fas fa-sort'
													: sortDescending
														? 'fas fa-sort-down'
														: 'fas fa-sort-up'
											"
										></i>
									</button>
								</div>
							}
							@if (tableConfig.controls.columns.interactable) {
								<div
									aria-hidden="true"
									class="header-resize-control"
									tabindex="-1"
									(dblclick)="
										tc_Configuration_RemoveColumnResize(staticColumn.col)
									"
									(mousedown)="
										tc_Configuration_ColumnResizeStart(
											$event,
											headerCellElement,
											staticColumn.col
										)
									"
								></div>
							}
						</div>
					</th>
				}

				<!-- Row Actions -->
				@if (tc_RowActions_Enabled() && !userPreferences.leftRowActions) {
					<ng-container [ngTemplateOutlet]="rowActionsColumnHeader" />
				}
			</tr>
		</thead>

		<!--  -->
		<!--  -->
		<!-- SEARCH -->
		<!--  -->
		<!--  -->
		@if (tc_Search_Enabled()) {
			<tbody class="search-body d-print-none fs-normal">
				<tr pgTableSection="searchRow">
					<!-- Selection -->
					@if (tc_Selection_Enabled()) {
						<td class="selection-column d-print-none"></td>
					}
					<!-- Row Actions -->
					@if (tc_RowActions_Enabled() && userPreferences.leftRowActions) {
						<td
							class="actions-column-size actions-column-size-expanded-{{
								rowActionsTemplate.size
							}} d-print-none"
							[ngClass]="{
								'actions-column-size-expanded':
									userPreferences.expandRowActions,
							}"
						></td>
					}
					<!-- Columns -->
					@for (
						staticColumn of staticColumns;
						track columns_StaticTrackBy($index, staticColumn)
					) {
						<td
							id="pgtable.{{ htmlId }}.search.column.{{
								staticColumn.col.columnId
							}}"
							[ngClass]="staticColumn.col.searchTemplate?.cellClass"
							[style.display]="staticColumn.visible ? '' : 'none'"
							[style.width]="columns_GetWidth(staticColumn.col)"
						>
							@if (staticColumn.col.searchTemplate != null) {
								<ng-container
									[ngTemplateOutlet]="staticColumn.col.searchTemplate.template"
									[ngTemplateOutletContext]="{
										$implicit: tableConfig.search.form,
									}"
								/>
							}
						</td>
					}
					<!-- Row Actions -->
					@if (tc_RowActions_Enabled() && !userPreferences.leftRowActions) {
						<td
							class="actions-column-size actions-column-size-expanded-{{
								rowActionsTemplate.size
							}} d-print-none"
							[ngClass]="{
								'actions-column-size-expanded':
									userPreferences.expandRowActions,
							}"
						></td>
					}
				</tr>
			</tbody>
		}
	</table>
	<div class="skip-links-container">
		<a aeAnchorLink href="#pgtable.{{ htmlId }}.skip-links">
			Back to table skip links
		</a>
	</div>
</ng-template>

<!--  -->
<!--  -->
<!-- Body Table -->
<!--  -->
<!--  -->
<ng-template #pgTableBody>
	<table
		#pgTableElement
		class="pgtable-table table body-table"
		pgTableSection="bodyTable"
		tabindex="-1"
		id="pgtable.{{ htmlId }}.data.table"
		[ngClass]="{
			'table-md':
				userPreferences.marginSize === PaginationTableMarginSize.medium,
			'table-sm':
				userPreferences.marginSize === PaginationTableMarginSize.small,
			'table-no-margin':
				userPreferences.marginSize === PaginationTableMarginSize.zero,
			'table-bordered':
				userPreferences.marginSize === PaginationTableMarginSize.zero,
			'table-small-text': userPreferences.smallText,
		}"
		[style.min-width]="minWidth"
	>
		<caption class="screen-reader-text">
			{{
				name
			}}
		</caption>

		<!-- Assistive Header -->
		<thead>
			<tr class="assistive-header-row" pgTableSection="assistiveRow">
				<!-- Selection Combo -->
				@if (tc_Selection_Enabled()) {
					<th class="selection-column d-print-none" scope="col">
						Row Selected
					</th>
				}

				<!-- Row Actions -->
				@if (tc_RowActions_Enabled() && userPreferences.leftRowActions) {
					<ng-container [ngTemplateOutlet]="assistiveHeaderRowActions" />
				}

				<!-- Columns -->
				@for (
					staticColumn of staticColumns;
					track columns_StaticTrackBy($index, staticColumn)
				) {
					<th
						scope="col"
						[attr.pgTableHeader]="staticColumn.col.columnId"
						id="pgtable.{{ htmlId }}.header.column.descriptive-header.{{
							staticColumn.col.columnId
						}}"
						[ngClass]="{ 'd-none': !staticColumn.visible }"
						[style.width]="columns_GetWidth(staticColumn.col)"
					>
						<ng-container
							[ngTemplateOutlet]="staticColumn.col.headerTemplate.template"
						/>
					</th>
				}

				<!-- Row Actions -->
				@if (tc_RowActions_Enabled() && !userPreferences.leftRowActions) {
					<ng-container [ngTemplateOutlet]="assistiveHeaderRowActions" />
				}

				<!-- Row Actions Template -->
				<ng-template #assistiveHeaderRowActions>
					<th
						scope="col"
						class="actions-column-size actions-column-size-expanded-{{
							rowActionsTemplate.size
						}} d-print-none"
						[ngClass]="{
							'actions-column-size-expanded': userPreferences.expandRowActions,
						}"
					>
						Row Actions
					</th>
				</ng-template>
			</tr>
		</thead>

		@if (currentState === TableState.presenting) {
			<tbody class="content-body" pgTableSection="loadingCover"></tbody>
		} @else {
			<tbody>
				<tr class="table-state-row">
					<td
						[colSpan]="
							visibleColumnCount +
							(tc_Selection_Enabled() ? 1 : 0) +
							(tc_RowActions_Enabled() ? 1 : 0)
						"
					>
						@switch (currentState) {
							@case (TableState.error) {
								@if (stateErrorTemplate.first?.disabled === false) {
									<ng-container
										[ngTemplateOutlet]="stateErrorTemplate.first.template"
										[ngTemplateOutletContext]="{
											$implicit: lastError,
											defaultTemplate: stateErrorDefault,
										}"
									/>
								} @else {
									<div class="mb-3 text-center fs-3">
										There was an error
										<i
											aria-hidden="true"
											class="far fa-exclamation-triangle"
										></i>
									</div>
								}
								<ng-template #stateErrorDefault>
									<div class="mb-3 text-center fs-3">
										There was an error
										<i
											aria-hidden="true"
											class="far fa-exclamation-triangle"
										></i>
									</div>
								</ng-template>
							}
							@case (TableState.loading) {
								@if (stateLoadingTemplate.first?.disabled === false) {
									<ng-container
										[ngTemplateOutlet]="stateLoadingTemplate.first.template"
									/>
								} @else {
									<div class="mb-3 text-center fs-3">
										Loading
										<i aria-hidden="true" class="far fa-spin fa-spinner"></i>
									</div>
								}
							}
							@case (TableState.noItems) {
								@if (stateNoItemsTemplate.first?.disabled === false) {
									<ng-container
										[ngTemplateOutlet]="stateNoItemsTemplate.first.template"
									/>
								} @else {
									<div
										class="mb-3 text-center fs-3"
										pgTableSection="contentRow"
									>
										Nothing Found
										<i aria-hidden="true" class="far fa-empty-set"></i>
									</div>
								}
							}
							@default {
								<div class="mb-3 text-center text-danger fs-3">
									This should not happen
									<i aria-hidden="true" class="far fa-bug"></i>
								</div>
							}
						}
					</td>
				</tr>
			</tbody>
		}

		<tbody class="content-body">
			@for (staticItem of staticItems; track staticItem; let rowId = $index) {
				@if (staticItem.item != null) {
					<tr
						#rowElement
						pgTableSection="contentRow"
						[ngClass]="rows_GetRowClasses(staticItem.item, rowId)"
						[style.display]="staticItem.display"
					>
						<!-- Selection -->
						@if (
							tc_Selection_Enabled() &&
							tc_Selection_getKeyProperty(staticItem.item) != null
						) {
							<td
								class="text-center text-vertical-middle selection-column d-print-none"
							>
								<label class="table-row-selector-click-wrapper">
									<span class="screen-reader-text">Select Row</span>
									<input
										class="table-row-selector"
										type="checkbox"
										[checked]="tc_Selection_IsItemSelected(staticItem.item)"
										id="pgtable.{{ htmlId }}.display.column.selected.row.{{
											rowId
										}}"
										(click)="
											tc_Selection_SelectItem(
												staticItem.item,
												!tc_Selection_IsItemSelected(staticItem.item),
												$event
											)
										"
									/>
								</label>
							</td>
						}
						@if (
							tc_Selection_Enabled() &&
							tc_Selection_getKeyProperty(staticItem.item) == null
						) {
							<td
								class="text-center text-vertical-middle selection-column d-print-none"
							>
								<span class="screen-reader-text"
									>Row Selection Not Available</span
								>
							</td>
						}
						<!-- Row Actions -->
						@if (tc_RowActions_Enabled() && userPreferences.leftRowActions) {
							<ng-container
								[ngTemplateOutlet]="rowActionsColumnBody"
								[ngTemplateOutletContext]="{
									$implicit: staticItem.item,
									rowId: rowId,
									rowElement: rowElement,
								}"
							/>
						}
						<!-- Columns -->
						@for (
							staticColumn of staticColumns;
							track columns_StaticTrackBy($index, staticColumn)
						) {
							<td
								#rowColElement
								class="content-limiter"
								[attr.cell-auto-title]="staticColumn.col.autoTitle"
								[attr.cell-columnId]="staticColumn.col.columnId"
								id="pgtable.{{ htmlId }}.display.column.{{
									staticColumn.col.columnId
								}}.row.{{ rowId }}"
								[ngClass]="
									columns_GetColumnRowClasses(
										staticItem,
										staticColumn.col,
										rowColElement
									)
								"
								[style.display]="staticColumn.visible ? '' : 'none'"
								[style.width]="columns_GetWidth(staticColumn.col)"
							>
								<ng-container
									[ngTemplateOutlet]="staticColumn.col.displayTemplate.template"
									[ngTemplateOutletContext]="{
										$implicit: staticItem.item,
										rowId: rowId,
										alternateViewActive: columns_alternateDisplayActive(
											staticColumn.col.columnId
										),
									}"
								/>
							</td>
						}
						<!-- Row Actions -->
						@if (tc_RowActions_Enabled() && !userPreferences.leftRowActions) {
							<ng-container
								[ngTemplateOutlet]="rowActionsColumnBody"
								[ngTemplateOutletContext]="{
									$implicit: staticItem.item,
									rowId: rowId,
									rowElement: rowElement,
								}"
							/>
						}
					</tr>
				}
			}
		</tbody>
	</table>
	<div class="skip-links-container">
		<a aeAnchorLink href="#pgtable.{{ htmlId }}.skip-links">
			Back to table skip links
		</a>
	</div>
</ng-template>

<!-- -->
<!-- Row Actions Column -->
<!--  -->
<ng-template #rowActionsColumnHeader>
	<th
		scope="col"
		class="actions-column-size actions-column-size-expanded-{{
			rowActionsTemplate.size
		}} d-print-none"
		[ngClass]="{
			'actions-column-size-expanded': userPreferences.expandRowActions,
			'text-end': !userPreferences.leftRowActions,
			'text-start': userPreferences.leftRowActions,
		}"
	>
		@if (tableConfig.controls.rowActions.interactable) {
			<label
				class="m-0"
				container="body"
				ngbTooltip="{{
					userPreferences.expandRowActions
						? 'Shrink actions column'
						: 'Expand actions column'
				}}"
				[openDelay]="300"
			>
				<span class="screen-reader-text">{{
					userPreferences.expandRowActions
						? 'Shrink actions column'
						: 'Expand actions column'
				}}</span>
				<input
					class="screen-reader-text"
					type="checkbox"
					[formControl]="userPrefForm.controls.expandRowActions"
					id="pgtable.{{ htmlId }}.header.column.row-actions.toggle-expanded"
				/>
				<div class="btn btn-icon btn-link text-decoration-none">
					<i
						aria-hidden="true"
						[ngClass]="
							userPreferences.expandRowActions ===
							userPreferences.leftRowActions
								? 'fas fa-chevron-square-left'
								: 'fas fa-chevron-square-right'
						"
					></i>
				</div>
			</label>
		}
	</th>
</ng-template>

<ng-template
	#rowActionsColumnBody
	let-row
	let-rowElement="rowElement"
	let-rowId="rowId"
>
	<td
		pgtable-actions-cell
		class="pgtable-row-actions-column-display actions-column-size actions-column-size-expanded-{{
			rowActionsTemplate.size
		}} d-print-none"
		id="pgtable.{{ htmlId }}.display.column.row-actions.row.{{ rowId }}"
		[ngClass]="{
			'actions-on-left': userPreferences.leftRowActions,
			'actions-column-size-expanded': userPreferences.expandRowActions,
		}"
	>
		@if (!userPreferences.expandRowActions) {
			<div class="pgtable-row-actions-container">
				<div class="pgtable-row-actions-container-tint" name="has-actions">
					<ng-container [ngTemplateOutlet]="rowActionsTemplateInner" />
				</div>
				<div
					class="pgtable-row-actions-container-tint text-muted"
					name="no-actions"
				>
					<span>No Actions</span>
				</div>
			</div>
			<button
				class="btn btn-icon"
				title="Open action bar"
				type="button"
				id="pgtable.{{ htmlId }}.display.column.row-actions.row.{{
					rowId
				}}.toggle"
				(click)="tc_Build_RowActions_ToggleContainer(rowElement)($event)"
			>
				<i aria-hidden="true" class="fas fa-ellipsis-h"></i>
			</button>
		} @else {
			<ng-container [ngTemplateOutlet]="rowActionsTemplateInner" />
		}

		<ng-template #rowActionsTemplateInner>
			<ng-container
				[ngTemplateOutlet]="rowActionsTemplate.displayTemplate.template"
				[ngTemplateOutletContext]="{ $implicit: row, rowId: rowId }"
			/>
		</ng-template>
	</td>
</ng-template>

<!--  -->
<!--  -->
<!-- TABLE STATES -->
<!--  -->
<!--  -->

<!--  -->
<!--  -->
<!-- FOOTER FOOTER FOOTER -->
<!--  -->
<!--  -->

<!-- Selection Dropdown -->
<ng-template #pgTableSelectionDropdown>
	<div class="btn-group btn-group-sm">
		<button
			class="btn btn-outline-secondary py-0 d-flex rounded-end"
			title="Select Page"
			type="button"
			(click)="tc_Selection_TogglePage()"
		>
			<input
				class="header-page-select-input rounded-start"
				title="Select Page"
				type="checkbox"
				[checked]="tc_Selection_IsPageSelected()"
				id="pgtable.{{ htmlId }}.header.select.selectpage"
			/>
		</button>
		<div
			aria-label="Selection Dropdown"
			class="btn-group"
			container="body"
			ngbDropdown
			placement="bottom-left"
			role="group"
		>
			<button
				class="btn btn-secondary py-0 dropdown-toggle-split"
				ngbDropdownToggle
				title="Open Select Options"
				type="button"
				id="pgtable.{{ htmlId }}.header.select.opendrop"
			></button>

			<div class="dropdown-menu" ngbDropdownMenu>
				<button
					class="dropdown-item"
					type="button"
					(click)="tc_Selection_TogglePage()"
				>
					Select Page
				</button>
				@if (tableConfig.selection.getAllKeys$ != null) {
					<button
						class="dropdown-item"
						type="button"
						[disabled]="getPageActive"
						id="pgtable.{{ htmlId }}.header.select.selectall"
						(click)="tc_Selection_SelectAll()"
					>
						Select All
					</button>
				}
				<button
					class="dropdown-item"
					type="button"
					(click)="tc_Selection_Reset()"
				>
					Clear Selection
				</button>
			</div>
		</div>
	</div>
</ng-template>
