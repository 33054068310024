import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { CourseVersionModel } from '../course-version.model';
import { MappingSectionModel } from '../mapping-section.model';
import {
	CurriculumMappingGraderOptionModel,
	CurriculumMappingMigrateStudentsListResponseModel,
	CurriculumMappingSectionCopyTeachersArgument,
	CurriculumMappingSectionMappingGroupTeacherOptionArgument,
	CurriculumMappingSectionMigrateStudentsArgument,
	CurriculumMappingSectionTeacherMappingCreateArgument,
	CurriculumMappingSectionTeacherMappingEditArgument,
	CurriculumMappingSectionTeacherMappingModel,
	CurriculumMappingSectionTeacherMappingScheduledModel,
	CurriculumMappingSectionTeacherScheduleMappingArgument,
} from './curriculum-mapping-section-teacher-mapping.model';
import {
	CurriculumMappingSectionEditArgument,
	CurriculumMappingSectionListResponseModel,
	CurriculumMappingSectionMappingGroupsModel,
	CurriculumMappingSectionMappingPossibleTeacherModel,
	CurriculumMappingSectionModel,
	CurriculumMappingSectionOptionsModel,
	CurriculumMappingSectionPagingArgument,
} from './curriculum-mapping-section.model';
@Injectable({
	providedIn: 'root',
})
export class CurriculumMappingService {
	constructor(private httpClient: HttpClient) {}

	/** Sections */
	public getSectionList = (
		args: PagingArgument<CurriculumMappingSectionPagingArgument>,
	): Observable<CurriculumMappingSectionListResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/section_list`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CurriculumMappingSectionListResponseModel, r),
					{ errorCode: '9CA48BC9' },
				),
			);
	};

	public getSection = (
		id: number,
	): Observable<CurriculumMappingSectionModel> => {
		return this.httpClient.get<any>(`/api/v1/teacher/mapping/${id}`).pipe(
			mapResponse(
				(r) =>
					plainToInstance(CurriculumMappingSectionModel, r.lms_course_mapping),
				{
					errorCode: '7DF39F54',
				},
			),
		);
	};

	public editSection = (
		id: number,
		args: CurriculumMappingSectionEditArgument,
	): Observable<CurriculumMappingSectionModel> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/${id}/edit`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionModel,
							r.lms_course_mapping,
						),
					{
						errorCode: '4DD86C4C',
						errorMessage: 'There was an issue updating the section',
					},
				),
			);
	};

	public getSectionLmsCourseOptions = (
		lms_id: number,
	): Observable<CurriculumMappingSectionOptionsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/teacher/mapping/section_options?lms_id=${lms_id}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CurriculumMappingSectionOptionsModel, r),
					{
						errorCode: 'C21BF3C9',
					},
				),
			);
	};

	public getSectionOptions =
		(): Observable<CurriculumMappingSectionOptionsModel> => {
			return this.httpClient
				.get<any>(`/api/v1/teacher/mapping/section_options`)
				.pipe(
					mapResponse(
						(r) => plainToInstance(CurriculumMappingSectionOptionsModel, r),
						{
							errorCode: '92DE8AB9',
						},
					),
				);
		};

	/** Section Teacher Mapping */
	public getSectionTeacherMappings = (
		id: number,
		includeInactive: boolean = false,
	): Observable<CurriculumMappingSectionTeacherMappingModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/teacher/mapping/${id}/teachers?show_inactive=${includeInactive}`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionTeacherMappingModel,
							r.lms_teacher_mappings as any[],
						),
					{
						errorCode: 'C37B3D1B',
					},
				),
			);
	};

	public getSectionMappingGroupOptions = (
		id: number,
		args: CurriculumMappingSectionMappingGroupTeacherOptionArgument,
	): Observable<CurriculumMappingSectionMappingGroupsModel[]> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/${id}/groups`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionMappingGroupsModel,
							r.groups as any[],
						),
					{
						errorCode: '1F83D611',
					},
				),
			);
	};

	public getSectionMappingGraderOptions = (): Observable<
		CurriculumMappingGraderOptionModel[]
	> => {
		return this.httpClient.get<any>(`/api/v1/teacher/mapping/grader_list`).pipe(
			mapResponse(
				(r) =>
					plainToInstance(
						CurriculumMappingGraderOptionModel,
						r.results as any[],
					),
				{
					errorCode: 'FD3DC207',
				},
			),
		);
	};

	public getSectionMappingTeacherOptions = (
		id: number,
		args: CurriculumMappingSectionMappingGroupTeacherOptionArgument,
	): Observable<CurriculumMappingSectionMappingPossibleTeacherModel[]> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/${id}/possible_teachers`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionMappingPossibleTeacherModel,
							r.possible_teachers as any[],
						),
					{
						errorCode: '4FA99FD2',
					},
				),
			);
	};

	public createSectionTeacherMapping = (
		id: number,
		args: CurriculumMappingSectionTeacherMappingCreateArgument,
	): Observable<CurriculumMappingSectionTeacherMappingModel> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/${id}/create_teacher_mapping`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionTeacherMappingModel,
							r.teacher_mapping,
						),
					{
						errorCode: '129DC117',
						errorMessage: 'There was an issue creating the mapping',
					},
				),
			);
	};

	public editSectionTeacherMapping = (
		sectionId: number,
		teacherMappingId: number,
		args: CurriculumMappingSectionTeacherMappingEditArgument,
		errMsg: string = 'There was an issue updating the mapping',
	): Observable<CurriculumMappingSectionTeacherMappingModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/teacher/mapping/${sectionId}/teacher_mapping/${teacherMappingId}/edit`,
				args,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionTeacherMappingModel,
							r.teacher_mapping,
						),
					{
						errorCode: '0AF79464',
						errorMessage: errMsg,
					},
				),
			);
	};

	public copySectionTeacherMappings = (
		sectionId: number,
		args: CurriculumMappingSectionCopyTeachersArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/${sectionId}/copy_teachers`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '653BB438',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getTeacherMappingMigrateRegistrations = (
		sectionId: number,
		currentTeacherMappingId: number,
		suggestMappings: boolean,
		args: PagingArgument,
	): Observable<CurriculumMappingMigrateStudentsListResponseModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/teacher/mapping/${sectionId}/teacher_mapping/${currentTeacherMappingId}/registrations?suggest_mappings=${suggestMappings}`,
				args,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingMigrateStudentsListResponseModel,
							r,
						),
					{ errorCode: '1006497C' },
				),
			);
	};

	public resetTeacherMappingStudents = (
		sectionId: number,
		currentTeacherMappingId: number,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/teacher/mapping/${sectionId}/teacher_mapping/${currentTeacherMappingId}/registrations/queue_mapping_reset`,
				{},
			)
			.pipe(mapResponse((r) => r, { errorCode: '7a679678' }));
	};

	public migrateTeacherMappingStudents = (
		sectionId: number,
		currentTeacherMappingId: number,
		args: CurriculumMappingSectionMigrateStudentsArgument[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/teacher/mapping/${sectionId}/teacher_mapping/${currentTeacherMappingId}/registrations/migrate`,
				{ registration_updates: args },
			)
			.pipe(mapResponse((r) => r, { errorCode: '428A8D02' }));
	};

	/** Schedule Section Teacher Mapping */
	public getScheduledSectionTeacherMappings = (
		sectionId: number,
	): Observable<CurriculumMappingSectionTeacherMappingScheduledModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/teacher/mapping/schedule/list?section_id=${sectionId}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionTeacherMappingScheduledModel,
							r.schedules as any[],
						),
					{
						errorCode: '70318A2A',
					},
				),
			);
	};

	public createScheduleSectionTeacherMapping = (
		args: CurriculumMappingSectionTeacherScheduleMappingArgument,
		errMsg: string = 'There was an issue scheduling the mapping',
	): Observable<CurriculumMappingSectionTeacherMappingScheduledModel> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/schedule/create`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionTeacherMappingScheduledModel,
							r.schedule,
						),
					{
						errorCode: 'B962BF4F',
						errorMessage: errMsg,
					},
				),
			);
	};

	public editScheduleSectionTeacherMapping = (
		scheduleId: number,
		args: CurriculumMappingSectionTeacherScheduleMappingArgument,
	): Observable<CurriculumMappingSectionTeacherMappingScheduledModel> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/schedule/${scheduleId}/edit`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingSectionTeacherMappingScheduledModel,
							r.schedule,
						),
					{
						errorCode: '7464BC97',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public deleteScheduledSectionTeacherMapping = (
		scheduleId: number,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/schedule/${scheduleId}/delete`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9C3DA2D8',
					errorMessage: 'There was an issue removing the scheduled mapping',
				}),
			);
	};

	/** Queue Refresh Course Cache */
	public sectionMappingQueueRefreshCourseCache = (
		sectionId: number,
		limitToActive: boolean = false,
	): Observable<any> => {
		return this.httpClient
			.get<any>(
				`/api/v1/teacher/mapping/${sectionId}/refresh_course_cache?limit_to_active=${limitToActive}`,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7464BC97',
					errorMessage: 'There was an issue refreshing the teacher sections',
				}),
			);
	};

	public teacherMappingQueueRefreshCourseCache = (
		sectionId: number,
		teacherMappingId: number,
	): Observable<any> => {
		return this.httpClient
			.get<any>(
				`/api/v1/teacher/mapping/${sectionId}/teacher_mapping/${teacherMappingId}/refresh_course_cache`,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '460FFAD6',
					errorMessage:
						'There was an issue refreshing the teacher section course cache',
				}),
			);
	};

	public getTimeCreditIndex = (
		sectionId: number,
	): Observable<MappingSectionModel[]> => {
		return this.httpClient
			.get<
				MappingSectionModel[]
			>(`api/v1/teacher/mapping/${sectionId}/time-credit-index`)
			.pipe(
				mapResponse((r) => plainToInstance(MappingSectionModel, r as any[]), {
					errorCode: '3b92bd51',
					errorMessage: `There was an issue getting time credit index for section ${sectionId}`,
				}),
			);
	};

	public editTimeCreditIndex = (
		sectionId: number,
		assignments: any[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`api/v1/teacher/mapping/${sectionId}/time-credit-index`, {
				assignments: assignments,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'dc2c7141',
					errorMessage: `There was an issue getting time credit index for section ${sectionId}`,
				}),
			);
	};

	public getCourseVersions = (
		courseId: number,
	): Observable<CourseVersionModel[]> => {
		return this.httpClient
			.get<
				CourseVersionModel[]
			>(`/api/v1/teacher/mapping/course_versions/${courseId}`)
			.pipe(
				mapResponse((r) => plainToInstance(CourseVersionModel, r as any[]), {
					errorCode: 'a5913ab9',
					errorMessage: `There was an issue getting time credit index for course ${courseId}`,
				}),
			);
	};
}
