import { Type } from 'class-transformer';

export class StudentDailyAssignmentCountsModel {
	date: string;
	count: number;
}

export function StudentDailyAssignmentCountsModel_ClassTransform_Type() {
	return StudentDailyAssignmentCountsModel;
}

export class StudentDailyAssignmentCountModel {
	uid: number;
	first_name: string;
	last_name: string;
	registration_title: string;
	registration_official_start_date: string;
	registration_official_due_date: string;
	@Type(StudentDailyAssignmentCountModel_ClassTransform_Type)
	assignment_completion_counts: StudentDailyAssignmentCountsModel[];
}

export function StudentDailyAssignmentCountModel_ClassTransform_Type() {
	return StudentDailyAssignmentCountModel;
}

export interface StudentDailyAssignmentCountArgument {
	from: string;
	to: string;
	student_uids: number[];
}
