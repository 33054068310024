import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfirmAddressService } from 'src/lib/services/utility/address-popup/confirm-address.service';
import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { hasValue } from 'src/lib/utilities/compare';
import {
	AddressAPIResultModel,
	AddressComparisonInfo,
	AddressComponentType,
	AddressConfirmationLevel,
	GoogleAddressAPIModel,
} from './api-address.model';

export interface AddressVerificationRequest {
	addressFormGroups?: FormGroup<FormControlWrapper<UserAddressModel>>[];
	addressFormControl?: FormGroup<FormControlWrapper<UserAddressModel>>;
}

@Injectable({
	providedIn: 'root',
})
export class AddressValidationService {
	constructor(
		private confirmAddressService: ConfirmAddressService,
		private httpClient: HttpClient,
	) {}

	private checkAddress = (
		fg: FormGroup<FormControlWrapper<UserAddressModel>>,
	): Observable<AddressAPIResultModel> => {
		const addressLines = [fg.controls.street.value];

		if (fg.controls?.street2?.value) {
			addressLines.push(fg.controls.street2.value);
		}
		return this.httpClient
			.post<GoogleAddressAPIModel>(`/api/v1/gcloud/address_validation`, {
				address: {
					locality: fg.controls.city.value,
					administrativeArea: fg.controls.state.value,
					postalCode: fg.controls.zip?.value,
					addressLines: addressLines,
				},
				enableUspsCass: true,
			})
			.pipe(map((r) => plainToInstance(AddressAPIResultModel, r.result)));
	};

	public verifyAddresses$ = (data: {
		addressFormGroups?: FormGroup<FormControlWrapper<UserAddressModel>>[];
		addressFormControl?: FormGroup<FormControlWrapper<UserAddressModel>>;
	}): Observable<boolean> => {
		const obs$: Observable<
			[AddressAPIResultModel, FormGroup<FormControlWrapper<UserAddressModel>>]
		>[] = [];
		if (data?.addressFormGroups) {
			data.addressFormGroups.forEach((fg) => {
				if (fg && !this.hasEmptyValues(fg)) {
					obs$.push(combineLatest([this.checkAddress(fg), of(fg)]));
				} else {
					obs$.push(combineLatest([of(null), of(null)]));
				}
			});
		} else if (data?.addressFormControl) {
			if (!this.hasEmptyValues(data?.addressFormControl)) {
				obs$.push(
					combineLatest([
						this.checkAddress(data.addressFormControl),
						of(data.addressFormControl),
					]),
				);
			} else {
				obs$.push(combineLatest([of(null), of(null)]));
			}
		}

		if (obs$.length === 0) {
			return of(true);
		}

		return combineLatest(obs$).pipe(
			switchMap((options) => {
				const arr: AddressComparisonInfo[] = [];
				options.forEach((o, i) => {
					if (o[0] != null) {
						const addressAPIModel: AddressAPIResultModel = o[0];
						const fg = o[1].controls;
						let invalidComponent =
							addressAPIModel?.address.addressComponents.find((v) => {
								return (
									v.confirmationLevel ===
									AddressConfirmationLevel.UNCONFIRMED_AND_SUSPICIOUS
								);
							}) != null;

						//If there is more than 1 unconfirmed component, throw invalid component error.
						if (!invalidComponent) {
							invalidComponent =
								addressAPIModel?.address.addressComponents.filter((v) => {
									return (
										v.confirmationLevel ===
										AddressConfirmationLevel.UNCONFIRMED_BUT_PLAUSIBLE
									);
								}).length > 1;
						}

						//change component info
						//build new address
						let subStreet = addressAPIModel.address.addressComponents.find(
							(v) => {
								return v.componentType === AddressComponentType.street2;
							},
						)?.componentName?.text;

						//check for duplicate values within the address ex. street 1 include Apt. 34 and street 2 includes Apt. 34
						const subStreetArr = subStreet?.split(' ');
						const tempArr: string[] = [];
						subStreetArr?.forEach((val) => {
							if (
								!tempArr.find(
									(v) => v.toLocaleLowerCase() === val.toLocaleLowerCase(),
								)
							) {
								tempArr.push(val);
							}
						});
						subStreet = tempArr.join(' ');

						let street = `${
							addressAPIModel.address.addressComponents.find((v) => {
								return v.componentType === AddressComponentType.streetNumber;
							})?.componentName?.text
						}`;

						const streetName = addressAPIModel.address.addressComponents.find(
							(v) => {
								return v.componentType === AddressComponentType.streetName;
							},
						)?.componentName?.text;

						const pointOfInterest =
							addressAPIModel.address.addressComponents.find((v) => {
								return v.componentType === AddressComponentType.pointOfInterest;
							})?.componentName?.text;

						const secondaryStreet =
							addressAPIModel.address.addressComponents.find((v) => {
								return v.componentType === AddressComponentType.secondaryStreet;
							});

						street = `${street && street !== undefined ? street : ''} ${
							streetName ? streetName : ''
						}`;

						if (secondaryStreet) {
							street += ` ${secondaryStreet}`;
						}
						if (pointOfInterest) {
							street += ` ${pointOfInterest}`;
						}

						if (street.trim() === 'undefined') {
							street = null;
						}

						let city = addressAPIModel.address.addressComponents.find((v) => {
							return (
								v.componentType === AddressComponentType.city ||
								v.componentType === AddressComponentType.neighborhood
							);
						})?.componentName.text;

						if (city) {
							city = `${city}`;
						}

						const state = `${
							addressAPIModel.address.addressComponents.find((v) => {
								return v.componentType === AddressComponentType.state;
							})?.componentName.text
						}`;

						const zip = `${
							addressAPIModel.address.addressComponents.find((v) => {
								return v.componentType === AddressComponentType.zip;
							})?.componentName.text
						}`;

						let differentVals =
							fg.street.value !== street ||
							fg.city.value !== city ||
							fg.state.value !== state;

						//Not all addresses require zip. Check if zip exists first.
						if (fg.zip?.value) {
							differentVals = differentVals || fg.zip?.value !== zip;
						}

						const currentAddress: UserAddressModel = {
							street: fg.street.value,
							city: fg.city.value,
							state: fg.state.value,
							zip: fg.zip?.value,
						};

						const updatedAddress: UserAddressModel = {
							street: street,
							city: city,
							state: state,
							zip: zip,
						};

						if (Object.values(updatedAddress).some((v) => !hasValue(v))) {
							invalidComponent = true;
						}

						if (fg.street2?.value || subStreet) {
							differentVals = differentVals || fg.street2.value !== subStreet;
							currentAddress.street2 = fg.street2.value;
							updatedAddress.street2 = subStreet;
						}
						if (differentVals || invalidComponent) {
							arr.push({
								index: i,
								currentAddress: currentAddress,
								updatedAddress: updatedAddress,
								invalidComponent: invalidComponent,
							});
						} else {
							arr.push(null);
						}
					} else {
						arr.push(null);
					}
				});

				if (arr.length > 0) {
					if (
						arr.filter((v) => {
							return v != null;
						}).length > 0
					) {
						return this.confirmAddressService.openModal$(arr).pipe(
							switchMap((val) => {
								if (val) {
									val.forEach((addressInfo) => {
										if (addressInfo && !addressInfo.invalidComponent) {
											if (data.addressFormGroups) {
												data.addressFormGroups[addressInfo.index].patchValue(
													addressInfo.updatedAddress,
												);
											} else {
												data.addressFormControl.patchValue(
													addressInfo.updatedAddress,
												);
											}
										}
									});
									return of(true);
								}
								return of(false);
							}),
						);
					} else {
						return of(true);
					}
				}

				return of(false);
			}),
		);
	};

	private hasEmptyValues = (
		fg: FormGroup<FormControlWrapper<UserAddressModel>>,
	) => {
		let nullCount = 0;
		Object.keys(fg.controls).forEach((k) => {
			if (k !== 'street2' && k !== 'zip') {
				if (fg.controls[k]?.value == null) {
					nullCount++;
				}
			}
		});
		return nullCount > 0;
	};
}
