import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export enum EnrollmentPaymentPaywhirlStatus {
	failed = 0,
	successful = 1,
	failed_no_subscription = 2,
}

export class EnrollmentPaymentPaywhirlOrderModel {
	order_id: number;
	@Transform(dateTransformer)
	created_timestamp: Date;
	amount: number;
	status: EnrollmentPaymentPaywhirlStatus;
}

export function EnrollmentPaymentPaywhirlOrderModel_ClassTransformType() {
	return EnrollmentPaymentPaywhirlOrderModel;
}

export class EnrollmentPaymentPaywhirlBillModel {
	invoice_id: number;
	@Transform(dateTransformer)
	created_timestamp: Date;
	amount: number;
	status: EnrollmentPaymentPaywhirlStatus;
}

export function EnrollmentPaymentPaywhirlBillModel_ClassTransformType() {
	return EnrollmentPaymentPaywhirlBillModel;
}

export class EnrollmentPaymentInvoiceDataModel {
	invoice_id: number;
	paid: number;
	@Transform(dateTransformer)
	created_timestamp: Date;
	amount: number;
	status: string;
}

export function EnrollmentPaymentInvoiceDataModel_ClassTransformType() {
	return EnrollmentPaymentInvoiceDataModel;
}

export class EnrollmentPaymentDataModel {
	customer_id: number;
	subscription_status: string;
	installments_left: number;
	@Type(EnrollmentPaymentPaywhirlBillModel_ClassTransformType)
	bill_last: EnrollmentPaymentPaywhirlBillModel;
	@Type(EnrollmentPaymentPaywhirlBillModel_ClassTransformType)
	bill_last_successful: EnrollmentPaymentPaywhirlBillModel;
	@Type(EnrollmentPaymentInvoiceDataModel_ClassTransformType)
	invoices: EnrollmentPaymentInvoiceDataModel[];
	@Type(EnrollmentPaymentPaywhirlOrderModel_ClassTransformType)
	shopify: EnrollmentPaymentPaywhirlOrderModel[];
	ignore_payment_status: boolean;
	@Transform(dateTransformer)
	next_payment_attempt_timestamp: Date;
}

export function EnrollmentPaymentDataModel_ClassTransformType() {
	return EnrollmentPaymentDataModel;
}

export class ShopifyInfoModel {
	customer_id: number;
	@Type(EnrollmentPaymentPaywhirlOrderModel_ClassTransformType)
	orders: EnrollmentPaymentPaywhirlOrderModel[];
}

export function ShopifyInfoModel_ClassTransformType() {
	return ShopifyInfoModel;
}

export class PaywhirlInfoModel {
	@Type(EnrollmentPaymentDataModel_ClassTransformType)
	paywhirl: EnrollmentPaymentDataModel;
	@Type(ShopifyInfoModel_ClassTransformType)
	shopify: ShopifyInfoModel;
}
