import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertActionModalComponent } from './alert-action-modal/alert-action-modal.component';
import { AlertPopupMessage } from './alert-popup-message';

@Injectable({
	providedIn: 'root',
})
export class AlertPopupService {
	constructor(private modalService: NgbModal) {}

	public alert$(message: AlertPopupMessage) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: message.size ?? 'sm',
			};
			const modal = this.modalService.open(
				AlertActionModalComponent,
				ngbModalOptions,
			);

			(modal.componentInstance as AlertActionModalComponent).bindModalData(
				message,
			);

			modal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
