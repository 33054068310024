import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class NotificationEventHistoryItemModel {
	set_id: number;
	set_name: string;
	recipient: string;
	recipient_email: string;
	@Expose({ name: 'timestamp' })
	@Transform(dateTransformer)
	date: Date;
	user: string;
	user_id: number;
	subject: string;
	event_id: number;
}

export function NotificationEventHistoryItemModel_ClassTransform_Type() {
	return NotificationEventHistoryItemModel;
}

export class NotificationEventHistoryModel {
	@Type(NotificationEventHistoryItemModel_ClassTransform_Type)
	items: NotificationEventHistoryItemModel[];
	count: number;
}

export interface NotificationEventHistoryArgument {
	set_name: PagingSearchFieldArgument;
	recipient: PagingSearchFieldArgument;
	recipient_email: PagingSearchFieldArgument;
	date: PagingSearchFieldArgument;
	user: PagingSearchFieldArgument;
	user_id: PagingSearchFieldArgument;
	subject: PagingSearchFieldArgument;
}
