import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { BackgroundCheckArgument } from '../trackers/background-check-tracker.model';

@Injectable({
	providedIn: 'root',
})
export class BackgroundCheckService {
	constructor(private httpClient: HttpClient) {}

	public setBackgroundCheck = (
		userId: number,
		args: BackgroundCheckArgument,
	) => {
		return this.httpClient
			.post<any>(`/api/v1/staff/${userId}/background_checks`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'C8E7AABD',
				}),
			);
	};

	public deleteBackgroundCheck = (
		userId: number,
		backgroundCheckId: number,
	) => {
		return this.httpClient
			.delete(`/api/v1/staff/${userId}/background_checks/${backgroundCheckId}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '95D8DC0C',
				}),
			);
	};

	public updateBackgroundCheck = (
		userId: number,
		backgroundCheckId: number,
		args: BackgroundCheckArgument,
	) => {
		return this.httpClient
			.patch(
				`/api/v1/staff/${userId}/background_checks/${backgroundCheckId}`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '6AB7D2DC',
				}),
			);
	};
}
