import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
	AsyncSubject,
	catchError,
	of,
	race,
	switchMap,
	takeUntil,
	timer,
} from 'rxjs';
import { getEnvironment } from 'src/lib/environment/environment';
import { hasValue } from 'src/lib/utilities/compare';
import { ConfirmPopupService } from '../../../confirm-popup/confirm-popup.service';

@Injectable({
	providedIn: 'root',
})
export class TechBannerBuildVersionService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	private buildVersion: string = null;
	private first = true;

	// update after finding out environment issue
	private _checkInterval =
		getEnvironment().settings.services.techBanners.buildVersion.checkInterval;

	constructor(
		private httpClient: HttpClient,
		private confirmService: ConfirmPopupService,
	) {}

	public checkBuildVersion$() {
		if (this.first) {
			console.info(
				`Initializing Build Version Check with ${this._checkInterval} ms timer`,
			);
			this.first = false;
		}

		return this.buildVersion$().pipe(
			catchError(() => {
				console.error('Unable to find build version');
				return of(null);
			}),
			switchMap((version) => {
				if (!hasValue(version)) {
					// build version not found
					return of(false);
				} else if (this.buildVersion == null) {
					// first time checking, set version and recheck in 30 minutes
					this.buildVersion = version;

					return this.recheckTimer$().pipe(
						switchMap(() => this.checkBuildVersion$()),
					);
				} else if (this.buildVersion !== version) {
					// version is not the same, wait 30 minutes and pop confirm
					return this.recheckTimer$().pipe(
						switchMap(() => {
							return this.confirm$();
						}),
					);
				} else {
					// version is the same, recheck in 30 minutes
					return this.recheckTimer$().pipe(
						switchMap(() => this.checkBuildVersion$()),
					);
				}
			}),
			takeUntil(this._unsubscribe$),
		);
	}

	private recheckTimer$ = () => {
		return timer(this._checkInterval);
	};

	private buildVersion$ = () => {
		const ts = new Date().getTime();

		return this.httpClient.get(`/aeclient/dist/build.txt?t=${ts}`, {
			responseType: 'text',
		});
	};

	private confirm$ = () => {
		return race(
			this.recheckTimer$().pipe(switchMap(() => of(true))),
			this.confirmService.confirm$({
				message:
					'A new version of the application is available. Please refresh the page to update.',
				yesText: 'Refresh',
				noText: 'I need more time',
			}),
		).pipe(
			switchMap((r) => {
				if (r) {
					window.location.reload();
					return of(null);
				} else {
					return this.recheckTimer$().pipe(switchMap(() => this.confirm$()));
				}
			}),
		);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
