<div class="modal-header">
	<h1 class="modal-title">
		{{ title }}
	</h1>
	<button
		class="btn btn-outline-dark"
		title="Close"
		type="button"
		(click)="close(false)"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
<div *aeSpinUntil="loaded" class="modal-body">
	@if (state === 0) {
		<div>
			<div class="alert alert-warning">
				The address you entered appears to be incorrect. Verify the address you
				would like to use.
			</div>
			@for (
				addressObj of addressObjects;
				track addressObj;
				let index = $index
			) {
				<div [formGroup]="formGroup">
					<div [ngClass]="{ 'mt-3': index > 0 }">
						<ae-toggle-button
							display="block"
							[formControl]="formGroup.controls.selections.controls[index]"
						>
							<ng-template aeToggleButtonOn>
								<div class="fw-bold">As Entered</div>
								<ng-container
									*ngTemplateOutlet="
										addressOutput;
										context: { $implicit: addressObj.currentAddress }
									"
								/>
							</ng-template>
							<ng-template aeToggleButtonOff>
								<div class="fw-bold">Suggested Address</div>
								<ng-container
									*ngTemplateOutlet="
										addressOutput;
										context: { $implicit: addressObj.updatedAddress }
									"
								/>
							</ng-template>
						</ae-toggle-button>
					</div>
				</div>
			}
		</div>
	}

	@if (state === 1) {
		<div>
			<div class="alert alert-danger">
				The address you entered appears to be incorrect or incomplete.
			</div>
			@for (
				addressObj of invalidAddressObjects;
				track addressObj;
				let index = $index
			) {
				<div>
					<div [ngClass]="{ 'mt-3': index > 0 }">
						<div class="fw-bold">As Entered</div>
						<ng-container
							*ngTemplateOutlet="
								addressOutput;
								context: { $implicit: addressObj.currentAddress }
							"
						/>
					</div>
				</div>
			}
		</div>
	}
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="confirm.address.modal.cancel"
		type="button"
		(click)="close(false)"
	>
		Cancel
	</button>

	@if (state === 1) {
		<button
			class="btn btn-danger"
			id="confirm.address.modal.update.use_as_entered"
			type="button"
			(click)="close(true)"
		>
			Use As Entered
		</button>
	}

	<button
		class="btn btn-primary"
		id="confirm.address.modal.update"
		type="button"
		(click)="nextSave()"
	>
		{{
			addressObjects && invalidComponentsLength > 0 && state === 0
				? 'Update & Continue'
				: addressObjects && invalidComponentsLength === 0
					? 'Confirm'
					: 'Edit'
		}}
	</button>
</div>

<ng-template #addressOutput let-item>
	<ae-address-display [address]="item" [includeAddressLink]="false" />
</ng-template>
