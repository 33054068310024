<div>
	<form>
		<div class="form-group" (submit)="requestReset()">
			<label for="password.reset.component.email">Reset your password</label>
			<div class="mb-2">
				Enter your email address, and a link with instructions to reset your
				password will be sent
			</div>
			<input
				autocomplete="email"
				class="form-control"
				id="password.reset.component.email"
				placeholder="Enter your email"
				type="email"
				[formControl]="ctrl"
			/>

			<div class="mt-1">
				<button
					class="btn btn-link ps-0"
					type="button"
					[disabled]="saving"
					(click)="setState.emit(loginState.needHelp)"
				>
					Need Help?
				</button>
			</div>
		</div>

		<button
			class="btn btn-primary btn-lg btn-block fw-bold"
			id="password.reset.component.login"
			type="submit"
			[disabled]="!ctrl.valid || saving"
			(click)="requestReset()"
		>
			<ae-wait-spinner [saving]="saving">Send link</ae-wait-spinner>
		</button>
	</form>
</div>
