import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { StudentQuickLogMeetupModalComponent } from './student-quick-log-meetup-modal.component';

@Injectable({
	providedIn: 'root',
})
export class StudentQuickLogMeetupModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(studentId: number) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: 'md',
				scrollable: true,
			};
			const modal = this.modalService.open(
				StudentQuickLogMeetupModalComponent,
				ngbModalOptions,
			);

			(
				modal.componentInstance as StudentQuickLogMeetupModalComponent
			).bindModalData({ studentId: studentId });

			modal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
