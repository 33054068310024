import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class OrganizationStudentSearchItemModel {
	public uid: number;
	public first_name: string;
	public last_name: string;
	public email: string;
	public grade: string;
	public phone: number;

	@Transform(dateTransformer)
	public enrolled: Date;
	@Transform(dateTransformer)
	public deactivated: Date;
	public program_name: string;
	public parent_program_name: string;
	public include_withdrawn: boolean;
	public include_preenrolled: boolean;
	public tier: string;
	public tag: string[];
	@Transform(dateTransformer)
	public birthdate: Date;
	public grad_plan: string;
	public cohort_year: number;
	public district_id: string;
	public state_id: string;
	public student_location: string;
}

export function OrganizationStudentSearchItemModel_ClassTransform_Type() {
	return OrganizationStudentSearchItemModel;
}
