<section class="billboard billboard-slim">
	<div class="billboard-header">
		<h2 class="billboard-title">Drupal</h2>
	</div>
	<div class="billboard-body">
		<div
			class="iframe-wrapper"
			[ngStyle]="{ 'background-image': 'url(' + loadingImageUrl + ')' }"
		>
			<iframe title="Drupal Profile" [src]="iframeSrc | dompurify: 5"> </iframe>
		</div>
	</div>
</section>
