import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BehaviorCache } from 'src/lib/utilities/cache';
import {
	UserDataModel,
	UserTimezoneModel,
} from '../../../api/users/user-data.model';
import { UsersService } from '../../../api/users/users.service';
import { InitializationService } from '../../../utility/initialization/initialization.service';

@Injectable({
	providedIn: 'root',
})
export class UserStoreService {
	private _dataCache = new BehaviorCache<number, UserDataModel>(
		(uid) => this.userService.getUserData(uid),
		'UserStoreService DataCache',
		() => null,
	);

	private _profileTabCache = new BehaviorCache<number, string[]>(
		(uid) => this.userService.getProfileUserTabs(uid),
		'UserStoreService ProfileTabCache',
		() => [],
	);

	private _timezoneCache = new BehaviorCache<number, UserTimezoneModel>(
		(uid) => this.userService.getTimezone(uid),
		'UserStoreService TimezoneCache',
		() => {
			return {
				institute_timezone: null,
				timezone: null,
			};
		},
	);

	private _currentUserUid = new BehaviorSubject<number>(undefined);
	private _currentUserLinkId = new BehaviorSubject<number>(undefined);

	public currentUserUid: number = null;
	public currentUserLinkId: number = null;

	public currentUserUid$ = this._currentUserUid
		.asObservable()
		.pipe(filter((x) => x !== undefined));

	public currentUserLinkId$ = this._currentUserLinkId
		.asObservable()
		.pipe(filter((x) => x !== undefined));

	constructor(
		initService: InitializationService,
		private userService: UsersService,
	) {
		this._currentUserUid
			.asObservable()
			.subscribe((x) => (this.currentUserUid = x));

		this._currentUserLinkId
			.asObservable()
			.subscribe((x) => (this.currentUserLinkId = x));

		if (initService?.permissionParams != null) {
			this._currentUserUid.next(initService.permissionParams.user);
			this._currentUserLinkId.next(initService.link_id);
		}
	}

	public getCurrentUser = () =>
		this._currentUserUid.asObservable().pipe(filter((x) => x != null));

	public userData$ = (uid: number, useCache: boolean = true) => {
		if (!useCache) {
			this._dataCache.fetchData(uid, true);
		}

		return this._dataCache.getCache(uid);
	};

	public refreshUserData = (uid: number) => {
		this._dataCache.fetchData(uid, true);
	};

	public profileTabs$ = (uid: number) => {
		return this._profileTabCache.getCache(uid);
	};

	public refreshProfileTabs = (uid: number) => {
		this._profileTabCache.fetchData(uid, true);
	};

	public timezone$ = (uid: number) => {
		return this._timezoneCache.getCache(uid);
	};

	public refreshTimezone = (uid: number) => {
		this._timezoneCache.fetchData(uid, true);
	};
}
