import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import spacetime from 'spacetime';
import { IconTooltipComponent } from '../icon-tooltip/icon-tooltip.component';

@Component({
	selector: 'ae-timezone-notice',
	templateUrl: './timezone-notice.component.html',
	styleUrls: ['./timezone-notice.component.scss'],
	standalone: true,
	imports: [IconTooltipComponent],
})
export class TimezoneNoticeComponent implements OnChanges {
	@Input() timezone: string;
	@Input() text = 'Displayed in';

	public tzDisplay: string;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.timezone) {
			this.tzDisplay = changes.timezone.currentValue;

			if (changes.timezone.currentValue) {
				try {
					const sp = spacetime.now(changes.timezone.currentValue);
					this.tzDisplay = (
						sp.format(`{timezone} ({offset})`) as string
					).replace(new RegExp(/_/g), ' ');
				} catch {
					console.error(
						'Invalid timezone given',
						changes.timezone.currentValue,
					);
				}
			}
		}
	}
}
