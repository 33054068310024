import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { RegistrationFinalizationResponseModel } from './registration-response.model';

@Injectable({
	providedIn: 'root',
})
export class RegistrationService {
	constructor(private httpClient: HttpClient) {}

	public finalizeCourse = (
		registrationId: number,
	): Observable<RegistrationFinalizationResponseModel> => {
		const args = {
			registrations: [registrationId],
			process: 1,
			show_reasons: true,
		};
		return this.httpClient
			.post<RegistrationFinalizationResponseModel>(
				`api/v1/registration/finalize`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(RegistrationFinalizationResponseModel, r),
					{
						errorCode: '939F86AD',
					},
				),
			);
	};
}
