@if (userId != null) {
	@if (date) {
		<span
			title="{{
				date
					| spaceMap: tz
					| spaceFormat
						: '{numeric-us} {hour-24-pad}:{minute-pad}:{second-pad} {timezone} ({offset})'
			}}"
		>
			{{ date | spaceMap: tz | spaceFormat: format }}
		</span>
	} @else {
		<span> - </span>
	}
} @else {
	<span> No User Provided </span>
}
