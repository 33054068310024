import { Expose, Transform, Type } from 'class-transformer';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from 'src/lib/services/utility/utility-models/address.model';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

import {
	ReentryPreApprovalModel,
	ReentryPreApprovalModel_ClassTransform_Type,
} from './re-entry-pre-approval.model';

export enum ReEntryStatus {
	Requested = 0,
	ApprovalRequested = 1,
	ApprovalReceived = 2,
	PaperworkRequested = 3,
	PaperworkCompleted = 4,
	AssignedReEntryCoach = 5,
	ReEnrolled = 6,
	Cancelled = 100,
}

export const ReEntryStatusTitles: { key: number; title: string }[] = [
	{
		key: 0,
		title: 'Re-entry Requested',
	},
	{
		key: 1,
		title: 'Approval Requested',
	},
	{
		key: 2,
		title: 'Approval Received',
	},
	{
		key: 3,
		title: 'Paperwork Requested',
	},
	{
		key: 4,
		title: 'Paperwork Completed',
	},
	{
		key: 5,
		title: 'Assigned to Re-entry Coach',
	},
];

export class ReEntryStatusOptionModel {
	key: ReEntryStatus;
	title: string;
}

export function ReEntryStatusOptionModel_ClassTransform_Type() {
	return ReEntryStatusOptionModel;
}

export class ReEntryOptionItemModel {
	key: number;
	title: string;
}

export function ReEntryOptionsItemModel_ClassTransform_Type() {
	return ReEntryOptionItemModel;
}

export class ReEntryOptionsModel {
	@Type(ReEntryStatusOptionModel_ClassTransform_Type)
	status: ReEntryStatusOptionModel[];
	@Type(ReEntryOptionsItemModel_ClassTransform_Type)
	needs_computer: ReEntryOptionItemModel[];
	@Type(ReEntryOptionsItemModel_ClassTransform_Type)
	prefer_previous_ac: ReEntryOptionItemModel[];
	@Type(ReEntryOptionsItemModel_ClassTransform_Type)
	ready_to_work: ReEntryOptionItemModel[];
	@Type(ReEntryOptionsItemModel_ClassTransform_Type)
	cancelled: ReEntryOptionItemModel[];
	@Type(ReEntryOptionsItemModel_ClassTransform_Type)
	referral_source: ReEntryOptionItemModel[];
}

export class ReEntryRequestAdditionalModel {
	@Type(UserAddressModel_ClassTransform_Type)
	previous_address?: UserAddressModel;
}

export function ReEntryRequestAdditionalModel_ClassTransform_Type() {
	return ReEntryRequestAdditionalModel;
}

export class ReEntryRequestHistoryModel {
	updated_by: number;
	updated_by_name: string;
	@Transform(dateTransformer)
	updated_on: Date;
	status: ReEntryStatus;
	is_work: boolean;
}

export function ReEntryRequestHistoryModel_ClassTransform_Type() {
	return ReEntryRequestHistoryModel;
}

export class SalesforceApprovalInstituteModel {
	title: string;
	institute_id: number;
}

export function SalesforceApprovalInstituteModel_ClassTransform_Type() {
	return SalesforceApprovalInstituteModel;
}

export class SalesforceApprovalApproverModel {
	id: number;
	name: string;
}

export function SalesforceApprovalApproverModel_ClassTransform_Type() {
	return SalesforceApprovalApproverModel;
}

export class SalesforceApprovalApproversModel {
	@Type(SalesforceApprovalApproverModel_ClassTransform_Type)
	approved_by: SalesforceApprovalApproverModel;
	@Expose({ name: 'approved_on_timestamp' })
	@Transform(dateTransformer)
	approved_on_date: Date;
	is_approved: boolean;
	status: string;
	type: string;
	reason: string;
}

export function SalesforceApprovalApproversModel_ClassTransform_Type() {
	return SalesforceApprovalApproversModel;
}

export class ReEntryRequestSalesforceApprovalModel {
	@Type(SalesforceApprovalApproversModel_ClassTransform_Type)
	approvers: SalesforceApprovalApproversModel[];
	@Expose({ name: 'completed_timestamp' })
	@Transform(dateTransformer)
	completed_date: Date;
	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created_date: Date;
	id: number;
	@Type(SalesforceApprovalInstituteModel_ClassTransform_Type)
	institute: SalesforceApprovalInstituteModel;
	reason: string;
	status: number;
}

export function ReEntryRequestSalesforceApprovalModel_ClassTransform_Type() {
	return ReEntryRequestSalesforceApprovalModel;
}

export class ReentryInstituteModel {
	id: number;
	title: string;
}

export function ReentryInstituteModel_ClassTransform_Type() {
	return ReentryInstituteModel;
}

export class ReEntryRequestModel {
	uid: number;
	reentry_request_id: number;
	created_by: number;
	created_by_name: string;
	@Transform(dateTransformer)
	created_on: Date;
	status: number;
	needs_computer: number;
	notes: string;
	@Transform(dateTransformer)
	updated_on: Date;
	@Transform(dateTransformer)
	completed_on: Date;
	completed_by: number;
	completed_by_name: string;
	cancelled_reason: number;
	cancelled_notes: string;
	@Type(ReEntryRequestAdditionalModel_ClassTransform_Type)
	additional_data: ReEntryRequestAdditionalModel;
	internal_notes: string;
	@Type(ReEntryRequestHistoryModel_ClassTransform_Type)
	history: ReEntryRequestHistoryModel[];
	prefer_previous_ac: number;
	ready_to_work: number;
	@Type(ReentryInstituteModel_ClassTransform_Type)
	target_institute: ReentryInstituteModel;
	referral_source: number;
}

export function ReEntryRequestModel_ClassTransform_Type() {
	return ReEntryRequestModel;
}

export class ReEntryHistoryModel extends ReEntryRequestModel {
	@Type(ReEntryRequestSalesforceApprovalModel_ClassTransform_Type)
	salesforce_approval: ReEntryRequestSalesforceApprovalModel;
	@Type(ReentryPreApprovalModel_ClassTransform_Type)
	pre_approval: ReentryPreApprovalModel;
}

export function ReEntryHistoryModel_ClassTransform_Type() {
	return ReEntryHistoryModel;
}

export class ReEntryRequestResponseModel {
	success: boolean;
	@Type(ReEntryRequestModel_ClassTransform_Type)
	reentry_request: ReEntryRequestModel;
	@Type(ReEntryRequestSalesforceApprovalModel_ClassTransform_Type)
	salesforce_approval: ReEntryRequestSalesforceApprovalModel;
	@Type(ReentryPreApprovalModel_ClassTransform_Type)
	pre_approval: ReentryPreApprovalModel;
}

export interface ReEntryRequestUpsertArgument {
	notes?: string;
	needs_computer?: number;
	additional_data?: ReEntryRequestAdditionalModel;
	status?: ReEntryStatus;
	internal_notes?: string;
	ready_to_work?: number;
	prefer_previous_ac?: number;
	referral_source?: number;
}

export interface ReEntryRequestCancelArgument {
	cancelled_reason: number;
	cancelled_notes: string;
}
