import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { MeetupManagementListItemModel } from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupRescheduleModalComponent } from './meetup-reschedule-modal.component';

@Injectable({
	providedIn: 'root',
})
export class MeetupRescheduleModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(meetup: MeetupManagementListItemModel) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				scrollable: true,
				windowClass: 'modal-primary',
			};
			const modal = this.modalService.open(
				MeetupRescheduleModalComponent,
				ngbModalOptions,
			);

			(modal.componentInstance as MeetupRescheduleModalComponent).bindModalData(
				{
					meetup: meetup,
				},
			);

			modal.result
				.then((res) => {
					o.next(res);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
