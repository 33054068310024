import { SlicePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UserDataContactModel } from 'src/lib/services/api/users/user-data.model';
import { hasKey, hasSomeKey } from 'src/lib/utilities/compare';
import { HrefUrlPipe } from '../../../../../pipes/href-url.pipe';
import { IconTooltipComponent } from '../../../../../templates/global/icon-tooltip/icon-tooltip.component';
import { StaffPhoneComponent } from '../../../../../templates/global/staff-phone/staff-phone.component';
import { AddressDisplayComponent } from '../../../../../templates/global/user-address/address-display.component';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';

@Component({
	selector: 'ae-profile-contact-view',
	templateUrl: './profile-contact-view.component.html',
	styleUrls: ['./profile-contact-view.component.scss'],
	standalone: true,
	imports: [
		IconTooltipComponent,
		SpinWhileDirective,
		StaffPhoneComponent,
		AddressDisplayComponent,
		SlicePipe,
		HrefUrlPipe,
	],
})
export class ProfileContactViewComponent {
	@Input() public contactData: UserDataContactModel;
	@Input() public canEditContact: boolean;
	@Input() public loading: boolean;
	@Input() public enableEdit: () => void;
	@Input() public existingPendingChange: boolean;

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public hasKey = hasKey;
	public hasSomeKey = hasSomeKey;
}
