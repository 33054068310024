import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class PermissionHistoryFieldsModel {
	add: string[];
	remove: string[];
	reset: string[];
}

export function PermissionHistoryFieldsModel_ClassTransform_Type() {
	return PermissionHistoryFieldsModel;
}

export class PermissionRequestorModel {
	uid: number;
	name: string;
}

export function PermissionRequestorModel_ClassTransform_Type() {
	return PermissionRequestorModel;
}

export class PermissionResolutionModel {
	@Type(PermissionRequestorModel_ClassTransform_Type)
	by: PermissionRequestorModel;
	@Transform(dateTransformer)
	on: Date;
	reason: string;
}

export function PermissionResolutionModel_ClassTransform_Type() {
	return PermissionResolutionModel;
}

export class PermissionHistoryModel {
	id: number;
	status: number;
	entity_type: string;
	entity_id: string;
	@Type(PermissionHistoryFieldsModel_ClassTransform_Type)
	fields: PermissionHistoryFieldsModel;
	@Transform(dateTransformer)
	requested_on: Date;
	@Type(PermissionRequestorModel_ClassTransform_Type)
	requested_by: PermissionRequestorModel;
	@Type(PermissionResolutionModel_ClassTransform_Type)
	resolution: PermissionResolutionModel;
}

export function PermissionHistoryModel_ClassTransform_Type() {
	return PermissionHistoryModel;
}

export class PermissionHistoryResponseModel {
	@Type(PermissionHistoryModel_ClassTransform_Type)
	edits: PermissionHistoryModel[];
	count: number;
}

export interface PermissionHistorySearchArgument {
	requested_on: PagingSearchFieldArgument;
	requested_by_name: PagingSearchFieldArgument;
	permission: PagingSearchFieldArgument;
	change: PagingSearchFieldArgument;
	role: PagingSearchFieldArgument;
	override_title: PagingSearchFieldArgument;
}
