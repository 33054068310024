import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentPathModel } from './student-path.model';

@Injectable({
	providedIn: 'root',
})
export class StudentPathService {
	constructor(private httpClient: HttpClient) {}

	public getPath = (uid: number): Observable<StudentPathModel> => {
		return this.httpClient.get<any>(`/api/v1/students/${uid}/my-path`).pipe(
			mapResponse((r) => plainToInstance(StudentPathModel, r), {
				errorCode: 'C42B403D',
			}),
		);
	};
}
