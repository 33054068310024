import { PagingSearchFieldArgument } from './paging-search-field.argument';

export interface PagingArgument<
	PagingSearch extends {
		[K in keyof PagingSearch]: PagingSearchFieldArgument | any;
	} = any,
> {
	skip?: number;
	take?: number;
	sort?: { by: string; order: 'DESC' | 'ASC' };
	filters?: PagingSearch;
}

export function booleanToSortOrderDescending(
	val: boolean,
): 'DESC' | 'ASC' | null {
	if (val == null) return null;
	return val ? 'DESC' : 'ASC';
}
