import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { AbstractPagingResponseModel } from 'src/lib/utilities/api/patterns/pagination/abstract-paging-response.model';

export class StudentMeetupGateExemptionModel {
	id: number;
	@Expose({ name: 'start_time' })
	@Transform(dateTransformer)
	start_date: Date;
	@Expose({ name: 'end_time' })
	@Transform(dateTransformer)
	end_date: Date;
	minutes: number;
	created_by_first_name: string;
	created_by_last_name: string;
	created_by_uid: number;
	@Expose({ name: 'created_time' })
	@Transform(dateTransformer)
	created_date: Date;
	is_editable: boolean;
	is_current: boolean;
}

export function StudentMeetupGateExemptionModel_ClassTransform_Type() {
	return StudentMeetupGateExemptionModel;
}

export class StudentMeetupGateExemptionResponseModel extends AbstractPagingResponseModel<StudentMeetupGateExemptionModel> {
	@Type(StudentMeetupGateExemptionModel_ClassTransform_Type)
	results: StudentMeetupGateExemptionModel[];
}
