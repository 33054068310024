import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from 'src/lib/utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	StudentEventModel,
	StudentEventModel_ClassTransform_Type,
} from './student-event.model';

export class StudentEventsResponseModel extends AbstractPagingResponseModel<StudentEventModel> {
	@Type(StudentEventModel_ClassTransform_Type)
	results: StudentEventModel[];
}

export interface StudentEventsFilter {
	log_id?: number;
	subject?: string;
	body?: string;
	medium?: string;
	assigned_name?: string;
	creator_name?: string;
	category?: PagingSearchFieldArgument | string | string[];
	category_exclude?: string[];
	completed_task?: boolean;
	updated?: PagingSearchFieldArgument;
	created?: PagingSearchFieldArgument;
	due_date?: PagingSearchFieldArgument;
	complete_date?: PagingSearchFieldArgument;
	twoway_contact?: boolean;
	contact_events_only?: boolean;
	student_name?: string;
	purpose?: PagingSearchFieldArrayArgument;
	relationship?: PagingSearchFieldArrayArgument;
}
