import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class EnrollmentApproverModel {
	id: number;
	name: string;
}

export function EnrollmentApproverModel_ClassTransform_Type() {
	return EnrollmentApproverModel;
}

export class EnrollmentApproversModel {
	is_approved: boolean;
	type: string;
	@Type(EnrollmentApproverModel_ClassTransform_Type)
	approved_by: EnrollmentApproverModel;
	@Expose({ name: 'approved_on_timestamp' })
	@Transform(dateTransformer)
	approved_on_date: Date;
	@Type(EnrollmentApproverModel_ClassTransform_Type)
	available_approvers: EnrollmentApproverModel[];
}

export function EnrollmentApproversModel_ClassTransform_Type() {
	return EnrollmentApproversModel;
}

export class EnrollmentApprovalRequestInstituteModel {
	institute_id: number;
	title: string;
}

export function EnrollmentApprovalRequestInstituteModel_ClassTransform_Type() {
	return EnrollmentApprovalRequestInstituteModel;
}

export class EnrollmentApprovalRequestModel {
	id: number;
	status: number;
	@Type(EnrollmentApproversModel_ClassTransform_Type)
	approvers: EnrollmentApproversModel[];
	@Type(EnrollmentApprovalRequestInstituteModel_ClassTransform_Type)
	institute: EnrollmentApprovalRequestInstituteModel;
	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created_date: Date;
	@Expose({ name: 'completed_timestamp' })
	@Transform(dateTransformer)
	completed_date: Date;
	reason: string;
}

export function EnrollmentApprovalRequestModel_ClassTransform_Type() {
	return EnrollmentApprovalRequestModel;
}

export class EnrollmentRequestApprovalOptionsModel {
	is_eligible: boolean;
	ineligible_reasons: string[];
	into_institute_id: number;
	requires_approval: boolean;
	can_check_approval: boolean;
}

export function EnrollmentRequestApprovalOptionsModel_ClassTransform_Type() {
	return EnrollmentRequestApprovalOptionsModel;
}

export class EnrollmentApprovalRequestArgument {
	institute_id: number;
	reason: string;
}

export class StudentEnrollmentRequestStatusModel {
	student_reference: string;
	@Type(EnrollmentApprovalRequestModel_ClassTransform_Type)
	approval_request: EnrollmentApprovalRequestModel;
	@Type(EnrollmentRequestApprovalOptionsModel_ClassTransform_Type)
	approval_options: EnrollmentRequestApprovalOptionsModel;
}

export enum EnrollmentRequestStatusType {
	Pending = 0,
	Approved = 1,
	Rejected = -1,
	Cancelled = -2,
}
