import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserStoreService } from '../services/stores/users/user/user-store.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationProxyGuard {
	constructor(
		private userStoreService: UserStoreService,
		private router: Router,
	) {}

	canActivate(
		_route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		if (this.userStoreService.currentUserUid) {
			return this.router.createUrlTree([
				`profile/${this.userStoreService.currentUserUid}/notifications`,
			]);
		}
		return true;
	}
}
