import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { SpinUntilDirective } from '../../../../templates/layout/spin-while/spin-until.directive';
import {
	ISitemapService,
	PROFILE_SITEMAP_SERVICE,
	SitemapItem,
} from './profile-sitemap.service';

@Component({
	selector: 'ae-profile-sitemap',
	templateUrl: './profile-sitemap.component.html',
	styleUrls: ['./profile-sitemap.component.scss'],
	standalone: true,
	imports: [SpinUntilDirective, RouterLink, AsyncPipe],
})
export class ProfileSitemapComponent implements OnInit {
	public sitemap$: Observable<SitemapItem[]>;

	constructor(
		@Inject(PROFILE_SITEMAP_SERVICE) private sitemapService: ISitemapService,
	) {}

	ngOnInit(): void {
		this.sitemap$ = this.sitemapService.sitemap$();
	}
}
