import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';

@Injectable({
	providedIn: 'root',
})
export class RedirectToProfileGuard {
	constructor(
		private userStoreService: UserStoreService,
		private router: Router,
	) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (state.url === '/profile') {
			return this.userStoreService.currentUserUid$.pipe(
				map((u) => this.router.createUrlTree(['/profile', u])),
			);
		} else return true;
	}
}
