import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	ChannelParticipantModel,
	ChannelParticipantRole,
} from 'src/lib/services/api/gabby/channels/channel-participant.model';

@Component({
	selector: 'ae-gabby-participant-label',
	templateUrl: './gabby-participant-label.component.html',
	styleUrls: ['./gabby-participant-label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class GabbyParticipantLabelComponent implements OnChanges {
	@HostBinding('title') hostTitle;

	@Input() participant: ChannelParticipantModel;

	public getParticipantLabelType = () => {
		if (this.participant == null) return '';
		switch (this.participant.role_id) {
			case ChannelParticipantRole.LocalAdvocate:
				return 'la';
			case ChannelParticipantRole.Parent:
				return 'p';
			case ChannelParticipantRole.Student:
				return 's';
			case ChannelParticipantRole.Teacher:
				return 't';
			case ChannelParticipantRole.AcademicCoach:
				return 'ac';
			case ChannelParticipantRole.StudentContact:
				return 'sc';
			case ChannelParticipantRole.CareerCounselor:
				return 'cc';
			case ChannelParticipantRole.TestingCoordinator:
				return 'tc';
			case ChannelParticipantRole.Principal:
				return 'pp';
			case ChannelParticipantRole.TechSupport:
				return 'ts';
			case ChannelParticipantRole.SystemMessage:
				return 'sm';
			case ChannelParticipantRole.Substitute:
				return 'sb';
			case ChannelParticipantRole.Specialist:
				return 'sp';
			case ChannelParticipantRole.GradCoordinator:
				return 'gc';
			case ChannelParticipantRole.ReEntryCoach:
			case ChannelParticipantRole.ReEntryCoordinator:
				return 're';
			case ChannelParticipantRole.Tutor:
				return 'tu';
			default:
				return '';
		}
	};

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.participant != null &&
			changes.participant.currentValue != null
		) {
			this.hostTitle = this.participant.role_name;
		}
	}
}
