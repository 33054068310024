@if (isHidden) {
	<button
		aria-hidden="true"
		class="btn btn-icon"
		tabindex="-1"
		title="Reveal Attachment"
		type="button"
		(click)="hideAttachment(false)"
	>
		<i aria-hidden="true" class="far fa-caret-square-left fa-lg"></i>
	</button>
} @else {
	<button
		aria-hidden="true"
		class="btn btn-icon"
		tabindex="-1"
		title="Hide Attachment"
		type="button"
		(click)="hideAttachment(true)"
	>
		<i aria-hidden="true" class="far fa-caret-square-down fa-lg"></i>
	</button>
}
