import { Injectable, OnDestroy } from '@angular/core';
import {
	AsyncSubject,
	asapScheduler,
	catchError,
	delay,
	mergeAll,
	of,
	scheduled,
	switchMap,
	takeUntil,
} from 'rxjs';
import { TechBannerBuildVersionService } from './banners/build-version/tech-banner-build-version.service';
import { TechBannerChromeVersionService } from './banners/chrome-version/tech-banner-chrome-version.service';
import { TechBannerPlannedMaintenanceService } from './banners/planned-maintenance/tech-banner-planned-maintenance.service';

@Injectable({
	providedIn: 'root',
})
export class TechBannerService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	constructor(
		private chromeVersionService: TechBannerChromeVersionService,
		private plannedMaintenanceService: TechBannerPlannedMaintenanceService,
		private buildVersionService: TechBannerBuildVersionService,
	) {}

	public init = () => {
		of(null)
			.pipe(
				delay(4000),
				switchMap(() => {
					return scheduled(
						[
							/** BANNER SERVICES THAT HANDLE CHECKING AND DISPLAYING */
							/** Release Info  */
							/** Service Outage  */
							/** Masquerading  */
							this.plannedMaintenanceService.checkMaintenance$(),
							this.chromeVersionService.checkVersion$(),
						],
						asapScheduler,
					).pipe(
						mergeAll(),
						catchError((err) => {
							console.error(err);
							return of(null);
						}),
					);
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe({
				error: (err) => {
					console.error(err);
				},
				complete: () => {
					console.info('Tech Banners Initialized');
				},
			});

		this.buildVersionService.checkBuildVersion$().subscribe();
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
