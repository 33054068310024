import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import {
	AbstractPerformanceRequirementNote,
	PerformanceRequirementNoteInterface,
	PerformanceRequirementNoteType,
} from '../abstract-performance-requirement-note.model';
import { AttendancePerformanceRequirementNote } from '../attendance-performance-requirement-note.model';
import { MilestonePerformanceRequirementNote } from '../milestone-performance-requirement-note.model.model';
import { RolloverPerformanceRequirementNote } from '../rollover-performance-requirement-note.model';
import { UnknownPerformanceRequirementNote } from '../unknown-performance-requirement-note.model';

@Injectable({
	providedIn: 'root',
})
export class StudentPerformanceRequirementNoteParserService {
	public parseNote = (
		model: PerformanceRequirementNoteInterface,
	): AbstractPerformanceRequirementNote => {
		switch (model.type) {
			case PerformanceRequirementNoteType.Milestone: {
				return plainToInstance(MilestonePerformanceRequirementNote, model);
			}
			case PerformanceRequirementNoteType.Rollover: {
				return plainToInstance(RolloverPerformanceRequirementNote, model);
			}
			case PerformanceRequirementNoteType.Attendance: {
				return plainToInstance(AttendancePerformanceRequirementNote, model);
			}
			default: {
				console.error('Unknown performance note 5CA22D95', model);
				return plainToInstance(UnknownPerformanceRequirementNote, model);
			}
		}
	};
}
