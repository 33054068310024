<div
	class="input-group validate-include-children flex-nowrap overflow-hidden"
	container="body"
	ngbDropdown
>
	<button
		class="btn btn-gray"
		ngbDropdownToggle
		type="button"
		[disabled]="disabled"
		[id]="id + '.equalitycomparer.operators'"
		[ngClass]="{ 'flex-grow-1 rounded': hideInput }"
	>
		<ae-search-operator-icon [operator]="ecForm.controls.operator.value" />
	</button>
	<div aria-label="Operator" ngbDropdownMenu role="listbox">
		@for (op of internalOperators; track op) {
			<button
				ngbDropdownItem
				role="option"
				type="button"
				[attr.aria-selected]="ecForm.controls.operator.value === op"
				(click)="selectOperator(op)"
				(keydown.enter)="selectOperator(op)"
			>
				<ae-search-operator-icon [operator]="op" />
			</button>
		}
	</div>
	<input
		#dateInput
		class="form-control squared-start rounded-end"
		[disabled]="this.ecForm.controls.operand.disabled"
		id="{{ id }}"
		[max]="getMaxDate()"
		[min]="getMinDate()"
		[ngClass]="{ 'd-none': hideInput }"
		[ngModelOptions]="{ standalone: true }"
		[placeholder]="'mm/dd/yyyy'"
		[type]="inputType"
		[(ngModel)]="formattedModel"
		(blur)="changeInputType('date'); onFormattedChange(formattedModel, true)"
		(dblclick)="changeInputType('text')"
		(keydown.enter)="dateInput.blur()"
		(ngModelChange)="onFormattedChange($event)"
	/>
</div>
