import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: 'ng-template[aeValidationError]',
	standalone: true,
})
export class ValidationErrorDirective {
	@Input('aeValidationError') public error: string;

	constructor(public template: TemplateRef<any>) {}
}
