import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class NotificationSetHistoryModel {
	updated_by_uid: number;
	updated_by_name: string;
	reason: string;
	@Expose({ name: 'updated_on_timestamp' })
	@Transform(dateTransformer)
	updated_on_date: Date;
}

export class NotificationSetHistoryListItemModel {
	updated_by_uid: number;
	updated_by_name: string;
	changed_reason: string;
	set_name: string;
	set_id: number;
	@Transform(dateTransformer)
	updated_on: Date;
}

export function NotificationSetHistoryListItemModel_ClassTransform_Type() {
	return NotificationSetHistoryListItemModel;
}

export class NotificationSetHistoryListModel {
	@Type(NotificationSetHistoryListItemModel_ClassTransform_Type)
	notification_set_history: NotificationSetHistoryListItemModel[];
	count: number;
}

export class NotificationHistoryListArgument {
	set_name: PagingSearchFieldArgument;
	updated_by_name: PagingSearchFieldArgument;
	updated_on_timestamp: PagingSearchFieldArgument;
}
