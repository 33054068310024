import {
	ClassConstructor,
	instanceToInstance,
	instanceToPlain,
	plainToInstance,
	TransformationType,
	TransformFnParams,
} from 'class-transformer';
import spacetime from 'spacetime';
// eslint-disable-next-line no-restricted-imports
import { isNumber } from '../compare';
// eslint-disable-next-line no-restricted-imports
import { convertToInt, convertToPercentage } from '../convert';

export const dateTransformer = function (params: TransformFnParams) {
	if (params.type === TransformationType.PLAIN_TO_CLASS) {
		if (!params.value) {
			return null;
		} else if (isNumber(params.value)) {
			return new Date(params.value * 1000);
		} else {
			return spacetime(params.value).toNativeDate();
		}
	} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
		return spacetime(params.value).format('iso-utc');
	} else if (params.type === TransformationType.CLASS_TO_CLASS) {
		return params.value;
	} else return params.value;
};

export const stringTransformer = function (params: TransformFnParams) {
	if (params.type === TransformationType.PLAIN_TO_CLASS) {
		if (params.value == null) return null;
		return `${params.value}`;
	} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
		return params.value;
	} else if (params.type === TransformationType.CLASS_TO_CLASS) {
		return params.value;
	} else return params.value;
};

export const integerTransformer = function (params: TransformFnParams) {
	if (params.type === TransformationType.PLAIN_TO_CLASS) {
		if (params.value == null) return null;
		if (params.value instanceof Array) {
			const numArray = [];
			for (let i = 0; params.value.length > i; i++) {
				const parsedInt = convertToInt(params.value[i]);
				if (parsedInt == null) {
					throw new Error(
						`integerTransformer. Could not parse int from ${params.value}`,
					);
				} else {
					numArray.push(parsedInt);
				}
			}
			return numArray;
		} else {
			const parsedInt = convertToInt(params.value);
			if (parsedInt == null) {
				throw new Error(
					`integerTransformer. Could not parse int from ${params.value}`,
				);
			} else {
				return parsedInt;
			}
		}
	} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
		return params.value;
	} else if (params.type === TransformationType.CLASS_TO_CLASS) {
		return params.value;
	} else return params.value;
};

export const floatTransformer = function (params: TransformFnParams) {
	if (params.type === TransformationType.PLAIN_TO_CLASS) {
		if (params.value == null) return null;
		if (params.value instanceof Array) {
			const numArray = [];
			for (let i = 0; params.value.length > i; i++) {
				const parsedFloat = parseFloat(params.value[i]);
				if (parsedFloat == null) {
					throw new Error(
						`floatTransformer. Could not parse float from ${params.value}`,
					);
				} else {
					numArray.push(parsedFloat);
				}
			}
			return numArray;
		} else {
			const parsedFloat = parseFloat(params.value);
			if (parsedFloat == null) {
				throw new Error(
					`floatTransformer. Could not parse float from ${params.value}`,
				);
			} else {
				return parsedFloat;
			}
		}
	} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
		return params.value;
	} else if (params.type === TransformationType.CLASS_TO_CLASS) {
		return params.value;
	} else return params.value;
};

export const percentageTransformer = function (params: TransformFnParams) {
	if (params.type === TransformationType.PLAIN_TO_CLASS) {
		if (params.value == null) return null;
		const parsedInt = convertToPercentage(params.value);
		if (parsedInt == null) {
			throw new Error(
				`percentageTransformer. Could not get percentage from ${params.value}`,
			);
		} else {
			return parsedInt;
		}
	} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
		return params.value;
	} else if (params.type === TransformationType.CLASS_TO_CLASS) {
		return params.value;
	} else return params.value;
};

export const primitive_ClassTransform_Types = {
	string: () => {
		return String;
	},
	number: () => {
		return Number;
	},
};

export const recordTransformerFactory = function <T>(
	genericType: () => ClassConstructor<T>,
) {
	return (params: TransformFnParams) => {
		if (params.type === TransformationType.PLAIN_TO_CLASS) {
			if (params.value != null) {
				const record = {};
				Object.entries(params.value).forEach(([k, v]) => {
					record[k] = plainToInstance(genericType(), v);
				});

				return record;
			} else {
				return null;
			}
		} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
			return instanceToPlain(params.value);
		} else if (params.type === TransformationType.CLASS_TO_CLASS) {
			if (params.value != null) {
				const record = {};
				Object.entries(params.value).forEach(([k, v]) => {
					record[k] = instanceToInstance(genericType(), v);
				});

				return record;
			} else {
				return null;
			}
		} else {
			return params.value;
		}
	};
};
