import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { GroupModel } from '../../generic/groups/group.model';

export class ProgramPaperworkItemModel {
	title: string;
	@Transform(dateTransformer)
	created: Date;
	@Transform(dateTransformer)
	end_date: Date;
	file_id: string;
	file_name: string;
	file_url: string;
	@Transform(dateTransformer)
	file_updated: Date;
	group_count: number;
	paperwork_id: number;
	@Type(PaperworkNoteModel_ClassTransform_Type)
	notes: PaperworkNoteModel[];
	type: { id: string; title: string };
	additional_file_keys: string[];
	groups?: GroupModel[];
	activation_requirement: boolean;
}

export function ProgramPaperworkItemModel_ClassTransform_Type() {
	return ProgramPaperworkItemModel;
}

export class ProgramPaperworkResponseModel {
	count: number;
	@Type(ProgramPaperworkItemModel_ClassTransform_Type)
	paperwork: ProgramPaperworkItemModel[];
}

export interface ProgramPaperworkPagingArgument {
	title?: PagingSearchFieldArgument;
	type_title?: PagingSearchFieldArrayArgument;
	created?: PagingSearchFieldArgument;
	file_updated?: PagingSearchFieldArgument;
	group_count?: PagingSearchFieldArgument;
}

export interface ProgramPaperworkEditArgument {
	title: string;
	file_id?: number;
	type: string;
	groups: string[];
	additional_file_keys: string[];
	notes: string;
	file: File;
}

export class PaperworkTypeModel {
	id: string;
	title: string;
}

export function PaperworkTypeModel_ClassTransform_Type() {
	return PaperworkTypeModel;
}

export class PaperworkTypeResponseModel {
	@Type(PaperworkTypeModel_ClassTransform_Type)
	paperwork_types: PaperworkTypeModel[];
}

export class PaperworkNoteModel {
	@Transform(dateTransformer)
	date: Date;
	note: string;
	user: string;
}

export function PaperworkNoteModel_ClassTransform_Type() {
	return PaperworkNoteModel;
}
