import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class CreditLimitExceptionModel {
	id: number;
	uid: number;
	additional_credits: number;
	@Expose({ name: 'requested_for_timestamp' })
	@Transform(dateTransformer)
	requested_for_date: Date;
	requested_by_uid: number;
	requested_by_name: string;
	@Expose({ name: 'requested_on_timestamp' })
	@Transform(dateTransformer)
	requested_on_date: Date;
	@Expose({ name: 'approved_on_timestamp' })
	@Transform(dateTransformer)
	approved_on_date: Date;
	approved_by_uid: number;
	approved_by_name: string;
	@Expose({ name: 'rejected_on_timestamp' })
	@Transform(dateTransformer)
	rejected_on_date: Date;
	rejected_by_uid: number;
	rejected_by_name: string;
	rejected_reason: string;
	reason: string;
}

export interface CreditLimitRequestArgument {
	reason: string;
}

export interface CreditLimitRequestApprovalArgument {
	action: string;
	reason?: string;
}

export enum CreditLimitApproveReject {
	reject = 'reject',
	approve = 'approve',
}
