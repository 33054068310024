@if (clonedFormGroup != null) {
	<form [formGroup]="clonedFormGroup" (submit)="accept()">
		<div class="modal-header">
			<h1 class="modal-title">Advanced Search</h1>
			<ae-modal-mover />
			<button
				class="btn btn-outline-dark"
				title="Close"
				type="button"
				(click)="activeModal.dismiss()"
			>
				<i aria-hidden="true" class="far fa-times"></i>
			</button>
		</div>
		<div class="modal-body">
			<div class="row">
				@for (columnTemplate of searchableColumns(); track columnTemplate) {
					<div class="col-sm-6">
						<div class="form-group">
							<label [for]="columnTemplate.columnId">
								<ng-container
									[ngTemplateOutlet]="columnTemplate.headerTemplate.template"
								/>
							</label>
							@if (columnTemplate.searchTemplate != null) {
								<ng-container
									[ngTemplateOutlet]="columnTemplate.searchTemplate.template"
									[ngTemplateOutletContext]="{ $implicit: clonedFormGroup }"
								/>
							}
						</div>
						@if (customFormTemplate != null) {
							@if (customFormTemplate.template != null) {
								<ng-container
									[ngTemplateOutlet]="customFormTemplate.template"
									[ngTemplateOutletContext]="{ $implicit: clonedFormGroup }"
								/>
							}
						}
					</div>
				}
			</div>
		</div>
		<div class="modal-footer">
			<button class="btn btn-secondary" type="button" (click)="cancel()">
				Cancel
			</button>
			<button class="btn btn-primary" type="submit">Search</button>
		</div>
	</form>
}
