import { NgTemplateOutlet } from '@angular/common';
import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	TemplateRef,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { isString } from 'src/lib/utilities/compare';

export interface IconTooltipTypeDefinition {
	icon: string;
	color: string;
}

export type IconTooltipKey =
	| 'help'
	| 'pending'
	| 'note'
	| 'log'
	| 'alert'
	| 'note2';

export type IconTooltipType = IconTooltipKey | IconTooltipTypeDefinition;

const config: Record<IconTooltipKey, IconTooltipTypeDefinition> = {
	help: {
		icon: 'far fa-question-circle',
		color: 'text-info',
	},
	pending: {
		icon: 'far fa-clock',
		color: '',
	},
	note: {
		icon: 'fas fa-comment-alt-exclamation',
		color: '',
	},
	log: {
		icon: 'far fa-info-circle',
		color: '',
	},
	alert: {
		icon: 'fas fa-circle-exclamation',
		color: 'text-danger',
	},
	note2: {
		icon: 'far fa-comment-exclamation',
		color: '',
	},
};

@Component({
	selector: 'ae-icon-tooltip',
	templateUrl: './icon-tooltip.component.html',
	styleUrls: ['./icon-tooltip.component.scss'],
	standalone: true,
	imports: [NgbTooltip, NgTemplateOutlet],
})
export class IconTooltipComponent implements OnChanges, OnInit {
	@Input() message: string | TemplateRef<any>;
	@Input() type: IconTooltipType;
	@Input() id: string;

	protected definition: IconTooltipTypeDefinition;
	protected messageTemplate: TemplateRef<any>;
	protected messageString: string;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.message) {
			this.messageTemplate = null;
			this.messageString = null;

			if (isString(this.message)) {
				this.messageString = this.message;
			} else {
				this.messageTemplate = this.message;
			}
		}

		if (changes.type) {
			if (isString(this.type)) {
				this.definition = config[this.type];
			} else {
				this.definition = this.type;
			}
		}
	}

	ngOnInit(): void {
		if (this.type == null) {
			throw new Error('IconTooltipComponent is missing type');
		}
	}
}
