import { Pipe, PipeTransform } from '@angular/core';
import type { Format, Spacetime } from 'spacetime';
import { isString } from 'src/lib/utilities/compare';

@Pipe({
	name: 'spaceFormat',
	standalone: true,
})
export class SpaceFormatPipe implements PipeTransform {
	transform(value: Spacetime, format: Format | string): string {
		if (!isString(format)) throw Error(`No format given for SpaceFormatPipe`);

		if (value == null) return '';
		else {
			return value.format(format) as string;
		}
	}
}
