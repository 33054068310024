import {
	AfterContentChecked,
	ContentChild,
	Directive,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { PaginationTableColumnDisplayDirective } from './pagination-table-column-display.directive';
import { PaginationTableColumnExportDisplayDirective } from './pagination-table-column-export-display.directive';
import { PaginationTableColumnHeaderDirective } from './pagination-table-column-header.directive';
import { PaginationTableColumnSearchDirective } from './pagination-table-column-search.directive';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'ae-pgt-column',
	standalone: true,
})
export class PaginationTableColumnDirective<
		T,
		F extends {
			[K in keyof F]: AbstractControl<any>;
		} = any,
	>
	implements AfterContentChecked, OnChanges
{
	@Input()
	public disabled: boolean = false;
	@Input()
	public columnId: string;
	@Input()
	public sortable: boolean = true;
	@Input()
	public alternateViews: [standard: string, alternate: string] = null;
	@Input()
	public starWidth: number = 1;
	@Input()
	public fixedWidth: string | null = null;
	@Input()
	public isDefault: boolean = false;
	@Input()
	public isAuxiliary: boolean = false;
	@Input()
	public isExportable: boolean = true;
	@Input()
	public autoTitle: boolean | ((item: T, columnId?: string) => string) = true;
	@Input()
	public locked: boolean = false;

	@ContentChild(PaginationTableColumnHeaderDirective)
	public headerTemplate: PaginationTableColumnHeaderDirective;
	@ContentChild(PaginationTableColumnSearchDirective)
	public searchTemplate: PaginationTableColumnSearchDirective<T, F>;
	@ContentChild(PaginationTableColumnDisplayDirective)
	public displayTemplate: PaginationTableColumnDisplayDirective<T>;
	@ContentChild(PaginationTableColumnExportDisplayDirective)
	public exportDisplayTemplate: PaginationTableColumnExportDisplayDirective<T>;

	private onChange = new Subject<SimpleChanges>();
	public onChange$ = this.onChange.asObservable();

	ngAfterContentChecked(): void {
		if (this.columnId == null) {
			throw Error('ae-pgt-column is missing required input columnId');
		}
		if (this.headerTemplate == null) {
			throw Error(
				'ae-pgt-column is missing required child ae-pgt-column-header',
			);
		}
		if (this.displayTemplate == null) {
			throw Error(
				'ae-pgt-column is missing required child ae-pgt-column-display',
			);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.onChange.next(changes);
	}
}
