import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class AbDrillDownAcNoContactModel {
	student_uid: number;
	student_name: string;
	@Transform(dateTransformer)
	last_access: Date;
	@Transform(dateTransformer)
	date_assigned: Date;
	@Transform(dateTransformer)
	contact_start: Date;
	@Transform(dateTransformer)
	contact_end: Date;
	missing_contact_periods: number;
}
