import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Editor } from 'ngx-editor';
import { EditorView } from 'prosemirror-view';
import { Subscription } from 'rxjs';
import { hasValue } from 'src/lib/utilities/compare';
import FontSizeMark from './FontSizeMark';

interface FontSize {
	name: string;
	value: string;
}

@Component({
	selector: 'ae-ngx-editor-size-menu',
	template: `
		<select
			class="form-select form-select-sm"
			placeholder="Select Size"
			[formControl]="activeItemCtrl"
		>
			@for (i of sizes; track i) {
				<option [ngValue]="i.value">{{ i.name }}</option>
			}
		</select>
	`,
	styles: [':host {display: flex}'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule],
})
export class NgxEditorSizeMenuComponent implements OnInit, OnDestroy {
	private editorView: EditorView;
	private updateSubscription: Subscription;

	@Input() editor: Editor;
	@Input() group: string;

	isDropdownOpen = false;

	disabledItems: string[] = [];
	activeItem: FontSize;

	protected activeItemCtrl = new FormControl(null);

	protected sizes: FontSize[] = [
		{
			name: 'Small',
			value: 'small',
		},
		{
			name: 'Normal',
			value: 'normal',
		},
		{
			name: 'Large',
			value: 'large',
		},
		{
			name: 'Huge',
			value: 'huge',
		},
	];

	private commands: Record<string, FontSizeMark> = {
		huge: new FontSizeMark('huge'),
		large: new FontSizeMark('large'),
		small: new FontSizeMark('small'),
		normal: new FontSizeMark(null),
	};

	constructor() {
		this.activeItemCtrl.valueChanges.subscribe((value: string) => {
			value = hasValue(value) ? value : this.activeItem?.value;
			const { state, dispatch } = this.editorView;
			const command = this.commands[value];
			command.toggle()(state, dispatch);
		});
	}

	get isSelected(): boolean {
		return Boolean(this.activeItem || this.isDropdownOpen);
	}

	private update = (view: EditorView) => {
		const { state } = view;
		this.disabledItems = [];
		const activeItems = [];

		this.sizes.forEach((item: FontSize) => {
			if (item) {
				const command = this.commands[item.value];
				const isActive = command ? command.isActive(state) : true;

				if (isActive) {
					activeItems.push(item);
				}

				if (command ? !command.canExecute(state) : true) {
					this.disabledItems.push(item.value);
				}
			}
		});

		if (activeItems.length === 0) {
			[this.activeItem] = [this.sizes[1]];
			activeItems.push(this.activeItem);
		}

		if (
			this.disabledItems.length === this.sizes?.length &&
			this.activeItemCtrl.enabled
		) {
			this.activeItemCtrl.disable();
		} else if (
			this.disabledItems.length !== this.sizes?.length &&
			this.activeItemCtrl.disabled
		) {
			this.activeItemCtrl.enable();
		}

		if (activeItems.length === 1) {
			[this.activeItem] = activeItems;
			this.activeItemCtrl.setValue(this.activeItem.value, { emitEvent: false });
		} else {
			this.activeItem = null;
			this.activeItemCtrl.setValue(null, { emitEvent: false });
		}
	};

	ngOnInit(): void {
		this.editorView = this.editor.view;

		this.updateSubscription = this.editor.update.subscribe(
			(view: EditorView) => {
				this.update(view);
			},
		);
	}

	ngOnDestroy(): void {
		this.updateSubscription.unsubscribe();
	}
}
