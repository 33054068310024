<div class="page-title-header">
	<h1 class="page-title-title fs-4">
		@if (pageHeaderTitleTemplate != null) {
			<ng-container [ngTemplateOutlet]="pageHeaderTitleTemplate" />
		} @else {
			{{ pageHeaderTitle }}
		}
	</h1>
	<div class="page-title-content"><ng-content /></div>
</div>
