import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	JsonApiDataModel,
	JsonApiDataModel_ClassTransform_TransformFactory,
	JsonApiModel,
} from '../json-api.model';

export class DegreeModel {
	@Expose({ name: 'drupal_internal__id' })
	id: number;
	langcode: string;
	status: boolean;
	name: string;

	@Transform(dateTransformer)
	created: Date;

	@Transform(dateTransformer)
	changed: Date;

	cip2010_code: string;
	description: string;
}

export function DegreeModel_ClassTransform_Type() {
	return DegreeModel;
}

export class DegreeJsonApiModel extends JsonApiModel<DegreeModel> {
	@Transform(
		JsonApiDataModel_ClassTransform_TransformFactory(
			DegreeModel_ClassTransform_Type,
		),
	)
	data?: JsonApiDataModel<DegreeModel>[];
}
