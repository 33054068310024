import { NgClass } from '@angular/common';
import { Component } from '@angular/core';

@Component({
	selector: 'ae-spin-while',
	template: `
		<div aria-busy="true" title="Loading" [ngClass]="alignmentClass">
			<span
				>{{ spinMessage
				}}<i class="far fa-spin fa-spinner" [ngClass]="spinClass"></i
			></span>
		</div>
	`,
	standalone: true,
	imports: [NgClass],
})
export class SpinWhileComponent {
	public spinClass: string = 'fa-3x';
	public alignmentClass: string = 'text-center my-4';
	public spinMessage: string = '';
}
