import { Type } from 'class-transformer';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class StudentQueueItemModel {
	uid: number;
	profile_first_name: string;
	profile_preferred_name: string;
	profile_last_name: string;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
}
