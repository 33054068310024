<section #rootElement class="billboard" tabindex="-1">
	<div class="billboard-header">
		<h2 class="billboard-title">Account</h2>
		@if (canEditAccount) {
			<div class="billboard-actions">
				<div class="btn-toolbar">
					<button
						class="btn btn-secondary"
						id="profile.account.edit.cancel"
						type="button"
						(click)="accountSaveListener(false)"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						id="profile.account.edit.enable"
						type="button"
						[disabled]="savingInfo || !accountFormGroup.valid"
						(click)="save()"
					>
						Save Changes
					</button>
				</div>
			</div>
		}
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading" [formGroup]="accountFormGroup">
			<!-- Account Info -->
			<h3 class="fw-bold fs-5">Account Info</h3>
			<div class="row mb-1">
				<div class="col-xl-3 col-lg-4 mb-3">
					<div class="fw-bold">Created</div>
					<div>
						{{
							accountData?.created
								? (accountData?.created | date: 'yyyy-MM-dd hh:mm a')
								: '-'
						}}
					</div>
				</div>
				<div class="col-xl-3 col-lg-4 mb-3">
					<div class="fw-bold">Last Online</div>
					<div>
						{{
							accountData?.last_online
								? (accountData?.last_online | date: 'yyyy-MM-dd hh:mm a')
								: '-'
						}}
					</div>
				</div>
				@if (accountData?.status != null) {
					<div class="col-xl-3 col-lg-4 mb-3">
						<div class="fw-bold">Status</div>
						<div>{{ accountData.status ? 'Active' : 'Not Active' }}</div>
					</div>
				}
			</div>

			<div class="row">
				<div class="col-xl-6 col-lg-8 mb-3">
					<div class="fw-bold">Assigned Programs</div>
					@if (gaData?.programs?.length >= 1) {
						<ul>
							@for (program of gaData?.programs?.sort(); track program) {
								<li>
									{{ program }}
								</li>
							}
						</ul>
					}
					@if (gaData?.programs?.length < 1 ?? true) {
						<div>-</div>
					}
				</div>

				@if (formattedAccessType != null) {
					<div class="col-xl-6 col-lg-4 mb-3">
						<div class="fw-bold">
							Assigned Roles
							@if (canEditRoles) {
								<button
									class="btn btn-icon"
									id="profile.account.edit.roles"
									title="Edit Assigned Roles"
									type="button"
									(click)="editRoles()"
								>
									<i aria-hidden="true" class="fas fa-pencil"></i>
								</button>
							}
						</div>
						@if (formattedAccessType.length > 0) {
							<ul>
								@for (role of formattedAccessType; track role) {
									<li>
										{{ role }}
									</li>
								}
							</ul>
						}
						@if (formattedAccessType.length < 1) {
							<div>-</div>
						}
					</div>
				}

				@if (assignedStudents != null) {
					<div class="col mb-3">
						<div class="fw-bold">Assigned Students</div>
						@if (assignedStudents.length > 0) {
							<ul>
								@for (student of assignedStudents; track student) {
									<li>
										{{ student }}
									</li>
								}
							</ul>
						}
						@if (assignedStudents.length < 1) {
							<div>-</div>
						}
					</div>
				}

				@if (canEditLinkedAccounts) {
					<div class="col mb-3">
						<div class="fw-bold">
							External Accounts
							<button
								class="btn btn-icon"
								id="profile.account.external-accounts.edit"
								title="Add External Account"
								type="button"
								(click)="addExternalAccounts()"
							>
								<i aria-hidden="true" class="fas fa-pencil"></i>
							</button>
						</div>
						@if (externalAccounts?.length > 0) {
							<ul>
								@for (account of externalAccounts; track account) {
									<li>
										{{ account.title }}
									</li>
								}
							</ul>
						} @else {
							<div>-</div>
						}
					</div>
				}
			</div>

			<!-- Administrator Settings -->
			<h3 class="fw-bold fs-5">Administrator Settings</h3>
			<div class="form-group">
				<label for="profile.account.edit.title"> Title </label>
				<input
					autocomplete="off"
					class="form-control"
					formControlName="title"
					id="profile.account.edit.title"
					type="text"
				/>
			</div>

			<div aeGroupValidation class="form-group">
				<label for="profile.account.edit.admin_type">
					Administrator Type*
				</label>
				<ng-select
					bindLabel="title"
					bindValue="key"
					formControlName="admin_type"
					labelForId="profile.account.edit.admin_type"
					[clearable]="true"
					[items]="dropdownAdminType"
					[multiple]="true"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Administrator Type is required
					</ng-template>
				</ae-group-validation-display>
			</div>

			<!--Employee ID-->
			@if (canEditId) {
				<div class="row">
					<div aeGroupValidation class="col-lg-12 form-group">
						<label for="profile.account.edit.employee_id"> Employee ID </label>
						<input
							autocomplete="off"
							class="form-control"
							formControlName="employee_id"
							id="profile.account.edit.employee_id"
							type="text"
						/>
					</div>
				</div>
			}
		</div>
	</div>
</section>
