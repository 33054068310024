import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentPathModel } from 'src/lib/services/api/students/path/student-path.model';
import { StudentPathService } from 'src/lib/services/api/students/path/student-path.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentPathStoreService {
	private _pathCache = new BehaviorCache<number, StudentPathModel>(
		(uid) => this.studentPathService.getPath(uid),
		'StudentPathStore PathCache',
	);

	constructor(private studentPathService: StudentPathService) {}

	/**
	 * Path
	 */
	public path$ = (uid: number): Observable<StudentPathModel> => {
		return this._pathCache.getCache(uid);
	};

	public refreshPath = (uid: number): Promise<boolean> => {
		return this._pathCache.fetchData(uid, true);
	};
}
