import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { ActivityBoardAdminItemModel } from '../activity-board-admin.model';
import { ActivityBoardAvailableItemModel } from '../activity-board-available.model';
import { ActivityBoardChangeArgument } from '../activity-board-change.model';
import { ActivityBoardTranscriptResponseModel } from './activity-board-transcript.model';

@Injectable({
	providedIn: 'root',
})
export class ActivityBoardTranscriptService {
	constructor(private httpClient: HttpClient) {}

	public getActivityBoard = (
		currentUserUid: number,
		daysFilter?: number,
	): Observable<ActivityBoardTranscriptResponseModel> => {
		const query = buildQueryString({
			active_days: daysFilter,
		});

		return this.httpClient
			.get<ActivityBoardTranscriptResponseModel>(
				`/api/v1/activity-boards/transcript/${currentUserUid}${query}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ActivityBoardTranscriptResponseModel, r),
					{
						errorCode: '9a426063',
						validateSuccess: false,
						checkPreMap: false,
					},
				),
			);
	};

	public getAvailableTranscripts = (
		uid: number,
	): Observable<ActivityBoardAvailableItemModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/transcript/${uid}/manage/available`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(ActivityBoardAvailableItemModel, r.people as any[]),
					{ errorCode: '7f4364d0' },
				),
			);
	};

	public changeTranscripts = (
		uid: number,
		args: ActivityBoardChangeArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/activity-boards/transcript/${uid}/manage`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'c0da7a82',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getTranscriptAdminList = (): Observable<
		ActivityBoardAdminItemModel[]
	> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/transcript/all`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAdminItemModel,
							r.activity_boards as any[],
						),
					{ errorCode: '2916bc69' },
				),
			);
	};
}
