<div class="profile-img profile-img-size-{{ size }}">
	<img
		#profileImage
		alt=""
		aria-hidden="true"
		class="img-thumbnail p-0 rounded-circle"
		[src]="src || defaultProfileImageUrl"
		(error)="profileImageError(profileImage)"
	/>
</div>
