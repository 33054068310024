import { KeyValue } from '@angular/common';
import { Type } from 'class-transformer';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class CurriculumMappingEndorsementBaseModel {
	id: number;
	name: string;
	state: string;
	min_grade_level: number;
	max_grade_level: number;
}

export function CurriculumMappingEndorsementBaseModel_ClassTransform_Type() {
	return CurriculumMappingEndorsementBaseModel;
}

export class CurriculumMappingEndorsementAssignedModel extends CurriculumMappingEndorsementBaseModel {
	assigned_min_grade_level: number;
	assigned_max_grade_level: number;
}

export function CurriculumMappingEndorsementAssignedModel_ClassTransform_Type() {
	return CurriculumMappingEndorsementAssignedModel;
}

export class CurriculumMappingEndorsementCourseModel {
	course_id: number;
	course_title: string;
	course_number: string;
}

export function CurriculumMappingEndorsementCourseModel_ClassTransform_Type() {
	return CurriculumMappingEndorsementCourseModel;
}

export class CurriculumMappingEndorsementTeacherModel {
	teacher_uid: number;
	teacher_name: string;
}

export function CurriculumMappingEndorsementTeacherModel_ClassTransform_Type() {
	return CurriculumMappingEndorsementTeacherModel;
}

// endorsement list
export class CurriculumMappingEndorsementListItemModel extends CurriculumMappingEndorsementBaseModel {
	count_courses_that_require: number;
	count_teachers_assigned: number;
}

export function CurriculumMappingEndorsementListItemModel_ClassTransform_Type() {
	return CurriculumMappingEndorsementListItemModel;
}

export class CurriculumMappingEndorsementListResponseModel {
	count: number;
	@Type(CurriculumMappingEndorsementListItemModel_ClassTransform_Type)
	endorsements: CurriculumMappingEndorsementListItemModel[];
}

// single endorsement
export class CurriculumMappingEndorsementModel extends CurriculumMappingEndorsementBaseModel {
	@Type(CurriculumMappingEndorsementTeacherModel_ClassTransform_Type)
	teachers_assigned: CurriculumMappingEndorsementTeacherModel[];
	@Type(CurriculumMappingEndorsementCourseModel_ClassTransform_Type)
	courses_that_require: CurriculumMappingEndorsementCourseModel[];
}

export function CurriculumMappingEndorsementModel_ClassTransform_Type() {
	return CurriculumMappingEndorsementModel;
}

export interface CurriculumMappingEndorsementUpsertArgument {
	name: string;
	state: string;
	min_grade_level: number;
	max_grade_level: number;
}

export class CurriculumMappingEndorsementOptionsModel {
	state: KeyValue<string, string>[];
}

export interface CurriculumMappingEndorsementsPagingArgument {
	state?: PagingSearchFieldArgument;
	name?: PagingSearchFieldArgument;
	grade_string?: PagingSearchFieldArgument;
	count_courses_that_require?: PagingSearchFieldArgument;
	count_teachers_assigned?: PagingSearchFieldArgument;
}
