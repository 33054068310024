import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'ae-unread-counter',
	templateUrl: './unread-counter.component.html',
	styleUrls: ['./unread-counter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [],
})
export class UnreadCounterComponent implements OnChanges {
	@Input() count: number;

	public displayCount: string;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.count) {
			const count = changes.count.currentValue;
			if (count <= 0) {
				this.displayCount = null;
			} else if (count > 9) {
				this.displayCount = `9+`;
			} else {
				this.displayCount = `${count}`;
			}
		}
	}
}
