@if (editForm) {
	<form [formGroup]="editForm" (ngSubmit)="save()">
		<div class="modal-header">
			<h1 class="modal-title">
				{{
					editForm.controls.create.value ? 'Create New Group' : 'Rename Group'
				}}
			</h1>
			<button
				class="btn btn-outline-dark"
				title="Close"
				type="button"
				(click)="dismiss()"
			>
				<i aria-hidden="true" class="far fa-times"></i>
			</button>
		</div>
		<div class="modal-body">
			<div aeGroupValidation class="form-group">
				<label
					for="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.edit-saved.name"
					>Name</label
				>
				<input
					autocomplete="off"
					class="form-control"
					formControlName="name"
					type="text"
					id="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.edit-saved.name"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Name is required
					</ng-template>
					<ng-template aeValidationError="duplicate">
						There is already a saved configuration with that name
					</ng-template>
					<ng-template aeValidationError="protected">
						Untitled is a protected value
					</ng-template>
				</ae-group-validation-display>
			</div>
			@if (editForm.controls.create.value) {
				<div aeGroupValidation class="form-group">
					<label>
						<input
							formControlName="copy"
							type="checkbox"
							id="pgtable.{{
								pgTable.tableConfig.tableId
							}}.configcontrols.edit-saved.copy"
						/>
						Copy selected group
					</label>
				</div>
			}
		</div>
		<div class="modal-footer">
			<button
				class="btn btn-secondary"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.edit-saved.cancel"
				(click)="dismiss()"
			>
				Cancel
			</button>
			<button
				class="btn btn-primary"
				type="submit"
				[disabled]="!editForm.valid"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.edit-saved.save"
			>
				Ok
			</button>
		</div>
	</form>
}
