import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

import {
	GraduationReviewRequestChecklistModel,
	GraduationReviewRequestChecklistModel_ClassTransformType,
	GraduationReviewStatus,
} from '../students/graduation-review/graduation-review.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class GradCoordinatorPauseModel {
	pause_status: string;
	@Transform(dateTransformer)
	pause_request_date: Date;
	@Transform(dateTransformer)
	pause_start_date: Date;
	@Transform(dateTransformer)
	pause_end_date: Date;
	pause_exit_code: string;
	pause_exit_code_title: string;
	pause_request_explanation: string;
}

export function GradCoordinatorPauseModel_ClassTransform_Type() {
	return GradCoordinatorPauseModel;
}

export class GradCoordinatorGradRequestModel {
	@Type(GraduationReviewRequestChecklistModel_ClassTransformType)
	checklist: GraduationReviewRequestChecklistModel[];
	checklist_notes: string;
	id: number;
	@Transform(dateTransformer)
	last_updated_on: Date;
	note: string;
	status: GraduationReviewStatus;
	status_title: string;
	@Transform(dateTransformer)
	requested_on: Date;
}

export function GradCoordinatorGradRequestModel_ClassTransform_Type() {
	return GradCoordinatorGradRequestModel;
}

export class GradCoordinatorTrackerModel {
	get profile_first_name() {
		return this.first_name;
	}
	get profile_preferred_name() {
		return this.first_name;
	}
	get profile_last_name() {
		return this.last_name;
	}

	first_name: string;
	last_name: string;
	uid: number;
	user_link_id: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	enrollment_status: number;
	@Type(GradCoordinatorPauseModel_ClassTransform_Type)
	pause: GradCoordinatorPauseModel;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	state_id: string;
	institute_state: string;
	credits_remaining: string;
	@Type(GradCoordinatorGradRequestModel_ClassTransform_Type)
	graduation_request: GradCoordinatorGradRequestModel;
	cohort: string;
	credits_completed: string;
}

export function GradCoordinatorTrackerModel_ClassTransform_Type() {
	return GradCoordinatorTrackerModel;
}

export class GradCoordinatorTrackerResponseModel {
	success: boolean;
	count: number;
	@Type(GradCoordinatorTrackerModel_ClassTransform_Type)
	results: GradCoordinatorTrackerModel[];
}

export interface GradCoordinatorSearchArgument {
	first_name: PagingSearchFieldArgument;
	last_name: PagingSearchFieldArgument;
	uid: PagingSearchFieldArgument;
	institute_title: PagingSearchFieldArgument;
	institute_type: PagingSearchFieldArgument;
	institute_state: PagingSearchFieldArrayArgument;
	state_id: PagingSearchFieldArgument;
	pause_start_date: PagingSearchFieldArgument;
	graduation_request_status: PagingSearchFieldArrayArgument;
	graduation_request_requested_on: PagingSearchFieldArgument;
	graduation_request_last_updated_on: PagingSearchFieldArgument;
	credits_remaining: PagingSearchFieldArgument;
	work_queue: PagingSearchFieldArgument;
	graduation_review_request_checklist_last_mile_verification_completed_on: PagingSearchFieldArgument;
	graduation_review_request_checklist_state_specific_requirements_completed_on: PagingSearchFieldArgument;
	graduation_review_request_checklist_credits_reviewed_completed_on: PagingSearchFieldArgument;
	graduation_review_request_checklist_testing_completed_on: PagingSearchFieldArgument;
	enrollment_status: PagingSearchFieldArgument;
	cohort: PagingSearchFieldArgument;
	credits_completed: PagingSearchFieldArgument;
}
