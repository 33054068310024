import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	CurriculumMappingCertificationModel,
	CurriculumMappingCertificationModel_ClassTransform_Type,
} from '../certifications/curriculum-mapping-certification.model';
import { CurriculumMappingSectionTeacherMappingModel } from '../curriculum-mapping-section-teacher-mapping.model';

export class CurriculumMappingTeacherListItemModel {
	name: string;
	uid: number;
	phone: string;
	@Transform(dateTransformer)
	dob: Date;
	certification_count: number;
	mapped_sections_count: number;
	@Expose({ name: 'substitute_teacher' })
	substitute_teacher_id: number;
	substitute_teacher_name: string;
	pic: string;
	notes: string;
}

export class CurriculumMappingTeacherMappedSectionsModel extends CurriculumMappingSectionTeacherMappingModel {
	section_title: string;
	section_id: number;
	version_code: number;
}

export function CurriculumMappingTeacherMappedSectionsModel_ClassTransform_Type() {
	return CurriculumMappingTeacherMappedSectionsModel;
}

export class CurriculumMappingTeacherModel {
	@Type(CurriculumMappingCertificationModel_ClassTransform_Type)
	certifications: CurriculumMappingCertificationModel[];
	@Transform(dateTransformer)
	dob: Date;
	@Type(CurriculumMappingTeacherMappedSectionsModel_ClassTransform_Type)
	mapped_sections: CurriculumMappingTeacherMappedSectionsModel[];
	name: string;
	notes: string;
	phone: boolean;
	pic: string;
	substitute_teacher: string;
	uid: number;
}

export class CurriculumMappingTeacherEditDataNameModel {
	first: string;
	middle: string;
	last: string;
	suffix: string;
	preferred?: string;
}

export function CurriculumMappingTeacherEditDataNameModel_ClassTransformType() {
	return CurriculumMappingTeacherEditDataModel;
}

export class CurriculumMappingTeacherEditDataModel {
	@Type(CurriculumMappingTeacherEditDataNameModel_ClassTransformType)
	name: CurriculumMappingTeacherEditDataNameModel;
	uid: number;
	@Transform(dateTransformer)
	birthdate: Date;
	substitute_teacher: number;
	substitute_teacher_name: string;
	pic: string;
	notes: string;
}

export interface CurriculumMappingTeacherEditDataArgument {
	substitute_teacher?: number;
	birthdate?: Date;
	notes?: string;
	pic?: string;
	name?: CurriculumMappingTeacherEditDataNameModel;
}
