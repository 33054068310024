<button
	class="toggle-container btn p-0 border-0"
	type="button"
	[ngClass]="{ 'value-on': value, 'container-block': display === 'block' }"
	(click)="toggleValue()"
>
	<div class="flipper"></div>
	<div class="on-value">
		@if (onTemplate) {
			<ng-container [ngTemplateOutlet]="onTemplate?.template" />
		} @else {
			<span>{{ onText }}</span>
		}
	</div>
	<div class="off-value">
		@if (offTemplate) {
			<ng-container [ngTemplateOutlet]="offTemplate?.template" />
		} @else {
			<span>{{ offText }}</span>
		}
	</div>
</button>
