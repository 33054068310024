import { KeyValue } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import spacetime, { Spacetime } from 'spacetime';
import {
	hasKey,
	hasValue,
	isDate,
	isInteger,
	isNumber,
	isString,
	isValidDate,
} from './compare';

export function convertToString(input: any): string | null {
	if (hasValue(input)) {
		return input.toString();
	} else if (input === '') {
		return '';
	}

	return null;
}

export function convertToPercentage(input: number): number | null {
	if (!isNumber(input)) {
		return null;
	} else {
		return Math.round(input * 100);
	}
}

export function convertToBoolean(
	input: boolean | number | string | null,
): boolean | null {
	if (!hasValue(input)) {
		return null;
	} else if (isString(input)) {
		// true/false
		if (input.trim().toLowerCase().indexOf('t') === 0) return true;
		if (input.trim().toLowerCase().indexOf('f') === 0) return false;

		// yes/no
		if (input.trim().toLowerCase().indexOf('y') === 0) return true;
		if (input.trim().toLowerCase().indexOf('n') === 0) return false;

		// try number parsing
		return Boolean(parseInt(input));
	} else {
		return Boolean(input);
	}
}

export function convertToDate(
	input: Date | string | number | NgbDateStruct,
): Date {
	if (!hasValue(input)) {
		return null;
	} else if (isString(input) || isDate(input) || isInteger(input)) {
		const st = spacetime(input as any);
		if (st.isValid()) {
			return st.toNativeDate();
		} else return null;
	} else if (
		input.hasOwnProperty('year') &&
		isInteger(input['year']) &&
		input.hasOwnProperty('month') &&
		isInteger(input['month']) &&
		input.hasOwnProperty('day') &&
		isInteger(input['day'])
	) {
		return new Date(input['year'], input['month'] - 1, input['day']);
	} else {
		return null;
	}
}

export function convertDateToNgbDateStruct(input: Date): NgbDateStruct {
	if (!isValidDate(input)) return null;

	return {
		year: input.getFullYear(),
		month: input.getMonth() + 1,
		day: input.getDate(),
	} as NgbDateStruct;
}

export function convertToInt(input: string | number): number | null {
	if (isNumber(input)) {
		return Math.floor(input);
	} else if (isString(input)) {
		const parsed = parseInt(input);
		if (isInteger(parsed)) {
			return parsed;
		}
	}

	return null;
}

export function convertMapToObject<V>(map: Map<number, V>): Record<number, V>;
export function convertMapToObject<V>(map: Map<string, V>): Record<string, V>;
export function convertMapToObject<V>(map: Map<any, V>): any {
	if (map == null) return null;

	const obj: any = {};

	for (const [key, val] of map.entries()) {
		obj[key] = val;
	}

	return obj;
}

export function convertMapToArray<K, V>(
	map: Map<K, V> | ReadonlyMap<K, V>,
): KeyValue<K, V>[] {
	const arr: KeyValue<K, V>[] = [];

	for (const [key, val] of map.entries()) {
		arr.push({
			key: key,
			value: val,
		});
	}

	return arr;
}

export function convertSetToArray<K>(set: Set<K>): K[] {
	const arr: K[] = [];

	for (const v of set.values()) {
		arr.push(v);
	}

	return arr;
}

export function convertDateToPhpTimestamp(d: Date | Spacetime): number | null {
	if (hasKey(d as Spacetime, 'epoch')) {
		return Math.trunc((d as Spacetime).epoch / 1000);
	} else if (isValidDate(d)) {
		return Math.trunc(d.getTime() / 1000);
	} else {
		return null;
	}
}

export function tryParseInt(input: string): number | null {
	if (isString(input)) {
		const parsed = parseInt(input);
		if (isInteger(parsed) && `${parsed}` === input.trim()) {
			return parsed;
		}
	}

	return null;
}
