<div class="row rightside-info-floater-outer-container">
	<div class="col col-lg-auto mb-3">
		<div
			#rightSideInfoContainer
			class="rightside-info-floater-container info-floater-container-{{
				rightSideInfoConfig?.size || 'md'
			}}"
			[ngClass]="{ minimized: collapsed, 'animation-on': animationOn }"
		>
			<button
				class="btn info-floater-collapse-btn"
				type="button"
				id="side.nav.info.component.{{ id }}.collapse.btn"
				title="{{ collapsed ? 'Expand' : 'Collapsed' }}"
				(click)="toggleCollapse()"
				(mouseenter)="buttonEnter()"
				(mouseleave)="buttonLeave()"
			>
				<i
					aria-hidden="true"
					class="fas text-primary fa-lg"
					[ngClass]="
						rightSideInfoConfig?.icon
							? rightSideInfoConfig.icon
							: 'fas fa-hamburger'
					"
				></i>
			</button>
			<div class="rightside-info-floater-cover">
				<div class="rightside-info-floater">
					<div class="nav-title">
						@if (rightSideInfoConfig?.header) {
							<div [ngTemplateOutlet]="rightSideInfoConfig.header"></div>
						} @else {}
					</div>
					<div [ngTemplateOutlet]="rightSideInfoConfig.body"></div>
				</div>
			</div>
			<div class="info-floater-hover-mouse-assist"></div>
		</div>
	</div>
</div>
