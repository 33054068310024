import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { OrganizationsTeachersItemModel } from './organizations-teachers-search-item.model';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsTeachersService {
	constructor(private httpClient: HttpClient) {}

	public searchTeachers = (
		name: string,
		ids?: number[],
	): Observable<OrganizationsTeachersItemModel[]> => {
		return this.httpClient
			.post<OrganizationsTeachersItemModel[]>(
				`/api/v1/organizations/all/staff/teachers`,
				{
					name,
					uids: ids,
				},
			)
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationsTeachersItemModel, r), {
					errorCode: 'D651D4A5',
				}),
			);
	};

	public getAllTeachers = (): Observable<OrganizationsTeachersItemModel[]> => {
		return this.httpClient
			.post<
				OrganizationsTeachersItemModel[]
			>(`/api/v1/organizations/all/staff/teachers?show_deactive=true`, {})
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationsTeachersItemModel, r), {
					errorCode: '6D23E940',
				}),
			);
	};
}
