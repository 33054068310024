import { Transform } from 'class-transformer';
import { integerTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class OrganizationStaffModel {
	uid: number;
	name: string;
	type: string;
	type_id: number;
	email: string[];
	@Transform(integerTransformer)
	phone: number[];
	calendar: string[];
	picture_path: string;
}

export function OrganizationStaffModel_ClassTransform_Type() {
	return OrganizationStaffModel;
}
