import { Transform } from 'class-transformer';
import { integerTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentGraduatePlacementModel {
	college_degree_type: string;
	college_degree_start_term: string;
	college_degree_start_year: number;
	career_title: string;
	career_start: Date;
	career_employer: string;
	career_wage_hourly: string;
	career_type: string;
	@Transform(integerTransformer)
	college_placement: number;
	college_placement_name: string;
	@Transform(integerTransformer)
	college_degree: number;
	college_degree_name: string;
}

export interface StudentGraduatePlacementArgument {
	college_degree_type: string;
	college_degree_start_term: string;
	college_degree_start_year: number;
	career_title: string;
	career_start: string;
	career_employer: string;
	career_wage_hourly: number;
	career_type: string;
	college_placement: number;
	college_degree: number;
}
