import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { AbstractPerformanceRequirementProof } from './abstract-performance-requirement-proof.model';

export class CoursePerformanceRequirementProof extends AbstractPerformanceRequirementProof {
	class_name: string;
	@Transform(dateTransformer)
	date: Date;
	credits: number;
}
