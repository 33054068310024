import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StudentTagModel } from './student-tag.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsTagsService {
	constructor(private httpClient: HttpClient) {}

	/** Tags */

	public getAllTags = (): Observable<string[]> => {
		return this.httpClient
			.get<any>(`/api/v1/students/tags/list`)
			.pipe(mapResponse((r) => r, { errorCode: 'A96ED29E' }));
	};

	public searchTags = (name: string): Observable<string[]> => {
		return this.httpClient
			.post<any>(`/api/v1/students/tags/list`, {
				name,
			})
			.pipe(mapResponse((r) => r, { errorCode: 'D606BDD0' }));
	};

	/** Student Tags */
	public getStudentTags = (uid: number): Observable<StudentTagModel[]> => {
		return this.httpClient.get<any>(`/api/v1/students/${uid}/tags`).pipe(
			mapResponse((r) => plainToInstance(StudentTagModel, r as any[]), {
				errorCode: '99BCF911',
			}),
		);
	};

	public addStudentTag = (
		uid: number,
		args: string[],
	): Observable<string[]> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/tags/add`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '0BAF61D0',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public removeStudentTag = (
		uid: number,
		args: string[],
	): Observable<string[]> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/tags/remove`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '04C5F94E',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
