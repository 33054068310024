import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentRelationshipsModel } from 'src/lib/services/api/students/relationships/student-relationships.model';
import { StudentRelationshipsService } from 'src/lib/services/api/students/relationships/student-relationships.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentRelationshipStoreService {
	private _relationshipCache = new BehaviorCache<
		number,
		StudentRelationshipsModel[]
	>(
		(uid) => this.studentRelationshipsService.getRelationships(uid),
		'StudentRelationshipStore RelationshipCache',
		() => [],
	);

	constructor(
		private studentRelationshipsService: StudentRelationshipsService,
	) {}

	/**
	 * Relationships
	 */
	public relationships$ = (
		uid: number,
	): Observable<StudentRelationshipsModel[]> => {
		return this._relationshipCache.getCache(uid);
	};

	public refreshRelationships = (uid: number): Promise<boolean> => {
		return this._relationshipCache.fetchData(uid, true);
	};
}
