import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class ReentryPreApprovalUserModel {
	uid: number;
	name: string;
}

export function ReentryPreApprovalUserModel_ClassTransform_Type() {
	return ReentryPreApprovalUserModel;
}

export class ReentryPreApprovalModel {
	@Type(ReentryPreApprovalUserModel_ClassTransform_Type)
	student: ReentryPreApprovalUserModel;
	@Type(ReentryPreApprovalUserModel_ClassTransform_Type)
	created_by: ReentryPreApprovalUserModel;
	@Transform(dateTransformer)
	created_on: Date;
	description: string;
	file: string;
	status: number;
}

export function ReentryPreApprovalModel_ClassTransform_Type() {
	return ReentryPreApprovalModel;
}

export interface ReentryPreApprovalArgument {
	target_program_id: number;
	description: string;
}

export interface ReentryCompleteArgument {
	graduation_plan: number;
	bypass_requirements?: boolean;
	bypass_requirements_reason?: string;
}
