import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class SafeBillingNotInvoicedModel {
	registration_id: number;
	registration_title: string;
	student_uid: number;
	student_first_name: string;
	student_last_name: string;
	institute_id: number;
	institute_title: string;
	credits: number;
	state_id: string;
	@Expose({ name: 'registration_start_timestamp' })
	@Transform(dateTransformer)
	registration_start_date: Date;
	@Expose({ name: 'registration_complete_timestamp' })
	@Transform(dateTransformer)
	registration_complete_date: Date;
	billed_status: number;
	more_other: string;
}

export function SafeBillingNotInvoicedModel_ClassTransform_Type() {
	return SafeBillingNotInvoicedModel;
}

export class SafeBillingNotInvoicedListModel {
	@Type(SafeBillingNotInvoicedModel_ClassTransform_Type)
	not_invoiced: SafeBillingNotInvoicedModel[];
	count: number;
}

export interface SafeBillingNotInvoicedSearchArgument {
	registration_title: PagingSearchFieldArgument;
	registration_start: PagingSearchFieldArgument;
	registration_complete: PagingSearchFieldArgument;
	state_id: PagingSearchFieldArgument;
	student_first_name?: PagingSearchFieldArgument;
	student_last_name?: PagingSearchFieldArgument;
	institute_id: PagingSearchFieldArrayArgument;
	credits?: PagingSearchFieldArgument;
	student_uid?: PagingSearchFieldArgument;
	institute_title?: PagingSearchFieldArgument;
	student_full_name?: PagingSearchFieldArgument;
	billed_status?: { value: number };
	registration_id?: PagingSearchFieldArgument;
}
