import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class PermissionUserRolesModel {
	uid: number;
	name: string;
	email: string;
	active: boolean;
	roles: string[];
	@Transform(dateTransformer)
	member_since: Date;
	@Transform(dateTransformer)
	last_login: Date;
	@Transform(dateTransformer)
	last_access: Date;
}

export function PermissionUserRolesModel_ClassTransform_Type() {
	return PermissionUserRolesModel;
}

export class PermissionUserRolesResponseModel {
	@Type(PermissionUserRolesModel_ClassTransform_Type)
	users: PermissionUserRolesModel[];
	count: number;
}

export interface PermissionUserSearchArgument {
	uid: PagingSearchFieldArgument;
	name: PagingSearchFieldArgument;
	email: PagingSearchFieldArgument;
	active: { value: boolean };
	roles: PagingSearchFieldArrayArgument;
	member_since: PagingSearchFieldArgument;
	last_login: PagingSearchFieldArgument;
}

export interface PermissionUserRolesArgument {
	add_roles: string[];
	remove_roles: string[];
	roles: string[];
}
