import { Type } from 'class-transformer';
import { AbstractActivityBoardResponseModel } from '../abstract-activity-board-response.model';

export class ActivityBoardTranscriptModel {
	person_uid: number;
	person_name: string;
	uploaded: number;
	entered: number;
	verified: number;
	mapped: number;
	locked: number;
}

export function ActivityBoardTranscriptModel_ClassTransformType() {
	return ActivityBoardTranscriptModel;
}

export class ActivityBoardTranscriptResponseModel extends AbstractActivityBoardResponseModel<ActivityBoardTranscriptModel> {
	@Type(ActivityBoardTranscriptModel_ClassTransformType)
	activity_board: ActivityBoardTranscriptModel[];
}
