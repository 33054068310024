// web/modules/custom/ga_student/src/Model/Requirement/ProgramEventType.php
export enum PerformanceRequirementNoteType {
	Milestone = 'milestone',
	Rollover = 'rollover',
	Attendance = 'attendance',
}

export interface PerformanceRequirementNoteInterface {
	type: PerformanceRequirementNoteType;
}

export abstract class AbstractPerformanceRequirementNote
	implements PerformanceRequirementNoteInterface
{
	public type: PerformanceRequirementNoteType;
}
