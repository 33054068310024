import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { StudentCurrentCourseModel } from 'src/lib/services/api/students/classes/current-courses/student-current-course.model';
import { StudentPastCourseModel } from 'src/lib/services/api/students/classes/past-courses/student-past-course.model';
import { StudentCourseProgressReportModel } from 'src/lib/services/api/students/classes/student-course-progress-report.model';
import {
	StudentCourseActivitiesModel,
	StudentCourseModel,
	StudentRegistrationTypes,
} from 'src/lib/services/api/students/classes/student-course.model';
import { StudentCourseService } from 'src/lib/services/api/students/classes/student-course.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentCourseStoreService {
	private _allClassesCache = new BehaviorCache<
		[uid: number, registrationId: number],
		StudentCourseModel
	>(
		([uid, registrationId]) =>
			this.courseService.getCourse(uid, registrationId),
		'StudentCourseStore AllClassesCache',
		() => null,
	);

	private _classProgressReportCache = new BehaviorCache<
		[uid: number, registrationId: number],
		StudentCourseProgressReportModel
	>(
		([uid, registrationId]) =>
			this.courseService.getCourseProgressReport(uid, registrationId),
		'StudentCourseStore ClassProgressReportCache',
		() => null,
		60 * 15, // 15 minutes
		true,
	);

	private _classActivitiesCache = new BehaviorCache<
		[uid: number, registrationId: number],
		StudentCourseActivitiesModel
	>(
		([uid, registrationId]) =>
			// Progress report resyncs with LMS so we do make sure that is done first
			this.classProgressReport$(uid, registrationId).pipe(
				take(1),
				switchMap(() =>
					this.courseService.getCourseActivities(uid, registrationId),
				),
			),
		'StudentCourseStore ClassActivitiesCache',
		() => null,
		60 * 15, // 15 minutes
	);

	private _currentCache = new BehaviorCache<
		number,
		StudentCurrentCourseModel[]
	>(
		(uid) =>
			this.courseService.getCurrentCourses(uid, {}).pipe(
				map((c) => c.results),
				tap((classes) => {
					classes?.forEach((c) => {
						const set = this._allClassesCache.getCacheSet([
							uid,
							c.registration_id,
						]);
						set.lastRefresh = new Date();
						set.data.next(c);
					});
				}),
			),
		'StudentCourseStore CurrentClassesCache',
		() => [],
	);

	private _currentPersistentCache = new BehaviorCache<
		number,
		StudentCurrentCourseModel[]
	>(
		(uid) =>
			this.courseService
				.getCurrentCourses(uid, {
					skip: 0,
					take: 1000,
					filters: {
						registration_type: {
							value: StudentRegistrationTypes.Persistent,
						},
					},
				})
				.pipe(map((x) => x.results)),

		'StudentCourseStore CurrentClassesCache',
		() => [],
	);

	private _pastCache = new BehaviorCache<number, StudentPastCourseModel[]>(
		(uid) =>
			this.courseService.getPastCourses(uid, { take: 1000 }).pipe(
				map((c) => c.results),
				tap((classes) => {
					classes?.forEach((c) => {
						const set = this._allClassesCache.getCacheSet([
							uid,
							c.registration_id,
						]);
						set.lastRefresh = new Date();
						set.data.next(c);
					});
				}),
			),
		'StudentCourseStore PastClassesCache',
		() => [],
	);

	private _allCache = new BehaviorCache<number, StudentCourseModel[]>(
		(uid) =>
			this.courseService.getAllCourses(uid, {}).pipe(
				map((c) => c.results),
				tap((classes) => {
					classes?.forEach((c) => {
						const set = this._allClassesCache.getCacheSet([
							uid,
							c.registration_id,
						]);
						set.lastRefresh = new Date();
						set.data.next(c);
					});
				}),
			),
		'StudentCourseStore AllClassesCache',
		() => [],
	);

	constructor(private courseService: StudentCourseService) {}

	/**
	 * Single
	 */
	public class$ = (
		uid: number,
		registrationId: number,
	): Observable<StudentCourseModel> => {
		return this._allClassesCache.getCache([uid, registrationId]);
	};

	public refreshClass = (
		uid: number,
		registrationId: number,
	): Promise<boolean> => {
		return this._allClassesCache.fetchData([uid, registrationId], true);
	};

	public classProgressReport$ = (
		uid: number,
		registrationId: number,
	): Observable<StudentCourseProgressReportModel> => {
		return this._classProgressReportCache.getCache([uid, registrationId]);
	};

	public refreshClassProgressReport = (
		uid: number,
		registrationId: number,
	): Promise<boolean> => {
		return this._classProgressReportCache.fetchData(
			[uid, registrationId],
			true,
		);
	};

	public clearClassProgressReport = (
		uid: number,
		registrationId: number,
	): void => {
		this._classProgressReportCache.clearData([uid, registrationId]);
	};

	public classProgressReportCacheKeyAdded = () => {
		return this._classProgressReportCache.keyAdded$;
	};

	public classActivities$ = (
		uid: number,
		registrationId: number,
	): Observable<StudentCourseActivitiesModel> => {
		return this._classActivitiesCache.getCache([uid, registrationId]);
	};

	public refreshClassActivities = (
		uid: number,
		registrationId: number,
	): Promise<boolean> => {
		// Progress report resyncs with LMS so we clear the data as we'll refetch it
		this.clearClassProgressReport(uid, registrationId);
		return this._classActivitiesCache.fetchData([uid, registrationId], true);
	};

	public clearClassActivities = (uid: number, registrationId: number): void => {
		// Progress report resyncs with LMS so we make sure that is done as well
		this.clearClassProgressReport(uid, registrationId);
		this._classActivitiesCache.clearData([uid, registrationId]);
	};

	/**
	 * Current
	 */
	public current$ = (uid: number): Observable<StudentCurrentCourseModel[]> => {
		return this._currentCache.getCache(uid);
	};

	public refreshCurrent = (uid: number): Promise<boolean> => {
		return this._currentCache.fetchData(uid, true);
	};

	public clearCurrent = (uid: number): void => {
		return this._currentCache.clearData(uid);
	};

	/**
	 * Past
	 */
	public past$ = (uid: number): Observable<StudentPastCourseModel[]> => {
		return this._pastCache.getCache(uid);
	};

	public refreshPast = (uid: number): Promise<boolean> => {
		return this._pastCache.fetchData(uid, true);
	};

	/**
	 * All
	 */
	public all$ = (uid: number): Observable<StudentCourseModel[]> => {
		return this._allCache.getCache(uid);
	};

	public refreshAll = (uid: number): Promise<boolean> => {
		return this._allCache.fetchData(uid, true);
	};

	/**
	 * Current Persistent
	 */
	public currentPersistent$ = (
		uid: number,
	): Observable<StudentCurrentCourseModel[]> => {
		return this._currentPersistentCache.getCache(uid);
	};

	public refreshCurrentPersistent = (uid: number): Promise<boolean> => {
		return this._currentPersistentCache.fetchData(uid, true);
	};

	public clearCurrentPersistent = (uid: number): void => {
		this._currentPersistentCache.clearData(uid);
	};
}
