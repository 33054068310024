import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { createValidFileExtension } from '../../controls/file-uploader/validators';

@Component({
	selector: 'ae-display-file',
	templateUrl: './display-file.component.html',
	styleUrls: ['./display-file.component.scss'],
	standalone: true,
	imports: [],
})
export class DisplayFileComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() class: string;

	@Input() file: File;

	@ViewChild('img')
	private imgElem: ElementRef;

	private afterViewInit = new AsyncSubject<null>();
	private imageFormat: string[] = ['jpeg', 'jpg', 'png', 'gif'];
	private checkIfImageFormat;

	public isImageFile: boolean;

	constructor(private rndr: Renderer2) {}

	ngOnInit() {
		if (this.file == null) {
			throw new Error('DisplayFileComponent did not recieve a file');
		}

		this.checkIfImageFormat = createValidFileExtension(this.imageFormat);

		this.isImageFile = this.checkIfImageFormat({ value: this.file }) == null;
	}

	ngAfterViewInit(): void {
		if (this.imgElem) {
			this.rndr.addClass(this.imgElem.nativeElement, 'hide');
		}

		this.afterViewInit.next(null);
		this.afterViewInit.complete();
	}

	ngOnChanges(_changes: SimpleChanges): void {
		this.afterViewInit.subscribe(() => {
			if (this.file && this.checkIfImageFormat({ value: this.file }) == null) {
				this.isImageFile = true;
				const fr = new FileReader();
				fr.onload = (_e) => {
					this.rndr.removeClass(this.imgElem.nativeElement, 'hide');
					this.imgElem.nativeElement.src = fr.result;
				};
				fr.readAsDataURL(this.file);
			} else {
				this.isImageFile = false;
			}
		});
	}
}
