<ng-select
	bindValue="id"
	loadingText="Loading Programs"
	placeholder="No Program Selected"
	[appendTo]="'body'"
	class="{{ class }}"
	[clearSearchOnAdd]="false"
	[clearable]="true"
	[closeOnSelect]="false"
	[formControl]="orgControl"
	[items]="_organizations"
	labelForId="program.multi.select.component.{{ id }}"
	[loading]="organizations == null"
	[maxSelectedItems]="maxSelectedItems"
	[multiple]="true"
	[searchFn]="orgsSearchFn"
	[virtualScroll]="true"
>
	<ng-template let-clear="clear" let-item="item" ng-label-tmp>
		<button
			class="btn p-0 d-inline-flex align-items-center"
			role="button"
			type="button"
			[disabled]="organizations == null"
			[ngClass]="
				showWarning &&
				(!item.is_active || (validIds && !validIds.includes(item.id)))
					? 'invalid-group-selection'
					: ''
			"
			(click)="clear(item)"
		>
			<ae-wait-spinner [saving]="organizations == null">
				@if (clear) {
					<span class="ng-value-icon left"><i class="far fa-times"></i></span>
				}
				<div class="ng-value-label">
					<div
						class="program-title"
						[ngClass]="!item.is_active ? 'text-decoration-line-through' : ''"
					>
						{{
							item.title == null && item.label == null
								? getInvalidOrganizationName(item)
								: item.title
						}}
					</div>
					@if (showFullLabel) {
						<div class="fs-sm">
							{{ item.parent_title }}
						</div>
					}
					@if (showFullLabel) {
						<div class="fs-xs fw-bold">
							{{ item.subtitle }}
						</div>
					}
				</div>
			</ae-wait-spinner>
		</button>
	</ng-template>
	<ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
		<div>
			<div
				class="program-title"
				[ngClass]="
					item.is_active === false ? 'text-decoration-line-through' : ''
				"
			>
				{{ item.title }}
			</div>
			<div class="fs-sm">
				{{ item.parent_title }}
			</div>
			<div class="fs-xs fw-bold">
				{{ item.subtitle }}
			</div>
		</div>
	</ng-template>
</ng-select>
