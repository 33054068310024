import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'keyToName',
	standalone: true,
})
export class KeyToNamePipe implements PipeTransform {
	transform(value: string): string {
		value = value?.replace(/_/g, ' ');
		return value;
	}
}
