import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentAssignmentModel } from './student-assignment.model';

@Injectable({
	providedIn: 'root',
})
export class StudentAssignmentService {
	constructor(private httpClient: HttpClient) {}

	public getRecentlyGradedAssignments = (
		studentId: number,
	): Observable<StudentAssignmentModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/assignments/recently_graded`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(StudentAssignmentModel, r.assignments as any[]),
					{ errorCode: '222E6A39' },
				),
			);
	};
}
