import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { ActivityBoardAdminItemModel } from '../activity-board-admin.model';
import { ActivityBoardAvailableItemModel } from '../activity-board-available.model';
import { ActivityBoardChangeArgument } from '../activity-board-change.model';
import { ActivityBoardLaResponseModel } from './activity-board-la.model';

@Injectable({
	providedIn: 'root',
})
export class ActivityBoardsLaService {
	constructor(private httpClient: HttpClient) {}

	public getActivityBoard = (
		currentUserUid: number,
	): Observable<ActivityBoardLaResponseModel> => {
		return this.httpClient
			.get<ActivityBoardLaResponseModel>(
				`/api/v1/activity-boards/local_advocate/${currentUserUid}`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(ActivityBoardLaResponseModel, r), {
					errorCode: '88FD93F8',
					validateSuccess: false,
					checkPreMap: false,
				}),
			);
	};

	public getAvailableLocalAdvocates = (
		uid: number,
	): Observable<ActivityBoardAvailableItemModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/local_advocate/${uid}/manage/available`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAvailableItemModel,
							r.local_advocates as any[],
						),
					{ errorCode: '165FC2A8' },
				),
			);
	};

	public changeLocalAdvocates = (
		uid: number,
		args: ActivityBoardChangeArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/activity-boards/local_advocate/${uid}/manage`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F2A0A12A',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getLaAdminList = (): Observable<ActivityBoardAdminItemModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/local_advocate/all`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAdminItemModel,
							r.activity_boards as any[],
						),
					{ errorCode: '9B1F4AA3' },
				),
			);
	};
}
