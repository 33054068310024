import { Injectable } from '@angular/core';
import { randomNumber } from 'src/lib/utilities/random';

/**https://github.com/bermi/password-generator/issues/22#issuecomment-477946847 */
@Injectable({ providedIn: 'root' })
export class PasswordGeneratorService {
	private vowel = /[aeiou]$/i;
	private consonant = /[bcdfghjklmnpqrstvwxyz]$/i;

	public generate = (
		length = 10,
		memorable = true,
		pattern = /\w/,
		prefix = '',
	): string => {
		const validChars: string[] = [];

		if (!memorable) {
			for (let i = 33; i < 126; i++) {
				const char = String.fromCharCode(i);

				if (char.match(pattern)) {
					validChars.push(char);
				}
			}

			if (!validChars.length) {
				throw new Error('Invalid pattern');
			}
		}

		while (prefix.length < length) {
			let char: string;

			if (memorable) {
				pattern = prefix.match(this.consonant) ? this.vowel : this.consonant;
				char = String.fromCharCode(randomNumber(33, 126)).toLowerCase();
			} else {
				char = validChars[randomNumber(0, validChars.length)];
			}

			if (char.match(pattern)) {
				prefix += char;
			}
		}

		return prefix;
	};
}
