import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise } from 'rxjs/operators';
import { isNumber } from 'src/lib/utilities/compare';

export enum ViewSize {
	xs = 0,
	sm = 1,
	md = 2,
	lg = 3,
	xl = 4,
	xxl = 5,
}

const cStyle = getComputedStyle(document.documentElement);
const sizeCutoffs: { minWidth: number; size: ViewSize }[] = [
	{
		minWidth: parseInt(cStyle.getPropertyValue('--breakpoint-xs')),
		size: ViewSize.xs,
	},
	{
		minWidth: parseInt(cStyle.getPropertyValue('--breakpoint-sm')),
		size: ViewSize.sm,
	},
	{
		minWidth: parseInt(cStyle.getPropertyValue('--breakpoint-md')),
		size: ViewSize.md,
	},
	{
		minWidth: parseInt(cStyle.getPropertyValue('--breakpoint-lg')),
		size: ViewSize.lg,
	},
	{
		minWidth: parseInt(cStyle.getPropertyValue('--breakpoint-xl')),
		size: ViewSize.xl,
	},
	{
		minWidth: parseInt(cStyle.getPropertyValue('--breakpoint-xxl')),
		size: ViewSize.xxl,
	},
];

@Injectable()
export class SizeMonitorService {
	private _size: ViewSize;
	private _sizeSubject = new BehaviorSubject<ViewSize>(null);
	private _sizePairwiseSubject = new BehaviorSubject<{
		previousSize: ViewSize | null;
		currentSize: ViewSize;
	}>(undefined);
	private _pixelsSubject = new BehaviorSubject<number>(0);

	constructor() {
		this._sizeSubject.subscribe((x) => (this._size = x));

		this._sizeSubject
			.asObservable()
			.pipe(
				distinctUntilChanged(),
				pairwise(),
				map(([prev, cur]) => {
					return {
						previousSize: prev,
						currentSize: cur,
					};
				}),
			)
			.subscribe((x) => this._sizePairwiseSubject.next(x));
	}

	get size() {
		return this._size;
	}

	get size$() {
		return this._sizePairwiseSubject
			.asObservable()
			.pipe(filter((x) => x !== undefined));
	}

	get pixels$() {
		return this._pixelsSubject
			.asObservable()
			.pipe(filter((x) => x !== undefined));
	}

	public notifyOfSizeChange = (widthPx: number): void => {
		if (!isNumber(widthPx)) return;
		for (let i = sizeCutoffs.length - 1; i >= 0; i--) {
			if (widthPx >= sizeCutoffs[i].minWidth) {
				this._sizeSubject.next(sizeCutoffs[i].size);
				break;
			}
		}
		this._pixelsSubject.next(widthPx);
	};
}
