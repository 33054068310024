import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentTranscriptRequestModel {
	log_id: number;
	is_completed: boolean;
	requested_by: number;
	requested_by_name: string;
	type: string;
	fax_numbers: number[];
	emails: string[];
	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created_date: Date;
}

export function StudentTranscriptRequestModel_ClassTransform_Type() {
	return StudentTranscriptRequestModel;
}
