import {
	Directive,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AsyncSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
	selector: '[aeRootContainerSpacer]',
	standalone: true,
})
export class RootContainerSpacerDirective implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	constructor(
		private elemRef: ElementRef,
		private renderer: Renderer2,
		private route: ActivatedRoute,
		private router: Router,
	) {}

	private checkRouteData = () => {
		this.renderer.removeClass(this.elemRef.nativeElement, 'no-bottom-margin');

		if (this.route.snapshot.firstChild?.data?.noRootMargin) {
			this.renderer.addClass(this.elemRef.nativeElement, 'no-bottom-margin');
		}
	};

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.checkRouteData();
			});

		this.checkRouteData();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
