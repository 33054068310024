import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpCallCache, buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { isNullOrEmptyString } from 'src/lib/utilities/compare';
import { ProgramManagementDataModel } from '../program-management-data.model';
import {
	ProgramEditRequestCreateArgument,
	ProgramEditRequestUpdateArgument,
} from './program-edit-request-create.argument';

import { ProgramEditRequestOptionsModel } from './program-edit-request-options.model';
import {
	ProgramEditRequestListModel,
	ProgramEditRequestModel,
	ProgramEditRequestValidateModel,
} from './program-edit-request.model';

@Injectable({
	providedIn: 'root',
})
export class ProgramManagementRequestService {
	constructor(private httpClient: HttpClient) {}

	// Organization Admin - Edit Request  - List All Open
	public getAllOpenEditRequests = (
		show_resolved?: true | null,
	): Observable<ProgramEditRequestListModel[]> => {
		const query = buildQueryString({
			show_resolved,
		});

		return this.httpClient
			.get<any>(`/api/v1/organizations/edit_requests${query}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramEditRequestListModel,
							r.pending_edits as any[],
						),
					{
						errorCode: '737D172B',
					},
				),
			);
	};

	// POSTMAN: Organization Admin - Edit Request - List Open
	public getOpenEditRequests = (
		orgId: number,
		show_resolved?: true | null,
	): Observable<ProgramEditRequestListModel[]> => {
		const query = buildQueryString({
			show_resolved,
		});

		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/edit_requests${query}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramEditRequestListModel,
							r.pending_edits as any[],
						),
					{
						errorCode: '737D172B',
					},
				),
			);
	};

	// POSTMAN: Organization Admin - Edit Request - View Edit Request
	public viewEditRequest = (
		orgId: number,
		requestId: number,
	): Observable<ProgramEditRequestModel> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/edit_requests/${requestId}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramEditRequestModel, r.edit_request),
					{
						errorCode: '14943865',
					},
				),
			);
	};

	// POSTMAN: Organization Admin - Edit Request - Options
	private httpCallCache_getEditRequestOptions = new HttpCallCache<
		number,
		ProgramEditRequestOptionsModel
	>();

	public getEditRequestOptions = (
		orgId: number,
		forceRefresh: boolean = false,
	): Observable<ProgramEditRequestOptionsModel> => {
		if (forceRefresh) {
			this.httpCallCache_getEditRequestOptions.flushAll();
		}

		return this.httpCallCache_getEditRequestOptions.fetch$(
			orgId,
			this.httpClient
				.get<any>(`/api/v1/organizations/${orgId}/edit_requests/options`)
				.pipe(
					mapResponse(
						(r) =>
							plainToInstance(ProgramEditRequestOptionsModel, r.edit_options),
						{
							errorCode: '737D172B',
						},
					),
				),
		);
	};

	private httpCallCache_getDefaultRequestOptions = new HttpCallCache<
		number,
		ProgramEditRequestOptionsModel
	>();

	public getDefaultRequestOptions = (
		forceRefresh: boolean = false,
	): Observable<ProgramEditRequestOptionsModel> => {
		if (forceRefresh) {
			this.httpCallCache_getDefaultRequestOptions.flushAll();
		}

		return this.httpCallCache_getDefaultRequestOptions.fetch$(
			null,
			this.httpClient
				.get<any>(`/api/v1/organizations/edit_requests/options`)
				.pipe(
					mapResponse(
						(r) =>
							plainToInstance(ProgramEditRequestOptionsModel, r.edit_options),
						{
							errorCode: '737D172B',
						},
					),
				),
		);
	};

	// POSTMAN: Organization Admin - Edit Request - Create
	public createEditRequest = (
		orgId: number,
		args: Partial<ProgramEditRequestCreateArgument>,
	): Observable<ProgramEditRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/${orgId}/edit_requests`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramEditRequestModel, r.edit_request),
					{
						errorCode: 'BD57A965',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	// POSTMAN: Organization Admin - Edit Request - Change Request
	public updateEditRequest = (
		orgId: number,
		requestId: number,
		args: ProgramEditRequestUpdateArgument,
	): Observable<ProgramEditRequestModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/organizations/${orgId}/edit_requests/${requestId}`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramEditRequestModel, r.edit_request),
					{
						errorCode: '474C435E',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	// POSTMAN Organization Admin - Edit Request - Validate
	public validateEditRequest = (
		args: ProgramManagementDataModel,
	): Observable<ProgramEditRequestValidateModel> => {
		return this.httpClient
			.post<ProgramEditRequestValidateModel>(
				`/api/v1/organizations/edit_requests/validate`,
				{
					edit_request: {
						id: null,
						fields: args,
					},
				},
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramEditRequestValidateModel, r),
					{
						errorCode: 'BD57A965',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public asyncValidators = {
		uniqueProgramNameValidator$: (name: string): AsyncValidatorFn => {
			return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
				if (isNullOrEmptyString(ctrl.value) || ctrl.value === name) {
					return of(null);
				} else {
					return this.validateEditRequest({
						administrative: { name: ctrl.value },
					}).pipe(
						map((r) => {
							if (r.success) {
								return null;
							} else {
								return {
									uniqueProgramName: 'This name is already being used',
								};
							}
						}),
					);
				}
			};
		},
		uniqueShortNameValidator$: (unique_id: string): AsyncValidatorFn => {
			return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
				if (isNullOrEmptyString(ctrl.value) || ctrl.value === unique_id) {
					return of(null);
				} else {
					return this.validateEditRequest({
						administrative: { unique_id: ctrl.value },
					}).pipe(
						map((r) => {
							if (r.success) {
								return null;
							} else {
								return {
									uniqueShortName: 'This short name is already being used',
								};
							}
						}),
					);
				}
			};
		},
	};

	// BULK EDIT
	public getBulkEditRequestOptions =
		(): Observable<ProgramEditRequestOptionsModel> => {
			return this.httpClient
				.get<any>(`/api/v1/organizations/bulk/edit_requests/options`)
				.pipe(
					mapResponse(
						(r) =>
							plainToInstance(ProgramEditRequestOptionsModel, r.edit_options),
						{
							errorCode: 'FD039023',
						},
					),
				);
		};

	public createBulkEditRequest = (
		args: ProgramEditRequestCreateArgument,
	): Observable<ProgramEditRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/bulk/edit_requests`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramEditRequestModel, r.edit_request),
					{
						errorCode: '7C4FAC1B',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};
}
