import { Type } from 'class-transformer';
import {
	StudentTranscriptRequestModel,
	StudentTranscriptRequestModel_ClassTransform_Type,
} from './student-transcript-request.model';

export class TranscriptRequestDefaultModel {
	fax: number[];
	iep_fax: number[];
	email: string[];
	iep_email: string[];
}

export class StudentFileSendModel {
	file_id: string;
	description: string;
}

export function StudentFileSendModel_ClassTransform_Type() {
	return StudentFileSendModel;
}

export class StudentTranscriptRequestsResponseModel {
	@Type(StudentTranscriptRequestModel_ClassTransform_Type)
	transcript_requests: StudentTranscriptRequestModel[];
	iep_504: boolean;
	defaults: TranscriptRequestDefaultModel;
	@Type(StudentFileSendModel_ClassTransform_Type)
	files_to_use: StudentFileSendModel[];
}
