import { Type } from 'class-transformer';

export class RoleManagerAssignedProgramModel {
	id: number;
	state: string;
	type: string;
	title: string;
	parent_title: string;
	timezone: string;
	associated_student_assignment: boolean;
}

export function RoleManagerAssignedProgramModel_ClassTransform_Type() {
	return RoleManagerAssignedProgramModel;
}

export class RoleManagerAssignedTeamModel {
	id: number;
	title: string;
	@Type(RoleManagerAssignedProgramModel_ClassTransform_Type)
	institutes: RoleManagerAssignedProgramModel[];
}

export function RoleManagerAssignedTeamModel_ClassTransform_Type() {
	return RoleManagerAssignedTeamModel;
}

export class RoleManagerAssignmentsResponseModel {
	@Type(RoleManagerAssignedTeamModel_ClassTransform_Type)
	assigned_teams: RoleManagerAssignedTeamModel[];
	@Type(RoleManagerAssignedProgramModel_ClassTransform_Type)
	special_assignments: RoleManagerAssignedProgramModel[];
}
