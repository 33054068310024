import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	TimeTrackerEntryResponseModel,
	TimeTrackerOptionsModel,
	TimeTrackerUpsertArgument,
} from './time-tracker.model';

@Injectable({
	providedIn: 'root',
})
export class TimeTrackerService {
	constructor(private httpClient: HttpClient) {}

	public getTimeTrackerEntries = (
		uid: number,
		end_date: string, // date formatted {year}-{iso-month}-{day-pad}
		duration: number, // days to go back from end date
	): Observable<TimeTrackerEntryResponseModel> => {
		return this.httpClient
			.get<any>(
				`/api/v1/students/${uid}/time-tracker/${end_date}/list?duration=${duration}`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(TimeTrackerEntryResponseModel, r), {
					errorCode: '4BDCB789',
				}),
			);
	};

	public getTimeTrackerOptions = (
		uid: number,
		date: string, // date formatted {year}-{iso-month}-{day-pad}
	): Observable<TimeTrackerOptionsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/time-tracker/${date}/options`)
			.pipe(
				mapResponse((r) => plainToInstance(TimeTrackerOptionsModel, r), {
					errorCode: 'D531A64B',
				}),
			);
	};

	public createTimeEntry = (
		uid: number,
		date: string, // date formatted {year}-{iso-month}-{day-pad}
		args: TimeTrackerUpsertArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/time-tracker/entry/${date}`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '05E196B0',
				}),
			);
	};

	public editTimeEntry = (
		uid: number,
		date: string, // date formatted {year}-{iso-month}-{day-pad}
		args: TimeTrackerUpsertArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/time-tracker/entry/${date}/edit`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '6D4AF561',
				}),
			);
	};
}
