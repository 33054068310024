import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class WorkQueueModel {
	id: number;
	uid: number;
	name: string;
	color: string;
	purpose: string;
	@Transform(dateTransformer)
	created: Date;
	@Transform(dateTransformer)
	updated: Date;
	withdrawn: boolean;
	reassigned?: boolean;
}

export interface WorkQueueArgument {
	name: string;
	color: string;
}

export class WorkQueueDefaultResponse {
	success: boolean;
	error_messages?: string[];
}

export class WorkQueueFlagModel {
	queueId: number;
	name: string;
	color: string;
}
