import { Exclude, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class CommunicationAnnouncementModel {
	id: number;
	private _subject: string;

	get subject() {
		return this._subject;
	}
	set subject(val: string) {
		this._subject = val;
		this.pretty_subject = val.replace(/<.[^>]*>/g, '').trim();
	}

	@Exclude()
	pretty_subject: string;
	body: string;
	is_gate: number;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	end_date: Date;
	groups: string[];
	creator_uid: number;
	creator_name: string;
	complete_url: string;
}

export function CommunicationAnnouncementListModel_ClassTransform_Type() {
	return CommunicationAnnouncementModel;
}
