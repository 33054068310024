import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter, mergeMap, tap } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';
import { MessageModel } from '../../api/gabby/messages/message.model';
import {
	GabbyChannelMessageRead,
	GabbyChannelMessagesRead,
} from '../wamp-event-factory/gabby/gabby-event-types';
import { GabbyWampEventFactoryService } from '../wamp-event-factory/gabby/gabby-wamp-event-factory.service';

@Injectable({
	providedIn: 'root',
})
export class MessageEventService {
	private _unreadMessages$ = new Subject<MessageModel>();
	private _readMessages$ = new Subject<GabbyChannelMessageRead>();

	private _trackedReadMessages = new Set<number>();
	private _trackedUnreadMessages = new Set<number>();

	constructor(private wefs: GabbyWampEventFactoryService) {
		this.init();
	}

	private init = () => {
		const unreadMessagesSubject = new Subject<MessageModel>();
		this.wefs.newMessage$().subscribe((messageEvent) => {
			SessionLogger.log('MessageEventService', 'newMessage', messageEvent);
			unreadMessagesSubject.next(messageEvent.message);
		});

		const readMessagesSubject = new Subject<GabbyChannelMessagesRead>();
		this.wefs.readMessages$().subscribe((readEvent) => {
			SessionLogger.log('MessageEventService', 'readMessages', readEvent);
			readMessagesSubject.next(readEvent);
		});

		// Bundle observables for performance
		unreadMessagesSubject
			.pipe(
				tap((m) => this._trackedUnreadMessages.add(m.message_id)),
				delay(getEnvironment().settings.services.wamp.gabby.unreadDelay),
				tap((m) => this._trackedUnreadMessages.delete(m.message_id)),
				filter((m) => !this._trackedReadMessages.has(m.message_id)),
			)
			.subscribe((x) => this._unreadMessages$.next(x));

		readMessagesSubject
			.pipe(
				mergeMap((x) =>
					x.readMessageIds.map((mid) => {
						return {
							channelId: x.channelId,
							directoryId: x.directoryId,
							readerLinkId: x.readerLinkId,
							readMessageId: mid,
							senderLinkId: x.senderLinkId,
						} as GabbyChannelMessageRead;
					}),
				),
				tap((x) => this._trackedReadMessages.add(x.readMessageId)),
				filter((x) => !this._trackedUnreadMessages.has(x.readMessageId)),
			)
			.subscribe((x) => this._readMessages$.next(x));
	};

	public unreadMessages$ = ({
		directoryId = null,
		channelId = null,
	}: {
		directoryId?: string;
		channelId?: number | string;
	} = {}) => {
		return this._unreadMessages$.pipe(
			filter((m) => {
				let _return = true;

				if (_return && directoryId) {
					_return = _return && directoryId === m.directory;
				}

				if (_return && channelId) {
					_return = _return && channelId === m.channel_id;
				}

				return _return;
			}),
		);
	};

	public readMessages$ = ({
		directoryId = null,
		channelId = null,
	}: {
		directoryId?: string;
		channelId?: number | string;
	} = {}) => {
		return this._readMessages$.pipe(
			filter((x) => {
				let _return = true;

				if (_return && directoryId) {
					_return = _return && directoryId === x.directoryId;
				}

				if (_return && channelId) {
					_return = _return && channelId === x.channelId;
				}

				return _return;
			}),
		);
	};
}
