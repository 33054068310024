import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	GroupInstituteModel,
	GroupInstituteModel_ClassTransform_Type,
	GroupMemberModel,
	GroupMemberModel_ClassTransform_Type,
} from './group-member.model';

export class GroupMembersSearchModel extends AbstractPagingResponseModel<GroupMemberModel> {
	@Type(GroupMemberModel_ClassTransform_Type)
	results: GroupMemberModel[];
}

export class GroupInstitutesSearchModel extends AbstractPagingResponseModel<GroupInstituteModel> {
	@Type(GroupInstituteModel_ClassTransform_Type)
	results: GroupInstituteModel[];
}
