import { Pipe, PipeTransform } from '@angular/core';
import pluralize from 'pluralize';
import { isNonEmptyString } from '../utilities/compare';

@Pipe({
	name: 'pluralize',
	standalone: true,
})
export class PluralizePipe implements PipeTransform {
	transform(value: string, count?: number): string {
		return isNonEmptyString(value) ? pluralize(value, count ?? 2) : value;
	}
}
