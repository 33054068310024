// eslint-disable-next-line no-restricted-imports
import {
	CategorizedPerformanceHistory,
	CategorizedPerformanceHistoryActivated,
	CategorizedPerformanceHistoryActive,
	CategorizedPerformanceHistoryWithdraw,
	IStudentPerformanceHistoryModel,
	StudentPerformanceHistoryType,
	StudentPerformanceStatus,
	StudentPerformanceTrends,
} from 'src/lib/constants/compliance';
// eslint-disable-next-line no-restricted-imports
import { BootstrapTypes } from 'src/lib/constants/constants';
// eslint-disable-next-line no-restricted-imports
import { StudentPerformanceHistoryRequirementsModel } from 'src/lib/services/api/students/performance/student-performance-history-detailed.model';
// eslint-disable-next-line no-restricted-imports
import { StudentStatusModel } from 'src/lib/services/api/students/status/student-status.model';
// eslint-disable-next-line no-restricted-imports
import { isNonEmptyString } from '../compare';

export function calculatePerformanceTrend(
	status: StudentPerformanceStatus,
	previousStatus: StudentPerformanceStatus,
): [StudentPerformanceTrends, BootstrapTypes] {
	if (status === StudentPerformanceStatus.Met) {
		return previousStatus === StudentPerformanceStatus.NotMet
			? [StudentPerformanceTrends.info, BootstrapTypes.info]
			: [StudentPerformanceTrends.success, BootstrapTypes.success];
	} else if (status === StudentPerformanceStatus.NotMet) {
		return previousStatus === StudentPerformanceStatus.NotMet
			? [StudentPerformanceTrends.danger, BootstrapTypes.danger]
			: [StudentPerformanceTrends.warning, BootstrapTypes.warning];
	} else {
		return [StudentPerformanceTrends.default, BootstrapTypes.default];
	}
}

export function buildRequirementsTitle(
	requirements: StudentPerformanceHistoryRequirementsModel[],
) {
	let title =
		filterAdminRequirements(requirements)
			.filter((r) => r.performance !== StudentPerformanceStatus.Met)
			.map((r) => `${r.title}`)
			.join('\n') ?? 'REQUIREMENTS NOT CALCULATED';

	if (isNonEmptyString(title)) {
		title = `---Unmet Requirements---\n${title}`;
	} else {
		title = 'All Requirements Met';
	}

	return title;
}

export function filterAdminRequirements(
	requirements: StudentPerformanceHistoryRequirementsModel[],
): StudentPerformanceHistoryRequirementsModel[] {
	return (
		requirements?.filter(
			(r) =>
				r.types.length > 1 ||
				r.types[0] !== StudentPerformanceHistoryType.StudentFacing,
		) ?? []
	);
}

export function complianceBillableStatusTitle(
	status: StudentPerformanceStatus,
): string {
	switch (status) {
		case StudentPerformanceStatus.Met:
			return 'Claimable';
		case StudentPerformanceStatus.NotMet:
			return 'Not Claimable';
		case StudentPerformanceStatus.Undetermined:
		default:
			return 'Claimability Not Calculated';
	}
}

export function compliancePerformanceStatusTitle(
	status: StudentPerformanceStatus,
): string {
	switch (status) {
		case StudentPerformanceStatus.Met:
			return 'Expectations Met';
		case StudentPerformanceStatus.NotMet:
			return 'Expectations Not Met';
		case StudentPerformanceStatus.Undetermined:
		default:
			return 'Expectations Not Calculated';
	}
}

export function categorizeHistories(
	histories: IStudentPerformanceHistoryModel[],
	status: StudentStatusModel,
): CategorizedPerformanceHistory[] {
	return histories.map((h) => {
		if (
			status.enrollment.withdrawal_date &&
			h.period.start < status.enrollment.withdrawal_date &&
			h.period.end >= status.enrollment.withdrawal_date
		) {
			return {
				type: 'WITHDRAW',
				withdraw_date: status.enrollment.withdrawal_date,
				graduated_date: status.graduated_date,
				performance: h,
			} as CategorizedPerformanceHistoryWithdraw;
		} else if (
			status.enrollment.activated_date &&
			h.period.start < status.enrollment.activated_date &&
			h.period.end >= status.enrollment.activated_date
		) {
			return {
				type: 'ACTIVATED',
				activation_date: status.enrollment.activated_date,
				performance: h,
			} as CategorizedPerformanceHistoryActivated;
		} else {
			return {
				type: 'ACTIVE',
				performance: h,
			} as CategorizedPerformanceHistoryActive;
		}
	});
}
