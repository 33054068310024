import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'floatFloor',
	standalone: true,
})
export class FloatFloorPipe implements PipeTransform {
	transform(value: number, precision: number = 0): number {
		precision = Math.max(precision, 0);
		const s = `${value}`;
		let i = s.indexOf('.');
		if (i === -1) {
			i = s.length;
		}

		return parseFloat(s.slice(0, i + 1 + precision));
	}
}
