import { DirectoryType } from './directory-type.enum';

export class DirectoryModel {
	id: string;
	type: DirectoryType;
	type_key: number;
	title: string;
	unread_messages: number;
	display_image: string;
	is_primary: boolean;
}
