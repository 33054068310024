export class UserSearchModel {
	uid: number;
	first_name: string;
	last_name: string;
	preferred_name: string;
	middle_name: string;
	maiden_name: string;
	mail: string;
	program_title: string;
	institute_id: number;
	active: boolean;
	role: string;
	user_image: string;
	search_matched_fields: string[];
}
