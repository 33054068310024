import {
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { isValidDate } from 'src/lib/utilities/compare';

import { NgClass } from '@angular/common';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import spacetime from 'spacetime';
import {
	convertDateToNgbDateStruct,
	convertToDate,
} from 'src/lib/utilities/convert';
import { IfEmptyPipe } from '../../../pipes/if-empty.pipe';
import {
	createDateValidation,
	createMaxCalendarDateValidation,
	createMinCalendarDateValidation,
} from '../single-date-picker/validators';

@Component({
	selector: 'ae-bootstrap-date-picker',
	templateUrl: './bootstrap-date-picker.component.html',
	styleUrls: ['./bootstrap-date-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BootstrapDatePickerComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => BootstrapDatePickerComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [FormsModule, NgClass, NgbInputDatepicker, IfEmptyPipe],
})
export class BootstrapDatePickerComponent
	implements ControlValueAccessor, OnChanges
{
	@HostBinding('class.custom-form-control') customFormControl = true;

	@Input() format: string = 'MM/dd/yyyy';
	@Input() placeholder: string = null;
	@Input() minDate: string | Date | null = null;
	@Input() maxDate: string | Date | null = null;
	@Input() startDate: string | Date | null = null;
	@Input() id: any = null;
	@Input() markDisabled: (
		date: NgbDateStruct,
		current: { year: number; month: number },
	) => boolean = null;
	@Input() noValidate: boolean = false;

	private _minDate: Date;
	private _maxDate: Date;
	private _startDate: Date;
	private _verifiedValue: Date;

	public disabled: boolean = false;
	public hiddenModel: any;
	public formattedModel: string;

	private _touchFunction: () => void;
	private _validateFn: ((_: any) => any)[];
	private _changeFunction: (value: Date) => void = () => null;

	constructor(private ele: ElementRef<HTMLElement>) {}

	private updateHiddenModel = (updatedModel: Date) => {
		if (isValidDate(updatedModel)) {
			this.hiddenModel = convertDateToNgbDateStruct(updatedModel);
		} else {
			this.hiddenModel = null;
		}

		if (updatedModel != null) {
			this.setTouched();
		}
	};

	private updateFormattedModel = (updatedModel: Date, force = false) => {
		if (isValidDate(updatedModel)) {
			let calendarDate = spacetime(updatedModel);
			calendarDate = calendarDate.add(1, 'hour');
			this.formattedModel = calendarDate.unixFmt(this.format);
		} else if (force) {
			this.formattedModel = null;
		}

		if (updatedModel != null) {
			this.setTouched();
		}
	};

	private setVerifiedValue = (newValue: Date) => {
		if (this._changeFunction == null) return;

		this._verifiedValue = convertToDate(newValue);

		if (!isValidDate(this._verifiedValue)) {
			this._verifiedValue = null;
		}

		this._changeFunction(this._verifiedValue);
	};

	public setTouched = () => {
		if (this._touchFunction) {
			this._touchFunction();
		}
	};

	public onFormattedChange = (dateModel, force = false) => {
		const updatedModel = convertToDate(dateModel);
		// update to Sugar Date
		this.updateFormattedModel(updatedModel, force);
		// update the hidden model
		this.updateHiddenModel(updatedModel);
		// update the real date given back to the parent
		this.setVerifiedValue(updatedModel);
	};

	public onHiddenChange = (dateModel) => {
		const updatedModel = convertToDate(dateModel);
		// update to Sugar Date
		this.updateFormattedModel(updatedModel);
		// update the real date given back to the parent
		this.setVerifiedValue(updatedModel);
	};

	public getMaxDate = () => convertDateToNgbDateStruct(this._maxDate);
	public getMinDate = () => convertDateToNgbDateStruct(this._minDate);
	public getStartDate = () => {
		if (this._startDate == null) return null;
		if (this._verifiedValue != null) {
			return convertDateToNgbDateStruct(this._verifiedValue);
		} else {
			return convertDateToNgbDateStruct(this._startDate);
		}
	};

	public isPresentingInvalid = () => {
		return this.ele.nativeElement.classList.contains('is-invalid');
	};

	// Implementing NG_VALIDATORS
	public validate(ctrl: FormControl) {
		const errors = {};

		if (!this.noValidate) {
			this._validateFn?.forEach((fn) => {
				const error = fn(ctrl);
				if (error) {
					const key = Object.keys(error)[0];
					errors[key] = error[key];
				}
			});
		}

		return errors;
	}

	// Implementing OnChanges
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.minDate) {
			this._minDate = convertToDate(changes.minDate.currentValue);
		}

		if (changes.maxDate) {
			this._maxDate = convertToDate(changes.maxDate.currentValue);
		}

		if (changes.startDate) {
			this._startDate = convertToDate(changes.startDate.currentValue);
		}

		this._validateFn = [];
		if (this._maxDate != null) {
			this._validateFn.push(createMaxCalendarDateValidation(this._maxDate));
		}
		if (this._minDate != null) {
			this._validateFn.push(createMinCalendarDateValidation(this._minDate));
		}

		if (changes.markDisabled && this.markDisabled != null) {
			const func = this.markDisabled;
			this._validateFn.push((ctrl: AbstractControl) => {
				if (ctrl.value == null) {
					return null;
				}

				const parsedDate = convertToDate(ctrl.value);
				if (!isValidDate(parsedDate)) {
					return null;
				}

				const error = {
					markDisabled: {
						given: ctrl.value,
					},
				};

				let current: any = {};
				if (this._verifiedValue) {
					current = convertDateToNgbDateStruct(this._verifiedValue);
				}

				if (func(convertDateToNgbDateStruct(parsedDate), current)) {
					return error;
				}

				// Date greater than minDate
				return null;
			});
		}

		this._validateFn.push(createDateValidation());
		this._changeFunction(this._verifiedValue);
	}

	// Implementing ControlValueAccessor
	public writeValue(val: any): void {
		this.onFormattedChange(val, true);
	}
	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
