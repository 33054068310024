import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { getEnvironment } from '../environment/environment';
import { ActiveStudentService } from '../services/startup/active-student/active-student.service';

@Injectable({
	providedIn: 'root',
})
export class HasActiveStudentGuard {
	constructor(
		private activeStudent: ActiveStudentService,
		private router: Router,
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		return this.checkForStudent(route);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot) {
		return this.checkForStudent(childRoute);
	}

	private checkForStudent = (route: ActivatedRouteSnapshot) => {
		this.activeStudent._ensureInitialized(route);

		return this.activeStudent.getActiveStudent$().pipe(
			take(1),
			map((x) => {
				if (x == null) {
					return this.router.createUrlTree([
						getEnvironment().settings.startup.student
							.activeStudentGuardFallbackRoute,
					]);
				} else {
					return true;
				}
			}),
		);
	};
}
