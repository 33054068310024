import { Transform } from 'class-transformer';
// eslint-disable-next-line no-restricted-imports
import { stringTransformer } from '../../class-transformer-types';
import { AbstractEnumOption } from './abstract-enum-option.model';

export class StringEnumOption implements AbstractEnumOption<string> {
	@Transform(stringTransformer)
	key: string;
	title: string;
}

export function StringEnumOption_ClassTransform_Type() {
	return StringEnumOption;
}
