import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentLearningPlanUpdateModel {
	uid: number;
	@Transform(dateTransformer)
	updated: Date;
	first: string;
	last: string;
}

export function StudentLearningPlanUpdateModel_ClassTransform_Type() {
	return StudentLearningPlanUpdateModel;
}

export class StudentLearningPlanNotesModel {
	state_notes: string;
	district_notes: string;
	student_plan_notes: string;
	@Type(StudentLearningPlanUpdateModel_ClassTransform_Type)
	student_plan_updates: StudentLearningPlanUpdateModel[];
}
