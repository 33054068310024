import { Type } from 'class-transformer';
import { StudentContactAttributes } from './student-contact-options.model';

export class StudentContactPhoneModel {
	phone: number;
	is_primary: boolean;
	sms_blocked: boolean;
	type: string;
	sms: boolean;
}

export function StudentContactPhoneModel_ClassTransform_Type() {
	return StudentContactPhoneModel;
}

export class StudentContactModel {
	link_id: number;
	name: string;
	@Type(StudentContactPhoneModel_ClassTransform_Type)
	phones: StudentContactPhoneModel[];
	emails: string[];
	url: string;
	account_uid?: number;
	attributes: StudentContactAttributes[];
	relationship: string;
}
