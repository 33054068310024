import { Transform, Type } from 'class-transformer';
import { recordTransformerFactory } from 'src/lib/utilities/api/class-transformer-types';

export class ProgramRequestFieldPermissionsModel {
	request: boolean;
	approve: boolean;
}

export function ProgramRequestFieldPermissionsModel_ClassTransform_Type() {
	return ProgramRequestFieldPermissionsModel;
}

export class ProgramRequestFieldModel {
	type: string;
	name: string;
	validators: unknown[];
	tokens: Record<string, string>;
	@Type(ProgramRequestFieldPermissionsModel_ClassTransform_Type)
	permissions: ProgramRequestFieldPermissionsModel;
	additional_data: { file_url: string };
}

export function ProgramRequestFieldModel_ClassTransform_Type() {
	return ProgramRequestFieldModel;
}

export class ProgramRequestGroupModel {
	type: string;
	key: string;
	@Transform(
		recordTransformerFactory(ProgramRequestFieldModel_ClassTransform_Type),
	)
	fields: Record<string, ProgramRequestFieldModel>;
}

export function ProgramRequestGroupModel_ClassTransform_Type() {
	return ProgramRequestGroupModel;
}

export class ProgramRequestPropertiesModel {
	type: string;
	key: string;

	@Transform(
		recordTransformerFactory(ProgramRequestFieldModel_ClassTransform_Type),
	)
	fields: Record<string, ProgramRequestGroupModel>;
}

export class ProgramEditRequestOptionsModel {
	@Transform(
		recordTransformerFactory(ProgramRequestGroupModel_ClassTransform_Type),
	)
	fields: Partial<
		Record<string, ProgramRequestGroupModel & ProgramRequestPropertiesModel>
	>;
}

export function ProgramRequestEditOptionsGroupFieldsModel_ClassTransform_Type() {
	return ProgramEditRequestOptionsModel;
}

export class ProgramManagementIdNameOptionModel {
	id: number | string;
	name: string;
	category?: string;
}
