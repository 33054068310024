<table class="exporter-table">
	<!--  -->
	<!-- HEADER HEADER HEADER -->
	<!--  -->
	<thead #exportTableHeader>
		<tr>
			<!-- Columns -->
			@for (columnTemplate of visibleColumns; track columnTemplate) {
				<th>
					<ng-container
						[ngTemplateOutlet]="columnTemplate.headerTemplate.template"
					/>
				</th>
			}
		</tr>
	</thead>

	<!--  -->
	<!-- BODY BODY BODY -->
	<!--  -->
	<tbody #exportTableBody>
		@for (row of currentItems; track row; let rowId = $index) {
			<tr>
				<!-- Columns -->
				@for (columnTemplate of visibleColumns; track columnTemplate) {
					<td>
						@switch (columnTemplate.exportDisplayTemplate != null) {
							@case (true) {
								<ng-container
									[ngTemplateOutlet]="
										columnTemplate.exportDisplayTemplate.template
									"
									[ngTemplateOutletContext]="{
										$implicit: row,
										rowId: rowId,
									}"
								/>
							}
							@default {
								<ng-container
									[ngTemplateOutlet]="columnTemplate.displayTemplate.template"
									[ngTemplateOutletContext]="{
										$implicit: row,
										rowId: rowId,
										alternateViewActive: false,
									}"
								/>
							}
						}
					</td>
				}
			</tr>
		}
	</tbody>
</table>
