import { Type } from 'class-transformer';

export class UserReportModel {
	schedule_id: number;
	report_id: string;
	report_title: string;
	frequency: string;
	frequency_period: string;
	frequency_value: number;
	subscribed: boolean;
}

export function UserReportModel_ClassTransform_Type() {
	return UserReportModel;
}

export class UserNotificationModel {
	description: string;
	event_id: string;
	instance_id: number;
	instance_title: string;
	is_required: boolean;
	notification_id: number;
	preference_send_by_default: boolean;
	recipient_type: string;
	send_by_default: boolean;
}

export function UserNotificationModel_ClassTransform_Type() {
	return UserNotificationModel;
}

export class UserNotificationSubscriptionsModel {
	@Type(UserNotificationModel_ClassTransform_Type)
	notifications: UserNotificationModel[];
	@Type(UserReportModel_ClassTransform_Type)
	reports: UserReportModel[];
}

export function UserNotificationSubscriptionsModel_ClassTransform_Type() {
	return UserNotificationSubscriptionsModel;
}
