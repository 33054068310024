<button
	#tooltip="ngbTooltip"
	class="btn btn-outline-white"
	container="body"
	placement="bottom"
	title="Dismiss Notice"
	triggers="manual"
	type="button"
	[autoClose]="true"
	[id]="id"
	[ngbTooltip]="tooltipTemplate"
	(click)="tryClose()"
>
	<i aria-hidden="true" class="far fa-times"></i>
</button>

<ng-template #tooltipTemplate>
	@if (secondsUntilReady <= 0) {
		<div>
			{{ delayMessage }}
		</div>
	} @else {
		<div>
			You must wait another {{ secondsUntilReady }} seconds before you can
			dismiss the modal.
		</div>
	}
</ng-template>
