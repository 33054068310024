import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	ChannelParticipantModel,
	ChannelParticipantModel_ClassTransform_Type,
} from './channel-participant.model';

export enum ChannelTopic {
	Custom = 1,
	AcademicCoach = 2,
	Teacher = 3,
	LocalAdvocate = 4,
	TechSupport = 5,
	CareerCounselor = 6,
	TestingCoordinator = 7,
	Principal = 8,
	Specialist = 9,
	GradCoordinator = 10,
	ReEntry = 11,
	Tutor = 12,
}

export class ChannelModel {
	public id: number;
	public original_id: string;
	public title: string;
	public subtitle: string;
	public unknown: boolean;
	public lookup_id: string;

	public student_link_id: number;
	public is_student_chat: boolean;
	public student_uid: number;

	@Expose({ name: 'last_message_timestamp' })
	@Transform(dateTransformer)
	public last_message_created?: Date;

	public first_message_id: number;
	public unread_messages: number;
	public directory: string;
	public can_leave: boolean;
	public can_archive: boolean;
	public can_send: boolean;
	public can_join: boolean;
	public timezone: string;
	public timezone_user: string;
	public last_message_read_id: string;
	public sms_fallback: boolean;

	public topic: string;
	public topic_id: ChannelTopic;
	public primary_topic_link_id: number | number;

	@Type(ChannelParticipantModel_ClassTransform_Type)
	public participants: ChannelParticipantModel[];
}

export function ChannelModel_ClassTransform_Type() {
	return ChannelModel;
}

export class ChannelsResponse {
	count: number;
	@Type(ChannelModel_ClassTransform_Type)
	results: ChannelModel[];
}
