import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { MeetupRsvpRecurringModalComponent } from './meetup-rsvp-recurring-modal.component';

@Injectable({
	providedIn: 'root',
})
export class MeetupRsvpRecurringModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(
		meetupId: number,
		arrival: number,
		departure: number,
		isReschedule: boolean,
	) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				scrollable: true,
				windowClass: 'modal-primary',
			};
			const modal = this.modalService.open(
				MeetupRsvpRecurringModalComponent,
				ngbModalOptions,
			);

			(
				modal.componentInstance as MeetupRsvpRecurringModalComponent
			).bindModalData({
				meetupId: meetupId,
				arrival: arrival,
				departure: departure,
				isReschedule: isReschedule,
			});

			modal.result
				.then((val) => {
					o.next(val);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
