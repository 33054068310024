import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SessionExpiredModalComponent } from './session-expired-modal.component';

@Injectable({
	providedIn: 'root',
})
export class SessionExpiredModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$() {
		return new Observable<boolean>(() => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				scrollable: false,
			};
			const modal = this.modalService.open(
				SessionExpiredModalComponent,
				ngbModalOptions,
			);

			(modal.componentInstance as SessionExpiredModalComponent).bindModalData();

			modal.result.finally(() => {
				// eslint-disable-next-line no-self-assign
				window.location.href = window.location.href;
			});
		});
	}
}
