import fuzzysort from 'fuzzysort';
import spacetime, { TimeUnit } from 'spacetime';
import { SearchOperators } from 'src/lib/utilities/api/patterns/pagination/search-operators.enum';
import { containsString, isNullOrEmptyString } from 'src/lib/utilities/compare';
import { convertToBoolean } from 'src/lib/utilities/convert';
import { compareDateRangePickerTouchesRangeItem } from 'src/lib/utilities/date';
import { DateRangePickerValue } from '../../controls/date-range-picker/date-range-picker.types';
import { EqualityComparerDateValue } from '../../controls/equality-comparer-date/equality-comparer-date.component';
import { EqualityComparerNumberInputValue } from '../../controls/equality-comparer-number-input/equality-comparer-number-input.component';
import { EqualityComparerTextInputValue } from '../../controls/equality-comparer-text-input/equality-comparer-text-input.component';
import { OrNorSelectorValue } from '../../controls/or-nor-select/or-nor-select.component';
import { FilterFunction } from './pagination-helper';

export const getRowTestValue = <T, K>(
	valueGet: ((item: T) => K) | keyof T,
	item: T,
): K => {
	if (typeof valueGet === 'function') {
		return valueGet(item);
	} else {
		return item[valueGet] as any;
	}
};

export const pgFilterFactory = {
	equals: <TRow, TValue>(
		getValue: ((item: TRow) => TValue) | keyof TRow,
	): FilterFunction<TRow, TValue> => {
		return (item: TRow, filter: TValue) => {
			if (filter == null) {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				return testValue === filter;
			}
		};
	},
	fuzzyString: <TRow>(
		getValue: ((item: TRow) => string) | keyof TRow,
		threshold: number = 0.55, // Magic number from experimentation
	): FilterFunction<TRow, string> => {
		return (item: TRow, filter: string) => {
			if (filter == null || filter === '') {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				const sortResults = fuzzysort
					.go(filter.toLowerCase(), [testValue?.toLowerCase()], {
						threshold: threshold,
					})
					.map((sc) => sc);

				return sortResults.length > 0;
			}
		};
	},
	containsString: <TRow>(
		getValue: ((item: TRow) => string) | keyof TRow,
		caseInsensitive: boolean = true,
	): FilterFunction<TRow, string | number> => {
		return (item: TRow, filter: string | number) => {
			if (filter == null || filter === '') {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				return containsString(testValue, filter.toString(), caseInsensitive);
			}
		};
	},
	booleanLike: <TRow>(
		getValue: ((item: TRow) => boolean | number | string) | keyof TRow,
	): FilterFunction<TRow, boolean | number | string> => {
		return (item: TRow, filter: boolean | number | string) => {
			if (
				filter == null ||
				(typeof filter === 'string' && filter.trim() === '')
			) {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				return convertToBoolean(testValue) === convertToBoolean(filter);
			}
		};
	},
	arrayContains: <TRow, TArray, TFilter = TArray>(
		getValue: ((item: TRow) => TArray[]) | keyof TRow,
		compareFunc?: (arrItem: TArray, filter: TFilter) => boolean,
	): FilterFunction<TRow, TFilter> => {
		compareFunc =
			compareFunc ??
			function (x, y) {
				return (x as any) === (y as any);
			};

		return (item: TRow, filter: TFilter) => {
			if (filter == null) {
				return true;
			} else {
				const testValues = getRowTestValue(getValue, item);
				return testValues?.some((i) => compareFunc(i, filter)) ?? false;
			}
		};
	},
	equalityComparerDate: <TRow>(
		getValue: ((item: TRow) => Date) | keyof TRow,
		startOf: TimeUnit = 'date',
	): FilterFunction<TRow, EqualityComparerDateValue> => {
		return (item: TRow, filter: EqualityComparerDateValue) => {
			if (filter == null || filter.operator == null) {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				switch (filter.operator) {
					case SearchOperators.IsNull:
						return testValue == null;
					case SearchOperators.IsNotNull:
						return testValue != null;
				}

				const comparisonDate = filter.operand;
				if (testValue == null || comparisonDate == null) {
					return false;
				}

				const itemTimestamp = spacetime(testValue).startOf(startOf).epoch;
				const comparisonTimestamp =
					spacetime(comparisonDate).startOf(startOf).epoch;

				switch (filter.operator) {
					case SearchOperators.LessThan:
						return itemTimestamp < comparisonTimestamp;
					case SearchOperators.GreaterThan:
						return itemTimestamp > comparisonTimestamp;
					case SearchOperators.LessThanOrEqual:
						return itemTimestamp <= comparisonTimestamp;
					case SearchOperators.GreaterThanOrEqual:
						return itemTimestamp >= comparisonTimestamp;
					case SearchOperators.Equals:
						return itemTimestamp === comparisonTimestamp;
					default:
						return false;
				}
			}
		};
	},
	equalityComparerNumber: <TRow>(
		getValue: ((item: TRow) => number) | keyof TRow,
		nullAsZero: boolean = true,
	): FilterFunction<TRow, EqualityComparerNumberInputValue> => {
		return (item: TRow, filter: EqualityComparerNumberInputValue) => {
			if (filter == null || filter.operator == null) {
				return true;
			} else {
				let testValue = getRowTestValue(getValue, item);

				switch (filter.operator) {
					case SearchOperators.IsNull:
						return testValue == null;
					case SearchOperators.IsNotNull:
						return testValue != null;
				}

				if (nullAsZero) {
					testValue = testValue ?? 0;
				}

				const comparisonNumber = filter.operand;
				if (testValue == null || isNaN(testValue) || comparisonNumber == null) {
					return false;
				}

				switch (filter.operator) {
					case SearchOperators.LessThan:
						return testValue < comparisonNumber;
					case SearchOperators.GreaterThan:
						return testValue > comparisonNumber;
					case SearchOperators.LessThanOrEqual:
						return testValue <= comparisonNumber;
					case SearchOperators.GreaterThanOrEqual:
						return testValue >= comparisonNumber;
					case SearchOperators.Equals:
						return testValue === comparisonNumber;
					default:
						return false;
				}
			}
		};
	},
	equalityComparerString: <TRow>(
		getValue: ((item: TRow) => string) | keyof TRow,
		caseInsensitive: boolean = true,
	): FilterFunction<TRow, EqualityComparerTextInputValue> => {
		return (item: TRow, filter: EqualityComparerTextInputValue) => {
			if (isNullOrEmptyString(filter?.value)) return true;
			else {
				const testValue = getRowTestValue(getValue, item);

				return (
					containsString(testValue, filter.value, caseInsensitive) ===
					filter.contains
				);
			}
		};
	},
	multiEqualityComparerString: <TRow>(
		getValue: ((item: TRow) => string) | keyof TRow,
	): FilterFunction<TRow, EqualityComparerTextInputValue[]> => {
		return (item: TRow, filter: EqualityComparerTextInputValue[]) => {
			if (filter == null) return true;
			else {
				const testValue = getRowTestValue(getValue, item);

				const ors = filter.filter((x) => x.contains);
				const nors = filter.filter((x) => !x.contains);

				return (
					!nors.some((x) => containsString(testValue, x.value)) &&
					(ors.length === 0 ||
						ors.some((x) => containsString(testValue, x.value)))
				);
			}
		};
	},
	orNorSelector: <TRow, TSelection>(
		getValue: ((item: TRow) => TSelection) | keyof TRow,
		comparisonFunc?: (filter: TSelection, value: TSelection) => boolean,
	): FilterFunction<TRow, OrNorSelectorValue<TSelection>> => {
		comparisonFunc =
			comparisonFunc ??
			function (f, v) {
				return f === v;
			};

		return (item: TRow, filter: OrNorSelectorValue<TSelection>) => {
			if (filter == null) return true;
			else if (filter.values == null || filter.values.length === 0) {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				return (
					filter.values.some((f) => comparisonFunc(f, testValue)) === filter.or
				);
			}
		};
	},
	dateRangePicker: <TRow>(
		getValue:
			| ((item: TRow) => {
					toDate: Date;
					fromDate: Date;
			  })
			| keyof TRow,
	): FilterFunction<TRow, DateRangePickerValue> => {
		return (item: TRow, filter: DateRangePickerValue) => {
			if (filter == null || filter.toDate == null || filter.fromDate == null) {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				return compareDateRangePickerTouchesRangeItem(
					testValue?.fromDate,
					testValue?.toDate,
					filter,
				);
			}
		};
	},

	// This selector should be used with the const iep504TableFilterOptions in data.ts
	iep504Selector: <TRow>(
		getValue: ((item: TRow) => string) | keyof TRow,
	): FilterFunction<TRow, string> => {
		return (item: TRow, filter: string) => {
			if (filter == null) {
				return true;
			} else {
				const testValue = getRowTestValue(getValue, item);

				if (filter === 'any') {
					return !isNullOrEmptyString(testValue);
				} else if (filter === 'none') {
					return isNullOrEmptyString(testValue);
				} else {
					return testValue === filter;
				}
			}
		};
	},
};
