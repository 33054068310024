import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class CalendarEventModel {
	id?: number;
	title: string;
	type: string;
	notes: string;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	end_date: Date;
	groups: string[];
}

export function CalendarEventModel_ClassTransform_Type() {
	return CalendarEventModel;
}
