import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StudentResponseFieldsModel } from './student-response-fields.model';
import { UserModalModel } from './user-modal.model';

@Injectable({
	providedIn: 'root',
})
export class UsersModalsService {
	constructor(private httpClient: HttpClient) {}

	public getUserModals = (uid: number): Observable<UserModalModel[]> => {
		return this.httpClient.get<any>(`/api/v1/modal/${uid}`).pipe(
			mapResponse((r) => plainToInstance(UserModalModel, r.modals as any[]), {
				errorCode: '3855E63E',
			}),
		);
	};

	public completeUserModal = (
		uid: number,
		modalId: number,
	): Observable<any> => {
		return this.httpClient
			.get<any>(`/api/v1/modal/${uid}/edit/${modalId}/complete`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D8B337AC',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getStudentModals = (uid: number): Observable<UserModalModel[]> => {
		return this.httpClient.get<any>(`api/v1/students/${uid}/modals`).pipe(
			mapResponse((r) => plainToInstance(UserModalModel, r.modals as any[]), {
				errorCode: '3855E63E',
			}),
		);
	};

	public completeStudentModal = (
		uid: number,
		modalId: number,
	): Observable<any> => {
		return this.httpClient
			.get<any>(`api/v1/students/${uid}/modals/${modalId}/complete`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D8B337AC',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getStudentResponseFields = (
		studentId?: number,
		responseFormKey?: string,
		query?: Record<string, any>,
	): Observable<StudentResponseFieldsModel> => {
		const qs = buildQueryString(query);

		return this.httpClient
			.get<any>(
				`api/v1/students/${studentId}/responses/${responseFormKey}${qs}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentResponseFieldsModel, r.response_form),
					{
						errorCode: 'B5445443',
					},
				),
			);
	};

	public setStudentResponseFields = (
		studentId: number,
		responseFormKey: string,
		formResponses: any,
		query?: Record<string, any>,
	): Observable<any> => {
		const qs = buildQueryString(query);

		return this.httpClient
			.post<any>(
				`api/v1/students/${studentId}/responses/${responseFormKey}${qs}`,
				formResponses,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F5BE38F0',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	// Survey (Barrier Data)
	public getStudentSurvey = (surveyId: number): Observable<any> => {
		return this.httpClient.get<any>(`api/v1/response/survey/${surveyId}`).pipe(
			mapResponse((r) => r, {
				errorCode: '90E2E5A7',
				errorMessage: DefaultErrorMessage.Saving,
			}),
		);
	};

	public createModal = (): Observable<any> => {
		return this.httpClient
			.post<any>(`api/v1/modals`, {
				add: [
					{
						type: 'Speedbump',
						title: 'Barrier Data Survey',
						key: 'barrier_data_survey',
						groups: ['enrollment_type||student'],
					},
				],
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'a9a5b100',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
