import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProfileAccessibilityStatementModalComponent } from './profile-accessibility-statement-modal.component';

@Injectable({
	providedIn: 'root',
})
export class ProfileAccessibilityStatementModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$() {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: 'lg',
				scrollable: true,
				windowClass: 'modal-primary',
			};
			const modal = this.modalService.open(
				ProfileAccessibilityStatementModalComponent,
				ngbModalOptions,
			);

			(
				modal.componentInstance as ProfileAccessibilityStatementModalComponent
			).bindModalData();

			modal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
