<span
	container="body"
	role="tooltip"
	tabindex="0"
	triggers="hover focus click:blur"
	class="btn btn-icon {{ definition?.color }}"
	[id]="id"
	[ngbTooltip]="messageTemplate ?? stringTemplate"
>
	<span class="screen-reader-text">
		<ng-container *ngTemplateOutlet="messageTemplate ?? stringTemplate" />
	</span>
	<i aria-hidden="true" [class]="definition?.icon"></i>
</span>
<ng-template #stringTemplate>
	{{ messageString }}
</ng-template>
