@if (!loading) {
	<div
		class="input-group flex-nowrap overflow-hidden"
		container="body"
		ngbDropdown
		placement="bottom-right"
		[autoClose]="'outside'"
	>
		<div
			class="flex-grow-1 text-input-min-width multi-equality-comparer-text-input-container"
		>
			<ae-equality-comparer-text-input [formControl]="outsideControl" />
		</div>
		<label
			class="btn btn-secondary m-0 multi-text-input-toggle"
			ngbDropdownToggle
			title="Toggle Additional Filters"
			for="{{
				id
			}}.multi-equality-comparer-text-inputs.component.dropdown.toggle"
		>
			@if (controlFG?.value.length > 1) {
				<div class="multi-text-input-counter text-white">
					{{ controlFG?.value.length || 0 }}
				</div>
			}
		</label>
		<div ngbDropdownMenu>
			@for (item of controlFG.controls; track item; let i = $index) {
				<div class="p-2">
					<div class="input-group multi-equality-comparer-text-input-container">
						<ae-equality-comparer-text-input [formControl]="item" />
						<button
							class="btn btn-secondary m-0 input-group-append d-flex align-items-center squared-start"
							title="Remove Filter"
							type="button"
							id="{{
								id
							}}.multi-equality-comparer-text-inputs.component.remove.input.{{
								i
							}}"
							(click)="removeFilter(i)"
						>
							<i aria-hidden="true" class="far fa-minus"></i>
						</button>
					</div>
				</div>
			}
			<div class="p-2">
				<button
					class="btn btn-primary btn-block"
					type="button"
					id="{{ id }}.multi-equality-comparer-text-inputs.component.add.input"
					(click)="addNewFilter()"
				>
					Add Another
				</button>
			</div>
		</div>
	</div>
}
