import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HTTP_NO_PERMISSION } from 'src/lib/constants/constants';
import { Features } from 'src/lib/constants/permissions';
import { SelfEnrollmentCourseOptionModel } from 'src/lib/services/api/students/self-enrollment/self-enrollment-course.model';
import { SelfEnrollmentService } from 'src/lib/services/api/students/self-enrollment/self-enrollment.service';
import { MapResponseErrors } from 'src/lib/utilities/api/map-response';
import { BehaviorCache } from 'src/lib/utilities/cache';
import { PermissionStoreService } from '../../permission-store/permission-store.service';

@Injectable({
	providedIn: 'root',
})
export class StudentSelfEnrollmentStoreService {
	private _selfEnrollmentOptionsCache = new BehaviorCache<
		[uid: number],
		SelfEnrollmentCourseOptionModel
	>(
		([uid]) => {
			return this.permissionService
				.getFieldSet$({
					UserId: uid,
				})
				.pipe(
					switchMap((fieldSet) => {
						if (
							fieldSet.canDoAny(
								Features['course.self_enroll.from_upcoming'],
								Features['course.self_enroll.from_learning_plan'],
							)
						) {
							return this.selfEnrollmentService
								.getSelfEnrollmentOptions(uid)
								.pipe(
									catchError((err: MapResponseErrors) => {
										if (err[0] === HTTP_NO_PERMISSION) {
											return of(null);
										} else {
											return throwError(() => err);
										}
									}),
								);
						} else {
							return of(null);
						}
					}),
				);
		},
		'StudentSelfEnrollmentStoreService SelfEnrollmentCache',
		() => null,
		15 * 60, // 15 minutes
	);

	constructor(
		private selfEnrollmentService: SelfEnrollmentService,
		private permissionService: PermissionStoreService,
	) {}

	public enrollmentOptions$ = (
		uid: number,
	): Observable<SelfEnrollmentCourseOptionModel> => {
		return this._selfEnrollmentOptionsCache.getCache([uid]);
	};

	public refreshEnrollmentOptions = (uid: number): Promise<boolean> => {
		return this._selfEnrollmentOptionsCache.fetchData([uid], true);
	};
}
