import {
	NgClass,
	NgIf,
	NgSwitch,
	NgSwitchCase,
	NgSwitchDefault,
} from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import spacetime from 'spacetime';

export interface GraduationPacingIconState {
	state: 'success' | 'info' | 'danger';
	direction: 'behind' | 'ahead' | 'on track';
	months: number;
}

@Component({
	selector: 'ae-graduation-pacing-icon',
	templateUrl: './graduation-pacing-icon.component.html',
	styleUrls: ['./graduation-pacing-icon.component.scss'],
	exportAs: 'ae-graduation-pacing-icon',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgSwitch, NgSwitchCase, NgIf, NgSwitchDefault],
})
export class GraduationPacingIconComponent
	implements OnChanges, GraduationPacingIconState
{
	@Input() expected_date: Date;
	@Input() paced_date: Date;
	@Input() grad_type: 'cohort_year' | 'end_date';
	@Input() set_color: boolean = true;

	@Output() calculatedPacing = new EventEmitter<GraduationPacingIconState>();

	public state = null;
	public direction = null;
	public months = null;

	ngOnChanges(_changes: SimpleChanges): void {
		this.calculateValues();
		this.calculatedPacing.emit({
			state: this.state,
			direction: this.direction,
			months: this.months,
		});
	}

	public getColor = () => {
		return this.set_color ? `text-${this.state}` : null;
	};

	private calculateValues = () => {
		this.state = null;
		this.direction = null;
		this.months = null;

		if (this.expected_date && this.paced_date && this.grad_type) {
			const monthsDiff = spacetime(this.expected_date).diff(
				this.paced_date,
				'month',
			);
			this.months = Math.abs(monthsDiff);

			if (monthsDiff > 0) {
				this.direction = 'behind';
			} else if (monthsDiff < 0) {
				this.direction = 'ahead';
			} else {
				this.direction = 'on track';
			}

			if (this.direction === 'on track') {
				this.state = 'success';
			} else if (this.direction === 'ahead') {
				this.state = this.grad_type === 'cohort_year' ? 'danger' : 'info';
			} else {
				this.state = this.grad_type === 'cohort_year' ? 'info' : 'danger';
			}
		}
	};
}
