import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
	selector: 'ae-page-title-header',
	templateUrl: './page-title-header.component.html',
	styleUrls: ['./page-title-header.component.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class PageTitleHeaderComponent implements OnInit {
	@Input() pageHeaderTitleTemplate: TemplateRef<any>;
	@Input() pageHeaderTitle: string;

	ngOnInit() {
		try {
			this.validateInputs();
		} catch (e) {
			console.error(e);
		}
	}

	private validateInputs = () => {
		if (this.pageHeaderTitleTemplate == null && this.pageHeaderTitle == null) {
			throw new Error('PageTitleHeaderComponent you must define a title');
		}

		if (this.pageHeaderTitleTemplate != null && this.pageHeaderTitle != null) {
			throw new Error(
				'PageTitleHeaderComponent you must only define one title type',
			);
		}
	};
}
