import { MonoTypeOperatorFunction, PartialObserver, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import 'store';
// eslint-disable-next-line no-restricted-imports
import { isArray } from '../compare';
// eslint-disable-next-line no-restricted-imports
import { TypedStorage, TypedStoreType } from 'src/lib/types/typed-storage';
// eslint-disable-next-line no-restricted-imports
import { SessionStoreService } from 'src/lib/services/stores/session-store/session-store.service';
// eslint-disable-next-line no-restricted-imports
import { noop } from '../noop';

export type SessionLogFunction = (
	message?: any,
	...optionalParams: any[]
) => void;

function buildTapLog<T>(
	log: SessionLogFunction,
	logNext?: (logger: SessionLogFunction, x?: T) => void,
	logError?: (logger: SessionLogFunction, e?: any) => void,
	logComplete?: (logger: SessionLogFunction) => void,
) {
	const observer: PartialObserver<T> = {
		next: noop,
		error: noop,
		complete: noop,
	};

	if (logNext) {
		observer.next = function (v) {
			logNext(log, v);
		};
	}

	if (logError) {
		observer.error = function (err) {
			logError(log, err);
		};
	}

	if (logComplete) {
		observer.complete = function () {
			logComplete(log);
		};
	}

	return tap<T>(observer);
}

export class SessionLoggerInternal {
	private resetEvent = new Subject<null>();
	private logEvent = new Subject<string>();
	private unhandledLogEvent = new Subject<string>();

	private debounceTime = 500;

	constructor(private store: TypedStorage) {
		this.hookLogEvent();
	}

	private hookLogEvent = () => {
		this.resetEvent.next(null);
		const unhandledLogs = [];

		this.unhandledLogEvent
			.pipe(takeUntil(this.resetEvent))
			.subscribe((l) => unhandledLogs.push(l));

		this.logEvent
			.pipe(debounceTime(this.debounceTime), takeUntil(this.resetEvent))
			.subscribe(() => {
				if (unhandledLogs.length === 0) return;
				const straightLogs = unhandledLogs
					.map((l) => l.trim().replace(/(\r\n|\n|\r)/gm, '|~|'))
					.reverse();

				const logs: string[] = this.store.get(
					'sessionLoggerLogs',
					TypedStoreType.ARRAY,
					[],
				);
				logs.splice(0, 0, ...straightLogs);
				logs.splice(250, Number.MAX_SAFE_INTEGER); // Don't keep more than 250 records
				this.store.set('sessionLoggerLogs', logs);

				unhandledLogs.length = 0;
			});
	};

	private stringifyLogObject = (obj: any): string => {
		let msg: string;

		if (obj === null) {
			msg = 'NULL';
		} else if (obj === undefined) {
			msg = 'UNDEFINED';
		} else if (isArray(obj)) {
			try {
				msg = JSON.stringify(obj);
			} catch {
				// swallow
			}
		} else if (typeof obj === 'object') {
			msg = obj.toString();
			if (msg === '[object Object]') {
				msg = undefined;

				try {
					msg = JSON.stringify(obj);
				} catch {
					// swallow
				}
			}
		}

		return msg || `${obj}`;
	};

	private push = (message?: any, ...optionalParams: any[]) => {
		let msg: string = this.stringifyLogObject(message);
		(optionalParams || []).forEach((p) => {
			msg += ` ${this.stringifyLogObject(p)}`;
		});

		this.unhandledLogEvent.next(
			`${new Date().getTime()} ${msg.substring(0, 5000)}`,
		); // Clip messages longer than 5000 chars
		this.logEvent.next(null);
	};

	public logObservableTap = <T>(
		logNext?: (logger: SessionLogFunction, x?: T) => void,
		logError?: (logger: SessionLogFunction, e?: any) => void,
		logComplete?: (logger: SessionLogFunction) => void,
	): MonoTypeOperatorFunction<T> => {
		return buildTapLog(this.log, logNext, logError, logComplete);
	};

	public logObservableNext = <T>(
		message?: any,
		...optionalParams: any[]
	): MonoTypeOperatorFunction<T> => {
		return buildTapLog(this.log, (l) => l(message, ...optionalParams));
	};

	public logObservableError = <T>(
		message?: any,
		...optionalParams: any[]
	): MonoTypeOperatorFunction<T> => {
		return buildTapLog(this.log, undefined, (l) =>
			l(message, ...optionalParams),
		);
	};

	public logObservableComplete = <T>(
		message?: any,
		...optionalParams: any[]
	): MonoTypeOperatorFunction<T> => {
		return buildTapLog(this.log, undefined, undefined, (l) =>
			l(message, ...optionalParams),
		);
	};

	public log: SessionLogFunction = (
		message?: any,
		...optionalParams: any[]
	): void => {
		this.push(message, ...optionalParams);
	};

	public fetch = (): string[] => {
		const logs: string[] = this.store.get(
			'sessionLoggerLogs',
			TypedStoreType.ARRAY,
			[],
		);
		return logs;
	};

	public reset = (): void => {
		this.hookLogEvent();
		this.store.set('sessionLoggerLogs', []);
	};
}

export const SessionLogger = new SessionLoggerInternal(
	new SessionStoreService(),
);

(window as any).loggerlogger = SessionLogger;
