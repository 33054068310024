import { Type } from 'class-transformer';
import {
	OrganizationStudentSearchItemFieldModel,
	OrganizationStudentSearchItemFieldModel_ClassTransform_Type,
} from './organization-student-search-item-field.model';

export class OrganizationStudentSearchItemFieldsModel {
	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	uid: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	first_name: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	last_name: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	email: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	grade: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	phone: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	enrolled: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	deactivated: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	program_name: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	parent_program_name: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	tier: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	tag: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	grad_plan: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	cohort_year: OrganizationStudentSearchItemFieldModel;

	@Type(OrganizationStudentSearchItemFieldModel_ClassTransform_Type)
	birthdate: OrganizationStudentSearchItemFieldModel;
}

export function OrganizationStudentSearchItemFieldsModel_ClassTransform_Type() {
	return OrganizationStudentSearchItemFieldsModel;
}
