import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class CurriculumMappingSectionTeacherGroupModel {
	group_id: string;
	group_title: string;
}

export function CurriculumMappingSectionTeacherGroupModel_ClassTransform_Type() {
	return CurriculumMappingSectionTeacherGroupModel;
}

export class CurriculumMappingSectionTeacherMappingGraderModel {
	grader_uid: number;
	grader_name: string;
	is_substitute_teacher: boolean;
}

export function CurriculumMappingSectionTeacherMappingGraderModel_ClassTransform_Type() {
	return CurriculumMappingSectionTeacherGroupModel;
}

export class CurriculumMappingMigrateRegistrationModel {
	uid: number;
	name: string;
	registration_id: number;
	institute_title: string;
	program_type_title: string;
	institute_state: string;
	current_teacher_uid: number;
	current_teacher_name: string;
	current_teacher_mapping_id: number;
	suggested_teacher_mapping_id: number;
}

export function CurriculumMappingMigrateRegistrationModel_ClassTransform_Type() {
	return CurriculumMappingMigrateRegistrationModel;
}

export class CurriculumMappingMigrateTeacherSectionOptionsModel {
	teacher_mapping_id: number;
	teacher_uid: number;
	teacher_name: string;
}

export function CurriculumMappingMigrateTeacherSectionOptionsModel_ClassTransform_Type() {
	return CurriculumMappingMigrateTeacherSectionOptionsModel;
}

export class CurriculumMappingMigrateStudentsListResponseModel {
	registration_list_count: number;
	@Type(CurriculumMappingMigrateRegistrationModel_ClassTransform_Type)
	registration_list: CurriculumMappingMigrateRegistrationModel[];
	@Type(CurriculumMappingMigrateTeacherSectionOptionsModel_ClassTransform_Type)
	teacher_section_options: CurriculumMappingMigrateTeacherSectionOptionsModel[];
}

abstract class CurriculumMappingSectionTeacherMappingBaseModel {
	alternate_primary_mapping: string;
	teacher_mapping_id: number;
	teacher_uid: number;
	teacher_name: string;
	@Type(CurriculumMappingSectionTeacherMappingGraderModel_ClassTransform_Type)
	graders: CurriculumMappingSectionTeacherMappingGraderModel[];
	@Type(CurriculumMappingSectionTeacherGroupModel_ClassTransform_Type)
	groups: CurriculumMappingSectionTeacherGroupModel[];
}

export class CurriculumMappingSectionTeacherMappingModel extends CurriculumMappingSectionTeacherMappingBaseModel {
	lms_external_id: string;
}

export class CurriculumMappingSectionTeacherMappingScheduledModel extends CurriculumMappingSectionTeacherMappingBaseModel {
	id: number;
	section_id: number;
	section_title: string;
	@Expose({ name: 'schedule_start_timestamp' })
	@Transform(dateTransformer)
	schedule_start_date: Date;
}

export class CurriculumMappingGraderOptionModel {
	uid: number;
	name: string;
}

export interface CurriculumMappingSectionMappingGroupTeacherOptionArgument {
	check_state_certificates: boolean;
	check_endorsements: boolean;
	teachers?: number[];
	groups?: string[];
}

export interface CurriculumMappingSectionTeacherMappingCreateArgument
	extends CurriculumMappingSectionTeacherMappingEditArgument {
	teacher_uid: number;
	alternate_primary_mapping: string;
	allow_duplicate_teacher?: boolean;
}

export interface CurriculumMappingSectionTeacherMappingEditArgument {
	check_state_certificates?: boolean;
	check_endorsements?: boolean;
	groups?: string[];
	grader_uids?: number[];
}

export interface CurriculumMappingSectionTeacherScheduleMappingArgument
	extends CurriculumMappingSectionTeacherMappingEditArgument {
	section_id: number;
	teacher_mapping_id: number;
	schedule_start_timestamp: number;
	teacher_uid?: number;
	alternate_primary_mapping?: string;
	allow_duplicate_teacher?: boolean;
}

export interface CurriculumMappingSectionCopyTeachersArgument {
	from_section: number;
	check_state_certificates: boolean;
	check_endorsements: boolean;
}

export interface CurriculumMappingSectionMigrateStudentsArgument {
	registration_id: number;
	teacher_mapping_id: number;
}
