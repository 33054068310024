import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	MeetupMinutesModel,
	MeetupMinutesModel_ClassTransform_Type,
} from '../organizations/meetups/meetup-minutes.model';
import {
	TrackerUserPerformance,
	TrackerUserPerformance_ClassTransform_Type,
} from '../students/performance/student-performance-history-detailed.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class ACTrackerClassPacingModel {
	@Transform(dateTransformer)
	start_date: Date;
	weeks: number;
}

export class ACTrackerClassModel {
	id: number;
	title: string;
	assignment_count: number;
	assignment_completed: number;
	@Expose({ name: 'success' })
	grade: number;
	progress: number;
	notes: string;
	@Transform(dateTransformer)
	end_date: Date;
	@Transform(dateTransformer)
	counted_month: Date;
	@Type(ACTrackerClassPacingModel_ClassTransform_Type)
	pacing: ACTrackerClassPacingModel[];
	registration_type: string;
	@Expose({ name: 'success_zero' })
	grade_zero: number;
	pending_final: boolean;
	lms: string;
}

export class ACTrackerUserGraduationPacing {
	@Transform(dateTransformer)
	estimate_graduation_date: Date;
	monthly_credit_rate: number;
	status: string;
	@Transform(dateTransformer)
	target_end_date: Date;
	type: 'cohort_year' | 'end_date';
}

export class ACTrackUserGraduation {
	credits_remain: number;
	@Transform(dateTransformer)
	expected_date: Date;
	@Type(ACTrackerUserGraduationPacing_ClassTransform_Type)
	pacing: ACTrackerUserGraduationPacing;
}

export class ACTrackerItemModel {
	cohort_year: number;
	credits_remain: number;
	uid: number;
	@Transform(dateTransformer)
	access: Date;
	institute_id: number;
	timezone: string;
	title: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	assigned_uid: number;
	@Transform(dateTransformer)
	assigned_at: Date;
	@Transform(dateTransformer)
	activated: Date;
	profile_first_name: string;
	profile_preferred_name: string;
	profile_last_name: string;
	profile_student_year: string;
	credits: number;
	phone: number;
	phone_type: string;
	assigned_first: string;
	assigned_last: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	state_program: string;
	@Expose({ name: 'title' })
	program_title: string;
	@Expose({ name: 'parent_title' })
	program_parent_title: string;
	is_1418: number;
	is_paused: boolean;
	counted_classes: number;
	completed_class_count: number;
	@Type(MeetupMinutesModel_ClassTransform_Type)
	meetup_minutes: MeetupMinutesModel;
	@Type(ACTrackerClassModel_ClassTransform_Type)
	classes: ACTrackerClassModel[];
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	weekly_assignments: number;
	@Type(ACTrackUserGraduation_ClassTransform_Type)
	graduation: ACTrackUserGraduation;
	@Type(TrackerUserPerformance_ClassTransform_Type)
	performance_data: TrackerUserPerformance;
	reenrolled: boolean;
	pending_finals: number;
	assigned_la_first: string;
	assigned_la_last: string;
	assigned_la_uid: number;
	mail: string;
	tags: string[];
	@Transform(dateTransformer)
	deactivated: Date;
	@Transform(dateTransformer)
	star_math: Date;
	@Transform(dateTransformer)
	star_reading: Date;
	track: string;
	@Transform(dateTransformer)
	safe_funding_expiration: Date;
	persistent_courses: number;
	graduation_request_status_title: string;
	user_link_id: number;
}

export function ACTrackUserGraduation_ClassTransform_Type() {
	return ACTrackUserGraduation;
}
export function ACTrackerClassModel_ClassTransform_Type() {
	return ACTrackerClassModel;
}
export function ACTrackerClassPacingModel_ClassTransform_Type() {
	return ACTrackerClassPacingModel;
}
export function ACTrackerUserGraduationPacing_ClassTransform_Type() {
	return ACTrackerUserGraduationPacing;
}
