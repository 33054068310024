import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { joinQueryString } from 'src/lib/utilities/api/http';
import { DegreeJsonApiModel, DegreeModel } from './models/degree.model';
import {
	OccupationJsonApiModel,
	OccupationModel,
} from './models/occupation.model';
import {
	PostSecondaryInstituteJsonApiModel,
	PostSecondaryInstituteModel,
} from './models/postsecondary-institution.model';

@Injectable({
	providedIn: 'root',
})
export class JsonApiResourcesService {
	/*
		DOCUMENTATION FOR WRITTING DRUPAL JSONAPI FILTERS
		https://www.drupal.org/docs/8/core/modules/jsonapi-module/filtering
	*/

	constructor(private httpClient: HttpClient) {}

	public getPostSecondaryInstitutes = (
		search: {
			name?: string;
			id?: number;
		} = {},
	): Observable<PostSecondaryInstituteModel[]> => {
		const queries: string[] = [];

		if (search) {
			if (search.name) {
				queries.push(`filter[name][condition][path]=name`);
				queries.push(`filter[name][condition][operator]=CONTAINS`);
				queries.push(`filter[name][condition][value]=${search.name}`);
			}

			if (search.id != null) {
				queries.push(`filter[id][condition][path]=drupal_internal__id`);
				queries.push(`filter[id][condition][operator]==`);
				queries.push(`filter[id][condition][value]=${search.id}`);
			}
		}

		return this.httpClient
			.get<PostSecondaryInstituteJsonApiModel>(
				`/api/v1/jsonapi/ga_postsec_inst/ga_postsec_inst${joinQueryString(
					queries,
				)}`,
			)
			.pipe(
				map((r) =>
					plainToInstance(PostSecondaryInstituteJsonApiModel, r).exportData(),
				),
				catchError((r) =>
					throwError(() =>
						plainToInstance(PostSecondaryInstituteJsonApiModel, r.error),
					),
				),
			);
	};

	public getOccupations = (
		search: {
			name?: string;
			id?: number;
		} = {},
	): Observable<OccupationModel[]> => {
		const queries: string[] = [];

		if (search) {
			if (search.name) {
				queries.push(`filter[name][condition][path]=name`);
				queries.push(`filter[name][condition][operator]=CONTAINS`);
				queries.push(`filter[name][condition][value]=${search.name}`);
			}

			if (search.id != null) {
				queries.push(`filter[id][condition][path]=drupal_internal__id`);
				queries.push(`filter[id][condition][operator]==`);
				queries.push(`filter[id][condition][value]=${search.id}`);
			}
		}

		return this.httpClient
			.get<OccupationJsonApiModel>(
				`/api/v1/jsonapi/ga_occupation/ga_occupation${joinQueryString(
					queries,
				)}`,
			)
			.pipe(
				map((r) => plainToInstance(OccupationJsonApiModel, r).exportData()),
				catchError((r) =>
					throwError(plainToInstance(OccupationJsonApiModel, r.error)),
				),
			);
	};

	public getDegrees = (
		search: {
			name?: string;
			id?: number;
		} = {},
	): Observable<DegreeModel[]> => {
		const queries: string[] = [];

		if (search) {
			if (search.name) {
				queries.push(`filter[name][condition][path]=name`);
				queries.push(`filter[name][condition][operator]=CONTAINS`);
				queries.push(`filter[name][condition][value]=${search.name}`);
			}

			if (search.id != null) {
				queries.push(`filter[id][condition][path]=drupal_internal__id`);
				queries.push(`filter[id][condition][operator]==`);
				queries.push(`filter[id][condition][value]=${search.id}`);
			}
		}

		return this.httpClient
			.get<DegreeJsonApiModel>(
				`/api/v1/jsonapi/ga_degree/ga_degree${joinQueryString(queries)}`,
			)
			.pipe(
				map((r) => plainToInstance(DegreeJsonApiModel, r).exportData()),
				catchError((r) =>
					throwError(plainToInstance(DegreeJsonApiModel, r.error)),
				),
			);
	};
}
