<section #rootElement class="billboard" tabindex="-1">
	<div class="billboard-header">
		<h2 class="billboard-title">Contact</h2>
		<div class="billboard-actions d-flex">
			<div class="text-center flex-grow-0 mx-2 align-self-center">
				@if (existingPendingChange) {
					<ae-icon-tooltip
						message="A request to change this info is pending"
						type="pending"
					/>
				}
			</div>
			@if (canEditContact) {
				<button
					class="btn btn-default"
					id="profile.contact.edit.enable"
					type="button"
					[disabled]="loading"
					(click)="enableEdit()"
				>
					Edit
				</button>
			}
		</div>
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading">
			<!-- Phone Numbers -->
			@if (hasKey(contactData, 'phone')) {
				<div class="mb-4">
					<h3 class="fw-bold fs-5">Phone Numbers</h3>
					@if (contactData?.phone.length === 0) {
						<div class="text-muted">No Phone Numbers</div>
					}
					@for (phone of contactData?.phone; track phone) {
						<div class="mb-1">
							<div class="fw-bold">
								Number
								@if (phone.prim) {
									<i
										aria-hidden="true"
										class="fas fa-star text-yellow ms-1"
									></i>
								}
							</div>
							<div>
								<ae-staff-phone [phone]="phone" />
							</div>
						</div>
					}
				</div>
			}

			<!-- Emails -->

			@if (hasKey(contactData, 'email')) {
				<div class="mb-4">
					<h3 class="fw-bold fs-5">Email Address</h3>
					@if (contactData?.email.length === 0) {
						<div class="text-muted">No Email Address</div>
					}
					@for (email of contactData?.email | slice: 0 : 1; track email) {
						<div class="mb-1">
							<div class="fw-bold">
								Email
								@if (email.prim) {
									<i
										aria-hidden="true"
										class="fas fa-star text-yellow ms-1"
									></i>
								}
							</div>
							<div class="email-container">
								<a target="_blank" href="mailto:{{ email.value }}">
									{{ email.value }}
								</a>
							</div>
						</div>
					}
				</div>
			}

			<!-- SSO Email -->

			@if (hasKey(contactData, 'ssoEmail') && contactData.ssoEmail.length > 0) {
				<div class="mb-4">
					<h3 class="fw-bold fs-5">Single Sign-On Email</h3>

					@for (ssoEmail of contactData.ssoEmail; track ssoEmail.value) {
						<div class="mb-1">
							<div class="text-truncate" [title]="ssoEmail.value">
								{{ ssoEmail.value }}
							</div>
						</div>
					}
				</div>
			}

			<!-- Mailing Address -->

			@if (hasKey(contactData, 'address')) {
				<div class="mb-4">
					<h3 class="fw-bold fs-5">Mailing Addresses</h3>
					@if (contactData?.address.length === 0) {
						<div class="text-muted">No Mailing Addresses</div>
					}
					@for (address of contactData?.address; track address) {
						<div class="mb-1">
							<div class="fw-bold">
								Address
								@if (address.prim) {
									<i
										aria-hidden="true"
										class="fas fa-star text-yellow ms-1"
									></i>
								}
							</div>
							<ae-address-display
								[address]="address.value"
								[includeAddressLink]="false"
							/>
						</div>
					}
				</div>
			}

			<!-- Calendar links -->
			@if (hasKey(contactData, 'messenger')) {
				<div class="mb-4">
					<h3 class="fw-bold fs-5">Calendar Link</h3>
					@if (contactData?.messenger.length === 0) {
						<div class="text-muted">No Calendar Link</div>
					}
					@if (contactData?.messenger.length > 0) {
						<div>
							<div class="email-container">
								<a
									target="_blank"
									href="{{ contactData.messenger[0].value | hrefUrl }}"
								>
									{{ contactData.messenger[0].value }}
								</a>
							</div>
						</div>
					}
				</div>
			}
		</div>
	</div>
</section>
