import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	StudentMeetupAdditionalInfoModel,
	StudentMeetupAdditionalInfoModel_ClassTransform_Type,
} from './student-meetup-additional-info.model';
import {
	StudentMeetupGateExemptionModel,
	StudentMeetupGateExemptionModel_ClassTransform_Type,
} from './student-meetup-gate-exemption.model';

export class StudentMeetupModel {
	id: number;
	meetup_id: string;
	log_id: number;
	@Expose({ name: 'meetup_time' })
	@Transform(dateTransformer)
	meetup_date: Date;
	@Expose({ name: 'arrival_timestamp' })
	@Transform(dateTransformer)
	arrival_date: Date;
	@Expose({ name: 'departure_timestamp' })
	@Transform(dateTransformer)
	departure_date: Date;
	minutes: number;
	meetup_host_uid: number;
	meetup_type: 'virtual' | 'physical';
	@Type(StudentMeetupAdditionalInfoModel_ClassTransform_Type)
	additional_data: StudentMeetupAdditionalInfoModel;
}

// API response for the GetMeetupInfo call
export class StudentMeetupInfoModel {
	program: string;
	personal: string;
	meetup_gate: boolean;
	@Type(StudentMeetupGateExemptionModel_ClassTransform_Type)
	meetup_gate_exemption: StudentMeetupGateExemptionModel;
}

export function StudentMeetupModel_ClassTransform_Type() {
	return StudentMeetupModel;
}
