import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { StudentContactAttributes } from 'src/lib/services/api/students/contacts/student-contact-options.model';
import { UserDataPersonalContactModel } from 'src/lib/services/api/users/user-data.model';
import { PhoneNumberPipe } from '../../../../../pipes/phone-number.pipe';
import { IconTooltipComponent } from '../../../../../templates/global/icon-tooltip/icon-tooltip.component';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';

@Component({
	selector: 'ae-profile-familysupport-view',
	templateUrl: './profile-familysupport-view.component.html',
	styleUrls: ['./profile-familysupport-view.component.scss'],
	standalone: true,
	imports: [
		IconTooltipComponent,
		SpinWhileDirective,
		NgbTooltip,
		PhoneNumberPipe,
	],
})
export class ProfileFamilySupportViewComponent {
	@Input() public contactData: UserDataPersonalContactModel[];
	@Input() public canEditContact: boolean;
	@Input() public loading: boolean;
	@Input() public enableEdit: () => void;
	@Input() public existingPendingChange: boolean;

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;

	public hasAttribute = (
		contact: UserDataPersonalContactModel,
		attr: StudentContactAttributes,
	): boolean => {
		if (contact == null || contact.attributes == null) {
			return false;
		}
		return contact.attributes.indexOf(attr) !== -1;
	};
}
