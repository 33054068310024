import { Injectable } from '@angular/core';
import { Observable, of, race, timer } from 'rxjs';
import {
	catchError,
	delay,
	filter,
	map,
	switchMap,
	take,
} from 'rxjs/operators';
import { WampService } from '../../wamp.service';

@Injectable({
	providedIn: 'root',
})
export class GabbyWampPublishFactoryService {
	constructor(private wampService: WampService) {}

	public notify_navigateRequestToUser$ = (
		userLinkId: number,
	): Observable<boolean> => {
		return race([
			// Listen for the ack so we know our message was received
			this.wampService
				.subscribe$<[number]>('gabby.navigate.request.user.ack')
				.pipe(
					map(([ackedUserLinkId]) => ackedUserLinkId === userLinkId),
					filter((x) => x),
					take(1),
				),

			// Fire the publish
			timer(50) // Delay one cycle to give time for the ack to attach
				.pipe(
					switchMap(() =>
						this.wampService.publish$('gabby.navigate.request.user', [
							userLinkId,
						]),
					),
					delay(3000), // Wait 3 seconds for a response then give up
					map(() => false),
					take(1),
				),
		]).pipe(catchError(() => of(false)));
	};

	public notify_channelFlagsChanged$ = (): Observable<unknown> => {
		return this.wampService.publish$(
			'gabby.channel.flags.changed',
			null,
			null,
			true,
		);
	};

	public notify_channelUserTyping$ = (
		channelId: number,
		typingUserLinkId: number,
		active: boolean,
	): Observable<unknown> => {
		return this.wampService.publishGlobal$('gabby.channel.user.typing', [
			channelId,
			typingUserLinkId,
			active,
		]);
	};
}
