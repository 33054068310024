import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import spacetime from 'spacetime';
import { CalendarEventModel } from 'src/lib/services/api/organizations/calendar/calendar-event.model';
import { StudentProgramEventsService } from 'src/lib/services/api/students/program-events/student-program-events.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentProgramEventStoreService {
	private _programEventsCache = new BehaviorCache<
		[uid: number, month: number, year: number],
		CalendarEventModel[]
	>(
		([uid, month, year]) => {
			const formatting = '{year}-{iso-month}-{date-pad}';
			const start = spacetime([year, month, 1]);
			const endVal = start
				.endOf('month')
				.add(1, 'date')
				.format(formatting) as string;

			const startVal = start.subtract(1, 'date').format(formatting) as string;

			return this.studentProgramEventsService.getStudentListCalendar(
				uid,
				startVal,
				endVal,
			);
		},
		'StudentProgramEventStoreService programEventsCache',
		() => [],
	);

	constructor(
		private studentProgramEventsService: StudentProgramEventsService,
	) {}

	public events$ = (
		uid: number,
		month: number,
		year: number,
	): Observable<CalendarEventModel[]> => {
		return this._programEventsCache.getCache([uid, month, year]);
	};

	public refreshEvents = (
		uid: number,
		month: number,
		year: number,
	): Promise<boolean> => {
		return this._programEventsCache.fetchData([uid, month, year], true);
	};

	public clearAllEvents = (): void => {
		this._programEventsCache.clearAllData();
	};
}
