import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	JsonApiDataModel,
	JsonApiDataModel_ClassTransform_TransformFactory,
	JsonApiModel,
} from '../json-api.model';

export class OccupationModel {
	@Expose({ name: 'drupal_internal__id' })
	id: number;
	langcode: string;
	status: boolean;
	name: string;

	@Transform(dateTransformer)
	created: Date;

	@Transform(dateTransformer)
	changed: Date;

	onet_soc_code: string;
	description: string;
}

export function OccupationModel_ClassTransform_Type() {
	return OccupationModel;
}

export class OccupationJsonApiModel extends JsonApiModel<OccupationModel> {
	@Transform(
		JsonApiDataModel_ClassTransform_TransformFactory(
			OccupationModel_ClassTransform_Type,
		),
	)
	data?: JsonApiDataModel<OccupationModel>[];
}
