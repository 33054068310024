<div class="modal-body mb-0 pb-0 pt-1">
	<ae-profile-sitemap />
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="print.modal.component.close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Close
	</button>
</div>
