import { Type } from 'class-transformer';

export class UserChecklistsAvailableModel {
	checklist_id: number;
	checklist_title: string;
	@Type(UserChecklistsLevelModel_ClassTransform_Type)
	levels: UserChecklistsLevelModel[];
}

export class UserChecklistsLevelModel {
	level_id: number;
	level_title: string;
	level_key: string;
	level_color: string;
}

export function UserChecklistsLevelModel_ClassTransform_Type() {
	return UserChecklistsLevelModel;
}
