import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import spacetime from 'spacetime';
import { CalendarEventModel } from 'src/lib/services/api/organizations/calendar/calendar-event.model';
import { CalendarEventsService } from 'src/lib/services/api/organizations/calendar/calendar-events.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class ProgramEventStoreService {
	private _programEventsCache = new BehaviorCache<
		[programId: number, month: number, year: number],
		CalendarEventModel[]
	>(
		([programId, month, year]) => {
			const formatting = '{year}-{iso-month}-{date-pad}';
			const start = spacetime([year, month, 1]);
			const endVal = start
				.endOf('month')
				.add(1, 'date')
				.format(formatting) as string;
			const startVal = start.subtract(1, 'date').format(formatting) as string;

			return this.calendarEventsService.getCalendarEvents(
				programId,
				startVal,
				endVal,
			);
		},
		'ProgramEventStoreService programEventsCache',
		() => [],
	);

	constructor(private calendarEventsService: CalendarEventsService) {}

	public events$ = (
		programId: number,
		month: number,
		year: number,
	): Observable<CalendarEventModel[]> => {
		return this._programEventsCache.getCache([programId, month, year]);
	};

	public refreshEvents = (
		programId: number,
		month: number,
		year: number,
	): Promise<boolean> => {
		return this._programEventsCache.fetchData([programId, month, year], true);
	};
}
