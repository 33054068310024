import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	StudentPastCourseModel,
	StudentPastCourseModel_ClassTransform_Type,
} from './student-past-course.model';

export class StudentPastCoursesResponseModel extends AbstractPagingResponseModel<StudentPastCourseModel> {
	@Type(StudentPastCourseModel_ClassTransform_Type)
	results: StudentPastCourseModel[];
}
