import { Expose, Transform, Type } from 'class-transformer';
import {
	StudentStateTestAttendanceStatus,
	StudentStateTestResult,
	StudentStateTestStatus,
} from 'src/lib/constants/state-tests';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { hasValue } from 'src/lib/utilities/compare';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from '../../../utility/utility-models/address.model';

export function isStudentStateTestDone(result: {
	status: StudentStateTestStatus;
	result: StudentStateTestResult;
}): boolean {
	if (
		result.status === StudentStateTestStatus.excused ||
		result.result === StudentStateTestResult.passed
	) {
		return true;
	} else if (
		result.status === StudentStateTestStatus.attempt_only &&
		hasValue(result.result)
	) {
		return true;
	} else return false;
}

export class StudentStateTestResultModel {
	status: StudentStateTestStatus;
	result: StudentStateTestResult;
	score: string;
	@Transform(dateTransformer)
	completed: Date;

	get isDone() {
		return isStudentStateTestDone(this);
	}
}

export function StudentStateTestResultModel_ClassTransform_Type() {
	return StudentStateTestResultModel;
}

export class StudentStateTestEventAttendanceModel {
	acknowledged_by: { uid: number; name: string };
	@Transform(dateTransformer)
	acknowledged_date: Date;
	status: StudentStateTestAttendanceStatus;
	@Transform(dateTransformer)
	updated_date: Date;
}

export function StudentStateTestEventAttendanceModel_ClassTransform_Type() {
	return StudentStateTestEventAttendanceModel;
}

export class StudentStateTestEventModel {
	in_groups: boolean;
	@Type(StudentStateTestEventAttendanceModel_ClassTransform_Type)
	attendance: StudentStateTestEventAttendanceModel;
	@Type(StudentStateTestResultModel_ClassTransform_Type)
	result: StudentStateTestResultModel;
	test_id: number;
	test_name: string;
	test_instance_id: number;
	test_instance_name: string;
	@Type(UserAddressModel_ClassTransform_Type)
	address: UserAddressModel;
	@Transform(dateTransformer)
	start_time: Date;
	@Transform(dateTransformer)
	end_time: Date;
	student_notes: string;
	is_required: boolean;
	is_indeterminate_time: boolean;
}

export function StudentStateTestEventModel_ClassTransform_Type() {
	return StudentStateTestEventModel;
}

export class StudentStateTestModel {
	test_id: number;
	instance_id: number;
	test_title: string;
	test_instance_title: string;
	@Expose({ name: 'completed_timestamp' })
	@Transform(dateTransformer)
	completed_date: Date;
	status: StudentStateTestStatus | null;
	status_text: string;
	result_id: number;
	result: StudentStateTestResult | null;
	result_text: string;
	score: string;
	is_required: boolean;
	is_eligible: boolean;
	primary: boolean;
	@Transform(dateTransformer)
	created_on: Date;
	@Transform(dateTransformer)
	updated_on: Date;
	student_note: string;

	get isDone() {
		return isStudentStateTestDone(this);
	}
}

export function StudentStateTestModel_ClassTransform_Type() {
	return StudentStateTestModel;
}
