import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { UserModalKeys } from './user-modal-keys.enum';

export class UserModalModel<T = any> {
	modal_additional_data: T;
	modal_id: number;
	modal_title: string;
	modal_text: string;
	modal_key: UserModalKeys;
	type: 'Speedbump' | 'Gate' | null;

	_subkey: string; // Bumpgates have subkeys
	get fullkey() {
		return `${this.modal_key}.${this._subkey ?? ''}`;
	}

	@Expose({ name: 'completed_on_timestamp' })
	@Transform(dateTransformer)
	completed_on_date: Date;
	icon?: string;
}
export class HaltingCourseResponseADQueryStringModel {
	registration: number;
}

export function HaltingCourseResponseADQueryStringModel_ClassTransform_Type() {
	return HaltingCourseResponseADQueryStringModel;
}

export class HaltingCourseResponseADRequirementModel {
	response_key: string;
	response_title: string;
	@Type(HaltingCourseResponseADQueryStringModel_ClassTransform_Type)
	additional_query_string: HaltingCourseResponseADQueryStringModel;
}

export function HaltingCourseResponseADRequirementModel_ClassTransform_Type() {
	return HaltingCourseResponseADRequirementModel;
}
export class HaltingCourseResponseADModel {
	@Type(HaltingCourseResponseADRequirementModel_ClassTransform_Type)
	requirements: HaltingCourseResponseADRequirementModel[];
}
