import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class ChecklistActionModel {
	action_id: number;
	description: string;
	type: string;
	script_text: string;
	@Transform(dateTransformer)
	completed_on: Date;
	completed_by: number;
	completed_by_name: string;
	additional_data: string;
}

export function ChecklistActionModel_ClassTransform_Type() {
	return ChecklistActionModel;
}

export class UserChecklistModel {
	checklist_id: number;
	checklist_title: string;
	level_id: number;
	level_title: string;
	level_key: string;
	level_color: string;
	@Type(ChecklistActionModel_ClassTransform_Type)
	actions: ChecklistActionModel[];
}
