import { FieldRequiredValue } from './field-required-value.enum';
import { FieldTypeValue } from './field-type-value.enum';

export class FieldModel {
	title: string;
	type: FieldTypeValue;
	required: FieldRequiredValue;
	default?: any;
	validation_requirements?: Record<string, unknown>;
}

export function FieldModel_ClassTransform_Type() {
	return FieldModel;
}
