import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentFutureCourseModel {
	registration_id: number;
	title: string;
	@Transform(dateTransformer)
	requested_date: Date;
	notes: string;
	course_id: number;
	course_number: string;
	version_id: number;
	version_code: string;
	section_id: number;
	section_type: number;
	credits_possible: number;
	@Transform(dateTransformer)
	expected_start: Date;
	section_valid: boolean;
	@Expose({ name: 'scheduled_start' })
	@Transform(dateTransformer)
	schedule_start_actual: Date;
	weight: number;
	subject: string;
}

export function StudentFutureCourseModel_ClassTransform_Type() {
	return StudentFutureCourseModel;
}
