@if (!errorLoading) {
	@if (!editEnabled) {
		<ae-profile-familysupport-view
			[canEditContact]="canEditContact"
			[contactData]="contactData"
			[enableEdit]="enableEdit"
			[existingPendingChange]="existingPendingChange"
			[loading]="loading"
		/>
	}
	@if (editEnabled) {
		<ae-profile-familysupport-edit
			[ageGate]="ageGate"
			[contactData]="contactData"
			[contactOptions]="contactOptions"
			[contactSaveListener]="resetContactForm"
			[dropdownOptions]="dropdownOptions"
			[userId]="userId"
		/>
	}
} @else {
	<section class="billboard">
		<div class="billboard-header">
			<h2 class="billboard-title">Contact</h2>
		</div>
		<div class="billboard-body">
			<div class="text-center text-danger my-3">
				There was an issue loading Contact Info, if the problem persists please
				contact support
			</div>
			<div class="d-flex justify-content-center mb-2">
				<button
					class="btn btn-default px-3"
					id="profile.contact.error.retry"
					type="button"
					(click)="getContactInfo()"
				>
					Retry
				</button>
			</div>
		</div>
	</section>
}
