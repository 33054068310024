import { Type } from 'class-transformer';
import {
	ReentryInstituteModel,
	ReentryInstituteModel_ClassTransform_Type,
} from './re-entry-request.model';

export class ReentryRequirementModel {
	passed: boolean;
	title: string;
	type: string;
	type_title: string;
	can_waive: false;
	requires_file: false;
}

export function ReentryRequirementModel_ClassTransform_Type() {
	return ReentryRequirementModel;
}

export class ReentryRequirementsResponseModel {
	@Type(ReentryInstituteModel_ClassTransform_Type)
	target_institute: ReentryInstituteModel;
	@Type(ReentryRequirementModel_ClassTransform_Type)
	requirements: ReentryRequirementModel[];
}
