import { AbstractControl } from '@angular/forms';
import spacetime from 'spacetime';
import { isValidDate } from 'src/lib/utilities/compare';
import { convertToDate } from 'src/lib/utilities/convert';
import { convertToCalendarDate } from 'src/lib/utilities/date';
import { DateRangePickerValue } from './date-range-picker.types';

export function createDateValidation(
	blackoutDateMethod?: (date: Date) => boolean,
): (_: any) => any {
	return (ctrl: AbstractControl<DateRangePickerValue>) => {
		const error = {
			date: true,
		};

		if (ctrl.value && ctrl.value.fromDate != null) {
			const dateConverted = isValidDate(convertToDate(ctrl.value.fromDate));
			if (dateConverted !== true) {
				return error;
			}
		}

		if (ctrl.value && ctrl.value.toDate != null) {
			const dateConverted = isValidDate(convertToDate(ctrl.value.toDate));
			if (dateConverted !== true) {
				return error;
			}
		}

		if (
			blackoutDateMethod &&
			(blackoutDateMethod(ctrl.value ? ctrl.value.toDate : null) ||
				blackoutDateMethod(ctrl.value ? ctrl.value.fromDate : null))
		) {
			return error;
		}

		// No errors in date format
		return null;
	};
}

export function createBothDateValidation(): (_: any) => any {
	return (ctrl: AbstractControl<DateRangePickerValue>) => {
		const error = {
			bothDates: true,
		};

		// Checking if one value is filled but not the other (because it could be empty and not required)
		if (
			ctrl.value &&
			((ctrl.value.fromDate && !ctrl.value.toDate) ||
				(ctrl.value.toDate && !ctrl.value.fromDate))
		) {
			return error;
		}

		// No errors in date format
		return null;
	};
}

export function createStartingDateValidation(): (_: any) => any {
	return (ctrl: AbstractControl<DateRangePickerValue>) => {
		const error = {
			startDate: true,
		};

		if (
			ctrl.value &&
			ctrl.value.toDate != null &&
			ctrl.value.fromDate != null
		) {
			const toDateConverted = isValidDate(convertToDate(ctrl.value.toDate));
			const fromDateConverted = isValidDate(convertToDate(ctrl.value.fromDate));
			if (
				toDateConverted &&
				fromDateConverted &&
				spacetime(ctrl.value.fromDate).isAfter(spacetime(ctrl.value.toDate))
			) {
				return error;
			}
		}

		// No errors in date format
		return null;
	};
}

export function createMinCalendarDateValidation(minDate: any): (_: any) => any {
	return (ctrl: AbstractControl<DateRangePickerValue>) => {
		if (ctrl.value == null) {
			return null;
		}
		const validDates = createDateValidation();
		const convertedMinDate = convertToDate(minDate);
		const valid =
			validDates(ctrl) == null && isValidDate(convertedMinDate) === true;
		const error = {
			minDate: {
				given: ctrl.value,
				min: convertedMinDate,
			},
		};

		if (
			(valid &&
				convertToCalendarDate(convertToDate(ctrl.value.toDate)) <
					convertToCalendarDate(convertToDate(minDate))) ||
			convertToCalendarDate(convertToDate(ctrl.value.fromDate)) <
				convertToCalendarDate(convertToDate(minDate))
		) {
			return error;
		}

		// Date greater than minDate
		return null;
	};
}

export function createMaxCalendarDateValidation(
	maxDate: Date,
): (_: any) => any {
	return (ctrl: AbstractControl<DateRangePickerValue>) => {
		if (ctrl.value == null) {
			return null;
		}

		const validDates = createDateValidation();
		const convertedMaxDate = convertToDate(maxDate);
		const valid =
			validDates(ctrl) == null && isValidDate(convertedMaxDate) === true;
		const error = {
			maxDate: {
				given: ctrl.value,
				max: convertedMaxDate,
			},
		};

		if (
			(valid &&
				convertToCalendarDate(convertToDate(ctrl.value.toDate)) >
					convertToCalendarDate(convertToDate(maxDate))) ||
			convertToCalendarDate(convertToDate(ctrl.value.fromDate)) >
				convertToCalendarDate(convertToDate(maxDate))
		) {
			return error;
		}

		// Date less than maxDate
		return null;
	};
}
