import {
	StudentInformationAddressModel,
	StudentInformationEmailModel,
	StudentInformationPhoneModel,
} from './student-information.model';

export class StudentInformationArgument {
	preferred_name?: string;
	attendance_class?: string;
	birthdate?: Date;
	first_name?: string;
	middle_name?: string;
	last_name?: string;
	suffix?: string;
	maiden_name?: string;
	gender?: string;
	ethnicity?: string; // API still refers to race as ethnicity 14E31FFD
	grade?: string;
	student_id?: string;
	state_id?: string;
	cohort_year?: string;
	courses_concurrent?: number;
	district_id?: string;
	calculated_grade?: number;
	email?: string;
	graduation_plan?: number;
	track?: number;
	sr_pay_level?: number;
	sr_pay_level_notes?: string;
	more_other?: string;
	district_residence?: string;
	custom_district?: string;
	timezone?: string;
	throttle_status?: string;
	contact_emails?: StudentInformationEmailModel[];
	phone_numbers?: StudentInformationPhoneModel[];
	addresses?: StudentInformationAddressModel[];
	student_location?: string;
}

export class StudentInformationUpdateResponseModel {
	uid: number;
}

export function StudentInformationUpdateResponseModel_ClassTransform_Type() {
	return StudentInformationUpdateResponseModel;
}
