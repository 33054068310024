import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export enum GraduationReviewStatus {
	Requested = 1,
	Unchecked = 2,
	MissingGraduationRequirements = 3,
	WaitingOnProgram = 4,
	WaitingOnStudent = 5,
	ApprovedForGraduation = 6,
	Rejected = 7,
	NeedsTesting = 8,
	GradVerification = 9,
}

export class GraduationReviewOptionModel {
	key: string | number;
	title: string;
}

export function GraduationReviewOptionModel_ClassTransformType() {
	return GraduationReviewOptionModel;
}

export class GraduationReviewUpdateReasonsModel {
	@Type(GraduationReviewOptionModel_ClassTransformType)
	'3': GraduationReviewOptionModel[];
}

export function GraduationReviewUpdateReasonsModel_ClassTransformType() {
	return GraduationReviewUpdateReasonsModel;
}

export class GraduationReviewOptionResponseModel {
	success: boolean;
	@Type(GraduationReviewOptionModel_ClassTransformType)
	checklist_fields: GraduationReviewOptionModel[];
	@Type(GraduationReviewOptionModel_ClassTransformType)
	status: GraduationReviewOptionModel[];
	@Type(GraduationReviewOptionModel_ClassTransformType)
	allowed_status_options: GraduationReviewOptionModel[];
	@Type(GraduationReviewUpdateReasonsModel_ClassTransformType)
	update_reasons: GraduationReviewUpdateReasonsModel;
}

export class GraduationReviewRequestorModel {
	uid: number;
	name: string;
}

export function GraduationReviewRequestorModel_ClassTransformType() {
	return GraduationReviewRequestorModel;
}

export class GraduationReviewRequestChecklistModel {
	key: string;
	is_completed: boolean;
	@Transform(dateTransformer)
	completed_on: Date;
	@Type(GraduationReviewRequestorModel_ClassTransformType)
	completed_by: GraduationReviewRequestorModel;
}

export function GraduationReviewRequestChecklistModel_ClassTransformType() {
	return GraduationReviewRequestChecklistModel;
}

export class GradReviewFileModel {
	url: string;
	title: string;
}

export function GradReviewFileModel_ClassTransformType() {
	return GradReviewFileModel;
}

export class GraduationReviewRequestUpdateModel {
	id: number;
	is_current: boolean;
	@Type(GraduationReviewOptionModel_ClassTransformType)
	status: GraduationReviewOptionModel;
	@Transform(dateTransformer)
	created_on: Date;
	@Type(GraduationReviewRequestorModel_ClassTransformType)
	created_by: GraduationReviewRequestorModel;
	note: string;
	reasons: string[];
	@Type(GradReviewFileModel_ClassTransformType)
	files: GradReviewFileModel[];
}

export function GraduationReviewRequestUpdateModel_ClassTransformType() {
	return GraduationReviewRequestUpdateModel;
}

export class GraduationReviewRequestModel {
	id: number;
	is_completed: boolean;
	@Transform(dateTransformer)
	requested_on: Date;
	@Type(GraduationReviewRequestorModel_ClassTransformType)
	requested_by: GraduationReviewRequestorModel;
	@Transform(dateTransformer)
	last_updated_on: Date;
	@Type(GraduationReviewRequestChecklistModel_ClassTransformType)
	checklist: GraduationReviewRequestChecklistModel[];
	checklist_notes: string;
	@Type(GraduationReviewRequestUpdateModel_ClassTransformType)
	updates: GraduationReviewRequestUpdateModel[];
}

export interface GradReviewUpdateArgument {
	note?: string;
	status: GraduationReviewStatus;
	reasons?: string[]; // used for requirements only for status 3 currently
}

export interface GradReviewChecklistArgument {
	checklist_field: string;
	is_completed: boolean;
}

export interface GradReviewCreateArgument {
	checklist_notes: string;
	reason: string;
}
