import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { HttpCallCache } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	EnrollmentReportOptionModel,
	EnrollmentReportResponseModel,
} from './enrollment-report.model';

@Injectable({
	providedIn: 'root',
})
export class EnrollmentReportService {
	constructor(private httpClient: HttpClient) {}

	private httpCallCache_viewProgramEnrollmentReport = new HttpCallCache<
		string,
		EnrollmentReportResponseModel
	>(15 * 60 * 1000); // 15 minutes

	private httpCallCache_getProgramEnrollmentReportOptions = new HttpCallCache<
		number,
		EnrollmentReportOptionModel[]
	>(15 * 60 * 1000); // 15 minutes

	public viewProgramEnrollmentReport = (
		orgId: number,
		reportId: number,
		forceRefresh: boolean = false,
	): Observable<EnrollmentReportResponseModel> => {
		if (forceRefresh) {
			this.httpCallCache_viewProgramEnrollmentReport.flushAll();
		}

		return this.httpCallCache_viewProgramEnrollmentReport.fetch$(
			`orgId: ${orgId} reportId: ${reportId}`,
			this.httpClient
				.get<any>(`/api/v1/billing/enrollment_reports/${orgId}/${reportId}`)
				.pipe(
					mapResponse(
						(r) => plainToInstance(EnrollmentReportResponseModel, r),
						{
							errorCode: '8E8B201D',
						},
					),
				),
		);
	};

	public getProgramEnrollmentReportOptions = (
		orgId: number,
		forceRefresh: boolean = false,
	): Observable<EnrollmentReportOptionModel[]> => {
		if (forceRefresh) {
			this.httpCallCache_getProgramEnrollmentReportOptions.flushAll();
		}

		return this.httpCallCache_getProgramEnrollmentReportOptions.fetch$(
			orgId,
			this.httpClient
				.get<any>(`/api/v1/billing/enrollment_reports/${orgId}/options`)
				.pipe(
					mapResponse(
						(r) =>
							plainToInstance(
								EnrollmentReportOptionModel,
								r as EnrollmentReportOptionModel[],
							),
						{
							errorCode: '1F03D490',
						},
					),
				),
		);
	};
}
