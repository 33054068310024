import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StudentContactOptionsModel } from './student-contact-options.model';
import { StudentContactArgument } from './student-contact.argument';
import { StudentContactModel } from './student-contact.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsContactsService {
	constructor(private httpClient: HttpClient) {}

	public getContacts = (
		studentId: number,
	): Observable<StudentContactModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/contacts`)

			.pipe(
				mapResponse((r) => plainToInstance(StudentContactModel, r as any[]), {
					errorCode: 'A504F5B3',
				}),
			);
	};

	public getContact = (
		studentId: number,
		contactId: number,
	): Observable<StudentContactModel> => {
		return this.httpClient
			.get<StudentContactModel[]>(`/api/v1/students/${studentId}/contacts`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentContactModel, r), {
					errorCode: '958815DE',
					checkPreMap: false,
					validateSuccess: false,
				}),
				map((x) => {
					const filtered = x.filter((f) => f.link_id === contactId);
					if (filtered.length > 0) return filtered[0];
					else return null;
				}),
			);
	};

	public getContactOptions = (): Observable<StudentContactOptionsModel> => {
		return this.httpClient
			.get<StudentContactOptionsModel>(`/api/v1/students/contacts/options`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentContactOptionsModel, r), {
					errorCode: '2C4F1294',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public createContact = (
		studentId: number,
		args: StudentContactArgument,
	): Observable<StudentContactModel> => {
		return this.httpClient
			.post<StudentContactModel>(
				`/api/v1/students/${studentId}/contacts/add`,
				args,
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentContactModel, r), {
					errorCode: '12443A60',
					errorMessage: DefaultErrorMessage.Saving,
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public editContact = (
		studentId: number,
		contactId: number,
		args: StudentContactArgument,
	): Observable<StudentContactModel> => {
		return this.httpClient
			.post<StudentContactModel>(
				`/api/v1/students/${studentId}/contacts/${contactId}`,
				args,
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentContactModel, r), {
					errorCode: '51B6DE0B',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public deleteContact = (
		studentId: number,
		contactId: number,
	): Observable<any> => {
		return this.httpClient
			.delete<any>(`/api/v1/students/${studentId}/contacts/${contactId}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '51B6DE0B',
					errorMessage: 'There was an issue removing contact',
				}),
			);
	};
}
