<div class="modal-header">
	<h1 class="modal-title">Manage User Roles</h1>
	<button
		class="btn btn-outline-dark"
		id="role.edit.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<div *aeSpinWhile="loading" class="form-group">
		<div class="fw-bold">{{ item.name }}</div>
		<ng-select
			bindLabel="title"
			bindValue="key"
			[formControl]="selectCtrl"
			[items]="roleOptions"
			[multiple]="true"
		/>
	</div>
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="role.edit.modal.component.cancel"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Cancel
	</button>
	<button
		class="btn btn-primary"
		id="role.edit.modal.component.save"
		type="button"
		[disabled]="loading || !selectCtrl.valid || !selectCtrl.dirty"
		(click)="save()"
	>
		Save
	</button>
</div>
