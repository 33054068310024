import {
	Component,
	forwardRef,
	HostBinding,
	Input,
	OnDestroy,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { AsyncSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedValidatorFn } from 'src/lib/types/forms.def';

@Component({
	selector: 'ae-advanced-color-picker',
	templateUrl: './advanced-color-picker.component.html',
	styleUrls: ['./advanced-color-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AdvancedColorPickerComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => AdvancedColorPickerComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule],
})
export class AdvancedColorPickerComponent
	implements ControlValueAccessor, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() id: string;

	@HostBinding('class.custom-form-control') customFormControl = true;

	public disabled: boolean = false;

	protected colorValue: string;
	protected inputControl: FormControl<string>;

	private hexRegex = /^#?[0-9A-Fa-f]{6}$/;

	private _touchFunction: () => void;
	private _validateFns = new Map<string, TypedValidatorFn<string>>();
	private _changeFunction: (value: Partial<string>) => void = () => null;

	constructor(private fb: FormBuilder) {
		this.init();
	}

	private init = () => {
		this.inputControl = this.fb.control(null);

		this.inputControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((v) => {
				v = this.modifyText(v);
				this.colorValue = v;
				this.inputControl.setValue(v, { emitEvent: false });

				if (this._changeFunction) {
					this._changeFunction(v);
				}

				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	// Implementing NG_VALIDATORS
	public validate = () => {
		const errors = {};

		this._validateFns.forEach((fn) => {
			if (!fn) return;

			const error = fn(this.inputControl);
			if (error) {
				const key = Object.keys(error)[0];
				errors[key] = error[key];
			}
		});

		if (
			this.inputControl.value &&
			!new RegExp(this.hexRegex).test(this.inputControl.value)
		) {
			errors['ishex'] = { invalidColorValue: true };
		}

		return errors;
	};

	// Implementing ControlValueAccessor
	public writeValue(val: string): void {
		this.inputControl?.patchValue(val);
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}

	private modifyText = (input: string): string => {
		let str = input?.trim();
		if (new RegExp(this.hexRegex).test(str)) {
			if (!input.startsWith('#')) {
				str = `#${str}`;
			}
		}
		return str;
	};
}
