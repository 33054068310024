import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	StudentOfficialTranscriptRequestArgument,
	StudentTranscriptRequestArgument,
} from './student-transcript-request.argument';
import { StudentTranscriptRequestsResponseModel } from './student-transcript-requests-response.model';
import { TranscriptGenerateArgument } from './transcript-generate.argument';

@Injectable({
	providedIn: 'root',
})
export class StudentTranscriptsService {
	constructor(private httpClient: HttpClient) {}

	public getTranscriptRequests = (
		studentId: number,
	): Observable<StudentTranscriptRequestsResponseModel> => {
		return this.httpClient
			.get<StudentTranscriptRequestsResponseModel>(
				`/api/v1/students/${studentId}/transcript/request`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentTranscriptRequestsResponseModel, r),
					{ errorCode: '09FC6E11' },
				),
			);
	};

	public createTranscriptRequest = (
		studentId: number,
		args: StudentTranscriptRequestArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${studentId}/transcript/request`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '8979678E',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public createOfficialTranscriptRequest = (
		studentId: number,
		args: StudentOfficialTranscriptRequestArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`api/v1/students/${studentId}/transcript/request_official_transcript`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '7b02fe2c',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public bulkGenerateTranscripts = (args: TranscriptGenerateArgument[]) => {
		return this.httpClient
			.post<any>(`api/v1/students/transcript/generate`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '1DDBA020',
				}),
			);
	};
}
