import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	CredentialedFeedbackArgument,
	CredentialedResponseModel,
	CredentialedStaffNotifyArgument,
} from './credentialed-response.model';

@Injectable({
	providedIn: 'root',
})
export class CredentialedResponderService {
	constructor(private httpClient: HttpClient) {}

	public getResponseFeedback = (
		studentUid: number,
		responseId: number,
	): Observable<CredentialedResponseModel> => {
		return this.httpClient
			.get<any>(
				`/api/v1/students/${studentUid}/response_feedback/${responseId}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CredentialedResponseModel, r.student_response),
					{
						errorCode: 'BE40B244',
					},
				),
			);
	};

	public submitResponseFeedback = (
		studentUid: number,
		responseId: number,
		args: CredentialedFeedbackArgument,
	): Observable<CredentialedResponseModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${studentUid}/response_feedback/${responseId}`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CredentialedResponseModel, r.student_response),
					{
						errorCode: '500F2CD5',
					},
				),
			);
	};

	public sendStaffNotification = (
		responderUid: number,
		args: CredentialedStaffNotifyArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/tracker/credentialed_responder/${responderUid}/send_staff_notification`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'DE5988F9',
				}),
			);
	};
}
