import { CommonModule } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ContentChild,
	Input,
	forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'src/lib/utilities/noop';
import { ToggleButtonOffDirective } from './toggle-button-off.directive';
import { ToggleButtonOnDirective } from './toggle-button-on.directive';

@Component({
	selector: 'ae-toggle-button',
	templateUrl: './toggle-button.component.html',
	styleUrls: ['./toggle-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ToggleButtonComponent),
		},
	],
	standalone: true,
	imports: [CommonModule],
})
export class ToggleButtonComponent implements ControlValueAccessor {
	@Input() onText: string = 'On';
	@Input() offText: string = 'Off';
	@Input() display: 'inline' | 'block' = 'inline';

	@ContentChild(ToggleButtonOnDirective)
	public onTemplate: ToggleButtonOnDirective;
	@ContentChild(ToggleButtonOffDirective)
	public offTemplate: ToggleButtonOffDirective;

	public value: boolean;

	private _changeFunction: (value: boolean) => void = noop;
	private _touchedFunction: () => void = noop;

	constructor(private cdr: ChangeDetectorRef) {}

	// ControlValueAccessor
	public writeValue(value: boolean): void {
		this.value = value ?? false;
		this.cdr.detectChanges();
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	public toggleValue = () => {
		this.value = !this.value;

		this._touchedFunction();
		this._changeFunction(this.value);
	};
}
