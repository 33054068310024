import { Transform, Type } from 'class-transformer';
import {
	FieldDynamicModel,
	FieldDynamicModel_ClassTransform_TransformFactory,
} from '../../../../utilities/api/patterns/dynamic-fields/field-dynamic.model';
import {
	FieldModel,
	FieldModel_ClassTransform_Type,
} from '../../../../utilities/api/patterns/dynamic-fields/field.model';
import {
	OrganizationStudentEditTypedFieldsModel,
	OrganizationStudentEditTypedFieldsModel_ClassTransform_Type,
} from './organization-student-edit-typed-fields.model';

export class OrganizationStudentEditFieldsModel {
	@Type(OrganizationStudentEditTypedFieldsModel_ClassTransform_Type)
	fields: OrganizationStudentEditTypedFieldsModel;

	@Transform(
		FieldDynamicModel_ClassTransform_TransformFactory(
			FieldModel_ClassTransform_Type,
		),
	)
	files?: FieldDynamicModel<FieldModel>[];
}

export function OrganizationStudentEditFieldsModel_ClassTransform_Type() {
	return OrganizationStudentEditFieldsModel;
}
