<div class="d-flex align-items-center volume-slider">
	<div>
		<i aria-hidden="true" class="fas fa-fw" [ngClass]="getVolumeIcon()"></i>
	</div>
	<input
		class="volume-slider-control"
		max="100"
		min="0"
		step="1"
		type="range"
		[formControl]="form.controls.volume"
		id="{{ id }}"
	/>
	<div>
		{{ form.controls.volume.value }}
	</div>
</div>
