import { Transform, Type } from 'class-transformer';
import { recordTransformerFactory } from 'src/lib/utilities/api/class-transformer-types';

export class StudentPathClassSectionModel {
	class_name: string;
	section: string;
	required: boolean;
	current: boolean;
	scheduled: boolean;
	transferred: boolean;
	grade: string;
	credits: number;
	credits_converted: number;
	registration_id: null | number;
	term: string;
}

export function StudentPathClassSectionModel_ClassTransform_Type() {
	return StudentPathClassSectionModel;
}

export class StudentPathRegistrationModel {
	class_name: string;
	course_name: string;
	credits: number;
	current: boolean;
	grade: string;
	registration_id: number;
	required: boolean;
	scheduled: boolean;
	section: number;
	term: string;
	transferred: boolean;
}

export function StudentPathRegistrationModel_ClassTransform_Type() {
	return StudentPathRegistrationModel;
}

export class StudentPathClassModel {
	class_name: string;
	required: number;
	required_converted: number;
	completed: number;
	completed_converted: number;
	course_code: string;
	@Type(StudentPathClassSectionModel_ClassTransform_Type)
	sections: StudentPathClassSectionModel[];
}

export function StudentPathClassModel_ClassTransform_Type() {
	return StudentPathClassModel;
}

export class StudentPathSubjectModel {
	subject: string;
	subject_code: string;
	complete: number;
	remain: number | null;
	required: number | null;

	completed_converted: number | null;
	remain_converted: number | null;
	required_converted: number | null;

	@Transform(
		recordTransformerFactory(StudentPathClassModel_ClassTransform_Type),
	)
	classes: Record<string, StudentPathClassModel>;
	@Type(StudentPathRegistrationModel_ClassTransform_Type)
	registrations: StudentPathRegistrationModel[];
}

export function StudentPathSubjectModel_ClassTransform_Type() {
	return StudentPathSubjectModel;
}

export class StudentPathPersistentCourseModel {
	registration_id: number;
	name: string;
	complete: boolean;
	grade: string;
	credits: number;
}

export function StudentPathPersistentCourseModel_ClassTransform_Type() {
	return StudentPathPersistentCourseModel;
}

export class StudentPathModel {
	graduation_path: string | null;
	credits_complete: number;
	credits_remain: number | null;
	credits_required: number | null;

	credits_complete_converted: number;
	credits_remain_converted: number;
	credits_required_converted: number;

	gpa: number;
	@Transform(
		recordTransformerFactory(StudentPathSubjectModel_ClassTransform_Type),
	)
	subject_areas: Record<string, StudentPathSubjectModel>;
	@Type(StudentPathPersistentCourseModel_ClassTransform_Type)
	persistent_courses: StudentPathPersistentCourseModel[];
}

export function StudentPathModel_ClassTransform_Type() {
	return StudentPathModel;
}
