import {
	ComponentRef,
	Directive,
	Input,
	TemplateRef,
	ViewContainerRef,
	ViewRef,
} from '@angular/core';
import { SpinWhileComponent } from './spin-while.component';

// Context
export class SpinWhileDirectiveContext {
	public $implicit: any = null;
	public aeSpinWhile: any = null;
}

// Directive
@Directive({
	selector: '[aeSpinWhile]',
	standalone: true,
})
export class SpinWhileDirective {
	private _spinClass = 'fa-3x';
	private _alignmentClass = 'text-center my-4';
	private _spinMessage = '';

	private _context: SpinWhileDirectiveContext = new SpinWhileDirectiveContext();
	private _spinWhileComponent: ComponentRef<SpinWhileComponent>;
	private _viewRef: ViewRef;

	@Input() public set aeSpinWhile(condition: any) {
		this._context.$implicit = this._context.aeSpinWhile = condition;
		this.refreshView();
	}

	@Input() public set aeSpinWhileSpinClass(spinClass: string) {
		this._spinClass = spinClass;
		if (this._spinWhileComponent) {
			this._spinWhileComponent.instance.spinClass = this._spinClass;
		}
	}

	@Input() public set aeSpinWhileAlignmentClass(alignmentClass: string) {
		this._alignmentClass = alignmentClass;
		if (this._spinWhileComponent) {
			this._spinWhileComponent.instance.alignmentClass = this._alignmentClass;
		}
	}

	@Input() public set aeSpinWhileSpinMessage(spinMessage: string) {
		this._spinMessage = spinMessage;
		if (this._spinWhileComponent) {
			this._spinWhileComponent.instance.spinMessage = this._spinMessage;
		}
	}

	@Input() public set aeSpinWhileInline(inline: boolean) {
		if (inline) {
			this.aeSpinWhileSpinClass = 'fa-1x';
			this.aeSpinWhileAlignmentClass = '';
		}
	}

	constructor(
		private _viewContainer: ViewContainerRef,
		private _templateRef: TemplateRef<any>,
	) {}

	private refreshView = () => {
		if (this._context.$implicit) {
			if (this._spinWhileComponent == null) {
				this._viewContainer.clear();

				this._viewRef = null;
				this._spinWhileComponent =
					this._viewContainer.createComponent(SpinWhileComponent);

				if (this._spinClass != null) {
					this._spinWhileComponent.instance.spinClass = this._spinClass;
				}
				if (this._alignmentClass != null) {
					this._spinWhileComponent.instance.alignmentClass =
						this._alignmentClass;
				}
				if (this._spinMessage != null) {
					this._spinWhileComponent.instance.spinMessage = this._spinMessage;
				}
			}
		} else {
			if (this._viewRef == null) {
				this._viewContainer.clear();

				this._spinWhileComponent = null;
				this._viewRef = this._viewContainer.createEmbeddedView(
					this._templateRef,
					this._context,
				);
			}
		}
	};
}
