import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, timer } from 'rxjs';
import { map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { ModalMoverComponent } from '../../../../templates/global/modal-mover/modal-mover.component';

@Component({
	selector: 'ae-confirm-action-modal',
	templateUrl: './confirm-action-modal.component.html',
	styleUrls: ['./confirm-action-modal.component.scss'],
	standalone: true,
	imports: [ModalMoverComponent, NgClass, NgTemplateOutlet],
})
export class ConfirmActionModalComponent implements AfterViewInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public title: string = null;
	@Input() public subtitle: string = null;
	@Input() public message: string = null;
	@Input() public template: TemplateRef<any>;
	@Input() public yesText: string = 'Yes';
	@Input() public noText: string = 'No';
	@Input() public countDownDelay: number = 0;
	@Input() public mover: boolean = false;
	@Input() public cancelBtn: boolean = false;
	@Input() public confirmStyle: string = null;
	@Input() public rejectStyle: string = null;

	@ViewChild('yesButton', { static: true }) yesButton: ElementRef<HTMLElement>;

	constructor(
		private cdref: ChangeDetectorRef,
		public activeModal: NgbActiveModal,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		title?: string;
		subtitle?: string;
		message?: string;
		template?: TemplateRef<any>;
		yesText?: string;
		noText?: string;
		countDownDelay?: number;
		mover?: boolean;
		cancelBtn?: boolean;
		confirmStyle?: string;
		rejectStyle?: string;
	}): void => {
		// SET DATA
		this.title = data.title;
		this.subtitle = data.subtitle;
		this.message = data.message;
		this.template = data.template;
		this.yesText = data.yesText || this.yesText;
		this.noText = data.noText || this.noText;
		this.countDownDelay = data.countDownDelay || this.countDownDelay;
		this.mover = data.mover;
		this.cancelBtn = data.cancelBtn;
		this.confirmStyle = data.confirmStyle;
		this.rejectStyle = data.rejectStyle;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		if (this.countDownDelay > 0) {
			timer(1000, 1000)
				.pipe(
					map(() => this.countDownDelay - 1),
					tap((x) => (this.countDownDelay = x)),
					takeWhile((x) => x > 0),
					takeUntil(this._unsubscribe$),
				)
				.subscribe();
		}
	};

	public close = (confirmed: boolean) => {
		if (confirmed) this.activeModal.close();
		else this.activeModal.dismiss();
	};

	protected getConfirmClass = () => {
		return `${this.countDownDelay > 0 ? 'show-countdown' : ''} ${
			this.confirmStyle ? this.confirmStyle : 'btn-primary'
		}`;
	};

	ngAfterViewInit() {
		this.yesButton.nativeElement.focus();
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
