<div
	#flipCard
	class="flip-card"
	[ngClass]="{
		'enabled-flip': !deactivateFlip,
	}"
	(mouseenter)="flip('back')"
	(mouseleave)="flip('front')"
>
	<div class="flip-card-inner text-center">
		<div
			class="flip-card-front ng-flex-fill"
			[attr.inert]="flipped ? true : null"
		>
			@if (frontTemplate) {
				<ng-container [ngTemplateOutlet]="frontTemplate" />
			}
			<div class="screen-reader-text focusable">
				@if (!deactivateFlip) {
					<button
						class="btn btn-primary shadow btn-flip-bottom"
						type="button"
						(click)="flip('back')"
					>
						Flip To Card Back
					</button>
				}
			</div>
		</div>

		@if (!deactivateFlip) {
			<div
				class="flip-card-back ng-flex-fill"
				[attr.inert]="flipped ? null : true"
			>
				<div class="screen-reader-text focusable">
					<button
						class="btn btn-primary shadow btn-flip-top"
						type="button"
						(click)="flip('front')"
					>
						Flip To Card Front
					</button>
				</div>
				<div>
					@if (backTemplate) {
						<ng-container [ngTemplateOutlet]="backTemplate" />
					}
				</div>
			</div>
		}
	</div>
</div>
