<!--  -->
<!-- Notifications -->
<!--  -->
<section class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Notifications</h2>
		<div class="billboard-actions btn-toolbar">
			@if (canEdit) {
				<button
					class="btn btn-default"
					id="profile.subscriptions.notifications.edit.enable"
					type="button"
					[disabled]="loading || saving"
					(click)="enableEditNotification()"
				>
					{{ editingNotifications ? 'Cancel' : 'Edit' }}
				</button>
			}
			@if (editingNotifications) {
				<button
					class="btn btn-outline-primary"
					id="profile.subscriptions.notifications.reset"
					type="button"
					[disabled]="loading || saving"
					(click)="resetAllNotifications()"
				>
					Reset to Default
				</button>
				<button
					class="btn btn-primary"
					id="profile.subscriptions.notifications.save"
					type="button"
					[disabled]="loading || saving"
					(click)="save('notification')"
				>
					<ae-wait-spinner [saving]="saving"> Save Changes </ae-wait-spinner>
				</button>
			}
		</div>
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading">
			@if (notifications().length > 0) {
				<div>
					@if (editingNotifications) {
						@if (requiredNotifications().length > 0) {
							<div>
								<ng-container
									*ngTemplateOutlet="
										notificationEditContainer;
										context: {
											$implicit: requiredNotifications(),
											type: 'Required',
										}
									"
								/>
							</div>
						}
						@if (notRequiredNotifications().length > 0) {
							<div>
								<ng-container
									*ngTemplateOutlet="
										notificationEditContainer;
										context: {
											$implicit: notRequiredNotifications(),
											type: 'Not Required',
										}
									"
								/>
							</div>
						}
					} @else {
						@if (requiredNotifications().length > 0) {
							<div>
								<ng-container
									*ngTemplateOutlet="
										notificationViewContainer;
										context: {
											$implicit: requiredNotifications(),
											type: 'Required',
										}
									"
								/>
							</div>
						}
						@if (viewNotRequiredNotifications().length > 0) {
							<div>
								<ng-container
									*ngTemplateOutlet="
										notificationViewContainer;
										context: {
											$implicit: viewNotRequiredNotifications(),
											type: 'Not Required',
										}
									"
								/>
							</div>
						}
					}
				</div>
			} @else {
				<div class="text-center text-muted">No notifications available</div>
			}
		</div>
	</div>
</section>

<ng-template #notificationTitleInfo let-notification>
	{{ notification.instance_title }}
	@if (notification.description) {
		<i
			aria-hidden="true"
			class="fa-fw far fa-info-circle"
			[ngbTooltip]="notification.description"
		></i>
	}
</ng-template>

<ng-template #notificationViewContainer let-items let-type="type">
	<div>
		<div class="row pb-2">
			<div class="fw-bold col-lg-9">{{ type }}</div>
		</div>
		@for (notification of items; track notification) {
			<div class="pb-2">
				<ng-container
					*ngTemplateOutlet="
						notificationTitleInfo;
						context: { $implicit: notification }
					"
				/>
			</div>
		}
	</div>
</ng-template>

<ng-template #notificationEditContainer let-items let-type="type">
	<div class="row pb-2">
		<div class="fw-bold col-lg-9">{{ type }}</div>
	</div>
	@for (notification of items; track notification) {
		<div class="pb-2 d-flex">
			<button
				class="btn btn-icon"
				id="profile.subscriptions.notifications.reset.button"
				title="Reset notification to default"
				type="button"
				[ngClass]="
					getNotificationGroup(notification.notification_id).controls
						.hasPreference.value &&
					!getNotificationGroup(notification.notification_id).controls
						.hasPreference.disabled
						? ''
						: 'visibility-hidden'
				"
				(click)="resetNotification(notification)"
			>
				<i class="fa-solid fa-rotate-left"></i>
			</button>
			<ae-input-checkbox
				[formControl]="
					getNotificationGroup(notification.notification_id).controls.preference
				"
				id="profile.subscriptions.notifications.{{
					notification.notification_id
				}}"
			>
				<ng-container
					*ngTemplateOutlet="
						notificationTitleInfo;
						context: { $implicit: notification }
					"
				/>
			</ae-input-checkbox>
		</div>
	}
</ng-template>

<!--  -->
<!-- Reports -->
<!--  -->
<section class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Reports</h2>
		<div class="billboard-actions btn-toolbar">
			@if (canEdit) {
				<button
					class="btn btn-default"
					id="profile.subscriptions.reports.edit.enable"
					type="button"
					[disabled]="loading || saving"
					(click)="enableEditReports()"
				>
					{{ editingReports ? 'Cancel' : 'Edit' }}
				</button>
			}
			@if (editingReports) {
				<button
					class="btn btn-primary"
					id="profile.subscriptions.reports.save"
					type="button"
					[disabled]="loading || saving"
					(click)="save('report')"
				>
					<ae-wait-spinner [saving]="saving"> Save Changes </ae-wait-spinner>
				</button>
			}
		</div>
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading">
			@if (reports().length > 0) {
				@if (editingReports) {
					@for (group of groupedEditReports(); track group) {
						<div class="pb-2">
							@for (report of group; track report; let first = $first) {
								@if (first) {
									<div class="fw-bold">
										{{ report.report_title }}
									</div>
								}

								<span class="me-3">
									<ae-input-checkbox
										[formControl]="
											getReportGroup(report.schedule_id).controls.subscribed
										"
										id="profile.subscriptions.reports.{{ report.schedule_id }}"
									>
										<ng-container
											*ngTemplateOutlet="
												reportTitleInfo;
												context: { $implicit: report }
											"
										/>
									</ae-input-checkbox>
								</span>
							}
						</div>
					} @empty {
						<div class="text-center text-muted">No reports available</div>
					}
				} @else {
					@for (group of groupedViewReports(); track group) {
						<div class="pb-2">
							@for (
								report of group;
								track report;
								let first = $first;
								let last = $last
							) {
								@if (first) {
									<div class="fw-bold">
										{{ report.report_title }}
									</div>
								}

								<span>
									<ng-container
										*ngTemplateOutlet="
											reportTitleInfo;
											context: { $implicit: report }
										"
									/>
									<!-- prettier-ignore -->
									@if (!last) {, }
								</span>
							}
						</div>
					} @empty {
						<div class="text-center text-muted">
							No reports are subscribed to
						</div>
					}
				}
			} @else {
				<div class="text-center text-muted">No reports available</div>
			}
		</div>
	</div>
</section>

<ng-template #reportTitleInfo let-report
	><span class="text-capitalize"
		>{{ report.frequency_period
		}}<i
			aria-hidden="true"
			class="fa-fw far fa-info-circle"
			[ngbTooltip]="report.frequency"
		></i></span
></ng-template>
