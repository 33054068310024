import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	JsonApiDataModel,
	JsonApiDataModel_ClassTransform_TransformFactory,
	JsonApiModel,
} from '../json-api.model';

export class PostSecondaryInstituteModel {
	@Expose({ name: 'drupal_internal__id' })
	id: number;
	langcode: string;
	status: boolean;
	name: string;

	@Transform(dateTransformer)
	created: Date;

	@Transform(dateTransformer)
	changed: Date;

	ope_id: number;
	ipeds_id: number;
}

export function PostSecondaryInstituteModel_ClassTransform_Type() {
	return PostSecondaryInstituteModel;
}

export class PostSecondaryInstituteJsonApiModel extends JsonApiModel<PostSecondaryInstituteModel> {
	@Transform(
		JsonApiDataModel_ClassTransform_TransformFactory(
			PostSecondaryInstituteModel_ClassTransform_Type,
		),
	)
	data?: JsonApiDataModel<PostSecondaryInstituteModel>[];
}
