import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BehaviorCache } from 'src/lib/utilities/cache';
import { firstBy } from 'thenby';
import { OrganizationModel } from '../../api/organizations/organization.model';
import { OrganizationsService } from '../../api/organizations/organizations.service';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsStoreService {
	private _organizationsCache = new BehaviorCache<
		[showDeactivated: boolean, includeStructural: boolean],
		OrganizationModel[]
	>(
		([showDeactivated, includeStructural]) =>
			this.organizationsService
				.getOrganizations(showDeactivated, includeStructural)
				.pipe(
					map((orgs) =>
						orgs.sort(
							firstBy<OrganizationModel, string>((o) => o.state)
								.thenBy<OrganizationModel, string>((o) => o.itype_shortcode)
								.thenBy((o) => o.title),
						),
					),
				),
		'OrganizationsStoreService OrganizationsCache',
	);

	constructor(private organizationsService: OrganizationsService) {}

	/**
	 * Organizations
	 */
	public organizations$ = (
		showDeactivated: boolean = false,
		includeStructural: boolean = false,
	): Observable<OrganizationModel[]> => {
		return this._organizationsCache.getCache([
			showDeactivated,
			includeStructural,
		]);
	};

	public refreshOrganizations = (
		showDeactivated: boolean = false,
		includeStructural: boolean = false,
	): Promise<boolean> => {
		return this._organizationsCache.fetchData(
			[showDeactivated, includeStructural],
			true,
		);
	};
}
