import { Type } from 'class-transformer';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class StateTestCreateModel {
	test_name: string;
	test_id: number;
	required_groups: string[];
	optional_groups: string[];
}

export class StateTestModel {
	test_id: number;
	test_name: string;
	required_groups_count: number;
	optional_groups_count: number;
	active: boolean;
}

export function StateTestModel_ClassTransform_Type() {
	return StateTestModel;
}

export class StateTestListModel {
	@Type(StateTestModel_ClassTransform_Type)
	state_tests: StateTestModel[];
	count: number;
	skip: number;
	take: number;
}

export class StateTestListResponseModel {
	count: number;
	@Type(StateTestModel_ClassTransform_Type)
	state_tests: StateTestModel[];
}

export interface StateTestMapperSearchArgument {
	test_name?: PagingSearchFieldArgument;
	required_groups_count?: PagingSearchFieldArgument;
	optional_groups_count?: PagingSearchFieldArgument;
	active?: { value: boolean };
	test_id?: PagingSearchFieldArgument;
}
