import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	EventCategory,
	StudentEventModel,
} from 'src/lib/services/api/students/events/student-event.model';

import { NgClass, TitleCasePipe } from '@angular/common';
import {
	NgbNav,
	NgbNavContent,
	NgbNavItem,
	NgbNavItemRole,
	NgbNavLink,
	NgbNavLinkBase,
	NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { IfEmptyPipe } from 'src/lib/pipes/if-empty.pipe';
import { StudentsService } from 'src/lib/services/api/students/students.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { StaffViewerComponent } from 'src/lib/templates/global/staff-viewer/staff-viewer.component';
import { UserTimeComponent } from 'src/lib/templates/global/user-time/user-time.component';
import { NgDompurifyPipe } from 'src/lib/thirdparty/ng-dompurify/ng-dompurify.pipe';
import { mergeStrings } from 'src/lib/utilities/array';
import { firstBy } from 'thenby';

@Component({
	selector: 'ae-event-view',
	templateUrl: './event-view.component.html',
	styleUrls: ['./event-view.component.scss'],
	standalone: true,
	imports: [
		NgbNav,
		NgbNavItem,
		NgbNavItemRole,
		NgbNavLink,
		NgbNavLinkBase,
		NgbNavContent,
		UserTimeComponent,
		StaffViewerComponent,
		NgClass,
		NgbNavOutlet,
		TitleCasePipe,
		NgDompurifyPipe,
		IfEmptyPipe,
	],
})
export class EventViewComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() currentEvent: StudentEventModel;
	@Input() studentId: number;
	public initiatedBy: { uid: number; name: string };
	public EventCategory = EventCategory;
	public canViewInternal: boolean = false;
	public errorLoading = false;
	public loading = true;

	protected relationship = '';

	protected currentEventPurposes: string[] = [];

	constructor(
		private studentsService: StudentsService,
		private permissionStore: PermissionStoreService,
	) {}

	ngOnInit() {
		const rel = this.currentEvent.relationship;
		if (rel?.toLocaleLowerCase().includes('guardian')) {
			this.relationship = 'Parent/Guardian';
		} else {
			this.relationship = rel ? rel.charAt(0).toUpperCase() + rel.slice(1) : '';
		}

		this.getStudentOverview();
		this.permissionStore
			.getFieldSet$({
				UserId: this.studentId,
			})
			.pipe(
				tap((x) => {
					this.canViewInternal = x.canDo(
						Permits['ga_user_log|view internal only logs'],
					);
				}),
			)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe();

		this.currentEvent.line_items.sort(firstBy((x) => x.date, -1));
	}

	public getStudentOverview = () => {
		this.errorLoading = false;
		this.loading = true;

		Object.keys(this.currentEvent?.purposes).forEach((val) => {
			this.currentEventPurposes.push(this.currentEvent.purposes[val]);
		});

		this.studentsService.getStudentOverview(this.studentId).subscribe({
			next: (overview) => {
				if (this.currentEvent.category === this.EventCategory.note) {
					if (this.currentEvent.initiated_by != null) {
						this.initiatedBy =
							this.currentEvent.initiated_by === 'creator'
								? {
										name: this.currentEvent.creator_name,
										uid: this.currentEvent.creator_uid,
									}
								: {
										name: `${overview.first_name} ${overview.last_name}`,
										uid: overview.uid,
									};
					} else this.initiatedBy = null;
				}
				this.loading = false;
			},
			error: (errors) => {
				console.error(mergeStrings(errors));
				this.errorLoading = true;
				this.loading = false;
			},
		});
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
