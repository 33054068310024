import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';
import { StudentContactAttributes } from '../../students/contacts/student-contact-options.model';

export interface UserUpdateRequestProfileArgument {
	profile_first_name?: string;
	profile_last_name?: string;
	profile_middle_name?: string;
	profile_maiden_name?: string;
	profile_suffix?: string;
	profile_preferred_name?: string;
	profile_student_gender?: string;
	profile_student_ethnicity?: string; // API still refers to race as ethnicity 14E31FFD
	profile_city?: string;
	profile_state?: string;
	profile_user_title?: string;
	profile_about?: string;
	profile_intro_video?: string;
	profile_employee_id?: string;
}

export class UserUpdateRequestContactPhoneArgument {
	value: string;
	type?: string;
	prim: boolean;
	active: boolean;
	weight: number;
	sms?: boolean;
}

export class UserUpdateRequestContactEmailArgument {
	value: string;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

export class UserUpdateRequestContactAddressArgument {
	value: UserAddressModel;
	type: string;
	prim: boolean;
	active: boolean;
	weight: number;
}

export interface UserUpdateRequestContactArgument {
	phone?: UserUpdateRequestContactPhoneArgument[];
	address?: UserUpdateRequestContactAddressArgument[];
	email?: UserUpdateRequestContactEmailArgument[];
	messenger?: UserUpdateRequestContactEmailArgument[];
}

export interface UserUpdateRequestStudentArgument {
	dob?: Date;
	program?: string;
}

export interface UserDataPersonalContactPhoneArgument {
	phone: number | string;
	is_primary: boolean;
	sms_blocked?: boolean;
	type?: string;
	sms?: boolean;
}

export interface UserUpdateRequestPersonalContactArgument {
	link_id?: number;
	name: string;
	relationship: string;
	phones?: UserDataPersonalContactPhoneArgument[];
	emails?: string[];
	attributes?: StudentContactAttributes[];
	url?: string;
	account_uid?: number;
	delete?: boolean;
}

export interface UserUpdateRequestUsersArgument {
	theme?: string;
	admin_type?: string[];
	title?: string;
}
export interface UserUpdateRequestContactsArgument {
	personal?: UserUpdateRequestPersonalContactArgument[];
}

export interface UserUpdateRequestArgument {
	profile?: UserUpdateRequestProfileArgument;
	contact_field?: UserUpdateRequestContactArgument;
	student_data?: UserUpdateRequestStudentArgument;
	contacts?: UserUpdateRequestContactsArgument;
	user?: UserUpdateRequestUsersArgument;
}

export class UpdateUserRequestArgumentResponse {
	success: boolean;
	update_request: any;
}

export interface UserApproveRequestArgument {
	fields_to_approve: {
		profile?: {
			profile_student_gender?: boolean;
			profile_first_name?: boolean;
			profile_middle_name?: boolean;
			profile_last_name?: boolean;
			profile_maiden_name?: boolean;
			profile_preferred_name?: boolean;
			profile_student_ethnicity?: boolean; // API still refers to race as ethnicity 14E31FFD
		};
		contact_field?: {
			phone?: boolean;
			address?: boolean;
		};
		student_data?: {
			dob?: boolean;
			cohort_year?: boolean;
			student_given_id?: boolean;
		};
		contacts?: {
			personal: number[];
		};
	};
}
