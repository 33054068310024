<div class="w-100 text-center">
	<div class="fw-bold mb-2">{{ program.institute_title }}</div>
	<div *aeSpinWhile="loading">
		@if (!errorLoading) {
			@for (item of ssoOptions | keyvalue; track $index) {
				<div class="mb-2">
					<gal-sso-button
						[data]="{
							program: program,
							ssoLink: item.value,
							type: item.key,
							destinationUrl: destinationUrl,
						}"
					/>
				</div>
			} @empty {
				<div class="text-muted">No school/district login options found</div>
				<div [ngTemplateOutlet]="backToLogin"></div>
			}
		} @else {
			<div class="text-danger">There was an issue loading</div>
			<div [ngTemplateOutlet]="backToLogin"></div>
		}
	</div>

	<div class="pt-4">
		<button
			class="btn btn-link"
			type="button"
			[disabled]="saving"
			(click)="goToSchoolSelect()"
		>
			Not your school?
		</button>
	</div>
</div>

<ng-template #backToLogin let-error>
	<button
		class="btn btn-link"
		type="button"
		[disabled]="saving"
		(click)="setState.emit(loginState.credential)"
	>
		Back to login
	</button>
</ng-template>
