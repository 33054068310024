<div class="editor" id="{{ editorID }}">
	<ngx-editor-menu
		[colorPresets]="['red', 'orange', 'yellow', 'green', 'blue', 'purple']"
		[customMenuRef]="sizeMenu"
		[disabled]="!editor.view.editable"
		[editor]="editor"
		[toolbar]="toolbar"
	/>
	<ngx-editor
		class="d-block overflow-auto"
		[editor]="editor"
		[formControl]="contentControl"
		[style.height]="height"
	/>
</div>

<ng-template #sizeMenu>
	<ae-ngx-editor-size-menu [editor]="editor" />
</ng-template>
