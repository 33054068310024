import { Type } from 'class-transformer';

export class CourseRequestOptionModel {
	key: string | number;
	title: string;
}

export function CourseRequestOptionModel_ClassTransform_Type() {
	return CourseRequestOptionModel;
}

export class CourseRequestOptionsModel {
	@Type(CourseRequestOptionModel_ClassTransform_Type)
	reasons: CourseRequestOptionModel[];
	@Type(CourseRequestOptionModel_ClassTransform_Type)
	status: CourseRequestOptionModel[];
}
