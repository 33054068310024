<ng-container>
	@if (!isInline) {
		<div class="fw-bold">{{ label }}</div>
	} @else {
		<span class="fw-bold me-1">{{ label }}</span>
	}
	<ng-content />
	<!-- give non inline a little space at the bottom -->
	@if (!isInline) {
		<div class="mb-2"></div>
	}
</ng-container>
