@if (!errorLoading) {
	<ng-container *aeSpinWhile="loading">
		@if (!editEnabled) {
			<ae-profile-account-view
				[accountData]="accountData"
				[assignedStudents]="assignedStudents"
				[canEditAccount]="isAdminAccount"
				[canViewId]="canViewEmployeeID"
				[canViewLinkedAccounts]="canViewLinkedAccounts"
				[enableEdit]="enableEdit"
				[existingPendingChange]="existingPendingChange"
				[externalAccounts]="externalAccounts"
				[formattedAccessType]="formattedAccessType"
				[formattedAdminTypeList]="formattedAdminTypeList"
				[gaData]="gaData"
				[loading]="loading"
				[profileData]="profileData"
			/>
		}
		@if (editEnabled) {
			<ae-profile-account-edit
				[accountData]="accountData"
				[accountSaveListener]="accountSaveListener"
				[assignedStudents]="assignedStudents"
				[canEditAccount]="isAdminAccount"
				[canEditId]="canEditEmployeeID"
				[canEditLinkedAccounts]="canEditLinkedAccounts"
				[canEditRoles]="canEditRoles"
				[currentUserId]="currentUserId"
				[enableEdit]="enableEdit"
				[existingPendingChange]="existingPendingChange"
				[externalAccounts]="externalAccounts"
				[formattedAccessType]="formattedAccessType"
				[gaData]="gaData"
				[loading]="loading"
				[profileData]="profileData"
			/>
		}
	</ng-container>
} @else {
	<section class="billboard">
		<div class="billboard-header">
			<h2 class="billboard-title">Account</h2>
		</div>
		<div class="billboard-body">
			<div class="text-center text-danger my-3">
				There was an issue loading Account Info, if the problem persists please
				contact support
			</div>
			<div class="d-flex justify-content-center mb-2">
				<button
					class="btn btn-default px-3"
					id="profile.contact.error.retry"
					type="button"
					(click)="getAccountInfo()"
				>
					Retry
				</button>
			</div>
		</div>
	</section>
}
