<div class="input-group validate-include-children flex-nowrap overflow-hidden">
	<input
		#mainInput
		class="form-control squared-end"
		placeholder="mm/dd/yyyy - mm/dd/yyyy"
		type="search"
		[disabled]="disabled"
		id="{{ id }}"
		[ngModelOptions]="{ updateOn: 'blur', standalone: true }"
		[(ngModel)]="formattedModel"
		(keydown.enter)="mainInput.blur()"
		(ngModelChange)="onFormattedChange($event)"
	/>
	<button
		class="btn rounded-end calendar-btn"
		title="Open Calendar"
		type="button"
		[disabled]="disabled"
		[ngClass]="
			isPresentingInvalid() ? 'btn-outline-danger' : 'btn-outline-secondary'
		"
		(click)="datepicker.toggle()"
	>
		<i aria-hidden="true" class="far fa-calendar-alt"></i>
	</button>

	<input
		#datepicker="ngbDatepicker"
		class="form-control"
		container="body"
		name="datepicker"
		ngbDatepicker
		placement="bottom-right"
		type="hidden"
		[autoClose]="'outside'"
		[dayTemplate]="t"
		[displayMonths]="2"
		[firstDayOfWeek]="7"
		[maxDate]="getMaxDate()"
		[minDate]="getMinDate()"
		[positionTarget]="mainInput"
		[startDate]="getStartDate()"
		(dateSelect)="onDateSelection($event, datepicker)"
	/>
	<ng-template #t let-date let-focused="focused">
		<span
			class="custom-day"
			[class.faded]="isHovered(date) || isInside(date)"
			[class.focused]="focused"
			[class.range]="isRange(date)"
			[ngClass]="isDisabled(date) ? 'disabled-date' : ''"
			(mouseenter)="onHoverSelection(date)"
			(mouseleave)="hoveredDate = null"
		>
			{{ date.day }}
		</span>
	</ng-template>
</div>
