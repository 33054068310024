import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PermissionField } from '../constants/permissions';
import { PermissionStoreService } from '../services/stores/permission-store/permission-store.service';
import { InitializationService } from '../services/utility/initialization/initialization.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private permissionStore: PermissionStoreService,
		private initializationService: InitializationService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkPermission(route);
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkPermission(route);
	}

	private checkPermission = (
		route: ActivatedRouteSnapshot,
	): Observable<boolean | UrlTree> | boolean | UrlTree => {
		if (!this.initializationService.loggedIn) {
			return this.router.createUrlTree(['/login'], {
				queryParamsHandling: 'merge',
				queryParams: route.queryParams,
			});
		}

		// Check if permissions requested
		if (route.data?.permissionKeys != null) {
			let field: PermissionField = {};
			if (route.data.byPermissionField != null) {
				field = route.data.byPermissionField(route.params);
			}

			// Check the permissions
			return this.permissionStore.getFieldSet$(field).pipe(
				take(1), // Finish the stream for the promise to fire
				map((x) => {
					return (
						(route.data?.permissionAndType === true
							? route.data.permissionKeys.every((p) => x.canDo(p))
							: route.data.permissionKeys.some((p) => x.canDo(p))) ||
						this.router.createUrlTree(['/nopermission'])
					);
				}),
			);
		} else {
			return true;
		}
	};
}
