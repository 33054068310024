import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentAssignmentsModel {
	registration_id: string;
	assignment_id: string;
	assignment_title: string;
	class_title: string;

	@Transform(dateTransformer)
	submission_date: Date;
	scored_date: number;
	score: number;
}

export function StudentAssignmentsModel_ClassTransform_Type() {
	return StudentAssignmentsModel;
}

export interface StudentAssignmentsArgument {
	// Dates formatted YYYY-MM-DD
	start_date: string;
	end_date: string;
}
