export enum UserModalKeys {
	'welcome' = 'welcome',
	'agreement' = 'agreement',
	'daily_attendance' = 'daily_attendance',
	'paperwork_requirement' = 'paperwork_requirement',
	'address_requirement' = 'address_requirement',
	'meetup_lockout_requirement' = 'meetup_lockout_requirement',
	'star_assessment_requirement' = 'star_assessment_requirement',
	'pending_invoices_requirement' = 'pending_invoices_requirement',
	'bumpgate_requirement' = 'bumpgate_requirement',
	'ale_intervention' = 'ale_intervention',
	'password_gate' = 'password_gate',
	'taa_pre_class' = 'taa_pre_class',
	'enrollment_checklist_activated' = 'enrollment_checklist_activated',
	'student_state_test' = 'student_state_test',
	'attendance_compliance_modal' = 'attendance_compliance_modal',
	'count_period_registration_response' = 'count_period_registration_response',
	'drop_warning' = 'drop_warning',
	'barrier_data_survey' = 'barrier_data_survey',
	'activation_gate' = 'activation_gate',
}
