// https://gist.github.com/jed/982883
export function generateGuid(): string {
	return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
			16,
		),
	);
}

export function randomNumber(min: number, max: number): number {
	const arr = new Uint8Array(max);

	getRandomValues(arr);

	for (const key in arr) {
		if (arr.hasOwnProperty(key)) {
			const value = arr[key];

			if (value > min && value < max) {
				return value;
			}
		}
	}

	return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomValues(buf: Uint8Array): Uint8Array {
	if (crypto && crypto.getRandomValues) {
		return crypto.getRandomValues(buf);
	} else {
		return buf.slice().map(() => Math.floor(Math.random() * 100000));
	}
}
