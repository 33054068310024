export enum FieldTypeValue {
	tier = 'tier',
	string = 'string',
	email = 'email',
	address = 'address',
	phoneNumber = 'phone_number',
	date = 'date',
	option = 'option',
	file = 'file',
	select = 'select',
	multiselect = 'multiselect',
}
