import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	NotificationEventHistoryArgument,
	NotificationEventHistoryModel,
} from './models/notification-event-history.model';
import { NotificationOptionsModel } from './models/notification-options.model';
import {
	NotificationHistoryListArgument,
	NotificationSetHistoryListModel,
	NotificationSetHistoryModel,
} from './models/notification-set-history.model';
import { NotificationSetOptionsModel } from './models/notification-set-options.model';
import { NotificationSetUpsertArgument } from './models/notification-set-upsert.argument';
import { NotificationSetModel } from './models/notification-set.model';
import { NotificationSettingsArgument } from './models/notification-settings.argument';
import { NotificationSetSettingsModel } from './models/notification-settings.model';
import { NotificationUpsertArgument } from './models/notification-upsert.argument';

@Injectable({
	providedIn: 'root',
})
export class NotificationManagerService {
	constructor(private httpClient: HttpClient) {}

	/*********************/
	/* Notification Sets */
	/*********************/

	public getNotificationSet = (
		setId: number,
	): Observable<NotificationSetModel> => {
		return this.httpClient
			.get<any>(`/api/v1/communication/notification/sets/${setId}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetModel, r.notification_set),
					{
						errorCode: 'AE8CD8AC',
					},
				),
			);
	};

	public getNotificationSetList = (): Observable<NotificationSetModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/communication/notification/sets`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(NotificationSetModel, r.notification_sets as []),
					{
						errorCode: 'AE8CD8AC',
					},
				),
			);
	};

	public getNotificationSetOptions =
		(): Observable<NotificationSetOptionsModel> => {
			return this.httpClient
				.get<NotificationSetOptionsModel>(
					`/api/v1/communication/notification/sets/options`,
				)
				.pipe(
					mapResponse((r) => plainToInstance(NotificationSetOptionsModel, r), {
						errorCode: '3589778C',
					}),
				);
		};

	public createNotificationSet = (
		args: NotificationSetUpsertArgument,
	): Observable<NotificationSetModel> => {
		return this.httpClient
			.post<any>(`/api/v1/communication/notification/sets`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetModel, r.notification_set),
					{
						errorCode: '102F0AD8',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public editNotificationSet = (
		id: number,
		args: NotificationSetUpsertArgument,
	): Observable<NotificationSetModel> => {
		return this.httpClient
			.post<any>(`/api/v1/communication/notification/sets/${id}`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetModel, r.notification_set),
					{
						errorCode: '6184AE1F',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public deleteNotificationSet = (id: number): Observable<any> => {
		return this.httpClient
			.delete<any>(`/api/v1/communication/notification/sets/${id}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '2B66B894',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getSetHistory = (
		id: number,
	): Observable<NotificationSetHistoryModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/communication/notification/sets/${id}/history`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(NotificationSetHistoryModel, r.history as any[]),
					{
						errorCode: '644FA6D7',
					},
				),
			);
	};

	public getSetHistoryList = (
		args: PagingArgument<NotificationHistoryListArgument>,
	): Observable<NotificationSetHistoryListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/communication/notification/sets/history`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetHistoryListModel, r),
					{
						errorCode: '644FA6D7',
					},
				),
			);
	};

	/*********************/
	/*** Notifications ***/
	/*********************/
	public createNotification = (
		setId: number,
		args: NotificationUpsertArgument,
	): Observable<NotificationSetModel> => {
		return this.httpClient
			.post<any>(
				`api/v1/communication/notification/sets/${setId}/notification`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetModel, r.notification_set),
					{
						errorCode: 'EBF103B2',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public editNotification = (
		setId: number,
		notificationId: number,
		args: NotificationUpsertArgument,
	): Observable<NotificationSetModel> => {
		return this.httpClient
			.post<any>(
				`api/v1/communication/notification/sets/${setId}/notification/${notificationId}`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetModel, r.notification_set),
					{
						errorCode: 'A1BC5A72',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public deleteNotification = (
		setId: number,
		notificationId: number,
		reason: string,
	): Observable<any> => {
		return this.httpClient
			.delete<any>(
				`api/v1/communication/notification/sets/${setId}/notification/${notificationId}`,
				{ body: { reason_for_change: reason } },
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '454A3F9D',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getNotificationOptions = (
		triggerId: string,
	): Observable<NotificationOptionsModel> => {
		return this.httpClient
			.get<NotificationOptionsModel>(
				`/api/v1/communication/notification/options?trigger_id=${triggerId}`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(NotificationOptionsModel, r), {
					errorCode: 'F253C810',
				}),
			);
	};

	public getNotificationCategoryOptions = (
		category: string,
	): Observable<NotificationOptionsModel> => {
		return this.httpClient
			.get<NotificationOptionsModel>(
				`/api/v1/communication/notification/options?category=${category}`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(NotificationOptionsModel, r), {
					errorCode: '0BCF0DEC',
				}),
			);
	};

	public getNotificationEventHistory = (
		args: PagingArgument<NotificationEventHistoryArgument>,
	): Observable<NotificationEventHistoryModel> => {
		return this.httpClient
			.post<NotificationEventHistoryModel>(
				`/api/v1/communication/notification/sets/event-history`,
				args,
			)
			.pipe(
				mapResponse((r) => plainToInstance(NotificationEventHistoryModel, r), {
					errorCode: '3E02933F',
				}),
			);
	};

	/*****************************/
	/*** Notification Settings ***/
	/*****************************/
	public getNotificationSettings = (): Observable<
		NotificationSetSettingsModel[]
	> => {
		return this.httpClient
			.get<any>(`/api/v1/communication/notification_settings`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(NotificationSetSettingsModel, r.instances as any[]),
					{
						errorCode: 'C51E0955',
					},
				),
			);
	};

	public updateNotificationSettings = (
		instanceId: number,
		args: NotificationSettingsArgument,
	): Observable<NotificationSetSettingsModel> => {
		return this.httpClient
			.post<any>(
				`api/v1/communication/notification_settings/${instanceId}`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(NotificationSetSettingsModel, r.instance),
					{
						errorCode: '8BF67599',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};
}
