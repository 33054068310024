import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class EnrollmentReportUrlModel {
	url: string;
	label: string;
}

export function EnrollmentReportUrlModel_ClassTransform_Type() {
	return EnrollmentReportUrlModel;
}

export class EnrollmentReportStarModel {
	@Transform(dateTransformer)
	math: Date;
	@Transform(dateTransformer)
	reading: Date;
}

export function EnrollmentReportStarModel_ClassTransform_Type() {
	return EnrollmentReportStarModel;
}

export class EnrollmentReportModel {
	first: string;
	last: string;
	state_id: string;
	district_id: string;
	@Transform(dateTransformer)
	dob: Date;
	@Transform(dateTransformer)
	enrollment_date: Date;
	grade: number;
	cohort: number;
	fte: number;
	guardian_name: string;
	@Type(EnrollmentReportStarModel_ClassTransform_Type)
	star: EnrollmentReportStarModel;
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	learning_plan: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	weekly_contact_twoway: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	weekly_contact_twoway_certified: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	weekly_contact_attempts: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	progress_evaluation: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	attendance: EnrollmentReportUrlModel[];
	meetups: string;
	meetups_virtual_reason: string;
	milestone: string;
	satisfactory_progress: string;
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	cdc: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	enrollment_forms: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	student_contract: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	intervention_plan: EnrollmentReportUrlModel[];
	last_satisfactory_month: string;
	unsatisfactory_progress_list: string;
	@Transform(dateTransformer)
	withdraw_date: Date;
	withdraw_reason: string;
	completed_course_titles: string;
	completed_credits_earned: number;
	completed_credits_earned_school_year: number;
	counted_course_titles: string;
	counted_course_both: string;
	enroll_reasons: string;
	dropout_code: string;
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	course_activity_partial_list: EnrollmentReportUrlModel[];
	@Type(EnrollmentReportUrlModel_ClassTransform_Type)
	gabby_reminders_3: EnrollmentReportUrlModel[];
}

export function EnrollmentReportModel_ClassTransform_Type() {
	return EnrollmentReportModel;
}

export class EnrollmentReportColumnModel {
	column_id: string;
	column_title: string;
	column_description: string;
}

export function EnrollmentReportColumnModel_ClassTransform_Type() {
	return EnrollmentReportColumnModel;
}

export class EnrollmentReportCategoryModel {
	@Type(EnrollmentReportColumnModel_ClassTransform_Type)
	columns: EnrollmentReportColumnModel[];
	@Type(EnrollmentReportModel_ClassTransform_Type)
	students: EnrollmentReportModel[];
}

export function EnrollmentReportCategoryModel_ClassTransform_Type() {
	return EnrollmentReportCategoryModel;
}

export class EnrollmentReportRulesModel {
	title: string;
	body: string;
}

export function EnrollmentReportRulesModel_ClassTransform_Type() {
	return EnrollmentReportRulesModel;
}

export class EnrollmentReportOverviewItemModel {
	grade: number;
	headcount: number;
	credits_earned: number;
}
export function EnrollmentReportOverviewItemModel_ClassTransform_Type() {
	return EnrollmentReportOverviewItemModel;
}

export class EnrollmentReportResponseModel {
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	end_date: Date;
	report_name: string;
	active_days_in_period: number;
	@Type(EnrollmentReportCategoryModel_ClassTransform_Type)
	compliant: EnrollmentReportCategoryModel;
	@Type(EnrollmentReportCategoryModel_ClassTransform_Type)
	not_compliant: EnrollmentReportCategoryModel;
	@Type(EnrollmentReportCategoryModel_ClassTransform_Type)
	withdrawn: EnrollmentReportCategoryModel;
	@Type(EnrollmentReportRulesModel_ClassTransform_Type)
	rules: EnrollmentReportRulesModel[];
	@Type(EnrollmentReportOverviewItemModel_ClassTransform_Type)
	overview: Record<
		string | 'enabled',
		EnrollmentReportOverviewItemModel | string[]
	>;
	funding_enabled: boolean;
	all_labels: EnrollmentReportLabelOptionModel;
}

export class EnrollmentReportOptionModel {
	report_id: number;
	report_month: string;
}

export class EnrollmentReportLabelOptionModel {
	[key: string]: string;
}
