<div class="modal-header">
	<h1 class="modal-title">Available Meetups</h1>
	<button
		class="btn btn-outline-dark"
		id="sap.meetup.available.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.close(hasChanges)"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body p-0">
	<div *aeSpinWhile="loading">
		<div
			class="d-flex flex-column flex-lg-row schedule-container position-relative"
		>
			@if (!availableMeetups) {
				<div class="no-meetup-available-message text-muted">
					No Meetups Available
				</div>
			}
			@for (day of data; track day; let i = $index) {
				<div
					class="flex-grow-1 d-flex flex-column w-100 schedule-day"
					[ngClass]="{ weekend: isWeekend(day[0]) }"
				>
					<div class="fw-bold text-center day-header text-nowrap">
						{{ day[0] | spaceFormat: '{day-short} {iso-month}/{date}' }}
					</div>
					<div class="p-2 overflow-auto">
						@for (meetup of day[1]; track meetup) {
							<button
								class="btn btn-block text-start btn-outline-shadow-light"
								type="button"
								(click)="markSelected(meetup.id)"
							>
								<div class="button-innards">
									<div class="fs-sm">
										<div class="d-flex flex-nowrap">
											<div class="fw-bold flex-grow-1">
												{{ meetupTitles[meetup.gathering_type] }}
											</div>
											@if (meetup.rsvp) {
												<div class="ps-1">
													<i
														aria-hidden="true"
														class="fa-solid fa-thumbs-up text-info"
													></i>
												</div>
											}
										</div>
										<div class="fw-normal">
											<div class="my-1">
												<ae-user-time
													format="{time}"
													sourceTz="program"
													[date]="meetup.start_date"
													[userId]="studentId"
												/>
												<span class="text-nowrap">
													-
													<ae-user-time
														format="{time}"
														sourceTz="program"
														[date]="meetup.end_date"
														[userId]="studentId"
												/></span>
											</div>
											<div>With {{ meetup.host.name }}</div>
											<div>
												{{
													meetup.type === 'virtual' ? 'Virtual' : 'In Person'
												}}
											</div>
											@if (meetup.id === selectedMeetupId) {
												<div class="border-bottom px-1 my-1"></div>
												@if (meetup.rsvp) {
													<div class="mb-1">
														Student has a current RSVP to this meetup
													</div>
												}
												<div class="mb-1">
													@if (meetup.type === 'virtual') {
														<a
															target="_blank"
															[href]="normalizeHref(meetup.link)"
														>
															{{ meetup.link }}
														</a>
													} @else {
														<ae-address-display [address]="meetup.address" />
													}
													<ng-template #address>
														<ae-address-display [address]="meetup.address" />
													</ng-template>
												</div>
												@if (hasValue(meetup.description)) {
													<div class="mb-1">
														{{ meetup.description }}
													</div>
												}
											}
										</div>
									</div>
								</div>
							</button>
						}
					</div>
				</div>
			}
		</div>
	</div>
</div>
