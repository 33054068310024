import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { GroupValidationDisplayComponent } from 'src/lib/templates/layout/group-validation/group-validation-display.component';
import { GroupValidationDirective } from 'src/lib/templates/layout/group-validation/group-validation.directive';
import { ValidationErrorDirective } from 'src/lib/templates/layout/group-validation/validation-error.directive';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { hasValue } from 'src/lib/utilities/compare';

export interface PaginationTableSearchSaveEdit {
	newName: string;
	selectedSave: string;
}

@Component({
	selector: 'ae-legacy-dashboard-save-search-modal',
	templateUrl: './legacy-dashboard-save-search-modal.component.html',
	styleUrls: ['./legacy-dashboard-save-search-modal.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		GroupValidationDirective,
		GroupValidationDisplayComponent,
		ValidationErrorDirective,
		NgSelectModule,
	],
})
export class LegacyDashboardSaveSearchModalComponent {
	@Input() searches: any[];
	public currentSearches: string[];
	public editForm: FormGroup<FormControlWrapper<PaginationTableSearchSaveEdit>>;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private fb: FormBuilder,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { searches: any[] }): void => {
		// SET DATA
		this.searches = data.searches;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		this.currentSearches = this.searches.map((x) => x.name);

		this.editForm = this.fb.group<
			FormControlWrapper<PaginationTableSearchSaveEdit>
		>(
			{
				newName: new FormControl(null, (control: FormControl<string>) => {
					if (control.value == null) {
						return null;
					} else if (
						this.currentSearches.find(
							(x) =>
								x.toLowerCase().trim() === control.value.toLowerCase().trim(),
						)
					) {
						return { duplicate: true };
					} else return null;
				}),
				selectedSave: new FormControl(null),
			},
			{
				validators: [
					(
						group: FormGroup<FormControlWrapper<PaginationTableSearchSaveEdit>>,
					) => {
						if (
							!hasValue(group.controls.newName.value) &&
							!hasValue(group.controls.selectedSave.value)
						) {
							return {
								required: 'Either a new name or a current name is required',
							};
						}

						return null;
					},
				],
			},
		);
	};

	public dismiss = () => {
		this.activeModal.dismiss();
	};

	public save = () => {
		const value = this.editForm.value;
		const name = (value.selectedSave || value.newName).trim();
		this.activeModal.close(name);
	};
}
