import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	concat,
	firstValueFrom,
	Observable,
	of,
	Subject,
} from 'rxjs';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { ChannelModel } from 'src/lib/services/api/gabby/channels/channel.model';
import { ChannelsService } from 'src/lib/services/api/gabby/channels/channels.service';
import { MessagePlatform } from 'src/lib/services/api/gabby/messages/message-platform.enum';
import { GabbyUsersService } from 'src/lib/services/api/gabby/users/gabby-users.service';
import { MessageEventService } from 'src/lib/services/wamp/gabby/message-event.service';
import { GabbyWampEventFactoryService } from 'src/lib/services/wamp/wamp-event-factory/gabby/gabby-wamp-event-factory.service';
import { GabbyWampPublishFactoryService } from 'src/lib/services/wamp/wamp-publish-factory/gabby/gabby-wamp-publish-factory.service';
import { BehaviorCache, subscribeAndPromise } from 'src/lib/utilities/cache';
import { isArray, isNumber, isString } from 'src/lib/utilities/compare';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';
import { UserSetting } from '../../users/settings/user-setting.enum';
import { UserSettingsStoreService } from '../../users/settings/user-settings-store.service';
import { UserStoreService } from '../../users/user/user-store.service';
import { ChannelKeyset } from './channel-keyset';
import { ChannelStorageSet } from './channel-storage-set';

@Injectable({
	providedIn: 'root',
})
export class ChannelStoreService {
	private _flaggedChannels = new BehaviorSubject<(string | number)[]>(
		undefined,
	);
	private _channelRemovedEvent = new Subject<ChannelKeyset>();
	private _allChannelSet = new Map<string | number, ChannelModel>();
	private _directoryStorageSets = new Map<string, ChannelStorageSet>();
	private _userChannelCache: BehaviorCache<number, ChannelModel[]>;

	constructor(
		private channelService: ChannelsService,
		private messageEventService: MessageEventService,
		private userStore: UserStoreService,
		private gabbyUserService: GabbyUsersService,
		private wefs: GabbyWampEventFactoryService,
		private wpfs: GabbyWampPublishFactoryService,
		private settingsStore: UserSettingsStoreService,
	) {
		this.init();

		this._userChannelCache = new BehaviorCache<number, ChannelModel[]>(
			this.fetchUserChannels$,
			'UserChannelCache',
			() => [],
			300,
		);
	}

	private init = () => {
		// Add new channels
		this.wefs.newChannel$().subscribe((channelEvent) => {
			SessionLogger.log('ChannelStoreService', 'newChannel', channelEvent);
			this.patchChannel$(channelEvent.channel).subscribe();
		});

		// Add channel participants
		this.wefs.channelParticipantAdded$().subscribe((participantEvent) => {
			SessionLogger.log(
				'ChannelStoreService',
				'channelParticipantAdded',
				participantEvent,
			);

			this.channelService
				.getChannel(participantEvent.directoryId, participantEvent.channelId)
				.pipe(switchMap((c) => this.patchChannel$(c)))
				.subscribe();
		});

		// Remove channel participants and remove channels
		this.wefs.channelParticipantRemoved$().subscribe((participantEvent) => {
			SessionLogger.log(
				'ChannelStoreService',
				'channelParticipantRemoved',
				participantEvent,
			);

			if (
				participantEvent.userLinkIds.indexOf(
					this.userStore.currentUserLinkId,
				) === -1
			) {
				this.channelService
					.getChannel(participantEvent.directoryId, participantEvent.channelId)
					.pipe(switchMap((c) => this.patchChannel$(c)))
					.subscribe();
			} else {
				this.removeChannel$(
					participantEvent.directoryId,
					participantEvent.channelId,
				).subscribe();
			}
		});

		// Remove channels
		this.wefs.channelRemoved$().subscribe((channelEvent) => {
			SessionLogger.log('ChannelStoreService', 'channelRemoved', channelEvent);
			this.removeChannel$(
				channelEvent.directoryId,
				channelEvent.channelId,
			).subscribe();
		});

		// Update channel message data
		this.wefs.newMessage$().subscribe((m) => {
			const set = this.getStorageSet(m.message.directory);

			set.channels.pipe(take(1)).subscribe((channels) => {
				const channel = channels?.find((c) => c.id === m.channelId);

				if (channel != null) {
					channel.last_message_created = m.message.created;
					set.pump();
				}
			});
		});

		// Update channel flag data
		this.wefs.channelFlagsChanged$().subscribe(() => {
			this.fetchFlaggedChannels();
		});

		// Initialize channel flags
		this.fetchFlaggedChannels();
	};

	/*
	 *
	 * Channels
	 *
	 */
	public channels$ = (directoryId: string): Observable<ChannelModel[]> => {
		this.fetchChannels$(directoryId).subscribe();

		return this.getStorageSet(directoryId)
			.channels.asObservable()
			.pipe(filter((x) => x !== undefined));
	};

	public channelsInitializing$ = (directoryId: string): Observable<boolean> => {
		return this.getStorageSet(directoryId).initialingChannels$;
	};

	public userChannels$ = (userLinkId: number): Observable<ChannelModel[]> => {
		return this._userChannelCache.getCache(userLinkId);
	};

	public channel$ = (
		channelId: string | number | [id: number, original_id: string],
		directoryId?: string,
	): Observable<ChannelModel> => {
		const set = this.getStorageSet(directoryId);

		return this.channels$(directoryId).pipe(
			map((x) => {
				let id: number = null;
				let original_id: string = null;

				// Setup the two check types
				if (isNumber(channelId)) {
					id = channelId;
				} else if (isString(channelId)) {
					original_id = channelId;
				} else if (isArray(channelId)) {
					id = channelId[0];
					original_id = channelId[1];
				}

				// Check by ID first
				if (id != null) {
					let foundChannel = x.find((c) => c.id === id);

					if (foundChannel == null && this._allChannelSet.has(id)) {
						foundChannel = this._allChannelSet.get(id);
					}

					if (foundChannel != null) return foundChannel;
				}

				// Check by original_id
				if (original_id != null) {
					let foundChannel = x.find((c) => c.original_id === original_id);

					if (foundChannel == null && this._allChannelSet.has(original_id)) {
						foundChannel = this._allChannelSet.get(original_id);
					}

					if (foundChannel != null) return foundChannel;
				}

				// Nothing found, bail
				return null;
			}),
			switchMap((x) => {
				return set.initialingChannels$.pipe(
					map<boolean, [ChannelModel, boolean]>((i) => [x, i]),
				);
			}),
			filter(([c, i]) => {
				return c != null || !i;
			}),
			map(([x]) => x),
		);
	};

	public channelRemoved$ = (): Observable<ChannelKeyset> => {
		return this._channelRemovedEvent.asObservable().pipe(delay(1));
	};

	public patchChannel$ = (
		channelModel: ChannelModel,
	): Observable<ChannelModel> => {
		if (channelModel == null) return of(null);

		this._allChannelSet.set(channelModel.id, channelModel);
		const set = this.getStorageSet(channelModel.directory);

		return new Observable<ChannelModel>((o) => {
			combineLatest([
				this.channel$(
					[channelModel.id, channelModel.original_id],
					channelModel.directory,
				),
				set.channels,
			])
				.pipe(
					filter(([_, channels]) => channels !== undefined),
					take(1),
				)
				.subscribe(([foundChannel, channels]) => {
					try {
						if (foundChannel) {
							channels.splice(channels.indexOf(foundChannel), 1);

							set.stateSubscriptions
								.get(foundChannel.id)
								?.forEach((s) => s.unsubscribe());
							set.stateSubscriptions
								.get(foundChannel.original_id)
								?.forEach((s) => s.unsubscribe());
						}

						channels.push(channelModel);
						this.hookChannel(set, channelModel);
						set.pump();

						o.next(channelModel);
						o.complete();
					} catch (e) {
						o.error(e);
					}
				});
		});
	};

	public removeChannel$ = (
		directoryId: string,
		channelId: string | number,
	): Observable<null> => {
		const set = this.getStorageSet(directoryId);

		return new Observable<null>((o) => {
			set.channels.pipe(take(1)).subscribe((channels) => {
				if (channels == null) {
					o.next(null);
					o.complete();
				} else {
					try {
						const foundChannel = channels.find((x) => x.id === channelId);
						if (foundChannel == null) {
							const originalChannel = channels.find(
								(x) => x.original_id === channelId,
							);

							if (originalChannel != null) {
								channels.splice(channels.indexOf(originalChannel), 1);
								set.stateSubscriptions
									.get(originalChannel.original_id)
									.forEach((s) => s.unsubscribe());

								this._channelRemovedEvent.next({
									channelId: originalChannel.id,
									directoryId: originalChannel.directory,
								});
							}
						} else {
							channels.splice(channels.indexOf(foundChannel), 1);
							set.stateSubscriptions
								.get(foundChannel.id)
								.forEach((s) => s.unsubscribe());

							this._channelRemovedEvent.next({
								channelId: foundChannel.id,
								directoryId: foundChannel.directory,
							});
						}

						set.pump();

						o.next(null);
						o.complete();
					} catch (e) {
						o.error(e);
					}
				}
			});
		});
	};

	public refreshChannels = (directoryId: string) => {
		return firstValueFrom(this.fetchChannels$(directoryId, true));
	};

	private fetchChannels$ = (
		directoryId: string,
		force: boolean = false,
	): Observable<ChannelModel[]> => {
		const set = this.getStorageSet(directoryId);

		return new Observable<ChannelModel[]>((o) => {
			if (directoryId == null) {
				set.lastRefresh = new Date();

				set.channels.next([]);
				o.next([]);
				o.complete();
			} else if (set.lastRefresh == null || force) {
				set.lastRefresh = new Date();
				set.setInitializingChannels(true);

				const loadNextPage = (page: number) => {
					this.channelService
						.getChannels(
							directoryId,
							page * getEnvironment().settings.services.store.channel.pageSize,
							getEnvironment().settings.services.store.channel.pageSize,
						)
						.pipe(
							switchMap((response) => {
								const channels = response.results;

								channels.forEach((c) => {
									this._allChannelSet.set(c.id, c);
									this.hookChannel(set, c);
								});

								return combineLatest([
									set.channels.pipe(take(1)).pipe(
										map((m) => {
											const [merged, changed] = this.mergeChannels(m, channels);
											if (changed) {
												set.channels.next(merged);
											}

											return merged;
										}),
									),
									of(response.count),
								]);
							}),
						)
						.subscribe({
							next: ([channels, count]) => {
								if (
									count <=
									(page + 1) *
										getEnvironment().settings.services.store.channel.pageSize
								) {
									set.setInitializingChannels(false);
									o.next(channels);
									o.complete();
								} else {
									loadNextPage(page + 1);
								}
							},
							error: (err) => {
								set.setInitializingChannels(false);
								o.error(err);
							},
						});
				};
				loadNextPage(0);
			}
		});
	};

	private fetchUserChannels$ = (
		userLinkId: number,
	): Observable<ChannelModel[]> => {
		let userChannels: ChannelModel[] = [];

		return new Observable<ChannelModel[]>((o) => {
			const loadNextPage = (page: number) => {
				this.gabbyUserService
					.getChannels(
						userLinkId,
						page * getEnvironment().settings.services.store.channel.pageSize,
						getEnvironment().settings.services.store.channel.pageSize,
					)
					.pipe(
						map((response) => {
							const channels = response.results;
							userChannels = this.mergeChannels(userChannels, channels)[0];
							return [userChannels, response.count] as [ChannelModel[], number];
						}),
					)
					.subscribe({
						next: ([channels, count]) => {
							if (
								count <=
								(page + 1) *
									getEnvironment().settings.services.store.channel.pageSize
							) {
								o.next(channels);
								o.complete();
							} else {
								loadNextPage(page + 1);
							}
						},
						error: (err) => {
							o.error(err);
						},
					});
			};
			loadNextPage(0);
		}).pipe(
			switchMap((ucs) => {
				return concat(ucs.map((uc) => this.patchChannel$(uc))).pipe(
					map(() => ucs),
				);
			}),
		);
	};

	/*
	 *
	 * Flagged Channels
	 *
	 */
	public flaggedChannels$ = () => {
		return this._flaggedChannels.pipe(filter((x) => x !== undefined));
	};

	public isFlaggedChannel$ = (channelId: string | number) => {
		return this.flaggedChannels$().pipe(
			map((f) => f.indexOf(channelId) !== -1),
		);
	};

	public addFlaggedChannel$ = (channelId: string | number) => {
		return this.settingsStore
			.getSetting$<(string | number)[]>(UserSetting['gabby.channels.flags'])
			.pipe(
				switchMap((f) => {
					f = f || [];
					const i = f.indexOf(channelId);
					if (i === -1) {
						SessionLogger.log(
							'ChannelStoreService',
							'addFlaggedChannel',
							channelId,
						);

						f.push(channelId);
						return this.settingsStore.saveSetting$(
							UserSetting['gabby.channels.flags'],
							f,
						);
					} else {
						return of(f);
					}
				}),
				switchMap(() => this.wpfs.notify_channelFlagsChanged$()),
			);
	};

	public removeFlaggedChannel$ = (channelId: string | number) => {
		return this.settingsStore
			.getSetting$<(string | number)[]>(UserSetting['gabby.channels.flags'])
			.pipe(
				switchMap((f) => {
					f = f || [];
					const i = f.indexOf(channelId);
					if (i !== -1) {
						SessionLogger.log(
							'ChannelStoreService',
							'removeFlaggedChannel',
							channelId,
						);

						f.splice(i, 1);
						return this.settingsStore.saveSetting$(
							UserSetting['gabby.channels.flags'],
							f,
						);
					} else {
						return of(f);
					}
				}),
				switchMap(() => this.wpfs.notify_channelFlagsChanged$()),
			);
	};

	public refreshFlaggedChannels = () => {
		return this.fetchFlaggedChannels();
	};

	private fetchFlaggedChannels = (): Promise<boolean> => {
		return subscribeAndPromise(
			this.settingsStore.getSetting$<(string | number)[]>(
				UserSetting['gabby.channels.flags'],
				false,
			),
			{
				next: (x) => {
					SessionLogger.log('ChannelStoreService', 'refreshChannelFlags', x);
					x = x || [];
					this._flaggedChannels.next(x);
				},
			},
		);
	};

	/*
	 *
	 * Internals
	 *
	 */
	private hookChannel = (set: ChannelStorageSet, channel: ChannelModel) => {
		if (set.stateSubscriptions.has(channel.id)) {
			set.stateSubscriptions.get(channel.id).forEach((s) => s.unsubscribe());
		}

		const subs = [];

		// Unread counter up
		subs.push(
			this.messageEventService
				.unreadMessages$({
					directoryId: channel.directory,
					channelId: channel.id,
				})
				.pipe(
					filter(
						(e) =>
							e.message_sender_link_id !== this.userStore.currentUserLinkId &&
							e.message_send_medium !== MessagePlatform.system,
					),
				)
				.subscribe((e) => {
					channel.unread_messages++;

					SessionLogger.log(
						'ChannelStoreService',
						'incremented channel unread count',
						{
							message_id: e.message_id,
							channel_id: channel.id,
							directory_id: e.directory,
							unread_count: channel.unread_messages,
						},
					);
					set.pump();
				}),
		);

		// Unread counter down
		subs.push(
			this.messageEventService
				.readMessages$({
					directoryId: channel.directory,
					channelId: channel.id,
				})
				.pipe(
					filter((e) => {
						return (
							e.senderLinkId !== this.userStore.currentUserLinkId &&
							(e.readerLinkId === this.userStore.currentUserLinkId ||
								e.readerLinkId === 0)
						);
					}),
				)
				.subscribe((e) => {
					channel.unread_messages--;

					SessionLogger.log(
						'ChannelStoreService',
						'decremented channel unread count',
						{
							message_id: e.readMessageId,
							channel_id: channel.id,
							directory_id: e.directoryId,
							unread_count: channel.unread_messages,
						},
					);

					if (channel.unread_messages < 0) {
						console.error(
							'ChannelStoreService ',
							'decremented channel unread count below zero ',
							{
								message_id: e.readMessageId,
								channel_id: channel.id,
								directory_id: e.directoryId,
								unread_count: channel.unread_messages,
							},
						);
					}

					set.pump();
				}),
		);

		set.stateSubscriptions.set(channel.id, subs);
	};

	private getStorageSet = (directoryId: string): ChannelStorageSet => {
		if (!this._directoryStorageSets.has(directoryId)) {
			this._directoryStorageSets.set(directoryId, new ChannelStorageSet());
		}

		return this._directoryStorageSets.get(directoryId);
	};

	private mergeChannels = (
		original: ChannelModel[],
		newChannels: ChannelModel[],
	): [ChannelModel[], boolean] => {
		let changed = false;

		if (original == null) {
			original = newChannels.slice();
			changed = true;
		} else {
			original = original.slice();

			newChannels.forEach((newChannel) => {
				const foundChannelIndex = original.findIndex((currentChannel) => {
					if (currentChannel.id == null) {
						return currentChannel.original_id === newChannel.original_id;
					} else {
						return currentChannel.id === newChannel.id;
					}
				});

				if (foundChannelIndex === -1) {
					original.push(newChannel);
					changed = true;
				} else {
					original.splice(foundChannelIndex, 1, newChannel);
					changed = true;
				}
			});
		}

		return [original, changed];
	};
}
