import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentNewsModel } from './student-news.model';

@Injectable({
	providedIn: 'root',
})
export class StudentNewsService {
	constructor(private httpClient: HttpClient) {}

	public getNews = (studentId: number): Observable<StudentNewsModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/communication/student/${studentId}/news`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentNewsModel, r as any[]), {
					errorCode: '435B6D22',
				}),
			);
	};
}
