import {
	AbstractControl,
	FormArray,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
// eslint-disable-next-line no-restricted-imports
import { FormControlWrapper } from 'src/public.types';
// eslint-disable-next-line no-restricted-imports
import {
	PASSWORD_RESTRICTIONS,
	ValidationConstants,
} from '../constants/constants';
// eslint-disable-next-line no-restricted-imports
import { PhoneNumberPipe } from '../pipes/phone-number.pipe';
// eslint-disable-next-line no-restricted-imports
import { OrganizationModel } from '../services/api/organizations/organization.model';
// eslint-disable-next-line no-restricted-imports
import { UserAddressModel } from '../services/utility/utility-models/address.model';
import {
	arrayContainsValue,
	hasValue,
	isArray,
	isNonEmptyString,
	isNullOrEmptyString,
} from './compare';

/**
 * https://stackoverflow.com/a/49743369/3780285
 * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
 *
 * @param control AbstractControl
 * @returns AbstractControl
 */
export function cloneAbstractControl<T extends AbstractControl<G>, G>(
	control: T,
);
export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
	let newControl: FormGroup | FormArray | FormControl;

	if (control instanceof FormGroup) {
		const formGroup = new FormGroup(
			{},
			control.validator,
			control.asyncValidator,
		);
		const controls = control.controls;

		Object.keys(controls).forEach((key) => {
			formGroup.addControl(key, cloneAbstractControl(controls[key]));
		});

		newControl = formGroup;
	} else if (control instanceof FormArray) {
		const formArray = new FormArray(
			[],
			control.validator,
			control.asyncValidator,
		);

		control.controls.forEach((formControl) =>
			formArray.push(cloneAbstractControl(formControl)),
		);

		newControl = formArray;
	} else if (control instanceof FormControl) {
		newControl = new FormControl(
			control.value,
			control.validator,
			control.asyncValidator,
		);
	} else {
		throw Error('Error cloneAbstractControl: unexpected control value');
	}

	if (control.disabled) newControl.disable({ emitEvent: false });

	return newControl as any;
}

/**
 * Deep copies the given source onto the given target. Only works if they have the same data shape.
 *
 * @param sourceControl AbstractControl
 * @param targetControl AbstractControl
 */
export function copyToAbstractControl<T extends AbstractControl<G>, G>(
	sourceControl: T,
	targetControl: T,
	options?: {
		skipNull?: boolean;
		copyState?: boolean;
	},
);
export function copyToAbstractControl<T extends AbstractControl>(
	sourceControl: T,
	targetControl: T,
	options?: {
		skipNull?: boolean;
		copyState?: boolean;
	},
): void {
	options = options || ({} as any);
	options.copyState = options.copyState || false;
	options.skipNull = options.skipNull || false;

	if (
		sourceControl instanceof FormGroup &&
		targetControl instanceof FormGroup
	) {
		Object.keys(sourceControl.controls).forEach((key) => {
			if (targetControl.controls[key] != null) {
				copyToAbstractControl(
					sourceControl.controls[key],
					targetControl.controls[key],
					options,
				);
			}
		});
	} else if (
		sourceControl instanceof FormArray &&
		targetControl instanceof FormArray
	) {
		for (let i = 0; i < sourceControl.controls.length; i++) {
			if (targetControl.controls.length > i) {
				copyToAbstractControl(
					sourceControl.controls[i],
					targetControl.controls[i],
					options,
				);
			}
		}
	} else if (
		sourceControl instanceof FormControl &&
		targetControl instanceof FormControl
	) {
		if (options.skipNull && sourceControl.value == null) {
			return;
		} else {
			targetControl.setValue(sourceControl.value);
		}
	} else {
		throw new Error(
			'Error copyToAbstractControl: unexpected source or target control type',
		);
	}

	if (options.copyState) {
		if (sourceControl.dirty) targetControl.markAsDirty();
		if (sourceControl.touched) targetControl.markAsTouched();
	}
}

export function markFormGroupTouched(formGroup: any) {
	(Object as any)
		.values(formGroup.controls)
		.forEach((control: AbstractControl) => {
			control.markAsTouched();
			control.markAsDirty();

			// HACK: we are breaking the law because angular does not have a touchChanges observable
			// https://github.com/angular/angular/issues/10887
			control.statusChanges['next'](control.status);

			if ((control as any).controls) {
				markFormGroupTouched(control);
			}
		});
}

export function forceUpdateStatus(
	control: AbstractControl | AbstractControl<any>,
) {
	if (control instanceof FormGroup) {
		Object.keys(control.controls).forEach((key) => {
			if (control.controls[key] != null) {
				forceUpdateStatus(control.controls[key]);
			}
		});

		if (control.updateValueAndValidity != null) {
			control.updateValueAndValidity();
		}
	} else if (control instanceof FormArray) {
		for (const c of control.controls) {
			forceUpdateStatus(c);
		}

		if (control.updateValueAndValidity != null) {
			control.updateValueAndValidity();
		}
	} else if (control instanceof FormControl) {
		// HACK: we are breaking the law because angular does not have a touchChanges observable
		// https://github.com/angular/angular/issues/10887
		if (control.validator) {
			control.validator(control);
		}
		control.updateValueAndValidity({
			emitEvent: true,
			onlySelf: false,
		});
		control.statusChanges['next'](control.status);
	} else {
		throw new Error(
			'Error copyToAbstractControl: unexpected source or target control type',
		);
	}
}

export function verifyOrMarkForm(form: FormGroup): boolean {
	if (!form.valid) {
		markFormGroupTouched(form);
		return false;
	} else {
		return true;
	}
}

export function phoneOrEmailFormatter(
	value: string | number,
): string | number | null {
	if (value === '') {
		return null;
	} else if (RegExp(ValidationConstants.phoneNumberRegex).test(`${value}`)) {
		return PhoneNumberPipe.toNumber(`${value}`);
	}

	return value;
}

export function parseTimeValue(
	value: string,
): [hour: number, minute: number, second?: number] {
	const parsed = value.split(':').map((x) => parseInt(x) || 0);
	parsed.length = parsed.length <= 3 ? parsed.length : 2;
	parsed[0] = parsed[0] ?? 0;
	parsed[1] = parsed[1] ?? 0;
	return parsed as any;
}

export const commonValidators = {
	phone: (ctrl: AbstractControl) => {
		let error = null;
		if (
			ctrl.value &&
			!new RegExp(ValidationConstants.phoneNumberRegex).test(ctrl.value)
		) {
			error = { phone: { given: ctrl.value } };
		}
		return error;
	},
	notSevenDigitPhone: (ctrl: AbstractControl) => {
		let error = null;
		if (
			ctrl.value &&
			new RegExp(ValidationConstants.phoneNumberRegexSevenDigit).test(
				ctrl.value,
			)
		) {
			error = { notsevendigitphone: { given: ctrl.value } };
		}
		return error;
	},
	password: (ctrl: AbstractControl) => {
		let error = null;
		if (ctrl.value && ctrl.value.length < 8) {
			error = {
				password: { requirements: 'Password must be at least 8 characters' },
			};
		}
		return error;
	},
	requiredNotEmpty: (ctrl: AbstractControl) => {
		const regex = /^\s*$/;

		if (regex.test(ctrl.value) || Validators.required(ctrl)) {
			return { required: true };
		}

		return null;
	},
	requiredTrue: (ctrl: AbstractControl) => {
		if (ctrl.value !== true) {
			return { required: true };
		}

		return null;
	},
	url: (http: 'banHttp' | 'forceHttps' | null = null) => {
		return (ctrl: AbstractControl) => {
			const regex = new RegExp(ValidationConstants.urlRegex, 'i');

			if (isNonEmptyString(ctrl.value)) {
				if (!regex.test(ctrl.value)) {
					return { url: { requirements: 'Must be a proper URL' } };
				}

				if (http === 'banHttp' && /^https?:\/\//i.test(ctrl.value)) {
					return {
						url: { requirements: 'Do not include http:// or https://' },
					};
				}

				if (http === 'forceHttps' && !/^https:\/\//i.test(ctrl.value)) {
					return { url: { requirements: 'Must include https://' } };
				}
			}
			return null;
		};
	},
	zipcode: (ctrl: AbstractControl) => {
		const regex = new RegExp(ValidationConstants.zipcode, 'i');
		if (hasValue(ctrl.value) && !regex.test(ctrl.value)) {
			return { zipcode: { requirements: 'Must be a 5 or 9 digit zipcode' } };
		}
		return null;
	},
	validSelection: <T>(
		options: T[] | (() => T[]),
		keyCheck?: (option: T, item: any) => boolean,
	) => {
		keyCheck =
			keyCheck ??
			function (o, i) {
				return o === i;
			};

		let getOptions: () => T[];

		if (!(typeof options === 'function')) {
			getOptions = function () {
				return options;
			};
		} else {
			getOptions = options;
		}

		return function (ctrl: AbstractControl) {
			if (
				hasValue(ctrl.value) &&
				!arrayContainsValue(getOptions(), ctrl.value, keyCheck)
			) {
				return { validSelection: `${ctrl.value} is not a valid selection` };
			}
			return null;
		};
	},
	validMultiSelection: <T>(
		options: T[] | (() => T[]),
		keyCheck?: (option: T, item: any) => boolean,
	) => {
		keyCheck =
			keyCheck ??
			function (o, i) {
				return o === i;
			};

		let getOptions: () => T[];

		if (!(typeof options === 'function')) {
			getOptions = function () {
				return options;
			};
		} else {
			getOptions = options;
		}

		return function (ctrl: AbstractControl) {
			if (hasValue(ctrl.value) && isArray(ctrl.value)) {
				const badSelections = ctrl.value.filter(
					(v) => !arrayContainsValue(getOptions(), v, keyCheck),
				);

				if (badSelections.length > 0) {
					return {
						validMultiSelection: `${badSelections.join(', ')} ${
							badSelections.length > 1 ? 'are' : 'is'
						} not a valid selection`,
					};
				}
			}
			return null;
		};
	},
	regex: (regExp: RegExp, name: string, errorOnMatch: boolean = true) => {
		return function (ctrl: AbstractControl) {
			if (ctrl.value) {
				if (regExp.test(ctrl.value) === errorOnMatch) {
					const error = {};
					error[name] = true;
					return error;
				}
			}

			return null;
		};
	},

	emailOrPhone: (ctrl: AbstractControl) => {
		return emailOrPhoneValidator(ctrl);
	},

	multipleEmailOrPhone: (ctrl: AbstractControl) => {
		let err = null;
		if (ctrl.value && typeof ctrl.value === 'string') {
			const arr = ctrl.value.split(',');
			arr.forEach((val) => {
				const error = emailOrPhoneValidator(new FormControl(val.trim()));
				if (error) {
					err = error;
				}
			});
		}
		return err;
	},

	csvHasDuplicateValues: (ctrl: AbstractControl) => {
		let err = null;
		if (ctrl.value && typeof ctrl.value === 'string') {
			const arr = ctrl.value.split(/,\s*/);
			if (new Set(arr).size !== arr.length) {
				err = {
					duplicateValues: 'Duplicate values exist in form field',
				};
			}
		}
		return err;
	},

	hasDuplicateValues: <T, G>(
		valFunc: (val: T) => G,
		titleFunc: (val: T) => string,
	) => {
		return (ctrl: AbstractControl): ValidationErrors => {
			const map = new Map<G, T[]>();
			const options = ctrl.value;

			if (ctrl.value == null || !isArray(ctrl.value)) {
				return null;
			}

			options.forEach((v) => {
				if (map.has(valFunc(v))) {
					const arr = map.get(valFunc(v));
					arr.push(v);
					map.set(valFunc(v), arr);
				} else {
					map.set(valFunc(v), [v]);
				}
			});

			const errorMessages = [];
			map.forEach((value) => {
				if (value.length > 1) {
					const temp = value.map((v) => {
						return titleFunc(v);
					});

					let str = '';
					for (let i = 0; i < temp.length; i++) {
						str += temp[i];
						if (i < temp.length - 2) {
							str += ', ';
						} else if (i < temp.length - 1) {
							str += ' and ';
						}
					}

					errorMessages.push(`The values ${str} cannot be used together`);
				}
			});

			if (errorMessages.length > 0) {
				return {
					duplicateValues: errorMessages.join('\n'),
				};
			}

			return null;
		};
	},

	passwordForbiddenValues: (ctrl: AbstractControl) => {
		const pass = ctrl?.value?.toLowerCase();
		return PASSWORD_RESTRICTIONS.find((val) => {
			return pass?.includes(val);
		})
			? { forbiddenValue: true }
			: null;
	},

	emailAndLength: (ctrl: AbstractControl) => {
		if (ctrl.value == null) {
			return null;
		}
		if (ctrl.value.trim().length > 60) {
			return { email: 'Max length allowed is 60 characters' };
		}
		if (Validators.email(ctrl) != null) {
			return { email: 'Must be a valid email' };
		}
		return null;
	},
	programStudentReady: (orgs: OrganizationModel[]) => {
		return function (ctrl: AbstractControl) {
			if (ctrl.value) {
				const org = orgs.find((o) => o.id === ctrl.value);
				if (ctrl.value && !org?.ready_for_students) {
					return {
						programNotReady: `${
							org?.title ?? 'This program'
						} has not been finalized and is
					not ready for students yet. `,
					};
				}
			}

			return null;
		};
	},
	validateCheckboxChecked: (fg: AbstractControl<boolean[]>) => {
		const checkboxes: boolean[] = fg.value;
		if (!checkboxes.some((x) => x)) {
			return { requiredCheckbox: true };
		}

		return null;
	},
};

export const addressValidators = {
	addressValidator: (fg: FormGroup) => {
		let error = null;
		if (
			Object.keys(fg.controls).some(
				(ky) => ky !== 'street2' && isNullOrEmptyString(fg.controls[ky].value),
			)
		) {
			error = { invalidAddress: 'A valid address is required' };
		}
		return error;
	},

	addressValidatorNotRequired: (
		fg: FormGroup<FormControlWrapper<UserAddressModel>>,
	) => {
		let error = null;

		if (
			Object.keys(fg.controls).some(
				(k) => k !== 'street2' && !isNullOrEmptyString(fg.controls[k].value),
			) &&
			Object.keys(fg.controls).some(
				(ky) => ky !== 'street2' && isNullOrEmptyString(fg.controls[ky].value),
			)
		) {
			error = { invalidAddress: 'A valid address is required' };
		}

		return error;
	},
};

function emailOrPhoneValidator(ctrl: AbstractControl) {
	let error = null;
	if (
		(commonValidators.phone(ctrl) != null ||
			commonValidators.notSevenDigitPhone(ctrl) != null) &&
		Validators.email(ctrl) != null
	) {
		error = {
			invalidEmailOrPhone: 'A valid email address or phone number is required',
		};
	}
	return error;
}
