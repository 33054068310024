import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import fuzzysort from 'fuzzysort';
import { AsyncSubject, BehaviorSubject, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { OrganizationModel } from 'src/lib/services/api/organizations/organization.model';
import { OrganizationsStoreService } from 'src/lib/services/stores/organizations-store/organizations-store.service';
import { isNonEmptyString } from 'src/lib/utilities/compare';
import { noop } from 'src/lib/utilities/noop';
import { ProgramAdvancedSearchModalService } from 'src/lib/views/modals/program-advanced-search-modal/program-advanced-search-modal.service';
import { WaitSpinnerComponent } from '../../global/wait-spinner/wait-spinner.component';

@Component({
	selector: 'ae-organization-multi-select',
	templateUrl: './organization-multi-select.component.html',
	styleUrls: ['./organization-multi-select.component.scss'],
	exportAs: 'aeOrganizationMultiSelect',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OrganizationMultiSelectComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
		NgClass,
		WaitSpinnerComponent,
	],
})
export class OrganizationMultiSelectComponent
	implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
	@HostBinding('class.custom-form-control') customFormControl = true;
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() organizations: OrganizationModel[];
	@Input() validIds: number[];
	@Input() clearable: boolean = false;
	@Input() id: string;
	@Input() class: string;
	@Input() showFullLabel: boolean = false;
	@Input() showWarning: boolean = false;
	@Input() includeInactive: boolean = false;
	@Input() maxSelectedItems: number;

	@Output() loadingComplete = new EventEmitter<boolean>();
	@Output() update = new EventEmitter<number[]>();

	public searchMap = new Map<number, Fuzzysort.Prepared[]>();
	public subtitleMap = new Map<number, string>();

	public _organizations: OrganizationModel[] = [];
	private _organizations$ = new BehaviorSubject<OrganizationModel[]>(undefined);
	private _organizationsSubscription: Subscription;

	public isInitiating = true;

	public orgControl: FormControl<number[]>;

	private _changeFunction: (value: number[]) => void = noop;
	private _touchedFunction: () => void = noop;
	private _changeWatcher: Subscription;

	@ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

	constructor(
		private organizationsStoreService: OrganizationsStoreService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private programAdvancedSearchModalService: ProgramAdvancedSearchModalService,
	) {}

	ngOnInit() {
		this.orgControl = this.fb.control([]);
		this.watchForChange();

		if (this.organizations == null) {
			this._organizationsSubscription = this.organizationsStoreService
				.organizations$(this.includeInactive)
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe({
					next: (x) => {
						this.organizations = x;
						this._organizations = x;
						this._organizations$.next(this._organizations);
						this.loadingComplete.emit(true);
					},
				});
		} else {
			this._organizations$.next(this.organizations);
			this.loadingComplete.emit(true);
		}

		this.ngSelect.openEvent.pipe(take(1)).subscribe(() => {
			this._touchedFunction();
		});

		// This resets the scroll back to top
		this.ngSelect.searchEvent
			.pipe(
				switchMap((x) => {
					if (isNonEmptyString(x?.term)) {
						this._organizations = [];
						this.cdr.detectChanges();

						return this.ngSelect.scroll.pipe(take(1));
					} else {
						return of(null);
					}
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this._organizations = this.organizations ?? [];
				this.cdr.detectChanges();
			});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.organizations) {
			this._organizationsSubscription?.unsubscribe();
			this._organizations = changes.organizations.currentValue ?? [];
		}
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.orgControl.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((x) => {
				this._changeFunction(x);
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	public orgsSearchFn = (term: string, item: OrganizationModel): boolean => {
		const trimmedTerm = term?.trim();

		if (item.id.toFixed() === trimmedTerm) {
			return true;
		}

		if (item.unique_id === trimmedTerm) {
			return true;
		}

		// Check subtitle first
		if (!this.subtitleMap.has(item.id)) {
			this.subtitleMap.set(
				item.id,
				item.subtitle.toLowerCase().replace(/[\s-]/g, ''),
			);
		}

		if (
			trimmedTerm.length > 1 &&
			this.subtitleMap
				.get(item.id)
				.indexOf(trimmedTerm.replace(/[\s-]/g, '').toLowerCase()) > -1
		) {
			return true;
		}

		// Cache the prepared searchable values
		if (!this.searchMap.has(item.id)) {
			const searchTerms = [];
			this.permuteArray([item.title, item.parent_title], searchTerms);

			const resultSets: string[][] = [[]];
			this.permuteArray([item.title, item.parent_title], resultSets);

			const prepared = resultSets.map((set) =>
				fuzzysort.prepare(set.join(', ')),
			);

			this.searchMap.set(item.id, prepared);
		}

		// Check if it's a match
		return (
			fuzzysort.go(term, this.searchMap.get(item.id), {
				threshold: 0.7,
				limit: 1,
			}).length > 0
		);
	};

	private permuteArray = <TPerm>(
		input: TPerm[],
		resultArray: TPerm[][],
		usedChars: TPerm[] = [],
	): void => {
		for (let i = 0; i < input.length; i++) {
			const ch = input.splice(i, 1)[0];
			usedChars.push(ch);
			if (input.length === 0) {
				resultArray.push(usedChars.slice());
			}
			this.permuteArray(input, resultArray, usedChars);
			input.splice(i, 0, ch);
			usedChars.pop();
		}
	};

	public advancedSearch = () => {
		if (this.orgControl.enabled) {
			this.programAdvancedSearchModalService
				.openModal$(
					true,
					this._organizations$.pipe(
						filter((x) => x !== undefined),
						map((orgs) => orgs.map((o) => o.id)),
					),
					this.orgControl.value,
					this.maxSelectedItems,
				)
				.subscribe((ids) => {
					this.orgControl.setValue(ids as number[]);
					this.update.emit(ids as number[]);
				});
		}
	};

	// ControlValueAccessor
	public writeValue(id: number[]): void {
		try {
			this.stopWatchForChange();
			this.orgControl.setValue(id);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		if (isDisabled) {
			this.orgControl.disable();
		} else {
			this.orgControl.enable();
		}

		this.watchForChange();
	}

	public getInvalidOrganizationName = (organization) => {
		if (this.includeInactive && this.organizations) {
			return this.organizations.find((org) => {
				return org.id === organization?.id;
			})?.title;
		} else {
			return null;
		}
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
