import { hasValue, isNonEmptyString, isNullOrEmptyString } from './compare';

export function camelCaseSplit(input: string | null): string | null {
	if (isNullOrEmptyString(input)) return input;

	const returnArr = [];
	// eslint-disable-next-line @typescript-eslint/prefer-for-of
	for (let i = 0; i < input.length; i++) {
		let character = input[i];
		if (character.toUpperCase() === character) {
			character = ` ${character}`;
		}
		returnArr.push(character);
	}

	return returnArr.join('').trim();
}

export function splitOnUnderScoreAndCapitalizeAllWords(val: string) {
	return val
		?.toLowerCase()
		.split('_')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');
}

export function buildUserName(
	firstName: string,
	lastName: string,
	preferredName?: string,
): { names: [string, string]; fullName: string } {
	const first = `${
		isNullOrEmptyString(preferredName) ? firstName : preferredName
	}`.trim();
	lastName = (lastName ?? '').trim();

	return {
		names: [first, lastName],
		fullName: `${first} ${lastName}`.trim(),
	};
}

export function buildFullUserName(
	item: UserNameModel,
	byFirst: boolean = true,
) {
	const firstName = item.profile_first_name ?? item.first_name;
	const lastName = item.profile_last_name ?? item.last_name;
	const preferredName = isNonEmptyString(
		item.profile_preferred_name ?? item.preferred_name,
	)
		? ` "${item.profile_preferred_name ?? item.preferred_name}" `
		: ' ';

	if (byFirst) {
		return `${firstName}${preferredName}${lastName}`;
	} else {
		return `${lastName}, ${firstName}${preferredName}`;
	}
}

export function buildPageTitle(...titles: string[]): string {
	titles = titles ?? [];
	titles.push('Graduation Alliance');
	return titles.filter((t) => hasValue(t)).join(' | ');
}

export class UserNameModel {
	first?: string;
	last?: string;
	middle?: string;
	preferred?: string;

	first_name?: string;
	last_name?: string;
	middle_name?: string;
	preferred_name?: string;

	profile_first_name?: string;
	profile_last_name?: string;
	profile_middle_name?: string;
	profile_preferred_name?: string;
}
