import { Transform, Type } from 'class-transformer';
import { StudentPerformanceStatus } from 'src/lib/constants/compliance';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { calculatePerformanceTrend } from 'src/lib/utilities/site/compliance';

export class RoleManagerStudentLoadTeamModel {
	team_id: number;
	team_name: string;
	students: number[];
	students_count: number;
}

export function RoleManagerStudentLoadTeamModel_ClassTransform_Type() {
	return RoleManagerStudentLoadTeamModel;
}

export class RoleManagerStudentPerformance {
	get expectation_status() {
		return calculatePerformanceTrend(
			this.performance,
			this.performance_met_previous,
		);
	}

	billable: StudentPerformanceStatus;
	drop_expected: boolean;
	performance: StudentPerformanceStatus;
	performance_met_previous: StudentPerformanceStatus;
	track: string;
}

export function RoleManagerStudentPerformance_ClassTransform_Type() {
	return RoleManagerStudentPerformance;
}

export class RoleManagerStudentDataModel {
	uid: number;
	name: string;
	iep_504: string;
	institute_id: number;
	institute_title: string;
	parent_institute_id: number;
	parent_institute_title: string;
	expected_status_code: number;
	expected_status_title: string;
	previous_institute_id: number;
	previous_institute_title: string;
	enrolled: boolean;
	@Transform(dateTransformer)
	assigned: Date;
	is_paused: boolean;
	@Type(RoleManagerStudentPerformance_ClassTransform_Type)
	performance_data: RoleManagerStudentPerformance;
	esl: string;
	state_program: string;
}

export function RoleManagerStudentDataModel_ClassTransform_Type() {
	return RoleManagerStudentDataModel;
}

export class RoleManagerStudentLoadModel {
	all_students: number[];
	all_students_count: number;
	special_assignments: number[];
	special_assignments_count: number;
	@Type(RoleManagerStudentLoadTeamModel_ClassTransform_Type)
	teams: RoleManagerStudentLoadTeamModel[];
	@Type(RoleManagerStudentDataModel_ClassTransform_Type)
	student_data: RoleManagerStudentDataModel[];
}

// either academic coaches or a associated_group_id must be provided
export interface RoleManagerMigrateStudentsArgument {
	student_uids: number[];
	members?: number[];
	associated_group_id?: number;
	split_method: MigrateStudentsSplitOptions;
	custom_split?: Record<number, number>;
}

export enum MigrateStudentsSplitOptions {
	evenly = 'evenly',
	equalize = 'equalize',
	custom = 'custom',
}
