import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	UpdateUserRequestArgumentResponse,
	UserApproveRequestArgument,
	UserUpdateRequestArgument,
} from './users-update-request.argument';
import {
	UsersPendingRequestsResponse,
	UsersRequestOptionsResponse,
} from './users-update-request.model';

@Injectable({
	providedIn: 'root',
})
export class UsersUpdateRequestsService {
	constructor(private httpClient: HttpClient) {}

	public getRequestsOptions = (
		uid: number,
	): Observable<UsersRequestOptionsResponse> => {
		return this.httpClient
			.get<UsersRequestOptionsResponse>(
				`api/v1/user/${uid}/update_request/options`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(UsersRequestOptionsResponse, r), {
					errorCode: 'F1D58089',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public getPendingRequests = (
		uid: number,
	): Observable<UsersPendingRequestsResponse> => {
		return this.httpClient
			.get<UsersPendingRequestsResponse>(
				`api/v1/user/${uid}/update_request/pending`,
			)
			.pipe(
				mapResponse((r) => plainToInstance(UsersPendingRequestsResponse, r), {
					errorCode: '87E055A7',
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};

	public createUpdateRequest = (
		uid: number,
		argument: UserUpdateRequestArgument,
	) => {
		return this.httpClient
			.post<UpdateUserRequestArgumentResponse>(
				`/api/v1/user/${uid}/update_request/create`,
				argument,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(UpdateUserRequestArgumentResponse, r),
					{
						errorCode: 'BBEBC6DA',
						errorMessage: DefaultErrorMessage.Saving,
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public approveUpdateRequest = (
		uid: number,
		request_id: number,
		argument: UserApproveRequestArgument,
	) => {
		return this.httpClient
			.post<UpdateUserRequestArgumentResponse>(
				`/api/v1/user/${uid}/update_request/${request_id}/approve`,
				argument,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '1BEC1C05',
					errorMessage: DefaultErrorMessage.Saving,
					checkPreMap: false,
					validateSuccess: false,
				}),
			);
	};
}
