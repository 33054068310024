import { InjectionToken } from '@angular/core';
import { NgDompurifyHook } from '../types/ng-dompurify-hook';

const TEMP_ATTR_TARGET = 'data-dompurify-temp-target';

/**
 * Token for adding hooks to DOMPurify, see {@link addHook}
 */
export const DOMPURIFY_HOOKS = new InjectionToken<readonly NgDompurifyHook[]>(
	'Hooks for DOMPurify',
	{
		factory: () => [
			// https://github.com/cure53/DOMPurify/issues/317#issuecomment-912474068
			{
				name: 'beforeSanitizeAttributes',
				hook: function (node) {
					if (node.tagName === 'A') {
						if (!node.hasAttribute('target')) {
							node.setAttribute('target', '_self');
						}

						if (node.hasAttribute('target')) {
							node.setAttribute(TEMP_ATTR_TARGET, node.getAttribute('target'));
						}
					}
				},
			},
			{
				name: 'beforeSanitizeAttributes',
				hook: function (node) {
					if (node.tagName === 'IMG') {
						if (!node.hasAttribute('alt')) {
							node.setAttribute('alt', '');
						}
					}
				},
			},
			{
				name: 'afterSanitizeAttributes',
				hook: function (node) {
					if (node.tagName === 'A' && node.hasAttribute(TEMP_ATTR_TARGET)) {
						node.setAttribute('target', node.getAttribute(TEMP_ATTR_TARGET));
						node.removeAttribute(TEMP_ATTR_TARGET);

						if (node.getAttribute('target') === '_blank') {
							node.setAttribute('rel', 'noopener');
						}
					}
				},
			},
		],
		providedIn: 'root',
	},
);
