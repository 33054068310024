import { Expose, Type } from 'class-transformer';

// Sub Models
export class StudentInformationUpdateOptionItemModel {
	key: any;
	title: any;
}

export class StudentInformationUpdateMultiOptionModel {
	title: string;
	type: string;
	required: string;

	@Type(StudentInformationUpdateOptionItemModel_ClassTransform_Type)
	options: StudentInformationUpdateOptionItemModel[];
}

export class StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsStateModel {
	title: string;
	type: string;

	@Type(StudentInformationUpdateOptionItemModel_ClassTransform_Type)
	options: StudentInformationUpdateOptionItemModel[];
}

export class StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsModel {
	@Type(
		StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsStateModel_ClassTransform_Type,
	)
	state: StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsStateModel;
}

export class StudentInformationUpdateAddressOptionSubfieldsAddressModel {
	title: string;
	type: string;
	@Type(
		StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsModel_ClassTransform_Type,
	)
	subfields: StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsModel;
}

export class StudentInformationUpdateAddressOptionSubfieldsModel {
	@Type(
		StudentInformationUpdateAddressOptionSubfieldsAddressModel_ClassTransform_Type,
	)
	address: StudentInformationUpdateAddressOptionSubfieldsAddressModel;
}

export class StudentInformationUpdateAddressOptionModel {
	title: string;
	type: string;
	required: string;
	@Type(StudentInformationUpdateAddressOptionSubfieldsModel_ClassTransform_Type)
	subfields: StudentInformationUpdateAddressOptionSubfieldsModel;
}

export class StudentInformationUpdateTrackOptionModel {
	title: string;
	type: string;
	required: string;
	@Type(StudentInformationUpdateOptionItemModel_ClassTransform_Type)
	options: StudentInformationUpdateOptionItemModel[];
}

export class StudentInformationUpdateAttendanceClassOptionModel {
	title: string;
	type: string;
	required: string;
	@Type(StudentInformationUpdateOptionItemModel_ClassTransform_Type)
	options: StudentInformationUpdateOptionItemModel[];
}

// Main Model
export class StudentInformationUpdateOptionsModel {
	@Type(StudentInformationUpdateMultiOptionModel_ClassTransform_Type)
	graduation_plan: StudentInformationUpdateMultiOptionModel;

	@Type(StudentInformationUpdateMultiOptionModel_ClassTransform_Type)
	gender: StudentInformationUpdateMultiOptionModel;

	@Expose({ name: 'ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	@Type(StudentInformationUpdateMultiOptionModel_ClassTransform_Type)
	race: StudentInformationUpdateMultiOptionModel;

	@Type(StudentInformationUpdateMultiOptionModel_ClassTransform_Type)
	grade: StudentInformationUpdateMultiOptionModel;

	@Type(StudentInformationUpdateAddressOptionModel_ClassTransform_Type)
	addresses: StudentInformationUpdateAddressOptionModel;

	@Type(StudentInformationUpdateTrackOptionModel_ClassTransform_Type)
	track: StudentInformationUpdateTrackOptionModel;

	@Type(StudentInformationUpdateAttendanceClassOptionModel_ClassTransform_Type)
	attendance_class: StudentInformationUpdateAttendanceClassOptionModel;

	@Type(StudentInformationUpdateMultiOptionModel_ClassTransform_Type)
	district_residence: StudentInformationUpdateMultiOptionModel;
}

export function StudentInformationUpdateAttendanceClassOptionModel_ClassTransform_Type() {
	return StudentInformationUpdateAttendanceClassOptionModel;
}
export function StudentInformationUpdateOptionsModel_ClassTransform_Type() {
	return StudentInformationUpdateOptionsModel;
}
export function StudentInformationUpdateMultiOptionModel_ClassTransform_Type() {
	return StudentInformationUpdateMultiOptionModel;
}
export function StudentInformationUpdateOptionItemModel_ClassTransform_Type() {
	return StudentInformationUpdateOptionItemModel;
}
export function StudentInformationUpdateTrackOptionModel_ClassTransform_Type() {
	return StudentInformationUpdateTrackOptionModel;
}

export function StudentInformationUpdateAddressOptionModel_ClassTransform_Type() {
	return StudentInformationUpdateAddressOptionModel;
}

export function StudentInformationUpdateAddressOptionSubfieldsModel_ClassTransform_Type() {
	return StudentInformationUpdateAddressOptionSubfieldsModel;
}
export function StudentInformationUpdateAddressOptionSubfieldsAddressModel_ClassTransform_Type() {
	return StudentInformationUpdateAddressOptionSubfieldsAddressModel;
}
export function StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsModel_ClassTransform_Type() {
	return StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsModel;
}
export function StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsStateModel_ClassTransform_Type() {
	return StudentInformationUpdateAddressOptionSubfieldsAddressSubfieldsStateModel;
}
