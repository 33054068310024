<section class="root-container">
	<div class="display-container rounded">
		<div>
			<h1 class="login-logo-carrier">
				<img alt="Graduation Alliance" class="mb-3" [src]="loginLogo" />
			</h1>
		</div>
		<ng-container *aeSpinWhile="loading">
			<div class="w-100">
				@switch (currentState) {
					@case (loginState.credential) {
						<gal-credential-login
							(email)="setEmail($event)"
							(processing)="setProcessing($event)"
							(setState)="setState($event)"
						/>
					}
					@case (loginState.schoolSelection) {
						<gal-sso-program-select
							(selectedProgram)="setSelectedProgram($event)"
							(setState)="setState($event)"
						/>
					}
					@case (loginState.sso) {
						<gal-sso-login
							[destinationUrl]="url"
							[program]="currentProgram"
							(setState)="setState($event)"
						/>
					}
					@case (loginState.passwordReset) {
						<gal-password-reset
							[email]="currentEmail"
							(processing)="setProcessing($event)"
							(setState)="setState($event)"
						/>
					}
					@case (loginState.needHelp) {
						<gal-need-help (setState)="setState($event)" />
					}
					@default {
						<div class="text-danger">
							There was an issue loading try reloading the page
						</div>
					}
				}
			</div>

			<div
				class="w-100"
				[ngClass]="currentState === loginState.credential ? '' : 'd-none'"
			>
				<div class="pt-2">
					<gal-sso-button [data]="{ type: 'google', destinationUrl: url }" />
				</div>
			</div>

			@if (currentState === loginState.credential) {
				<div class="pt-2">
					<button
						class="btn btn-link"
						type="button"
						[disabled]="processing"
						(click)="setState(loginState.schoolSelection)"
					>
						Sign in with your school account
					</button>
				</div>
			} @else if (
				[
					loginState.schoolSelection,
					loginState.passwordReset,
					loginState.needHelp,
				].includes(currentState)
			) {
				<div class="pt-2">
					<button
						class="btn btn-link"
						type="button"
						[disabled]="processing"
						(click)="setState(loginState.credential)"
					>
						Back to login
					</button>
				</div>
			}
			<div class="flex-grow-1"></div>
			<div class="mt-2">
				<button
					class="btn btn-link"
					type="button"
					(click)="openAccessibilityStatement()"
				>
					Accessibility Statement
				</button>
			</div>
		</ng-container>
	</div>
</section>
