import { Type } from 'class-transformer';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class ProgramAdministratorRelationshipModel {
	id: number;
	name: string;
}

export function ProgramAdministratorRelationshipModel_ClassTransform_Type() {
	return ProgramAdministratorRelationshipModel;
}

export class ProgramAdministratorStudentAssignmentModel {
	uid: number;
	name: string;
	institute_id: number;
	institute_title: string;
	status: number;
	@Type(ProgramAdministratorRelationshipModel_ClassTransform_Type)
	assignment: ProgramAdministratorRelationshipModel[];
}

export function ProgramAdministratorStudentAssignmentModel_ClassTransform_Type() {
	return ProgramAdministratorStudentAssignmentModel;
}

export class ProgramAdministratorStudentAssignmentListModel {
	count: number;
	@Type(ProgramAdministratorStudentAssignmentModel_ClassTransform_Type)
	assigned_students: ProgramAdministratorStudentAssignmentModel[];
}

export interface ProgramAdminStudentListSearchArgument {
	name: PagingSearchFieldArgument;
	institute_title: PagingSearchFieldArgument;
	status: PagingSearchFieldArgument;
	relationship_name: PagingSearchFieldArgument;
}

export interface ProgramAdminStudentAssignmentUpdateArgument {
	students: number[];
	set: number[];
	remove?: number[];
	add?: number[];
}
