import { NgClass } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	forwardRef,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { AsyncSubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GroupModel } from 'src/lib/services/api/generic/groups/group.model';
import { GroupsStoreService } from 'src/lib/services/stores/general/groups/groups-store.service';
import { firstBy } from 'thenby';

@Component({
	selector: 'ae-group-select',
	templateUrl: './group-select.component.html',
	styleUrls: ['./group-select.component.scss'],
	exportAs: 'aeGroupSelect',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		NgClass,
		NgSelectModule,
		NgOptionHighlightModule,
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GroupSelectComponent),
			multi: true,
		},
	],
})
export class GroupSelectComponent
	implements OnInit, OnDestroy, ControlValueAccessor
{
	@HostBinding('class.custom-form-control') customFormControl = true;
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() multiple = false;
	@Input() allowClear = false;
	@Input() groupType = null;
	@Input() groups: GroupModel[];
	@Input() includedGroups: string[] = [];

	@Output() changeOutput = new EventEmitter<boolean>();
	@Output() clearOutput = new EventEmitter<boolean>();
	@Output() closeOutput = new EventEmitter<boolean>();
	@Output() removeOutput = new EventEmitter<boolean>();

	private _touchFunction: () => void;
	private _changeFunction: (value: Partial<string | string[]>) => void = () =>
		null;
	private _changeWatcher: Subscription;

	public control: FormControl<string | string[]>;

	@ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

	constructor(
		private fb: FormBuilder,
		private groupsStoreService: GroupsStoreService,
	) {}

	ngOnInit() {
		this.control = this.fb.control(null);

		this.ngSelect.openEvent.pipe(take(1)).subscribe(() => {
			this._touchFunction();
		});

		if (!this.groups) {
			this.groupsStoreService
				.groups$(this.groupType)
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe({
					next: (groups) => {
						this.groups = groups.sort(
							firstBy((x: GroupModel) => x.collection_name).thenBy(
								(x) => x.name,
							),
						);
					},
				});
		}
	}

	// Implementing ControlValueAccessor
	public writeValue(val: string | string[]): void {
		try {
			this.stopWatchForChange();
			this.control.setValue(val);
		} catch (e) {
			throw new Error(
				`GroupSelectComponent.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		if (isDisabled) this.control.disable();
		else this.control.enable();
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.control.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				this._changeFunction(val);
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	protected groupsSearchFn(term: string, item: GroupModel) {
		term = term.toLocaleLowerCase();
		return (
			item.name.toLocaleLowerCase().includes(term) ||
			item.collection_name.toLocaleLowerCase().includes(term)
		);
	}

	protected changeEvent = () => {
		this.changeOutput.emit(true);
	};

	protected clearEvent = () => {
		this.clearOutput.emit(true);
	};

	protected closeEvent = () => {
		this.closeOutput.emit(true);
	};

	protected removeEvent = () => {
		this.removeOutput.emit(true);
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
