import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { mergeStrings } from 'src/lib/utilities/array';
import { OrganizationModel } from '../../api/organizations/organization.model';
import { LocalStoreService } from '../local-store/local-store.service';
import { OrganizationsStoreService } from '../organizations-store/organizations-store.service';

@Injectable({
	providedIn: 'root',
})
export class ActiveOrgStoreService {
	private _unsubscribe = new AsyncSubject<null>();
	private _organizations = new BehaviorSubject<OrganizationModel[]>(undefined);
	private _currentOrg = new BehaviorSubject<OrganizationModel>(undefined);
	private _setCurrentOrg = new Subject<number>();

	constructor(
		private organizationsStoreService: OrganizationsStoreService,
		localStore: LocalStoreService,
	) {
		this.organizationsStoreService
			.organizations$()
			.pipe(takeUntil(this._unsubscribe))
			.subscribe({
				next: (x) => this._organizations.next(x),
				error: (errors) => console.error(mergeStrings(errors)),
			});

		combineLatest([
			this.organizations$,
			this._setCurrentOrg.asObservable(),
		]).subscribe(([orgs, currentOrg]) => {
			const foundOrg = orgs?.find(
				(x: OrganizationModel) => x.id === currentOrg,
			);

			if (foundOrg != null) {
				localStore.set('primaryOrgId', foundOrg.id);
				this._currentOrg.next(foundOrg);
			}
		});

		this.organizations$.pipe(first()).subscribe((x) => {
			const orgId = localStore.get('primaryOrgId', TypedStoreType.NUMBER);
			if (orgId != null) {
				const foundOrg = x?.find((o) => o.id === orgId);
				if (foundOrg != null) {
					this.setActiveOrganization(foundOrg.id);
					return;
				}
			}

			this.setActiveOrganization(x[0]);
		});
	}

	private get organizations$() {
		return this._organizations
			.asObservable()
			.pipe(filter((x) => x !== undefined));
	}

	get activeOrganization$() {
		return this._currentOrg.asObservable().pipe(
			filter((x) => x !== undefined),
			distinctUntilChanged((x, y) => x.id === y.id),
		);
	}

	public setActiveOrganization = (org: number | OrganizationModel): void => {
		let id: number;
		if (org instanceof OrganizationModel) {
			id = org.id;
		} else {
			id = org;
		}

		this._setCurrentOrg.next(id);
	};
}
