import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
	distinctUntilChanged,
	filter,
	map,
	switchMap,
	take,
	tap,
} from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { getEnvironment } from 'src/lib/environment/environment';
import { InitializationService } from 'src/lib/services/utility/initialization/initialization.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { tryParseInt } from 'src/lib/utilities/convert';
import { LocalStoreService } from '../../stores/local-store/local-store.service';
import { ParentsStoreService } from '../../stores/parents/parents-store.service';
import { PermissionStoreService } from '../../stores/permission-store/permission-store.service';
import { SessionStoreService } from '../../stores/session-store/session-store.service';
import { UserStoreService } from '../../stores/users/user/user-store.service';

@Injectable({
	providedIn: 'root',
})
export class ActiveStudentService {
	private _activeStudentSubject = new BehaviorSubject<number>(undefined);
	private _availableStudents = new BehaviorSubject<
		[id: number, name?: string][]
	>(undefined);

	private _initialized = false;

	constructor(
		initService: InitializationService,
		private parentsStore: ParentsStoreService,
		private permissionService: PermissionStoreService,
		private userStore: UserStoreService,
		localStore: LocalStoreService,
		private sessionStore: SessionStoreService,
	) {
		this.getActiveStudent$()
			.pipe(take(1))
			.subscribe(() => (this._initialized = true));

		if (initService.hasAnyRole('student')) {
			this.initializeSingleStudent(initService.permissionParams.user);
		} else if (initService.hasAnyRole('parent')) {
			this._initialized = true; // We have to stop other initializations from happening

			this.getActiveStudent$().subscribe((sId) => {
				localStore.set(
					getEnvironment().settings.startup.student.activeStudentSettingID,
					sId,
				);
			});

			this.parentsStore
				.students$(initService.permissionParams.user)
				.pipe(
					tap((x) =>
						this._availableStudents.next(x.map((y) => [y.uid, y.name])),
					),
					switchMap((x) => combineLatest([of(x), this._activeStudentSubject])),
					take(1),
				)
				.subscribe(([students, active]) => {
					if (active == null) {
						const storeSId: number = localStore.get(
							getEnvironment().settings.startup.student.activeStudentSettingID,
							TypedStoreType.NUMBER,
						);
						const storeStudent = students.find((s) => s.uid === storeSId)?.uid;

						this._activeStudentSubject.next(
							storeStudent ?? students[0]?.uid ?? null,
						);
					}
				});
		}
	}

	private initializeSingleStudent = (studentId: number | null) => {
		if (!this._initialized) {
			if (studentId == null) {
				this._availableStudents.next([]);
				this._activeStudentSubject.next(null);
			} else {
				this._availableStudents.next([[studentId]]);
				this._activeStudentSubject.next(studentId);

				this.userStore.userData$(studentId).subscribe((data) => {
					this._availableStudents.next([
						[
							studentId,
							`${
								data.profile.profile_preferred_name ??
								data.profile.profile_first_name
							} ${data.profile.profile_last_name}`,
						],
					]);
				});
			}
		}
	};

	public _ensureInitialized = (route: ActivatedRouteSnapshot) => {
		if (!this._initialized) {
			const hasParam = route.queryParamMap.has('initialStudentId');
			const hasStoreSetting = this.sessionStore.get(
				'active-student-service.initialStudentId',
				TypedStoreType.NUMBER,
			);

			if (hasParam || hasStoreSetting) {
				let studentId: number;
				if (hasParam) {
					studentId = tryParseInt(route.queryParamMap.get('initialStudentId'));
				}
				if (!studentId && hasStoreSetting) {
					studentId = hasStoreSetting;
				}

				if (studentId) {
					this.sessionStore.set(
						'active-student-service.initialStudentId',
						studentId,
					);

					this.permissionService
						.getFieldSet$({
							UserId: studentId,
						})
						.subscribe((permits) => {
							if (permits.canDo(Permits['access content'])) {
								this.initializeSingleStudent(studentId);
							} else {
								this.initializeSingleStudent(null);
							}
						});
				} else {
					this.initializeSingleStudent(null);
				}
			} else {
				this.initializeSingleStudent(null);
			}
		}
	};

	public getActiveStudent$ = (): Observable<number> => {
		return this._activeStudentSubject.pipe(
			distinctUntilChanged(),
			filter((x) => x !== undefined),
		);
	};

	public setActiveStudent$ = (studentId: number): Observable<number> => {
		return this._availableStudents.pipe(
			filter((x) => x !== undefined),
			take(1),
			map((students) => {
				const found = students.find((s) => s[0] === studentId);
				if (found) {
					this._activeStudentSubject.next(found[0]);
					return found[0];
				} else {
					throw new Error(
						`Student ID ${studentId} is not a permissable record`,
					);
				}
			}),
		);
	};

	public getAvailableStudents$ = () => {
		return this._availableStudents.pipe(filter((x) => x !== undefined));
	};
}
