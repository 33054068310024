import { AbstractControl } from '@angular/forms';
import { isValidDate } from 'src/lib/utilities/compare';
import { convertToDate } from 'src/lib/utilities/convert';
import { convertToCalendarDate } from 'src/lib/utilities/date';

export function createDateValidation(): (_: any) => any {
	return (ctrl: AbstractControl) => {
		const error = {
			date: true,
		};

		if (ctrl.value != null || ctrl.value === '') {
			const dateConverted = isValidDate(convertToDate(ctrl.value));
			if (dateConverted !== true) {
				return error;
			}
		}

		// No errors in date format
		return null;
	};
}

export function createMinCalendarDateValidation(minDate: any): (_: any) => any {
	return (ctrl: AbstractControl) => {
		if (ctrl.value == null) {
			return null;
		}
		const validDates = createDateValidation();
		const convertedMinDate = convertToDate(minDate);
		const valid =
			validDates(ctrl) == null && isValidDate(convertedMinDate) === true;
		const error = {
			minDate: {
				given: ctrl.value,
				min: convertedMinDate,
			},
		};

		if (
			valid &&
			convertToCalendarDate(convertToDate(ctrl.value)) <
				convertToCalendarDate(convertToDate(minDate))
		) {
			return error;
		}

		// Date greater than minDate
		return null;
	};
}

export function createMaxCalendarDateValidation(
	maxDate: Date,
): (_: any) => any {
	return (ctrl: AbstractControl) => {
		if (ctrl.value == null) {
			return null;
		}

		const validDates = createDateValidation();
		const convertedMaxDate = convertToDate(maxDate);
		const valid =
			validDates(ctrl) == null && isValidDate(convertedMaxDate) === true;
		const error = {
			maxDate: {
				given: ctrl.value,
				max: convertedMaxDate,
			},
		};

		if (
			valid &&
			convertToCalendarDate(convertToDate(ctrl.value)) >
				convertToCalendarDate(convertToDate(maxDate))
		) {
			return error;
		}

		// Date less than maxDate
		return null;
	};
}
