import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { ProgramFileModel } from './management/files-service/program-file.model';

export class OrganizationModel {
	public id: number;
	public is_active: boolean;
	public unique_id: string;
	public itype_shortcode: string;
	public title: string;
	public subtitle?: string;
	public state: string;
	public parent?: string;
	public parent_title?: string;
	public timezone?: string;
	@Transform(dateTransformer)
	public created: Date;
	public itype_id: number;
	public ready_for_students: boolean | number;

	is_structural?: boolean;
	active_admin_count?: number;
	enrolled_student_count?: number;
	created_timestamp?: number;
	files?: ProgramFileModel[];
	groups?: any[];
	institute_id?: number;
	institute_title?: string;
	parent_institute_title?: string;
	properties?: Record<string, unknown>;
}

export function OrganizationSearchModel_ClassTransform_Type() {
	return OrganizationSearchModel;
}

export class OrganizationSearchModel {
	@Type(OrganizationSearchModel_ClassTransform_Type)
	institutes: OrganizationModel[];
	count: number;
}
