import { Expose, Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';
import {
	MeetupMinutesModel,
	MeetupMinutesModel_ClassTransform_Type,
} from '../organizations/meetups/meetup-minutes.model';
import {
	TrackerUserPerformance,
	TrackerUserPerformance_ClassTransform_Type,
} from '../students/performance/student-performance-history-detailed.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class LATrackerClassPacingModel {
	@Transform(dateTransformer)
	start_date: Date;
	weeks: number;
}

export class LATrackerClassModel {
	id: number;
	title: string;
	assignment_count: number;
	assignment_completed: number;
	@Expose({ name: 'success' })
	grade: number;
	progress: number;
	notes: string;
	@Transform(dateTransformer)
	end_date: Date;
	@Type(LATrackerClassPacingModel_ClassTransform_Type)
	pacing: LATrackerClassPacingModel[];
	@Transform(dateTransformer)
	complete_date?: Date;
	@Transform(dateTransformer)
	counted_month?: Date;
	registration_type: string;
	pending_final: boolean;
	@Expose({ name: 'success_zero' })
	grade_zero: number;
	lms: string;
}

export class LATrackerItemModel {
	@Transform(integerTransformer)
	cohort_year: number;
	credits_remain: number;
	uid: number;
	user_link_id: number;
	@Transform(dateTransformer)
	access: Date;
	institute_id: number;
	timezone: string;
	title: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	assigned_uid: number;
	@Transform(dateTransformer)
	assigned_at: Date;
	profile_first_name: string;
	profile_preferred_name: string;
	profile_last_name: string;
	profile_student_year: string;
	credits: number;
	phone: number;
	phone_type: string;
	assigned_first: string;
	assigned_last: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	@Transform(dateTransformer)
	activated: Date;
	state_program: string;
	@Expose({ name: 'title' })
	program_title: string;
	@Expose({ name: 'parent_title' })
	program_parent_title: string;
	@Type(TrackerUserPerformance_ClassTransform_Type)
	performance_data: TrackerUserPerformance;
	is_1418: boolean;
	is_paused: boolean;
	counted_classes: number;
	@Type(MeetupMinutesModel_ClassTransform_Type)
	meetup_minutes: MeetupMinutesModel;
	@Type(LATrackerClassModel_ClassTransform_Type)
	classes: LATrackerClassModel[];
	@Type(LATrackerClassModel_ClassTransform_Type)
	completed_classes?: LATrackerClassModel[];
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	weekly_assignments: number;
	pending_finals: number;
	assigned_ac_first: string;
	assigned_ac_last: string;
	assigned_ac_uid: number;
	@Transform(dateTransformer)
	deactivated: Date;
	track: string;
	persistent_courses: number;
	graduation_request_status_title: string;
}

export function LATrackerClassModel_ClassTransform_Type() {
	return LATrackerClassModel;
}
export function LATrackerClassPacingModel_ClassTransform_Type() {
	return LATrackerClassPacingModel;
}
