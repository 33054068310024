export enum AttachementType {
	unknown = 0,
	image = 10,
	video = 20,
	generic = 30,
}

export class MessageAttachmentModel {
	private _mimetype: string;
	private _url: string;
	private _type: AttachementType;
	private _filename: string;

	get mimetype() {
		return this._mimetype;
	}
	set mimetype(value: string) {
		this._mimetype = value;

		if (this.mimetype == null || this.mimetype === '') {
			this._type = AttachementType.unknown;
		} else if (this.mimetype.indexOf('image') === 0) {
			this._type = AttachementType.image;
		} else if (this.mimetype.indexOf('video') === 0) {
			this._type = AttachementType.video;
		} else {
			this._type = AttachementType.generic;
		}
	}

	get url() {
		return this._url;
	}
	set url(value: string) {
		this._url = value;

		this._filename = this.url.slice(
			this.url.lastIndexOf('/') + 1,
			this.url.lastIndexOf('?') > 0 ? this.url.lastIndexOf('?') : undefined,
		);
	}

	get filename() {
		return this._filename;
	}

	get type() {
		return this._type;
	}
}

export function MessageAttachmentModel_ClassTransform_Type() {
	return MessageAttachmentModel;
}
