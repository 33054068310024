import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class CareerTrackerUserModel {
	uid: number;
	name: string;
}

export function CareerTrackerUserModel_ClassTransform_Type() {
	return CareerTrackerUserModel;
}

export class CareerTrackerModel {
	cohort_year: number;
	credits_remain: number;
	uid: number;
	user_link_id: number;
	@Transform(dateTransformer)
	access: Date;
	institute_id: number;
	timezone: string;
	title: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	assigned_uid: number;
	@Transform(dateTransformer)
	assigned_at: Date;
	profile_first_name: string;
	profile_preferred_name: string;
	profile_last_name: string;
	profile_student_year: string;
	phone: number;
	phone_type: string;
	assigned_first: string;
	assigned_last: string;
	exit_reason_title: string;
	@Transform(dateTransformer)
	deactivated_time: Date;
	career_placed: string;
	college_placed: string;
	college_plan_attend: boolean;
	career_help: boolean;
	career_desired: string;
	pathway_title: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	state_program: string;
	@Expose({ name: 'title' })
	program_title: string;
	@Expose({ name: 'parent_title' })
	program_parent_title: string;
	is_1418: boolean;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
}
