@if (pgTable) {
	<div class="modal-header">
		<h1 class="modal-title">Column Settings</h1>
		<ae-modal-mover />
		<button
			class="btn btn-outline-dark"
			title="Close"
			type="button"
			(click)="dismiss()"
		>
			<i aria-hidden="true" class="far fa-times"></i>
		</button>
	</div>
	<div class="modal-body pgtable-configuration-modal">
		<div class="form-group btn-icon-highlight-zone">
			<label
				for="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.active-config.picker"
			>
				Selected Group
			</label>
			<div class="d-flex">
				<div class="flex-grow-1 pe-2">
					<ng-select
						bindLabel="name"
						[clearable]="false"
						[items]="pgTable.userPreferences.savedConfigurations"
						labelForId="pgtable.{{
							pgTable.tableConfig.tableId
						}}.configcontrols.active-config.picker"
						[(ngModel)]="pgTable.userPreferences.activeConfiguration"
						(ngModelChange)="
							modal_TryLoadSavedConfiguration(
								pgTable.userPreferences.activeConfiguration.configId
							)
						"
					/>
				</div>
				<button
					class="btn btn-icon"
					title="Rename"
					type="button"
					id="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.active-config.edit"
					(click)="savedConfiguration_OpenEditModal(false)"
				>
					<i aria-hidden="true" class="fas fa-pen fa-fw"></i>
				</button>
				<button
					class="btn btn-icon"
					title="Create New"
					type="button"
					id="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.active-config.create"
					(click)="savedConfiguration_OpenEditModal(true)"
				>
					<i aria-hidden="true" class="fas fa-plus fa-fw"></i>
				</button>
				<button
					class="btn btn-icon"
					title="Delete"
					type="button"
					id="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.active-config.delete"
					(click)="savedConfiguration_OpenDeleteModal()"
				>
					<i aria-hidden="true" class="fas fa-trash fa-fw"></i>
				</button>
			</div>
		</div>
		<hr />
		<div class="form-group">
			<div class="d-flex btn-icon-highlight-zone">
				<h2 class="label-spacer flex-grow-1 h5">Select Visible Columns</h2>
				<button
					class="btn btn-icon flex-grow-0"
					title="Expand/Collapse All Style Rules"
					type="button"
					(click)="modal_ToggleAllStyleRuleExpanded()"
				>
					<i aria-hidden="true" class="fas fa-palette"></i>
				</button>
				<div class="column-action-bar-scroll-spacer"></div>
			</div>
			<overlay-scrollbars-dragdrop
				#columnScrollbar
				[options]="modal_ColumnSelectorScrollOptions"
			>
				<div
					cdkDropList
					cdkDropListLockAxis="y"
					[cdkDropListData]="modal_SortedColumns"
					(cdkDropListDropped)="drop($event)"
				>
					@for (column of modal_SortedColumns; track column) {
						<div #columnSelectorElement class="mb-1">
							<div
								cdkDrag
								class="column-action-bar w-100 btn-group btn-group-sm"
							>
								<div
									class="btn flex-grow-0"
									[ngClass]="
										modal_isColumnChecked(column) ? 'btn-info' : 'btn-white'
									"
								>
									<i aria-hidden="true" class="far fa-grip-lines"></i>
								</div>
								<label
									class="btn flex-grow-1 text-start m-0 d-flex align-items-center"
									for="pgtable.{{
										pgTable.tableConfig.tableId
									}}.configcontrols.column.{{ column.columnId }}"
									[ngClass]="
										modal_isColumnChecked(column) ? 'btn-info' : 'btn-white'
									"
								>
									<input
										class="screen-reader-text"
										type="checkbox"
										[checked]="modal_isColumnChecked(column)"
										[disabled]="column.locked"
										id="pgtable.{{
											pgTable.tableConfig.tableId
										}}.configcontrols.column.{{ column.columnId }}"
										(click)="
											pgTable.tc_Configuration_ToggleColumnVisibility(
												column.columnId
											)
										"
									/>
									<span
										class="unselectable pointer flex-grow-1 pgtable-configuration-column-name"
										[ngClass]="{
											'text-decoration-line-through': column.disabled,
										}"
									>
										<ng-container
											[ngTemplateOutlet]="column.headerTemplate.template"
										/>
										@if (newColumns.indexOf(column.columnId) !== -1) {
											<span title="New Column">
												<i
													aria-hidden="true"
													class="fas fa-sparkles fa-fw ms-1"
												></i>
											</span>
										}
									</span>
									@if (column.disabled) {
										<span title="Column currently disabled">
											<i aria-hidden="true" class="far fa-eye-slash"></i>
										</span>
									}
									@if (column.locked) {
										<span title="Column cannot be hidden">
											<i aria-hidden="true" class="far fa-lock"></i>
										</span>
									}
								</label>
								@if (modal_isColumnResized(column)) {
									<button
										class="btn flex-grow-0"
										title="Reset Column Sizing"
										type="button"
										[ngClass]="[
											modal_isColumnChecked(column) ? 'btn-info' : 'btn-white',
											undersizedColumns.indexOf(column.columnId) !== -1
												? 'flashing-btn'
												: '',
										]"
										(click)="modal_ResetColumnResizing(column)"
									>
										<i aria-hidden="true" class="far fa-arrows-h"></i>
									</button>
								}
								<button
									class="btn flex-grow-0"
									title="Style Rules"
									type="button"
									[ngClass]="
										modal_isColumnChecked(column) ? 'btn-info' : 'btn-white'
									"
									(click)="
										modal_ToggleStyleRuleExpanded(
											column.columnId,
											null,
											columnSelectorElement
										)
									"
								>
									<i
										aria-hidden="true"
										class="fa-palette"
										[ngClass]="
											modal_GetColumnStyleRules(column.columnId).length > 0
												? 'fas'
												: 'fal'
										"
									></i>
								</button>
								<div
									class="column-action-bar-scroll-spacer rounded-end"
									[ngClass]="
										modal_isColumnChecked(column) ? 'bg-info' : 'bg-white'
									"
								></div>
							</div>
							@if (modal_IsStyleRuleExpanded(column.columnId)) {
								<div
									class="column-rule-editor border px-3"
									[ngClass]="
										modal_isColumnChecked(column)
											? 'border-info'
											: 'border-white'
									"
								>
									@if (modal_GetColumnStyleRules(column.columnId).length > 0) {
										@for (
											rule of modal_GetColumnStyleRules(column.columnId);
											track rule;
											let i = $index
										) {
											<div class="d-flex align-items-center mt-3">
												<div class="flex-grow-1">
													<div class="d-flex align-items-center">
														<div>Cells have &nbsp;</div>
														<ng-select
															appendTo="body"
															aria-label="Style"
															bindLabel="name"
															bindValue="class"
															class="flex-grow-1"
															groupBy="group"
															[clearable]="false"
															[formControl]="rule.controls.class"
															[items]="columnStyleRuleClasses"
															labelForId="pgtable.{{
																pgTable.tableConfig.tableId
															}}.configcontrols.column-style-rules.{{
																column.columnId
															}}.{{ i }}.class"
														>
															<ng-template let-item="item" ng-option-tmp>
																<div
																	[ngClass]="
																		item.class +
																		' ' +
																		item.additionalSelectClasses
																	"
																>
																	{{ item.name }}
																</div>
															</ng-template>
														</ng-select>
													</div>
													<div>
														<ng-select
															appendTo="body"
															aria-label="Comparison"
															bindLabel="label"
															bindValue="value"
															[clearable]="false"
															[formControl]="rule.controls.comparison"
															[items]="columnStyleRuleComparisons"
															labelForId="pgtable.{{
																pgTable.tableConfig.tableId
															}}.configcontrols.column-style-rules.{{
																column.columnId
															}}.{{ i }}.comparison"
														/>
														@if (!rule.controls.value.disabled) {
															<input
																aria-label="Comparison Value"
																class="form-control"
																type="text"
																[formControl]="rule.controls.value"
																id="pgtable.{{
																	pgTable.tableConfig.tableId
																}}.configcontrols.column-style-rules.{{
																	column.columnId
																}}.{{ i }}.value"
															/>
														}
													</div>
												</div>
												<button
													class="btn btn-icon"
													title="Delete Rule"
													type="button"
													id="pgtable.{{
														pgTable.tableConfig.tableId
													}}.configcontrols.column-style-rules.{{
														column.columnId
													}}.{{ i }}.delete"
													(click)="modal_DeleteStyleRule(rule)"
												>
													<i aria-hidden="true" class="far fa-times"></i>
												</button>
											</div>
										}
									} @else {
										<div class="text-center text-muted mt-3">
											No Rules Defined
										</div>
									}
									<div class="my-3 text-center">
										<button
											class="btn btn-primary btn-sm"
											type="button"
											(click)="modal_AddColumnStyleRule(column.columnId)"
										>
											Add Rule
										</button>
									</div>
								</div>
							}
						</div>
					}
				</div>
			</overlay-scrollbars-dragdrop>
		</div>
		<h2 class="h5 screen-reader-text">Column Setting Additional Actions</h2>
		<div class="btn-group w-100">
			<button
				class="btn btn-outline-primary btn-sm"
				title="Select Default Columns"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.reset-controls.columns"
				(click)="pgTable.tc_Configuration_ResetColumnVisibility()"
			>
				<span aria-hidden="true" class="fa-stack">
					<i class="far fa-columns-3 fa-stack-1x"></i>
				</span>
				<span>Select Default Columns</span>
			</button>
			<button
				class="btn btn-outline-primary btn-sm"
				title="Select All Columns"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.reset-controls.columns"
				(click)="pgTable.tc_Configuration_SelectAllColumns()"
			>
				<span aria-hidden="true" class="fa-stack">
					<i class="far fa-grate fa-stack-1x"></i>
				</span>
				<span>Select All Columns</span>
			</button>
		</div>
		<div class="btn-group w-100 mt-2">
			<button
				class="btn btn-outline-primary btn-sm"
				title="Reset Style Rules"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.reset-controls.rules"
				(click)="modal_ResetStyleRules()"
			>
				<span aria-hidden="true" class="fa-stack">
					<i class="fas fa-palette fa-stack-1x"></i>
					<i class="fas fa-slash fa-lg fa-stack-1x"></i>
				</span>
				<span>Reset Style Rules</span>
			</button>
			<button
				class="btn btn-outline-primary btn-sm"
				title="Reset Column Sizing"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.reset-controls.resize"
				(click)="modal_ResetColumnResizing()"
			>
				<span aria-hidden="true" class="fa-stack">
					<i class="fas fa-arrows-h fa-lg fa-stack-1x"></i>
					<i class="fas fa-slash fa-lg fa-stack-1x"></i>
				</span>
				<span>Reset Column Sizing</span>
			</button>
			<button
				class="btn btn-outline-primary btn-sm"
				title="Reset Column Order"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.reset-controls.order"
				(click)="modal_ResetColumnOrder()"
			>
				<span aria-hidden="true" class="fa-stack">
					<i class="fas fa-sort-alt fa-lg fa-stack-1x"></i>
					<i class="fas fa-slash fa-lg fa-stack-1x"></i>
				</span>
				<span>Reset Column Order</span>
			</button>
		</div>
	</div>
	<div class="modal-footer">
		<button
			class="btn btn-secondary"
			type="button"
			id="pgtable.{{ pgTable.tableConfig.tableId }}.configcontrols.close"
			(click)="dismiss()"
		>
			Close
		</button>
	</div>
}
