import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { AbDrillDownAcMappedTranscriptModel } from './ab-drill-down-ac-mapped-transcripts.model';
import { AbDrillDownAcNoClassesModel } from './ab-drill-down-ac-no-classes.model';
import { AbDrillDownAcNoContactModel } from './ab-drill-down-ac-no-contact.model';
import { AbDrillDownAcStudentActivityViewsModel } from './ab-drill-down-ac-student-activity-views.model';
import { AbDrillDownGradChatModel } from './ab-drill-down-gradchat.model';
import { AbDrillDownLaContactAttemptModel } from './ab-drill-down-la-contact-attempts.model';
import {
	AbDrillDownTeacherFeedbackResponseModel,
	AbDrillDownTeacherFeedbackSearchArgument,
} from './ab-drill-down-teacher-feedback.model';
import { AbDrillDownTranscriptStudentModel } from './ab-drill-down-transcript-student.model';

@Injectable({
	providedIn: 'root',
})
export class AbDrillDownService {
	constructor(private httpClient: HttpClient) {}

	public getTeacherFeedback = (
		teacherId: number,
		args: PagingArgument<AbDrillDownTeacherFeedbackSearchArgument>,
	): Observable<AbDrillDownTeacherFeedbackResponseModel> => {
		return this.httpClient
			.post<AbDrillDownTeacherFeedbackResponseModel>(
				`/api/v1/activity-boards/teacher/details/${teacherId}/feedback`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(AbDrillDownTeacherFeedbackResponseModel, r),
					{ errorCode: 'A55590EF' },
				),
			);
	};

	public getTeacherGradChat = (
		teacherId: number,
	): Observable<AbDrillDownGradChatModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/teacher/details/${teacherId}/gradchat`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownGradChatModel,
							r.teacher_gradchat as any[],
						),
					{ errorCode: '1435B2C4' },
				),
			);
	};

	public getLaGradChat = (
		laUid: number,
	): Observable<AbDrillDownGradChatModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/local_advocate/details/${laUid}/gradchat`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(AbDrillDownGradChatModel, r.gradchat as any[]),
					{ errorCode: 'F53C77D2' },
				),
			);
	};

	public getAcGradChat = (
		acUid: number,
	): Observable<AbDrillDownGradChatModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/academic_coach/details/${acUid}/gradchat`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(AbDrillDownGradChatModel, r.gradchat as any[]),
					{ errorCode: '2E6767F6' },
				),
			);
	};

	public getAcNoContact = (
		acUid: number,
	): Observable<AbDrillDownAcNoContactModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/academic_coach/details/${acUid}/no_contact`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(AbDrillDownAcNoContactModel, r.details as any[]),
					{
						errorCode: '1745AF7B',
					},
				),
			);
	};

	public getAcStudentActivityViews = (
		acUid: number,
	): Observable<AbDrillDownAcStudentActivityViewsModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/academic_coach/details/${acUid}/student_activity`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownAcStudentActivityViewsModel,
							r.details as any[],
						),
					{ errorCode: '35946138' },
				),
			);
	};
	public getAcNoClasses = (
		acUid: number,
	): Observable<AbDrillDownAcNoClassesModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/academic_coach/details/${acUid}/no_classes`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(AbDrillDownAcNoClassesModel, r.details as any[]),
					{ errorCode: 'C5903BBD' },
				),
			);
	};

	public getAcMappedTranscripts = (
		acUid: number,
	): Observable<AbDrillDownAcMappedTranscriptModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/academic_coach/details/${acUid}/transcripts_mapped`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownAcMappedTranscriptModel,
							r.details as any[],
						),
					{ errorCode: 'C5903BBD' },
				),
			);
	};

	public getLaContactAttempts = (
		laUid: number,
	): Observable<AbDrillDownLaContactAttemptModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/activity-boards/local_advocate/details/${laUid}/contact_attempts`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownLaContactAttemptModel,
							r.details as any[],
						),
					{ errorCode: '5C3937D6' },
				),
			);
	};

	// Transcript Dropdown

	public getTranscriptEnteredDates = (
		uid: number,
		active_days: number,
	): Observable<AbDrillDownTranscriptStudentModel[]> => {
		const query = buildQueryString({
			active_days: active_days,
		});
		return this.httpClient
			.post<any>(
				`/api/v1/activity-boards/transcript/details/${uid}/entered${query}`,
				{},
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownTranscriptStudentModel,
							r.details as AbDrillDownTranscriptStudentModel[],
						),
					{
						errorCode: '778e9a10',
					},
				),
			);
	};

	public getTranscriptUploadedDates = (
		uid: number,
		active_days: number,
	): Observable<AbDrillDownTranscriptStudentModel[]> => {
		const query = buildQueryString({
			active_days: active_days,
		});
		return this.httpClient
			.post<any>(
				`/api/v1/activity-boards/transcript/details/${uid}/uploaded${query}`,
				{},
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownTranscriptStudentModel,
							r.details as AbDrillDownTranscriptStudentModel[],
						),
					{ errorCode: '621ca063' },
				),
			);
	};

	public getTranscriptVerifiedDates = (
		uid: number,
		active_days: number,
	): Observable<AbDrillDownTranscriptStudentModel[]> => {
		const query = buildQueryString({
			active_days: active_days,
		});
		return this.httpClient
			.post<any>(
				`/api/v1/activity-boards/transcript/details/${uid}/verified${query}`,
				{},
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownTranscriptStudentModel,
							r.details as AbDrillDownTranscriptStudentModel[],
						),
					{ errorCode: 'd3d98c87' },
				),
			);
	};

	public getTranscriptMappedDates = (
		uid: number,
		active_days: number,
	): Observable<AbDrillDownTranscriptStudentModel[]> => {
		const query = buildQueryString({
			active_days: active_days,
		});
		return this.httpClient
			.post<any>(
				`/api/v1/activity-boards/transcript/details/${uid}/mapped${query}`,
				{},
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownTranscriptStudentModel,
							r.details as AbDrillDownTranscriptStudentModel[],
						),
					{ errorCode: '218b65bd' },
				),
			);
	};

	public getTranscriptLockedDates = (
		uid: number,
		active_days: number,
	): Observable<AbDrillDownTranscriptStudentModel[]> => {
		const query = buildQueryString({
			active_days: active_days,
		});
		return this.httpClient
			.post<any>(
				`/api/v1/activity-boards/transcript/details/${uid}/locked${query}`,
				{},
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							AbDrillDownTranscriptStudentModel,
							r.details as AbDrillDownTranscriptStudentModel[],
						),
					{ errorCode: '218b65bd' },
				),
			);
	};
}
