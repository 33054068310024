import {
	Expose,
	instanceToInstance,
	instanceToPlain,
	plainToInstance,
	Transform,
	TransformationType,
	TransformFnParams,
	Type,
} from 'class-transformer';
import {
	FieldContainerModel,
	FieldContainerModel_ClassTransform_TransformFactory,
} from '../../../../utilities/api/patterns/dynamic-fields/field-container.model';
import {
	FieldSelectModel,
	FieldSelectModel_ClassTransform_TransformFactory,
} from '../../../../utilities/api/patterns/dynamic-fields/field-select.model';
import { FieldTypeValue } from '../../../../utilities/api/patterns/dynamic-fields/field-type-value.enum';
import {
	FieldModel,
	FieldModel_ClassTransform_Type,
} from '../../../../utilities/api/patterns/dynamic-fields/field.model';
import {
	NumberEnumOption,
	NumberEnumOption_ClassTransform_Type,
} from '../../../../utilities/api/patterns/dynamic-fields/number-enum-option.model';
import {
	StringEnumOption,
	StringEnumOption_ClassTransform_Type,
} from '../../../../utilities/api/patterns/dynamic-fields/string-enum-option.model';
import {
	OrganizationStudentEditAddressFieldModel,
	OrganizationStudentEditAddressFieldModel_ClassTransform_Type,
} from './organization-student-edit-address-field.model';

export function FieldSelectModelOrFieldModel_ClassTransform_TransformFactory(
	params: TransformFnParams,
) {
	if (params.value.type === FieldTypeValue.select) {
		return FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		)(params);
	} else {
		if (params.type === TransformationType.PLAIN_TO_CLASS) {
			return plainToInstance(FieldModel_ClassTransform_Type(), params.value);
		} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
			return instanceToPlain(params.value);
		} else if (params.type === TransformationType.CLASS_TO_CLASS) {
			return instanceToInstance(params.value);
		}
	}
}

export class OrganizationStudentEditTypedFieldsModel {
	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	tier?: FieldSelectModel<StringEnumOption>;

	@Type(FieldModel_ClassTransform_Type)
	suffix?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	first_name?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	middle_name?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	last_name?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	preferred_name?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	email?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	primary_parent?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	phone_number?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	additional_phone_numbers?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	birthdate?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	password?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	activated_date?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	estimated_credits_to_graduate?: FieldModel;

	@Transform(
		FieldContainerModel_ClassTransform_TransformFactory(
			OrganizationStudentEditAddressFieldModel_ClassTransform_Type,
		),
	)
	address?: FieldContainerModel<OrganizationStudentEditAddressFieldModel>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	grade?: FieldSelectModel<StringEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	gender?: FieldSelectModel<StringEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	attendance_class?: FieldSelectModel<StringEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	attendance_type?: FieldSelectModel<StringEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	iep_504?: FieldSelectModel<NumberEnumOption>;

	@Expose({ name: 'ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	race?: FieldSelectModel<StringEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	academic_coach?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	administrator?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	assistant?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	attendance_coach?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	counselor?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	graduation_alliance_counselor?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	local_advocate?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	enrollment_counselor?: FieldSelectModel<NumberEnumOption>;

	@Transform(FieldSelectModelOrFieldModel_ClassTransform_TransformFactory)
	student_location?: FieldSelectModel<StringEnumOption> | FieldModel;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	cohort_year?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			NumberEnumOption_ClassTransform_Type,
		),
	)
	graduation_plan?: FieldSelectModel<NumberEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	district_residence?: FieldSelectModel<StringEnumOption>;

	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	timezone?: FieldSelectModel<StringEnumOption>;

	throttle_status?: FieldSelectModel<StringEnumOption>;

	@Type(FieldModel_ClassTransform_Type)
	enrollment_notes?: FieldModel;
}

export function OrganizationStudentEditTypedFieldsModel_ClassTransform_Type() {
	return OrganizationStudentEditTypedFieldsModel;
}
