<div [aeModalHideUntil]="agreementsForm"></div>

@if (agreementsForm) {
	<div class="modal-header">
		<h1 class="modal-title">Agreement Update</h1>
		<ae-modal-close-timeout
			delayMessage="You can close but the Agreement Update will continue to prompt again until all agreements have been confirmed"
			id="prompt.agreements.modal.component.times.close"
			[countdown]="data?.type === 'Gate'"
			[uid]="uid"
			(closeAction)="activeModal.dismiss()"
		/>
	</div>
	<div class="modal-body">
		<p>
			Hi <span class="fw-bold">{{ uid | userName }}</span
			>, as part of our commitment to be transparent on how we collect and use
			data in our systems, we will periodically ask you to review and agree to
			our
			@for (agreement of agreements; track agreement; let i = $index) {
				<span>
					@if (agreements.length > 0 && i === agreements.length - 1) {
						<span> and </span>
					}
					{{ agreement.title }}
					@if (agreements.length > 2 && i !== agreements.length - 1) {
						<span>, </span>
					}
				</span>
			}
			. If we make any changes to them we will notify you accordingly.
		</p>
		<p>
			By clicking the links below, you are acknowledging your review and consent
			to these important agreements.
		</p>
		@for (agreement of agreements; track agreement; let i = $index) {
			<div class="mb-3">
				<button
					class="btn btn-link btn-text d-block"
					type="button"
					(click)="viewAgreement(agreement)"
				>
					{{ agreement.title }}
				</button>
				@if (!agreement.completed) {
					<ae-input-checkbox
						[formControl]="agreementsForm.at(i)"
						id="prompt.agreements.modal.component.agreements.{{ i }}"
					>
						I Agree
					</ae-input-checkbox>
				}
			</div>
		}
	</div>
	<div class="modal-footer flex-nowrap">
		<button
			class="btn btn-primary text-nowrap"
			position="top"
			tooltipClass="tooltip-danger"
			type="button"
			[disabled]="!agreementsForm.valid"
			(click)="complete()"
		>
			<ae-wait-spinner [saving]="saving"> Ok </ae-wait-spinner>
		</button>
	</div>
}
