import { Type } from 'class-transformer';
import { AbstractActivityBoardResponseModel } from '../abstract-activity-board-response.model';

export class ActivityBoardLaModel {
	local_advocate_uid: number;
	local_advocate_name: string;
	login_days: number;
	students_count: number;
	students_with_2_counted_courses: number;
	students_with_2_completed_courses: number;
	students_with_120_meetup_minutes: number;
	contact_attempts: number;
	contact_attempts_this_week: number;
	gradchat_messages_sent: number;
}

export function ActivityBoardLaModel_ClassTransformType() {
	return ActivityBoardLaModel;
}

export class ActivityBoardLaResponseModel extends AbstractActivityBoardResponseModel<ActivityBoardLaModel> {
	@Type(ActivityBoardLaModel_ClassTransformType)
	activity_board: ActivityBoardLaModel[];
}
