import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { ChannelMergeArgument } from './channel-merge.argument';
import { ChannelArgument } from './channel.argument';
import { ChannelModel, ChannelsResponse } from './channel.model';

@Injectable({
	providedIn: 'root',
})
export class ChannelsService {
	constructor(private httpClient: HttpClient) {}

	public getChannels = (
		directoryId: string,
		skip: number,
		take: number,
	): Observable<ChannelsResponse> => {
		const qs = buildQueryString({
			skip,
			take,
		});

		return this.httpClient
			.get<any>(`/api/v1/gradchat/${directoryId}/channels${qs}`)
			.pipe(map((r) => plainToInstance(ChannelsResponse, r)));
	};

	public getChannel = (
		directoryId: string,
		channelId: string | number,
	): Observable<ChannelModel> => {
		return this.httpClient
			.get<ChannelModel>(`/api/v1/gradchat/${directoryId}/channel/${channelId}`)
			.pipe(map((r) => plainToInstance(ChannelModel, r)));
	};

	public createChannel = (
		directoryId: string,
		args: ChannelArgument,
	): Observable<ChannelModel> => {
		return this.httpClient
			.post<ChannelModel>(
				`/api/v1/gradchat/${directoryId}/channel/create`,
				args,
			)
			.pipe(map((r) => plainToInstance(ChannelModel, r)));
	};

	public leaveChannel = (
		directoryId: string,
		channelId: number,
	): Observable<any> => {
		return this.httpClient.post(
			`/api/v1/gradchat/${directoryId}/channel/${channelId}/leave`,
			undefined,
		);
	};

	public archiveChannel = (
		directoryId: string,
		channelId: number,
	): Observable<any> => {
		return this.httpClient.post(
			`/api/v1/gradchat/${directoryId}/channel/${channelId}/archive`,
			undefined,
		);
	};

	public mergeChannel = (
		directoryId: string,
		channelId: number,
		args: ChannelMergeArgument,
	): Observable<any> => {
		return this.httpClient.post(
			`/api/v1/gradchat/${directoryId}/channel/${channelId}/merge`,
			args,
		);
	};

	public blockChannel = (
		directoryId: string,
		channelId: number,
	): Observable<any> => {
		return this.httpClient.post(
			`/api/v1/gradchat/${directoryId}/channel/${channelId}/block`,
			undefined,
		);
	};

	public setChannelSmsFallbackEnabled = (
		directoryId: string,
		channelId: number,
		enabled: boolean,
	): Observable<any> => {
		return this.httpClient.post(
			`/api/v1/gradchat/${directoryId}/channel/${channelId}/sms_fallback`,
			{
				enabled: enabled,
			},
		);
	};
}
