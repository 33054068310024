@if (parsedSsoLink != null) {
	<a
		class="btn btn-dark btn-block btn-lg fw-bold sso-btn py-2"
		target="_self"
		[href]="parsedSsoLink"
		(click)="saveUniqueId()"
	>
		<div class="icon-container">
			<img alt="" role="presentation" [src]="ssoImage" />
		</div>
		<div>Sign in with {{ data.type | titlecase }}</div>
	</a>
}
