import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class AbDrillDownAcStudentActivityViewsModel {
	student_uid: number;
	student_name: string;
	@Transform(dateTransformer)
	date_assigned: Date;
	@Transform(dateTransformer)
	date_of_view: Date;
	viewed_page_id: string;
	viewed_section_id: string;
	date_count_views: number;
}
