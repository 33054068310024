import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	StudentStateTestEventModel,
	StudentStateTestModel,
} from 'src/lib/services/api/students/tests/student-state-test.model';
import { StudentsTestsService } from 'src/lib/services/api/students/tests/students-tests.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentStateTestStoreService {
	private _stateTestEventsCache = new BehaviorCache<
		number,
		StudentStateTestEventModel[]
	>(
		(uid) => this.studentsTestsService.getAvailableTestInstances(uid, true),
		'StudentStateTestStoreService StateTestEventsCache',
		() => [],
	);

	private _studentStateTestCache = new BehaviorCache<
		number,
		StudentStateTestModel[]
	>(
		(uid) =>
			this.studentsTestsService.getTestResults(uid, {
				skip: 0,
				take: 100,
			}),
		'StudentStateTestStoreService StudentStateTestCache',
	);

	constructor(private studentsTestsService: StudentsTestsService) {}

	/**
	 * Test Events
	 */
	public stateTestEvents$ = (
		uid: number,
	): Observable<StudentStateTestEventModel[]> => {
		return this._stateTestEventsCache.getCache(uid);
	};

	public refreshStateTestEvents = (uid: number): Promise<boolean> => {
		return this._stateTestEventsCache.fetchData(uid, true);
	};

	public clearStateTestEvents = (uid: number) => {
		this._stateTestEventsCache.clearData(uid);
	};

	/**
	 * Tests
	 */
	public studentTests$ = (uid: number): Observable<StudentStateTestModel[]> => {
		return this._studentStateTestCache.getCache(uid);
	};

	public refreshStudentTests = (uid: number): Promise<boolean> => {
		return this._studentStateTestCache.fetchData(uid, true);
	};
}
