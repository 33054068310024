import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';

import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	PaperworkManagerBulkExportCreateArgument,
	PaperworkManagerBulkExportModel,
	PaperworkManagerBulkExportResponseModel,
} from './models/paperwork-manager-bulk-export.model';
import {
	PaperworkManagerCurrentResponseModel,
	PaperworkManagerCurrentSearchArgument,
	PaperworkManagerExpireArgument,
	PaperworkManagerNotifyStaffArgument,
} from './models/paperwork-manager-current.model';
import {
	PaperworkManagerBulkRequestArgument,
	PaperworkManagerBulkSendRequestModel,
	PaperworkManagerProgramPaperworkOptionModel,
	PaperworkManagerReEntryRequirementsResponseModel,
	PaperworkManagerRequirementsResponseModel,
	PaperworkManagerRequirementsSearchArgument,
	PaperworkManagerSetGateArgument,
} from './models/paperwork-manager-requirements.model';

@Injectable({
	providedIn: 'root',
})
export class PaperworkManagerService {
	constructor(private httpClient: HttpClient) {}

	public getEnrollmentRequirements = (
		args: PagingArgument<PaperworkManagerRequirementsSearchArgument>,
	): Observable<PaperworkManagerRequirementsResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/tracker/paperwork/requirements`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkManagerRequirementsResponseModel, r),
					{
						errorCode: '98566B45',
					},
				),
			);
	};

	public getReEntryRequirements = (
		args: PagingArgument<PaperworkManagerRequirementsSearchArgument>,
	): Observable<PaperworkManagerReEntryRequirementsResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/tracker/paperwork/reentry_requirements`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							PaperworkManagerReEntryRequirementsResponseModel,
							r,
						),
					{
						errorCode: '4BF09922',
					},
				),
			);
	};

	public getCurrentPaperwork = (
		args: PagingArgument<PaperworkManagerCurrentSearchArgument>,
	): Observable<PaperworkManagerCurrentResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/tracker/paperwork/current`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkManagerCurrentResponseModel, r),
					{
						errorCode: '254C356A',
					},
				),
			);
	};

	public notifyStaffPaperwork = (
		args: PaperworkManagerNotifyStaffArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/notify`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D94E13D2',
				}),
			);
	};

	public setPaperworkGate = (
		args: PaperworkManagerSetGateArgument[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/gate`, { paperwork_gate: args })
			.pipe(
				mapResponse((r) => r, {
					errorCode: '9CB49D29',
				}),
			);
	};

	public bulkExpirePaperwork = (
		args: PaperworkManagerExpireArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/expire`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '1573819E',
				}),
			);
	};

	public viewProgramPaperwork = (
		program_id: number,
		include_archived: boolean = false,
	): Observable<PaperworkManagerProgramPaperworkOptionModel[]> => {
		return this.httpClient
			.post<any>(`/api/v1/paperwork/search`, {
				program_id,
				include_archived,
			})
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							PaperworkManagerProgramPaperworkOptionModel,
							r.paperwork as any[],
						),
					{
						errorCode: 'A89D1FA5',
					},
				),
			);
	};

	// Postman: Paperwork Request - Bulk Create
	public bulkRequestPaperwork = (
		args: PaperworkManagerBulkRequestArgument,
	): Observable<PaperworkManagerBulkSendRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/request`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '756E37D5',
				}),
			);
	};

	/*****************/
	/* Bulk Exporter */
	/*****************/
	public getBulkExportList = (
		args: PagingArgument,
	): Observable<PaperworkManagerBulkExportResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/bulk_export/history`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkManagerBulkExportResponseModel, r),
					{
						errorCode: 'B34CD2D0',
					},
				),
			);
	};

	public createBulkExport = (
		args: PaperworkManagerBulkExportCreateArgument,
	): Observable<PaperworkManagerBulkExportModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/bulk_export`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkManagerBulkExportModel, r.export),
					{
						errorCode: 'F683B341',
					},
				),
			);
	};

	public cancelBulkExport = (id: number): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/paperwork/bulk_export/${id}/cancel`, {})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '6A7F2E28',
				}),
			);
	};

	public getBulkHistoryRequest = (id: number): Observable<any> => {
		return this.httpClient
			.get<any>(`/api/v1/students/paperwork/bulk_export/${id}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(PaperworkManagerBulkExportModel, r.export),
					{
						errorCode: '74A8D77B',
					},
				),
			);
	};
}
