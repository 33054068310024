<div class="modal-header">
	<h1 class="modal-title">
		<div>{{ title }}</div>
		<div class="modal-subtitle">
			{{ studentId | userName }}
		</div>
	</h1>
	<button
		class="btn btn-outline-dark"
		id="student.quick.log.meetup.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.close()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<div class="alert alert-info" role="alert">
		Time entered is based on timezone {{ hostTimezone }}.
	</div>
	<div *aeSpinWhile="loading" [formGroup]="addFormGroup">
		<div aeGroupValidation class="form-group">
			<label
				class="w-100"
				for="student.quick.log.meetup.modal.component.form.date"
			>
				<div class="d-flex">
					<div class="flex-grow-1">Date*</div>
					<div>
						{{ addFormGroup.controls.date.value | date: 'EEEE, MM/dd/yyyy' }}
					</div>
				</div></label
			>

			<ae-single-date-picker
				formControlName="date"
				id="student.quick.log.meetup.modal.component.form.date"
				[maxDate]="currentDate"
				[placeholder]="'mm/dd/yyyy'"
			/>

			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Date is required
				</ng-template>
			</ae-group-validation-display>
		</div>

		<div class="form-group row" formGroupName="time">
			<div aeGroupValidation class="col-sm-6">
				<label for="student.quick.log.meetup.modal.component.form.start">
					Arrival*
				</label>

				<input
					class="form-control"
					formControlName="start"
					id="student.quick.log.meetup.modal.component.form.start"
					type="time"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Arrival Time is required
					</ng-template>
				</ae-group-validation-display>
			</div>

			<div aeGroupValidation class="col-sm-6 col">
				<label for="student.quick.log.meetup.modal.component.form.end_time">
					Departure*
				</label>

				<input
					class="form-control"
					formControlName="end"
					id="student.quick.log.meetup.modal.component.form.end_time"
					type="time"
				/>

				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Departure Time is required
					</ng-template>
				</ae-group-validation-display>
			</div>
			@if (addFormGroup.controls.time.hasError('invalid_time')) {
				<div class="text-danger col-12 fs-sm">
					Start/End Time must be before
					<ae-current-time
						class="text-uppercase"
						format="{hour}:{minute-pad} {ampm}"
						hoverFormat="{timezone} ({offset})"
						[timezone]="hostTimezone"
					/>
				</div>
			}
			@if (addFormGroup.controls.time.hasError('invalid_range')) {
				<div class="text-danger col-12">Start must be before End time</div>
			}
			<div class="col-12 mt-3">
				<ae-input-checkbox
					[formControl]="addFormGroup.controls.time.controls.time_confirmed"
				>
					I confirm student attended for {{ getTime() }} minutes*
				</ae-input-checkbox>
			</div>
		</div>

		<div aeGroupValidation class="form-group">
			<label for="student.quick.log.meetup.modal.component.form.purpose">
				Purpose*
			</label>

			<ng-select
				bindLabel="label"
				bindValue="value"
				formControlName="purpose"
				[items]="purposeOptions"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Purpose is required
				</ng-template>
			</ae-group-validation-display>
		</div>

		<div class="form-group">
			<label for="student.quick.log.meetup.modal.component.form.type">
				Setting*
			</label>
			<div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						formControlName="type"
						id="student.quick.log.meetup.modal.component.type.virtual"
						type="radio"
						value="virtual"
					/>
					<label
						class="form-check-label"
						for="student.quick.log.meetup.modal.component.type.virtual"
					>
						<i aria-hidden="true" class="fas fa-house-laptop"></i> Virtual
					</label>
				</div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						formControlName="type"
						id="student.quick.log.meetup.modal.component.type.in_person"
						type="radio"
						value="physical"
					/>
					<label
						class="form-check-label"
						for="student.quick.log.meetup.modal.component.type.in_person"
					>
						<i aria-hidden="true" class="fas fa-people-line"></i> In Person
					</label>
				</div>
			</div>
		</div>

		@if (addFormGroup.controls.link.enabled) {
			<div aeGroupValidation class="form-group">
				<label for="student.quick.log.meetup.modal.component.form.link">
					Link
				</label>
				<input
					class="form-control"
					formControlName="link"
					id="student.quick.log.meetup.modal.component.form.link"
					type="text"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="url"> Invalid URL </ng-template>
				</ae-group-validation-display>
			</div>
		}

		@if (addFormGroup.controls.address.enabled) {
			<div class="form-group">
				<div class="row">
					<div aeGroupValidation class="form-group col-12">
						<label for="student.quick.log.meetup.modal.component.address.street"
							>Street Address*</label
						>
						<input
							autocomplete="off"
							class="form-control"
							id="student.quick.log.meetup.modal.component.address.street"
							type="text"
							[formControl]="addFormGroup.controls.address.controls.street"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								Street is required
							</ng-template>
						</ae-group-validation-display>
					</div>
					<div class="form-group col-12">
						<label
							for="student.quick.log.meetup.modal.component.address.street2"
							>Street 2</label
						>
						<input
							aeInputTrim
							autocomplete="off"
							class="form-control"
							id="student.quick.log.meetup.modal.component.address.street2"
							type="text"
							[formControl]="addFormGroup.controls.address.controls.street2"
						/>
					</div>
					<div aeGroupValidation class="form-group col-lg-9">
						<label for="student.quick.log.modal.component.address.city"
							>City*</label
						>
						<input
							autocomplete="off"
							class="form-control"
							id="student.quick.log.modal.component.address.city"
							type="text"
							[formControl]="addFormGroup.controls.address.controls.city"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								City is required
							</ng-template>
						</ae-group-validation-display>
					</div>
					<div aeGroupValidation class="form-group col-lg-3">
						<label for="student.quick.log.modal.component.address.state"
							>State*</label
						>
						<ng-select
							bindLabel="key"
							bindValue="key"
							labelForId="student.quick.log.modal.component.state"
							[clearable]="false"
							[formControl]="addFormGroup.controls.address.controls.state"
							[items]="stateOptions"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								State is required
							</ng-template>
						</ae-group-validation-display>
					</div>
				</div>
			</div>
		}

		<div class="form-group">
			<label
				for="student.quick.log.meetup.modal.component.form.gathering_type.meetup"
			>
				Meetup Type*
			</label>
			<div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						formControlName="gathering_type"
						id="student.quick.log.meetup.modal.component.gathering_type.one_one"
						type="radio"
						value="one_one"
					/>
					<label
						class="form-check-label"
						for="student.quick.log.meetup.modal.component.gathering_type.one_one"
					>
						{{ meetupTitles.one_one }}
					</label>
				</div>

				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						formControlName="gathering_type"
						id="student.quick.log.meetup.modal.component.gathering_type.one_one_strong_start"
						type="radio"
						value="one_one_strong_start"
					/>
					<label
						class="form-check-label"
						for="student.quick.log.meetup.modal.component.gathering_type.one_one_strong_start"
					>
						{{ meetupTitles.one_one_strong_start }}
					</label>
				</div>
			</div>
		</div>

		@if (addFormGroup.controls.study_plan.enabled) {
			<div aeGroupValidation class="form-group">
				<label for="student.quick.log.meetup.modal.component.form.study_plan">
					Student's Study Plan*
				</label>
				<textarea
					class="form-control"
					formControlName="study_plan"
					id="student.quick.log.meetup.modal.component.form.study_plan"
					rows="3"
				></textarea>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Students Study Plan is required
					</ng-template>
					<ng-template aeValidationError="maxlength">
						Study Plan must be less than 64 characters long
					</ng-template>
				</ae-group-validation-display>
			</div>
		}

		<div aeGroupValidation class="form-group">
			<label for="student.quick.log.meetup.modal.component.form.barriers">
				What barriers is this student currently facing?
			</label>

			<ng-select formControlName="barriers" [items]="barrierOptions" />

			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="maxlength">
					Resources must be less than 64 characters long
				</ng-template>
			</ae-group-validation-display>
		</div>

		<div aeGroupValidation class="form-group">
			<label for="student.quick.log.meetup.modal.component.form.resources">
				What resources were offered?
			</label>
			<input
				class="form-control"
				formControlName="resources"
				id="student.quick.log.meetup.modal.component.form.resources"
				type="text"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="maxlength">
					Resources must be less than 64 characters long
				</ng-template>
			</ae-group-validation-display>
		</div>

		<div class="form-group">
			<label for="student.quick.log.meetup.modal.component.description">
				Additional Notes
			</label>
			<textarea
				class="form-control"
				formControlName="description"
				id="student.quick.log.meetup.modal.component.description"
				rows="4"
			>
			</textarea>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="student.quick.log.meetup.modal.component.cancel"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Cancel
	</button>
	<button
		class="btn btn-primary"
		id="student.quick.log.meetup.modal.component.save"
		type="button"
		[disabled]="loading || saving || !addFormGroup?.valid"
		(click)="save()"
	>
		<ae-wait-spinner [saving]="saving">Log</ae-wait-spinner>
	</button>
</div>
