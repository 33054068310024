import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class LoginProgramModel {
	institute_title: string;
	institute_unique_id: string;
}

export interface LoginProgramSearchArgument {
	institute_title: PagingSearchFieldArgument;
	institute_unique_id: PagingSearchFieldArgument;
}
