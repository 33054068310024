<div
	class="sidenav-tabber-outer-container"
	[ngClass]="{ 'right-side-enabled': rightSideInfoConfig }"
>
	<div
		#sidenavTabberContainer
		class="sidenav-tabber-container tabber-container-{{ size }}"
		[ngClass]="{ minimized: collapsed, 'animation-on': animationOn }"
	>
		@if (!isStudentApp) {
			<button
				#collapseButton
				aria-hidden="true"
				class="btn tabber-collapse-btn"
				type="button"
				id="side.nav.tabber.component.{{ id }}.collapse.btn"
				title="{{ collapsed ? 'Expand' : 'Collapse' }}"
				(click)="toggleCollapse(collapseButton)"
				(mouseenter)="buttonEnter()"
				(mouseleave)="buttonLeave()"
			>
				<i
					aria-hidden="true"
					class="fas text-primary fa-lg"
					[ngClass]="
						collapsed ? 'fa-caret-square-right' : 'fa-caret-square-left'
					"
				></i>
			</button>
		}
		<div class="sidenav-tabber-cover">
			<div class="sidenav">
				<div
					id="side.nav.tabber.component.{{ id }}.tablist.title"
					[ngClass]="{ 'sidebar-nav-title': !customTitle }"
				>
					<ng-content />
				</div>
				<nav *aeSpinWhile="tabs == null; spinClass: 'fa-lg'">
					<div
						aria-orientation="vertical"
						class="sidenav-tablist"
						role="tablist"
						tabindex="-1"
						attr.aria-controls="side.nav.tabber.component.{{ id }}.tabpanel"
						id="side.nav.tabber.component.{{ id }}.tablist"
					>
						@for (tab of visibleTabs; track tab) {
							<a
								#sidenavTab
								class="sidebar-tab"
								queryParamsHandling="preserve"
								role="tab"
								routerLinkActive="active"
								attr.aria-controls="side.nav.tabber.component.{{ id }}.tabpanel"
								[attr.aria-selected]="selectedTabCtrl.value === tab.path"
								id="side.nav.tabber.component.{{ id }}.tabitem.{{ tab.path }}"
								[routerLink]="[tab.path]"
								[tabindex]="selectedTabCtrl.value === tab.path ? 0 : -1"
								(keydown)="onKeyDown($event)"
							>
								{{ tab.name }}
							</a>
						}
					</div>

					<label
						class="screen-reader-text"
						for="side.nav.tabber.component.{{ id }}.tabselect"
						>Selected Tab</label
					>
					<ng-select
						bindLabel="name"
						bindValue="path"
						class="sidebar-tab-select"
						[clearable]="false"
						[formControl]="selectedTabCtrl"
						[items]="visibleTabs"
						labelForId="side.nav.tabber.component.{{ id }}.tabselect"
					/>
				</nav>
			</div>
		</div>
		<div class="tabber-hover-mouse-assist"></div>
	</div>

	<section
		aeSizeMonitor
		aria-labelledby="sidenav-tabber.component.tab-header"
		class="sidenav-content-container"
		role="tabpanel"
		tabindex="0"
		id="side.nav.tabber.component.{{ id }}.tabpanel"
		[ngClass]="{ 'hide-overflow': hideOverflow }"
	>
		<h2 class="screen-reader-text" id="sidenav-tabber.component.tab-header">
			@if (activatedTab) {
				{{ activatedTab.name }}
			} @else {
				Tab is loading...
			}
		</h2>
		<ng-container *aeSpinWhile="activatedTab == null">
			@if (activatedTab.hasPermission) {
				<router-outlet />
			} @else {
				<div class="text-center mt-5 fs-3">
					You do not have permission to view this tab
				</div>
			}
		</ng-container>
		<div class="skip-links-container skip-links-bottom-left">
			<a aeAnchorLink href="#side.nav.tabber.component.{{ id }}.tablist">
				Back To Tab Navigation
			</a>
			<a aeAnchorLink href="#main-skip-links">Back To Page Start</a>
		</div>
	</section>

	@if (rightSideInfoConfig) {
		<aside class="right-side-container">
			<ae-rightside-info-floater
				id="{{ id }}.info.floater"
				[rightSideInfoConfig]="rightSideInfoConfig"
			/>
		</aside>
	}
</div>
