import { Type } from 'class-transformer';

import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { StudentEventModel } from '../../../students/events/student-event.model';

export class ProgramAdminNotificationModel extends StudentEventModel {}

export function ProgramAdminNotificationModel_ClassTransform_Type() {
	return ProgramAdminNotificationModel;
}

export class ProgramAdminNotificationListModel {
	count: number;
	@Type(ProgramAdminNotificationModel_ClassTransform_Type)
	results: ProgramAdminNotificationModel[];
}

export interface AdminNotificationHistorySearchArgument {
	subject: string;
	student_name: string;
	category: PagingSearchFieldArgument;
	medium: string;
	updated: PagingSearchFieldArgument;
}
