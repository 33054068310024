import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { SessionStoreService } from '../../session-store/session-store.service';

const gabbyMessageDraftStoreKey = 'gabby-message-draft-store-key';

@Injectable({
	providedIn: 'root',
})
export class MessageDraftStoreService {
	private _draftStateMap = new Map<string, boolean>();
	private _draftMap = new Map<string, BehaviorSubject<any>>();
	private _draftStateSubject = new Subject<null>();

	constructor(private sessionStore: SessionStoreService) {
		this.init();
	}

	public getDraft$ = <T>(channelId: string | number): Observable<T> => {
		return this.getDraftSubject$(channelId).asObservable();
	};

	public updateDraft = <T>(channelId: string | number, value: T) => {
		this.getDraftSubject$(channelId).next(value);
	};

	public hasDraft = (channelId: string | number): boolean => {
		return this._draftStateMap.get(`${channelId}`);
	};

	public draftStateUpdated$ = () => this._draftStateSubject.asObservable();

	private getDraftSubject$ = (
		channelId: string | number,
		defaultValue: any = null,
	) => {
		const sChannelId = `${channelId}`;
		if (!this._draftMap.has(sChannelId)) {
			const sub = new BehaviorSubject<any>(defaultValue);

			sub.pipe(skip(1), debounceTime(500)).subscribe((s) => {
				const storeObject = this.sessionStore.get(
					gabbyMessageDraftStoreKey,
					TypedStoreType.OBJECT,
					{},
				);
				storeObject[sChannelId] = s;
				this.sessionStore.set(gabbyMessageDraftStoreKey, storeObject);

				const oldState = this._draftStateMap.get(sChannelId);
				const newState = s != null;
				this._draftStateMap.set(sChannelId, newState);
				if (oldState !== newState) {
					this._draftStateSubject.next(null);
				}
			});

			this._draftMap.set(sChannelId, sub);
		}

		return this._draftMap.get(sChannelId);
	};

	private init = () => {
		const storeObject = this.sessionStore.get(
			gabbyMessageDraftStoreKey,
			TypedStoreType.OBJECT,
			{},
		);
		for (const k in storeObject) {
			if (storeObject.hasOwnProperty(k)) {
				this.getDraftSubject$(k, storeObject[k]);
				this._draftStateMap.set(k, storeObject[k] != null);
			}
		}
	};
}
