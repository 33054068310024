import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class SupportingPaperworkModel {
	name: string;
	url: string;
}

export function SupportingPaperworkModel_ClassTransform_Type() {
	return SupportingPaperworkModel;
}

export class AssociatedPaperworkModel {
	id: number;
	file_url: string;
	completed_file_url: string;
	@Type(SupportingPaperworkModel_ClassTransform_Type)
	supporting_files: SupportingPaperworkModel[];
	title: string;
	type_title: string;
}

export function AssociatedPaperworkModel_ClassTransform_Type() {
	return AssociatedPaperworkModel;
}

export class PaperworkApprovalModel {
	id: number;
	status: string;
	signer_email: string;
	@Type(AssociatedPaperworkModel_ClassTransform_Type)
	associated_paperwork: AssociatedPaperworkModel[];

	@Transform(dateTransformer)
	signed_timestamp: Date;
	@Transform(dateTransformer)
	created_timestamp: Date;
	signing_link: string;
	password: string;

	first_name: string;
	last_name: string;
	uid: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	enrollment_status: number;
	parent_institute_id: number;
	parent_institute_title: string;
}

export function PaperworkApprovalModel_ClassTransform_Type() {
	return PaperworkApprovalModel;
}

export class PaperworkArgument {
	item_id: number;
	comment: string;
}

export class PaperworkApproveRejectArgument {
	paperwork_request_id: number;
	paperwork_items_to_approve: PaperworkArgument[];
	paperwork_items_to_reject: PaperworkArgument[];
}
