<div class="modal-header">
	<h1 class="modal-title">
		{{ opts.modalTitle }}
		@if (opts.printHeader) {
			<span> - {{ opts.printHeader }}</span>
		}
	</h1>
	<button
		class="btn btn-outline-white"
		id="print.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<div class="print-display-container">
		<div [innerHtml]="html | dompurify"></div>
	</div>
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="print.modal.component.close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Close
	</button>

	<button
		class="btn btn-primary"
		id="print.modal.component.save"
		type="button"
		[disabled]="printing"
		(click)="print()"
	>
		<ae-wait-spinner [saving]="printing">Print</ae-wait-spinner>
	</button>
</div>
