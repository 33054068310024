export class IStudentPerformanceHistoryPeriodModel {
	start: Date;
	end: Date;
}

export interface IStudentPerformanceHistoryModel {
	id: number;
	title: string;
	short_title: string;
	performance: StudentPerformanceStatus;
	billable: StudentPerformanceStatus;
	finalized: boolean;
	trend: StudentPerformanceTrend;
	drop_expected: boolean;
	months_remain?: number;
	period: IStudentPerformanceHistoryPeriodModel;
}

export enum StudentPerformanceHistoryType {
	NotSpecified = 1,
	Other = 2,
	All = 3,
	Contractual = 4,
	Treatment = 5,
	Billing = 6,
	Individual = 7,
	Performance = 8,
	StudentFacing = 9,
}

export enum StudentPerformanceStatus {
	Met = 1,
	NotMet = 2,
	Undetermined = 3,
}

export enum StudentPerformanceTrend {
	OnTrack = 1,
	Risk = 2,
	OffTrack = 3,
}

export interface CategorizedPerformanceHistoryWithdraw {
	type: 'WITHDRAW';
	withdraw_date: Date;
	graduated_date: Date;
	performance: IStudentPerformanceHistoryModel;
}
export interface CategorizedPerformanceHistoryActivated {
	type: 'ACTIVATED';
	activation_date: Date;
	performance: IStudentPerformanceHistoryModel;
}
export interface CategorizedPerformanceHistoryActive {
	type: 'ACTIVE';
	enrolled_date: Date;
	performance: IStudentPerformanceHistoryModel;
}

export type CategorizedPerformanceHistory =
	| CategorizedPerformanceHistoryWithdraw
	| CategorizedPerformanceHistoryActivated
	| CategorizedPerformanceHistoryActive;

export enum StudentPerformanceTrends {
	success = 'Good Standing',
	info = 'Recovering',
	warning = 'Deficient',
	danger = 'At Risk',
	default = 'Not Calculated',
}

export const ProgramPerformanceOptions = [
	StudentPerformanceTrends.success,
	StudentPerformanceTrends.info,
	StudentPerformanceTrends.warning,
	StudentPerformanceTrends.danger,
	StudentPerformanceTrends.default,
];
