import { type Spacetime } from 'spacetime';

export interface MessageForm {
	message: string;
	sendSms: boolean;
	attachments: File[];
}

export enum PostMessageRenderType {
	AttachmentImage = 10,
	AttachmentVideo = 11,
	AttachmentFile = 12,

	UnreadMarker = 100,
	DateMarker = 101,
	TimeMarker = 102,
}

export interface PostMessageRender {
	key: string;
	type: PostMessageRenderType;
}

export interface DatePostMessageRender extends PostMessageRender {
	date: Spacetime;
}

export interface AttachmentPostMessageRender extends PostMessageRender {
	filename: string;
	url: string;
	index: number;
}
