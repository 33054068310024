import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, combineLatest } from 'rxjs';
import {
	filter,
	finalize,
	pairwise,
	startWith,
	takeUntil,
} from 'rxjs/operators';
import spacetime from 'spacetime';
import { getEnvironment } from 'src/lib/environment/environment';
import {
	UpdateUserRequestUserOptionsThemeModel,
	UpdateUserRequestUserOptionsTimezoneModel,
	UsersRequestOptionsResponse,
} from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { UserDataArgument } from 'src/lib/services/api/users/user-data.argument';
import {
	UserDataContactModel,
	UserDataModel,
} from 'src/lib/services/api/users/user-data.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { WebNotificationService } from 'src/lib/services/utility/web-notification/web-notification.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { convertToInt } from 'src/lib/utilities/convert';
import { filterTimezoneOptions } from 'src/lib/utilities/spacetime';
import { TimezoneSelectComponent } from '../../../../templates/controls/timezone-select/timezone-select.component';
import { SpinWhileDirective } from '../../../../templates/layout/spin-while/spin-while.directive';
import { GabbyNotificationVolumeComponent } from '../../../gabby/gabby-notification/gabby-notification-volume/gabby-notification-volume.component';

@Component({
	selector: 'ae-profile-settings',
	templateUrl: './profile-settings.component.html',
	styleUrls: ['./profile-settings.component.scss'],
	standalone: true,
	imports: [
		SpinWhileDirective,
		FormsModule,
		ReactiveFormsModule,
		NgClass,
		TimezoneSelectComponent,
		GabbyNotificationVolumeComponent,
	],
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public loading: boolean = true;
	public errorLoading: boolean = false;
	public savingTheme: boolean = false;

	public themes: UpdateUserRequestUserOptionsThemeModel[] = [];

	public themeCtrl: FormControl<string>;
	public timezoneCtrl: FormControl<string>;
	public timezones: UpdateUserRequestUserOptionsTimezoneModel[];

	public userId: number;

	public contactData: UserDataContactModel;
	public error: string;

	constructor(
		private userService: UsersService,
		private userRequestOptions: UsersUpdateRequestsService,
		private route: ActivatedRoute,
		private toastrService: ToastrService,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder,
		private webNotifications: WebNotificationService,
	) {}

	ngOnInit() {
		this.route.params.subscribe((p) => {
			this.userId = convertToInt(p.userId);
			this.getSettingInfo();
		});
	}

	public getSettingInfo = () => {
		this.loading = true;
		combineLatest([
			this.userService.getUserData(this.userId),
			this.userRequestOptions.getRequestsOptions(this.userId),
		])
			.pipe(finalize(() => (this.loading = false)))
			.subscribe({
				next: ([userData, options]) => {
					this.initThemeCtrl(userData, options);
					this.initTimezoneCtrl(userData?.user?.timezone, options);
				},
				error: (errors) => {
					this.error = mergeStrings(errors);
				},
			});
	};

	private initThemeCtrl = (
		userData: UserDataModel,
		options: UsersRequestOptionsResponse,
	) => {
		const isStudentApp = getEnvironment().isApp('student');

		let theme = isStudentApp
			? userData?.user.student_theme
			: userData?.user.theme;

		if (theme == null || theme === false) {
			theme = isStudentApp ? 'student_light' : 'default';
		}

		this.themeCtrl = this.fb.control<string>(theme as string);
		this.themeCtrl.valueChanges
			.pipe(
				filter(() => !this.savingTheme),
				startWith(theme as string),
				pairwise(),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(([oldVal, newVal]) => {
				this.setTheme(oldVal, newVal);
			});

		this.themes = isStudentApp
			? (options?.allowed_updates?.user?.student_theme?.options.map((x) => {
					return {
						key: x.key,
						title: x.title,
						color:
							x.key === 'student_light' ? 'var(--gray-300)' : 'var(--gray-800)',
					} as UpdateUserRequestUserOptionsThemeModel;
				}) ?? [])
			: (options?.allowed_updates?.user?.theme?.options ?? []);
	};

	private initTimezoneCtrl = (
		value: string,
		options: UsersRequestOptionsResponse,
	) => {
		this.timezones = filterTimezoneOptions(
			Object.values(options?.allowed_updates?.user?.timezone?.options ?? {}),
			(i) => i['key'],
		);

		if (this.timezones?.length > 0) {
			this.timezoneCtrl = this.fb.control<string>(value);
			this.timezoneCtrl.valueChanges
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe((val) => {
					this.setTimezone(val);
				});

			this.timezones = this.timezones
				.map((timezone) => {
					const offset = spacetime(null, timezone.key).offset() / 60;
					return { timezone, offset };
				})
				.map((x) => {
					x.timezone.title = `${x.timezone.title} (UTC ${
						x.offset < 0 ? '-' : '+'
					}${Math.abs(x.offset)})`;

					return x.timezone;
				});
		}
	};

	public setTimezone = (timezone: string) => {
		this.userService
			.setUserData(this.userId, { user: { timezone: timezone } })
			.subscribe({
				next: () => {
					this.toastrService.success('Timezone updated!');
				},
				error: (errors) => {
					this.toastrService.error(
						`Error updating your timezone: ${mergeStrings(errors)}`,
					);
				},
			});
	};

	public setTheme = (currentTheme: string, newTheme: string) => {
		this.savingTheme = true;
		this.themeCtrl.disable();

		const args: UserDataArgument = { user: {} };

		if (getEnvironment().isApp('student')) {
			args.user.student_theme = newTheme;
		} else {
			args.user.theme = newTheme;
		}

		// Save the change
		this.userService.setUserData(this.userId, args).subscribe({
			next: () => {
				this.toastrService.success('Theme updated!');
			},
			error: (errors) => {
				this.toastrService.error(
					`Error updating your theme: ${mergeStrings(errors)}`,
				);
				window.location.reload();
			},
			complete: () => {
				this.themeCtrl.enable();
				this.savingTheme = false;
			},
		});

		// Flip out the style sheets live
		const styleElement: HTMLLinkElement = document.querySelector(
			`link[href*="${currentTheme.replace('-', '_')}.css"]`,
		);
		const newStyleElement = styleElement?.cloneNode() as HTMLLinkElement;

		if (newStyleElement) {
			newStyleElement.onerror = function () {
				window.location.reload();
			};
			newStyleElement.onload = function () {
				styleElement.remove();
			};

			newStyleElement.href = styleElement.href.replace(
				`${currentTheme.replace('-', '_')}.css`,
				`${newTheme.replace('-', '_')}.css`,
			);
			styleElement.insertAdjacentElement('afterend', newStyleElement);
		} else {
			setTimeout(() => {
				window.location.reload();
			}, 500);
		}
	};

	public notificationsEnabled = (): boolean => {
		return this.webNotifications.canCreate();
	};

	public enableNotifications = (aggressive: boolean = true) => {
		return this.webNotifications.requestPermission(aggressive).subscribe(() => {
			this.cdr.markForCheck();
		});
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
