import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AddressComparisonInfo } from '../../api/generic/address/api-address.model';
import { ConfirmAddressModalComponent } from './confirm-address-modal/confirm-address-modal.component';

@Injectable({
	providedIn: 'root',
})
export class ConfirmAddressService {
	constructor(private modalService: NgbModal) {}

	public openModal$(addressData: AddressComparisonInfo[]) {
		const data = addressData.filter((a) => {
			return a != null;
		});
		return new Observable<AddressComparisonInfo[] | null>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: data.length <= 1 ? 'md' : 'lg',
			};
			const confirmModal = this.modalService.open(
				ConfirmAddressModalComponent,
				ngbModalOptions,
			);

			(
				confirmModal.componentInstance as ConfirmAddressModalComponent
			).bindModalData(data);

			confirmModal.result
				.then((responses: AddressComparisonInfo[]) => {
					o.next(responses);
					o.complete();
				})
				.catch(() => {
					o.next(null);
					o.complete();
				});
		});
	}
}
