import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrEmptyString } from 'src/lib/utilities/compare';
import { UniqueEmailModel } from './unique-email.model';

@Injectable({
	providedIn: 'root',
})
export class UniqueEmailService {
	constructor(private httpClient: HttpClient) {}

	public checkEmail = (email: string): Observable<UniqueEmailModel> => {
		return this.httpClient
			.get<UniqueEmailModel>(`/api/v1/user/email/${encodeURIComponent(email)}`)
			.pipe(map((r) => plainToInstance(UniqueEmailModel, r)));
	};

	public asyncValidators = {
		uniqueEmailValidator$: (currentSavedEmail: string): AsyncValidatorFn => {
			return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
				if (
					isNullOrEmptyString(ctrl.value) ||
					ctrl.value === currentSavedEmail
				) {
					return of(null);
				} else {
					return this.checkEmail(ctrl.value).pipe(
						map((r) => {
							if (r.email_in_use) {
								return {
									uniqueEmail:
										'This email is already being used with another account',
								};
							} else {
								return null;
							}
						}),
					);
				}
			};
		},
	};
}
