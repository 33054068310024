import { KeyValue } from '@angular/common';

export const CustomGroupShareProgramPrefix = 'inst_admin||';
export enum CustomGroupShareRole {
	ACs = 'relationship_requester||8',
	LAs = 'relationship_requester||7',
	ProgramAdmins = 'inst_admin||all',
	Teachers = 'is_teacher||all',
}

export interface CustomGroupShareOptions {
	[key: string]: string;
	[CustomGroupShareRole.ACs]: string;
	[CustomGroupShareRole.LAs]: string;
	[CustomGroupShareRole.Teachers]: string;
	[CustomGroupShareRole.ProgramAdmins]: string;
}

export class CustomGroupOptionsModel {
	state: OptionsModel;
	program_type: OptionsModel;
	tier: OptionsModel;
	relationship_academic_coach: OptionsModel;
	relationship_local_advocate: OptionsModel;
	relationship_re_entry_coach: OptionsModel;
	relationship_grad_coordinator: OptionsModel;
	relationship_testing_coordinator: OptionsModel;
	classes_start: OptionsModel;
	classes_due: OptionsModel;
	in_registration: OptionsModel;
	counted_courses: CompareModel;
	credits_to_graduate: CompareModel;
	missing_meetup_minutes: OptionsModel;
	star_test_needed: OptionsModel;
	status: OptionsModel;
	tagged_student: OptionsModel;
	has_teacher: OptionsModel;
	cohort_year: OptionsModel;
	has_incomplete_paperwork_request: OptionsModel;
	days_since_online: OptionsModel;
	performance_expectations: OptionsModel;
	performance_expectations_previous: OptionsModel;
	last_contact_by_me: OptionsModel;
	last_contact_by_any_staff: OptionsModel;
}

export function GroupOptionsModel_ClassTransform_Type() {
	return CustomGroupOptionsModel;
}

class OptionsModel {
	type: string;
	options: KeyValue<string, any>[];
}

class CompareModel {
	type: string;
	compare: string[];
}
