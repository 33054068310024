import { Pipe, PipeTransform } from '@angular/core';
import { hasValue } from '../utilities/compare';

@Pipe({
	name: 'ifEmpty',
	standalone: true,
})
export class IfEmptyPipe implements PipeTransform {
	transform<T>(value: T, display: string = '-'): T | string {
		if (hasValue(value) && (value as any) !== '') {
			return value;
		} else {
			return display;
		}
	}
}
