import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StatusCategoryModel {
	id: number;
	key: string;
}

export function StatusCategoryModel_ClassTransform_Type() {
	return StatusCategoryModel;
}

export class JiraTicketStatusModel {
	@Type(StatusCategoryModel_ClassTransform_Type)
	statusCategory: StatusCategoryModel;
}

export function JiraTicketStatusModel_ClassTransform_Type() {
	return JiraTicketStatusModel;
}

export class JiraTicketFieldModel {
	summary: string;
	@Transform(dateTransformer)
	created: Date;
	@Type(JiraTicketStatusModel_ClassTransform_Type)
	status: JiraTicketStatusModel;
}

export function JiraTicketFieldModel_ClassTransform_Type() {
	return JiraTicketFieldModel;
}

export class JiraLaptopTicketModel {
	id: string;
	@Type(JiraTicketFieldModel_ClassTransform_Type)
	fields: JiraTicketFieldModel;
}

export function JiraLaptopTicketModel_ClassTransform_Type() {
	return JiraLaptopTicketModel;
}

export class JiraLaptopIssuesModel {
	@Type(JiraLaptopTicketModel_ClassTransform_Type)
	issues: JiraLaptopTicketModel[];
}
