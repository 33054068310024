import { Type } from 'class-transformer';
import {
	PhoneModel,
	PhoneModel_ClassTransformType,
} from './relationships/student-relationships.model';

export class StudentProgramContactModel {
	uid: number;
	link_id: number;
	name: string;
	@Type(PhoneModel_ClassTransformType)
	phone: PhoneModel[];
	email: string;
	calendar: string[] | string;
	chat_link_id: string;
	picture_path: string;
	type: string;
}

export function StudentProgramContactModel_ClassTransform_Type() {
	return StudentProgramContactModel;
}

export class StudentProgramTechSupportModel {
	name: string;
	phone: number;
	email: string;
	calendar: string[] | string;
	chat_link_id: string;
	relation: string;
	link_id: number;
	picture_path: string;
}

export function StudentProgramTechSupportModel_ClassTransform_Type() {
	return StudentProgramTechSupportModel;
}

export class StudentProgramTutorSupportModel {
	name: string;
	phone: number;
	email: string;
	calendar: string[] | string;
	signup_link: string;
	chat_link_id: string;
	relation: string;
	link_id: number;
	picture_path: string;
}

export function StudentProgramTutorSupportModel_ClassTransform_Type() {
	return StudentProgramTutorSupportModel;
}

export type StudentProgramContact =
	| StudentProgramContactModel
	| StudentProgramTechSupportModel
	| StudentProgramTutorSupportModel;
