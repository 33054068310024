import { NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	HostBinding,
	Input,
	QueryList,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ValidationErrorDirective } from './validation-error.directive';

interface GroupValidationDisplayComponentDisplayError {
	display: ValidationErrorDirective;
	error: any;
}

@Component({
	selector: 'ae-group-validation-display',
	templateUrl: './group-validation-display.component.html',
	styleUrls: ['./group-validation-display.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet],
})
export class GroupValidationDisplayComponent {
	@HostBinding('class') hostClass = 'invalid-feedback';
	@HostBinding('attr.aria-live') hostAriaLive = 'polite';

	@Input() maxDisplay: number = 1;

	@ContentChildren(ValidationErrorDirective, { descendants: false })
	private _validationErrors: QueryList<ValidationErrorDirective>;

	public validationErrors: GroupValidationDisplayComponentDisplayError[];

	constructor(
		private cdr: ChangeDetectorRef,
		public hostElement: ElementRef<HTMLElement>,
	) {}

	public displayError = (errors: ValidationErrors) => {
		this.validationErrors = this._validationErrors
			.toArray()
			.map((x) => {
				return {
					display: x,
					error: errors[x.error],
				} as GroupValidationDisplayComponentDisplayError;
			})
			.filter((x) => {
				return x.error != null;
			})
			.slice(0, this.maxDisplay);

		this.cdr.detectChanges();
	};
}
