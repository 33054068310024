import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class AbDrillDownAcNoClassesModel {
	student_uid: number;
	student_name: string;
	@Transform(dateTransformer)
	date_assigned: Date;
	@Transform(dateTransformer)
	last_access: Date;
	days_since_last_class: number;
	is_at_max_credit: boolean;
	total_classes_counted_not_finalized: number;
	credits_remaining: number;
}
