import { PermitOrFeature } from 'src/lib/constants/permissions';

export class PermissionFieldSet {
	constructor(private permissions: PermitOrFeature[]) {
		this.permissions = permissions || [];
	}

	private logFieldCheck = (
		value: boolean,
		method: string,
		...fields: PermitOrFeature[]
	): boolean => {
		console.info(
			`Permission Check ${method} %c${fields.join(', ')}%c = %c${value}`,
			'color: yellow',
			'',
			value ? 'color: #abe100' : 'color: #9d1232; font-weight: bold',
		);
		return value;
	};

	private _hasPermission = (permission: PermitOrFeature): boolean => {
		return this.permissions.indexOf(permission) !== -1;
	};

	private _canDoAny = (permissions: PermitOrFeature[]): boolean => {
		if (permissions.length === 0) return true;

		for (const p of permissions) {
			if (this._hasPermission(p)) return true;
		}

		return false;
	};

	private _canDoAll = (permissions: PermitOrFeature[]): boolean => {
		if (permissions.length === 0) return false;

		for (const p of permissions) {
			if (!this._hasPermission(p)) return false;
		}

		return true;
	};

	public canDo = (permission: PermitOrFeature): boolean => {
		return this.logFieldCheck(
			this._hasPermission(permission),
			'canDo',
			permission,
		);
	};

	public canDoAny = (...permissions: PermitOrFeature[]): boolean => {
		return this.logFieldCheck(
			this._canDoAny(permissions),
			'canDoAny',
			...permissions,
		);
	};

	public canDoAll = (...permissions: PermitOrFeature[]): boolean => {
		return this.logFieldCheck(
			this._canDoAll(permissions),
			'canDoAll',
			...permissions,
		);
	};

	public listPermissions = () => this.permissions.slice();
}
