import { Type } from 'class-transformer';
import {
	StudentPerformanceHistoryType,
	StudentPerformanceStatus,
} from 'src/lib/constants/compliance';
import { PerformanceRequirementNoteInterface } from './notes/abstract-performance-requirement-note.model';
import { PerformanceRequirementProofInterface } from './proofs/abstract-performance-requirement-proof.model';
import { StudentPerformanceHistoryModel } from './student-performance-history.model';

export const StudentPerformanceHistoryTypeNames: ReadonlyMap<
	StudentPerformanceHistoryType,
	string
> = new Map([
	//[StudentPerformanceHistoryType.NotSpecified, 'Not Specified'],
	//[StudentPerformanceHistoryType.Other, 'Other'],
	[StudentPerformanceHistoryType.All, 'All'],
	[StudentPerformanceHistoryType.Contractual, 'Contractual'],
	[StudentPerformanceHistoryType.Treatment, 'Treatment'],
	[StudentPerformanceHistoryType.Billing, 'Billing'],
	//[StudentPerformanceHistoryType.Individual, 'Individual'],
	[StudentPerformanceHistoryType.Performance, 'Performance'],
]);

export const StudentPerformanceHistoryTypeIcons: ReadonlyMap<
	StudentPerformanceHistoryType,
	string
> = new Map([
	//[StudentPerformanceHistoryType.NotSpecified, 'fa-empty-set'],
	//[StudentPerformanceHistoryType.Other, 'fa-square-full'],
	[StudentPerformanceHistoryType.All, 'fa-shapes'],
	[StudentPerformanceHistoryType.Contractual, 'fa-club'],
	[StudentPerformanceHistoryType.Treatment, 'fa-shield'],
	[StudentPerformanceHistoryType.Billing, 'fa-octagon'],
	//[StudentPerformanceHistoryType.Individual, 'fa-spade'],
	[StudentPerformanceHistoryType.Performance, 'fa-diamond'],
]);

export class StudentPerformanceHistoryRequirementsModel {
	public title: string;
	public description: string;
	public performance: StudentPerformanceStatus;
	public notes: PerformanceRequirementNoteInterface[];
	public proof: PerformanceRequirementProofInterface[];
	public types: StudentPerformanceHistoryType[];
}

export function StudentPerformanceHistoryRequirementsModel_ClassTransform_Type() {
	return StudentPerformanceHistoryRequirementsModel;
}

export class StudentPerformanceHistoryDetailedModel extends StudentPerformanceHistoryModel {
	@Type(StudentPerformanceHistoryRequirementsModel_ClassTransform_Type)
	public requirements: StudentPerformanceHistoryRequirementsModel[];
}

export class TrackerUserPerformance {
	billable: StudentPerformanceStatus;
	drop_expected: boolean;
	months_remain: number;
	performance: StudentPerformanceStatus;
	performance_met_previous: StudentPerformanceStatus;
	@Type(StudentPerformanceHistoryRequirementsModel_ClassTransform_Type)
	requirements: StudentPerformanceHistoryRequirementsModel[];
}

export function TrackerUserPerformance_ClassTransform_Type() {
	return TrackerUserPerformance;
}
