import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { OrganizationCourseListModel } from 'src/lib/services/api/organizations/courses/organization-course-list.model';
import { OrganizationCourseModel } from 'src/lib/services/api/organizations/courses/organization-course.model';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { OrganizationCourseCatalogModel } from './organization-course-catalog.model';
import { OrganizationCourseSearchItemModel } from './organization-course-search-item.model';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsCoursesService {
	constructor(private httpClient: HttpClient) {}

	public getOrganizationCourses = (
		orgId: number,
	): Observable<OrganizationCourseListModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/courses`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(OrganizationCourseListModel, r as any[]),
					{
						errorCode: '505978F8',
					},
				),
			);
	};

	public getOrganizationCourse = (
		orgId: number,
		courseId: string,
	): Observable<OrganizationCourseModel> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/courses/${courseId}`)
			.pipe(
				mapResponse((r) => plainToInstance(OrganizationCourseModel, r), {
					errorCode: 'F9E8AF25',
				}),
			);
	};

	public searchClasses = (
		title: string,
		ids?: number[],
	): Observable<OrganizationCourseSearchItemModel[]> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/all/courses`, {
				title,
				course_ids: ids,
			})
			.pipe(
				mapResponse(
					(r) => plainToInstance(OrganizationCourseSearchItemModel, r as any[]),
					{
						errorCode: '9C28578E',
					},
				),
			);
	};

	/** Catalog */
	public getAllCatalogs = (): Observable<OrganizationCourseCatalogModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/catalogs`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							OrganizationCourseCatalogModel,
							r.catalogs as any[],
						),
					{ errorCode: 'F777EC83' },
				),
			);
	};
}
