import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentCreditVerificationModel {
	credit_check: boolean | null;
	@Transform(dateTransformer)
	credit_check_date: Date;
	@Transform(dateTransformer)
	verification_pending_date: Date;
	@Transform(dateTransformer)
	verification_complete_date: Date;
	notes: null;
}

export function StudentCreditVerificationModel_ClassTransform_Type() {
	return StudentCreditVerificationModel;
}

export class StudentCreditVerificationPaperworkModel {
	file_id: string;
	description: string;
	url: string;
	@Expose({ name: 'expiration_timestamp' })
	@Transform(dateTransformer)
	expiration_date: Date;
	@Expose({ name: 'uploaded_timestamp' })
	@Transform(dateTransformer)
	uploaded_date: Date;
	uploaded_by: number;
	uploaded_by_name: string;
}

export function StudentCreditVerificationPaperworkModel_ClassTransform_Type() {
	return StudentCreditVerificationPaperworkModel;
}

export class StudentCreditVerificationResponseModel {
	@Type(StudentCreditVerificationModel_ClassTransform_Type)
	verification: StudentCreditVerificationModel;
	@Type(StudentCreditVerificationPaperworkModel_ClassTransform_Type)
	paperwork: StudentCreditVerificationPaperworkModel;
}

export interface StudentCreditVerificationArgument {
	credit_check?: boolean | null;
	credit_check_date?: number;
	verification_pending_date?: number;
	verification_complete_date?: number;
	notes?: null;
}
