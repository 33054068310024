import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	CurriculumMappingCourseEditArgument,
	CurriculumMappingCourseModel,
} from './curriculum-mapping-course.model';

@Injectable({
	providedIn: 'root',
})
export class CurriculumMappingCoursesService {
	constructor(private httpClient: HttpClient) {}

	/**  Courses */
	public getAllCourses = (): Observable<
		KeyValue<string, CurriculumMappingCourseModel>[]
	> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/mapping/course_list`, {})
			.pipe(
				mapResponse(
					(r) => r.courses as KeyValue<string, CurriculumMappingCourseModel>[],
				),
			);
	};

	// in postman: Course -- Update All Endorsments
	public updateCourse = (
		courseId: number,
		args: CurriculumMappingCourseEditArgument,
	): Observable<CurriculumMappingCourseModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/teacher/endorsement/course_required/${courseId}/update`,
				args,
			)
			.pipe(
				mapResponse((r) =>
					plainToInstance(CurriculumMappingCourseModel, r.course_requirements),
				),
			);
	};
}
