<span [ngClass]="getColor()">
	<ng-container [ngSwitch]="direction">
		<ng-container *ngSwitchCase="'ahead'">
			<ng-container *ngIf="state !== 'danger'; else stackedAhead">
				<i aria-hidden="true" class="fas fa-forward fa-fw"></i>
			</ng-container>
			<ng-template #stackedAhead>
				<span class="fa-stack fa-1x">
					<i aria-hidden="true" class="far fa-square fa-stack-1x"></i>
					<i
						aria-hidden="true"
						class="fas fa-forward fa-stack-1x inner-icon"
					></i>
				</span>
			</ng-template>
		</ng-container>
		<ng-container *ngSwitchCase="'behind'">
			<ng-container *ngIf="state !== 'danger'; else stackedAhead">
				<i aria-hidden="true" class="fas fa-backward fa-fw"></i>
			</ng-container>
			<ng-template #stackedAhead>
				<span class="fa-stack fa-1x">
					<i aria-hidden="true" class="far fa-square fa-stack-1x"></i>
					<i
						aria-hidden="true"
						class="fas fa-backward fa-stack-1x inner-icon"
					></i>
				</span>
			</ng-template>
		</ng-container>
		<ng-container *ngSwitchCase="'on track'">
			<i aria-hidden="true" class="fas fa-graduation-cap fa-fw"></i>
		</ng-container>
		<ng-container *ngSwitchDefault />
	</ng-container>
</span>
