import { Injectable } from '@angular/core';
import { GA_NG, GA_NG_INTERNAL } from 'src/lib/types/ga-ng.def';
import { hasValue } from 'src/lib/utilities/compare';
import { tryParseInt } from 'src/lib/utilities/convert';
import { PermissionFieldSet } from '../../stores/permission-store/permission-field-set';

export type GAPortalRole =
	| 'authenticated'
	| 'self_registered'
	| 'student'
	| 'enrolled'
	| 'homeschooler'
	| 'credit_seeker'
	| 'diploma_seeker'
	| 'parent'
	| 'counselor'
	| 'assistant'
	| 'school_administrator'
	| 'proctor'
	| 'registrar'
	| 'gatekeeper'
	| 'special_ed_director'
	| 'business_manager'
	| 'mapper'
	| 'grader'
	| 'teacher'
	| 'onsite_mentor'
	| 'blog_editor'
	| 'staff'
	| 'staff_te'
	| 'staff_ea'
	| 'transcript_coordinator'
	| 'ga_counselor'
	| 'advocate'
	| 'regional_advocate'
	| 'recruiter'
	| 'program_manager'
	| 'testing_coordinator'
	| 'career_counselor'
	| 'online_mentor'
	| 'reentry_coordinator'
	| 'reentry_coach'
	| 'academic_coach_lead'
	| 'academic_coach_manager'
	| 'teacher_lead'
	| 'principal'
	| 'content_admin'
	| 'site_admin';

@Injectable({
	providedIn: 'root',
})
export class InitializationService implements GA_NG {
	constructor() {
		const ga_ng = ((window as any).ga_ng ?? {}) as GA_NG_INTERNAL;

		for (const key in ga_ng) {
			if (ga_ng.hasOwnProperty(key)) {
				this[key] = ga_ng[key];
			}
		}

		this.id = tryParseInt(String(this.id));

		if (hasValue(this.impersonaterId)) {
			this.id = tryParseInt(String(this.impersonaterId));
		}

		if (this.permissionParams != null) {
			this.permissionParams.program = tryParseInt(
				String(this.permissionParams.program),
			);
			this.permissionParams.user = tryParseInt(
				String(this.permissionParams.user),
			);
		}

		this.permissions = new PermissionFieldSet(ga_ng.permissions);
	}

	get loggedIn() {
		return this.permissionParams?.user > 0;
	}

	public id: number;
	public impersonaterId: number;
	public link_id: number;
	public name: string;
	public roles: Record<string, string> = {};
	public timezone: string;

	public legacy_base_url: string;
	public websocket: { realm: string; url: string } = {} as any;
	public permissionParams: { program: number; user: number } = {} as any;
	public permissions: PermissionFieldSet;
	public programEnrollments: string[] = [];

	hasAnyRole(...roles: GAPortalRole[]): boolean;
	hasAnyRole(...roles: string[]): boolean {
		return (roles ?? []).find((r) => this.roles[r] != null) != null;
	}

	hasNoRole(...roles: GAPortalRole[]): boolean;
	hasNoRole(...roles: string[]): boolean {
		return (roles ?? []).find((r) => this.roles[r] != null) == null;
	}
}
