import { Type } from 'class-transformer';

export class NotificationOptionRecipientSenderModel {
	title: string;
	id: string;
	description: string;
	category: string;
	has_custom_data: boolean;
}

export function NotificationOptionRecipientSenderModel_ClassTransform_Type() {
	return NotificationOptionRecipientSenderModel;
}

export class NotificationOptionTokenModel {
	key: string;
	name: string;
	description?: string;
	type: string;
}

export function NotificationOptionTokenModel_ClassTransform_Type() {
	return NotificationOptionTokenModel;
}

export class NotificationOptionsModel {
	@Type(NotificationOptionRecipientSenderModel_ClassTransform_Type)
	recipients: NotificationOptionRecipientSenderModel[];
	@Type(NotificationOptionTokenModel_ClassTransform_Type)
	tokens: NotificationOptionTokenModel[];
	@Type(NotificationOptionRecipientSenderModel_ClassTransform_Type)
	senders: NotificationOptionRecipientSenderModel[];
}
