import { Attrs, MarkType, Node } from 'prosemirror-model';
import { Command, SelectionRange, TextSelection } from 'prosemirror-state';

// Copied from https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.ts
function markApplies(
	doc: Node,
	ranges: readonly SelectionRange[],
	type: MarkType,
) {
	// eslint-disable-next-line @typescript-eslint/prefer-for-of
	for (let i = 0; i < ranges.length; i++) {
		const { $from, $to } = ranges[i];
		let can =
			$from.depth === 0
				? doc.inlineContent && doc.type.allowsMarkType(type)
				: false;
		doc.nodesBetween($from.pos, $to.pos, (node) => {
			if (can) return;
			can = node.inlineContent && node.type.allowsMarkType(type);
		});
		if (can) return true;
	}
	return false;
}

// Modification of toggleMark https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.ts
export function applyMark(
	markType: MarkType,
	attrs: Attrs | null = null,
): Command {
	return function (state, dispatch) {
		const { empty, $cursor, ranges } = state.selection as TextSelection;
		if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) {
			return false;
		}

		if (dispatch) {
			if ($cursor) {
				if (markType.isInSet(state.storedMarks || $cursor.marks())) {
					dispatch(state.tr.removeStoredMark(markType));
				} else dispatch(state.tr.addStoredMark(markType.create(attrs)));
			} else {
				const tr = state.tr;

				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let i = 0; i < ranges.length; i++) {
					const { $from, $to } = ranges[i];

					tr.removeMark($from.pos, $to.pos, markType);

					let from = $from.pos,
						to = $to.pos;
					const start = $from.nodeAfter,
						end = $to.nodeBefore;
					const spaceStart =
						start && start.isText ? /^\s*/.exec(start.text!)![0].length : 0;
					const spaceEnd =
						end && end.isText ? /\s*$/.exec(end.text!)![0].length : 0;
					if (from + spaceStart < to) {
						from += spaceStart;
						to -= spaceEnd;
					}
					tr.addMark(from, to, markType.create(attrs));
				}
				dispatch(tr.scrollIntoView());
			}
		}
		return true;
	};
}
