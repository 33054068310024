import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, take } from 'rxjs';
import spacetime from 'spacetime';
import { MeetupManagementListItemModel } from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupManagementService } from 'src/lib/services/api/organizations/meetups/meetup-management.service';
import { StudentMeetupInfoModel } from 'src/lib/services/api/students/meetups/student-meetup.model';
import { StudentsMeetupsService } from 'src/lib/services/api/students/meetups/students-meetups.service';
import { PerformanceExpectationMeetupModel } from 'src/lib/services/api/students/student-performance-expectation.model';
import { SearchOperators } from 'src/lib/utilities/api/patterns/pagination/search-operators.enum';
import { BehaviorCache } from 'src/lib/utilities/cache';
import { StudentPerformanceExpectationsStoreService } from '../expectations/student-performance-expectations-store.service';

@Injectable({
	providedIn: 'root',
})
export class StudentMeetupStoreService {
	private _listFutureMeetupsCache = new BehaviorCache<
		[uid: number, month: number, year: number],
		MeetupManagementListItemModel[]
	>(
		([uid, month, year]) => {
			let start = spacetime([year, month, 1]);
			const end = start.endOf('month').add(1, 'date');
			start = start.subtract(1, 'date');

			return combineLatest([
				this.meetupManagementService.listFutureMeetups({
					for_student: uid,
					take: 10000,
					filters: {
						start_date: {
							value: start.toNativeDate().toISOString(),

							operator: SearchOperators.GreaterThanOrEqual,
						},
						end_date: {
							value: end.toNativeDate().toISOString(),
							operator: SearchOperators.LessThanOrEqual,
						},
					},
				}),
				this.studentPerformanceExpectationsStoreService.expectations$(uid),
			]).pipe(
				take(1),
				map(([meetup, expectactions]) => {
					const meetupRequirement = expectactions?.program?.find(
						(p) => p.type === 'meetup_minutes' && !p.complete_base,
					) as PerformanceExpectationMeetupModel;

					if (
						meetupRequirement &&
						expectactions.periods.program.end >= new Date() && // Check if the period has expired
						meetupRequirement.meetup_types[0] === 'physical' && // Check for in person meetup requirements
						meetupRequirement.meetup_types.length === 1 // Check for only in person meetup requirements
					) {
						meetup = meetup.filter((m) => m.type === 'physical');
					}

					return meetup;
				}),
			);
		},
		'StudentMeetupStoreService ListFutureMeetupsCache',
		() => [],
		15 * 60,
	);

	private _meetupInfoCache = new BehaviorCache<number, StudentMeetupInfoModel>(
		(uid) => {
			return this.studentsMeetupsService.getMeetupInfo(uid);
		},
		'StudentMeetupStoreService ListFutureMeetupsCache',
		() => null,
		15 * 60,
	);

	constructor(
		private meetupManagementService: MeetupManagementService,
		private studentsMeetupsService: StudentsMeetupsService,
		private studentPerformanceExpectationsStoreService: StudentPerformanceExpectationsStoreService,
	) {}

	public meetups$ = (
		uid: number,
		month: number,
		year: number,
	): Observable<MeetupManagementListItemModel[]> => {
		return this._listFutureMeetupsCache.getCache([uid, month, year]);
	};

	public refreshMeetups = (
		uid: number,
		month: number,
		year: number,
	): Promise<boolean> => {
		return this._listFutureMeetupsCache.fetchData([uid, month, year], true);
	};

	public clearAllMeetups = () => {
		return this._listFutureMeetupsCache.clearAllData();
	};

	public meetupInfo$ = (uid: number): Observable<StudentMeetupInfoModel> => {
		return this._meetupInfoCache.getCache(uid);
	};

	public refreshMeetupInfo = (uid: number): Promise<boolean> => {
		return this._meetupInfoCache.fetchData(uid, true);
	};
}
