<div class="input-group validate-include-children flex-nowrap overflow-hidden">
	<input
		#mainInput
		class="form-control squared-end"
		type="search"
		[disabled]="disabled"
		id="{{ id }}"
		[ngModelOptions]="{ updateOn: 'blur', standalone: true }"
		[placeholder]="placeholder | ifEmpty: format.toLowerCase()"
		[(ngModel)]="formattedModel"
		(keydown.enter)="mainInput.blur()"
		(ngModelChange)="onFormattedChange($event)"
	/>
	<button
		class="btn rounded-end calendar-btn"
		title="Open Calendar"
		type="button"
		[disabled]="disabled"
		[ngClass]="
			isPresentingInvalid() ? 'btn-outline-danger' : 'btn-outline-secondary'
		"
		(click)="enrolledDatePicker.toggle()"
	>
		<i aria-hidden="true" class="far fa-calendar-day"></i>
	</button>
	<input
		#enrolledDatePicker="ngbDatepicker"
		container="body"
		ngbDatepicker
		placement="bottom-right"
		type="hidden"
		[firstDayOfWeek]="7"
		[markDisabled]="markDisabled"
		[maxDate]="getMaxDate()"
		[minDate]="getMinDate()"
		[ngModelOptions]="{ standalone: true }"
		[positionTarget]="mainInput"
		[startDate]="getStartDate()"
		[(ngModel)]="hiddenModel"
		(ngModelChange)="onHiddenChange($event)"
	/>
</div>
