import { ChangeDetectorRef, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'ae-session-expired-modal',
	templateUrl: './session-expired-modal.component.html',
	styleUrls: ['./session-expired-modal.component.scss'],
	standalone: true,
})
export class SessionExpiredModalComponent {
	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (): void => {
		// SET DATA

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
	};
}
