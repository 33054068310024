import {
	Directive,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	Self,
} from '@angular/core';
import { SizeMonitorService } from './size-monitor.service';

export class SizeMonitor {
	private _observer: ResizeObserver;
	private _setup = false;

	constructor(
		private service: SizeMonitorService,
		private element: HTMLElement,
		private ngZone: NgZone,
	) {}

	public setup(): void {
		if (this._setup) {
			throw new Error('SizeMonitor is already setup');
		}
		this._setup = true;

		this.ngZone.runOutsideAngular(() => {
			this._observer = new ResizeObserver((x) => {
				setTimeout(() => {
					x.forEach((r) => {
						if (!isNaN(r.contentRect.width)) {
							this.service.notifyOfSizeChange(r.contentRect.width);
							this.setVWScale(r.contentRect.width);
						}
					});
				});
			});
			this._observer.observe(this.element);
			this.setVWScale(this.element.clientWidth);
		});
	}

	private setVWScale(width: number) {
		const scale = (width - 320) / (1920 - 320); // 1080 screen to WCAG min of 320
		this.element.style.setProperty(
			'--vw-scale',
			Math.max(Math.min(scale, 1), 0).toString(),
		);
	}

	public teardown(): void {
		this._observer?.disconnect();
	}
}

@Directive({
	selector: '[aeSizeMonitor]',
	providers: [SizeMonitorService],
	standalone: true,
})
export class SizeMonitorDirective implements OnInit, OnDestroy {
	private _monitor: SizeMonitor;

	constructor(
		@Self() private service: SizeMonitorService,
		private ele: ElementRef<HTMLElement>,
		private ngZone: NgZone,
	) {}

	ngOnInit(): void {
		this._monitor = new SizeMonitor(
			this.service,
			this.ele.nativeElement,
			this.ngZone,
		);
		this._monitor.setup();
	}

	ngOnDestroy(): void {
		this._monitor.teardown();
	}
}
