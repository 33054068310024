import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentPerformanceRequirementNoteParserService } from './notes/parser/student-performance-requirement-note-parser.service';
import { StudentPerformanceRequirementProofParserService } from './proofs/parser/student-performance-requirement-proof-parser.service';
import { StudentPerformanceHistoryDetailedModel } from './student-performance-history-detailed.model';
import { StudentPerformanceHistoryModel } from './student-performance-history.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsPerformanceService {
	constructor(
		private httpClient: HttpClient,
		private proofParser: StudentPerformanceRequirementProofParserService,
		private noteParser: StudentPerformanceRequirementNoteParserService,
	) {}

	public getHistories = (
		studentUid: number,
		options?: {
			skip?: number;
			take?: number;
		},
	): Observable<StudentPerformanceHistoryModel[]> => {
		const args = buildQueryString(options);

		return this.httpClient
			.get<
				StudentPerformanceHistoryModel[]
			>(`/api/v1/students/${studentUid}/performance/history${args}`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentPerformanceHistoryModel, r), {
					errorCode: 'C51581E3',
				}),
			);
	};

	public getHistoryDetails = (
		studentUid: number,
		historyId: number,
	): Observable<StudentPerformanceHistoryDetailedModel> => {
		return this.httpClient
			.get<StudentPerformanceHistoryDetailedModel>(
				`/api/v1/students/${studentUid}/performance/history/${historyId}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentPerformanceHistoryDetailedModel, r),
					{ errorCode: 'C961D5BD' },
				),
				tap((m) => {
					m.requirements.forEach((r) => {
						r.proof = r.proof.map((p) => this.proofParser.parseProof(p));
						r.notes = r.notes.map((n) => this.noteParser.parseNote(n));
					});
				}),
			);
	};
}
