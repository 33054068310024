import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class StudentProgressTrackerItemModel {
	uid: number;
	profile_first_name: string;
	profile_last_name: string;
	profile_preferred_name: string;
	mail: string;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	work_queue: WorkQueueTrackerResponseModel[];
	@Transform(dateTransformer)
	access: Date;
	state_program: string;
	program_title: string[];
	program_parent_title: string[];
	tier: string;
	registration_title: string;
	registration_id: number;
	assigned_teacher_first: string;
	assigned_teacher_last: string;
	assigned_teacher_uid: number;
	assignments_ungraded: number;
	assignments_total: number;
	assignments_completed: number;
	current_grade_percent: number;
	current_grade_percent_zero: number;
	current_letter_grade: string;
	what_if_letter_grade: string;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	target_end_date: Date;
	student_location: string;
}

export function StudentProgressTrackerItemModel_ClassTransform_Type() {
	return StudentProgressTrackerItemModel;
}

export class StudentProgressTrackerModel {
	count: number;
	skip: number;
	take: number;
	@Type(StudentProgressTrackerItemModel_ClassTransform_Type)
	results: StudentProgressTrackerItemModel[];
}
