import { Type } from 'class-transformer';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	CredentialedResponseModel,
	CredentialedResponseModel_ClassTransform_Type,
} from '../../credentialed-responder/credentialed-response.model';
import {
	CredentialedResponderUserModel,
	CredentialedResponderUserModel_ClassTransform_Type,
} from '../../trackers/credentialed-responder-tracker.model';

export class StudentResponseListModel {
	@Type(CredentialedResponseModel_ClassTransform_Type)
	feedback_list: CredentialedResponseModel[];
	count: number;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	local_advocate: CredentialedResponderUserModel;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	academic_coach: CredentialedResponderUserModel;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	reentry_coach: CredentialedResponderUserModel;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	credentialed_responder: CredentialedResponderUserModel;
	days_since_last_response: number;
}

export interface StudentResponseListArgument {
	updated_by_name: PagingSearchFieldArgument;
	flags: PagingSearchFieldArgument;
	submitted_date: PagingSearchFieldArgument;
	responded_date: PagingSearchFieldArgument;
	staff_notification_sent_on: PagingSearchFieldArgument;
}
