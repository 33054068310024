import { Transform, Type } from 'class-transformer';
import { recordTransformerFactory } from 'src/lib/utilities/api/class-transformer-types';

export class NotificationSettingGroupModel {
	id: string;
	name: string;
}

export function NotificationSettingGroupModel_ClassTransform_Type() {
	return NotificationSettingGroupModel;
}

export class NotificationSettingNotificationModel {
	level: number;
	is_required: boolean;
	send_by_default: boolean;
}

export function NotificationSettingNotificationModel_ClassTransform_Type() {
	return NotificationSettingNotificationModel;
}

export class NotificationSettingModel {
	default: number;
	enabled: boolean;
	is_required: boolean;
	send_by_default: boolean;
	has_notification: boolean;
	title: string;
	@Type(NotificationSettingModel_ClassTransform_Type)
	notification: NotificationSettingNotificationModel;
}

export function NotificationSettingModel_ClassTransform_Type() {
	return NotificationSettingModel;
}

export class NotificationSetSettingsModel {
	description: string;
	event_id: string;
	@Type(NotificationSettingGroupModel_ClassTransform_Type)
	groups: NotificationSettingGroupModel[];
	instance_id: number;
	instance_title: string;
	@Transform(
		recordTransformerFactory(NotificationSettingModel_ClassTransform_Type),
	)
	notification_settings: Record<string, NotificationSettingModel>;
}
