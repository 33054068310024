import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class CredentialedResponderUserModel {
	uid: number;
	name: string;
}

export function CredentialedResponderUserModel_ClassTransform_Type() {
	return CredentialedResponderUserModel;
}

export class CredentialedResponderTrackerModel {
	get profile_first_name() {
		return this.first_name;
	}
	get profile_preferred_name() {
		return this.first_name;
	}
	get profile_last_name() {
		return this.last_name;
	}

	first_name: string;
	last_name: string;
	uid: number;
	user_link_id: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	enrollment_status: number;
	last_login: string;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	response_id: number;
	response_form_id: string;
	response_progress: string;
	response_form_title: string;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	local_advocate: CredentialedResponderUserModel;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	academic_coach: CredentialedResponderUserModel;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	reentry_coach: CredentialedResponderUserModel;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	credentialed_responder: CredentialedResponderUserModel;
	flags: string[];
	days_since_update: number;
	flags_count_inappropriate: number;
	flags_count_concerning: number;
	parent_institute_id: number;
	parent_institute_title: string;
	@Transform(dateTransformer)
	staff_notification_sent_on: Date;
}

export function CredentialedResponderTrackerModel_ClassTransform_Type() {
	return CredentialedResponderTrackerModel;
}

export class CredentialedResponderTrackerResponseModel {
	success: boolean;
	count: number;
	@Type(CredentialedResponderTrackerModel_ClassTransform_Type)
	results: CredentialedResponderTrackerModel[];
}

export interface CredentialedResponderTrackerArgument {
	first_name: PagingSearchFieldArgument;
	last_name: PagingSearchFieldArgument;
	uid: PagingSearchFieldArgument;
	work_queue: PagingSearchFieldArgument;
	institute_title: PagingSearchFieldArgument;
	institute_type: PagingSearchFieldArgument;
	institute_state: PagingSearchFieldArgument;
	last_login: PagingSearchFieldArgument;
	response_progress: PagingSearchFieldArgument;
	local_advocate_name: PagingSearchFieldArgument;
	academic_coach_name: PagingSearchFieldArgument;
	credentialed_responder_name: PagingSearchFieldArgument;
	twoway_contact: PagingSearchFieldArgument;
	flags_count_concerning: PagingSearchFieldArgument;
	flags_count_inappropriate: PagingSearchFieldArgument;
	days_since_update: PagingSearchFieldArgument;
}
