import {
	AfterContentInit,
	Directive,
	ElementRef,
	HostListener,
	OnDestroy,
	Optional,
} from '@angular/core';
import {
	AbstractControl,
	FormControlDirective,
	FormControlName,
} from '@angular/forms';
import { AsyncSubject, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { isString } from 'src/lib/utilities/compare';

@Directive({
	selector: 'input[aeInputTrim]',
	standalone: true,
})
export class InputTrimDirective implements AfterContentInit, OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	public _ready$ = new AsyncSubject<boolean>();

	private ctrl: AbstractControl;
	private checkTrim: boolean = false;

	constructor(
		private ele: ElementRef<HTMLInputElement>,
		@Optional() private formControl: FormControlDirective,
		@Optional() private formControlName: FormControlName,
	) {}

	@HostListener('focusout')
	onBlur() {
		if (this.checkTrim && this.ctrl) {
			const originalVal: unknown = this.ctrl.value;
			if (isString(originalVal)) {
				const trimVal = originalVal.trim();
				if (trimVal !== originalVal) {
					this.ctrl.setValue(trimVal);
				}
			}
		}

		this.checkTrim = false;
	}

	private refreshFormControl = () => {
		const dirName = this.formControl ? 'formcontrol' : 'formcontrolname';
		this.ctrl = this.formControl
			? this.formControl.control
			: this.formControlName.control;

		if (this.ctrl == null) {
			throw new Error(`Could not find controller using: ${dirName} on object.`);
		}
	};

	public attach = () => {
		if (this._unsubscribe$ == null) return;

		// Setting properties
		if (this.formControl == null && this.formControlName == null) {
			throw new Error('No FormControl and FormControlName were supplied');
		}
		if (this.formControl && this.formControlName) {
			throw new Error('Both FormControl and FormControlName were supplied');
		}

		this.refreshFormControl();

		// Setting up observable
		merge(this.ctrl.statusChanges, this.ctrl.valueChanges)
			.pipe(
				filter(() => {
					return document.activeElement === this.ele.nativeElement;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.checkTrim = true;
			});
	};

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.attach();
			this._ready$.next(true);
			this._ready$.complete();
		}, 5);
	}

	ngOnDestroy(): void {
		this._ready$.next(false);
		this._ready$.complete();

		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
