<div class="modal-header">
	<h1 class="modal-title">You have been logged out</h1>
	<button
		class="btn btn-outline-white"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div>
		Your session has expired. Click "Log in" to be directed to the login page.
	</div>
</div>
<div class="modal-footer">
	<button class="btn btn-primary" type="button" (click)="activeModal.dismiss()">
		Log in
	</button>
</div>
