import { NgModule } from '@angular/core';
import { PaginationTableColumnDisplayDirective } from './children/pagination-table-column-display.directive';
import { PaginationTableColumnExportDisplayDirective } from './children/pagination-table-column-export-display.directive';
import { PaginationTableColumnHeaderDirective } from './children/pagination-table-column-header.directive';
import { PaginationTableColumnSearchDirective } from './children/pagination-table-column-search.directive';
import { PaginationTableColumnDirective } from './children/pagination-table-column.directive';
import { PaginationTableControlsCustomDirective } from './children/pagination-table-controls-custom.directive';
import { PaginationTableFiltersCustomDirective } from './children/pagination-table-filters-custom.directive';
import { PaginationTableFooterCustomDirective } from './children/pagination-table-footer-custom.directive';
import { PaginationTableRowActionsColumnDirective } from './children/pagination-table-row-actions-column.directive';
import { PaginationTableStateErrorDirective } from './children/pagination-table-state-error.directive';
import { PaginationTableStateNoItemsDirective } from './children/pagination-table-state-noitems.directive';
import { PaginationTableComponent } from './core/pagination-table.component';

@NgModule({
	imports: [
		PaginationTableComponent,
		PaginationTableControlsCustomDirective,
		PaginationTableColumnDirective,
		PaginationTableRowActionsColumnDirective,
		PaginationTableColumnHeaderDirective,
		PaginationTableColumnDisplayDirective,
		PaginationTableColumnSearchDirective,
		PaginationTableFiltersCustomDirective,
		PaginationTableStateErrorDirective,
		PaginationTableFooterCustomDirective,
		PaginationTableStateNoItemsDirective,
		PaginationTableColumnExportDisplayDirective,
	],
	exports: [
		PaginationTableComponent,
		PaginationTableControlsCustomDirective,
		PaginationTableColumnDirective,
		PaginationTableRowActionsColumnDirective,
		PaginationTableColumnHeaderDirective,
		PaginationTableColumnDisplayDirective,
		PaginationTableColumnSearchDirective,
		PaginationTableFiltersCustomDirective,
		PaginationTableStateErrorDirective,
		PaginationTableFooterCustomDirective,
		PaginationTableStateNoItemsDirective,
		PaginationTableColumnExportDisplayDirective,
	],
})
export class PaginationTableModule {}
