import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	HostListener,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { getEnvironment } from 'src/lib/environment/environment';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import {
	getFileFromPaste,
	getFilesFromDrop,
	getFilesFromInput$,
	isFileAllowed as isFileTypeAllowed,
} from 'src/lib/utilities/file';
import { InputCheckboxComponent } from '../../../../templates/controls/input-checkbox/input-checkbox.component';
import { DisplayFileComponent } from '../../../../templates/global/display-file/display-file.component';
import { MessageForm } from '../gabby-chat-component-definitions';

@Component({
	selector: 'ae-gabby-message-builder',
	templateUrl: './gabby-message-builder.component.html',
	styleUrls: ['./gabby-message-builder.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		NgClass,
		NgbTooltip,
		DisplayFileComponent,
		InputCheckboxComponent,
	],
})
export class GabbyMessageBuilderComponent {
	@Input() messageForm: FormGroup<FormControlWrapper<MessageForm>>;

	public maxBodyLength =
		getEnvironment().settings.views.gabby.message.maxBodyLength;
	public attachmentExtensionFilter =
		getEnvironment().settings.views.gabby.message.attachmentExtensionFilter;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private toastrService: ToastrService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: {
		messageForm: FormGroup<FormControlWrapper<MessageForm>>;
	}): void => {
		// SET DATA
		this.messageForm = data.messageForm;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
		const attachments = this.messageForm.controls.attachments.value;
		this.messageForm.controls.attachments.setValue(null);

		this.appendFilesToForm(attachments);
	};

	public messagePaste = (e: ClipboardEvent) => {
		const file = getFileFromPaste(e);

		// load image if there is a pasted image
		if (file !== null) {
			this.appendFilesToForm([file]);
		}
	};

	@HostListener('drop', ['$event'])
	public onDrop = (event: DragEvent): void => {
		const files = getFilesFromDrop(event);
		this.appendFilesToForm(files);
	};

	public attachFiles = (e: Event, element: HTMLInputElement) => {
		getFilesFromInput$(
			e,
			element,
			getEnvironment().settings.views.gabby.message.maxAttachmentSize,
		).subscribe((updatedFiles) => {
			this.appendFilesToForm(updatedFiles);
		});
	};

	private appendFilesToForm = (files: File[]) => {
		const notAllowedFiles = files.filter((x) => !isFileTypeAllowed(x));
		notAllowedFiles.forEach((x) => {
			files.splice(files.indexOf(x), 1);
			this.toastrService.error(`${x.name} is not an allowed file type`);
		});

		const originalFiles = this.messageForm.controls.attachments.value || [];
		originalFiles.push(...files);
		this.messageForm.controls.attachments.setValue(originalFiles);
	};

	public removeAttachment = (file: File) => {
		const files = this.messageForm.controls.attachments.value || [];
		const fileIndex = files.indexOf(file);
		if (fileIndex !== -1) {
			files.splice(fileIndex, 1);
		}

		this.messageForm.controls.attachments.setValue(files);
	};

	public close = () => {
		this.activeModal.dismiss();
	};

	public submit = () => {
		this.activeModal.close(true);
	};
}
