<div>
	<nav *aeSpinWhile="tabs == null" class="mb-2">
		<div
			aria-orientation="horizontal"
			class="nav nav-tabs"
			role="tablist"
			tabindex="-1"
			attr.aria-controls="tabber.component.{{ id }}.tabpanel"
			id="tabber.component.{{ id }}.tablist"
		>
			@for (tab of visibleTabs; track tab) {
				<a
					#sidenavTab
					class="nav-link text-black rounded-top"
					role="tab"
					routerLinkActive="active"
					attr.aria-controls="tabber.component.{{ id }}.tabpanel"
					[attr.aria-selected]="selectedTabCtrl.value === tab.path"
					id="tabber.component.{{ id }}.tabitem.{{ tab.path }}"
					[queryParamsHandling]="preserveParams ? 'preserve' : ''"
					[routerLink]="[tab.path]"
					[tabindex]="selectedTabCtrl.value === tab.path ? 0 : -1"
					(keydown)="onKeyDown($event)"
				>
					{{ tab.name }}
				</a>
			}
		</div>
	</nav>

	<section
		aeSizeMonitor
		aria-labelledby="sidenav-tabber.component.tab-header"
		role="tabpanel"
		tabindex="0"
		id="tabber.component.{{ id }}.tabpanel"
	>
		<h2 class="screen-reader-text" id="sidenav-tabber.component.tab-header">
			@if (activatedTab) {
				{{ activatedTab.name }}
			} @else {
				Tab is loading...
			}
		</h2>
		<ng-container *aeSpinWhile="activatedTab == null">
			@if (activatedTab.hasPermission) {
				<router-outlet />
			} @else {
				<div class="text-center mt-5 fs-3">
					You do not have permission to view this tab
				</div>
			}
		</ng-container>
		<div class="skip-links-container skip-links-bottom-left">
			<a aeAnchorLink href="#tabber.component.{{ id }}.tablist">
				Back To Tab Navigation
			</a>
			<a aeAnchorLink href="#main-skip-links">Back To Page Start</a>
		</div>
	</section>
</div>
