import {
	Directive,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
} from '@angular/core';
import { AsyncSubject, Subject, fromEvent, race, takeUntil } from 'rxjs';

@Directive({
	selector: '[aeFocusTransfer]',
	standalone: true,
})
export class FocusTransferDirective implements OnChanges, OnDestroy {
	private _unsubscribe = new AsyncSubject<void>();

	private _changed = new Subject<void>();

	@Input() aeFocusTransfer: HTMLElement;
	@Input() keyboardFocusOnly: boolean = true;

	constructor(private elementRef: ElementRef<HTMLElement>) {}

	ngOnChanges(changes: SimpleChanges): void {
		this._changed.next(null);

		if (changes.aeFocusTransfer) {
			fromEvent(this.aeFocusTransfer, 'focus')
				.pipe(takeUntil(race(this._changed, this._unsubscribe)))
				.subscribe(() => {
					if (
						this.keyboardFocusOnly &&
						this.aeFocusTransfer.parentElement?.querySelector(
							':focus-visible',
						) == null
					) {
						return;
					}

					this.elementRef.nativeElement.classList.add('force-focus');
				});

			fromEvent(this.aeFocusTransfer, 'blur')
				.pipe(takeUntil(race(this._changed, this._unsubscribe)))
				.subscribe(() => {
					this.elementRef.nativeElement.classList.remove('force-focus');
				});
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe.next(null);
		this._unsubscribe.complete();
		this._unsubscribe = null;
	}
}
