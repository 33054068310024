import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export interface StudentEnrollmentPaperworkRequestArgs {
	guardian?: {
		name: string;
		primary_phone: number;
		primary_email: string;
	};
	paperwork_to_send: number[];
}

export class StudentEnrollmentPaperworkGuardianModel {
	contact_id: number;
	name: string;
}

export class StudentEnrollmentPaperworkAssociatedPaperworkModel {
	id: number;
	file_url: string;
	title: string;
	type_title: string;
}

export class StudentEnrollmentRejectedPaperworkModel extends StudentEnrollmentPaperworkAssociatedPaperworkModel {
	comment: string;
	deleted_on: Date;
	deleted_by: {
		uid: number;
		name: string;
	};
	completed_file_url: string;
	supporting_files: {
		name: string;
		url: string;
	}[];
}

export class StudentEnrollmentPaperworkCurrentPaperworkModel {
	id: number;
	status: string;
	signer_email: string;

	@Type(StudentEnrollmentPaperworkAssociatedPaperworkModel_ClassTransform_Type)
	associated_paperwork: StudentEnrollmentPaperworkAssociatedPaperworkModel[];

	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created_date: Date;

	@Expose({ name: 'signed_timestamp' })
	@Transform(dateTransformer)
	signed_date: Date;
	signing_link: string;
	password: string;
	audit_document_url: string;
}

export class StudentEnrollmentPaperworkStatusModel {
	success: boolean;
	under_18_warning: boolean;

	@Type(StudentEnrollmentRejectedPaperworkModel_ClassTransform_Type)
	rejected_paperwork: StudentEnrollmentRejectedPaperworkModel[];

	@Type(StudentEnrollmentPaperworkAssociatedPaperworkModel_ClassTransform_Type)
	available_paperwork: StudentEnrollmentPaperworkAssociatedPaperworkModel[];

	@Type(StudentEnrollmentPaperworkCurrentPaperworkModel_ClassTransform_Type)
	current_paperwork: StudentEnrollmentPaperworkCurrentPaperworkModel[];

	@Type(StudentEnrollmentPaperworkGuardianModel_ClassTransform_Type)
	guardian: StudentEnrollmentPaperworkGuardianModel;

	@Type(StudentEnrollmentPaperworkAssociatedPaperworkModel_ClassTransform_Type)
	required_activation_paperwork: StudentEnrollmentPaperworkAssociatedPaperworkModel[];
}

export function StudentEnrollmentPaperworkGuardianModel_ClassTransform_Type() {
	return StudentEnrollmentPaperworkGuardianModel;
}

export function StudentEnrollmentPaperworkAssociatedPaperworkModel_ClassTransform_Type() {
	return StudentEnrollmentPaperworkAssociatedPaperworkModel;
}
export function StudentEnrollmentPaperworkCurrentPaperworkModel_ClassTransform_Type() {
	return StudentEnrollmentPaperworkCurrentPaperworkModel;
}
export function StudentEnrollmentPaperworkStatusModel_ClassTransform_Type() {
	return StudentEnrollmentPaperworkStatusModel;
}

export function StudentEnrollmentRejectedPaperworkModel_ClassTransform_Type() {
	return StudentEnrollmentRejectedPaperworkModel;
}
