import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class TimeTrackerEntryModel {
	time_tracker_id: number;
	activity: string;
	@Transform(dateTransformer)
	start_time: Date;
	duration: number; // length of time in seconds
	value: string;
	status: number;
	@Transform(dateTransformer)
	approved: Date;
}

export function TimeTrackerEntryModel_ClassTransform_Type() {
	return TimeTrackerEntryModel;
}

export class TimeTrackerEntryResponseModel {
	date_limit: string;
	time_zone: string; // program time zone
	@Type(TimeTrackerEntryModel_ClassTransform_Type)
	entries: TimeTrackerEntryModel[];
}

export class TimeTrackerOptionModel {
	[key: string]: string;
}

export function TimeTrackerOptionModel_ClassTransform_Type() {
	return TimeTrackerOptionModel;
}

export class TimeTrackerOptionsItemModel {
	title: string;
	@Type(TimeTrackerOptionModel_ClassTransform_Type)
	options: TimeTrackerOptionModel[];
}

export function TimeTrackerOptionsItemModel_ClassTransform_Type() {
	return TimeTrackerOptionsItemModel;
}

export class TimeTrackerTimeRestrictionsModel {
	description: string;
	start_time: string;
	end_time: string;
}

export function TimeTrackerTimeRestrictionsModel_ClassTransform_Type() {
	return TimeTrackerTimeRestrictionsModel;
}

export class TimeTrackerOptionsModel {
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	staff_conference: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	online_coursework: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	tutoring_session: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	learning_labs: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	test_prep: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	career_prep: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	project_learning: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerOptionsItemModel_ClassTransform_Type)
	iep: TimeTrackerOptionsItemModel;
	@Type(TimeTrackerTimeRestrictionsModel_ClassTransform_Type)
	time_restrictions: TimeTrackerTimeRestrictionsModel;
}

export interface TimeTrackerUpsertArgument {
	activities: TimeTrackerActivityArgument[];
	teacher_pin?: number;
	teacher_notes?: string;
}

export interface TimeTrackerActivityArgument {
	activity: string;
	option: string;
	start_time: string;
	end_time: string;
}
