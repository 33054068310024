import { Type } from 'class-transformer';
import {
	RoleManagerMemberOptionModel,
	RoleManagerMemberOptionModel_ClassTransform_Type,
} from './role-manager-team.model';

export class RoleManagerProgramAssignedTeamModel {
	id: number;
	title: string;

	@Type(RoleManagerMemberOptionModel_ClassTransform_Type)
	members: RoleManagerMemberOptionModel[];
}

export function RoleManagerProgramAssignedTeamModel_ClassTransform_Type() {
	return RoleManagerProgramAssignedTeamModel;
}

export class RoleManagerSpecialAssignmentMemberModel extends RoleManagerMemberOptionModel {
	associated_student_assignment: boolean;
}

export function RoleManagerSpecialAssignmentMemberModel_ClassTransform_Type() {
	return RoleManagerSpecialAssignmentMemberModel;
}

export class RoleManagerProgramAssignmentsResponseModel {
	parent_title: string;
	@Type(RoleManagerProgramAssignedTeamModel_ClassTransform_Type)
	assigned_teams: RoleManagerProgramAssignedTeamModel[];
	@Type(RoleManagerSpecialAssignmentMemberModel_ClassTransform_Type)
	special_assignments: RoleManagerSpecialAssignmentMemberModel[];
}
