<div class="mb-3">
	<form [formGroup]="loginForm" (submit)="login()">
		<div aeGroupValidation class="form-group">
			<label for="credential.login.component.email"
				>Sign in with your email</label
			>
			<input
				#emailInput
				aeInputTrim
				autocomplete="email"
				class="form-control"
				formControlName="email"
				id="credential.login.component.email"
				placeholder="Enter your email"
				type="email"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Email is required
				</ng-template>
				<ng-template aeValidationError="email"> Must be an email </ng-template>
			</ae-group-validation-display>
		</div>

		@if (hasValue(loginForm.controls.email.value)) {
			<div aeGroupValidation class="form-group">
				<label
					class="screen-reader-text"
					for="credential.login.component.password"
					>Password</label
				>
				<input
					autocomplete="current-password"
					class="form-control"
					formControlName="password"
					id="credential.login.component.password"
					placeholder="Enter your password"
					type="password"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Password is required
					</ng-template>
				</ae-group-validation-display>
			</div>

			<div
				*ngIf="loginError"
				aria-live="polite"
				class="alert alert-danger"
				role="alert"
			>
				{{ errorMessage }}
			</div>

			<button
				class="btn btn-primary btn-lg btn-block fw-bold"
				id="credential.login.component.login"
				type="submit"
				[disabled]="!loginForm.valid || working"
			>
				<ae-wait-spinner [saving]="working">Sign In</ae-wait-spinner>
			</button>

			<div class="w-100 text-center pt-2">
				<button
					class="btn btn-link"
					type="button"
					[disabled]="working"
					(click)="forgotPassword()"
				>
					Forgot password?
				</button>
			</div>
		}
	</form>
</div>
