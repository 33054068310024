export enum SearchOperators {
	Like = 'LIKE',
	In = 'IN',
	NotIn = 'NOT IN',
	Equals = '=',
	GreaterThan = '>',
	LessThan = '<',
	GreaterThanOrEqual = '>=',
	LessThanOrEqual = '<=',
	IsNull = 'IS NULL',
	IsNotNull = 'IS NOT NULL',
}
