import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject, takeUntil, timer } from 'rxjs';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';

@Component({
	selector: 'ae-modal-close-timeout',
	templateUrl: './modal-close-timeout.component.html',
	styleUrls: ['./modal-close-timeout.component.scss'],
	standalone: true,
	imports: [NgbTooltip],
})
export class ModalCloseTimeoutComponent implements OnDestroy, AfterContentInit {
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() uid: number;
	@Input() id: string = 'modal.close.timeout.default.id';
	@Input() countdown: boolean = true;
	@Input() secondsUntilReady: number = 5;
	@Input() delayMessage: string = 'You can dismiss the modal';
	@Output() closeAction = new EventEmitter();

	@ViewChild('tooltip', { static: true }) tooltip: NgbTooltip;

	constructor(
		private userStoreService: UserStoreService,
		private cdr: ChangeDetectorRef,
	) {}

	ngAfterContentInit(): void {
		timer(0, 1000)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => {
				this.secondsUntilReady--;
				this.cdr.detectChanges();
			});
	}

	public tryClose = () => {
		if (
			this.uid !== this.userStoreService.currentUserUid ||
			!this.countdown ||
			this.secondsUntilReady <= 0
		) {
			this.closeAction.emit();
		} else {
			this.tooltip.open();
		}
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
