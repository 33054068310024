import { Injectable } from '@angular/core';
import { LocalStoreService } from 'src/public.services';
import { TypedStoreType } from 'src/public.types';

@Injectable({
	providedIn: 'root',
})
export class LoginSaveService {
	constructor(private localStore: LocalStoreService) {}

	public getUniqueId = (): string => {
		return this.localStore.get('programUniqueId', TypedStoreType.STRING, '');
	};

	public setUniqueId = (uniqueId: string): void => {
		this.localStore.set('programUniqueId', uniqueId);
	};
}
