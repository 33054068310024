export enum StaffRelationshipTypes {
	academic_coach = 8,
	administrator = 4,
	assistant = 5,
	attendance_coach = 9,
	college_career_counselor = 25,
	counselor = 6,
	enrollment_counselor = 15,
	local_advocate = 7,
	reentry_coach = 34,
	testing_coordinator = 26,
	credentialed_responder = 36,
	grad_coordinator = 37,
	reentry_coordinator = 40,
	tutor = 41,

	// Admin types
	gatekeeper = 11,
	businessManager = 17,
	programManager = 16,
	programRegistrar = 18,
	registrar = 12,
	specialEdDirector = 13,
	transcriptCoordinator = 14,
}
