import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentCourseProgressReportItemModel {
	id: string;
	title: string;
	score: number;
	@Transform(dateTransformer)
	submitted_date: Date;
	@Transform(dateTransformer)
	due_date: Date;
	order: number;
	excused: boolean;
	extra_credit: boolean;
	attempts: number;
	points_earned: number;
	points_possible: number;
	final: boolean;
	@Transform(dateTransformer)
	scored_date: Date;
	allow_retry: boolean;
	retry_implemented: boolean;
	is_teacher_graded: boolean;
}

export function StudentCourseProgressReportItemModel_ClassTransformType() {
	return StudentCourseProgressReportItemModel;
}

export class StudentCourseProgressReportModel {
	title: string;
	current_grade: number;
	calculated_grade: number;
	@Type(StudentCourseProgressReportItemModel_ClassTransformType)
	items: StudentCourseProgressReportItemModel[];
}
