import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { SurveyPeriodModel } from './survey-period.model';

export class SurveyPeriodMemberModel {
	first_name: string;
	last_name: string;
	uid: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	enrollment_status: number;
	is_attending: boolean;
	@Transform(dateTransformer)
	activated_date: Date;
}

export function SurveyPeriodMemberModel_ClassTransform_Type() {
	return SurveyPeriodMemberModel;
}

export class SurveyPeriodMembersModel extends SurveyPeriodModel {
	@Type(SurveyPeriodMemberModel_ClassTransform_Type)
	attending: SurveyPeriodMemberModel[];
	@Type(SurveyPeriodMemberModel_ClassTransform_Type)
	not_attending: SurveyPeriodMemberModel[];
}

export class SurveyPeriodPotentialMembersModel {
	@Type(SurveyPeriodMemberModel_ClassTransform_Type)
	members: SurveyPeriodMemberModel[];
	skip: number;
	take: number;
	count: number;
}

export interface SurveyPeriodEditMembersArgument {
	add_attending: number[];
	add_not_attending: number[];
	remove_attending: number[];
}
