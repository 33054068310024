import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	CourseRequestApproveArgument,
	CourseRequestCreateArgument,
	CourseRequestModel,
} from './course-request-model';
import { CourseRequestOptionsModel } from './course-request-options-model';

@Injectable({
	providedIn: 'root',
})
export class CourseRequestService {
	constructor(private httpClient: HttpClient) {}

	public view = (
		uid: number,
		requestId: number,
	): Observable<CourseRequestModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/classes/requests/${requestId}`)
			.pipe(
				mapResponse((r) => plainToInstance(CourseRequestModel, r.request), {
					errorCode: '678D3C4C',
				}),
			);
	};

	public list = (uid: number): Observable<CourseRequestModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/classes/requests`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CourseRequestModel, r.requests as any[]),
					{
						errorCode: 'CE864D1F',
					},
				),
			);
	};

	public create = (
		uid: number,
		args: CourseRequestCreateArgument,
	): Observable<CourseRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/classes/requests`, args)
			.pipe(
				mapResponse((r) => plainToInstance(CourseRequestModel, r.request), {
					errorCode: 'AA509E84',
				}),
			);
	};

	public approve = (
		uid: number,
		requestId: number,
		args: CourseRequestApproveArgument,
	): Observable<CourseRequestModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/classes/requests/${requestId}/approve`,
				args,
			)
			.pipe(
				mapResponse((r) => plainToInstance(CourseRequestModel, r.request), {
					errorCode: '5D0977B2',
				}),
			);
	};

	public options = (): Observable<CourseRequestOptionsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/registration/requests/options`)
			.pipe(
				mapResponse((r) => plainToInstance(CourseRequestOptionsModel, r), {
					errorCode: '64F92EA6',
				}),
			);
	};

	public cancel = (uid: number, requestId: number): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/classes/requests/${requestId}/cancel`,
				{},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '112CE56B',
				}),
			);
	};

	/*********************/
	/** Course Requests **/
	/*********************/

	public allList = (): Observable<CourseRequestModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/registration/requests?skip=0&take=500`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CourseRequestModel, r.requests as any[]),
					{
						errorCode: '64F92EA6',
					},
				),
			);
	};
}
