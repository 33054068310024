import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	CredentialedResponderUserModel,
	CredentialedResponderUserModel_ClassTransform_Type,
} from '../trackers/credentialed-responder-tracker.model';

export class CredentialedResponseFeedbackModel {
	is_twoway: boolean;
	can_respond: boolean;
	body: string;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	updated_by: CredentialedResponderUserModel;
	responded_date: string;
}

export function CredentialedResponseFeedbackModel_ClassTransform_Type() {
	return CredentialedResponseFeedbackModel;
}

export class CredentialedResponseAnswerModel {
	key: string;
	value: string;
}

export function CredentialedResponseAnswerModel_ClassTransform_Type() {
	return CredentialedResponseAnswerModel;
}

export class CredentialedResponseModel {
	id: number;
	key: string;
	title: string;
	@Transform(dateTransformer)
	submitted_date: Date;
	@Type(CredentialedResponseFeedbackModel_ClassTransform_Type)
	feedback: CredentialedResponseFeedbackModel;
	@Type(CredentialedResponseAnswerModel_ClassTransform_Type)
	answers: CredentialedResponseAnswerModel[];
	flags: string[];
	@Transform(dateTransformer)
	staff_notification_sent_on: Date;
	@Type(CredentialedResponderUserModel_ClassTransform_Type)
	staff_notification_send_by: CredentialedResponderUserModel;
}

export function CredentialedResponseModel_ClassTransform_Type() {
	return CredentialedResponseModel;
}

export interface CredentialedFeedbackArgument {
	feedback: string;
	flags: string[];
	rejected?: boolean;
}

export interface CredentialedStaffNotifyArgument {
	response_ids: number[];
	body: string;
}
