import { Transform } from 'class-transformer';
// eslint-disable-next-line no-restricted-imports
import { integerTransformer } from '../../class-transformer-types';
import { AbstractEnumOption } from './abstract-enum-option.model';

export class NumberEnumOption implements AbstractEnumOption<number> {
	@Transform(integerTransformer)
	key: number;
	title: string;
}

export function NumberEnumOption_ClassTransform_Type() {
	return NumberEnumOption;
}
