<form (ngSubmit)="save()">
	<section #rootElement class="billboard" tabindex="-1">
		<div class="billboard-header">
			<h2 class="billboard-title">Family and Support</h2>
			<div class="billboard-actions">
				<div class="btn-toolbar">
					<button
						class="btn btn-secondary"
						id="profile.contact.edit.cancel"
						type="button"
						(click)="contactSaveListener(false)"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						id="profile.contact.edit.enable"
						type="submit"
						[disabled]="
							savingInfo || !profileContactFormGroup.valid || !hasChanges
						"
					>
						Save Changes
					</button>
				</div>
			</div>
		</div>
		<div class="billboard-body">
			@if (ageGate === true && primaryContactIndex == null) {
				<div class="alert alert-info">
					<i aria-hidden="true" class="fas fa-exclamation-triangle"></i> A
					primary guardian is required to save the contact info for this student
				</div>
			}
			@for (
				item of profileContactFormGroup.controls;
				track item;
				let i = $index;
				let last = $last
			) {
				<ng-container [formGroup]="item">
					@if (item.controls.delete.value === false) {
						<div>
							<!-- Name/Relationship/Description -->
							<div class="row">
								<div aeGroupValidation class="col-xl-6 form-group">
									<label for="profile.contact.edit.name.{{ i }}"> Name* </label>
									<input
										autocomplete="off"
										class="form-control"
										formControlName="name"
										placeholder="Name"
										type="text"
										id="profile.contact.edit.name.{{ i }}"
									/>
									<ae-group-validation-display [maxDisplay]="1">
										<ng-template aeValidationError="required">
											A full name is required
										</ng-template>
									</ae-group-validation-display>
								</div>
								<div aeGroupValidation class="col-xl-3 col-sm-6 form-group">
									<label for="profile.contact.edit.relationship.{{ i }}">
										Relationship*
									</label>
									<ng-select
										bindLabel="value"
										bindValue="key"
										formControlName="relationship"
										required
										[clearable]="false"
										[items]="formattedDropdownRelationship"
										labelForId="profile.contact.edit.relationship.{{ i }}"
									/>
									<ae-group-validation-display [maxDisplay]="1">
										<ng-template aeValidationError="required">
											A relationship is required
										</ng-template>
									</ae-group-validation-display>
								</div>
								@if (
									profileContactFormGroup.controls[i].controls.relationship
										.value === 'Other'
								) {
									<div aeGroupValidation class="col-xl-3 col-sm-6 form-group">
										<label for="profile.contact.edit.description.{{ i }}">
											Describe Relationship*
										</label>
										<input
											autocomplete="off"
											class="form-control"
											formControlName="describeRelationship"
											placeholder="Describe Relationship"
											type="text"
											id="profile.contact.edit.description.{{ i }}"
										/>
										<ae-group-validation-display [maxDisplay]="1">
											<ng-template aeValidationError="required">
												A relationship description is required
											</ng-template>
										</ae-group-validation-display>
									</div>
								}
							</div>
							<div class="row">
								<!-- Name/Relationship/Description -->
								<div class="col-xl-6">
									@for (
										phone of profileContactFormGroup.controls[i].controls.phone
											.controls;
										track phone;
										let phoneFirst = $first;
										let phoneIndex = $index
									) {
										<div class="row">
											<div
												aeGroupValidation
												class="col-xl-4 col-md-6 form-group"
											>
												<label
													class="d-flex"
													for="profile.contact.edit.number.{{ i }}.{{
														phoneIndex
													}}"
												>
													<span class="flex-grow-1">Number</span>
													@if (!phoneFirst) {
														<button
															class="btn btn-icon"
															title="Delete this phone number"
															type="button"
															(click)="deletePhone(i, phoneIndex)"
														>
															<i aria-hidden="true" class="fas fa-times"></i>
														</button>
													}
												</label>
												<input
													autocomplete="off"
													class="form-control"
													placeholder="Number"
													type="text"
													[formControl]="phone.controls.number"
													id="profile.contact.edit.number.{{ i }}.{{
														phoneIndex
													}}"
												/>
												<ae-group-validation-display [maxDisplay]="1">
													<ng-template aeValidationError="notsevendigitphone">
														Must be a 10 digit phone number
													</ng-template>
													<ng-template aeValidationError="phone">
														Must be a phone number
													</ng-template>
													<ng-template aeValidationError="required">
														Phone number is required
													</ng-template>
												</ae-group-validation-display>
											</div>
											<div class="col-xl-4 col-md-3 col-6 form-group">
												<label
													for="profile.contact.edit.type.{{ i }}.{{
														phoneIndex
													}}"
												>
													Type
												</label>
												<ng-select
													bindLabel="value"
													bindValue="key"
													[formControl]="phone.controls.type"
													[items]="formattedDropdownPhoneType"
													labelForId="profile.contact.edit.type.{{ i }}.{{
														phoneIndex
													}}"
												/>
											</div>
											<div class="col-xl-4 col-md-3 col-6 form-group">
												<div class="label-spacer d-block">&nbsp;</div>
												<ae-input-checkbox
													[formControl]="phone.controls.sms"
													id="profile.contact.edit.sms.{{ i }}.{{ phoneIndex }}"
												>
													SMS
												</ae-input-checkbox>
											</div>
										</div>
									}
								</div>
								<div class="col-xl-6">
									@for (
										email of profileContactFormGroup.controls[i].controls.email
											.controls;
										track email;
										let emailIndex = $index;
										let emailFirst = $first
									) {
										<div aeGroupValidation class="form-group">
											<label
												class="d-flex"
												for="profile.contact.edit.email.{{ i }}.{{
													emailIndex
												}}"
											>
												<span class="flex-grow-1">Email</span>
												@if (!emailFirst) {
													<button
														class="btn btn-icon"
														title="Delete this email address"
														type="button"
														(click)="deleteEmail(i, emailIndex)"
													>
														<i aria-hidden="true" class="fas fa-times"></i>
													</button>
												}
											</label>
											<input
												autocomplete="off"
												class="form-control"
												placeholder="Email"
												type="text"
												[formControl]="email.controls.email"
												id="profile.contact.edit.email.{{ i }}.{{ emailIndex }}"
											/>
											<ae-group-validation-display [maxDisplay]="1">
												<ng-template aeValidationError="email">
													Must be a valid email address
												</ng-template>
												<ng-template aeValidationError="required">
													An email is required for parent account.
												</ng-template>
											</ae-group-validation-display>
										</div>
									}
								</div>
							</div>
							<div class="row">
								<div class="col-xl-12 d-flex">
									<div class="flex-grow-1">
										<label>
											<input
												class="screen-reader-text"
												type="checkbox"
												[disabled]="savingInfo"
												title="{{
													i === primaryContactIndex
														? 'Primary Contact'
														: 'Set as Primary Contact'
												}}"
												(click)="setPrimaryContact(i)"
											/>
											@switch (i === primaryContactIndex) {
												@case (true) {
													<i
														aria-hidden="true"
														class="fas fa-check-square pointer"
													></i>
												}
												@default {
													<i
														aria-hidden="true"
														class="far fa-square pointer"
													></i>
												}
											}
											<span class="unselectable pointer">
												Is Primary Parent or Guardian
											</span>
										</label>
									</div>
									<button
										class="btn btn-default"
										type="button"
										[disabled]="savingInfo"
										id="profile.contact.edit.address.{{ i }}.delete"
										(click)="deleteContact(i)"
									>
										Delete Contact
									</button>
								</div>
							</div>
							<hr />
						</div>
					}
				</ng-container>
			}
			<div class="text-center">
				<button class="btn btn-default" type="button" (click)="addContact()">
					Add Contact
				</button>
			</div>
		</div>
	</section>
</form>
