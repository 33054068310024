import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	CurriculumMappingEndorsementAssignedModel,
	CurriculumMappingEndorsementAssignedModel_ClassTransform_Type,
} from '../endorsements/curriculum-mapping-endorsement.model';

export class CurriculumMappingCertificationModel {
	id: number;
	teacher_uid: number;
	teacher_name: string;
	teacher_active: boolean;
	state: string;
	certification_number: string;
	@Transform(dateTransformer)
	valid_from: Date;
	@Transform(dateTransformer)
	valid_until: Date;
	file_route: string;
	@Type(CurriculumMappingEndorsementAssignedModel_ClassTransform_Type)
	endorsements: CurriculumMappingEndorsementAssignedModel[];
	bypass_endorsement_check: boolean;
}

export function CurriculumMappingCertificationModel_ClassTransform_Type() {
	return CurriculumMappingCertificationModel;
}

export class CurriculumMappingCertificationListResponseModel {
	count: number;
	@Type(CurriculumMappingCertificationModel_ClassTransform_Type)
	certifications: CurriculumMappingCertificationModel[];
}

export interface CurriculumMappingCertificationsUpsertArgument {
	uid?: number; // can not edit
	state?: string; // can not edit
	certification_number?: string;
	valid_from?: number;
	valid_until?: number;
	bypass_endorsement_check?: boolean;
	endorsements?: CurriculumMappingCertificationsUpsertEndorsementArgument[];
}

export interface CurriculumMappingCertificationsUpsertEndorsementArgument {
	endorsement_id: number;
	max_grade_level: number;
	min_grade_level: number;
}

export interface CurriculumMappingCertificationsPagingArgument {
	limit_to_active: { value: boolean };
	state: PagingSearchFieldArgument;
	teacher_name: PagingSearchFieldArgument;
	certification_number: PagingSearchFieldArgument;
	valid_from: PagingSearchFieldArgument;
	valid_until: PagingSearchFieldArgument;
	bypass_endorsement_check: { value: boolean };
	endorsement_name: PagingSearchFieldArgument;
	teacher_active: boolean;
}
