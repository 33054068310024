import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { OnlineStatus } from 'src/lib/services/stores/online-status-store/online-status.enum';
import { WampService } from '../../wamp.service';
import { CommonUserStatusChanged } from './common-event-types';

@Injectable({
	providedIn: 'root',
})
export class CommonWampEventFactoryService {
	constructor(private wampService: WampService) {}

	public userStatusChanged$ = () => {
		return this.wampService
			.subscribe$<[number, OnlineStatus]>('ga.link_current_status')
			.pipe(
				map(([linkId, status]) => {
					return {
						linkId: linkId,
						status: status,
					} as CommonUserStatusChanged;
				}),
			);
	};
}
