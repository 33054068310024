<div *aeSpinWhile="loading">
	@if (!errorLoading) {
		<ae-labeled-display label="Need Help?">
			Reach out to our tech support team with any questions or concerns about
			your login
		</ae-labeled-display>
		<ae-labeled-display [label]="helpDeskInfo?.relation">
			{{ helpDeskInfo?.name | ifEmpty }}
		</ae-labeled-display>
		<ae-labeled-display label="Call">
			{{ helpDeskInfo?.phone | phone | ifEmpty }}
		</ae-labeled-display>
		<ae-labeled-display label="Email">
			{{ helpDeskInfo?.email | ifEmpty }}
		</ae-labeled-display>
	} @else {
		<ae-labeled-display label="Need Help?">
			We're sorry, but we were unable to load the help desk information, please
			try reloading
		</ae-labeled-display>
	}
</div>
