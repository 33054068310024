<!-- Message Main Render -->
<div
	#messageRow
	class="message-row"
	[ngClass]="{
		'my-message': isMyMessage,
		'others-message': !isMyMessage,
		'system-message': message.message_type === MessageType.system,
		'reminder-message': message.message_type === MessageType.reminder,
		'full-width-message': fullWidth,
		'collapse-message': collapsed(),
		mobile: isMobile,
	}"
	(focus)="(null)"
	(mouseover)="loadWraps()"
>
	<div class="message-container">
		@if (participant) {
			<div aria-hidden="true" class="message-header">
				@switch (peekUser != null) {
					@case (true) {
						<button
							class="btn btn-sm"
							title="View Active Channels"
							type="button"
							(click)="peekUser(message.message_sender_link_id)"
						>
							{{ participant.name }}
						</button>
					}
					@default {
						<span class="mx-2 my-1">
							{{ participant.name }}
						</span>
					}
				}
				@if (!isMobile && !isStudent) {
					<ae-gabby-participant-label [participant]="participant" />
				}
			</div>
		}
		<div aria-hidden="true" class="system-message-header">
			@switch (peekUser != null) {
				@case (true) {
					<div class="btn btn-sm fw-bold" title="System Message" type="button">
						GradBot
					</div>
				}
				@default {
					<span class="mx-2 my-1" title="System Message"> GradBot </span>
				}
			}
		</div>
		<div aria-hidden="true" class="reminder-message-header">
			@switch (peekUser != null) {
				@case (true) {
					<div class="btn btn-sm fw-bold" title="Reminder" type="button">
						Reminder
					</div>
				}
				@default {
					<span class="mx-2 my-1" title="Reminder"> Reminder </span>
				}
			}
		</div>
		<div class="message-main">
			<div class="content-spacer"></div>

			<!-- Readers -->
			@if (!isMobile) {
				@if (wrapsLoaded) {
					<div
						aria-hidden="true"
						class="message-bubble-read"
						container="body"
						[disableTooltip]="readers.length === 0"
						[ngbTooltip]="messageReadHover"
						[openDelay]="500"
						[placement]="isMyMessage ? 'left' : 'right'"
					>
						<ng-container [ngTemplateOutlet]="mainReader" />
					</div>
				} @else {
					<div aria-hidden="true" class="message-bubble-read">
						<ng-container [ngTemplateOutlet]="mainReader" />
					</div>
				}
			}

			<!-- Content -->
			@if (message.message !== '') {
				<div class="message-bubble">
					<p id="gabby-chat-message-row-message-body-{{ message.message_id }}">
						<!-- DO NOT CHANGE FORMATTING. IT'S INTENTIONALLY SMASHED TOGETHER TO ENCOURAGE SCREEN READERS TO READ AS ONE BLOCK -->
						<span class="screen-reader-text">
							@switch (true) {
								@case (isMyMessage) {
									<span>You said </span>
								}
								@case (!isMyMessage && participant != null) {
									<span>{{ participant.name }} said </span>
								}
								@default {
									<span>System message </span>
								}
							}
						</span>
						@switch (message.html_message) {
							@case (true) {
								<span [innerHtml]="htmlMessage() | dompurify"></span>
							}
							@default {
								<span [innerHtml]="linkifiedMessage() | dompurify"></span>
							}
						}
					</p>
				</div>
			}

			<!-- Meta -->
			@if (!isMobile) {
				@if (wrapsLoaded) {
					@switch (collapsed()) {
						@case (true) {
							<div
								aria-hidden="true"
								class="message-bubble-transmit"
								container="body"
								[ngbTooltip]="messageCollapseHover"
								[openDelay]="500"
								[placement]="isMyMessage ? 'top-left' : 'top-right'"
								[tooltipClass]="'tooltip-lg'"
							>
								<ng-container [ngTemplateOutlet]="mainMeta" />
							</div>
						}
						@default {
							<div
								aria-hidden="true"
								class="message-bubble-transmit"
								container="body"
								[ngbTooltip]="messageTransmitHover"
								[openDelay]="500"
								[placement]="isMyMessage ? 'top-right' : 'top-left'"
							>
								<ng-container [ngTemplateOutlet]="mainMeta" />
							</div>
						}
					}
				} @else {
					<div aria-hidden="true" class="message-bubble-transmit">
						<ng-container [ngTemplateOutlet]="mainMeta" />
					</div>
				}
			}
			@if (isMobile) {
				<div aria-hidden="true" class="mobile-message-bubble-transmit">
					<ng-container [ngTemplateOutlet]="mainMeta" />
				</div>
			}
		</div>
	</div>
</div>

<!--------------------------------->
<!----- Message Main Templates ---->
<!--------------------------------->
<ng-template #mainReader>
	@switch (readers.length) {
		@case (0) {
			<span>
				<i aria-hidden="true" class="fal fa-check-circle stack-under"></i>
			</span>
		}
		@case (1) {
			<span>
				<i aria-hidden="true" class="fas fa-circle stack-under"></i>
				<i aria-hidden="true" class="fas fa-check fa-inverse stack-over"></i>
			</span>
		}
		@default {
			<span>
				<i aria-hidden="true" class="fas fa-circle stack-under"></i>
				<i
					aria-hidden="true"
					class="fas fa-check-double fa-inverse stack-over"
				></i>
			</span>
		}
	}
</ng-template>

<ng-template #mainMeta>
	@switch (isMyMessage) {
		<!-- Message Transmit Deliveries -->
		@case (true) {
			<i
				aria-hidden="true"
				class="fas fa-file-import"
				[class.show-sms-marker]="
					messageDeliveryPlatforms.get(MessagePlatform.sms) > 0
				"
				[class.text-danger]="failedMessageDeliveries.length > 0"
			></i>
		}
		<!-- Message Transmit Creation -->
		@case (false) {
			@switch (message.message_send_medium) {
				@case (MessagePlatform.sms) {
					<i aria-hidden="true" class="fas fa-mobile-screen fa-lg"></i>
				}
				@case (MessagePlatform.unknown) {
					<i aria-hidden="true" class="fas fa-mobile-screen fa-lg"></i>
				}
				@case (MessagePlatform.chat) {
					<i aria-hidden="true" class="fas fa-comment fa-lg"></i>
				}
				@case (MessagePlatform.system) {
					<i
						aria-hidden="true"
						class="fa-lg fas"
						[ngClass]="{
							'fa-robot': message.message_type === MessageType.system,
							'fa-bell-on': message.message_type === MessageType.reminder,
						}"
					></i>
				}
				@default {
					<i
						aria-hidden="true"
						class="fas fa-bug fa-lg"
						title="Unknown message platform. Please contact system administrator. {{
							message.message_send_medium
						}}"
					></i>
				}
			}
		}
	}
</ng-template>

<!--------------------------------->
<!----- Main Wrapper Templates ---->
<!--------------------------------->
<!--
          *	This whole wrapping solution is for performance reasons.
          *	ngbTooltip on init was causing a massive slowdown.
          -->

<!-- Reader -->

<!-- Meta -->

<!--------------------------------->
<!------ Popover Templates -------->
<!--------------------------------->
<ng-template #messageTransmitHover>
	<div class="text-nowrap">
		<div>
			{{
				adjustedTime(created)
					| spaceFormat
						: '{month-short} {date} {year}, {hour}:{minute-pad} {ampm}'
			}}
		</div>
		@if (message.sms_from_number) {
			<div>
				{{ message.sms_from_number | phone }}
			</div>
		}
		@if (message.sms_to_numbers != null && message.sms_to_numbers.length > 0) {
			<div class="mt-1">
				<div>Sent to:</div>
				@for (number of message.sms_to_numbers; track number) {
					<div>
						{{ number | phone }}
					</div>
				}
			</div>
		}
		@for (failure of failedMessageDeliveries; track failure; let i = $index) {
			<div>
				@if (i === 0) {
					<div class="mt-2 fw-bold">Delivery Failures</div>
				}
				@switch (failure.id) {
					@case (MessagePlatform.sms) {
						<div>
							{{ failure.value | phone }}
						</div>
					}
					@default {
						<div>{{ failure.value }}</div>
					}
				}
			</div>
		}
	</div>
</ng-template>

<ng-template #messageCollapseHover>
	<div class="text-start">
		<div class="text-nowrap mb-2 border-bottom w-100">
			<span class="fw-bold">
				@switch (message.message_type) {
					@case (MessageType.system) {
						GradBot
					}
					@case (MessageType.reminder) {
						Reminder
					}
					@default {
						{{ participant?.name }}
					}
				}
			</span>
			-
			<span class="fst-italic">
				{{
					adjustedTime(created)
						| spaceFormat
							: '{month-short} {date} {year}, {hour}:{minute-pad} {ampm}'
				}}
			</span>
		</div>
		<div>
			@switch (message.html_message) {
				@case (true) {
					<div [innerHtml]="htmlMessage() | dompurify"></div>
				}
				@default {
					<div [innerHtml]="linkifiedMessage() | dompurify"></div>
				}
			}
		</div>
	</div>
</ng-template>

<ng-template #messageReadHover>
	@for (reader of readers; track reader) {
		<div>
			<div>
				{{ reader.name }}
			</div>
		</div>
	}
</ng-template>

<!-- Message Post Renders -->
@for (postRender of postRenders; track postRenderTrackBy($index, postRender)) {
	@switch (postRender.type) {
		<!----------------->
		<!-- Attachments -->
		<!----------------->
		<!-- Image Attachment -->
		@case (PostMessageRenderType.AttachmentImage) {
			<div
				class="message-attachment"
				[aeGabbyChatMessagePostRender]="postRender.key"
				[ngClass]="{
					'my-message': isMyMessage,
					'others-message': !isMyMessage,
					'system-message': message.message_type === MessageType.system,
					'reminder-message': message.message_type === MessageType.reminder,
					'full-width-message': fullWidth,
				}"
				[renderType]="PostMessageRenderType.AttachmentImage"
			>
				<div aria-hidden="true" class="message-attachment-filename">
					{{ postRender.filename }}
					<ae-gabby-chat-attachment-hider
						#imghider
						[attachmentIndex]="postRender.index"
						[messageId]="message.message_id"
					/>
				</div>
				@if (!imghider.isHidden) {
					<a class="unselectable" target="_blank" [href]="postRender.url">
						<img
							alt="Image shared by {{ participant?.name }}"
							[src]="postRender.url"
						/>
					</a>
				}
			</div>
		}
		<!-- Video Attachment -->
		@case (PostMessageRenderType.AttachmentVideo) {
			<div
				class="message-attachment"
				[aeGabbyChatMessagePostRender]="postRender.key"
				[ngClass]="{
					'my-message': isMyMessage,
					'others-message': !isMyMessage,
					'system-message': message.message_type === MessageType.system,
					'reminder-message': message.message_type === MessageType.reminder,
					'full-width-message': fullWidth,
				}"
				[renderType]="PostMessageRenderType.AttachmentVideo"
			>
				<div aria-hidden="true" class="message-attachment-filename">
					{{ postRender.filename }}
					<ae-gabby-chat-attachment-hider
						#vidhider
						[attachmentIndex]="postRender.index"
						[messageId]="message.message_id"
					/>
				</div>
				@if (!vidhider.isHidden) {
					<video
						class="unselectable"
						controls
						preload="metadata"
						[src]="postRender.url"
					></video>
				}
			</div>
		}
		<!-- File Attachment -->
		@case (PostMessageRenderType.AttachmentFile) {
			<div
				class="message-attachment"
				[aeGabbyChatMessagePostRender]="postRender.key"
				[ngClass]="{
					'my-message': isMyMessage,
					'others-message': !isMyMessage,
					'system-message': message.message_type === MessageType.system,
					'reminder-message': message.message_type === MessageType.reminder,
					'full-width-message': fullWidth,
				}"
				[renderType]="PostMessageRenderType.AttachmentFile"
			>
				<a target="_blank" [href]="postRender.url">
					<span class="screen-reader-text">
						File shared by {{ participant?.name }}
					</span>
					<div>{{ postRender.filename }}</div>
					<i aria-hidden="true" class="fas fa-file-download fa-3x"></i>
				</a>
			</div>
		}
		<!------------->
		<!-- Markers -->
		<!------------->
		<!-- Unread marker -->
		@case (PostMessageRenderType.UnreadMarker) {
			<div
				class="block-marker unread-messages-marker"
				[aeGabbyChatMessagePostRender]="postRender.key"
				[renderType]="PostMessageRenderType.UnreadMarker"
			>
				<div class="messages-break-row">
					<div class="messages-break-line"></div>
					<div class="messages-break-content text-uppercase">
						Unread Messages
					</div>
					<div class="messages-break-line"></div>
				</div>
			</div>
		}
		<!-- Date marker -->
		@case (PostMessageRenderType.DateMarker) {
			<div
				class="block-marker"
				[aeGabbyChatMessagePostRender]="postRender.key"
				[renderType]="PostMessageRenderType.DateMarker"
			>
				<div class="messages-break-row">
					<div class="messages-break-line"></div>
					<div
						class="messages-break-content text-uppercase"
						title="{{
							adjustedTime(postRender.date)
								| spaceFormat: '{timezone} ({offset})'
						}}"
					>
						{{
							adjustedTime(postRender.date)
								| spaceFormat: '{month} {date}, {day}'
						}}
					</div>
					<div class="messages-break-line"></div>
				</div>
			</div>
		}
		<!-- Time marker -->
		@case (PostMessageRenderType.TimeMarker) {
			<div
				class="block-marker text-center text-uppercase"
				[aeGabbyChatMessagePostRender]="postRender.key"
				[renderType]="PostMessageRenderType.TimeMarker"
				title="{{
					adjustedTime(postRender.date) | spaceFormat: '{timezone} ({offset})'
				}}"
			>
				{{
					adjustedTime(postRender.date)
						| spaceFormat: '{hour}:{minute-pad} {ampm}'
				}}
			</div>
		}
		<!-- Unhandled Render Type -->
		@default {
			<div class="text-center text-danger">
				Unhandled Post Render - Please Inform Your Administrator
			</div>
		}
	}
}
