<div class="modal-header">
	<h1 class="modal-title">Upload Profile Image</h1>
	<button
		class="btn btn-outline-dark"
		title="Close"
		type="button"
		(click)="close(false)"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>
<div class="modal-body" [formGroup]="fileGroup">
	<div class="alert alert-info" role="alert">
		Remember that this is a school site and your profile picture should be
		school appropriate.
	</div>
	<ae-file-uploader
		formControlName="file"
		id="profile.navigation.image.upload.file"
		[extensionsAllowed]="['jpg', 'jpeg', 'png', 'gif']"
		[maxSizeBytes]="maxProfileSize"
		[previewImageClass]="'img-thumbnail p-0 rounded-circle profile-image'"
	/>
</div>
<div class="modal-footer">
	<div class="btn-toolbar">
		<button class="btn btn-secondary" type="button" (click)="close(false)">
			Cancel
		</button>
		<button
			class="btn btn-primary"
			type="submit"
			[disabled]="!fileGroup.valid || isSaving"
			(click)="save()"
		>
			Save
		</button>
	</div>
</div>
