import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class ECTrackerRegistrationChecklistItemModel {
	can_waive: boolean;
	passed: boolean;
	requires_file: false;
	title: string;
	type: string;
	type_title: string;
}

export class ECTrackerEnrollmentChecklistItemModel {
	is_completed: boolean;
	is_required: boolean;
	key: string;
	title: string;
}

export class ECTrackerItemModel {
	@Transform(dateTransformer)
	access: Date;
	@Transform(dateTransformer)
	activated: Date;

	@Transform(dateTransformer)
	assigned_at: Date;
	assigned_first: string;
	assigned_last: string;
	assigned_uid: number;

	assigned_la_first: string;
	assigned_la_last: string;
	assigned_la_uid: number;

	cohort_year: number;
	@Transform(dateTransformer)
	enrolled: Date;
	@Type(ECTrackerEnrollmentChecklistItemModel_ClassTransform_Type)
	enrollment_checklist: ECTrackerEnrollmentChecklistItemModel[];
	@Type(ECTrackerRegistrationChecklistItemModel_ClassTransform_Type)
	registration_checklist: ECTrackerRegistrationChecklistItemModel[];

	institute_id: number;

	is_1418: number;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	mail: string;

	@Transform(dateTransformer)
	deactivated_time: Date;

	@Expose({ name: 'parent_title' })
	program_parent_title: string;
	@Expose({ name: 'title' })
	program_title: string;
	state_program: string;

	phone: number;
	phone_type: string;

	profile_first_name: string;
	profile_preferred_name: string;
	profile_last_name: string;
	profile_student_year: string;

	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];

	reenrolled: boolean;
	timezone: string;
	uid: number;
	salesforce_url: string;
	tags: string[];
}

export function ECTrackerItemModel_ClassTransform_Type() {
	return ECTrackerItemModel;
}

export class ECTrackerModel {
	count: number;
	skip: number;
	take: number;
	@Type(ECTrackerItemModel_ClassTransform_Type)
	results: ECTrackerItemModel[];
}

export function ECTrackerEnrollmentChecklistItemModel_ClassTransform_Type() {
	return ECTrackerEnrollmentChecklistItemModel;
}
export function ECTrackerRegistrationChecklistItemModel_ClassTransform_Type() {
	return ECTrackerRegistrationChecklistItemModel;
}
