<div class="modal-header">
	<h1 class="modal-title">{{ isEditing ? 'Edit' : 'View' }} Event</h1>
	<button
		class="btn btn-outline-dark"
		id="lunchbox.pages.events.view.modal.close.times"
		title="Close Add Class"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div *aeSpinWhile="loading" class="modal-body">
	@if (!isEditing) {
		<ae-event-view [currentEvent]="currentEvent" [studentId]="studentId" />
	} @else {
		<ae-event-upsert
			#eventUpsert
			[editEvent]="currentEvent"
			[studentId]="studentId"
			(isFormValid)="isFormValid($event)"
		/>
	}
</div>
<div class="modal-footer">
	@if (!isEditing) {
		<button
			class="btn btn-secondary"
			id="lunchbox.pages.events.view.modal.close.button"
			type="button"
			(click)="close()"
		>
			Close
		</button>
		@if (canEditEvent) {
			<button
				class="btn btn-primary"
				id="lunchbox.pages.events.view.modal.edit.button"
				type="button"
				(click)="isEditing = true"
			>
				Edit
			</button>
		}
	} @else {
		@if (openEdit == null) {
			<button
				class="btn btn-secondary"
				id="lunchbox.pages.events.view.modal.edit.cancel.button"
				type="button"
				(click)="isEditing = false"
			>
				Cancel
			</button>
		}
		@if (openEdit != null) {
			<button
				class="btn btn-secondary"
				id="lunchbox.pages.events.view.modal.edit.close.button"
				type="button"
				(click)="close()"
			>
				Close
			</button>
		}
		<button
			class="btn btn-secondary"
			id="lunchbox.pages.events.view.modal.reset.button"
			type="button"
			(click)="reset()"
		>
			Reset
		</button>
		@if (canEditEvent) {
			<button
				class="btn btn-primary"
				id="lunchbox.pages.events.view.modal.save.button"
				type="button"
				[disabled]="!isValid || saving"
				(click)="save()"
			>
				<ae-wait-spinner [saving]="saving"> Save </ae-wait-spinner>
			</button>
		}
	}
</div>
