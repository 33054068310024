import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { hasValue } from '../utilities/compare';
import { ConfirmExitGuardModalComponent } from '../views/modals/confirm-exit-guard-modal/confirm-exit-guard-modal.component';

export interface ICanExitItem {
	safeExit: boolean;
	warningMessage?: string;
}

export interface IConfirmExit {
	canExit$: () => Observable<ICanExitItem>;
}

@Injectable({
	providedIn: 'root',
})
export class ConfirmExitGuard {
	constructor(private modalService: NgbModal) {}

	public canDeactivate(
		component: IConfirmExit,
		_currentRoute: ActivatedRouteSnapshot,
		_currentState: RouterStateSnapshot,
		_nextState: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		return component.canExit$().pipe(
			switchMap((x) => {
				if (!x.safeExit && hasValue(x?.warningMessage)) {
					const askModal = this.modalService.open(
						ConfirmExitGuardModalComponent,
						{
							size: 'sm',
							backdrop: 'static',
						},
					);

					(
						askModal.componentInstance as ConfirmExitGuardModalComponent
					).bindModalData({
						warningMessage: x.warningMessage,
					});

					return from(askModal.result).pipe(
						map(() => true),
						catchError(() => of(false)),
					);
				}

				return of(x.safeExit);
			}),
		);
	}
}
