import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupModel } from 'src/lib/services/api/generic/groups/group.model';
import { GroupsService } from 'src/lib/services/api/generic/groups/groups.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class GroupsStoreService {
	private _groupsCache = new BehaviorCache<
		'user' | 'institute' | 1, // 1 is a non null value
		GroupModel[]
	>(
		(groupType) => {
			if (groupType === 1) {
				return this.groupsService.getGroups();
			} else {
				return this.groupsService.getGroups(groupType);
			}
		},
		'GroupsStoreService GroupsCache',
		() => [],
	);

	constructor(private groupsService: GroupsService) {}

	/**
	 * Groups
	 */
	public groups$ = (
		groupType?: 'user' | 'institute',
	): Observable<GroupModel[]> => {
		return this._groupsCache.getCache(groupType ?? 1);
	};

	public refreshGroups = (
		groupType?: 'user' | 'institute',
	): Promise<boolean> => {
		return this._groupsCache.fetchData(groupType ?? 1, true);
	};
}
