import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { ReEntryStatus } from '../../students/re-entry-request/re-entry-request.model';
import { StudentEnrollmentPaperworkStatusModel_ClassTransform_Type } from '../../students/student-enrollment-paperwork-request.model';

export class PaperworkManagerBaseRequirementModel {
	first_name: string;
	last_name: string;
	uid: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	missing_activation_paperwork: string[];
	@Transform(dateTransformer)
	withdrawn_date: Date;
	requested_paperwork: string[];
	papergate_enabled: boolean;
	@Type(StudentEnrollmentPaperworkStatusModel_ClassTransform_Type)
	pending_paperwork_requests: number;
	@Transform(dateTransformer)
	oldest_paperwork_request_date: Date;
	@Transform(dateTransformer)
	wslp_last_generated: Date;
}

export class PaperworkManagerRequirementsModel extends PaperworkManagerBaseRequirementModel {
	enrollment_status: number;
	@Transform(dateTransformer)
	enrolled_date: Date;
	@Transform(dateTransformer)
	activated_date: Date;
}

export function PaperworkManagerRequirementsModel_ClassTransform_Type() {
	return PaperworkManagerRequirementsModel;
}

export class PaperworkManagerRequirementsResponseModel {
	@Type(PaperworkManagerRequirementsModel_ClassTransform_Type)
	results: PaperworkManagerRequirementsModel[];
	count: number;
}

export class PaperworkManagerReEntryRequirementsModel extends PaperworkManagerBaseRequirementModel {
	@Transform(dateTransformer)
	reentry_created_on: Date;
	reentry_status: ReEntryStatus;
}

export function PaperworkManagerReEntryRequirementsModel_ClassTransform_Type() {
	return PaperworkManagerReEntryRequirementsModel;
}

export class PaperworkManagerReEntryRequirementsResponseModel {
	@Type(PaperworkManagerReEntryRequirementsModel_ClassTransform_Type)
	results: PaperworkManagerReEntryRequirementsModel[];
	count: number;
}

export class PaperworkManagerProgramPaperworkOptionModel {
	paperwork_id: number;
	title: string;
}

export class PaperworkManagerBulkSendRequestCreatedModel {
	paperwork_ids: Record<number, number>;
	paperwork_request_id: number;
	student_id: string;
}

export function PaperworkManagerBulkSendRequestCreatedModel_ClassTransform_Type() {
	return PaperworkManagerBulkSendRequestCreatedModel;
}

export class PaperworkManagerBulkSendRequestErrorModel {
	student_id: string;
	message: string;
}

export function PaperworkManagerBulkSendRequestErrorModel_ClassTransform_Type() {
	return PaperworkManagerBulkSendRequestErrorModel;
}

export class PaperworkManagerBulkSendRequestModel {
	@Type(PaperworkManagerBulkSendRequestCreatedModel_ClassTransform_Type)
	created_requests: PaperworkManagerBulkSendRequestCreatedModel[];
	@Type(PaperworkManagerBulkSendRequestErrorModel_ClassTransform_Type)
	errors: PaperworkManagerBulkSendRequestErrorModel[];
}

export interface PaperworkManagerRequirementsSearchArgument {
	uid: PagingSearchFieldArgument;
	institute_title: PagingSearchFieldArgument;
	institute_id: PagingSearchFieldArrayArgument;
	institute_type: PagingSearchFieldArrayArgument;
	institute_state: PagingSearchFieldArrayArgument;
	missing_activation_paperwork: PagingSearchFieldArgument;
	papergate_enabled: { value: boolean };
	requested_paperwork: PagingSearchFieldArgument;
	pending_paperwork_requests: PagingSearchFieldArgument;
	oldest_paperwork_request_date: PagingSearchFieldArgument;
	wslp_last_generated: PagingSearchFieldArgument;
	first_name?: PagingSearchFieldArgument;
	last_name?: PagingSearchFieldArgument;
	legal_full_name?: PagingSearchFieldArgument;
}

export interface PaperworkManagerEnrollmentRequirementsArgument
	extends PaperworkManagerRequirementsSearchArgument {
	enrolled_date: PagingSearchFieldArgument;
	activated_date: PagingSearchFieldArgument;
}

export interface PaperworkManagerReEntryRequirementsArgument
	extends PaperworkManagerRequirementsSearchArgument {
	reentry_status: PagingSearchFieldArrayArgument;
	reentry_created_on: PagingSearchFieldArgument;
	withdrawn_date: PagingSearchFieldArgument;
}

export interface PaperworkManagerSetGateArgument {
	uid: number;
	is_gated: boolean;
}

export interface PaperworkManagerBulkRequestArgument {
	student_ids: number[];
	paperwork_ids: number[];
	only_send_missing: boolean;
}
