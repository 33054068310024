import { Type } from 'class-transformer';

export class PermissionOverrideModel {
	role: string;
	revoked: string[];
	granted: string[];
}

export function PermissionOverrideModel_ClassTransform_Type() {
	return PermissionOverrideModel;
}

export class PermissionProgramOverrideModel {
	id: number | string;
	@Type(PermissionOverrideModel_ClassTransform_Type)
	overrides: PermissionOverrideModel[];
}

export enum PermissionOverrideType {
	institute = 'institute',
	itype = 'institute_itype',
}
