import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentReminderModel {
	message: string;
	@Transform(dateTransformer)
	message_creation: Date;
	recipient_phone_number: number;
	sender_phone_number: number;
	message_topic: string;
	sender: string;
}

export function StudentReminderModel_ClassTransform_Type() {
	return StudentReminderModel;
}
