import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, Observable, takeUntil } from 'rxjs';
import { MinimumBrowserVersion } from 'src/lib/constants/constants';

@Injectable({
	providedIn: 'root',
})
export class TechBannerChromeVersionService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	constructor(private toastr: ToastrService) {}

	public checkVersion$() {
		return new Observable<null>((o) => {
			const version = this.getChromeVersion();

			if (version && version < MinimumBrowserVersion.Chrome) {
				this.toastr.warning(
					'Your browser is out of date and no longer supported. Your experience may be degraded until you update to a newer version. If you have a Chromebook, you may need to upgrade your device. Contact help desk with any questions you have.',
					'Outdated Chrome Version',
					{
						disableTimeOut: true,
						positionClass: 'toast-top-full-width',
						closeButton: true,
						tapToDismiss: false,
						toastClass: 'ngx-toastr no-pointer',
					},
				);
			}
			o.complete();
		}).pipe(takeUntil(this._unsubscribe$));
	}

	// https://stackoverflow.com/questions/4900436/how-to-detect-the-installed-chrome-version/4900484#4900484
	private getChromeVersion() {
		const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

		return raw ? parseInt(raw[2], 10) : false;
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
