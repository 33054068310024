import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AsyncSubject,
	BehaviorSubject,
	Observable,
	of,
	Subject,
	timer,
} from 'rxjs';
import {
	catchError,
	delay,
	exhaustMap,
	filter,
	map,
	pairwise,
	startWith,
	switchMap,
	take,
	tap,
} from 'rxjs/operators';
import { UserSetting } from './user-setting.enum';
import { UserSettingsArgument } from './user-settings.argument';

export class UserSettingsApiModel {
	[key: string]: any;
}

@Injectable({
	providedIn: 'root',
})
export class UserSettingsStoreService {
	private _loadedSettings = new BehaviorSubject<UserSettingsApiModel>({});
	public _getBusSubject = new Subject<null>();
	public _saveBusSubject = new Subject<null>();

	private _waitingGetPassengers: {
		subject: AsyncSubject<null>;
		keys: string[];
	}[] = [];
	private _waitingSavePassengers: {
		subject: AsyncSubject<boolean>;
		args: UserSettingsArgument;
	}[] = [];

	constructor(private httpClient: HttpClient) {
		this.initGetBus();
		this.initSaveBus();
	}

	getSetting$<T>(
		key: UserSetting | string,
		useCache: boolean = true,
	): Observable<T> {
		return this.getSettings$([key], useCache).pipe(map((x) => x.get(key)));
	}

	getSettings$(
		keys: UserSetting[] | string[],
		useCache: boolean = true,
	): Observable<Map<string, any>> {
		return this._loadedSettings.pipe(
			take(1),
			switchMap((settings) => {
				let allTrue = useCache;
				keys.forEach((x) => (allTrue = allTrue && settings[x] != null));

				if (allTrue) {
					// Use the loaded settings
					return this._loadedSettings.asObservable();
				} else {
					// Build a load passenger and wait
					const sub = new AsyncSubject<null>();
					this._waitingGetPassengers.push({
						keys: keys.slice(),
						subject: sub,
					});
					this._getBusSubject.next(null);

					// Wait for the bus to finish then use loaded settings
					return sub.pipe(switchMap(() => this._loadedSettings.asObservable()));
				}
			}),
			startWith<UserSettingsApiModel>(() => null),
			pairwise(),
			filter(([prev, cur]) => {
				// Check if there's been any changes, block if none
				if (prev == null) return true;

				let anyUnequal = false;
				keys.forEach((x) => (anyUnequal = anyUnequal || prev[x] !== cur[x]));
				return anyUnequal;
			}),
			map(([_prev, cur]) => {
				// Use a clone so users cannot affect the inner store
				const pullModel = this.cloneModel(cur);
				const results = new Map<string, any>();
				keys.forEach((k) => {
					results.set(k, pullModel[k]);
				});

				return results;
			}),
		);
	}

	saveSetting$<T>(key: UserSetting, value: T): Observable<boolean>;
	saveSetting$<T>(key: string, value: T): Observable<boolean>;
	saveSetting$<T>(key: string, value: T): Observable<boolean> {
		const args = {};
		args[key] = value;
		return this.saveSettings$(args);
	}

	public saveSettings$ = (args: UserSettingsArgument): Observable<boolean> => {
		// Build a save passenger and wait
		const sub = new AsyncSubject<boolean>();
		this._waitingSavePassengers.push({
			args: args,
			subject: sub,
		});
		this._saveBusSubject.next(null);

		return sub;
	};

	private cloneModel = (model: UserSettingsApiModel) => {
		return JSON.parse(JSON.stringify(model));
	};

	private loadAndStoreSettings = (keys: string[]): Observable<any> => {
		return this.loadServerSettings(keys).pipe(
			switchMap((x) => this.spliceWithLoaded(x)),
			catchError(() => of(false)),
		);
	};

	private spliceWithLoaded = (model: UserSettingsApiModel) => {
		return this._loadedSettings.pipe(
			take(1),
			map((x) => {
				for (const k in model) {
					if (model.hasOwnProperty(k)) {
						if (x[k] === undefined) {
							// Set the value so it's known we've attempted to load it
							x[k] = null;
						}

						if (model[k] != null) {
							x[k] = model[k];
						}
					}
				}

				return x;
			}),
			tap((x) => this._loadedSettings.next(x)),
		);
	};

	private loadServerSettings = (
		keys?: string[],
	): Observable<UserSettingsApiModel> => {
		return this.httpClient.post<UserSettingsApiModel>(
			`api/v1/user/settings`,
			keys,
		);
	};

	private updateServerSettings = (
		arg: UserSettingsArgument,
	): Observable<UserSettingsApiModel> => {
		return this.httpClient.post<UserSettingsApiModel>(
			`api/v1/user/settings/store`,
			arg,
		);
	};

	private initGetBus = () => {
		this._getBusSubject
			.pipe(
				filter(() => this._waitingGetPassengers.length > 0), // Toss irrelevant calls
				exhaustMap(() =>
					// About a frame or two delay
					timer(50).pipe(
						map<number, [string[], AsyncSubject<null>[]]>(() => {
							const passengers = this._waitingGetPassengers;
							this._waitingGetPassengers = [];

							const keySet = new Set<string>();
							const subjects: AsyncSubject<null>[] = [];

							// Fetch the unique set of keys
							passengers.forEach((wl) => {
								wl.keys.forEach((k) => keySet.add(k));
								subjects.push(wl.subject);
							});

							const keys: string[] = [];
							keySet.forEach((k) => keys.push(k));

							return [keys, subjects];
						}),
						switchMap(([keys, subjects]) => {
							// If there's nothing to do, just bail immediately
							if (keys.length === 0) {
								return of(subjects);
							} else {
								return this.loadAndStoreSettings(keys).pipe(
									map(() => subjects),
								);
							}
						}),
						tap((subjects) => {
							// Finish out the subjects waiting
							subjects.forEach((s) => {
								s.next(null);
								s.complete();
							});
						}),
					),
				),
				delay(1), // Pop over to next cycle to allow exhaust to complete before repumping
				tap(() => this._getBusSubject.next(null)),
			)
			.subscribe();
	};

	private initSaveBus = () => {
		this._saveBusSubject
			.pipe(
				filter(() => this._waitingSavePassengers.length > 0), // Toss irrelevant calls
				exhaustMap(() =>
					// About a frame or two delay
					timer(50).pipe(
						map<number, [UserSettingsArgument, AsyncSubject<boolean>[]]>(() => {
							const passengers = this._waitingSavePassengers;
							this._waitingSavePassengers = [];

							const args: UserSettingsArgument = {};
							const subjects: AsyncSubject<boolean>[] = [];

							// Build the latest arg
							passengers.forEach((ws) => {
								const wsArgs = ws.args;
								for (const i in wsArgs) {
									if (wsArgs.hasOwnProperty(i)) {
										args[i] = wsArgs[i];
									}
								}

								subjects.push(ws.subject);
							});

							return [args, subjects];
						}),
						switchMap(([args, subjects]) => {
							// If there's nothing to do, just bail immediately
							if (Object.entries(args).length === 0) {
								return of([true, subjects]);
							} else {
								return this.updateServerSettings(args).pipe(
									switchMap((x) => this.spliceWithLoaded(x)),
									map(() => subjects),
								);
							}
						}),
						tap((subjects) => {
							// Finish out the subjects waiting
							subjects.forEach((s) => {
								s.next(null);
								s.complete();
							});
						}),
					),
				),
				delay(1), // Pop over to next cycle to allow exhaust to complete before repumping
				tap(() => this._saveBusSubject.next(null)),
			)
			.subscribe();
	};
}
