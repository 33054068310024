import { Type } from 'class-transformer';

export class StateOptionModel {
	key: string;
	name: string;
}

export function GenericStateOptionModel_ClassTransformType() {
	return StateOptionModel;
}

export class StateOptionsModel {
	success: boolean;
	@Type(GenericStateOptionModel_ClassTransformType)
	states: StateOptionModel[];
}
