/* eslint-disable */

import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, ElementRef, NgZone } from '@angular/core';
import {
	OverlayScrollbarsComponent,
	OverlayscrollbarsModule,
} from 'overlayscrollbars-ngx';

@Component({
	selector: 'overlay-scrollbars-dragdrop',
	host: { 'data-overlayscrollbars-initialize': '' },
	// This template needs to match the ngx-overlayscrollbars template
	template: `
		<div
			overlayScrollbars
			[options]="options"
			[events]="mergeEvents(events)"
			[defer]="defer"
			#content
			data-overlayscrollbars-contents=""
			cdkScrollable
		>
			<ng-content></ng-content>
		</div>
	`,
	standalone: true,
	imports: [OverlayscrollbarsModule, CdkScrollable],
})
export class OverlayScrollbarsDragdropComponent extends OverlayScrollbarsComponent {
	constructor(ngZone: NgZone, targetRef: ElementRef<HTMLElement>) {
		super(ngZone, targetRef);
	}
}
