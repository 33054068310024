import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[aeAutofocus]',
	standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
	constructor(private el: ElementRef<HTMLElement>) {}

	ngAfterViewInit() {
		setTimeout(() => {
			if (!this.el.nativeElement.hasAttribute('tabindex')) {
				this.el.nativeElement.setAttribute('tabindex', '-1');
				this.el.nativeElement.classList.add('focus-hidden');
			}

			this.el.nativeElement.focus();
		});
	}
}
