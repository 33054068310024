export class GroupMemberModel {
	uid: number;
	first: string;
	last: string;
	preferred: string;
}

export function GroupMemberModel_ClassTransform_Type() {
	return GroupMemberModel;
}

export class GroupInstituteModel {
	id: number;
	title: string;
}

export function GroupInstituteModel_ClassTransform_Type() {
	return GroupInstituteModel;
}
