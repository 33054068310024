import { Type } from 'class-transformer';

export class StudentRelationshipsOptionModel {
	uid: number;
	name: string;
}

export function StudentRelationshipsOptionModel_ClassTransform_Type() {
	return StudentRelationshipsOptionModel;
}

export class StudentRelationshipsOptionsTypeModel {
	type: string;
	type_id: number;
	multiple: boolean;
	@Type(StudentRelationshipsOptionModel_ClassTransform_Type)
	options?: StudentRelationshipsOptionModel[];
}
