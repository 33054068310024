import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { map, switchMap } from 'rxjs/operators';
import { ChannelModel } from '../../../api/gabby/channels/channel.model';
import { MessageModel } from '../../../api/gabby/messages/message.model';
import { WampService } from '../../wamp.service';
import {
	GabbyChannelMessageUpdate,
	GabbyChannelMessagesRead,
	GabbyChannelNewMessage,
	GabbyChannelParticipantAdded,
	GabbyChannelParticipantRemoved,
	GabbyChannelRemoved,
	GabbyChannelUserTyping,
	GabbyNavigateRequestToUser,
	GabbyNewChannel,
} from './gabby-event-types';

@Injectable({
	providedIn: 'root',
})
export class GabbyWampEventFactoryService {
	constructor(private wampService: WampService) {}

	public newMessage$ = () => {
		return this.wampService
			.subscribe$<[number, MessageModel]>('gabby.channel.new_message')
			.pipe(
				map(([channelId, message]) => {
					return {
						channelId: channelId,
						message: plainToInstance(MessageModel, message),
					} as GabbyChannelNewMessage;
				}),
			);
	};

	public readMessages$ = () => {
		return this.wampService
			.subscribe$<
				[string, number, number, number[], number]
			>('gabby.channel.messages.read')
			.pipe(
				map(
					([
						directoryId,
						channelId,
						readerLinkId,
						readMessageIds,
						senderLinkId,
					]) => {
						return {
							directoryId: directoryId,
							channelId: channelId,
							readerLinkId: readerLinkId,
							readMessageIds: readMessageIds,
							senderLinkId: senderLinkId,
						} as GabbyChannelMessagesRead;
					},
				),
			);
	};

	public updateMessage$ = () => {
		return this.wampService
			.subscribe$<[number, MessageModel]>('gabby.channel.message.update')
			.pipe(
				map(([channelId, message]) => {
					return {
						channelId: channelId,
						message: plainToInstance(MessageModel, message),
					} as GabbyChannelMessageUpdate;
				}),
			);
	};

	public newChannel$ = () => {
		return this.wampService
			.subscribe$<[string, ChannelModel]>('gabby.new_channel')
			.pipe(
				map(([oldChannelId, channel]) => {
					return {
						oldChannelId: oldChannelId,
						channel: plainToInstance(ChannelModel, channel),
					} as GabbyNewChannel;
				}),
			);
	};

	public channelParticipantAdded$ = () => {
		return this.wampService
			.subscribe$<[string, number, number[]]>('gabby.channel.participant.new')
			.pipe(
				map(([directoryId, channelId, addedUserLinkIds]) => {
					return {
						directoryId: directoryId,
						channelId: channelId,
						userLinkIds: addedUserLinkIds,
					} as GabbyChannelParticipantAdded;
				}),
			);
	};

	public channelParticipantRemoved$ = () => {
		return this.wampService
			.subscribe$<
				[string, number, number[]]
			>('gabby.channel.participant.removed')
			.pipe(
				map(([directoryId, channelId, removedUserLinkIds]) => {
					return {
						directoryId: directoryId,
						channelId: channelId,
						userLinkIds: removedUserLinkIds,
					} as GabbyChannelParticipantRemoved;
				}),
			);
	};

	public channelRemoved$ = () => {
		return this.wampService
			.subscribe$<[string, number]>('gabby.channel.removed')
			.pipe(
				map(([directoryId, channelId]) => {
					return {
						directoryId: directoryId,
						channelId: channelId,
					} as GabbyChannelRemoved;
				}),
			);
	};

	public channelFlagsChanged$ = () => {
		return this.wampService.subscribe$<null>('gabby.channel.flags.changed');
	};

	public channelUserTyping$ = () => {
		return this.wampService
			.subscribe$<[number, number, boolean]>('gabby.channel.user.typing')
			.pipe(
				map(([channelId, userLinkId, active]) => {
					return {
						channelId: channelId,
						userLinkId: userLinkId,
						active: active,
					} as GabbyChannelUserTyping;
				}),
			);
	};

	public navigateRequestToUser$ = () => {
		return this.wampService
			.subscribe$<[number]>('gabby.navigate.request.user')
			.pipe(
				map(([userLinkId]) => {
					return {
						userLinkId: userLinkId,
					} as GabbyNavigateRequestToUser;
				}),
				switchMap((r) =>
					this.wampService
						.publish$('gabby.navigate.request.user.ack', [r.userLinkId])
						.pipe(map(() => r)),
				),
			);
	};
}
