import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { CustomGroupShareRole } from './custom-group-options.model';

export class CustomGroupModel {
	id: string; // Parent group ID
	personalized_id: string; // personalized group ID tied to parent group
	name: string;
	description: string;
	collection_name: string;
	creator_uid: number;
	creator_name: string;
	@Transform(dateTransformer)
	creation_date: Date;
	@Transform(dateTransformer)
	last_cached_used_date: Date;
	@Transform(dateTransformer)
	last_modified_date: Date;
	access: (string | CustomGroupShareRole)[];
	filters: {
		state: string[];
		program_type: number[];
		tier: number[];
		classes_start: string;
		classes_due: string;
		counted_courses: {
			compare: string;
			value: number;
		};
		credits_to_graduate: {
			compare: string;
			value: number;
		};
		missing_meetup_minutes: number;
		star_test_needed: string;
		specific_users: number[];
		status: string[];
		tagged_student: string[];
		has_teacher: number;
		program: number[];
		relationship_academic_coach: number | string;
		relationship_local_advocate: number | string;
		relationship_re_entry_coach: number | string;
		relationship_grad_coordinator: number | string;
		relationship_testing_coordinator: number | string;
		in_registration: number[];
		cohort_year: number[];
		has_incomplete_paperwork_request: boolean;
		days_since_online: {
			compare: string;
			value: number;
		};
		performance_expectations: number[];
		performance_expectations_previous: number[];
		months_remaining: {
			compare: string;
			value: number;
		};
		last_contact_by_me: {
			compare: string;
			value: number;
		};
		last_contact_by_any_staff: {
			compare: string;
			value: number;
		};
	};
}

export function CustomGroupModel_ClassTransform_Type() {
	return CustomGroupModel;
}
