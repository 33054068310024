import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, take, takeUntil, tap } from 'rxjs';
import spacetime from 'spacetime';
import { PlannedMaintenanceService } from 'src/lib/services/api/generic/planned-maintenance/planned-maintenance.service';
import { LocalStoreService } from 'src/lib/services/stores/local-store/local-store.service';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { hasValue } from 'src/lib/utilities/compare';
import { firstBy } from 'thenby';

@Injectable({
	providedIn: 'root',
})
export class TechBannerPlannedMaintenanceService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	constructor(
		private toastr: ToastrService,
		private plannedMaintenanceService: PlannedMaintenanceService,
		private localStore: LocalStoreService,
	) {}

	public checkMaintenance$() {
		return this.plannedMaintenanceService.viewMaintenance().pipe(
			take(1),
			tap((maintenance) => {
				maintenance.sort(firstBy((x) => x.start, 'asc')).forEach((m) => {
					const storedValue = this.localStore.get(
						`pm:${m.id}`,
						TypedStoreType.NUMBER,
					);

					if (
						hasValue(storedValue) &&
						storedValue > spacetime.now().startOf('day').epoch
					) {
						return;
					} else if (storedValue !== null) {
						this.localStore.remove(`pm:${m.id}`);
					}

					if (
						spacetime
							.now()
							.isBetween(
								spacetime(m.start)
									.subtract(m.warning_days, 'day')
									.startOf('day'),
								spacetime(m.start),
							)
					) {
						const tst = this.toastr.warning(
							`We will be conducting a planned maintenance on ${spacetime(m.start).format('nice')} which will last approximately ${m.duration_in_hours} hours. Please make sure to save any work beforehand to ensure no loss of data.`,
							'Planned Maintenance',
							{
								disableTimeOut: true,
								positionClass: 'toast-top-full-width',
								closeButton: true,
								tapToDismiss: false,
								toastClass: 'ngx-toastr no-pointer',
							},
						);

						tst.onHidden.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
							this.localStore.set(
								`pm:${m.id}`,
								spacetime(m.start).add(1, 'day').startOf('day').epoch,
							);
						});
					}
				});
			}),

			takeUntil(this._unsubscribe$),
		);
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
