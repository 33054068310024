<span #ref><ng-content /></span>
@if (spin) {
	<span
		aria-busy="true"
		title="Busy"
		[ngClass]="{ 'ms-1': ref.innerHTML.trim() }"
	>
		<i
			aria-hidden="true"
			class="far fa-spin fa-spinner"
			[ngClass]="class"
			[ngStyle]="style"
		></i>
	</span>
}
