<span aria-live="polite">
	&nbsp;
	@if (channelTypingUserLinkIds.size < 3) {
		@for (
			item of channelTypingUserLinkIds | keyvalue;
			track item;
			let last = $last
		) {
			<span
				>{{ getName(item.key)
				}}<span class="screen-reader-text"> is typing</span>
				@if (!last) {
					<span>, </span>
				}
			</span>
		}
		@if (channelTypingUserLinkIds.size > 0) {
			<span aria-hidden="true">...</span>
		}
	} @else {
		<span>Multiple people...</span>
		<span class="screen-reader-text">are typing</span>
	}
</span>
