import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData, buildQueryString } from 'src/lib/utilities/api/http';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	SafeBillingContractOptionModel,
	SafeBillingCreateInvoiceArgument,
	SafeBillingEditInvoiceArgument,
	SafeBillingInvoicedListModel,
	SafeBillingInvoicedModel,
	SafeBillingInvoicedSearchArgument,
	SafeBillingMarkDistrictApprovedArgument,
	SafeBillingMarkStudentApprovedArgument,
	SafeBillingOverrideInvoiceArgument,
	SafeBillingSafeInvoiceModel,
	SafeBillingSafeInvoiceSearchArgument,
	SafeBillingSendHistoryModel,
	SafeBillingSendInvoiceArgument,
} from './safe-billing-invoiced.model';
import { SafeBillingMarkInvoicedArgument } from './safe-billing-mark-invoiced.argument';
import {
	SafeBillingNotInvoicedListModel,
	SafeBillingNotInvoicedSearchArgument,
} from './safe-billing-not-invoiced.model';

@Injectable({
	providedIn: 'root',
})
export class SafeBillingService {
	constructor(private httpClient: HttpClient) {}
	/******************/
	/** Not Invoiced **/
	/******************/

	public getNotInvoiced = (
		args: PagingArgument<SafeBillingNotInvoicedSearchArgument>,
	): Observable<SafeBillingNotInvoicedListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/not_invoiced_list`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SafeBillingNotInvoicedListModel, r),
					{
						errorCode: '88DA99F1',
					},
				),
			);
	};

	public markRegistrationAsInvoiced = (
		args: SafeBillingMarkInvoicedArgument[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/mark_as_invoiced`, {
				registrations: args,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: '39D12BB4',
				}),
			);
	};

	/**************/
	/** Invoiced **/
	/**************/

	public getInvoiced = (
		args: PagingArgument<SafeBillingInvoicedSearchArgument>,
	): Observable<SafeBillingInvoicedListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/invoiced_list`, args)
			.pipe(
				mapResponse((r) => plainToInstance(SafeBillingInvoicedListModel, r), {
					errorCode: '1A2839ED',
				}),
			);
	};

	public regressRecord = (
		regIds: number[],
	): Observable<SafeBillingInvoicedListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/regress`, {
				registration_ids: regIds,
			})
			.pipe(
				mapResponse((r) => plainToInstance(SafeBillingInvoicedListModel, r), {
					errorCode: '0383EF56',
				}),
			);
	};

	public markStudentApproved = (
		args: SafeBillingMarkStudentApprovedArgument[],
	): Observable<SafeBillingInvoicedListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/mark_as_student_approved`, {
				registrations: args,
			})
			.pipe(
				mapResponse((r) => plainToInstance(SafeBillingInvoicedListModel, r), {
					errorCode: '9474853B',
				}),
			);
	};

	public uploadStudentApprovalCSV = (
		file: File,
	): Observable<SafeBillingInvoicedModel[]> => {
		const formData = buildFormData({ file: file });

		return this.httpClient
			.post<any>(
				`/api/v1/admin/billing/safe/csv_mark_as_student_approved`,
				formData,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							SafeBillingInvoicedModel,
							r.billed_registrations as any[],
						),
					{
						errorCode: 'A6CA7DCD',
					},
				),
			);
	};

	public markDistrictApproved = (
		args: SafeBillingMarkDistrictApprovedArgument[],
	): Observable<SafeBillingInvoicedListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/mark_as_district_approved`, {
				registrations: args,
			})
			.pipe(
				mapResponse((r) => plainToInstance(SafeBillingInvoicedListModel, r), {
					errorCode: '1EBCD69E',
				}),
			);
	};

	// gets the available contracts
	public getSafeInvoiceOptions = (): Observable<
		SafeBillingContractOptionModel[]
	> => {
		return this.httpClient
			.get<any>(`/api/v1/admin/billing/safe/invoice/options`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							SafeBillingContractOptionModel,
							r.safe_contracts as any[],
						),
					{
						errorCode: '8B27AE7E',
					},
				),
			);
	};

	public getSafeInvoices = (
		args: PagingArgument<SafeBillingSafeInvoiceSearchArgument>,
		show_details: boolean = false,
	): Observable<SafeBillingSafeInvoiceModel[]> => {
		const qs = buildQueryString({ show_details });

		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/invoice${qs}`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(SafeBillingSafeInvoiceModel, r.invoices as any[]),
					{
						errorCode: '765B0B86',
					},
				),
			);
	};

	public viewInvoice = (
		invoiceId: number,
	): Observable<SafeBillingSafeInvoiceModel> => {
		return this.httpClient
			.get<any>(`/api/v1/admin/billing/safe/invoice/${invoiceId}/view`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SafeBillingSafeInvoiceModel, r.invoice),
					{
						errorCode: '78147FB0',
					},
				),
			);
	};
	public createInvoice = (
		args: SafeBillingCreateInvoiceArgument,
	): Observable<SafeBillingSafeInvoiceModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/invoice/create`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SafeBillingSafeInvoiceModel, r.invoice),
					{
						errorCode: 'ED058C2E',
					},
				),
			);
	};

	public editInvoice = (
		invoiceId: number,
		args: SafeBillingEditInvoiceArgument,
	): Observable<SafeBillingSafeInvoiceModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/invoice/${invoiceId}/edit`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(SafeBillingSafeInvoiceModel, r.invoice),
					{
						errorCode: 'B28950AF',
					},
				),
			);
	};

	public deleteInvoice = (invoiceId: number): Observable<any> => {
		return this.httpClient
			.delete<any>(`/api/v1/admin/billing/safe/invoice/${invoiceId}/delete`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'B28950AF',
				}),
			);
	};

	public overrideInvoiceRegistrationData = (
		regId: number,
		args: Partial<SafeBillingOverrideInvoiceArgument>,
	): Observable<SafeBillingInvoicedModel> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/${regId}/override`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(SafeBillingInvoicedModel, r.billed_registration),
					{
						errorCode: 'AD1CD720',
					},
				),
			);
	};

	public sendInvoice = (
		invoiceId: number,
		args: Partial<SafeBillingSendInvoiceArgument>,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/admin/billing/safe/invoice/${invoiceId}/email`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'E4DE12EE',
				}),
			);
	};

	public getEmailHistory = (
		invoiceId: number,
	): Observable<SafeBillingSendHistoryModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/admin/billing/safe/invoice/${invoiceId}/email/history`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							SafeBillingSendHistoryModel,
							r.email_history as any[],
						),
					{
						errorCode: '982E85CC',
					},
				),
			);
	};

	// in POSTMAN: Safe Invoice - Get Files
	public previewInvoice = (invoiceId: number): Observable<string[]> => {
		return this.httpClient
			.get<any>(`/api/v1/admin/billing/safe/invoice/${invoiceId}/files`)
			.pipe(
				mapResponse((r) => r.files as any[], {
					errorCode: '982E85CC',
				}),
			);
	};
}
