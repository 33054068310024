import { Observable } from 'rxjs';

export type TypedStoreKey = string;

export enum TypedStoreType {
	ARRAY = 'Array',
	OBJECT = 'Object',
	STRING = 'String',
	NUMBER = 'Number',
	BOOLEAN = 'Boolean',
}

export interface GetFunction {
	<T>(key: TypedStoreKey, type: TypedStoreType.ARRAY, defaultVal?: T[]): T[];
	<T>(key: TypedStoreKey, type: TypedStoreType.OBJECT, defaultVal?: T): T;
	(
		key: TypedStoreKey,
		type: TypedStoreType.STRING,
		defaultVal?: string,
	): string;
	(
		key: TypedStoreKey,
		type: TypedStoreType.NUMBER,
		defaultVal?: number,
	): number;
	(
		key: TypedStoreKey,
		type: TypedStoreType.BOOLEAN,
		defaultVal?: boolean,
	): boolean;
}

export interface ObserveFunction {
	<T>(
		key: TypedStoreKey,
		type: TypedStoreType.ARRAY,
		defaultVal?: T[],
	): Observable<T[]>;
	<T>(
		key: TypedStoreKey,
		type: TypedStoreType.OBJECT,
		defaultVal?: T,
	): Observable<T>;
	(
		key: TypedStoreKey,
		type: TypedStoreType.STRING,
		defaultVal?: string,
	): Observable<string>;
	(
		key: TypedStoreKey,
		type: TypedStoreType.NUMBER,
		defaultVal?: number,
	): Observable<number>;
	(
		key: TypedStoreKey,
		type: TypedStoreType.BOOLEAN,
		defaultVal?: boolean,
	): Observable<boolean>;
}

export type SetFunction = (key: TypedStoreKey, value: unknown) => void;

export interface TypedStorage {
	get: GetFunction;
	set: SetFunction;
	remove: (key: TypedStoreKey) => void;
	observe$: ObserveFunction;
}
