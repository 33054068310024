import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class NotificationSetTriggerModel {
	trigger_id: string;
	trigger_name: string;
}

export function NotificationSetTriggerModel_ClassTransform_Type() {
	return NotificationSetTriggerModel;
}

export class NotificationSetGroupModel {
	group_id: string;
	group_name: string;
}

export function NotificationSetGroupModel_ClassTransform_Type() {
	return NotificationSetGroupModel;
}

export class NotificationRecipientModel {
	name: string;
	type_id: string;
	additional: null;
}

export function NotificationRecipientModel_ClassTransform_Type() {
	return NotificationRecipientModel;
}

export class NotificationItemModel {
	id: number;
	@Type(NotificationRecipientModel_ClassTransform_Type)
	recipient: NotificationRecipientModel;
	default_subscription_level: number;
	subject: string;
	body: string;
	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created_date: Date;
	@Expose({ name: 'updated_timestamp' })
	@Transform(dateTransformer)
	updated_date: Date;
	@Expose({ name: 'last_sent_timestamp' })
	@Transform(dateTransformer)
	last_sent_date: Date;
	@Type(NotificationRecipientModel_ClassTransform_Type)
	sender: NotificationRecipientModel;
	status: number;
}
/**
  ^^^ This is the status field values
 const STATUS_INACTIVE = 2;
 const STATUS_ACTIVE = 1;
 const STATUS_DELETED = 0;
 */

export function NotificationItemModel_ClassTransform_Type() {
	return NotificationItemModel;
}

export class NotificationConditionModel {
	condition_id: string;
	condition_title: string;
	value_id: string;
	value_title: string;
	comparison_id: number;
	comparison_name: string;
}

export function NotificationConditionModel_ClassTransform_Type() {
	return NotificationConditionModel;
}

export class NotificationSetModel {
	set_id: number;
	set_name: string;
	set_description: string;
	@Type(NotificationSetTriggerModel_ClassTransform_Type)
	trigger: NotificationSetTriggerModel;
	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created_date: Date;
	@Expose({ name: 'updated_timestamp' })
	@Transform(dateTransformer)
	updated_date: Date;
	@Expose({ name: 'last_sent_timestamp' })
	@Transform(dateTransformer)
	last_sent_date: Date;
	@Type(NotificationSetGroupModel_ClassTransform_Type)
	groups: NotificationSetGroupModel[];
	@Type(NotificationItemModel_ClassTransform_Type)
	notifications: NotificationItemModel[];
	@Type(NotificationConditionModel_ClassTransform_Type)
	conditions?: NotificationConditionModel[];
}
