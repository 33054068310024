<ng-select
	bindLabel="name"
	bindValue="id"
	groupBy="collection_name"
	loadingText="Loading Programs"
	placeholder="Groups"
	[appendTo]="'body'"
	[clearSearchOnAdd]="true"
	[closeOnSelect]="false"
	[formControl]="control"
	[items]="groups"
	[loading]="groups == null"
	[multiple]="multiple"
	[searchFn]="groupsSearchFn"
	[virtualScroll]="true"
	(change)="changeEvent()"
	(clear)="clearEvent()"
	(close)="closeEvent()"
	(remove)="removeEvent()"
>
	@switch (allowClear) {
		@case (true) {
			<ng-template let-clear="clear" let-item="item" ng-label-tmp>
				<button
					class="btn p-0 d-inline-flex align-items-center"
					type="button"
					[ngClass]="
						includedGroups?.includes(item.id) ? 'invalid-group-selection' : ''
					"
					(click)="clear(item)"
				>
					@if (clear) {
						<span class="ng-value-icon left"><i class="far fa-times"></i></span>
					}
					<div class="ng-value-label">
						<div>{{ item.name }}</div>
					</div>
				</button>
			</ng-template>
		}
		@default {
			<ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
				<div [ngOptionHighlight]="search">
					{{ item.name }}
				</div>
			</ng-template>
		}
	}

	<ng-template let-item="item" ng-optgroup-tmp>
		<div class="fw-bolder">
			{{ item.collection_name }}
		</div>
	</ng-template>
</ng-select>
