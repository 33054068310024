import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StatusEnrollmentEventModel {
	@Transform(dateTransformer)
	date: Date;
}

export function StatusEnrollmentEventModel_ClassTransform_Type() {
	return StatusEnrollmentEventModel;
}

export class StatusEnrollmentEventEnrollmentModel extends StatusEnrollmentEventModel {
	target_program_id: number;
	target_program_name: string;
	reentry_request_id: number;
}

export function StatusEnrollmentEventEnrollmentModel_ClassTransform_Type() {
	return StatusEnrollmentEventEnrollmentModel;
}

export class StatusEnrollmentEventWithdrawalGraduationModel {
	@Transform(dateTransformer)
	date: Date;
	timezone: string;
	timezone_type: number;
}

export function StatusEnrollmentEventWithdrawalGraduationModel_ClassTransform_Type() {
	return StatusEnrollmentEventWithdrawalGraduationModel;
}

export class StatusEnrollmentEventWithdrawalModel extends StatusEnrollmentEventModel {
	exit_reason: string;
	exit_code: number;
	exit_text: string;
	exit_category: number;
	exit_category_title: string;
	@Type(StatusEnrollmentEventWithdrawalGraduationModel_ClassTransform_Type)
	graduation_date: StatusEnrollmentEventWithdrawalGraduationModel;
	withdrawn_by_first: string;
	withdrawn_by_last: string;
	withdrawn_by_uid: string;
}

export function StatusEnrollmentEventWithdrawalModel_ClassTransform_Type() {
	return StatusEnrollmentEventWithdrawalModel;
}

export class StatusEnrollmentEventPauseModel extends StatusEnrollmentEventModel {
	@Transform(dateTransformer)
	pause_estimated_end_date: Date;
	paused_by: number;
	paused_by_name: string;
}

export function StatusEnrollmentEventPauseModel_ClassTransform_Type() {
	return StatusEnrollmentEventPauseModel;
}

export class StatusEnrollmentEventResumeModel extends StatusEnrollmentEventModel {
	resumed_by: number;
	resumed_by_name: string;
}

export function StatusEnrollmentEventResumeModel_ClassTransform_Type() {
	return StatusEnrollmentEventResumeModel;
}

export class StudentStatusEnrollmentEventsModel {
	@Type(StatusEnrollmentEventModel_ClassTransform_Type)
	activation: StatusEnrollmentEventModel;
	@Type(StatusEnrollmentEventEnrollmentModel_ClassTransform_Type)
	enrollment: StatusEnrollmentEventEnrollmentModel[];
	@Type(StatusEnrollmentEventWithdrawalModel_ClassTransform_Type)
	withdrawal: StatusEnrollmentEventWithdrawalModel[];
	@Type(StatusEnrollmentEventPauseModel_ClassTransform_Type)
	pause: StatusEnrollmentEventPauseModel[];
	@Type(StatusEnrollmentEventResumeModel_ClassTransform_Type)
	resume: StatusEnrollmentEventResumeModel[];
}

export function StudentStatusEnrollmentEventsModel_ClassTransform_Type() {
	return StudentStatusEnrollmentEventsModel;
}
