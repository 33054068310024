import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { StudentStatusModel } from 'src/lib/services/api/students/status/student-status.model';
import { StudentsStatusService } from 'src/lib/services/api/students/status/students-status.service';
import { BehaviorCache, subscribeAndPromise } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentStatusStoreService {
	private _statusCache = new BehaviorCache<number, StudentStatusModel>(
		(uid) => this.studentsStatusService.getStatus(uid),
		'StudentStatusStore StatusCache',
	);

	constructor(private studentsStatusService: StudentsStatusService) {}

	/**
	 * Status
	 */
	public status$ = (uid: number): Observable<StudentStatusModel> => {
		return this._statusCache.getCache(uid);
	};

	public refreshStatus = (uid: number): Promise<boolean> => {
		return subscribeAndPromise(
			this.studentsStatusService
				.refreshStatus(uid)
				.pipe(switchMap(() => this._statusCache.fetchData(uid, true))),
		);
	};
}
