import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentBellScheduleModel } from './student-bell-schedule.model';

@Injectable({
	providedIn: 'root',
})
export class StudentBellScheduleService {
	constructor(private httpClient: HttpClient) {}

	public getBellSchedule = (
		studentId: number,
	): Observable<StudentBellScheduleModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/bell-schedule`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentBellScheduleModel, r), {
					errorCode: 'DA803EBB',
				}),
			);
	};
}
