import { Transform, Type } from 'class-transformer';
import {
	FieldSelectModel,
	FieldSelectModel_ClassTransform_TransformFactory,
} from '../../../../utilities/api/patterns/dynamic-fields/field-select.model';
import {
	FieldModel,
	FieldModel_ClassTransform_Type,
} from '../../../../utilities/api/patterns/dynamic-fields/field.model';
import {
	StringEnumOption,
	StringEnumOption_ClassTransform_Type,
} from '../../../../utilities/api/patterns/dynamic-fields/string-enum-option.model';

export class OrganizationStudentEditAddressFieldModel {
	@Transform(
		FieldSelectModel_ClassTransform_TransformFactory(
			StringEnumOption_ClassTransform_Type,
		),
	)
	state?: FieldSelectModel<StringEnumOption>;

	@Type(FieldModel_ClassTransform_Type)
	street?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	city?: FieldModel;

	@Type(FieldModel_ClassTransform_Type)
	zip?: FieldModel;
}

export function OrganizationStudentEditAddressFieldModel_ClassTransform_Type() {
	return OrganizationStudentEditAddressFieldModel;
}
