import { Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	StudentStateTestModel,
	StudentStateTestModel_ClassTransform_Type,
} from '../students/tests/student-state-test.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import { TrackerContactTypeModel_ClassTransform_Type } from './tracker-contact-type.model';

export interface TestingCoordinatorPagingArgument
	extends PagingArgument<TestingCoordinatorSearchArgument> {
	include_tags?: true | null;
}
export interface TestingCoordinatorSearchArgument {
	uid: PagingSearchFieldArgument;
	first_name: PagingSearchFieldArgument;
	last_name: PagingSearchFieldArgument;
	last_attempt: PagingSearchFieldArgument;
	last_contact: PagingSearchFieldArgument;
	enrolled_date: PagingSearchFieldArgument;
	star_math: PagingSearchFieldArgument;
	star_reading: PagingSearchFieldArgument;
	paused: { value: boolean };
	iep_504: PagingSearchFieldArgument;
	zip: PagingSearchFieldArgument;
	institute_title: PagingSearchFieldArgument;
	institute_type: PagingSearchFieldArgument;
	institute_state: PagingSearchFieldArgument;
	credits_remaining: PagingSearchFieldArgument;
	work_queue: PagingSearchFieldArgument;
	cohort_year: PagingSearchFieldArgument;
	safe_funding_expiration: PagingSearchFieldArgument;
	hide_completed: { value: boolean };
	state_test_result_status: PagingSearchFieldArrayArgument;
	state_test_result_is_eligible: { value: boolean };
	state_test_result_result: PagingSearchFieldArrayArgument;
	tag: PagingSearchFieldArgument;
}

export class TestingCoordinatorTestOverviewModel {
	is_eligible: boolean;
	student_note: string;
}

export function TestingCoordinatorTestOverviewModel_ClassTransform_Type() {
	return TestingCoordinatorTestOverviewModel;
}

export class TestingCoordinatorTrackerItemModel {
	uid: number;
	user_link_id: number;
	first_name: string;
	last_name: string;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	cohort_year: string;
	@Transform(integerTransformer)
	credits_remaining: number;
	zip: string;
	enrollment_status: number;
	test_id: number;
	test_title: string;
	paused: boolean;
	iep_504: string;
	@Transform(dateTransformer)
	attending_date: Date;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Date;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Date;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	enrolled_date: Date;
	@Transform(dateTransformer)
	safe_funding_expiration: Date;
	star_math: number;
	star_reading: number;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	@Type(StudentStateTestModel_ClassTransform_Type)
	state_test_result: StudentStateTestModel;
	@Type(TestingCoordinatorTestOverviewModel_ClassTransform_Type)
	state_test_overview: TestingCoordinatorTestOverviewModel;
	test_is_grad_required: boolean;
	tags: string[];
}

export function TestingCoordinatorTrackerItemModel_ClassTransform_Type() {
	return TestingCoordinatorTrackerItemModel;
}

export class TestingCoordinatorTrackerResponseModel {
	success: boolean;
	count: number;
	@Type(TestingCoordinatorTrackerItemModel_ClassTransform_Type)
	results: TestingCoordinatorTrackerItemModel[];
}
