<section aeBillboardLabel class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Web Accessibility Statement</h2>
	</div>
	<div class="billboard-body">
		<section>
			<h3 class="fw-bold fs-5">General</h3>
			<p>
				Graduation Alliance is committed to making its website usable by all
				people, including those with disabilities by meeting or exceeding the
				requirements of the Web Content Accessibility Guidelines 2.1 Level A/AA
				(WCAG 2.1 A/AA). We strive to make our site an equal experience for
				everyone.
			</p>
			<p>
				We continue to monitor our site and make sure that all content provided
				is accessible to all visitors. In order to help us in this process, we
				have engaged The Bureau of Internet Accessibility, an accessibility
				consulting company, to conduct an accessibility audit consisting of
				manual testing of our website using BoIA's Subject Matter Experts who
				provides us with specific recommendations that will assist us in
				ensuring that our Web presence is in conformance with the WCAG 2.1 A/AA.
			</p>
		</section>
		<section>
			<h3 class="fw-bold fs-5">Disclaimer</h3>
			<p>
				It is important to note that efforts to the website are ongoing as we
				work to implement the relevant improvements to meet WCAG 2.1 A/AA
				guidelines over time.
			</p>
		</section>
		<section>
			<h3 class="fw-bold fs-5">Contact Us</h3>
			<p>
				We are dedicated to meeting all of your accessibility needs. Should you
				have specific questions or concerns about the accessibility of this site
				or need assistance in using the processes found within this site, we
				have individuals here at Graduation Alliance to assist you.
			</p>
			<p>
				Please contact us directly at
				<a href="mailto:student.support@graduationalliance.com"
					>student.support&#64;graduationalliance.com</a
				>. We would be happy to assist in making your visits to our site as
				convenient as possible.
			</p>
		</section>
	</div>
</section>
