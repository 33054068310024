import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class ProgramAdministratorsHistoryModel {
	uid: number;
	name: string;
	role: string;
	role_id: number;
	@Expose({ name: 'assigned_time' })
	@Transform(dateTransformer)
	assigned_date: Date;
	assigned_by: number;
	assigned_by_name: string;
	@Expose({ name: 'unassigned_time' })
	@Transform(dateTransformer)
	unassigned_date: Date;
	unassigned_by: number;
	unassigned_by_name: string;
}
