import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { StudentMeetupAvailableModalComponent } from './student-meetup-available-modal.component';

@Injectable({
	providedIn: 'root',
})
export class StudentMeetupAvailableModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(studentId: number) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: 'xl',
				scrollable: true,
			};
			const modal = this.modalService.open(
				StudentMeetupAvailableModalComponent,
				ngbModalOptions,
			);

			(
				modal.componentInstance as StudentMeetupAvailableModalComponent
			).bindModalData({ studentId: studentId });

			modal.result
				.then((hasChanges) => {
					o.next(hasChanges);
					o.complete();
				})
				.catch(() => {
					o.next(true);
					o.complete();
				});
		});
	}
}
