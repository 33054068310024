import { Type } from 'class-transformer';
import { AbstractActivityBoardResponseModel } from '../abstract-activity-board-response.model';

export class ActivityBoardTeacherModel {
	teacher_uid: number;
	teacher_name: string;
	login_days: number;
	students_count: number;
	graded_assignments: number;
	ungraded_assignments: number;
	ungraded_assignments_48: number;
	overdue_assignments: number;
	teacher_feedback_left: number;
	gradchat_messages_sent: number;
}

export function ActivityBoardTeacherModel_ClassTransformType() {
	return ActivityBoardTeacherModel;
}

export class ActivityBoardTeacherResponseModel extends AbstractActivityBoardResponseModel<ActivityBoardTeacherModel> {
	@Type(ActivityBoardTeacherModel_ClassTransformType)
	activity_board: ActivityBoardTeacherModel[];
}
