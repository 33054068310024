import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class WebNotificationService {
	public static featureEnabled = 'Notification' in window;
	private hasRequested: boolean = false;

	public create = (
		title: string,
		options?: NotificationOptions,
	): Notification => {
		if (!this.canCreate()) return null;
		else return new Notification(title, options);
	};

	public canCreate = (): boolean => {
		return (
			WebNotificationService.featureEnabled &&
			Notification.permission === 'granted'
		);
	};

	public requestPermission = (
		aggressive: boolean = false,
	): Observable<boolean> => {
		return new Observable((s) => {
			const complete = (val: boolean) => {
				s.next(val);
				s.complete();
			};

			if (!WebNotificationService.featureEnabled) complete(false);
			else if (this.canCreate()) complete(true);
			else if (Notification.permission === 'denied') {
				if (aggressive) {
					alert(
						`We cannot request permission for notifications because you have blocked them. Unblock them from your browser settings to enable notifications. For instructions go here: https://bit.ly/2XT4hHY`,
					);
					complete(false);
				} else {
					complete(false);
				}
			} else {
				if (!this.hasRequested || aggressive) {
					this.hasRequested = true;
					from(Notification.requestPermission())
						.pipe(map((x) => x === 'granted'))
						.subscribe(complete);
				} else {
					complete(false);
				}
			}
		});
	};
}
