import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	StudentPauseRequestApproveArgument,
	StudentPauseRequestArgument,
} from './students-pause.argument';
import {
	PauseExitReasonModel,
	StudentPauseModel,
	StudentPauseRequestModel,
} from './students-pause.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsPauseService {
	constructor(private httpClient: HttpClient) {}

	public getPauseStatus = (uid: number): Observable<StudentPauseModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/pause/status`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseModel, r), {
					errorCode: 'F4EE3655',
				}),
			);
	};

	public requestPause = (
		uid: number,
		args: StudentPauseRequestArgument,
	): Observable<StudentPauseRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/pause/request`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseRequestModel, r), {
					errorCode: 'CDE9E75F',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public rejectRequestPause = (
		uid: number,
		explanation: string,
	): Observable<StudentPauseRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/pause/reject`, {
				explanation: explanation,
			})
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseRequestModel, r), {
					errorCode: '3ACCF708',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public endPause = (uid: number): Observable<StudentPauseRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/pause/end`, {})
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseRequestModel, r), {
					errorCode: '6C5311B6',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public editPauseRequest = (
		uid: number,
		args: StudentPauseRequestArgument,
	): Observable<StudentPauseRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/pause/edit`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseRequestModel, r), {
					errorCode: 'E6F530BB',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public approvePauseRequest = (
		uid: number,
		args: StudentPauseRequestApproveArgument,
	): Observable<StudentPauseRequestModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/pause/approve`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseRequestModel, r), {
					errorCode: 'DBC6FC47',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getPauseOptions = (uid: number): Observable<PauseExitReasonModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/pause/options`)
			.pipe(
				mapResponse((r) => plainToInstance(PauseExitReasonModel, r), {
					errorCode: 'B5F3892E',
				}),
			);
	};

	public updatePauseRecord = (
		uid: number,
		args: StudentPauseRequestArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/pause/record/edit`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentPauseRequestModel, r), {
					errorCode: 'DBC6FC47',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
