@if (title) {
	<div class="modal-header">
		<h1 class="modal-title">
			{{ title }}
			<div class="modal-subtitle">{{ subtitle }}</div>
		</h1>
		@if (mover) {
			<ae-modal-mover />
		}
		@if (cancelBtn) {
			<button
				class="btn btn-outline-dark"
				title="Close"
				type="button"
				(click)="close(false)"
			>
				<i aria-hidden="true" class="far fa-times"></i>
			</button>
		}
	</div>
}
<div class="modal-body">
	@if (template == null) {
		<div class="text-center">
			{{ message }}
		</div>
	} @else {
		<ng-container [ngTemplateOutlet]="template" />
	}
</div>
<div class="modal-footer">
	<button
		class="btn"
		id="confirm.action.modal.cancel"
		type="button"
		[ngClass]="this.rejectStyle ? this.rejectStyle : 'btn-secondary'"
		(click)="close(false)"
	>
		{{ noText }}
	</button>
	<button
		#yesButton
		class="btn position-relative"
		id="confirm.action.modal.confirm"
		type="button"
		[disabled]="countDownDelay > 0"
		[ngClass]="getConfirmClass()"
		(click)="close(true)"
	>
		<div class="countdown-text">
			{{ countDownDelay }}
		</div>
		<div class="yes-text">
			{{ yesText }}
		</div>
	</button>
</div>
