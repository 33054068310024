import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SpinWhileComponent } from '../../layout/spin-while/spin-while.component';

@Component({
	selector: 'ae-overlay-spinner',
	templateUrl: './overlay-spinner.component.html',
	styleUrls: ['./overlay-spinner.component.scss'],
	standalone: true,
	imports: [NgClass, SpinWhileComponent],
})
export class OverlaySpinnerComponent {
	@Input() loading: boolean;
	@Input() includeBackground: boolean = false;
	/********************************************************/
	/***  parent element must be set to position-relative ***/
	/********************************************************/
}
