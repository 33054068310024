// eslint-disable-next-line no-restricted-imports
import { SelectOptions } from '../types/select-options';

export enum StudentClassFinalStatusEnum {
	submitted = 'Submitted',
	not_submitted = 'Not submitted',
	no_final = 'No final',
}

export enum GraduationPacingValue {
	ahead = 'ahead',
	on_track = 'on track',
	behind = 'behind',
}

export const GraduationPacingValues = [
	{
		title: 'Ahead',
		key: GraduationPacingValue.ahead,
	},
	{
		title: 'On Track',
		key: GraduationPacingValue.on_track,
	},
	{
		title: 'Behind',
		key: GraduationPacingValue.behind,
	},
];

export const StudentClassFinalStatusValues = [
	StudentClassFinalStatusEnum.submitted,
	StudentClassFinalStatusEnum.not_submitted,
	StudentClassFinalStatusEnum.no_final,
];

export const months = [
	{
		value: 0,
		name: 'Jan',
		fullname: 'January',
	},
	{
		value: 1,
		name: 'Feb',
		fullname: 'February',
	},
	{
		value: 2,
		name: 'Mar',
		fullname: 'March',
	},
	{
		value: 3,
		name: 'Apr',
		fullname: 'April',
	},
	{
		value: 4,
		name: 'May',
		fullname: 'May',
	},
	{
		value: 5,
		name: 'Jun',
		fullname: 'June',
	},
	{
		value: 6,
		name: 'Jul',
		fullname: 'July',
	},
	{
		value: 7,
		name: 'Aug',
		fullname: 'August',
	},
	{
		value: 8,
		name: 'Sep',
		fullname: 'September',
	},
	{
		value: 9,
		name: 'Oct',
		fullname: 'October',
	},
	{
		value: 10,
		name: 'Nov',
		fullname: 'November',
	},
	{
		value: 11,
		name: 'Dec',
		fullname: 'December',
	},
];

export const days = [
	{
		value: 0,
		name: 'Sun',
		fullname: 'Sunday',
	},
	{
		value: 1,
		name: 'Mon',
		fullname: 'Monday',
	},
	{
		value: 2,
		name: 'Tue',
		fullname: 'Tuesday',
	},
	{
		value: 3,
		name: 'Wed',
		fullname: 'Wednesday',
	},
	{
		value: 4,
		name: 'Thu',
		fullname: 'Thursday',
	},
	{
		value: 5,
		name: 'Fri',
		fullname: 'Friday',
	},
	{
		value: 6,
		name: 'Sat',
		fullname: 'Saturday',
	},
];

export const iep504TableFilterOptions = [
	{ key: '504', title: '504', value: 2 },
	{ key: 'iep', title: 'IEP', value: 1 },
	{ key: 'iep_504', title: 'IEP and 504', value: 3 },
	{ key: 'any', title: 'Any' },
	{ key: 'none', title: 'None', value: 0 },
];

export const yesNoSelectOptions: SelectOptions<boolean>[] = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

export const enrollmentStatusOptions = [
	{ label: 'Inactive', value: 0 },
	{ label: 'Active', value: 1 },
	{ label: 'Pending', value: 2 },
];
