<label
	#wrapper
	class="file-uploader-container validate-basic-display rounded"
	tabindex="0"
	title="{{ file ? file.name : '' }}"
	(keyup.Enter)="wrapper.click()"
	(keyup.Space)="wrapper.click()"
>
	<div class="align-content-center d-flex flex-row justify-content-center py-3">
		<input
			#uploadInput
			hidden="true"
			type="file"
			[disabled]="disabled"
			(change)="fileUploaded($event)"
		/>

		@if (!file) {
			<div class="align-self-center">
				<ng-container *aeSpinWhile="resizing"
					>Drop or Select File
				</ng-container>
			</div>
		}
		@if (file && !imageFile) {
			<div class="align-self-center filename-overflow">
				{{ file.name }}
			</div>
		}
		@if (file && imageFile) {
			<img
				#previewImage
				alt="Preview"
				class="file-uploader-preview"
				[ngClass]="previewImageClass ? previewImageClass : null"
			/>
		}
	</div>
</label>
