export class UserAddressModel {
	street: string;
	street2?: string;
	city: string;
	state: string;
	zip?: string;
}

export class UserAddressWithZip extends UserAddressModel {
	zip: string;
}

export function UserAddressModel_ClassTransform_Type() {
	return UserAddressModel;
}

export function UserAddressWithZip_ClassTransform_Type() {
	return UserAddressWithZip;
}
