import { Type } from 'class-transformer';
import {
	CurriculumMappingEndorsementBaseModel,
	CurriculumMappingEndorsementBaseModel_ClassTransform_Type,
} from '../endorsements/curriculum-mapping-endorsement.model';

export class CurriculumMappingCourseEndorsementModel {
	@Type(CurriculumMappingEndorsementBaseModel_ClassTransform_Type)
	endorsement: CurriculumMappingEndorsementBaseModel;
	endorsement_requirement_id: number;
	max_grade_level: number;
	min_grade_level: number;
}

export function CurriculumMappingCourseEndorsementModel_ClassTransform_Type() {
	return CurriculumMappingCourseEndorsementModel;
}

export class CurriculumMappingCourseModel {
	course_id: number;
	course_title: string;
	course_number: string;
	min_grade_level: number;
	max_grade_level: number;
	@Type(CurriculumMappingCourseEndorsementModel_ClassTransform_Type)
	required_endorsements: CurriculumMappingCourseEndorsementModel[];
}

export interface CurriculumMappingCourseEditArgument {
	min_grade_level: number;
	max_grade_level: number;
	endorsements: CurriculumMappingCourseEditEndorsementArgument[];
}

export interface CurriculumMappingCourseEditEndorsementArgument {
	endorsement_id: number;
	min_grade_level: number;
	max_grade_level: number;
}
