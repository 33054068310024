import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	StudentCourseModel,
	StudentCourseModel_ClassTransform_Type,
} from './student-course.model';

export class StudentCoursesResponseModel extends AbstractPagingResponseModel<StudentCourseModel> {
	@Type(StudentCourseModel_ClassTransform_Type)
	results: StudentCourseModel[];
}
