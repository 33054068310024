import { NgStyle } from '@angular/common';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'src/lib/utilities/noop';
import { colorPickerDefaultOptions } from './color-picker.data';

@Component({
	selector: 'ae-color-picker',
	exportAs: 'aeColorPicker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ColorPickerComponent),
		},
	],
	standalone: true,
	imports: [NgbPopover, NgStyle],
})
export class ColorPickerComponent implements ControlValueAccessor, OnInit {
	@Input() colorOptions: string[][] = null;

	@ViewChild(NgbPopover, { static: true }) popover: NgbPopover;

	public color: string;

	private _changeFunction: (value: string) => void = noop;
	private _touchedFunction: () => void = noop;

	ngOnInit() {
		if (this.colorOptions == null) {
			this.colorOptions = colorPickerDefaultOptions;
		}
	}

	public toggle = () => {
		this.popover.toggle();
	};

	// ControlValueAccessor
	public writeValue(color: string): void {
		this.color = color;
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}

	public registerOnTouched(fn: any): void {
		this._touchedFunction = fn;
	}

	public colorSelected = (color: string) => {
		this._touchedFunction();
		this._changeFunction(color);
		this.popover.close();
	};
}
