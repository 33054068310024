<input
	#dateInput
	class="form-control squared-end"
	[autocomplete]="autocomplete"
	[disabled]="disabled"
	id="{{ id }}"
	[max]="getMaxDate()"
	[min]="getMinDate()"
	[ngModelOptions]="{ standalone: true }"
	[placeholder]="'mm/dd/yyyy'"
	[type]="inputType"
	[(ngModel)]="formattedModel"
	(blur)="blur()"
	(dblclick)="changeInputType('text')"
	(keydown.enter)="dateInput.blur()"
	(ngModelChange)="onFormattedChange($event)"
/>
