import * as i0 from '@angular/core';
import { Directive, Input, EventEmitter, Component, Output, ViewChild, NgModule } from '@angular/core';
import { OverlayScrollbars } from 'overlayscrollbars';

/* eslint-disable @typescript-eslint/consistent-type-imports */
const _c0 = ["content"];
const _c1 = ["*"];
const createDefer = () => {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    // mock ssr calls with "noop"
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const noop = () => {};
    return [noop, noop];
  }
  let idleId;
  let rafId;
  const wnd = window;
  const idleSupported = typeof wnd.requestIdleCallback === 'function';
  const rAF = wnd.requestAnimationFrame;
  const cAF = wnd.cancelAnimationFrame;
  const rIdle = idleSupported ? wnd.requestIdleCallback : rAF;
  const cIdle = idleSupported ? wnd.cancelIdleCallback : cAF;
  const clear = () => {
    cIdle(idleId);
    cAF(rafId);
  };
  return [(callback, options) => {
    clear();
    idleId = rIdle(idleSupported ? () => {
      clear();
      // inside idle its best practice to use rAF to change DOM for best performance
      rafId = rAF(callback);
    } : callback,
    // @ts-ignore
    typeof options === 'object' ? options : {
      timeout: 2233
    });
  }, clear];
};
class OverlayScrollbarsDirective {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.instanceRef = null;
    const [requestDefer, cancelDefer] = createDefer();
    this.requestDefer = requestDefer;
    this.cancelDefer = cancelDefer;
  }
  osInitialize(target) {
    this.ngZone.runOutsideAngular(() => {
      const init = () => {
        this.instanceRef = OverlayScrollbars(target, this.options || {}, /* istanbul ignore next */
        this.events || {});
      };
      if (this.defer) {
        this.requestDefer(init, this.defer);
      } else {
        init();
      }
    });
  }
  osInstance() {
    return this.instanceRef;
  }
  ngOnChanges(changes) {
    const optionsChange = changes.options;
    const eventsChange = changes.events;
    if (optionsChange) {
      const curr = optionsChange.currentValue;
      this.options = curr;
      if (OverlayScrollbars.valid(this.instanceRef)) {
        this.instanceRef.options(curr || {}, true);
      }
    }
    /* istanbul ignore else */
    if (eventsChange) {
      const curr = eventsChange.currentValue;
      this.events = curr;
      if (OverlayScrollbars.valid(this.instanceRef)) {
        this.instanceRef.on(/* istanbul ignore next */
        curr || {}, true);
      }
    }
  }
  ngOnDestroy() {
    this.cancelDefer();
  }
}
OverlayScrollbarsDirective.ɵfac = function OverlayScrollbarsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OverlayScrollbarsDirective)(i0.ɵɵdirectiveInject(i0.NgZone));
};
OverlayScrollbarsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OverlayScrollbarsDirective,
  selectors: [["", "overlayScrollbars", ""]],
  inputs: {
    options: "options",
    events: "events",
    defer: "defer"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayScrollbarsDirective, [{
    type: Directive,
    args: [{
      selector: '[overlayScrollbars]' // https://angular.io/guide/styleguide#directive-selectors
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input,
      args: ['options']
    }],
    events: [{
      type: Input,
      args: ['events']
    }],
    defer: [{
      type: Input,
      args: ['defer']
    }]
  });
})();

/* eslint-disable @typescript-eslint/consistent-type-imports */
const mergeEventListeners = (emits, events) => Object.keys(emits).reduce((obj, name) => {
  const emitListener = emits[name];
  const eventListener = events[name];
  /* istanbul ignore next */
  obj[name] = [emitListener, ...(Array.isArray(eventListener) ? eventListener : [eventListener]).filter(Boolean)];
  return obj;
}, {});
class OverlayScrollbarsComponent {
  constructor(ngZone, targetRef) {
    this.ngZone = ngZone;
    this.targetRef = targetRef;
    this.onInitialized = new EventEmitter();
    this.onUpdated = new EventEmitter();
    this.onDestroyed = new EventEmitter();
    this.onScroll = new EventEmitter();
  }
  osInstance() {
    return this.osDirective.osInstance();
  }
  getElement() {
    return this.targetRef.nativeElement;
  }
  ngAfterViewInit() {
    const targetElm = this.getElement();
    const contentElm = this.contentRef.nativeElement;
    /* istanbul ignore else */
    if (targetElm && contentElm) {
      this.osDirective.osInitialize({
        target: targetElm,
        elements: {
          viewport: contentElm,
          content: contentElm
        }
      });
    }
  }
  ngOnDestroy() {
    this.osDirective?.osInstance()?.destroy();
  }
  mergeEvents(originalEvents) {
    return mergeEventListeners({
      initialized: (...args) => this.dispatchEventIfHasObservers(this.onInitialized, args),
      updated: (...args) => this.dispatchEventIfHasObservers(this.onUpdated, args),
      destroyed: (...args) => this.dispatchEventIfHasObservers(this.onDestroyed, args),
      scroll: (...args) => this.dispatchEventIfHasObservers(this.onScroll, args)
    }, originalEvents || {});
  }
  dispatchEventIfHasObservers(eventEmitter, args) {
    // `observed` is available since RxJS@7.2 because `observers` is being deprecated.
    if (eventEmitter.observed || eventEmitter.observers.length > 0) {
      // This is required to re-enter the Angular zone to call the event handler in the Angular
      // zone too. This will not re-enter the Angular zone if emitter doesn't have any observers,
      // for instance, it's being listened: `<overlay-scrollbars (osInitialized)="..."`.
      // Events are dispatched outside of the Angular zone because instance is created in the
      // `<root>` zone, see `OverlayScrollbarsDirective#osInitialize`.
      this.ngZone.run(() => eventEmitter.emit(args));
    }
  }
}
OverlayScrollbarsComponent.ɵfac = function OverlayScrollbarsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OverlayScrollbarsComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OverlayScrollbarsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OverlayScrollbarsComponent,
  selectors: [["overlay-scrollbars"], ["", "overlay-scrollbars", ""]],
  viewQuery: function OverlayScrollbarsComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c0, 5, OverlayScrollbarsDirective);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentRef = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.osDirective = _t.first);
    }
  },
  hostAttrs: ["data-overlayscrollbars-initialize", ""],
  inputs: {
    options: "options",
    events: "events",
    defer: "defer"
  },
  outputs: {
    onInitialized: "osInitialized",
    onUpdated: "osUpdated",
    onDestroyed: "osDestroyed",
    onScroll: "osScroll"
  },
  ngContentSelectors: _c1,
  decls: 3,
  vars: 3,
  consts: [["content", ""], ["overlayScrollbars", "", "data-overlayscrollbars-contents", "", 3, "options", "events", "defer"]],
  template: function OverlayScrollbarsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 1, 0);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("options", ctx.options)("events", ctx.mergeEvents(ctx.events))("defer", ctx.defer);
    }
  },
  dependencies: [OverlayScrollbarsDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayScrollbarsComponent, [{
    type: Component,
    args: [{
      selector: 'overlay-scrollbars, [overlay-scrollbars]',
      host: {
        'data-overlayscrollbars-initialize': ''
      },
      template: `
    <div
      overlayScrollbars
      data-overlayscrollbars-contents=""
      [options]="options"
      [events]="mergeEvents(events)"
      [defer]="defer"
      #content
    >
      <ng-content></ng-content>
    </div>
  `
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.ElementRef
    }];
  }, {
    options: [{
      type: Input,
      args: ['options']
    }],
    events: [{
      type: Input,
      args: ['events']
    }],
    defer: [{
      type: Input,
      args: ['defer']
    }],
    onInitialized: [{
      type: Output,
      args: ['osInitialized']
    }],
    onUpdated: [{
      type: Output,
      args: ['osUpdated']
    }],
    onDestroyed: [{
      type: Output,
      args: ['osDestroyed']
    }],
    onScroll: [{
      type: Output,
      args: ['osScroll']
    }],
    contentRef: [{
      type: ViewChild,
      args: ['content']
    }],
    osDirective: [{
      type: ViewChild,
      args: ['content', {
        read: OverlayScrollbarsDirective
      }]
    }]
  });
})();
class OverlayscrollbarsModule {}
OverlayscrollbarsModule.ɵfac = function OverlayscrollbarsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OverlayscrollbarsModule)();
};
OverlayscrollbarsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OverlayscrollbarsModule
});
OverlayscrollbarsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayscrollbarsModule, [{
    type: NgModule,
    args: [{
      declarations: [OverlayScrollbarsComponent, OverlayScrollbarsDirective],
      exports: [OverlayScrollbarsComponent, OverlayScrollbarsDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { OverlayScrollbarsComponent, OverlayScrollbarsDirective, OverlayscrollbarsModule };
