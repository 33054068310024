import { Type } from 'class-transformer';
import { primitive_ClassTransform_Types } from 'src/lib/utilities/api/class-transformer-types';

export class StudentContactOptionsModel {
	@Type(primitive_ClassTransform_Types.string)
	public attributes: Map<StudentContactAttributes, string>;

	@Type(primitive_ClassTransform_Types.string)
	public relationship: Map<string, string>;

	@Type(primitive_ClassTransform_Types.string)
	public phone_type: Map<string, string>;
}

export type StudentContactAttributes =
	| 'guardian'
	| 'primary'
	| 'supporter'
	| 'liveswith';
