import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentNewsModel } from 'src/lib/services/api/students/news/student-news.model';
import { StudentNewsService } from 'src/lib/services/api/students/news/student-news.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentNewsStoreService {
	private _newsCache = new BehaviorCache<number, StudentNewsModel[]>(
		(uid) => this.studentNewsService.getNews(uid),
		'StudentAnnouncementStore AnnouncementsCache',
		() => [],
	);

	constructor(private studentNewsService: StudentNewsService) {}

	/**
	 * Announcements
	 */
	public news$ = (uid: number): Observable<StudentNewsModel[]> => {
		return this._newsCache.getCache(uid);
	};

	public refreshNews = (uid: number): Promise<boolean> => {
		return this._newsCache.fetchData(uid, true);
	};
}
