import { Transform, Type } from 'class-transformer';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { SafeBillingNotInvoicedModel } from './safe-billing-not-invoiced.model';

export class SafeBillingInvoicedModel extends SafeBillingNotInvoicedModel {
	billed_amount: number;
	invoice_number: number;
	@Transform(dateTransformer)
	invoice_date: Date;
	oaks_warrant_number: string;
	@Transform(dateTransformer)
	payment_date: Date;
	fiscal_year: number;
}

export function SafeBillingInvoicedModel_ClassTransform_Type() {
	return SafeBillingInvoicedModel;
}

export class SafeBillingInvoicedListModel {
	@Type(SafeBillingInvoicedModel_ClassTransform_Type)
	billed_registrations: SafeBillingInvoicedModel[];
	count: number;
}

export class SafeBillingContractOptionModel {
	contract_id: number;
	contract_name: string;
	customer_name: string;
}

export class SafeBillingSafeInvoiceAdjustmentModel {
	item_id: number;
	item_name: string;
	item_total: number;
}

export function SafeBillingSafeInvoiceAdjustmentModel_ClassTransform_Type() {
	return SafeBillingSafeInvoiceAdjustmentModel;
}

export class SafeBillingSafeInvoiceModel {
	customer_name: string;
	contract_id: number;
	contract_name: string;
	purchase_order: string;
	invoice_id: number;
	invoice_name: string;
	@Transform(dateTransformer)
	invoice_date: Date;
	@Type(SafeBillingInvoicedModel_ClassTransform_Type)
	registrations: SafeBillingInvoicedModel[];
	@Type(SafeBillingSafeInvoiceAdjustmentModel_ClassTransform_Type)
	adjustments: SafeBillingSafeInvoiceAdjustmentModel[];
}

export class SafeBillingSendHistoryModel {
	log_id: number;
	@Transform(dateTransformer)
	sent_date: Date;
	send_date_formatted: string;
	sent_to: string;
	sent_from: string;
	files: string[];
}

export interface SafeBillingInvoicedSearchArgument {
	registration_title?: PagingSearchFieldArgument;
	institute_id: PagingSearchFieldArrayArgument;
	state_id: PagingSearchFieldArgument;
	billed_status: { value: number };
	invoice_number: PagingSearchFieldArgument;
	billed_amount?: PagingSearchFieldArgument;
	invoice_date?: PagingSearchFieldArgument;
	institute_title?: PagingSearchFieldArgument;
	registration_id?: PagingSearchFieldArgument;
	registration_start?: PagingSearchFieldArgument;
	registration_complete?: PagingSearchFieldArgument;
	student_uid?: PagingSearchFieldArgument;
	student_full_name?: PagingSearchFieldArgument;
	student_first_name?: PagingSearchFieldArgument;
	student_last_name?: PagingSearchFieldArgument;
	oaks_warrant_num?: PagingSearchFieldArgument;
	payment_date?: PagingSearchFieldArgument;
	fiscal_year?: PagingSearchFieldArgument;
}

export interface SafeBillingMarkStudentApprovedArgument {
	registration_id: number;
}

export interface SafeBillingMarkDistrictApprovedArgument {
	registration_id: number;
	oaks_warrant_number: string;
	payment_date: string;
	fiscal_year: string;
}

export interface SafeBillingCreateInvoiceArgument {
	contract_id: number;
	invoice_date: string;
	purchase_order: string;
}

export interface SafeBillingAdjustmentArgument {
	item_id?: number;
	item_name?: string;
	item_amount?: number;
	delete?: boolean;
}

export interface SafeBillingRegistrationArgument {
	registration_id: number;
	delete?: boolean;
}

export interface SafeBillingEditInvoiceArgument {
	adjustments?: SafeBillingAdjustmentArgument[];
	registrations?: SafeBillingRegistrationArgument[];
	contract_id?: number;
	invoice_date?: string;
	purchase_order?: string;
}

export interface SafeBillingOverrideInvoiceArgument {
	oaks_warrant_number: string;
	payment_date: string;
	fiscal_year: string;
	billed_amount: number;
	invoice_number: number;
	invoice_date: string;
}

export interface SafeBillingSendInvoiceArgument {
	send_to: number[];
	send_from: string;
	subject: string;
	message: string;
}

export class SafeBillingSafeInvoiceSearchArgument {
	customer_id?: PagingSearchFieldArgument;
	customer_name?: PagingSearchFieldArgument;
	invoice_id?: PagingSearchFieldArgument;
	invoice_date?: PagingSearchFieldArgument;
	invoice_name?: PagingSearchFieldArgument;
	contract_id?: PagingSearchFieldArgument;
	contract_name?: PagingSearchFieldArgument;
}
