import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	StudentFutureCourseModel,
	StudentFutureCourseModel_ClassTransform_Type,
} from './student-future-course.model';

export class FutureCoursesResponseModel extends AbstractPagingResponseModel<StudentFutureCourseModel> {
	@Type(StudentFutureCourseModel_ClassTransform_Type)
	results: StudentFutureCourseModel[];
}
