import { Expose, Transform, Type } from 'class-transformer';

import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from '../../utility/utility-models/address.model';

export class StudentModel {
	uid: number;
	institute_id?: number;
	last_institute_id?: number;
	suffix?: string;
	first_name?: string;
	middle_name?: string;
	last_name?: string;
	preferred_name?: string;
	email?: string;
	birthdate?: Date;
	district_id?: string;
	grade?: string;
	gender?: string;
	@Type(UserAddressModel_ClassTransform_Type)
	address?: UserAddressModel;
	phone_number?: number;
	tier?: string;
	password?: string;
	@Expose({ name: 'ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	race?: string;
	attendance_class?: string;
	attendance_type?: string;
	attendance_coach?: number[];
	iep_504?: string;
	academic_coach?: number[];
	administrator?: number[];
	assistant?: number[];
	counselor?: number[];
	graduation_alliance_counselor?: number[];
	testing_coordinator?: number[];
	local_advocate?: number[];
	enrollment_counselor?: number[];
	student_location?: string;
	@Transform(dateTransformer)
	activated_date?: Date;
	estimated_credits_to_graduate?: number;
	cohort_year?: number;
	graduation_plan?: string;
	enrollment_notes?: string;
	state_id?: string;
	timezone?: string;
	institute_timezone?: string;
}

export function StudentModel_ClassTransform_Type() {
	return StudentModel;
}
