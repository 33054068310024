export class CourseVersionModel {
	catalog_count: number;
	course_id: number;
	course_number: string;
	course_title: string;
	lms_id: number;
	lms_title: string;
	section_id: number;
	section_title: string;
	teacher_count: number;
	term: string;
	version_code: string;
	version_id: number;
	version_title: string;
}
