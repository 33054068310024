import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class PlannedMaintenanceModel {
	@Expose({ name: 'start_timestamp' })
	@Transform(dateTransformer)
	start: Date;
	id: number;
	duration_in_hours: number;
	warning_days: number;
}
