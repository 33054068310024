import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentCourseAssignmentFeedback {
	submission_id: number;
	message: string;
	@Transform(dateTransformer)
	feedback_date: Date;
	assignment_id: string;
	assignment_title: string;

	@Transform(dateTransformer)
	student_read: Date;
	@Transform(dateTransformer)
	user_read: Date;
}

export function StudentCourseAssignmentFeedback_ClassTransform_Type() {
	return StudentCourseAssignmentFeedback;
}

export class StudentCourseFeedbackModel {
	registration_id: number;
	registration_title: string;
	@Type(StudentCourseAssignmentFeedback_ClassTransform_Type)
	assignment_feedback: StudentCourseAssignmentFeedback[];
}
