import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import { StudentEventUpsertOptions } from './student-event-upsert-options.model';
import { StudentEventUpsertArgument } from './student-event-upsert.argument';
import { StudentEventModel } from './student-event.model';
import {
	StudentEventsFilter,
	StudentEventsResponseModel,
} from './student-events-response.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsEventsService {
	constructor(private httpClient: HttpClient) {}

	public getStudentEvent = (
		uid: number,
		eventId: number,
	): Observable<StudentEventModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/events/${eventId}`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventModel, r.event), {
					errorCode: 'DAEA9739',
				}),
			);
	};

	public getStudentEvents = (
		uid: number,
		args: PagingArgument<StudentEventsFilter>,
	): Observable<StudentEventsResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/events`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventsResponseModel, r), {
					errorCode: 'E2F4399E',
				}),
			);
	};

	public createStudentEvent = (
		uid: number,
		args: StudentEventUpsertArgument,
		files: File[],
	): Observable<StudentEventModel> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = args;
		if (files.length > 0) {
			files.forEach((file, index) => {
				const name = `file${index}`;
				bodyArgs[name] = file;
			});
		}
		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/events/add`, formData)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventModel, r), {
					errorCode: 'D7E233E7',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public updateStudentEvent = (
		uid: number,
		eventId: number,
		args: StudentEventUpsertArgument,
		files: File[],
	): Observable<StudentEventModel> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = args;

		if (files.length > 0) {
			files.forEach((file, index) => {
				const name = `file${index + 1}`;
				bodyArgs[name] = file;
			});
		}
		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/events/${eventId}/update`, formData)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventModel, r), {
					errorCode: 'B784C80E',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getEventUpsertOptions = (
		uid: number,
	): Observable<StudentEventUpsertOptions> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/events/options`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventUpsertOptions, r), {
					errorCode: '10F14C0F',
				}),
			);
	};

	/*************************/
	/** Additional Comments */
	/*************************/

	public getCurrentNotes = (
		uid: number,
		args: PagingArgument<StudentEventsFilter>,
	): Observable<StudentEventsResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/events/user-notes/current`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventsResponseModel, r), {
					errorCode: '8628FA15',
				}),
			);
	};

	public getArchivedNotes = (
		uid: number,
		args: PagingArgument<StudentEventsFilter>,
	): Observable<StudentEventsResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/events/user-notes/archived`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventsResponseModel, r), {
					errorCode: 'EDBA84AF',
				}),
			);
	};

	public createNote = (
		uid: number,
		body: string,
	): Observable<StudentEventModel> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = { body: body };

		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/events/user-notes/add`, formData)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventModel, r), {
					errorCode: 'B345329D',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public archiveNote = (
		uid: number,
		noteId: number,
	): Observable<StudentEventModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/events/user-notes/${noteId}/archive`,
				{},
			)
			.pipe(
				mapResponse((r) => plainToInstance(StudentEventModel, r), {
					errorCode: '022CA738',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
