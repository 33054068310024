import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	MessageAttachmentModel,
	MessageAttachmentModel_ClassTransform_Type,
} from './message-attachment.model';
import {
	MessageDeliveryModel,
	MessageDeliveryModel_ClassTransform_Type,
} from './message-delivery.model';
import { MessagePlatform } from './message-platform.enum';
import { MessageType } from './message-type.enum';

export class MessageModel {
	channel_id: number;
	directory: string;
	message_id: number;

	@Expose({ name: 'message_timestamp' })
	@Transform(dateTransformer)
	created: Date;

	message: string;

	@Type(MessageAttachmentModel_ClassTransform_Type)
	message_attachments: MessageAttachmentModel[];
	message_sender_link_id: number;
	message_read: boolean;
	message_send_medium: MessagePlatform;
	message_type: MessageType;

	@Type(MessageDeliveryModel_ClassTransform_Type)
	delivery_data: MessageDeliveryModel[];

	sms_from_number: number;
	sms_to_numbers: number[];

	html_message: boolean = false;
}
