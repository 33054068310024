import { Injectable, Injector, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import {
	asyncScheduler,
	AsyncSubject,
	concatAll,
	filter,
	map,
	Observable,
	of,
	scheduled,
	switchMap,
	takeUntil,
} from 'rxjs';
import spacetime from 'spacetime';
import { TypedStoreType } from 'src/lib/types/typed-storage';
import { hasValue, isDate } from 'src/lib/utilities/compare';
import { PromptAgreementsModalService } from 'src/lib/views/modals/prompt-agreements-modal/prompt-agreements-modal.service';
import { PromptPasswordModalService } from 'src/lib/views/modals/prompt-password-modal/prompt-password-modal.service';
import { UserModalKeys } from '../../api/users/modals/user-modal-keys.enum';
import { UserModalModel } from '../../api/users/modals/user-modal.model';
import { UsersModalsService } from '../../api/users/modals/users-modals.service';
import { LocalStoreService } from '../../stores/local-store/local-store.service';
import { UserStoreService } from '../../stores/users/user/user-store.service';
import { InitializationService } from '../../utility/initialization/initialization.service';

interface SpeedbumpSettings {
	key: string;
	date: Date;
}

@Injectable({
	providedIn: 'root',
})
export class PromptModalsService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private initialized = false;

	private doneSubject = new AsyncSubject<null>();

	public get done$() {
		return this.doneSubject.asObservable();
	}

	constructor(
		private usersModalsService: UsersModalsService,
		private userStoreService: UserStoreService,
		private injector: Injector,
		private initService: InitializationService,
		private route: ActivatedRoute,
		private localStore: LocalStoreService,
	) {}

	ngOnDestroy() {
		this.doneSubject.next(null);
		this.doneSubject.complete();

		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	public init = (): void => {
		if (this.initialized) {
			throw new Error('PromptModalsService has already been initialized');
		}
		this.initialized = true;

		if (
			!(this.initService.permissionParams.user > 0) || // Not logged in
			this.initService.impersonaterId != null || // Impersonating
			this.initService.hasAnyRole('student') // Is Student
		) {
			this.doneSubject.next(null);
			this.doneSubject.complete();
		} else {
			this.usersModalsService
				.getUserModals(this.userStoreService.currentUserUid)
				.pipe(
					map((r) => {
						const settings: SpeedbumpSettings[] = this.localStore.get(
							'prompt.modals.speedbump.date',
							TypedStoreType.ARRAY,
							[],
						);

						const readyModals = r.filter((y) => {
							if (isDate(y.completed_on_date)) {
								return false;
							}

							if (y.type === 'Speedbump') {
								const found = settings.find((s) => s.key === y.modal_key);

								if (
									hasValue(found?.date) &&
									spacetime(found.date).isAfter(
										spacetime.now().subtract(3, 'date'),
									)
								) {
									return false;
								} else {
									if (found) {
										found.date = new Date();
									} else {
										settings.push({
											key: y.modal_key,
											date: new Date(),
										});
									}

									return true;
								}
							} else {
								return true;
							}
						});

						this.localStore.set('prompt.modals.speedbump.date', settings);
						return readyModals;
					}),
					filter(() => {
						return !(
							this.route.snapshot.firstChild?.data?.utilityState === true
						);
					}),
					switchMap((r) => {
						const obs$ = r.map((modal) => this.tryOpenModal$(modal));

						return scheduled(obs$, asyncScheduler).pipe(concatAll());
					}),
					takeUntil(this._unsubscribe$),
				)
				.subscribe({
					complete: () => {
						this.doneSubject.next(null);
						this.doneSubject.complete();
					},
				});
		}
	};

	private tryOpenModal$ = (modalData: UserModalModel): Observable<boolean> => {
		let obs$: Observable<boolean>;

		switch (modalData.modal_key) {
			case UserModalKeys.password_gate: {
				obs$ = this.injector
					.get(PromptPasswordModalService)
					.openModal$(modalData);
				break;
			}
			case UserModalKeys.agreement: {
				obs$ = this.injector
					.get(PromptAgreementsModalService)
					.openModal$(modalData);
				break;
			}
			default: {
				obs$ = of(null);
				break;
			}
		}

		return obs$.pipe(
			switchMap((r) => {
				if (r === true) {
					return this.usersModalsService.completeUserModal(
						this.userStoreService.currentUserUid,
						modalData.modal_id,
					);
				} else {
					return of(null);
				}
			}),
		);
	};
}
