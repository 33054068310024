import {
	instanceToPlain,
	plainToInstance,
	Transform,
	TransformationType,
	TransformFnParams,
	Type,
} from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	TimeTrackerEntryModel,
	TimeTrackerEntryModel_ClassTransform_Type,
} from './time-tracker/time-tracker.model';

export class StudentPerformanceExpectationPeriodModel {
	@Transform(dateTransformer)
	start: Date;
	@Transform(dateTransformer)
	end: Date;
}

export function StudentPerformanceExpectationPeriodModel_ClassTransform_Type() {
	return StudentPerformanceExpectationPeriodModel;
}

export class StudentPerformanceExpectationPeriodsModel {
	@Type(StudentPerformanceExpectationPeriodModel_ClassTransform_Type)
	program: StudentPerformanceExpectationPeriodModel;
	@Type(StudentPerformanceExpectationPeriodModel_ClassTransform_Type)
	program_previous: StudentPerformanceExpectationPeriodModel;
	@Type(StudentPerformanceExpectationPeriodModel_ClassTransform_Type)
	week: StudentPerformanceExpectationPeriodModel;
}

export function StudentPerformanceExpectationPeriodsModel_ClassTransform_Type() {
	return StudentPerformanceExpectationPeriodsModel;
}

export class MeetupModel {
	id: number;
	type: number;
	minutes: number;
	@Transform(dateTransformer)
	date: Date;
}

export function MeetupModel_ClassTransform_Type() {
	return MeetupModel;
}

export class PerformanceExpectationClassModel {
	type: 'class';
	class_id: number;
	class_name: string;
	complete: boolean;
	complete_base: boolean;
	required_assignments: number;
	completed_assignments: number;
	@Transform(dateTransformer)
	target_end_date: Date;
	past_due: number;
}

export class PerformanceExpectationFinalModel {
	type: 'final';
	class_id: number;
	class_name: string;
	complete: boolean;
	complete_base: boolean;
	@Transform(dateTransformer)
	target_end_date: Date;
}

export class PerformanceExpectationCoursesModel {
	type: 'courses';
	completed_credits: number;
	required_credits: number;
	complete: boolean;
	complete_base: boolean;
	completed_credits_converted: number;
	required_credits_converted: number;
}

export class PerformanceExpectationWeeklyResponseModel {
	type: 'weekly_response';
	today_complete: boolean;
	complete: boolean;
	complete_base: boolean;
	completed_responses: number;
	required_responses: number;
}

export class PerformanceExpectationMeetupModel {
	type: 'meetup_minutes';
	complete: boolean;
	complete_base: boolean;
	required_minutes: number;
	completed_minutes: number;
	meetup_types: ('virtual' | 'physical')[];
	@Type(MeetupModel_ClassTransform_Type)
	meetups: MeetupModel[];
}

export class PerformanceExpectationTimeTrackerModel {
	type: 'time_tracker';
	complete: boolean;
	complete_base: boolean;
	completed_duration: number;
	@Type(TimeTrackerEntryModel_ClassTransform_Type)
	entries: TimeTrackerEntryModel[];
	required_duration: number;
}

function performanceExpectationModelFactory(
	input: PerformanceExpectationModel[],
) {
	const output = [];

	input.forEach((val) => {
		switch (val.type) {
			case 'class':
				output.push(plainToInstance(PerformanceExpectationClassModel, val));
				break;
			case 'final':
				output.push(plainToInstance(PerformanceExpectationFinalModel, val));
				break;
			case 'courses':
				output.push(plainToInstance(PerformanceExpectationCoursesModel, val));
				break;
			case 'weekly_response':
				output.push(
					plainToInstance(PerformanceExpectationWeeklyResponseModel, val),
				);
				break;
			case 'meetup_minutes':
				output.push(plainToInstance(PerformanceExpectationMeetupModel, val));
				break;
			case 'time_tracker':
				output.push(
					plainToInstance(PerformanceExpectationTimeTrackerModel, val),
				);
				break;
			default:
				output.push(input);
		}
	});
	return output;
}

export function performanceExpectationModelTransformer(
	params: TransformFnParams,
) {
	if (params.type === TransformationType.PLAIN_TO_CLASS) {
		if (params.value != null) {
			return performanceExpectationModelFactory(params.value);
		} else {
			return null;
		}
	} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
		return instanceToPlain(params.value);
	} else if (params.type === TransformationType.CLASS_TO_CLASS) {
		if (params.value != null) {
			return performanceExpectationModelFactory(params.value);
		} else {
			return null;
		}
	} else {
		return params.value;
	}
}

export type PerformanceExpectationModel =
	| PerformanceExpectationClassModel
	| PerformanceExpectationFinalModel
	| PerformanceExpectationCoursesModel
	| PerformanceExpectationWeeklyResponseModel
	| PerformanceExpectationMeetupModel
	| PerformanceExpectationTimeTrackerModel;

export class StudentPerformanceExpectationModel {
	@Type(StudentPerformanceExpectationPeriodsModel_ClassTransform_Type)
	periods: StudentPerformanceExpectationPeriodsModel;
	@Transform(performanceExpectationModelTransformer)
	program: PerformanceExpectationModel[];
	@Transform(performanceExpectationModelTransformer)
	program_previous: PerformanceExpectationModel[];
	@Transform(performanceExpectationModelTransformer)
	week: PerformanceExpectationModel[];
	drop_expected: boolean;
}
