export function containsString(
	target: string,
	source: string,
	caseInsensitive: boolean = true,
) {
	target = target || '';
	source = source || '';

	if (caseInsensitive) {
		target = target.toLowerCase();
		source = source.toLowerCase();
	}

	return target.indexOf(source) > -1;
}

export function arrayContainsValue<T, G>(
	target: T[],
	item: G,
	check?: (t: T, i: G) => boolean,
): boolean {
	check =
		check ??
		function (tt: any, ii: any) {
			return tt === ii;
		};

	return target?.some((t) => check(t, item)) ?? false;
}

export function isString(value: any): value is string {
	return typeof value === 'string';
}

export function isNullOrEmptyString(value: any): value is null {
	return !isString(value) || value == null || value.trim() === '';
}

export function isNonEmptyString(value: any): value is string {
	return isString(value) && !isNullOrEmptyString(value);
}

export function isNumber(value: any): value is number {
	return typeof value === 'number' && isFinite(value);
}

export function isInteger(value: any): value is number {
	return isNumber(value) && Math.floor(value) === value;
}

export function isDate(value: any): value is Date {
	return value instanceof Date;
}

export function isValidDate(value: any): value is Date {
	return isDate(value) && isNumber((value as Date).getTime());
}

export function isArray(value: any): value is any[] {
	return Array.isArray(value);
}

export function isObject(value: any): value is Record<string, unknown> {
	return typeof value === 'object';
}

export function hasValue(value: any): boolean {
	if (value === 0) return true;
	else if (typeof value === 'boolean') return true;
	else if (isDate(value)) return isValidDate(value);
	else return Boolean(value);
}

export function isEquivalentString(left: string, right: string): boolean {
	if (isString(left) && isString(right)) {
		return left.trim().toLocaleLowerCase() === right.trim().toLocaleLowerCase();
	} else {
		return false;
	}
}

export function isEquivalentArray<T>(
	left: T[],
	right: T[],
	itemCompare?: (il: T, ir: T) => boolean,
): boolean {
	itemCompare =
		itemCompare ||
		function (il: T, ir: T) {
			return il === ir;
		};

	if (isArray(left) && isArray(right)) {
		if (left.length !== right.length) {
			return false;
		} else {
			const leftCopy = left.slice();
			const rightCopy = right.slice();

			while (leftCopy.length > 0) {
				const l = leftCopy.pop();
				const ri = rightCopy.findIndex((r) => itemCompare(l, r));
				if (ri === -1) return false;
				else {
					rightCopy.splice(ri, 1);
				}
			}

			return true;
		}
	} else {
		return left === right;
	}
}

export function hasChangedString(
	original?: string,
	modified?: string,
	options?: {
		trim?: boolean;
		caseInsensitive?: boolean;
	},
): boolean {
	options = options ?? {};
	options.trim = options.trim ?? false;
	options.caseInsensitive = options.caseInsensitive ?? false;

	const origHas = hasValue(original);
	const modHas = hasValue(modified);

	if (!origHas && !modHas) {
		return false;
	}

	if (origHas && !modHas) {
		return true;
	}

	const origStr = isString(original);
	const modStr = isString(modified);

	if (origStr !== modStr) {
		return true;
	}

	if (options.trim) {
		original = original.trim();
		modified = modified.trim();
	}

	if (options.caseInsensitive) {
		original = original.toLowerCase();
		modified = modified.toLowerCase();
	}

	return original !== modified;
}

export function hasChangedNumber(
	original?: number,
	modified?: number,
): boolean {
	const origHas = hasValue(original);
	const modHas = hasValue(modified);

	if (!origHas && !modHas) {
		return false;
	}

	if (origHas && !modHas) {
		return true;
	}

	const origStr = isNumber(original);
	const modStr = isNumber(modified);

	if (origStr !== modStr) {
		return true;
	}

	return original !== modified;
}

export function hasKey<T>(obj: T, key: keyof T): boolean {
	if (obj == null) return false;
	return Object.keys(obj).find((k) => k === key) != null;
}

export function hasSomeKey<T>(obj: T, keys: (keyof T)[]): boolean {
	if (obj == null) return false;
	return Object.keys(obj).some((k) => keys.find((ki) => ki === k) != null);
}

export function compareObjects(o1, o2) {
	if (o1 instanceof Array && o2 instanceof Array && isEquivalentArray(o1, o2)) {
		return true;
	}

	if (o1 === o2) return true;

	if (
		o1 == null ||
		o2 == null ||
		typeof o1 != 'object' ||
		typeof o2 != 'object'
	) {
		return false;
	}

	const o1keys = Object.keys(o1);
	const o2keys = Object.keys(o2);

	if (o1keys.length !== o2keys.length) return false;

	for (const key of o1keys) {
		if (!compareObjects(o1[key], o2[key])) {
			return false;
		}
	}

	return true;
}

export function checkForChanges<T, K extends keyof T>(
	original: T,
	updated: T,
	overrides?: Record<K, (o: T[K], u: T[K]) => boolean>,
) {
	if (original == null || updated == null) return false;
	return Object.keys(original).some((key) => {
		const k = key as K;
		if (overrides?.[k]) {
			return overrides[k](original[k], updated[k]);
		} else {
			return !compareObjects(original[k], updated[k]);
		}
	});
}
