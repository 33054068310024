import { Type } from 'class-transformer';
import { primitive_ClassTransform_Types } from 'src/lib/utilities/api/class-transformer-types';

export class StudentEventUpsertOptions {
	@Type(primitive_ClassTransform_Types.string)
	twoway_contact: Map<string, string>;

	@Type(primitive_ClassTransform_Types.string)
	initiated_by: Map<string, string>;

	@Type(primitive_ClassTransform_Types.string)
	permission_level: Map<string, string>;

	@Type(primitive_ClassTransform_Types.string)
	medium: Map<string, string>;

	tags: any;

	purposes: Record<any, string>;

	@Type(primitive_ClassTransform_Types.string)
	assigned_uid: Map<string, string>;

	@Type(primitive_ClassTransform_Types.string)
	registration_id: Map<string, string>;

	relationships: Record<string, string>;
}
