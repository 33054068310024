import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import {
	SurveyPeriodGroupModel,
	SurveyPeriodGroupModel_ClassTransform_Type,
	SurveyPeriodSelectType,
	SurveyPeriodUserModel,
	SurveyPeriodUserModel_ClassTransform_Type,
} from './survey-period.model';

export class SurveyPeriodTrackerItemModel {
	id: number;
	active: boolean;
	title: string;
	state: string;
	program_select_type: SurveyPeriodSelectType;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	end_date: Date;
	finalized: boolean;
	@Transform(dateTransformer)
	finalized_on: Date;
	@Transform(dateTransformer)
	created_on: Date;
	@Type(SurveyPeriodUserModel_ClassTransform_Type)
	created_by: SurveyPeriodUserModel;
	@Transform(dateTransformer)
	updated_on: Date;
	@Type(SurveyPeriodUserModel_ClassTransform_Type)
	updated_by: SurveyPeriodUserModel;
	@Type(SurveyPeriodGroupModel_ClassTransform_Type)
	groups: SurveyPeriodGroupModel[];
	days_long: number;
	program_count: number;
	attending_student_count: number;
}

export function SurveyPeriodTrackerItemModel_ClassTransform_Type() {
	return SurveyPeriodTrackerItemModel;
}

export class SurveyPeriodTrackerModel {
	@Type(SurveyPeriodTrackerItemModel_ClassTransform_Type)
	results: SurveyPeriodTrackerItemModel[];
	count: number;
}

export interface SurveyPeriodTrackerArgument {
	title: PagingSearchFieldArgument;
	state: PagingSearchFieldArgument;
	program_select_type: PagingSearchFieldArgument;
	start_date: PagingSearchFieldArgument;
	end_date: PagingSearchFieldArgument;
	created_on: PagingSearchFieldArgument;
	created_by_name: PagingSearchFieldArgument;
	updated_on: PagingSearchFieldArgument;
	updated_by_name: PagingSearchFieldArgument;
	finalized_on: PagingSearchFieldArgument;
	attending_student_count: PagingSearchFieldArgument;
	days_long: PagingSearchFieldArgument;
}
