import { KeyValuePipe } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AsyncSubject, Subject, interval } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { ChannelModel } from 'src/lib/services/api/gabby/channels/channel.model';
import { GabbyWampEventFactoryService } from 'src/lib/services/wamp/wamp-event-factory/gabby/gabby-wamp-event-factory.service';
import { GabbyWampPublishFactoryService } from 'src/lib/services/wamp/wamp-publish-factory/gabby/gabby-wamp-publish-factory.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { MessageForm } from '../gabby-chat-component-definitions';

@Component({
	selector: 'ae-gabby-chat-typing-notification',
	templateUrl: './gabby-chat-typing-notification.component.html',
	styleUrls: ['./gabby-chat-typing-notification.component.scss'],
	standalone: true,
	imports: [KeyValuePipe],
})
export class GabbyChatTypingNotificationComponent
	implements OnInit, OnDestroy, OnChanges
{
	private _unsubscribe$ = new AsyncSubject<null>();

	public notifyTyping$ = new Subject<boolean>();

	@Input() messageForm: FormGroup<FormControlWrapper<MessageForm>>;
	@Input() channel: ChannelModel;
	@Input() viewingUserLinkId: number;

	public channelTypingUserLinkIds = new Map<number, Date>();

	constructor(
		private cdr: ChangeDetectorRef,
		private wefs: GabbyWampEventFactoryService,
		private wpfs: GabbyWampPublishFactoryService,
	) {}

	ngOnInit() {
		// Listen for typing notifications
		this.wefs
			.channelUserTyping$()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((e) => {
				if (
					this.channel != null &&
					e.channelId === this.channel.id &&
					e.userLinkId !== this.viewingUserLinkId
				) {
					if (this.channelTypingUserLinkIds.has(e.userLinkId)) {
						if (e.active) {
							this.channelTypingUserLinkIds.set(e.userLinkId, new Date());
						} else {
							this.channelTypingUserLinkIds.delete(e.userLinkId);
							this.cdr.detectChanges();
						}
					} else {
						if (e.active) {
							this.channelTypingUserLinkIds.set(e.userLinkId, new Date());
							this.cdr.detectChanges();
						}
					}
				}
			});

		// Gating sending the typing notifications
		let waitingToSend: boolean = null;
		interval(
			getEnvironment().settings.services.wamp.gabby.typingActivityTimeout / 2,
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => {
				waitingToSend = null;
			});

		// Sending typing notifications
		this.notifyTyping$
			.pipe(
				filter((x) => {
					return x !== waitingToSend;
				}),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((x) => {
				waitingToSend = x;
				this.wpfs
					.notify_channelUserTyping$(this.channel.id, this.viewingUserLinkId, x)
					.subscribe();
			});

		// Loop and watch the dates
		interval(
			getEnvironment().settings.services.wamp.gabby.typingActivityTimeout / 3,
		)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => {
				let changed = false;
				for (const [k, v] of this.channelTypingUserLinkIds) {
					if (
						new Date().getTime() - v.getTime() >
						getEnvironment().settings.services.wamp.gabby.typingActivityTimeout
					) {
						changed = true;
						this.channelTypingUserLinkIds.delete(k);
					}
				}

				if (changed) {
					this.cdr.detectChanges();
				}
			});
	}

	public getName = (userLinkId: number): string => {
		const participant = this.channel.participants.find(
			(p) => p.link_id === userLinkId,
		);
		if (participant != null) {
			return participant.name;
		} else return 'Someone';
	};

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.messageForm) {
			this.messageForm.valueChanges
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe((x) => {
					this.notifyTyping$.next(Boolean(x.message && x.message.length > 0));
				});
		}

		if (changes.channel || changes.viewingUserId) {
			this.channelTypingUserLinkIds.clear();
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
