import { Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	primitive_ClassTransform_Types,
} from 'src/lib/utilities/api/class-transformer-types';

export class PauseExitReasonModel {
	@Type(primitive_ClassTransform_Types.string)
	state_exit_reasons: Map<string, string>;
}

export class StudentPausePauseInfoModel {
	@Transform(dateTransformer)
	on_timestamp: Date;
	by: string;
	by_name: string;
	explanation: string;
}

export class StudentPauseRequestModel {
	id: number;
	is_paused: boolean;

	@Transform(dateTransformer)
	updated_on_timestamp: Date;
	exit_code: number;
	log_id: number;

	@Type(StudentPausePauseInfoModel_ClassTransform_Type)
	requested: StudentPausePauseInfoModel;
	@Type(StudentPausePauseInfoModel_ClassTransform_Type)
	started: StudentPausePauseInfoModel;
	@Type(StudentPausePauseInfoModel_ClassTransform_Type)
	ended: StudentPausePauseInfoModel;
	is_scheduled: boolean;
}

// API Model
export class StudentPauseModel {
	is_paused: boolean;

	@Type(StudentPauseRequestResponseModel_ClassTransform_Type)
	pause_status: StudentPauseRequestModel;

	@Type(StudentPauseRequestResponseModel_ClassTransform_Type)
	pause_request: StudentPauseRequestModel;
}

// Transformers
export function StudentPauseModel_ClassTransform_Type() {
	return StudentPauseModel;
}

export function StudentPauseRequestResponseModel_ClassTransform_Type() {
	return StudentPauseRequestModel;
}
export function StudentPausePauseInfoModel_ClassTransform_Type() {
	return StudentPausePauseInfoModel;
}
