import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPageTrackingEvent } from './user-page-tracking.argument';

@Injectable({
	providedIn: 'root',
})
export class UserPageTrackingService {
	constructor(private httpClient: HttpClient) {}

	public sendTrackingData = (
		events: UserPageTrackingEvent[],
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/page-view`, events)
			.pipe(map((r) => r));
	};
}
