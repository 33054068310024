import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ProfileListenerService {
	private _signalSlots = new Map<string, Subject<null>>();

	/******
	 *
	 * Signals and Slots
	 *
	 ******/

	private _getSignalSlotSubject = (slot: string): Subject<null> => {
		if (!this._signalSlots.has(slot)) {
			this._signalSlots.set(slot, new Subject<null>());
		}

		return this._signalSlots.get(slot);
	};

	public listenSignal$ = (slot: string): Observable<null> => {
		return this._getSignalSlotSubject(slot).asObservable();
	};

	public sendSignal = (slot: string): void => {
		this._getSignalSlotSubject(slot).next(null);
	};
}
