import { Injectable } from '@angular/core';
import {
	NgbModal,
	NgbModalOptions,
	NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { StudentEventModel } from 'src/lib/services/api/students/events/student-event.model';
import { StudentEventDetailModalComponent } from './student-event-detail-modal.component';

@Injectable({
	providedIn: 'root',
})
export class StudentEventDetailModalService {
	private _openModals = new Map<number, NgbModalRef>();

	constructor(private modalService: NgbModal) {}

	public openModal$(
		studentId: number,
		eventId: number,
		edit?: Partial<StudentEventModel>,
	) {
		return new Observable<boolean>((o) => {
			if (!this._openModals.has(eventId)) {
				const ngbModalOptions: NgbModalOptions = {
					backdrop: 'static',
					keyboard: false,
					size: 'lg',
				};
				const eventModal = this.modalService.open(
					StudentEventDetailModalComponent,
					ngbModalOptions,
				);

				(
					eventModal.componentInstance as StudentEventDetailModalComponent
				).bindModalData({
					studentId: studentId,
					eventId: eventId,
					edit: edit,
				});

				eventModal.result
					.then((value) => {
						o.next(value);
						o.complete();
					})
					.catch(() => {
						o.next(false);
						o.complete();
					})
					.finally(() => {
						this._openModals.delete(eventId);
					});

				this._openModals.set(eventId, eventModal);
			} else {
				o.next(false);
				o.complete();
			}
		});
	}

	public closeModal(_studentId: number, eventId: number) {
		if (this._openModals.has(eventId)) {
			this._openModals.get(eventId).dismiss();
		}
	}
}
