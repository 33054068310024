<ae-sidenav-tabber
	id="profile.navigation.component.tabber"
	size="lg"
	[tabs]="tabs"
>
	@switch (isOwnProfilePage) {
		@case (true) {
			<button
				class="btn assigned-staff-img-container assigned-staff-img-container-hover"
				title="Click to change picture"
				type="button"
				(click)="openUploadImageModal()"
			>
				<ng-container *aeSpinWhile="loading; spinClass: 'fa-lg'">
					<div class="assigned-staff-img">
						<img
							#profileImage
							alt="Profile Picture"
							class="img-thumbnail p-0 rounded-circle"
							[src]="userProfileImagePath || defaultProfileImageUrl"
							(error)="profileImageError(profileImage)"
						/>
						<div class="img-hover-text rounded-circle img-thumbnail">
							<i aria-hidden="true" class="fas fa-pencil"></i>
						</div>
					</div>
					<div
						class="assigned-staff-img-text"
						[ngStyle]="{ 'text-decoration': !status ? 'line-through' : '' }"
					>
						{{ userFullName }}
					</div>
				</ng-container>
			</button>
		}
		@default {
			<div class="assigned-staff-img-container">
				<ng-container *aeSpinWhile="loading; spinClass: 'fa-lg'">
					<div class="assigned-staff-img">
						<img
							alt=""
							class="img-thumbnail p-0 rounded-circle"
							[src]="userProfileImagePath || defaultProfileImageUrl"
						/>
						<div class="img-hover-text rounded-circle img-thumbnail">
							<i aria-hidden="true" class="fas fa-pencil"></i>
						</div>
					</div>
					<div
						class="assigned-staff-img-text"
						[ngStyle]="{ 'text-decoration': !status ? 'line-through' : '' }"
					>
						{{ userFullName }}
					</div>
				</ng-container>
			</div>
		}
	}

	@if (userId !== currentUserId) {
		<div class="masquerade-links">
			@if (masqueradeLink != null) {
				<a class="btn btn-primary" [href]="masqueradeLink">Masquerade</a>
			}
			@if (hasSuperMasquerade()) {
				<button
					class="btn btn-primary"
					type="button"
					(click)="superMasquerade()"
				>
					Super Masquerade
				</button>
			}
		</div>
	}
</ae-sidenav-tabber>
