import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { ChannelModel } from 'src/lib/services/api/gabby/channels/channel.model';

export class ChannelStorageSet {
	public static PUMP_DELAY = 250;

	private _channelsSubject: BehaviorSubject<ChannelModel[]>;
	private _initializingChannelsSubject: BehaviorSubject<boolean>;
	private _pump: Subject<null>;
	private _stateSubscriptions: Map<string | number, Subscription[]>;
	private _lastRefresh?: Date;

	get channels() {
		return this._channelsSubject;
	}
	get stateSubscriptions() {
		return this._stateSubscriptions;
	}

	set lastRefresh(val: Date) {
		this._lastRefresh = val;
	}
	get lastRefresh() {
		return this._lastRefresh;
	}

	get initialingChannels$() {
		return this._initializingChannelsSubject.pipe(distinctUntilChanged());
	}

	constructor() {
		this._channelsSubject = new BehaviorSubject<ChannelModel[]>(undefined);
		this._initializingChannelsSubject = new BehaviorSubject<boolean>(false);
		this._stateSubscriptions = new Map<string | number, Subscription[]>();
		this._pump = new Subject();

		this._pump
			.pipe(debounceTime(ChannelStorageSet.PUMP_DELAY))
			.subscribe(() =>
				this._channelsSubject
					.pipe(take(1))
					.subscribe((c) => this.channels.next(c)),
			);
	}

	public pump() {
		return this._pump.next(null);
	}

	public setInitializingChannels(val: boolean): void {
		this._initializingChannelsSubject.next(val);
	}
}
