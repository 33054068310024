export enum UserSetting {
	// Lunchbox
	'lunchbox.workbench.panes.0.pins' = 'lunchbox.workbench.panes.0.pins',
	'lunchbox.workbench.panes.1.pins' = 'lunchbox.workbench.panes.1.pins',
	'lunchbox.workbench.panes.layout' = 'lunchbox.workbench.panes.layout',
	'lunchbox.workbench.widget.layout' = 'lunchbox.workbench.widget.layout',
	'lunchbox.workbench.widget.pins' = 'lunchbox.workbench.widget.pins',
	'lunchbox.page.event.showall' = 'lunchbox.page.event.showall',
	'lunchbox.widget.status.history-count' = 'lunchbox.widget.status.history-count',
	'lunchbox.widget.status.enrollment-timeline.view-type' = 'lunchbox.widget.status.enrollment-timeline.view-type',

	// Gabby
	'gabby.directory.sort' = 'gabby.directory.sort',
	'gabby.channel-list.sort' = 'gabby.channel-list.sort',
	'gabby.channels.flags' = 'gabby.channels.flags',
	'gabby.system-message.collapse' = 'gabby.system-message.collapse',
}
