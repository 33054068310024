import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SearchOperators } from 'src/lib/utilities/api/patterns/pagination/search-operators.enum';

export function getSearchOperatorIcon(operator: SearchOperators) {
	switch (operator) {
		case SearchOperators.Like:
			return 'fa-tilde';
		case SearchOperators.In:
			return 'fa-intersection';
		case SearchOperators.NotIn:
			return 'fa-times';
		case SearchOperators.Equals:
			return 'fa-equals';
		case SearchOperators.GreaterThan:
			return 'fa-greater-than';
		case SearchOperators.LessThan:
			return 'fa-less-than';
		case SearchOperators.GreaterThanOrEqual:
			return 'fa-greater-than-equal';
		case SearchOperators.LessThanOrEqual:
			return 'fa-less-than-equal';
		case SearchOperators.IsNull:
			return 'fa-empty-set';
		case SearchOperators.IsNotNull:
			return 'fa-dot-circle';
		default:
			return 'fa-bug';
	}
}

export function getSearchOperatorTitle(operator: SearchOperators) {
	switch (operator) {
		case SearchOperators.Like:
			return 'Like';
		case SearchOperators.In:
			return 'In';
		case SearchOperators.NotIn:
			return 'Not In';
		case SearchOperators.Equals:
			return 'Equals';
		case SearchOperators.GreaterThan:
			return 'Greater Than';
		case SearchOperators.LessThan:
			return 'Less Than';
		case SearchOperators.GreaterThanOrEqual:
			return 'Greater Than or Equal';
		case SearchOperators.LessThanOrEqual:
			return 'Less Than or Equals';
		case SearchOperators.IsNull:
			return 'Is Empty';
		case SearchOperators.IsNotNull:
			return 'Is Not Empty';
		default:
			return 'Unknown';
	}
}

@Component({
	selector: 'ae-search-operator-icon',
	templateUrl: './search-operator-icon.component.html',
	styleUrls: ['./search-operator-icon.component.scss'],
	standalone: true,
	imports: [NgClass],
})
export class SearchOperatorIconComponent {
	@Input() class: string;

	@Input() operator: SearchOperators;
	@Input() font: string = 'far';

	public getSearchOperatorTitle = getSearchOperatorTitle;
	public getIconClasses = () => {
		return `${this.font} ${getSearchOperatorIcon(this.operator)}`;
	};
}
