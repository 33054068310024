<div>
	<div class="form-group">
		<label for="sso.program.select.component.select"
			>Select your school/district</label
		>
		<ng-select
			appendTo="body"
			bindLabel="institute_title"
			labelForId="sso.program.select.component.select"
			notFoundText="No programs found"
			placeholder="Enter school name"
			[clearSearchOnAdd]="true"
			[formControl]="selectCtrl"
			[hideSelected]="true"
			[items]="programs$ | async"
			[loading]="loadingPrograms"
			[typeahead]="programsInput$"
			[virtualScroll]="true"
			(add)="programsInput$.next('')"
		/>
	</div>
</div>
