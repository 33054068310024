import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	StudentMeetupModel,
	StudentMeetupModel_ClassTransform_Type,
} from './student-meetup.model';

export class StudentMeetupsResponseModel extends AbstractPagingResponseModel<StudentMeetupModel> {
	@Type(StudentMeetupModel_ClassTransform_Type)
	results: StudentMeetupModel[];
}
