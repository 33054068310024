@if (editForm) {
	<form [formGroup]="editForm" (ngSubmit)="save()">
		<div class="modal-header">
			<h1 class="modal-title">Save Search</h1>
			<button
				class="btn btn-outline-dark"
				title="Close"
				type="button"
				(click)="dismiss()"
			>
				<i aria-hidden="true" class="far fa-times"></i>
			</button>
		</div>
		<div class="modal-body">
			<div aeGroupValidation class="form-group">
				<label
					for="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.search.save.modal.name"
					>New Save</label
				>
				<input
					autocomplete="off"
					class="form-control"
					formControlName="newName"
					type="text"
					id="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.search.save.modal.name"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Name is required
					</ng-template>
					<ng-template aeValidationError="duplicate">
						There is already a saved search with that name
					</ng-template>
				</ae-group-validation-display>
			</div>
			<div class="d-flex align-content-center">
				<hr class="flex-grow-1" />
				<div class="p-2">OR</div>
				<hr class="flex-grow-1" />
			</div>
			<div aeGroupValidation class="form-group">
				<label
					for="pgtable.{{
						pgTable.tableConfig.tableId
					}}.configcontrols.search.save.modal.name"
					>Override Save</label
				>
				<ng-select formControlName="selectedSave" [items]="currentSearches" />
			</div>
		</div>
		<div class="modal-footer">
			<button
				class="btn btn-secondary"
				type="button"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.search.save.modal.cancel"
				(click)="dismiss()"
			>
				Cancel
			</button>
			<button
				class="btn btn-primary"
				type="submit"
				[disabled]="!editForm.valid"
				id="pgtable.{{
					pgTable.tableConfig.tableId
				}}.configcontrols.search.save.modal.save"
			>
				Ok
			</button>
		</div>
	</form>
}
