import { Injectable, OnDestroy } from '@angular/core';
import { AsyncSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { UserUpdateRequestModel } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class UserUpdateRequestStoreService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private _modalCache = new BehaviorCache<number, UserUpdateRequestModel[]>(
		(uid) =>
			this.permissionStore.getFieldSet$().pipe(
				switchMap((permissions) => {
					if (permissions.canDo(Permits['access content'])) {
						return this.usersUpdateRequestsService.getPendingRequests(uid).pipe(
							map((response) => {
								return response.pending_update_requests;
							}),
						);
					} else {
						return of([]);
					}
				}),
			),
		'UserUpdateRequestStoreService ModalCache',
		() => [],
	);

	constructor(
		private usersUpdateRequestsService: UsersUpdateRequestsService,
		private permissionStore: PermissionStoreService,
	) {}

	/**
	 * Modals
	 */
	public modals$ = (uid: number): Observable<UserUpdateRequestModel[]> => {
		return this._modalCache.getCache(uid);
	};

	public refreshModals = (uid: number): Promise<boolean> => {
		return this._modalCache.fetchData(uid, true);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
