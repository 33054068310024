import { Pipe, PipeTransform } from '@angular/core';
import { hasValue } from '../utilities/compare';

@Pipe({
	name: 'boolToString',
	standalone: true,
})
export class BoolToStringPipe implements PipeTransform {
	transform(
		value: boolean | null,
		trueVal: string = 'Yes',
		falseVal: string = 'No',
	): string | null {
		if (hasValue(value)) {
			return value ? trueVal : falseVal;
		}

		return null;
	}
}
