import { ChangeDetectorRef, Component } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, takeUntil } from 'rxjs';
import { SITE_ADMINISTRATOR } from 'src/lib/constants/constants';
import { PermissionEditRolesItypesModel } from 'src/lib/services/api/permission-manager/permission-edit-options.model';
import { PermissionManagerService } from 'src/lib/services/api/permission-manager/permission-manager.service';
import {
	PermissionUserRolesArgument,
	PermissionUserRolesModel,
} from 'src/lib/services/api/permission-manager/permission-user-roles.model';
import { PermissionManagerStoreService } from 'src/lib/services/stores/permission-manager-store/permission-manager-store.service';
import { mergeStrings } from 'src/lib/utilities/array';
import { SpinWhileDirective } from '../../../templates/layout/spin-while/spin-while.directive';

@Component({
	selector: 'ae-role-edit-modal',
	templateUrl: './role-edit-modal.component.html',
	styleUrls: ['./role-edit-modal.component.scss'],
	standalone: true,
	imports: [
		SpinWhileDirective,
		NgSelectModule,
		FormsModule,
		ReactiveFormsModule,
	],
})
export class RoleEditModalComponent {
	private _unsubscribe$ = new AsyncSubject<null>();
	public item: PermissionUserRolesModel;

	public roleOptions: PermissionEditRolesItypesModel[] = [];
	public selectCtrl: FormControl<string[]>;

	public loading = true;

	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
		private permissionManagerStore: PermissionManagerStoreService,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private permissionManagerService: PermissionManagerService,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (data: { item: PermissionUserRolesModel }): void => {
		// SET DATA
		this.item = data.item;

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC

		this.permissionManagerStore
			.editOptions$()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe({
				next: (o) => {
					this.roleOptions = Array.from(o.roles).filter(
						(x) =>
							x.has_permission_to_assign === true && x.is_assignable === true,
					);

					this.selectCtrl = this.fb.control<string[]>(
						this.item.roles.filter(
							(x) => this.roleOptions.find((y) => y.key === x) != null,
						),
					);

					this.loading = false;
				},
				error: (err) => {
					this.toastr.error(mergeStrings(err), 'Manage User Roles');
					this.activeModal.close();
				},
			});
	};

	public save = () => {
		const args: Partial<PermissionUserRolesArgument> = {};

		args.add_roles = this.selectCtrl.value
			.map((r) => {
				if (this.item.roles.find((x) => x === r) == null) {
					return r;
				} else {
					return null;
				}
			})
			.filter((x) => x != null);

		args.remove_roles = this.item.roles
			.filter((x) => x !== SITE_ADMINISTRATOR && x !== 'authenticated')
			.map((r) => {
				if (this.selectCtrl.value.find((x) => x === r) == null) {
					return r;
				} else {
					return null;
				}
			})
			.filter((x) => x != null);

		this.permissionManagerService.editUserRoles(this.item.uid, args).subscribe({
			next: () => {
				this.toastr.success(`Roles updated for ${this.item.name}`);
				this.activeModal.close();
			},
			error: (err) => {
				this.toastr.error(
					`There was an issue updating roles; ${mergeStrings(err)}`,
					'Manage User Roles',
				);
			},
		});
	};
}
