import { Type } from 'class-transformer';
import { UserAddressModel } from 'src/lib/services/utility/utility-models/address.model';

export enum AddressConfirmationLevel {
	CONFIRMATION_LEVEL_UNSPECIFIED = 'CONFIRMATION_LEVEL_UNSPECIFIED',
	CONFIRMED = 'CONFIRMED',
	UNCONFIRMED_BUT_PLAUSIBLE = 'UNCONFIRMED_BUT_PLAUSIBLE',
	UNCONFIRMED_AND_SUSPICIOUS = 'UNCONFIRMED_AND_SUSPICIOUS',
}

export enum AddressComponentType {
	streetNumber = 'street_number',
	streetName = 'route',
	street2 = 'subpremise',
	city = 'locality',
	state = 'administrative_area_level_1',
	zip = 'postal_code',
	secondaryStreet = 'administrative_area_level_2',
	pointOfInterest = 'point_of_interest',
	neighborhood = 'neighborhood',
}

export class AddressComparisonInfo {
	index: number;
	currentAddress: UserAddressModel;
	updatedAddress: UserAddressModel;
	invalidComponent: boolean;
}

export class AddressComponentModel {
	componentName: { text: string; languageCode: string };
	componentType: string;
	confirmationLevel: AddressConfirmationLevel;
}

export function AddressComponentModel_ClassTransform_Type() {
	return AddressComponentModel;
}

export class AddressAPIResultModel {
	@Type(AddressComponentModel_ClassTransform_Type)
	address: { addressComponents: AddressComponentModel[] };
	formattedAddress: string;
}

export function AddressAPIResultModel_ClassTransform_Type() {
	return AddressAPIResultModel;
}

export class GoogleAddressAPIModel {
	respondId: string;
	@Type(AddressAPIResultModel_ClassTransform_Type)
	result: AddressAPIResultModel;
}
