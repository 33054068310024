@if (!errorLoading) {
	@if (!editEnabled && userData) {
		<ae-profile-info-view
			[canEditProfile]="canEditProfile"
			[enableEdit]="enableEdit"
			[existingPendingChange]="existingPendingChange"
			[loading]="loading"
			[profileData]="userData.profile"
			[studentData]="userData.student_data"
		/>
	}
	@if (editEnabled && userData) {
		<ae-profile-info-edit
			[accountOptions]="accountOptions"
			[currentUserId]="currentUserId"
			[enableEdit]="enableEdit"
			[existingPendingChange]="existingPendingChange"
			[loading]="loading"
			[profileData]="userData.profile"
			[resetProfileInfoForm]="resetProfileInfoForm"
			[studentData]="userData.student_data"
		/>
	}
} @else {
	<section class="billboard">
		<div class="billboard-header">
			<h2 class="billboard-title">General Info</h2>
		</div>
		<div class="billboard-body">
			<div class="text-center text-danger my-3">
				There was an issue loading Profile Info, if the problem persists please
				contact support
			</div>
			<div class="d-flex justify-content-center mb-2">
				<button
					class="btn btn-default px-3"
					id="profile.contact.error.retry"
					type="button"
					(click)="getContactInfo()"
				>
					Retry
				</button>
			</div>
		</div>
	</section>
}

@if (userData && userData?.user?.profile_picture_high_res && isStudentApp) {
	<section class="billboard" tabindex="-1">
		<div class="billboard-header">
			<h2 class="billboard-title">School ID Card</h2>
			<div class="billboard-actions">
				@if (idCardReady) {
					<button
						class="btn btn-default"
						id="profile.id-card.print"
						type="button"
						(click)="print()"
					>
						Print
					</button>
				}
			</div>
		</div>
		<div class="billboard-body">
			<div
				#idCard
				[ngClass]="{
					border: idCardReady,
					'rounded-3': idCardReady,
					'border-black': idCardReady,
					'overflow-hidden': idCardReady,
				}"
				[style.max-width.px]="600"
			>
				<div *aeSpinWhile="true"></div>
			</div>
		</div>
		<!-- Template used to create the ID Card -->

		<div aria-hidden="true" class="id-card-hide">
			<div class="id-card-main" id="profile-info-id-card">
				<div class="id-card-photo-region">
					<div class="id-card-year">{{ getAcademicYear() }}</div>
					<div class="id-card-photo-positioner">
						<div
							class="id-card-photo"
							style.background-image="url('{{
								userData.user.profile_picture_high_res
							}}')"
						></div>
					</div>
					<div class="id-card-ga-logo">
						<img
							alt="Graduation Alliance"
							src="{{ '/images/student-id-ga-logo.png' | assetUrl }}"
						/>
					</div>
				</div>
				<div class="id-card-info-region">
					<div class="id-card-district-brand">
						@if (
							studentData?.branding.branding_enabled &&
							studentData.branding.branding_light_image
						) {
							<img
								alt="Profile Photo"
								priority
								[src]="studentData.branding.branding_light_image"
							/>
						}
					</div>
					<div class="id-card-name">
						{{ userData.profile.profile_first_name }}<br />
						{{ userData.profile.profile_last_name }}
					</div>
					<div class="id-card-details">
						<table>
							<tr>
								<td class="id-card-label">DOB:</td>
								<td class="id-card-value">
									{{ userData.student_data.dob | date: 'MM/dd/yyyy' }}
								</td>
							</tr>
							<tr>
								<td class="id-card-label">District:</td>
								<td class="id-card-value">
									{{ studentData?.branding.institute_title }}
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</section>
}
