import { UserAddressModel } from '../../utility/utility-models/address.model';
import { StudentContactArgument } from './contacts/student-contact.argument';
import { StudentModel } from './student.model';

export abstract class CreateStudentArgument {
	suffix?: string = null;
	first_name?: string = null;
	middle_name?: string = null;
	last_name?: string = null;
	preferred_name?: string = null;
	email?: string = null;
	birthdate?: Date = null;
	grade?: string = null;
	gender?: string = null;
	address?: UserAddressModel = null;
	phone_number?: number = null;
	additional_phone_numbers?: number[] = null;
	tier?: string = null;
	password?: string = null;
	ethnicity?: string = null; // API still refers to race as ethnicity 14E31FFD
	attendance_class?: string = null;
	attendance_type?: string = null;
	attendance_coach?: number[] = null;
	state_id?: string = null;
	district_id?: string = null;
	iep_504?: number = null;
	academic_coach?: number[] = null;
	administrator?: number[] = null;
	assistant?: number[] = null;
	counselor?: number[] = null;
	graduation_alliance_counselor?: number[] = null;
	local_advocate?: number[] = null;
	enrollment_counselor?: number[] = null;
	student_location?: string = null;
	activated_date?: Date = null;
	estimated_credits_to_graduate?: number = null;
	cohort_year?: number = null;
	graduation_plan?: string = null;
	create_program_email?: boolean = null;
	primary_parent?: StudentContactArgument = null;
}

export class StudentArgument extends CreateStudentArgument {
	uid?: number = null;
	institute_id?: number = null;
	transfer_code?: string = null;
	transfer_reason?: string = null;
	enrollment_notes?: string = null;
}

export type StudentFilesArgument = Record<
	string,
	File | { waived: true; comment: string; file: File }
>;

export class StudentResponseModel extends StudentModel {
	success: boolean;
	error_messages?: string[];
}

export class StudentResponseMap extends Map<string, StudentResponseModel> {}
