import { Type } from 'class-transformer';
import {
	ProgramManagementDataUserModel,
	ProgramManagementDataUserModel_ClassTransform_Type,
} from '../program-management-data.model';
import { ProgramEditRequestOptionsModel } from '../request-service/program-edit-request-options.model';

export class ProgramAdministratorsRelationshipOptionsModel {
	id: number;
	name: string;
	@Type(ProgramManagementDataUserModel_ClassTransform_Type)
	options: ProgramManagementDataUserModel[];
}

export class ProgramAdministratorsWelcomeEmailModel {
	subject: string;
	body: string;
	from?: string;
}

export function ProgramAdministratorsWelcomeEmailModel_ClassTransform_Type() {
	return ProgramAdministratorsWelcomeEmailModel;
}

export class ProgramAdministratorsUpsertOptionsModel {
	options: ProgramEditRequestOptionsModel;
	@Type(ProgramAdministratorsWelcomeEmailModel_ClassTransform_Type)
	welcome_email: ProgramAdministratorsWelcomeEmailModel;
}
