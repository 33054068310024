import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmActionModalComponent } from 'src/lib/services/utility/confirm-popup/confirm-action-modal/confirm-action-modal.component';
import { ConfirmPopupMessage } from './confirm-popup-message';

@Injectable({
	providedIn: 'root',
})
export class ConfirmPopupService {
	constructor(private modalService: NgbModal) {}

	public confirm$(message: ConfirmPopupMessage) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				size: message.size ?? 'sm',
			};
			const confirmModal = this.modalService.open(
				ConfirmActionModalComponent,
				ngbModalOptions,
			);

			(
				confirmModal.componentInstance as ConfirmActionModalComponent
			).bindModalData(message);

			confirmModal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
