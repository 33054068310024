import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
} from '@angular/forms';
import { AsyncSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControlWrapper } from 'src/lib/types/forms.def';

export interface EqualityComparerTextInputValue {
	contains: boolean;
	value: string;
}

let createCounter = 0;

@Component({
	selector: 'ae-equality-comparer-text-input',
	templateUrl: './equality-comparer-text-input.component.html',
	styleUrls: ['./equality-comparer-text-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => EqualityComparerTextInputComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule],
})
export class EqualityComparerTextInputComponent
	implements OnInit, OnDestroy, ControlValueAccessor
{
	private _unsubscribe$ = new AsyncSubject<null>();

	controlFG: FormGroup<FormControlWrapper<EqualityComparerTextInputValue>>;
	counterId = createCounter++;

	private _touchFunction: () => void;
	private _changeFunction: (
		value: Partial<EqualityComparerTextInputValue>,
	) => void = () => null;
	private _changeWatcher: Subscription;

	constructor(private fb: FormBuilder) {}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}

	ngOnInit() {
		this.controlFG = this.fb.group<
			FormControlWrapper<EqualityComparerTextInputValue>
		>({
			contains: new FormControl(true),
			value: new FormControl(null),
		});
		this.watchForChange();
	}

	private watchForChange = () => {
		if (this._changeWatcher) return;

		this._changeWatcher = this.controlFG.valueChanges
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((val) => {
				if (this._changeFunction && val) {
					this._changeFunction(val);
				}
				if (this._touchFunction) {
					this._touchFunction();
				}
			});
	};

	private stopWatchForChange = () => {
		this._changeWatcher?.unsubscribe();
		this._changeWatcher = null;
	};

	// Implementing ControlValueAccessor
	public writeValue(val: EqualityComparerTextInputValue): void {
		try {
			this.stopWatchForChange();
			val = val ?? { contains: true, value: null };
			this.controlFG?.setValue(val);
		} catch (e) {
			throw new Error(
				`EqualityComparerTextInputComponent.writeValue could not set value. INNER EXCEPTION: ${e}`,
			);
		} finally {
			this.watchForChange();
		}
	}

	public registerOnChange(fn: any): void {
		this._changeFunction = fn;
	}
	public registerOnTouched(fn: any): void {
		this._touchFunction = fn;
	}
	public setDisabledState(isDisabled: boolean): void {
		this.stopWatchForChange();

		if (isDisabled) this.controlFG.disable();
		else this.controlFG.enable();

		this.watchForChange();
	}
}
