import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentStarAssessmentModel {
	uid: number;
	ren_id: string;
	test_type: string;
	test_type_name: string;

	@Transform(dateTransformer)
	test_timestamp: Date;
	scaled_score: number;
	grade_equivalent: number;
	percentile: number;
	type_level: string;
}

export class StudentStarAssessmentResponseModel {
	count: number;

	@Type(StudentStarAssessmentModel_ClassTransform_Type)
	assessments: StudentStarAssessmentModel[];
}

export function StudentStarAssessmentResponseModel_ClassTransform_Type() {
	return StudentStarAssessmentResponseModel;
}
export function StudentStarAssessmentModel_ClassTransform_Type() {
	return StudentStarAssessmentModel;
}
