export class GabbyUserModel {
	id: number;
	name: string;
	subtitle: string;
	type: GabbyUserType;
	student_link_id?: number;
	status: GabbyUserStatus;
}

export enum GabbyUserType {
	student = 'student',
	contact = 'contact',
	staff = 'staff',
}

export enum GabbyUserStatus {
	active = 'active',
	inactive = 'inactive',
}
