import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { StudentCourseAddItemArgument } from '../classes/student-course-add.argument';

export class CourseRequestEnrollmentModel {
	first_name: string;
	last_name: string;
	uid: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	enrollment_status: number;
	parent_institute_id: number;
	parent_institute_title: string;
}

export function CourseRequestEnrollmentModel_ClassTransform_Type() {
	return CourseRequestEnrollmentModel;
}

export class CourseRequestPacingModel {
	@Transform(dateTransformer)
	estimate_graduation_date: Date;
	monthly_credit_rate: number;
	status: string;
	@Transform(dateTransformer)
	target_end_date: Date;
	type: 'cohort_year' | 'end_date';
}

export function CourseRequestPacingModel_ClassTransform_Type() {
	return CourseRequestPacingModel;
}

export class CourseRequestGraduationProgressModel {
	@Type(CourseRequestPacingModel_ClassTransform_Type)
	pacing: CourseRequestPacingModel;
	expected_date: string;
	credits_remain: number;
}

export function CourseRequestGraduationProgressModel_ClassTransform_Type() {
	return CourseRequestGraduationProgressModel;
}

export class CourseRequestUserModel {
	uid: number;
	name: string;
}

export function CourseRequestUserModel_ClassTransform_Type() {
	return CourseRequestUserModel;
}

export class CourseRequestSectionModel {
	section_id: number;
	section_title: string;

	// rest in payload but not being used currently, backend needs to update dates if want to use most likely
	target_end_timestamp: any;
	preferred_teacher_id: number;
	grant_access_now: boolean;
	pacing_start_timestamp: any;
	pacing_weeks: number;
	end_date_completion_timestamp: any;
}

export function CourseRequestSectionModel_ClassTransform_Type() {
	return CourseRequestSectionModel;
}

export class CourseRequestModel {
	id: number;
	uid: number;
	@Type(CourseRequestSectionModel_ClassTransform_Type)
	sections: CourseRequestSectionModel[];
	status: number;
	reason: string;
	notes: string;
	@Transform(dateTransformer)
	requested_on: Date;
	@Type(CourseRequestUserModel_ClassTransform_Type)
	requested_by: CourseRequestUserModel;
	@Transform(dateTransformer)
	completed_on: Date;
	@Type(CourseRequestUserModel_ClassTransform_Type)
	completed_by: CourseRequestUserModel;
	@Type(CourseRequestEnrollmentModel_ClassTransform_Type)
	enrollment: CourseRequestEnrollmentModel;
	@Type(CourseRequestGraduationProgressModel_ClassTransform_Type)
	graduation_progress: CourseRequestGraduationProgressModel;
	counted_courses: number;
	credits_earned: number;
	uncounted_courses: number;
	cohort_year: string;
}

export function CourseRequestModel_ClassTransform_Type() {
	return CourseRequestModel;
}

export interface CourseRequestCreateArgument {
	sections: StudentCourseAddItemArgument[];
	reason: string;
	notes: string;
}

export interface CourseRequestApproveArgument {
	approved_sections: number[];
	status?: number; // only used when nothing is approved 4 is passed
	rejection_reason?: string;
	override_maximum_credit_limit?: boolean;
}
