import { Transform, Type } from 'class-transformer';
import {
	integerTransformer,
	stringTransformer,
} from 'src/lib/utilities/api/class-transformer-types';

export class EnrollmentInfoIepOptionModel {
	@Transform(stringTransformer)
	key: string;
	title: string;
}

export function EnrollmentInfoIepOptionModel_ClassTransformType() {
	return EnrollmentInfoIepOptionModel;
}

export class StudentEnrollmentIepInfoOptionsModel {
	title: string;
	type: string;
	required: string;
	@Type(EnrollmentInfoIepOptionModel_ClassTransformType)
	options: EnrollmentInfoIepOptionModel[];
}

export function StudentEnrollmentIepInfoOptionsModel_ClassTransformType() {
	return StudentEnrollmentIepInfoOptionsModel;
}

export class EnrollmentInfoDropoutOptionModel {
	@Transform(integerTransformer)
	key: number;
	title: string;
}

export function EnrollmentInfoDropoutOptionModel_ClassTransformType() {
	return EnrollmentInfoDropoutOptionModel;
}

export class StudentEnrollmentDropoutInfoOptionsModel {
	title: string;
	type: string;
	required: string;
	@Type(EnrollmentInfoDropoutOptionModel_ClassTransformType)
	options: EnrollmentInfoDropoutOptionModel[];
}

export function StudentEnrollmentDropoutInfoOptionsModel_ClassTransformType() {
	return StudentEnrollmentDropoutInfoOptionsModel;
}

export class StudentEnrollmentInfoOptionsResponseModel {
	@Type(StudentEnrollmentIepInfoOptionsModel_ClassTransformType)
	iep_504: StudentEnrollmentIepInfoOptionsModel;
	@Type(StudentEnrollmentDropoutInfoOptionsModel_ClassTransformType)
	dropout_reasons: StudentEnrollmentDropoutInfoOptionsModel;
	@Type(StudentEnrollmentDropoutInfoOptionsModel_ClassTransformType)
	esl_check: StudentEnrollmentDropoutInfoOptionsModel;
}
