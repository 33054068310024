import { Directive, Input, TemplateRef } from '@angular/core';
import { IPaginationTable } from '../pagination-table-config';

export declare class PaginationTableColumnExportDisplayDirectiveContext<
	T = unknown,
> {
	$implicit: T;
	rowId: number;
}

@Directive({
	selector: 'ng-template[aePgtExportDisplay]',
	standalone: true,
})
export class PaginationTableColumnExportDisplayDirective<T> {
	@Input() public aePgtExportDisplay: IPaginationTable<T, any>;

	constructor(
		public template: TemplateRef<
			PaginationTableColumnExportDisplayDirectiveContext<T>
		>,
	) {}

	static ngTemplateGuard_aePgtExportDisplay: 'binding';
	static ngTemplateContextGuard<T>(
		_dir: PaginationTableColumnExportDisplayDirective<T>,
		_ctx: any,
	): _ctx is PaginationTableColumnExportDisplayDirectiveContext<T> {
		return true;
	}
}
