import { Injectable } from '@angular/core';
import spacetime from 'spacetime';
import {
	MeetupAttendeeStatus,
	MeetupManagementListItemModel,
} from 'src/lib/services/api/organizations/meetups/meetup-management.model';

@Injectable({
	providedIn: 'root',
})
export class MeetupUtilitiesService {
	public canRsvp = (meetup: MeetupManagementListItemModel): boolean => {
		return (
			(!meetup.rsvp || meetup.attendee_status <= 0) &&
			meetup.active &&
			!meetup.finalized &&
			spacetime.now().isBefore(meetup.end_date) &&
			spacetime.now().add(1, 'week').isAfter(meetup.end_date)
		);
	};

	public canReschedule = (meetup: MeetupManagementListItemModel): boolean => {
		return (
			meetup.rsvp &&
			spacetime.now().isBefore(meetup.start_date) &&
			meetup.attendee_status === MeetupAttendeeStatus.planning_to_attend
		);
	};

	public isInPast = (meetup: MeetupManagementListItemModel) => {
		return spacetime.now().isAfter(meetup.end_date);
	};

	public isActive = (meetup: MeetupManagementListItemModel) => {
		return spacetime.now().isBetween(meetup.start_date, meetup.end_date);
	};

	public rsvpText = (meetup: MeetupManagementListItemModel) => {
		if (this.isActive(meetup)) {
			return meetup.type === 'virtual' ? 'Join Now!' : 'Check In Now!';
		} else {
			return 'Count Me In!';
		}
	};

	public rsvpBSLevel = (meetup: MeetupManagementListItemModel) => {
		if (this.isActive(meetup)) {
			return 'warning';
		} else {
			return 'primary';
		}
	};

	public getMinutesAttended = (meetup: MeetupManagementListItemModel) => {
		return spacetime(meetup.actual_arrival_date).diff(
			meetup.actual_departure_date,
			'minute',
		);
	};
}
