import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';

// Based on https://stackoverflow.com/a/25574313/3780285
export function scrollTo$(
	scrollElement: HTMLElement,
	target: number,
	duration: number,
): Observable<any> {
	target = Math.round(target);
	duration = Math.round(duration);

	if (duration <= 0) {
		return new Observable<any>((o) => {
			scrollElement.scrollTop = target;
			o.next(scrollElement.scrollTop);
			o.complete();
		});
	}

	const startTime = Date.now();
	const endTime = startTime + duration;

	const startTop = scrollElement.scrollTop;
	const distance = target - startTop;

	const timeoutDuration = 15; // 60 FPS

	// based on http://en.wikipedia.org/wiki/Smoothstep
	const smooth_step = (start: number, end: number, point: number): number => {
		if (point <= start) {
			return 0;
		}
		if (point >= end) {
			return 1;
		}
		const x = (point - start) / (end - start); // interpolation
		return x * x * (3 - 2 * x);
	};

	return new Observable<any>((o) => {
		// This is to keep track of where the element's scrollTop is
		// supposed to be, based on what we're doing
		let previousScrollHeight = scrollElement.scrollHeight;
		let previousTop = scrollElement.scrollTop;
		let scrollStuckCount = 0;

		let cancelled = false;

		// Listen for the scroll wheel and just immediately cancel out
		const completeObservervable = (success: boolean) => {
			scrollElement.removeEventListener('wheel', wheelListener);
			if (success) {
				o.next();
			} else {
				o.error();
			}

			o.complete();
		};

		const wheelListener = () => {
			cancelled = true;
			completeObservervable(false);
		};
		scrollElement.addEventListener('wheel', wheelListener);

		// This is like a think function from a game loop
		const scrollNextFrame = () => {
			if (cancelled) {
				return;
			}

			if (scrollElement.scrollTop !== previousTop) {
				// If the viewport height changes, we can't tell if
				// the scroll is off course
				if (scrollElement.scrollHeight === previousScrollHeight) {
					completeObservervable(false);
					return;
				} else {
					previousScrollHeight = scrollElement.scrollHeight;
				}
			}

			// set the scrollTop for this frame
			const now = Date.now();
			const point = smooth_step(startTime, endTime, now);
			const frameTop = Math.round(startTop + distance * point);
			scrollElement.scrollTop = frameTop;

			// check if we're done!
			if (now >= endTime) {
				completeObservervable(true);
				return;
			}

			// If we were supposed to scroll but didn't, then we
			// probably hit the limit, we'll count a few then so consider it done
			if (
				scrollElement.scrollTop === previousTop &&
				scrollElement.scrollTop !== frameTop
			) {
				scrollStuckCount++;
				if (scrollStuckCount > 3) {
					completeObservervable(true);
					return;
				}
			} else {
				scrollStuckCount = 0;
			}

			previousTop = scrollElement.scrollTop;

			// schedule next frame for execution
			setTimeout(scrollNextFrame, timeoutDuration * (scrollStuckCount + 1));
		};

		// boostrap the animation process
		setTimeout(scrollNextFrame, timeoutDuration);

		return () => {
			scrollElement.removeEventListener('wheel', wheelListener);
			cancelled = true;
		};
	});
}

export function escapeHtml(unsafe: string): string {
	return unsafe
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#039;');
}

export function normalizeHref(href: string): string {
	if (href == null) return null;
	return `//${href}`.replace(/(\/\/https?:\/\/)/g, '//');
}

export function linkifyText(text: string, linkClass: string = null): string {
	if (text == null) return null;

	const escapedString = escapeHtml(text);
	const linkified = escapedString.replace(
		/([\w\d&$+,/:;=?#%\-_~.]{3,}\.[\w\d&$+,/:;=?#%\-_~]{2,})/g,
		`<a href="//$1" target="_blank" class="${linkClass}">$1</a>`,
	);

	return linkified.replace(/(\/\/https?:\/\/)/g, '//');
}

export function printHTML(
	safeHTML: SafeHtml,
	printHeader: string = null,
): boolean {
	const printFrame = document.createElement('iframe');
	printFrame.name = 'printFrame';
	printFrame.style.position = 'absolute';
	printFrame.style.top = '-1000000px';
	document.body.appendChild(printFrame);

	const frameDoc = printFrame.contentWindow;
	frameDoc.document.open();
	frameDoc.document.write(
		`<html><head><title>${printHeader ?? 'Print'}</title>`,
	);

	document.querySelectorAll('link').forEach((link) => {
		frameDoc.document.write(link.outerHTML);
	});

	frameDoc.document.write('</head><body>');
	frameDoc.document.write(safeHTML as string);
	frameDoc.document.write('</body></html>');
	frameDoc.document.close();
	setTimeout(function () {
		window.frames['printFrame'].focus();
		window.frames['printFrame'].print();
		document.body.removeChild(printFrame);
	}, 500);
	return false;
}

export function isDarkMode() {
	return (
		getComputedStyle(document.body).getPropertyValue('color-scheme') === 'dark'
	);
}
