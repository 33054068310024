import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import {
	AbstractPerformanceRequirementProof,
	PerformanceRequirementProofInterface,
	PerformanceRequirementProofType,
} from '../abstract-performance-requirement-proof.model';
import { ActivationPerformanceRequirementProof } from '../activation-performance-requirement-proof.model';
import { CoursePerformanceRequirementProof } from '../course-performance-requirement-proof.model';
import { EventLogPerformanceRequirementProof } from '../event-log-performance-requirement-proof.model';
import { MeetupPerformanceRequirementProof } from '../meetup-performance-requirement-proof.model';
import { MilestonePerformanceRequirementProof } from '../milestone-performance-requirement-proof.model';
import { UnknownPerformanceRequirementProof } from '../unknown-performance-requirement-proof.model';
import { WslpPerformanceRequirementProof } from '../wslp-performance-requirement-proof.model';

@Injectable({
	providedIn: 'root',
})
export class StudentPerformanceRequirementProofParserService {
	public parseProof = (
		model: PerformanceRequirementProofInterface,
	): AbstractPerformanceRequirementProof => {
		switch (model.type) {
			case PerformanceRequirementProofType.EventLogTwoWay: {
				return plainToInstance(EventLogPerformanceRequirementProof, model);
			}
			case PerformanceRequirementProofType.Milestone: {
				return plainToInstance(MilestonePerformanceRequirementProof, model);
			}
			case PerformanceRequirementProofType.MeetupAttendance:
			case PerformanceRequirementProofType.MeetupMinutes: {
				return plainToInstance(MeetupPerformanceRequirementProof, model);
			}
			case PerformanceRequirementProofType.CourseCounted:
			case PerformanceRequirementProofType.CourseCompleted: {
				return plainToInstance(CoursePerformanceRequirementProof, model);
			}
			case PerformanceRequirementProofType.WrittenStudentLearningPlan: {
				return plainToInstance(WslpPerformanceRequirementProof, model);
			}
			case PerformanceRequirementProofType.Activation: {
				return plainToInstance(ActivationPerformanceRequirementProof, model);
			}
			default: {
				console.error('Unknown performance proof 48c403ea', model);
				return plainToInstance(UnknownPerformanceRequirementProof, model);
			}
		}
	};
}
