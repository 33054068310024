import {
	AsyncSubject,
	BehaviorSubject,
	firstValueFrom,
	Observable,
	of,
	PartialObserver,
	Subject,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { isArray } from './compare';
import { SessionLogger } from './logging/session-logger';

export interface BehaviorCacheSet<T> {
	data: BehaviorSubject<T>;
	lastRefresh: Date | null;
}

export class BehaviorCache<
	K,
	T,
	C extends BehaviorCacheSet<T> = BehaviorCacheSet<T>,
> {
	private _map: Map<K | string, C>;
	private _keyMap: Map<K | string, K>;
	public observer: PartialObserver<[set: C, data: T]>;
	private _keyAdded$ = new Subject<K>();

	public get keyAdded$() {
		return this._keyAdded$.asObservable();
	}

	public get cacheMap() {
		return this._map;
	}

	constructor(
		private fetch$: (key: K) => Observable<T>,
		private cacheName: string,
		private emptyValue: (key?: K) => T = () => null,
		private secondsUntilStale: number = null,
		private waitForRefresh: boolean = false,
	) {
		this._map = new Map<K | string, C>();
		this._keyMap = new Map<K | string, K>();
	}

	public getStoredKeys = (): K[] => {
		return [...this._keyMap.values()];
	};

	public getStableKey = (key: K): K | string => {
		if (isArray(key)) {
			return JSON.stringify(key);
		}
		return key;
	};

	public getCacheSet = (key: K): C => {
		const stableKey = this.getStableKey(key);

		if (!this._map.has(stableKey)) {
			SessionLogger.log(`Cache ${this.cacheName} creating new set for ${key}`);
			this._map.set(stableKey, {
				data: new BehaviorSubject<T>(undefined),
				lastRefresh: null,
			} as C);
			this._keyMap.set(stableKey, key);

			this._keyAdded$.next(key);
		}

		return this._map.get(stableKey);
	};

	public getCache = (key: K): Observable<T> => {
		const cache = this.getCacheSet(key);
		this.fetchData(key);
		return cache.data.pipe(filter((x) => x !== undefined));
	};

	private _clearData = (stableKey: K | string) => {
		const set = this._map.get(stableKey);
		if (set) {
			set.lastRefresh = null;
			set.data.next(undefined);
		}
	};
	public clearData = (key: K) => {
		const set = this.getCacheSet(key);
		set.lastRefresh = null;
		set.data.next(undefined);
	};

	public clearAllData = () => {
		for (const k of this._map.keys()) {
			this._clearData(k);
		}
	};

	public tryRefreshData = (key: K): Promise<boolean> => {
		const stableKey = this.getStableKey(key);

		if (this._map.has(stableKey)) {
			return this.fetchData(key, true);
		} else {
			return subscribeAndPromise(of(false));
		}
	};

	public fetchData = (key: K, forceRefresh?: boolean): Promise<boolean> => {
		const set = this.getCacheSet(key);
		let refresh = set.lastRefresh == null || forceRefresh;
		if (!refresh && this.secondsUntilStale) {
			refresh =
				new Date().getTime() - this.secondsUntilStale * 1000 >
				set.lastRefresh?.getTime();
		}

		if (key == null) {
			set.data.next(this.emptyValue(key));
			return Promise.resolve(true);
		} else if (refresh) {
			set.lastRefresh = new Date();
			SessionLogger.log(`Cache ${this.cacheName} fetching for ${key}`);

			if (this.waitForRefresh) {
				set.data.next(undefined);
			}

			return subscribeAndPromise(this.fetch$(key), {
				next: (x) => {
					if (this.observer?.next) {
						this.observer.next([set, x]);
					}

					SessionLogger.log(`Cache ${this.cacheName} updated for ${key}: `, x);
					set.data.next(x ?? this.emptyValue(key));
				},
				error: (e) => {
					if (this.observer?.error) {
						this.observer.error([set, e]);
					}

					SessionLogger.log(`Cache ${this.cacheName} errored for ${key}: `, e);
					console.error(`Error while loading ${this.cacheName}. Key `, key, e);
					set.data.next(this.emptyValue(key));
				},
				complete: () => {
					if (this.observer?.complete) {
						this.observer.complete();
					}
				},
			});
		} else {
			return Promise.resolve(false);
		}
	};
}

export function subscribeAndPromise<T>(
	obs$: Observable<T>,
	observer?: PartialObserver<T>,
): Promise<boolean> {
	const subject = new AsyncSubject<boolean>();

	observer = observer ?? ({} as PartialObserver<T>);

	obs$.subscribe({
		next: (t) => {
			if (observer.next) observer.next(t);

			subject.next(true);
			subject.complete();
		},
		error: (e) => {
			if (observer.error) observer.error(e);

			subject.next(false);
			subject.complete();
		},
		complete: () => {
			if (observer.complete) observer.complete();

			subject.complete();
		},
	});

	return firstValueFrom(subject);
}
