import { Directive, ElementRef, Input } from '@angular/core';
import { PostMessageRenderType } from '../gabby-chat-component-definitions';

@Directive({
	selector: '[aeGabbyChatMessagePostRender]',
	standalone: true,
})
export class GabbyChatMessagePostRenderDirective {
	@Input('aeGabbyChatMessagePostRender')
	public renderId: string;

	@Input()
	public renderType: PostMessageRenderType;

	constructor(public elemRef: ElementRef<HTMLElement>) {}

	public isLoaded = (): boolean => {
		switch (this.renderType) {
			case PostMessageRenderType.AttachmentImage: {
				return this.isImageLoaded();
			}
			case PostMessageRenderType.AttachmentVideo: {
				return this.isVideoLoaded();
			}
			default: {
				return true;
			}
		}
	};

	private isImageLoaded = (): boolean => {
		const query = this.elemRef.nativeElement.querySelectorAll('img');
		for (let i = 0; i < query.length; i++) {
			if (!query.item(i).currentSrc) {
				// Image hasn't loaded yet
				return false;
			}
		}

		return true;
	};

	private isVideoLoaded = (): boolean => {
		const query = this.elemRef.nativeElement.querySelectorAll('video');
		for (let i = 0; i < query.length; i++) {
			if (!query.item(i).currentSrc) {
				// Video hasn't loaded yet
				return false;
			}
		}

		return true;
	};
}
