import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	TrackerUserPerformance,
	TrackerUserPerformance_ClassTransform_Type,
} from '../students/performance/student-performance-history-detailed.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class RegistrarTrackerModel {
	get profile_first_name() {
		return this.first_name;
	}
	get profile_preferred_name() {
		return this.first_name;
	}
	get profile_last_name() {
		return this.last_name;
	}

	first_name: string;
	last_name: string;
	uid: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	enrollment_status: number;
	@Type(TrackerUserPerformance_ClassTransform_Type)
	performance_data: TrackerUserPerformance;
	@Transform(dateTransformer)
	pause_request_date: Date;
	@Transform(dateTransformer)
	pause_start_date: Date;
	@Transform(dateTransformer)
	pause_end_date: Date;
	pause_status: 'pending' | 'approved';
	pause_exit_code: string;
	pause_exit_code_title: string;
	pause_request_explanation: string;
	@Transform(dateTransformer)
	withdrawal_date: Date;
	withdrawal_exit_code: number;
	withdrawal_exit_code_title: string;
	withdrawal_explanation: string;
	@Transform(dateTransformer)
	withdrawal_request_date: Date;
	withdrawal_request_exit_code: string;
	withdrawal_request_exit_code_title: string;
	withdrawal_request_explanation: string;
	@Transform(dateTransformer)
	unactivated_date: Date;
	unactivated_reason: string;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
}

export function RegistrarTrackerModel_ClassTransform_Type() {
	return RegistrarTrackerModel;
}

export class RegistrarTrackerResponseModel {
	success: boolean;
	count: number;
	@Type(RegistrarTrackerModel_ClassTransform_Type)
	results: RegistrarTrackerModel[];
}

export interface RegistrarTrackerPagingArgument
	extends PagingArgument<RegistrarTrackerSearchArgument> {
	shown_categories:
		| 'withdrawn'
		| 'withdrawn_requested'
		| 'paused'
		| 'pause_requested'
		| []; // used for toggles
}

export interface RegistrarTrackerSearchArgument {
	first_name: PagingSearchFieldArgument;
	last_name: PagingSearchFieldArgument;
	uid: PagingSearchFieldArgument;
	institute_title: PagingSearchFieldArgument;
	institute_type: PagingSearchFieldArgument;
	institute_state: PagingSearchFieldArgument;
	enrollment_status: PagingSearchFieldArgument;
	pause_request_date: PagingSearchFieldArgument;
	pause_start_date: PagingSearchFieldArgument;
	pause_end_date: PagingSearchFieldArgument;
	withdrawal_date: PagingSearchFieldArgument;
	unactivated_date: PagingSearchFieldArgument;
	work_queue: PagingSearchFieldArgument;
}
