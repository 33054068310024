@if (address != null) {
	<div class="btn-icon-highlight-zone d-inline-block">
		<div class="d-flex">
			<div class="flex-grow-1">
				{{ address.street }}
			</div>
			@if (includeAddressLink) {
				<div>
					<a class="btn btn-icon btn-link ms-1" target="_blank" [href]="link"
						><i class="fa-solid fa-map-location-dot"></i
					></a>
				</div>
			}
		</div>
		@if (address.street2) {
			<div class="text-nowrap">{{ address.street2 }}</div>
		}
		<div>
			{{ address.city }},
			{{ address.state }}
			@if (address.zip) {
				<span> {{ address.zip }}</span>
			}
		</div>
	</div>
} @else {
	<span class="text-muted"> No Address Provided </span>
}
