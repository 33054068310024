@if (!errorLoading) {
	<form *aeSpinWhile="loading" [formGroup]="eventFormGroup">
		@if (eventFormGroup.controls.category.enabled && editEvent == null) {
			<div class="btn-group d-flex" id="event.upsert.event.type">
				<input
					class="btn-check"
					formControlName="category"
					id="event.upsert.event.type.note"
					type="radio"
					value="Note"
				/>
				<label
					class="btn btn-outline-primary w-50"
					for="event.upsert.event.type.note"
				>
					Contact Event
				</label>
				<input
					class="btn-check"
					formControlName="category"
					id="event.upsert.event.type.task"
					type="radio"
					value="Task"
				/>
				<label
					class="btn btn-outline-primary w-50"
					for="event.upsert.event.type.task"
				>
					Task
				</label>
			</div>
		}
		<!-- base FG -->
		@if (editEvent == null) {
			<div class="fw-bold mb-2">Template</div>
			<div class="row mb-2">
				<div class="col-6">
					<button
						class="btn btn-default btn-sm btn-block"
						id="event.view.prefill.attempt"
						type="button"
						(click)="prefillAttempt()"
					>
						Attempt
					</button>
				</div>
				<div class="col-6">
					<button
						class="btn btn-default btn-sm btn-block"
						id="event.view.prefill.towway"
						type="button"
						(click)="prefillTwoWay()"
					>
						Two Way
					</button>
				</div>
			</div>
		}
		<div aeGroupValidation class="form-group">
			<label for="event.upsert.event.medium">Contact Method*</label>
			<select
				class="form-select"
				formControlName="medium"
				id="event.upsert.event.medium"
			>
				@for (item of allOptions.medium | keyvalue; track item) {
					<option [value]="item.key">
						{{ item.value }}
					</option>
				}
			</select>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Contact Method is required
				</ng-template>
			</ae-group-validation-display>
		</div>
		<div aeGroupValidation class="form-group">
			<label for="event.upsert.event.subject">Subject*</label>
			<input
				class="form-control"
				formControlName="subject"
				id="event.upsert.event.subject"
				type="text"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Subject is required
				</ng-template>
			</ae-group-validation-display>
		</div>
		<div aeGroupValidation class="form-group">
			<label for="event.upsert.event.body">Notes*</label>
			<ae-ngx-editor
				[editorID]="'event.upsert.event.body'"
				[formControl]="eventFormGroup.controls.body"
				[includeControls]="[
					'bold',
					'italic',
					'underline',
					'ordered_list',
					'bullet_list',
				]"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Notes are required
				</ng-template>
			</ae-group-validation-display>
		</div>
		<div aeGroupValidation class="form-group">
			<label for="event.upsert.event.relationship">Relationship*</label>
			<ng-select
				bindLabel="value"
				bindValue="key"
				formControlName="relationship"
				labelForId="event.upsert.event.relationship"
				[items]="relationships"
				[virtualScroll]="true"
			/>
			<ae-group-validation-display [maxDisplay]="1">
				<ng-template aeValidationError="required">
					Relationship is required
				</ng-template>
			</ae-group-validation-display>
		</div>
		<div aeGroupValidation class="form-group">
			<label for="event.upsert.event.purpose">Purpose*</label>
			<ng-select
				bindLabel="value"
				bindValue="key"
				formControlName="purpose"
				labelForId="event.upsert.event.purpose"
				[closeOnSelect]="false"
				[items]="purposeOptions"
				[multiple]="true"
			/>
		</div>
		<!-- Note Additional FG -->
		@if (eventFormGroup.controls.noteAdditional.enabled) {
			<div
				aeGroupValidation
				class="contact-type-container"
				formGroupName="noteAdditional"
			>
				<label class="fw-bold" for="event.upsert.event.twowaycontact"
					>Two Way Contact*</label
				>
				<label
					class="fw-normal pe-3"
					for="event.upsert.event.twowaycontact.yes"
				>
					<input
						formControlName="twoWayContact"
						id="event.upsert.event.twowaycontact.yes"
						name="twoWayContact"
						type="radio"
						value="1"
					/>
					Yes
				</label>
				<label class="fw-normal" for="event.upsert.event.twowayContact.no">
					<input
						formControlName="twoWayContact"
						id="event.upsert.event.twowayContact.no"
						name="twoWayContact"
						type="radio"
						value="0"
					/>
					No
				</label>
			</div>
		}
		@if (eventFormGroup.controls.noteAdditional.enabled) {
			<div
				aeGroupValidation
				class="contact-type-container"
				formGroupName="noteAdditional"
			>
				<label
					class="fw-bold d-flex align-items-end"
					for="event.upsert.event.initiatedBy"
				>
					Initiated By Student
					@if (editEvent == null) {
						<span>*</span>
					}
				</label>
				<label class="fw-normal pe-3" for="event.upsert.event.initiatedBy.yes">
					<input
						formControlName="initiatedByStudent"
						id="event.upsert.event.initiatedBy.yes"
						name="initiatedByStudent"
						type="radio"
						value="0"
					/>
					Yes</label
				>
				<label class="fw-normal" for="event.upsert.event.initiatedBy.no">
					<input
						formControlName="initiatedByStudent"
						id="event.upsert.event.initiatedBy.no"
						name="initiatedByStudent"
						type="radio"
						value="1"
					/>
					No</label
				>
			</div>
		}
		<!-- Task Additional FG -->
		@if (eventFormGroup.controls.taskAdditional.enabled) {
			<div formGroupName="taskAdditional">
				<div aeGroupValidation class="form-group">
					<label for="event.upsert.event.assignedTo">Assign To*</label>
					<ng-select
						bindLabel="value"
						bindValue="key"
						formControlName="assignedUid"
						labelForId="event.upsert.event.assignedTo"
						[items]="assignOptions"
						[virtualScroll]="true"
					/>
					<ae-group-validation-display [maxDisplay]="1">
						<ng-template aeValidationError="required">
							Assign To is required
						</ng-template>
					</ae-group-validation-display>
				</div>
				<div class="d-flex flex-wrap" formGroupName="dueDate">
					<div aeGroupValidation class="form-group flex-grow-1 me-3">
						<label for="event.upsert.event.dueDate.date"
							>Due Date*
							<ae-timezone-notice text="Calculated in" [timezone]="programTZ" />
						</label>
						<ae-single-date-picker
							formControlName="date"
							id="event.upsert.event.dueDate.date"
							[minDate]="minimumDueDate"
							[noValidate]="true"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								Due Date is required
							</ng-template>
						</ae-group-validation-display>
					</div>
					<div aeGroupValidation class="form-group">
						<label for="event.upsert.event.dueDate.time">Due Time*</label>
						<input
							class="form-control"
							formControlName="time"
							id="event.upsert.event.dueDate.time"
							type="time"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								Due Time is required
							</ng-template>
						</ae-group-validation-display>
					</div>
				</div>
				@if (
					eventFormGroup.controls.taskAdditional.controls.dueDate.hasError(
						'time'
					)
				) {
					<div class="text-danger mb-3">
						{{
							eventFormGroup.controls.taskAdditional.controls.dueDate.errors
								.time
						}}
					</div>
				}
			</div>
		}
		<div class="d-flex flex-row align-items-center mb-3">
			<div class="flex-grow-1"><hr /></div>
			<button
				class="btn btn-link btn-sm flex-grow-0"
				type="button"
				(click)="showAdditional = !showAdditional"
			>
				Additional Options
			</button>
			<div class="flex-grow-1"><hr /></div>
		</div>
		@if (showAdditional) {
			<!-- More base FG -->
			@if (
				eventFormGroup.controls.taskAdditional.controls.notifyCompleted.enabled
			) {
				<div class="form-group mb-0">
					<div class="d-flex align-items-center">
						<ae-input-checkbox
							[formControl]="
								eventFormGroup.controls.taskAdditional.controls.notifyCompleted
							"
							[id]="'event.upsert.event.notify'"
							>Notify when task is completed
						</ae-input-checkbox>
					</div>
				</div>
			}
			@if (eventFormGroup.controls.permission_level.enabled) {
				<div class="form-group">
					<div class="d-flex align-items-center">
						<ae-input-checkbox
							formControlName="permission_level"
							[id]="'event.upsert.event.permission.level'"
							>Strictly Internal
						</ae-input-checkbox>
					</div>
				</div>
			}
			<ng-container formGroupName="noteAdditional">
				@if (
					eventFormGroup.controls.noteAdditional.controls.completeDate.enabled
				) {
					<div aeGroupValidation class="form-group">
						<label for="event.upsert.event.completeDate">
							Contact/Completed Date
						</label>
						<ae-single-date-picker
							formControlName="completeDate"
							id="event.upsert.event.completeDate"
							[maxDate]="maximumCompleteDate"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								Contact/Completed Date is required
							</ng-template>
							<ng-template aeValidationError="maxDate">
								Contact/Completed Date can not be after today
							</ng-template>
						</ae-group-validation-display>
					</div>
				}
			</ng-container>
			<div class="form-group">
				<label for="event.upsert.event.tags">Reason / Tag</label>
				<ng-select
					bindLabel="value"
					bindValue="key"
					formControlName="tags"
					groupBy="parent"
					labelForId="event.upsert.event.tags"
					[items]="tagOptions"
					[markFirst]="false"
					[multiple]="true"
				/>
			</div>
			<div class="form-group">
				<label for="event.upsert.event.registrationId"
					>Related to a Specific Class?</label
				>
				<select
					class="form-select"
					formControlName="registrationId"
					id="event.upsert.event.registrationId"
				>
					<option [ngValue]="null">No</option>
					@for (item of allOptions.registration_id | keyvalue; track item) {
						<option [value]="item.key">
							{{ item.value }}
						</option>
					}
				</select>
			</div>
			<!-- New Files -->
			<div class="mb-3">
				@if (eventFormGroup.controls.file.enabled) {
					<div class="form-group mb-1">
						<label for="event.upsert.event.file">Add Attachments</label>
						<ae-file-uploader
							formControlName="file"
							id="event.upsert.event.file"
						/>
					</div>
					@for (file of filesToSave; track file; let i = $index) {
						<div class="d-flex">
							<div
								class="flex-grow-1"
								id="event.upsert.event.fileToSave{{ i }}"
							>
								<a
									><i aria-hidden="true" class="far fa-file"></i>
									{{ file.name }}</a
								>
							</div>
							<button
								class="btn btn-icon"
								type="button"
								id="event.upsert.event.fileToSave.remove{{ i }}"
								title="Remove {{ file.name }}"
								(click)="removeFileToSave(i)"
							>
								<i aria-hidden="true" class="far fa-times"></i>
							</button>
						</div>
					}
				}
				<!-- Existing Attachments -->
				@if (loadedFiles.length > 0) {
					<label class="fw-bold mt-2" for="event.upsert.event.current.file"
						>Current Attachments</label
					>
					@for (file of loadedFiles; track file; let i = $index) {
						<div class="d-flex" id="event.upsert.event.current.file">
							<div
								class="flex-grow-1"
								id="event.upsert.event.current.file{{ i }}"
							>
								<a target="_blank" href="{{ file.path }}"
									><i aria-hidden="true" class="far fa-file"></i>
									{{ file.description }}</a
								>
							</div>
							@if (permissions.canRemoveFile) {
								<button
									class="btn btn-icon"
									type="button"
									id="event.upsert.event.current.remove{{ i }}"
									title="Remove {{ file.description }}"
									(click)="removeSavedFile(file)"
								>
									<i aria-hidden="true" class="far fa-times"></i>
								</button>
							}
						</div>
					}
				}
			</div>
		}
	</form>
} @else {
	<div class="text-center text-danger my-3">
		There was an issue loading, if the problem persists contact support
	</div>
	<div class="d-flex justify-content-center mb-2">
		<button
			class="btn btn-default px-3"
			id="event.upsert.event.error.retry"
			type="button"
			(click)="loadFormInfo()"
		>
			Retry
		</button>
	</div>
}
