<div class="modal-header">
	<h1 class="modal-title">Confirm Exit</h1>
	<button
		class="btn btn-outline-white"
		id="confirm.exit.guard.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	{{ warningMessage }}
</div>
<div class="modal-footer">
	<button
		class="btn btn-secondary"
		id="confirm.exit.guard.modal.component.stay"
		type="button"
		(click)="activeModal.dismiss()"
	>
		Stay
	</button>

	<button
		class="btn btn-primary"
		id="confirm.exit.guard.modal.component.leave"
		type="button"
		(click)="activeModal.close()"
	>
		Leave
	</button>
</div>
