<input
	class="custom-check"
	type="checkbox"
	[formControl]="checkedControl"
	id="{{ (id ? id : backupId) | sanitizeId }}"
/>
<label
	class="custom-check-label form-check-label"
	for="{{ (id ? id : backupId) | sanitizeId }}"
	[ngClass]="{
		'text-muted': checkedControl.disabled,
		disabled: checkedControl.disabled,
	}"
>
	<span
		class="pointer check-icon check-icon-{{ type }}"
		[ngClass]="{ checked: checkedControl.value }"
	>
		@switch (checkedControl.value) {
			@case (true) {
				<i
					aria-hidden="true"
					[ngClass]="iconClass ? iconClass : typeDefaults[type].iconClass"
				></i>
			}
			@default {
				<i
					aria-hidden="true"
					[ngClass]="
						defaultClass ? defaultClass : typeDefaults[type].defaultClass
					"
				></i>
			}
		}
	</span>
	<span
		class="unselectable label-ng-content"
		[ngClass]="{ 'text-muted': checkedControl.disabled }"
	>
		<ng-content />
	</span>
</label>
