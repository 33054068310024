import { Type } from 'class-transformer';

export enum ExitCategoryTypes {
	deceased = '1',
	graduated = '2',
	transferred = '3',
	subscriptions = '4',
	incarcerated = '5',
	student_withdrawal = '6',
	drop = '7',
	attendance = '8',
	moved = '9',
}

export class ExitReasonModel {
	@Type(ExitReasonCategoryModel_ClassTransformType)
	exit_reasons: ExitReasonCategoryModel[];
}

export class ExitReasonCategoryModel {
	exit_category_id: ExitCategoryTypes;
	exit_category_name: string;
	exit_reason_id: string;
	exit_reason_name: string;
	show_calendar: boolean;
}

export function ExitReasonCategoryModel_ClassTransformType() {
	return ExitReasonCategoryModel;
}
