/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import {
	Exclude,
	Expose,
	instanceToPlain,
	plainToClassFromExist,
	plainToInstance,
	TransformationType,
	TransformFnParams,
} from 'class-transformer';
// eslint-disable-next-line no-restricted-imports
import { isArray } from '../../../compare';

export class FieldDynamicModel<T> {
	key: string;
	sort_order: number;

	@Exclude({ toPlainOnly: true })
	private _genericType: Function;

	@Exclude()
	private _field: T;

	constructor(genericType: Function) {
		this._genericType = genericType;
	}

	@Expose()
	get field(): T {
		return this._field;
	}
	set field(value: T) {
		this._field = plainToInstance(this._genericType(), value);
	}
}

export function FieldDynamicModel_ClassTransform_TransformFactory<T>(
	genericType: () => T,
) {
	return (params: TransformFnParams) => {
		if (params.type === TransformationType.PLAIN_TO_CLASS) {
			if (isArray(params.value)) {
				return params.value.map((v) => {
					return plainToClassFromExist(
						new FieldDynamicModel<T>(genericType),
						v,
					);
				});
			} else {
				return plainToClassFromExist(
					new FieldDynamicModel<T>(genericType),
					params.value,
				);
			}
		} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
			return instanceToPlain(params.value);
		} else if (params.type === TransformationType.CLASS_TO_CLASS) {
			if (isArray(params.value)) {
				return params.value.map((v) => {
					return plainToClassFromExist(
						new FieldDynamicModel<T>(genericType),
						instanceToPlain(v),
					);
				});
			} else {
				return plainToClassFromExist(
					new FieldDynamicModel<T>(genericType),
					instanceToPlain(params.value),
				);
			}
		} else {
			return params.value;
		}
	};
}
