import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { PagingSearchFieldArgument } from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class PermissionRoleHistoryModel {
	affected_user: string;
	changed_by: string;
	change: any;
	@Transform(dateTransformer)
	changed_on: Date;
	role: string;
}

export function PermissionRoleHistoryModel_ClassTransform_Type() {
	return PermissionRoleHistoryModel;
}

export class PermissionRoleHistoryResponseModel {
	@Type(PermissionRoleHistoryModel_ClassTransform_Type)
	results: PermissionRoleHistoryModel[];
	count: number;
}

export interface PermissionRoleHistorySearchArgument {
	affected_user: PagingSearchFieldArgument;
	changed_by: PagingSearchFieldArgument;
	change: PagingSearchFieldArgument;
	changed_on: PagingSearchFieldArgument;
	role: PagingSearchFieldArgument;
}
