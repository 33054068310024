import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentPerformanceExpectationModel } from 'src/lib/services/api/students/student-performance-expectation.model';
import { StudentsService } from 'src/lib/services/api/students/students.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentPerformanceExpectationsStoreService {
	private _listPerformanceExpectationsCache = new BehaviorCache<
		[uid: number],
		StudentPerformanceExpectationModel
	>(
		([_uid]) => {
			return this.studentsService.getPerformanceExpectations(_uid);
		},
		'StudentPerformanceExpectationsStoreService PerformanceExpectationsCache',
		() => null,
		5 * 60,
	);

	constructor(private studentsService: StudentsService) {}

	public expectations$ = (
		uid: number,
	): Observable<StudentPerformanceExpectationModel> => {
		return this._listPerformanceExpectationsCache.getCache([uid]);
	};

	public refreshExpectations = (uid: number): Promise<boolean> => {
		return this._listPerformanceExpectationsCache.fetchData([uid], true);
	};

	public clearExpectations = (uid: number): void => {
		this._listPerformanceExpectationsCache.clearData([uid]);
	};
}
