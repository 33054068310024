import { QueryList } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import { PaginationTableResultSet } from 'src/lib/types/pagination-table-result-set.def';
import { PaginationTableColumnDirective } from '../children/pagination-table-column.directive';
import { PaginationTableControlsCustomDirective } from '../children/pagination-table-controls-custom.directive';
import { PaginationTableFiltersCustomDirective } from '../children/pagination-table-filters-custom.directive';
import { PaginationTableRowActionsColumnDirective } from '../children/pagination-table-row-actions-column.directive';
import { PaginationTableActionDefinition } from '../pagination-table-action-definition';
import { PaginationTableConfig } from '../pagination-table-config';
import {
	PaginationTableSavedConfiguration,
	PaginationTableSavedSearch,
	PaginationTableStyleRule,
	PaginationTableUserPreferences,
} from '../types/ipagination-table-user-preferences';
import { IPaginationTable } from './pagination-table.interface';

export const protectedPaginationTableConfigurationDefaultName = 'Untitled';

export enum TableState {
	presenting = 10,
	loading = 11,
	noItems = 12,
	error = 13,
}

export interface PGTableStaticItem<T> {
	item: T;
	display: 'none' | null;
}

export interface InternalPaginationTable<T> extends IPaginationTable<T> {
	tableConfig: PaginationTableConfig<T>;
	columnTemplates: QueryList<PaginationTableColumnDirective<T>>;
	rowActionsTemplate: PaginationTableRowActionsColumnDirective<T>;
	customControlsTemplate: QueryList<PaginationTableControlsCustomDirective>;
	customFiltersTemplate: QueryList<PaginationTableFiltersCustomDirective>;

	redraw$: Subject<null | true>;
	redrawSlow$: Subject<null>;

	// Data
	nextSubscription$: Subscription;
	getAllKeysSubscription$: Subscription;
	currentItems: T[];
	totalCount: number;
	totalPages: number;
	viewingLow: number;
	viewingHigh: number;

	// Page State
	currentPage: number;
	selectedPageSize: number;
	sortKey: string;
	sortDescending: boolean;

	// Rendering
	currentState: TableState;

	// Selection
	selectedItems: any[];

	// Configuration
	activeRealm: string | null;
	userPreferences: PaginationTableUserPreferences;
	userPrefForm: FormGroup<PaginationTableUserPreferencesForm>;
	userPrefFormIgnoreChangesCount: number; // Angular doesn't let you block events when changing FormArrays https://github.com/angular/angular/issues/20439#issuecomment-456267166

	/*
	 *
	 * Pagination
	 *
	 */
	setSort: (sortKey: string, sortDescendingOverride?: boolean) => void;
	seek: (page: number) => Observable<PaginationTableResultSet<T>>;
	rescale: () => void;
	refresh: () => void;
	selectedPageSizeChanged: () => void;

	getPaginateButtons: () => void;

	/*
	 *
	 * Column Rendering
	 *
	 */
	getColumns: (getAll?: boolean) => PaginationTableColumnDirective<T>[];
	columns_GetWidth: (column: PaginationTableColumnDirective<T>) => string;
	columns_GetColumnRowClasses: (
		staticItem: PGTableStaticItem<T>,
		col: PaginationTableColumnDirective<T>,
		element: HTMLElement,
	) => string[];

	/*
	 *
	 * Table Control - Row Actions
	 *
	 */
	tc_RowActions_Enabled: () => boolean;

	/*
	 *
	 * Table Control - Selection
	 *
	 */
	tc_Selection_Enabled: () => boolean;
	tc_Selection_Reset: () => void;
	tc_Selection_IsItemSelected: (item: T) => boolean;
	tc_Selection_SelectItem: (
		item: T,
		selected: boolean,
		$event: Event,
		sendNotification?: boolean,
	) => void;
	tc_Selection_IsPageSelected: () => boolean;
	tc_Selection_TogglePage: () => void;
	tc_Selection_SelectAll: () => void;

	/*
	 *
	 * Table Control - Actions
	 *
	 */
	tc_Actions_GetVisibleActions: () => PaginationTableActionDefinition[];
	tc_Actions_ExecuteAction: (action: PaginationTableActionDefinition) => void;

	tc_Actions_ActionDisabled: (
		action: PaginationTableActionDefinition,
	) => boolean;

	tc_Actions_ActionTooltip: (action: PaginationTableActionDefinition) => string;

	/*
	 *
	 * Table Control - Search
	 *
	 */
	tc_Search_Enabled: () => boolean;
	tc_Search_Reset: () => void;

	/*
	 *
	 * Table Control - Refresh
	 *
	 */
	tc_Refresh_Request: () => void;

	/*
	 *
	 * Table Control - Configuration
	 *
	 */
	tc_Configuration_ToggleColumnVisibility: (columnId: string) => void;
	tc_Configuration_ResetColumnVisibility: () => void;
	tc_Configuration_SelectAllColumns: () => void;
	tc_Configuration_RemoveColumnResize: (
		column: PaginationTableColumnDirective<T>,
	) => void;

	/*
	 *
	 * Configuration Loading
	 *
	 */
	load_SavedConfiguration: (config: PaginationTableSavedConfiguration) => void;
	load_AddStyleRuleFormGroup: (
		target?: FormGroup<PaginationTableSavedConfigurationForm>,
	) => FormGroup<FormControlWrapper<PaginationTableStyleRule>>;

	/*
	 *
	 * Form Builders
	 *
	 */
	formBuild_SavedConfiguration_Control: () => FormControl<PaginationTableSavedConfiguration>;
	formBuild_SavedConfiguration_Group: () => FormGroup<PaginationTableSavedConfigurationForm>;
}

export interface PaginationTableSavedConfigurationForm
	extends Omit<
		FormControlWrapper<PaginationTableSavedConfiguration>,
		'styleRules'
	> {
	styleRules: FormArray<
		FormGroup<FormControlWrapper<PaginationTableStyleRule>>
	>;
}

export interface PaginationTableUserPreferencesForm
	extends Omit<
		FormControlWrapper<PaginationTableUserPreferences>,
		'activeConfiguration' | 'savedConfigurations' | 'savedSearches'
	> {
	activeConfiguration: FormGroup<PaginationTableSavedConfigurationForm>;
	savedConfigurations: FormArray<
		FormControl<PaginationTableSavedConfiguration>
	>;
	savedSearches: FormArray<
		FormGroup<FormControlWrapper<PaginationTableSavedSearch>>
	>;
}
