import { FormControl } from '@angular/forms';
import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class CourseBulkEditDateRegistrationModel {
	@Transform(dateTransformer)
	course_end_date: Date;
	course_name: string;
	@Transform(dateTransformer)
	course_start_date: Date;
	@Expose({ name: 'official_start_date' })
	@Transform(dateTransformer)
	official_start_date_actual: Date;
	pacing_type: CourseBulkEditType;
	registration_id: number;
}

export function CourseBulkEditDateRegistrationModel_ClassTransform_Type() {
	return CourseBulkEditDateRegistrationModel;
}

export class CourseBulkEditDateModel {
	first_name: string;
	preferred_name: string;
	last_name: string;
	uid: number;
	@Type(CourseBulkEditDateRegistrationModel_ClassTransform_Type)
	registrations: CourseBulkEditDateRegistrationModel[];
}

export class BulkEditCourseDateTableModel extends CourseBulkEditDateRegistrationModel {
	name: string;
	uid: number;
	endDateCtrl: FormControl<Date>;
	selectedCtrl: FormControl<boolean>;
}

export enum CourseBulkEditType {
	weekly = 'weekly',
	target_end_date = 'target_end_date',
}
