<section class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Settings</h2>
	</div>
	<div class="billboard-body">
		<div class="form-group">
			@if (error) {
				<div class="text-danger text-center">
					{{ error }}
				</div>
			}

			<fieldset *aeSpinWhile="loading">
				<legend class="fw-bold fs-normal">Theme</legend>
				<div class="theme-list list-unstyled">
					@for (theme of themes; track theme) {
						<div>
							<input
								#themeInput
								class="btn-check"
								name="theme"
								type="radio"
								[formControl]="themeCtrl"
								id="profile.settings.component.theme.{{ theme.key }}"
								[value]="theme.key"
							/>
							<label
								class="btn btn-block fw-normal d-flex align-self-stretch align-items-center m-0"
								for="profile.settings.component.theme.{{ theme.key }}"
								[ngClass]="
									theme.key === 'student_light' ? 'text-black' : 'text-white'
								"
								[style.background-color]="theme.color"
							>
								<div class="flex-grow-0 me-2">
									<i
										aria-hidden="true"
										class="far fa-fw"
										[ngClass]="
											themeInput.checked ? 'fa-check-square' : 'fa-square'
										"
									></i>
								</div>
								<div class="flex-grow-1 text-start text-capitalize">
									{{ theme.title }}
									<span class="screen-reader-text">Theme</span>
								</div>
							</label>
						</div>
					}
				</div>
			</fieldset>
		</div>
		<div class="row">
			@if (timezoneCtrl) {
				<div class="form-group col-lg-6">
					<label for="profile.settings.component.timezone.select"
						>Timezone</label
					>
					<ae-timezone-select
						labelForId="profile.settings.component.timezone.select"
						[formControl]="timezoneCtrl"
						[keyName]="'key'"
						[labelName]="'title'"
						[timezones]="timezones"
					/>
				</div>
			}
		</div>

		<div class="row">
			<div class="col-sm-6 form-group">
				<label
					class="label-spacer d-block"
					for="profile.settings.component.chat.notification-volume"
				>
					Chat Notification Volume
				</label>
				<ae-gabby-notification-volume
					id="profile.settings.component.chat.notification-volume"
				/>
			</div>

			@if (notificationsEnabled() === false) {
				<div class="col-12 form-group">
					<button
						class="btn btn-primary"
						id="profile.settings.component.chat.browser-notifications"
						type="button"
						(click)="enableNotifications()"
					>
						Enable Browser Notifications
					</button>
				</div>
			}
		</div>
	</div>
</section>
