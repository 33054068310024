import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { StudentAnnouncementModel } from './student-announcement-model';
import { StudentAnnouncementUpsertArgument } from './student-announcement-upsert-argument';
import { StudentAnnouncementUpsertResponseModel } from './student-announcement-upsert-response-model';

@Injectable({
	providedIn: 'root',
})
export class StudentAnnouncementsService {
	constructor(private httpClient: HttpClient) {}

	public getAnnouncements = (
		uid: number,
	): Observable<StudentAnnouncementModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${uid}/announcement/list`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentAnnouncementModel, r as any[]),
					{
						errorCode: 'F3B2039E',
					},
				),
			);
	};

	public createAnnouncement = (
		uid: number,
		args: StudentAnnouncementUpsertArgument,
	): Observable<StudentAnnouncementUpsertResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/announcement/create`, args)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentAnnouncementUpsertResponseModel, r),
					{
						errorCode: '295182DC',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public updateAnnouncement = (
		uid: number,
		announcementId: number,
		args: StudentAnnouncementUpsertArgument,
	): Observable<StudentAnnouncementUpsertResponseModel> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/announcement/${announcementId}/edit`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(StudentAnnouncementUpsertResponseModel, r),
					{
						errorCode: 'A53CE694',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public readAnnouncement = (
		uid: number,
		announcementId: number,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/students/${uid}/announcement/read/${announcementId}`,
				{},
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F6CBBC02',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public completeAnnouncement = (studentUid: number, uid: number): any => {
		return this.httpClient
			.get<any>(`/ga/message/announcement/complete/${uid}?uid=${studentUid}`)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '5c2131f8',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
