import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { StudentCourseFeedbackModel } from 'src/lib/services/api/students/classes/student-course-feedback.model';
import { StudentCourseService } from 'src/lib/services/api/students/classes/student-course.service';
import { BehaviorCache, subscribeAndPromise } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentCourseFeedbackStoreService {
	private _feedbackCache = new BehaviorCache<
		number,
		StudentCourseFeedbackModel[]
	>(
		(uid) => this.courseService.getFeedback(uid),
		'StudentCourseFeedbackStore FeedbackCache',
		() => [],
		60 * 5,
		true,
	);

	constructor(private courseService: StudentCourseService) {}

	/**
	 * Feedback
	 */
	public feedback$ = (
		uid: number,
	): Observable<StudentCourseFeedbackModel[]> => {
		return this._feedbackCache.getCache(uid);
	};

	public refreshFeedback = (uid: number) => {
		return this._feedbackCache.fetchData(uid, true);
	};

	public clearFeedback = (uid: number) => {
		return this._feedbackCache.clearData(uid);
	};

	/**
	 * Course Feedback
	 */
	public courseFeedback$ = (
		uid: number,
		registrationId: number,
	): Observable<StudentCourseFeedbackModel> => {
		return this._feedbackCache
			.getCache(uid)
			.pipe(map((x) => x.find((xx) => xx.registration_id === registrationId)));
	};

	public refreshCourseFeedback = (uid: number, registrationId: number) => {
		return this.fetchCourse(uid, registrationId);
	};

	/**
	 * Mark Feedback
	 */
	public markFeedbackRead$ = (
		uid: number,
		registrationId: number,
		submissionId: number,
	) => {
		return this.courseService
			.markFeedbackRead(uid, registrationId, [submissionId])
			.pipe(tap(() => this.fetchCourse(uid, registrationId)));
	};

	/**
	 *
	 * Internals
	 *
	 */
	private fetchCourse = (
		uid: number,
		registrationId: number,
	): Promise<boolean> => {
		this._feedbackCache.fetchData(uid);
		const set = this._feedbackCache.getCacheSet(uid);

		return subscribeAndPromise(
			combineLatest([
				this.courseService.getCourseFeedback(uid, registrationId),
				set.data.pipe(filter((x) => x !== undefined)),
			]).pipe(take(1)),
			{
				next: ([classFeedback, cache]) => {
					if (classFeedback != null) {
						const index = cache.findIndex(
							(c) => c.registration_id === registrationId,
						);
						if (index > -1) {
							cache.splice(index, 1, classFeedback);
						} else {
							cache.push(classFeedback);
						}

						set.data.next(cache);
					}
				},
			},
		);
	};
}
