import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	StudentRemindersFilter,
	StudentRemindersResponseModel,
} from './student-reminders-response.model';

@Injectable({
	providedIn: 'root',
})
export class StudentRemindersService {
	constructor(private httpClient: HttpClient) {}

	public getStudentReminders = (
		uid: number,
		args: PagingArgument<StudentRemindersFilter>,
	): Observable<StudentRemindersResponseModel> => {
		return this.httpClient
			.post<any>(`/api/v1/students/${uid}/reminders`, args)
			.pipe(
				mapResponse((r) => plainToInstance(StudentRemindersResponseModel, r), {
					errorCode: '62115896',
				}),
			);
	};
}
