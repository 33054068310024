import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AsyncSubject, Observable, of } from 'rxjs';
import { delay, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import {
	UserPageTrackingEvent,
	UserPageTrackingEventType,
} from '../../api/generic/page-tracking/user-page-tracking.argument';
import { UserPageTrackingService } from '../../api/generic/page-tracking/user-page-tracking.service';
import { InitializationService } from '../../utility/initialization/initialization.service';

@Injectable({
	providedIn: 'root',
})
export class PageTrackingMonitorService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();
	private _enabled = false;

	private eventList: UserPageTrackingEvent[] = [];
	private bundledEventObservable$: Observable<null>;

	constructor(
		private router: Router,
		private userPageTrackingService: UserPageTrackingService,
		private initService: InitializationService,
	) {}

	public init = () => {
		this._enabled = this.initService.loggedIn;
		if (!this._enabled) return; // Not logged in, bail

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.checkRouteData();
			});
	};

	public trackEvent = (
		name: string,
		type: UserPageTrackingEventType,
		student_id: number,
	) => {
		this.eventList.push({
			name: name,
			event_type: type,
			page_path: globalThis.location.href,
			student_uid: student_id,
			timestamp: Math.round(new Date().getTime() / 1000),
		});
		this.triggerSave();
	};

	private checkRouteData = (): void => {
		this.eventList.push({
			event_type: UserPageTrackingEventType.state_change,
			name: this.router.url,
			page_path: globalThis.location.href,
			timestamp: Math.round(new Date().getTime() / 1000),
		});
		this.triggerSave();
	};

	private triggerSave = () => {
		if (!this._enabled) return;

		if (!this.bundledEventObservable$) {
			this.bundledEventObservable$ = of(null);

			this.bundledEventObservable$
				.pipe(
					delay(1000),
					switchMap(() => {
						const events = this.eventList.slice();
						this.eventList = [];

						return this.userPageTrackingService.sendTrackingData(events);
					}),
					take(1),
				)
				.subscribe({
					next: (success) => {
						if (success) {
							// Do nothing
						} else {
							console.error(
								'PageTrackingMonitorService could not save page tracking, DCAC6FED',
							);
						}
					},
					error: (err) => {
						console.error(err);
					},
					complete: () => {
						this.bundledEventObservable$ = null;
						if (this.eventList.length > 0) {
							this.triggerSave();
						}
					},
				});
		}
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
