@if (!loading) {
	@if (!errorLoading) {
		<div class="event-view-container">
			<ul #nav="ngbNav" active="active" class="nav-tabs nav-inverse" ngbNav>
				<li ngbNavItem>
					<a id="event.view.event.tab.details" ngbNavLink>Event Details</a>
					<ng-template ngbNavContent>
						<div class="row mt-3">
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.type">Event Type</div>
								<div aria-labelledby="event.view.event.type">
									{{ currentEvent.category }}
								</div>
							</div>
							<div class="col-4">
								<div class="fw-bold" id="event.view.contact.method">
									Contact Method
								</div>
								<div aria-labelledby="event.view.contact.method">
									{{ currentEvent.medium }}
								</div>
							</div>
							<div class="col-4">
								<div class="fw-bold" id="event.view.contact.updated.date">
									Updated
								</div>
								<ae-user-time
									sourceTz="program"
									[date]="currentEvent.updated"
									[userId]="studentId"
								/>
							</div>
						</div>
						<hr />
						<div class="my-3">
							<div class="fw-bold" id="event.view.event.subject">Subject</div>
							<div aria-labelledby="event.view.event.subject">
								{{ currentEvent.subject }}
							</div>
						</div>
						<div class="my-3">
							<div class="fw-bold" id="event.view.event.body">Notes</div>
							<div class="event-view-notes">
								<div
									class="text-preline"
									[innerHTML]="currentEvent.body | dompurify"
								></div>
							</div>
						</div>
						<hr />
						<div class="row my-3">
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.creator">
									Created By
								</div>
								<div aria-labelledby="event.view.event.creator">
									<ae-staff-viewer [userId]="currentEvent.creator_uid">
										{{ currentEvent.creator_name }}
									</ae-staff-viewer>
								</div>
							</div>
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.created.date">
									Created
								</div>
								<ae-user-time
									sourceTz="program"
									[date]="currentEvent.created"
									[userId]="studentId"
								/>
							</div>
							@if (currentEvent.category === EventCategory.task) {
								<div class="col-4">
									<div class="fw-bold" id="event.view.event.due.date">
										<span>Due Date</span>
									</div>
									<ae-user-time
										format="{numeric-us} {time}"
										sourceTz="program"
										[date]="currentEvent.due_date"
										[userId]="studentId"
									/>
								</div>
							}
							@if (currentEvent.category === EventCategory.note) {
								<div class="col-4">
									<div class="fw-bold" id="event.view.event.completed.date">
										Contact/Completed
									</div>
									<ae-user-time
										sourceTz="program"
										[date]="currentEvent.complete_date"
										[userId]="studentId"
									/>
								</div>
							}
						</div>
						<div class="row my-3">
							@if (currentEvent.category === EventCategory.task) {
								<div class="col-4">
									<div class="fw-bold" id="event.view.event.assignee">
										Assigned To
									</div>
									<div aria-labelledby="event.view.event.assignee">
										{{ currentEvent.assigned_name }}
									</div>
								</div>
							}
							@if (currentEvent.category === EventCategory.note) {
								<div class="col-4">
									<div class="fw-bold" id="event.view.event.twoway.contact">
										Two Way Contact
									</div>
									<div aria-labelledby="event.view.event.twoway.contact">
										{{ currentEvent.twoway_contact ? 'Yes' : 'No' }}
									</div>
								</div>
								@if (initiatedBy != null) {
									<div class="col-4">
										<div class="fw-bold" id="event.view.event.intiated.by">
											Initiated By
										</div>
										<div aria-labelledby="event.view.event.intiated.by">
											<ae-staff-viewer [userId]="initiatedBy.uid">
												{{ initiatedBy.name }}
											</ae-staff-viewer>
										</div>
									</div>
								}
							}
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.registration.name">
									Related to Class
								</div>
								<div aria-labelledby="event.view.event.registration.name">
									<span>
										{{
											currentEvent.registration_name != null
												? currentEvent.registration_name
												: 'No'
										}}</span
									>
								</div>
							</div>
						</div>
						<div class="row my-3">
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.purpose">Purpose</div>
								@for (p of currentEventPurposes; track p) {
									<div aria-labelledby="event.view.event.assignee">
										{{ p }}
									</div>
								}
							</div>
							@if (canViewInternal) {
								<div class="col-4">
									<div class="fw-bold" id="event.view.event.permission">
										Strictly Internal
									</div>
									<div aria-labelledby="event.view.event.permission">
										{{ currentEvent.permission_level === '1' ? 'Yes' : 'No' }}
									</div>
								</div>
							}
							<div [ngClass]="canViewInternal ? 'col-4' : 'col-8'">
								<div class="fw-bold" id="event.view.event.tags">Tags</div>
								<div aria-labelledby="event.view.event.tags">
									@if (currentEvent.tags.length > 0) {
										@for (tag of currentEvent.tags; track tag; let l = $last) {
											<span>
												{{ tag }}
												@if (!l) {
													<span>,</span>
												}
											</span>
										}
									} @else {
										No Tags
									}
								</div>
							</div>
						</div>
						<div class="row my-3">
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.attachments">
									Attachments
								</div>
								@if (currentEvent.files.length > 0) {
									@for (
										file of currentEvent.files;
										track file;
										let i = $index
									) {
										<div aria-labelledby="event.view.event.attachments">
											<a target="_blank" href="{{ file.path }}"
												><i aria-hidden="true" class="far fa-file"></i>
												{{ file.description }}</a
											>
										</div>
									}
								} @else {
									<div>No Attachments</div>
								}
							</div>
							<div class="col-4">
								<div class="fw-bold" id="event.view.event.relationship">
									Relationship
								</div>
								<div>
									{{ relationship | ifEmpty }}
								</div>
							</div>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem>
					<a id="event.view.event.tab.history" ngbNavLink>History</a>
					<ng-template ngbNavContent>
						<div class="my-3">
							<div class="event-view-history">
								<table
									class="table table-striped"
									id="event.view.event.history"
								>
									<caption class="screen-reader-text">
										Event history
									</caption>
									<thead>
										<tr>
											<th class="event-history-date">
												<span>Date</span>
											</th>
											<th class="event-history-details">Details</th>
										</tr>
									</thead>
									@if (currentEvent.line_items?.length > 0) {
										<tbody>
											@for (item of currentEvent.line_items; track item) {
												<tr>
													<td class="event-history-date">
														<ae-user-time
															format="{numeric-us} {time}"
															sourceTz="program"
															[date]="item.date"
															[userId]="studentId"
														/>
													</td>
													<td class="event-history-details">{{ item.note }}</td>
												</tr>
											}
										</tbody>
									}
								</table>
								@if (currentEvent.line_items.length === 0) {
									<div class="text-center">No history</div>
								}
							</div>
						</div>
					</ng-template>
				</li>
			</ul>
			<div [ngbNavOutlet]="nav"></div>
		</div>
	} @else {
		<div class="text-center text-danger my-3">
			There was an issue loading, if the problem persists contact support
		</div>
		<div class="d-flex justify-content-center mb-2">
			<button
				class="btn btn-default px-3"
				id="event.view.event.error.retry"
				type="button"
				(click)="getStudentOverview()"
			>
				Retry
			</button>
		</div>
	}
} @else {
	<div class="my-3 text-center">
		<i aria-hidden="true" class="far fa-spinner fa-spin fa-3x"></i>
	</div>
}
