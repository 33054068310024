import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { FieldRequiredValue } from 'src/lib/utilities/api/patterns/dynamic-fields/field-required-value.enum';
import { StringEnumOption } from 'src/lib/utilities/api/patterns/dynamic-fields/string-enum-option.model';
import {
	UserAddressModel,
	UserAddressModel_ClassTransform_Type,
} from '../../utility/utility-models/address.model';

export class StudentEnrollmentDataDropoutReasonModel {
	options: StringEnumOption[];
	required: FieldRequiredValue;
	title: string;
	type: string;
}

export function StudentEnrollmentDataDropoutReasonModel_ClassTransform_Type() {
	return StudentEnrollmentDataDropoutReasonModel;
}

export class StudentEnrollmentDataEnrollmentModel {
	@Transform(dateTransformer)
	dropout_date: Date;
	referral_source: string;
	dropout_reasons: number[];
	dropout_reasons_other: any;
}

export function StudentEnrollmentDataEnrollmentModel_ClassTransform_Type() {
	return StudentEnrollmentDataEnrollmentModel;
}

export class StudentEnrollmentDataContactsModel {
	name: string;
	relationship: string;
	emails: string[];
	phones: number[];
	attributes: string[];
}

export function StudentEnrollmentDataContactsModel_ClassTransform_Type() {
	return StudentEnrollmentDataContactsModel;
}

export class StudentEnrollmentDataValuesModel {
	email: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	preferred_name: string;
	phone_number: number;
	@Transform(dateTransformer)
	birthdate: Date;
	@Type(UserAddressModel_ClassTransform_Type)
	address: UserAddressModel;
	gender: string;
	grade: string;
	@Expose({ name: 'ethnicity' }) // API still refers to race as ethnicity 14E31FFD
	race: string;
	district_id: string;
	state_id: string;
	additional_phone_numbers: number[];
	iep_504: string;
	@Type(StudentEnrollmentDataContactsModel_ClassTransform_Type)
	contacts: StudentEnrollmentDataContactsModel[];
	reference_id: string;
	institute_id: number;
	uid?: number;
	district_residence?: string;
}

export function StudentEnrollmentDataValuesModel_ClassTransform_Type() {
	return StudentEnrollmentDataValuesModel;
}

export class StudentEnrollmentDataOptionsModel {
	is_eligible: true;
	ineligible_reasons: StringEnumOption[];
	institutes: { id: number; title: string }[];
	suggested_institute_id: string;
	reason: StringEnumOption[];
	@Type(StudentEnrollmentDataDropoutReasonModel_ClassTransform_Type)
	dropout_reasons: StudentEnrollmentDataDropoutReasonModel;
}

export function StudentEnrollmentDataOptionsModel_ClassTransform_Type() {
	return StudentEnrollmentDataOptionsModel;
}

export class StudentEnrollmentDataResponseModel {
	success: boolean;
	@Type(StudentEnrollmentDataValuesModel_ClassTransform_Type)
	student_values: StudentEnrollmentDataValuesModel;
	@Type(StudentEnrollmentDataOptionsModel_ClassTransform_Type)
	student_options: StudentEnrollmentDataOptionsModel;
	@Type(StudentEnrollmentDataEnrollmentModel_ClassTransform_Type)
	enrollment_form: StudentEnrollmentDataEnrollmentModel;
}
