import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class TrackerContactTypeModel {
	@Transform(dateTransformer)
	all: Date;
	@Transform(dateTransformer)
	chat: Date;
	@Transform(dateTransformer)
	email: Date;
	@Transform(dateTransformer)
	phone: Date;
	@Transform(dateTransformer)
	'face-to-face': Date;
	@Transform(dateTransformer)
	facebook: Date;
	@Transform(dateTransformer)
	other: Date;
	@Transform(dateTransformer)
	'other digital communication': Date;
	@Transform(dateTransformer)
	'received email': Date;
	@Transform(dateTransformer)
	'skype or im': Date;
	@Transform(dateTransformer)
	'text': Date;
}

export function TrackerContactTypeModel_ClassTransform_Type() {
	return TrackerContactTypeModel;
}

export class TrackerContactOptionModel {
	key: string;
	title: string;
}

export const TrackerContactTypes: TrackerContactOptionModel[] = [
	{
		key: 'chat',
		title: 'Chat',
	},
	{
		key: 'email',
		title: 'Email',
	},
	{
		key: 'face-to-face',
		title: 'Face-to-Face',
	},
	{
		key: 'facebook',
		title: 'Facebook',
	},
	{
		key: 'other',
		title: 'Other',
	},
	{
		key: 'other digital communication',
		title: 'Other Digital Communication',
	},
	{
		key: 'phone',
		title: 'Phone',
	},
	{
		key: 'received email',
		title: 'Received Email',
	},
	{
		key: 'skype or im',
		title: 'Skype Or IM',
	},
	{
		key: 'text',
		title: 'Text',
	},
];
