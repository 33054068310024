import { Expose } from 'class-transformer';

export type SSOType = 'google' | 'microsoft' | 'clever';

export class LoginSsoOptionsModel {
	@Expose({ name: 'sso_openid_google' })
	google?: string;
	@Expose({ name: 'sso_saml_loginmicrosoftonlinecom' })
	microsoft?: string;
	@Expose({ name: 'sso_openid_clever' })
	clever?: string;
}
