import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ExternalAccountCreationModalComponent } from './external-account-creation-modal.component';

@Injectable({
	providedIn: 'root',
})
export class ExternalAccountCreationModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(userId: number) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
			};
			const upsertModal = this.modalService.open(
				ExternalAccountCreationModalComponent,
				ngbModalOptions,
			);

			(
				upsertModal.componentInstance as ExternalAccountCreationModalComponent
			).bindModalData({
				userId: userId,
			});

			upsertModal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
