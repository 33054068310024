import { Type } from 'class-transformer';

export class ProgramTypeOptionModel {
	itype: string;
	title: string;
	short_code: string;
}

export function GenericProgramTypeOptionModel_ClassTransformType() {
	return ProgramTypeOptionModel;
}

export class ProgramTypeOptionsModel {
	success: boolean;
	@Type(GenericProgramTypeOptionModel_ClassTransformType)
	program_types: ProgramTypeOptionModel[];
}
