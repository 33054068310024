<div class="input-group flex-nowrap overflow-hidden">
	<label class="btn btn-secondary m-0">
		<div>
			@switch (controlFG.controls.contains.value) {
				@case (true) {
					<span class="screen-reader-text">Text Is</span>
					<i aria-hidden="true" class="fas fa-equals"></i>
				}
				@case (false) {
					<span class="screen-reader-text">Text Is Not</span>
					<i aria-hidden="true" class="fas fa-not-equal"></i>
				}
			}
		</div>

		<input
			aria-label="Contains or does not contain"
			class="screen-reader-text"
			type="checkbox"
			[formControl]="controlFG.controls.contains"
			id="equality.comparer.text.input.component.or.checkbox.{{ counterId }}"
		/>
	</label>
	<input
		aria-label="Compare text input"
		class="form-control squared-start"
		type="text"
		[formControl]="controlFG.controls.value"
		id="equality.comparer.text.input.component.entered_value.{{ counterId }}"
	/>
</div>
