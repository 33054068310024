import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ExternalAccountModel } from 'src/lib/services/api/external-accounts/external-account.model';
import {
	UserDataGAModel,
	UserDataProfileModel,
	UserDataUserModel,
} from 'src/lib/services/api/users/user-data.model';
import { StringEnumOption } from 'src/lib/utilities/api/patterns/dynamic-fields/string-enum-option.model';
import { IfEmptyPipe } from '../../../../../pipes/if-empty.pipe';
import { SpinWhileDirective } from '../../../../../templates/layout/spin-while/spin-while.directive';

@Component({
	selector: 'ae-profile-account-view',
	templateUrl: './profile-account-view.component.html',
	styleUrls: ['./profile-account-view.component.scss'],
	standalone: true,
	imports: [SpinWhileDirective, DatePipe, IfEmptyPipe],
})
export class ProfileAccountViewComponent {
	@Input() public profileData: UserDataProfileModel;
	@Input() public canEditAccount: boolean;
	@Input() public loading: boolean;
	@Input() public enableEdit: () => void;
	@Input() public existingPendingChange: boolean;
	@Input() public accountData: UserDataUserModel;
	@Input() public gaData: UserDataGAModel;
	@Input() public formattedAdminTypeList: StringEnumOption[];
	@Input() public formattedAccessType: string[];
	@Input() public assignedStudents: string[];
	@Input() public canViewId: boolean;
	@Input() public canViewLinkedAccounts: boolean = false;
	@Input() public externalAccounts: ExternalAccountModel[];

	@ViewChild('rootElement', { static: true })
	rootElement: ElementRef<HTMLElement>;
}
