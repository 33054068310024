import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnlineStatusStoreService } from 'src/lib/services/stores/online-status-store/online-status-store.service';
import { OnlineStatus } from 'src/lib/services/stores/online-status-store/online-status.enum';
import { WampService } from 'src/lib/services/wamp/wamp.service';
import { convertToInt } from 'src/lib/utilities/convert';

@Component({
	selector: 'ae-online-status-bubble',
	templateUrl: './online-status-bubble.component.html',
	styleUrls: ['./online-status-bubble.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgbTooltip, NgClass],
})
export class OnlineStatusBubbleComponent
	implements OnChanges, OnInit, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<null>();

	@Input() public linkIds: number[] | number;

	public OnlineStatus = OnlineStatus;
	private statuses = new Map<number, OnlineStatus>();
	public wampConnected: boolean = true;

	constructor(
		private cdr: ChangeDetectorRef,
		private onlineStatusStore: OnlineStatusStoreService,
		private wampService: WampService,
	) {}

	ngOnInit() {
		this.cdr.detach();

		this.wampService.isConnected$
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((x) => {
				this.wampConnected = x;
				this.cdr.detectChanges();
			});
	}

	public currentStatus = (): OnlineStatus => {
		let status = OnlineStatus.offline;
		if (!this.wampConnected) return status;

		this.statuses.forEach((v) => {
			if (status === OnlineStatus.online) {
				// Skip more checking
			} else if (v !== OnlineStatus.offline) {
				status = v;
			}
		});

		return status;
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.linkIds) {
			this._unsubscribe$.next(null);
			this.statuses.clear();

			let ids = changes.linkIds.currentValue;
			if (ids != null) {
				const convertedIds = convertToInt(ids);
				if (convertedIds != null) {
					ids = [convertedIds];
				}

				ids.forEach((id) => {
					this.onlineStatusStore
						.userOnlineStatus$(id)
						.pipe(takeUntil(this._unsubscribe$))
						.subscribe((status) => {
							this.statuses.set(id, status);
							this.cdr.detectChanges();
						});
				});
			}
		}
	}

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
