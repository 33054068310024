<section #rootElement class="billboard" tabindex="-1">
	<div class="billboard-header">
		<h2 class="billboard-title">General Info</h2>
		<div class="billboard-actions d-flex">
			<div class="text-center flex-grow-0 mx-2 align-self-center">
				@if (existingPendingChange) {
					<ae-icon-tooltip
						message="A request to change this info is pending"
						type="pending"
					/>
				}
			</div>
			@if (canEditProfile) {
				<button
					class="btn btn-default"
					id="profile.contact.edit.enable"
					type="button"
					[disabled]="loading"
					(click)="enableEdit()"
				>
					Edit
				</button>
			}
		</div>
	</div>
	<div class="billboard-body">
		<div *aeSpinWhile="loading">
			<!-- Name -->
			<h3 class="fs-5 fw-bold">Demographics</h3>
			<div class="row mb-1">
				<div class="col-xl-12 col-lg-12 mb-3">
					<div class="fw-bold">Name</div>
					<div>
						{{ profileData?.profile_first_name }}
						{{
							profileData?.profile_preferred_name
								? "'" + profileData?.profile_preferred_name + "'"
								: ''
						}}
						{{
							profileData?.profile_middle_name
								? profileData?.profile_middle_name
								: ''
						}}
						{{ profileData?.profile_last_name }}
						{{ getMaidenName() }}
						{{ profileData?.profile_suffix }}
					</div>
				</div>

				<div class="col-xl-3 col-lg-4 mb-3">
					<div class="fw-bold">Birthdate</div>
					<div>
						{{
							studentData?.dob ? (studentData?.dob | date: 'MM/dd/yyyy') : '-'
						}}
					</div>
				</div>
				<div class="col-xl-3 col-lg-4 mb-3">
					<div class="fw-bold">Gender</div>
					<div>
						{{
							profileData?.profile_student_gender
								? profileData?.profile_student_gender
								: '-'
						}}
					</div>
				</div>
				<div class="col-xl-3 col-lg-6 mb-3">
					<div class="fw-bold">Race</div>
					<div>
						{{
							profileData?.profile_student_race
								? profileData?.profile_student_race
								: '-'
						}}
					</div>
				</div>
			</div>

			<!-- Social -->
			@if (
				hasSomeKey(profileData, [
					'profile_about',
					'profile_city',
					'profile_state',
					'profile_intro_video',
				])
			) {
				<h3 class="fs-5 fw-bold">Social</h3>
				@if (hasKey(profileData, 'profile_about')) {
					<div class="mb-3">
						<div class="fw-bold">About Me</div>
						<div>
							{{
								profileData?.profile_about ? profileData?.profile_about : '-'
							}}
						</div>
					</div>
				}
				<div class="row">
					<div class="col-xl-3 col-lg-6 mb-3">
						<div class="fw-bold">City</div>
						<div>
							{{ profileData?.profile_city ? profileData?.profile_city : '-' }}
						</div>
					</div>
					@if (hasKey(profileData, 'profile_state')) {
						<div class="col-xl-3 col-lg-3 mb-3">
							<div class="fw-bold">State</div>
							<div>
								{{
									profileData?.profile_state ? profileData?.profile_state : '-'
								}}
							</div>
						</div>
					}
				</div>
				@if (hasKey(profileData, 'profile_intro_video')) {
					<div class="row">
						<div class="col-lg-12">
							<div class="fw-bold">Welcome Video</div>
							@if (profileData.profile_intro_video) {
								<div>
									<a
										target="_blank"
										[href]="
											welcomeVideo(profileData.profile_intro_video)
												| dompurify: 5
										"
										>{{ welcomeVideo(profileData.profile_intro_video) }}</a
									>
								</div>
							}
							@if (!profileData.profile_intro_video) {
								<div>-</div>
							}
						</div>
					</div>
				}
			}
		</div>
	</div>
</section>
