import { Type } from 'class-transformer';

export class LoginCurrentUserModel {
	uid: number;
	roles: string[];
	name: string;
}

export function LoginCurrentUserModel_ClassTransformType() {
	return LoginCurrentUserModel;
}

export class LoginModel {
	@Type(LoginCurrentUserModel_ClassTransformType)
	current_user: LoginCurrentUserModel;
	csrf_token: string;
	logout_token: string;
}
