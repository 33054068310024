import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import { ReEntryStatus } from '../students/re-entry-request/re-entry-request.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';
import {
	TrackerContactTypeModel,
	TrackerContactTypeModel_ClassTransform_Type,
} from './tracker-contact-type.model';

export class ReentryCoordinatorHistoryModel {
	status: ReEntryStatus;
	@Transform(dateTransformer)
	updated_on: Date;
}

export function ReentryCoordinatorHistoryModel_ClassTransform_Type() {
	return ReentryCoordinatorHistoryModel;
}

export class ReentryCoordinatorTrackerModel {
	get profile_first_name() {
		return this.student_first_name;
	}
	get profile_preferred_name() {
		return this.student_preferred_name;
	}
	get profile_last_name() {
		return this.student_last_name;
	}
	get uid() {
		return this.student_id;
	}
	user_link_id: number;
	student_first_name: string;
	student_last_name: string;
	student_preferred_name: string;
	student_mail: string;
	student_id: number;
	student_cohort_year: string;
	student_timezone: string;
	reentry_request_status: ReEntryStatus;
	@Transform(dateTransformer)
	reentry_requested_on: Date;
	@Type(ReentryCoordinatorHistoryModel_ClassTransform_Type)
	reentry_request_history: ReentryCoordinatorHistoryModel[];
	institute_id: number;
	institute_title: string;
	institute_parent_title: string;
	institute_state: string;
	institute_type: string;
	institute_is_1418: boolean;
	academic_coach_id: number;
	academic_coach_name: string;
	local_advocate_id: number;
	local_advocate_name: string;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_attempt: Partial<TrackerContactTypeModel>;
	@Type(TrackerContactTypeModel_ClassTransform_Type)
	last_contact: Partial<TrackerContactTypeModel>;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	assignments_since_reentry_started: number;
	tags: string[];
}
