import { Directive, ElementRef, OnInit } from '@angular/core';
import { isNullOrEmptyString } from 'src/lib/utilities/compare';

let _labelIndex = 0;

@Directive({
	selector: 'section[aeBillboardLabel]',
	standalone: true,
})
export class BillboardLabelDirective implements OnInit {
	constructor(private elemRef: ElementRef<HTMLElement>) {}

	ngOnInit(): void {
		const titleElement =
			this.elemRef.nativeElement.querySelector('.billboard-title');
		if (titleElement) {
			if (isNullOrEmptyString(titleElement.id)) {
				titleElement.id = `billboard.label.auto-id.${_labelIndex++}`;
			}

			this.elemRef.nativeElement.setAttribute(
				'aria-labelledby',
				titleElement.id,
			);
		}
	}
}
