import { Observable } from 'rxjs';

export const PROFILE_SITEMAP_SERVICE = 'PROFILE_SITEMAP_SERVICE';

export interface ISitemapService {
	sitemap$(): Observable<SitemapItem[]>;
}

export interface SitemapItem {
	name: string;
	description: string;
	route: string;
}
