import { CommonSettings } from './common-settings';

export type AppNames = 'mobile' | 'admin' | 'student' | 'gabby';

export class EnvironmentTemplate {
	public static staticEnvironment: EnvironmentTemplate = null;

	public production: boolean;
	public assets: string;
	public settings: CommonSettings;

	constructor({
		production,
		assetRoot,
		settings = new CommonSettings(),
	}: {
		production: boolean;
		assetRoot: string;
		settings?: CommonSettings;
	}) {
		this.production = production;
		this.assets = assetRoot;
		this.settings = settings;

		if (EnvironmentTemplate.staticEnvironment != null) {
			throw new Error(
				'EnvironmentTemplate.staticEnvironment has already been set',
			);
		} else {
			EnvironmentTemplate.staticEnvironment = this;
		}
	}

	public isApp(name: AppNames) {
		return name === window['angular_app_name'];
	}
}
