import { AbstractControl } from '@angular/forms';
import { AsyncSubject, Observable, of } from 'rxjs';
import { PaginationTableActionDefinition } from './pagination-table-action-definition';
import { PaginationTableApi } from './pagination-table-api';
import {
	IPaginationTableConfig,
	IPaginationTableControlDefaultable,
	IPaginationTableControlInteractable,
	IPaginationTableControlStylable,
	IPaginationTableControls,
	IPaginationTableCustomization,
	IPaginationTablePagination,
	IPaginationTableRootConfig,
	IPaginationTableSearch,
	IPaginationTableSelection,
} from './types/ipagination-table-config';
import { PaginationFunction } from './types/pagination-function';
import { PaginationTableId } from './types/pagination-table-id';
import { PaginationTableMarginSize } from './types/pagination-table-margin-size';

export interface IPaginationTableControlsClean {
	margins: IPaginationTableControlInteractable &
		IPaginationTableControlDefaultable<PaginationTableMarginSize>;
	sortMinimized: IPaginationTableControlInteractable &
		IPaginationTableControlDefaultable<boolean>;
	smallText: IPaginationTableControlInteractable &
		IPaginationTableControlDefaultable<boolean>;
	wrapText: IPaginationTableControlInteractable &
		IPaginationTableControlDefaultable<boolean>;
	sticky: IPaginationTableControlInteractable &
		IPaginationTableControlDefaultable<boolean>;
	columns: IPaginationTableControlInteractable &
		IPaginationTableControlStylable;
	search: IPaginationTableControlInteractable &
		IPaginationTableControlStylable & {
			pinnedSearchClass?: string;
		};
	export: IPaginationTableControlInteractable & IPaginationTableControlStylable;
	refresh: IPaginationTableControlInteractable &
		IPaginationTableControlStylable & {
			hardRefresh$?: () => Observable<any> | void;
		};
	rowActions: IPaginationTableControlInteractable &
		IPaginationTableControlDefaultable<boolean>;
}

export const paginationTableMaxAllowedPageSize = 500;

export class PaginationTableConfig<
	T,
	F extends {
		[K in keyof F]: AbstractControl<any>;
	} = any,
> implements IPaginationTableRootConfig<T, F>
{
	private _hasReadied = false;
	private _tableReady = new AsyncSubject<PaginationTableApi<T>>();

	/** The table will provide the api controls back through here when it's ready. */
	public tableReady$ = this._tableReady.asObservable();

	private constructor(
		public tableId: PaginationTableId,
		public getPage$: PaginationFunction<T>,
		public initReady$?: Observable<any>,
		public initRealm?: {
			id: string;
			name: string;
		} | null,
	) {
		if (this.tableId == null) {
			throw new Error('PaginationTableConfig.tableId is null');
		}
		if (this.getPage$ == null) {
			throw new Error('PaginationTableConfig.getPage is null');
		}

		this.initReady$ = initReady$ ?? of(null);
	}

	readonly search: IPaginationTableSearch<F> = {
		debounceTime: 250,
	};

	readonly selection: IPaginationTableSelection<T> = {};

	readonly pagination: IPaginationTablePagination = {
		sizes: [15, 30, 50],
		selectorRange: 2,
	};

	readonly controls: IPaginationTableControlsClean = {
		margins: {
			interactable: true,
			default: PaginationTableMarginSize.medium,
		},
		sticky: {
			interactable: true,
			default: true,
		},
		columns: {
			interactable: true,
			showText: false,
			class: 'btn-outline-secondary',
		},
		search: {
			interactable: true,
			showText: false,
			class: 'btn-outline-secondary',
			pinnedSearchClass: 'btn-secondary',
		},
		export: {
			interactable: true,
			showText: false,
			class: 'btn-outline-secondary',
		},
		refresh: {
			interactable: true,
			showText: false,
			class: 'btn-outline-secondary',
		},
		rowActions: {
			interactable: true,
			default: false,
		},
		sortMinimized: {
			interactable: true,
			default: false,
		},
		smallText: {
			interactable: true,
			default: false,
		},
		wrapText: {
			interactable: true,
			default: false,
		},
	};

	readonly customization: IPaginationTableCustomization<T> = {};
	actions: PaginationTableActionDefinition[];

	public static Create<G>(
		input: IPaginationTableRootConfig<G>,
	): PaginationTableConfig<G> {
		const result = new PaginationTableConfig<G>(
			input.tableId,
			input.getPage$,
			input.initReady$,
		);

		result.updateValues(input);

		return result;
	}

	public updateValues = (input: IPaginationTableConfig<T>) => {
		const cleanInput = Object.assign({}, input);

		cleanInput.controls = cleanInput.controls ?? {};
		if (cleanInput.controls === false) {
			cleanInput.controls = {
				margins: false,
				sortMinimized: false,
				smallText: false,
				wrapText: false,
				sticky: false,
				columns: false,
				search: false,
				export: false,
				refresh: false,
				rowActions: false,
			} as IPaginationTableControls;
		}

		Object.entries(cleanInput.controls).forEach(([k, v]) => {
			if (v === false) {
				cleanInput.controls[k] = {
					interactable: false,
				} as IPaginationTableControlInteractable;
			}
		});

		this.validateConfig(cleanInput);

		Object.assign(this.search, cleanInput.search);
		Object.assign(this.selection, cleanInput.selection);
		Object.assign(this.pagination, cleanInput.pagination);
		Object.assign(this.customization, cleanInput.customization);
		Object.keys(this.controls).forEach((k) => {
			Object.assign(this.controls[k], cleanInput.controls[k]);
		});
		this.actions = input.actions ?? this.actions;
	};

	public validateConfig = (input: IPaginationTableConfig<T>) => {
		if (
			input.selection?.getAllKeys$ != null &&
			input.selection.keyProperty == null
		) {
			throw new Error(
				'PaginationTableConfig.selection.getAllKeys$ is defined but PaginationTableConfig.selection.keyProperty is null',
			);
		}

		if (
			input.pagination?.sizes != null &&
			input.pagination.sizes.length === 0
		) {
			throw new Error(
				'PaginationTableConfig.pagination.sizes is defined without any sizes given',
			);
		}

		if (
			input.pagination?.sizes !== 'infinite' &&
			Math.max(...(input.pagination?.sizes ?? [])) >
				paginationTableMaxAllowedPageSize
		) {
			throw new Error(
				`PaginationTableConfig.pagination.sizes has a size ${Math.max(
					...(input.pagination?.sizes ?? []),
				)} which is above the maximum allowed of ${paginationTableMaxAllowedPageSize}`,
			);
		}
	};

	public notifyTableReady = (tableApi: PaginationTableApi<T>) => {
		if (!this._hasReadied) {
			this._hasReadied = true;
			this._tableReady.next(tableApi);
			this._tableReady.complete();
		} else {
			throw new Error('Table api has already been set');
		}
	};
}

export interface IPaginationTable<
	T,
	F extends {
		[K in keyof F]: AbstractControl<any>;
	},
> {
	tableConfig: IPaginationTableRootConfig<T, F>;
}
