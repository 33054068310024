import { commonGlobal } from './common-global';

const registeredLoggers = new Array<(enable: boolean) => void>();
let isEnabled = false;
commonGlobal.registerLogging = (registerFunc: (enable: boolean) => void) => {
	registeredLoggers.push(registerFunc);
};

commonGlobal.enableLogging = (enable: boolean) => {
	if (!isEnabled && enable) {
		isEnabled = true;
		for (const logger of registeredLoggers) {
			logger(isEnabled);
		}
	} else if (isEnabled && !enable) {
		isEnabled = false;
		for (const logger of registeredLoggers) {
			logger(isEnabled);
		}
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
