/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import {
	Exclude,
	Expose,
	instanceToPlain,
	plainToClassFromExist,
	plainToInstance,
	TransformationType,
	TransformFnParams,
	Type,
} from 'class-transformer';

export class JsonApiDataModel<T> {
	@Exclude({ toPlainOnly: true })
	private _genericType: Function;

	@Exclude()
	private _attributes: T;

	constructor(genericType: Function) {
		this._genericType = genericType;
	}

	@Expose()
	get attributes(): T {
		return this._attributes;
	}
	set attributes(value: T) {
		this._attributes = plainToInstance(this._genericType(), value);
	}
}

export function JsonApiDataModel_ClassTransform_TransformFactory<T>(
	genericType: () => T,
) {
	return (params: TransformFnParams) => {
		if (params.value == null) return null;
		else if (params.type === TransformationType.PLAIN_TO_CLASS) {
			return params.value.map((v) => {
				return plainToClassFromExist(new JsonApiDataModel<T>(genericType), v);
			});
		} else if (params.type === TransformationType.CLASS_TO_PLAIN) {
			return instanceToPlain(params.value);
		} else if (params.type === TransformationType.CLASS_TO_CLASS) {
			return params.value.map((v) => {
				return plainToClassFromExist(
					new JsonApiDataModel<T>(genericType),
					instanceToPlain(v),
				);
			});
		}
		return params.value;
	};
}

export class JsonApiErrorsModel {
	title: string;
	status: string;
	detail: string;
}

export function JsonApiErrorsModel_ClassTransform_Type() {
	return JsonApiErrorsModel;
}

export abstract class JsonApiModel<T> {
	abstract data?: JsonApiDataModel<T>[];

	@Type(JsonApiErrorsModel_ClassTransform_Type)
	errors?: JsonApiErrorsModel[];

	exportData = (): T[] => {
		return this.data.map((x) => x.attributes);
	};
}
