import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorCache } from 'src/lib/utilities/cache';
import { ParentStudentModel } from '../../api/parents/parent-students.model';
import { ParentsService } from '../../api/parents/parents.service';

@Injectable({
	providedIn: 'root',
})
export class ParentsStoreService {
	private _studentsCache = new BehaviorCache<number, ParentStudentModel[]>(
		(uid) => this.parentsService.getStudents(uid),
		'ParentsStoreService StudentsCache',
		() => [],
	);

	constructor(private parentsService: ParentsService) {}

	/**
	 * Students
	 */
	public students$ = (uid: number): Observable<ParentStudentModel[]> => {
		return this._studentsCache.getCache(uid);
	};

	public refreshStudents = (uid: number): Promise<boolean> => {
		return this._studentsCache.fetchData(uid, true);
	};
}
