export class MinimumBrowserVersion {
	public static Chrome = 120;
}

export class ValidationConstants {
	public static posiviteCurrencyRegex: string =
		'(\\d{1,12}|[1-9]{1}(\\d{1,2})?,?\\d{3}|[1-9]{1}(\\d{1,2})?,\\d{3},?\\d{3}|[1-9]{1}(\\d{1,2})?,\\d{3},?\\d{3},?\\d{3})';
	public static nonZeroPosiviteCurrencyRegex: string =
		'([1-9]{1}(\\d{1,11})?|[1-9]{1}(\\d{1,2})?,?\\d{3}|[1-9]{1}(\\d{1,2})?,\\d{3},?\\d{3}|[1-9]{1}(\\d{1,2})?,\\d{3},?\\d{3},?\\d{3})';
	public static phoneNumberRegex: string =
		'^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})$';
	public static phoneNumberRegexSevenDigit: string =
		'^[ ]*[0-9]{3}[\\- ]?[0-9]{4}[ ]*$';
	public static isNumberRegex: string = '^\\d{1,15}$';
	public static alphaSpaceOnly: string = '^[A-Za-z ]+$';
	public static alphaNumericSpaceOnly: string = '^[a-zA-Z0-9 ]*$';
	public static zipcode: string = '^\\d{5}(-\\d{4})?$';
	public static formatedNumberRegex: string =
		'(\\d{1,12}|[1-9]{1}(\\d{1,2})?,?\\d{3}|[1-9]{1}(\\d{1,2})?,\\d{3},?\\d{3}|[1-9]{1}(\\d{1,2})?,\\d{3},?\\d{3},?\\d{3})';

	/* Pulled from: https://stackoverflow.com/questions/30970068/js-regex-url-validation/30970319 */
	public static urlRegex: string =
		'^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
}

export enum BootstrapTypes {
	default = 'default',
	success = 'success',
	info = 'info',
	warning = 'warning',
	danger = 'danger',
}

export const HTTP_NO_PERMISSION = 'NO PERMISSION';

export const ACHIEVEPOINT_PROGRAMS = [237, 687, 690, 696, 2921];

export const PASSWORD_RESTRICTIONS = [
	'password',
	'passw0rd',
	'changeme',
	'qwerty',
	'12345',
];

export const BARRIER_OPTIONS = [
	'Emotional Violence inside or outside the home',
	'Family Disruption',
	'Financial Instability',
	'Food Instability',
	'Heightened Anxiety',
	'Heightened Depression',
	'Homelessness',
	'Interpersonal Conflict',
	'Physical Violence inside or outside the home',
	'Suicidal Ideation',
	'Suicidal Intent',
	'Transportation',
	'Unstable Housing',
];

export const SITE_ADMINISTRATOR = 'site_admin';

/** Sourced from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
export const USA_TIMEZONES = [
	'Pacific/Honolulu',
	'America/Los_Angeles',
	'America/Phoenix',
	'America/Denver',
	'America/Chicago',
	'America/New_York',
	'America/Anchorage',
];
