import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { Permits } from 'src/lib/constants/permissions';
import { PermissionStoreService } from '../../stores/permission-store/permission-store.service';
import { GabbyWampPublishFactoryService } from '../wamp-publish-factory/gabby/gabby-wamp-publish-factory.service';

@Injectable({
	providedIn: 'root',
})
export class GabbyLinkService {
	constructor(
		private gabbyWampPublishFactoryService: GabbyWampPublishFactoryService,
		private permissionService: PermissionStoreService,
	) {}

	public openInChat$ = (studentLinkId: number) => {
		return this.permissionService.getFieldSet$().pipe(
			filter((field) =>
				field.canDo(Permits['ga_direct_message|gabby|access gabby ui']),
			),
			switchMap(() => {
				return this.gabbyWampPublishFactoryService
					.notify_navigateRequestToUser$(studentLinkId)
					.pipe(
						map((success) => {
							if (!success) {
								this.openInTab(studentLinkId);
							}
							return true;
						}),
					);
			}),
		);
	};

	public openInTab = (studentLinkId: number) => {
		window.open(`/gradchat?usrInit=${studentLinkId}`, '_blank');
	};
}
