import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { DirectoriesService } from 'src/lib/services/api/gabby/directories/directories.service';
import { DirectoryModel } from 'src/lib/services/api/gabby/directories/directory.model';
import { MessagePlatform } from 'src/lib/services/api/gabby/messages/message-platform.enum';
import { MessageEventService } from 'src/lib/services/wamp/gabby/message-event.service';
import { BehaviorCache, BehaviorCacheSet } from 'src/lib/utilities/cache';
import { isEquivalentString } from 'src/lib/utilities/compare';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';
import { UserStoreService } from '../../users/user/user-store.service';

export interface DirectoryCacheSet extends BehaviorCacheSet<DirectoryModel[]> {
	stateSubscriptions: Subscription[];
}

@Injectable({
	providedIn: 'root',
})
export class DirectoryStoreService {
	private _directoryCache = new BehaviorCache<
		number,
		DirectoryModel[],
		DirectoryCacheSet
	>(
		(uid) => this.directoriesService.getDirectories(uid),
		'DirectoryStoreService DirectoryCache',
		() => [],
	);

	constructor(
		private directoriesService: DirectoriesService,
		private messageEventService: MessageEventService,
		private userStore: UserStoreService,
	) {
		this.initializeCaches();
	}

	private initializeCaches = () => {
		this._directoryCache.observer = {
			next: ([set, directories]) => {
				set.stateSubscriptions?.forEach((s) => s.unsubscribe());

				const setPump = new Subject<null>();

				set.stateSubscriptions = [];

				directories.forEach((directory) => {
					// Unread counter up
					set.stateSubscriptions.push(
						this.messageEventService
							.unreadMessages$({ directoryId: directory.id })
							.pipe(
								filter(
									(e) =>
										e.message_sender_link_id !==
											this.userStore.currentUserLinkId &&
										e.message_send_medium !== MessagePlatform.system,
								),
							)
							.subscribe((e) => {
								directory.unread_messages++;

								SessionLogger.log(
									'DirectoryStoreService',
									'incremented directory unread count',
									{
										message_id: e.message_id,
										channel_id: e.channel_id,
										directory_id: directory.id,
										unread_count: directory.unread_messages,
									},
								);
								setPump.next(null);
							}),
					);

					// Unread counter down
					set.stateSubscriptions.push(
						this.messageEventService
							.readMessages$({ directoryId: directory.id })
							.pipe(
								filter(
									(e) =>
										e.senderLinkId !== this.userStore.currentUserLinkId &&
										(e.readerLinkId === this.userStore.currentUserLinkId ||
											e.readerLinkId === 0),
								),
							)
							.subscribe((e) => {
								directory.unread_messages--;

								SessionLogger.log(
									'DirectoryStoreService',
									'decremented directory unread count',
									{
										message_id: e.readMessageId,
										channel_id: e.channelId,
										directory_id: directory.id,
										unread_count: directory.unread_messages,
									},
								);

								if (directory.unread_messages < 0) {
									console.error(
										'DirectoryStoreService ',
										'decremented directory unread count below zero ',
										{
											message_id: e.readMessageId,
											channel_id: e.channelId,
											directory_id: directory.id,
											unread_count: directory.unread_messages,
										},
									);
								}

								setPump.next(null);
							}),
					);
				});

				set.stateSubscriptions.push(
					setPump
						.pipe(debounceTime(250))
						.subscribe(() => set.data.next(directories)),
				);
			},
		};
	};

	/**
	 * Directories
	 */
	public directories$ = (uid: number): Observable<DirectoryModel[]> => {
		return this._directoryCache.getCache(uid);
	};

	public directory$ = (
		userId: number,
		directoryId: string,
	): Observable<DirectoryModel> => {
		return this.directories$(userId).pipe(
			map((x) => x.find((c) => isEquivalentString(c.id, directoryId))),
		);
	};

	public refreshDirectories = (uid: number): Promise<boolean> => {
		return this._directoryCache.fetchData(uid, true);
	};
}
