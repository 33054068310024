import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InitializationService } from '../services/utility/initialization/initialization.service';

@Injectable({
	providedIn: 'root',
})
export class HasRoleGuard {
	constructor(
		private initService: InitializationService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkRole(route);
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkRole(route);
	}

	private checkRole = (route: ActivatedRouteSnapshot) => {
		const roles: [] = route.data?.roles ?? [];

		if (roles && this.initService.roles) {
			const found =
				roles.find((r) => this.initService.roles[r] != null) != null;
			return found || this.router.createUrlTree(['/nopermission']);
		}

		return true;
	};
}
