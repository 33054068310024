<section class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Tasks</h2>
	</div>
	<div
		aeBillboardCollapsable="profile.activity.tasks.collapse"
		class="billboard-body"
	>
		<!-- TASKS -->
		@if (tasksTableConfig) {
			<ae-pgt
				#t
				class="pgtable-no-container"
				name="Tasks"
				[tableConfig]="tasksTableConfig"
			>
				<ae-pgt-column columnId="subject" [starWidth]="4">
					<ng-template aePgtHeader>Subject</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.subject }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.tasks.component.search.subject"
							title="Subject"
							type="search"
							[formControl]="form.controls.subject"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="creator_name" [starWidth]="2">
					<ng-template aePgtHeader>Created By</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.creator_name }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.tasks.component.search.createdBy"
							title="Created By"
							type="search"
							[formControl]="form.controls.creator_name"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column
					columnId="student_name"
					[sortable]="false"
					[starWidth]="2"
				>
					<ng-template aePgtHeader>Student</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.student_name }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.tasks.component.search.studentName"
							title="Student"
							type="search"
							[formControl]="form.controls.student_name"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="medium" [starWidth]="1.5">
					<ng-template aePgtHeader cellClass="text-center">Type</ng-template>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						{{ item.medium }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.tasks.component.search.type"
							title="Type"
							type="search"
							[formControl]="form.controls.medium"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="due_date" [autoTitle]="false" [starWidth]="2">
					<ng-template aePgtHeader cellClass="text-center"
						>Due Date</ng-template
					>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						<ae-user-time [date]="item.due_date" [userId]="userId" />
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<ae-equality-comparer-date
							class="m-0"
							id="profile.activity.tasks.component.search.duedate"
							[formControl]="form.controls.due_date"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="updated" [autoTitle]="false" [starWidth]="2">
					<ng-template aePgtHeader cellClass="text-center">Updated</ng-template>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						<ae-user-time [date]="item.updated" [userId]="userId" />
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<ae-equality-comparer-date
							class="m-0"
							id="profile.activity.tasks.component.search.updated"
							[formControl]="form.controls.updated"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-row-actions-column>
					<ng-template let-item [aePgtDisplay]="t">
						<button
							class="btn btn-icon"
							title="View Task"
							type="button"
							id="profile.activity.tasks.component.view.task.{{ item.id }}"
							(click)="openEventModal(item)"
						>
							<i aria-hidden="true" class="far fa-eye"></i>
						</button>
						@if (isTaskEditable(item)) {
							<button
								class="btn btn-icon"
								title="Complete Task"
								type="button"
								id="profile.activity.tasks.component.complete.task.{{
									item.id
								}}"
								(click)="openCompletedTaskModal(item)"
							>
								<i aria-hidden="true" class="far fa-check"></i>
							</button>
						}
					</ng-template>
				</ae-pgt-row-actions-column>
			</ae-pgt>
		}
	</div>
</section>

<section class="billboard">
	<div class="billboard-header">
		<h3 class="billboard-title">Administrative Events</h3>
	</div>
	<div
		aeBillboardCollapsable="profile.activity.events.collapse"
		class="billboard-body"
	>
		<!-- EVENTS -->
		@if (eventsTableConfig) {
			<ae-pgt
				#t
				class="pgtable-no-container"
				name="Administrative Events"
				[tableConfig]="eventsTableConfig"
			>
				<ae-pgt-column columnId="subject" [starWidth]="4">
					<ng-template aePgtHeader>Subject</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.subject }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.event.component.search.subject"
							title="Subject"
							type="search"
							[formControl]="form.controls.subject"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column
					columnId="student_name"
					[sortable]="false"
					[starWidth]="2"
				>
					<ng-template aePgtHeader>Student</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.student_name }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.event.component.search.studentName"
							title="Student"
							type="search"
							[formControl]="form.controls.student_name"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="category" [starWidth]="2">
					<ng-template aePgtHeader>Category</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.category }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.event.component.search.category"
							title="Category"
							type="search"
							[formControl]="form.controls.category"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="medium" [starWidth]="1.5">
					<ng-template aePgtHeader cellClass="text-center">Type</ng-template>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						{{ item.medium }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.event.component.search.type"
							title="Type"
							type="search"
							[formControl]="form.controls.medium"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="updated" [autoTitle]="false" [starWidth]="2">
					<ng-template aePgtHeader cellClass="text-center">Updated</ng-template>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						<ae-user-time [date]="item.updated" [userId]="userId" />
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<ae-equality-comparer-date
							class="m-0"
							id="profile.activity.event.component.search.updated"
							[formControl]="form.controls.updated"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-row-actions-column>
					<ng-template let-item [aePgtDisplay]="t">
						<button
							class="btn btn-icon"
							title="View Event"
							type="button"
							id="profile.activity.event.action.{{ item.id }}"
							(click)="openEventModal(item)"
						>
							<i aria-hidden="true" class="far fa-eye"></i>
						</button>
					</ng-template>
				</ae-pgt-row-actions-column>
			</ae-pgt>
		}
	</div>
</section>

<section class="billboard">
	<div class="billboard-header">
		<h3 class="billboard-title">Notifications</h3>
	</div>
	<div
		aeBillboardCollapsable="profile.activity.notifications.collapse"
		class="billboard-body"
	>
		<!-- Notifications -->
		@if (notificationsTableConfig) {
			<ae-pgt
				#t
				class="pgtable-no-container"
				name="Notifications"
				[tableConfig]="notificationsTableConfig"
			>
				<ae-pgt-column columnId="subject" [starWidth]="4">
					<ng-template aePgtHeader>Subject</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.subject }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.notifications.component.search.subject"
							title="Subject"
							type="search"
							[formControl]="form.controls.subject"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="creator_name" [starWidth]="2">
					<ng-template aePgtHeader>Created By</ng-template>
					<ng-template let-item [aePgtDisplay]="t">
						{{ item.creator_name }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.notifications.component.search.associatedWith"
							title="Created By"
							type="search"
							[formControl]="form.controls.creator_name"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="medium" [starWidth]="1.5">
					<ng-template aePgtHeader cellClass="text-center">Type</ng-template>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						{{ item.medium }}
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<input
							class="form-control"
							id="profile.activity.notifications.component.search.type"
							title="Type"
							type="search"
							[formControl]="form.controls.medium"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-column columnId="updated" [autoTitle]="false" [starWidth]="2">
					<ng-template aePgtHeader cellClass="text-center">Updated</ng-template>
					<ng-template cellClass="text-center" let-item [aePgtDisplay]="t">
						<ae-user-time [date]="item.updated" [userId]="userId" />
					</ng-template>
					<ng-template let-form [aePgtSearch]="t">
						<ae-equality-comparer-date
							class="m-0"
							id="profile.activity.notifications.component.search.updated"
							[formControl]="form.controls.updated"
						/>
					</ng-template>
				</ae-pgt-column>
				<ae-pgt-row-actions-column>
					<ng-template let-item [aePgtDisplay]="t">
						<button
							class="btn btn-icon"
							title="View Event"
							type="button"
							id="profile.activity.notification.action.{{ item.id }}"
							(click)="openEventModal(item)"
						>
							<i aria-hidden="true" class="far fa-eye"></i>
						</button>
					</ng-template>
				</ae-pgt-row-actions-column>
			</ae-pgt>
		}
	</div>
</section>
