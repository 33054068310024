import {
	AfterViewChecked,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import {
	AsyncSubject,
	Observable,
	Subject,
	Subscription,
	combineLatest,
	of,
	race,
	timer,
} from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChannelModel } from 'src/lib/services/api/gabby/channels/channel.model';
import { MessageModel } from 'src/lib/services/api/gabby/messages/message.model';
import { MessagesService } from 'src/lib/services/api/gabby/messages/messages.service';
import { MessageStoreService } from 'src/lib/services/stores/gabby/message-store/message-store.service';
import { UserStoreService } from 'src/lib/services/stores/users/user/user-store.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import spacetime from 'spacetime';
import { Permits } from 'src/lib/constants/permissions';
import { getEnvironment } from 'src/lib/environment/environment';
import { AttachementType } from 'src/lib/services/api/gabby/messages/message-attachment.model';
import { UserActiveService } from 'src/lib/services/startup/user-active/user-active.service';
import { ChannelStoreService } from 'src/lib/services/stores/gabby/channel-store/channel-store.service';
import { MessageDraftStoreService } from 'src/lib/services/stores/gabby/message-draft-store/message-draft-store.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { StudentStoreService } from 'src/lib/services/stores/students/student/student-store.service';
import { FormControlWrapper } from 'src/lib/types/forms.def';
import {
	getFileFromPaste,
	getFilesFromDrop,
	getFilesFromInput$,
} from 'src/lib/utilities/file';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';
import {
	AttachmentPostMessageRender,
	DatePostMessageRender,
	MessageForm,
	PostMessageRender,
	PostMessageRenderType,
} from 'src/lib/views/gabby/gabby-chat/gabby-chat-component-definitions';
import { GabbyMessageBuilderComponent } from 'src/lib/views/gabby/gabby-chat/gabby-message-builder/gabby-message-builder.component';
import { GabbyStateModel } from 'src/lib/views/gabby/gabby-state.model';
import { firstBy } from 'thenby';
import { Key } from 'ts-key-enum';

export enum ChatCapState {
	top = 'top',
	loading = 'loading',
	moreAvailable = 'moreAvailable',
}

@Directive()
export abstract class AbstractGabbyChatComponent
	implements OnInit, OnDestroy, AfterViewChecked
{
	protected _unsubscribe$ = new AsyncSubject<null>();

	protected currentState$ = new Subject<GabbyStateModel>();

	@ViewChild('chatTextarea') chatTextarea: ElementRef<HTMLInputElement>;

	public currentState: GabbyStateModel;
	public currentUserLinkId: number;
	public channelUserUid: number;

	public refreshingChat: boolean = true;
	public currentChannel: ChannelModel;
	public isValidChannel: boolean = false;
	public hasAcceptedJoinWarning: boolean = false;
	public isMinor: boolean = false;
	public canViewDob: boolean = false;

	public hasInitialized: boolean;
	public messagesSubscription: Subscription;
	public messages: MessageModel[] = null;
	public messageForm: FormGroup<FormControlWrapper<MessageForm>>;
	public messagePostRenders: Record<
		number,
		(PostMessageRender & DatePostMessageRender & AttachmentPostMessageRender)[]
	>;
	protected messageFormDefaults: MessageForm;

	public maxBodyLength =
		getEnvironment().settings.views.gabby.message.maxBodyLength;
	public attachmentExtensionFilter =
		getEnvironment().settings.views.gabby.message.attachmentExtensionFilter;

	public capState: ChatCapState;
	public ChatCapState = ChatCapState;

	public loadMessagesSubject = new Subject<null>();

	public unreadMarkerPostRenderMessageId: number | true;
	public datePostRenders = new Map<number, DatePostMessageRender>();

	private _selectedChatText = false;
	private _ignoredKeys: string[] = [
		Key.ArrowDown,
		Key.ArrowLeft,
		Key.ArrowRight,
		Key.ArrowUp,
		Key.Shift,
		Key.Control,
		Key.Alt,
	];

	constructor(
		protected cdr: ChangeDetectorRef,
		protected channelStore: ChannelStoreService,
		protected userStore: UserStoreService,
		protected fb: FormBuilder,
		protected messageStore: MessageStoreService,
		protected messagesService: MessagesService,
		protected messageDraftStore: MessageDraftStoreService,
		protected modalService: NgbModal,
		protected userActivityService: UserActiveService,
		protected toastrService: ToastrService,
		protected permissionService: PermissionStoreService,
		protected studentStore: StudentStoreService,
	) {}

	public abstract ngOnInit(): void;
	protected abstract onMessageFormReset(): void;
	protected abstract beforeStateChange(): void;
	protected abstract afterStateChange(channel: ChannelModel): void;
	protected abstract canEnableSms$(channel: ChannelModel): Observable<boolean>;

	public innerOnInit() {
		this.currentUserLinkId = this.userStore.currentUserLinkId;
		this.messageForm = this.fb.group<FormControlWrapper<MessageForm>>(
			{
				message: new FormControl('', Validators.maxLength(this.maxBodyLength)),
				sendSms: new FormControl({ value: false, disabled: true }),
				attachments: new FormControl(
					[],
					[
						Validators.maxLength(
							getEnvironment().settings.views.gabby.message.maxAttachmentCount,
						),
						(control: FormControl<File[]>) => {
							if (control.value != null) {
								let size = 0;

								for (const file of control.value) {
									size += file.size;
								}

								if (
									size >
									getEnvironment().settings.views.gabby.message
										.maxAttachmentSize
								) {
									return {
										size: 'Files too large. Total file size must be less than 2MB',
									};
								}
							}

							return null;
						},
					],
				),
			},
			{
				validators: (group: FormGroup<FormControlWrapper<MessageForm>>) => {
					if (
						group.value.attachments != null &&
						group.value.attachments.length > 0
					) {
						return null;
					} else {
						const msg = group.value.message;
						const cleanedMsg = (msg || '').trim();

						if (cleanedMsg.length > 0) {
							return null;
						}
					}

					return { value: 'Either set a message or add attachments' };
				},
				updateOn: 'change',
			},
		);
		this.messageFormDefaults = this.messageForm.value as MessageForm;

		this.currentState$
			.pipe(
				tap((s) => {
					this.currentState = s;
					this.isValidChannel = false;
					this.beforeStateChange();
					this.cdr.detectChanges();
				}),
				switchMap((s) =>
					this.channelStore.channel$(s.channelId, s.directoryId).pipe(take(1)),
				),
				takeUntil(this._unsubscribe$),
			)
			.subscribe((channel) => {
				// Recycling the scroller
				// This allows it to better pick up the initial scroll position
				this.refreshingChat = true;
				this.cdr.detectChanges();
				this.refreshingChat = false;
				// Recycling the scroller

				this.currentChannel = channel;

				this.hasAcceptedJoinWarning = false;
				this.unreadMarkerPostRenderMessageId = null;
				this.messages = null;
				this.messagePostRenders = {};
				this.hasInitialized = false;
				this._selectedChatText = false;
				this.resetMessageForm(false);

				if (channel == null) {
					this.isValidChannel = false;
				} else {
					this.isValidChannel = true;

					// Check if we can send messages
					if (channel.can_send || channel.can_join) {
						this.messageForm.enable();
						this.messageForm.controls.sendSms.disable();

						this.canEnableSms$(channel)
							.pipe(takeUntil(race(this._unsubscribe$, this.currentState$)))
							.subscribe((x) => {
								if (x) {
									this.messageForm.controls.sendSms.enable();
								}
							});

						// Load the saved draft
						this.messageDraftStore
							.getDraft$<MessageForm>(channel.id)
							.pipe(take(1))
							.subscribe((draft) => {
								if (draft != null) {
									this.messageForm.patchValue(draft);
									this.cdr.detectChanges();

									this.updateChatHeight();
								}
							});

						// Save the drafts
						this.messageForm.valueChanges
							.pipe(takeUntil(race(this._unsubscribe$, this.currentState$)))
							.subscribe((draft) => {
								if (!this.messageForm.pristine) {
									if (draft.message != null && draft.message.length > 0) {
										delete draft.attachments;
										this.messageDraftStore.updateDraft(channel.id, draft);
									} else {
										this.messageDraftStore.updateDraft(channel.id, null);
									}
								}
							});
					} else {
						this.messageForm.disable();
					}
				}
				this.afterStateChange(channel);

				// Quick load so the scroller can set the fade effect
				this.cdr.detectChanges();

				if (this.isValidChannel) {
					this.setChannelSubscriptions();
				}
			});

		combineLatest([
			this.currentState$,
			this.permissionService.getFieldSet$().pipe(
				tap((p) => {
					this.canViewDob = p.canDo(Permits['ga_user|view dob']);
				}),
			),
		])
			.pipe(
				switchMap(([s]) => {
					this.isMinor = false;
					return this.channelStore.channel$(s.channelId, s.directoryId).pipe(
						take(1),
						switchMap((c) => {
							return combineLatest([
								this.canViewDob
									? this.studentStore.overview$(c?.student_uid).pipe(
											tap((student) => {
												this.isMinor = student?.birthday
													? spacetime(student.birthday).diff(
															new Date(),
															'year',
														) < 18
													: false;
												this.cdr.detectChanges();
											}),
										)
									: of(null),
							]);
						}),
					);
				}),
			)
			.subscribe();
	}

	private setChannelSubscriptions = () => {
		//
		// Set the slate clean
		//
		this.unreadMarkerPostRenderMessageId = null;
		let messageReadDelay =
			getEnvironment().settings.services.wamp.gabby.readChannelDelay;

		// Make sure the message read rate is set to fast after initial delay
		timer(
			getEnvironment().settings.services.wamp.gabby.readChannelDelay,
		).subscribe(() => {
			messageReadDelay = 0;
		});

		const nextMessages$ = new Subject<null>();
		const canMarkReadCurrentChannel =
			this.currentChannel.unknown ||
			this.currentChannel.participants.find(
				(p) => p.link_id === this.currentUserLinkId,
			) != null;

		//
		// Load more messages on scroll top
		//
		this.loadMessagesSubject
			.pipe(
				filter(() => this.capState !== ChatCapState.top),
				takeUntil(race(this._unsubscribe$, this.currentState$)),
			)
			.subscribe(() => {
				this.capState = ChatCapState.loading;

				if (this.messages != null && this.messages.length > 0) {
					this.messageStore.loadMoreMessages(
						this.currentState.directoryId,
						this.currentState.channelId,
						this.messages[0].message_id,
					);
				}
			});

		let firstMessageLoad = false;

		//
		// Listen for messages and handle them
		//
		this.messagesSubscription = this.messageStore
			.messages$(this.currentState.directoryId, this.currentState.channelId)
			.pipe(
				filter(() => this.currentChannel != null),
				tap((newMessages) => {
					nextMessages$.next(null);

					newMessages = newMessages.sort(firstBy((x) => x.created));

					this.datePostRenders.clear();

					let unreadMessage: MessageModel;
					let unreadComplete = false;
					let previousMessage: MessageModel;

					// Reset the unread marker if we're inactive
					if (!this.userActivityService.isActive) {
						this.unreadMarkerPostRenderMessageId = null;
					}

					// Setup the post render meta data
					for (let i = newMessages.length - 1; i >= 0; i--) {
						if (
							i === 0 &&
							newMessages[i].message_id === this.currentChannel.first_message_id
						) {
							this.capState = ChatCapState.top;
						} else {
							this.capState = ChatCapState.moreAvailable;
						}

						const indexMessage = newMessages[i];

						// Check for the unread messages marker
						if (!unreadComplete && !this.unreadMarkerPostRenderMessageId) {
							if (indexMessage.message_read === false) {
								unreadMessage = indexMessage;
							} else {
								if (unreadMessage != null) {
									this.unreadMarkerPostRenderMessageId =
										indexMessage.message_id;
								}

								unreadComplete = true;
							}
						}

						// Set timestamps
						if (previousMessage != null) {
							const prevST = spacetime(previousMessage.created);
							const indexST = spacetime(indexMessage.created);

							if (
								indexST.diff(prevST, 'millisecond') >
								getEnvironment().settings.views.gabby.chat.timestampThreshold
							) {
								this.datePostRenders.set(indexMessage.message_id, {
									key: `${indexMessage.message_id}.${PostMessageRenderType.TimeMarker}.${prevST.epoch}`,
									date: prevST,
									type: PostMessageRenderType.TimeMarker,
								});
							}

							if (indexST.diff(prevST, 'day') > 0) {
								this.datePostRenders.set(indexMessage.message_id, {
									key: `${indexMessage.message_id}.${PostMessageRenderType.DateMarker}.${prevST.epoch}`,
									date: prevST,
									type: PostMessageRenderType.DateMarker,
								});
							}
						}

						previousMessage = indexMessage;
					}

					// Render the messages
					this.calculateMessagePostRenders(this.messages, newMessages);
					this.messages = newMessages;
					this.hasInitialized = true;

					this.cdr.markForCheck();

					// Mark the message as read
					const lastMessage =
						newMessages.length > 0 ? newMessages[newMessages.length - 1] : null;
					if (
						lastMessage &&
						newMessages
							.slice(newMessages.length - 25)
							.some((m) => !m.message_read) &&
						canMarkReadCurrentChannel
					) {
						this.userActivityService.isActive$
							.pipe(
								tap((x) => {
									if (!x) {
										messageReadDelay =
											getEnvironment().settings.services.wamp.gabby
												.readChannelDelay;
									}
								}),
								switchMap((x) => timer(messageReadDelay).pipe(map(() => x))),
								filter((x) => x),
								take(1),
								takeUntil(
									race(this._unsubscribe$, this.currentState$, nextMessages$),
								),
							)
							.subscribe(() => {
								messageReadDelay = 0;

								// We are marking the message as read, no stopping
								this.messagesService
									.markRead(
										this.currentState.directoryId,
										this.currentState.channelId,
										lastMessage.message_id,
									)
									.subscribe();
							});
					}
				}),
				tap((messages) => {
					if (firstMessageLoad === false) {
						firstMessageLoad = true;

						// On first load, if we've only got a few messages, let's try to load more
						// It means we received a few new messages but we might not have enough for scrolling
						if (
							messages.length <
							getEnvironment().settings.services.store.message.loadcount
						) {
							this.loadMessagesSubject.next(null);
						}
					}
				}),
				takeUntil(race(this._unsubscribe$, this.currentState$)),
			)
			.subscribe();
	};

	private calculateMessagePostRenders = (
		oldMessages: MessageModel[],
		newMessages: MessageModel[],
	) => {
		// Lock in the unread marker
		this.unreadMarkerPostRenderMessageId =
			this.unreadMarkerPostRenderMessageId || true;

		// Clear the post render of the last message
		if (
			oldMessages != null &&
			this.messagePostRenders != null &&
			oldMessages[oldMessages.length - 1] != null
		) {
			delete this.messagePostRenders[
				oldMessages[oldMessages.length - 1].message_id
			];
		}

		// Calculate the rest
		newMessages.forEach((message) => {
			if (this.messagePostRenders[message.message_id] == null) {
				const postRenders: (
					| PostMessageRender
					| DatePostMessageRender
					| AttachmentPostMessageRender
				)[] = [];

				if (message.message_attachments != null) {
					message.message_attachments.forEach((a, i) => {
						const getRenderType = (
							attachementType: AttachementType,
						): PostMessageRenderType => {
							switch (attachementType) {
								case AttachementType.image:
									return PostMessageRenderType.AttachmentImage;
								case AttachementType.video:
									return PostMessageRenderType.AttachmentVideo;
								default:
									return PostMessageRenderType.AttachmentFile;
							}
						};

						postRenders.push({
							key: `${message.message_id}.${getRenderType(a.type)}.${
								a.filename
							}`,
							type: getRenderType(a.type),
							url: a.url,
							filename: a.filename,
							index: i,
						});
					});
				}

				if (message.message_id === this.unreadMarkerPostRenderMessageId) {
					// There can be only one, so flush any old markers
					for (const key in this.messagePostRenders) {
						if (this.messagePostRenders.hasOwnProperty(key)) {
							const index = this.messagePostRenders[key].findIndex(
								(x) => x.type === PostMessageRenderType.UnreadMarker,
							);

							if (index !== -1) {
								this.messagePostRenders[key].splice(index, 1);
							}

							// Force a rerender by changing the object
							this.messagePostRenders[key] =
								this.messagePostRenders[key].slice();
						}
					}

					postRenders.push({
						key: `${message.message_id}.unreadmarker`,
						type: PostMessageRenderType.UnreadMarker,
					});
				}

				const datePostRender = this.datePostRenders.get(message.message_id);
				if (datePostRender != null) {
					postRenders.push(datePostRender);
				}

				this.messagePostRenders[message.message_id] = postRenders as any; // Casting to any so we match the template type
			}
		});
	};

	public isCurrentParticipant = () => {
		return (
			this.currentChannel?.participants.find(
				(p) => p.link_id === this.currentUserLinkId,
			) ?? false
		);
	};

	@HostListener('drop', ['$event'])
	public onDrop = (event: DragEvent): void => {
		const files = getFilesFromDrop(event);
		if (files.length > 0) {
			this.messageForm.controls.attachments.setValue(files);
			this.openMessageBuilder();
		}
	};

	public attachFiles = (e: Event, element: HTMLInputElement) => {
		getFilesFromInput$(
			e,
			element,
			getEnvironment().settings.views.gabby.message.maxAttachmentSize,
		).subscribe((updatedFiles) => {
			this.messageForm.controls.attachments.setValue(updatedFiles);
			this.openMessageBuilder();
		});
	};

	public messagePaste = (e: ClipboardEvent) => {
		const file = getFileFromPaste(e);

		// load image if there is a pasted image
		if (file !== null) {
			this.messageForm.controls.attachments.setValue([file]);
			this.openMessageBuilder();
		}

		this.updateChatHeight();
	};

	public openMessageBuilder = () => {
		const modalRef = this.modalService.open(GabbyMessageBuilderComponent);

		(modalRef.componentInstance as GabbyMessageBuilderComponent).bindModalData({
			messageForm: this.messageForm,
		});

		this.currentState$.pipe(take(1)).subscribe(() => {
			modalRef.close(false);
		});

		modalRef.result
			.then((send) => {
				if (send) {
					this.sendMessage();
				}
			})
			.catch(() => {
				this.resetMessageForm(true);
			});
	};

	public sendMessage = () => {
		if (this.messageForm.valid) {
			const messageBody = {
				message: this.messageForm.controls.message.value,
				sms: this.messageForm.controls.sendSms.value,
				attachments: this.messageForm.controls.attachments.value,
			};

			SessionLogger.log('GabbyChatComponent', 'sendMessage', [
				this.currentState.directoryId,
				this.currentState.channelId,
				messageBody,
			]);

			this.messagesService
				.postMessage(
					this.currentState.directoryId,
					this.currentState.channelId,
					messageBody,
				)
				.subscribe({
					error: (err) => {
						this.toastrService.error(`Could not save message. ${err.message}`);
					},
				});

			this.resetMessageForm(true);
		}
	};

	public messagesTrackBy = (_index: number, item: MessageModel) => {
		return item.message_id;
	};

	public messageInputKeyDown = (event: KeyboardEvent) => {
		if (event.key === Key.Enter && !event.shiftKey) {
			this.sendMessage();
			event.preventDefault();
		} else if (this._ignoredKeys.indexOf(event.key) === -1) {
			this.updateChatHeight();
		}
	};

	private resetMessageForm = (flushDraft: boolean) => {
		if (this.messageForm != null) {
			this.messageForm.reset(this.messageFormDefaults);
			this.messageForm.controls.sendSms.disable();

			this.canEnableSms$(this.currentChannel)
				.pipe(takeUntil(race(this._unsubscribe$, this.currentState$)))
				.subscribe((x) => {
					if (x) {
						this.messageForm.controls.sendSms.enable();
					}
				});
		}

		// Reset size back to min
		if (this.chatTextarea != null) {
			this.chatTextarea.nativeElement.style.height = null;
		}

		if (flushDraft && this.currentState != null) {
			this.messageDraftStore.updateDraft(this.currentState.channelId, null);
		}

		this.onMessageFormReset();
	};

	private updateChatHeight = () => {
		setTimeout(() => {
			if (this.chatTextarea == null) return;
			// Remove the min so it rescales back to minimal
			this.chatTextarea.nativeElement.style.height = null;

			// Now push to just enough
			if (
				this.chatTextarea.nativeElement.scrollHeight >
				this.chatTextarea.nativeElement.clientHeight
			) {
				this.chatTextarea.nativeElement.style.height = `${
					this.chatTextarea.nativeElement.scrollHeight + 16
				}px`;
			}
		});
	};

	ngAfterViewChecked(): void {
		if (!this._selectedChatText && this.chatTextarea != null) {
			this._selectedChatText = true;
			this.chatTextarea.nativeElement.focus();
			this.chatTextarea.nativeElement.select();
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
