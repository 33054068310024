import { Expose, Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class AbDrillDownLaContactAttemptModel {
	student_uid: number;
	student_name: string;
	log_id: number;
	@Transform(dateTransformer)
	@Expose({ name: 'updated_timestamp' })
	@Transform(dateTransformer)
	updated: Date;
	@Expose({ name: 'created_timestamp' })
	@Transform(dateTransformer)
	created: Date;
	is_twoway: boolean;
	medium: string;
	subject: string;
}
