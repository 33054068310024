import { Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';
import {
	ProgramEditRequestFieldsModel_ClassTransform_Type,
	ProgramManagementDataModel,
	ProgramManagementDataUserModel,
	ProgramManagementDataUserModel_ClassTransform_Type,
} from '../program-management-data.model';

export class ResolutionRequestModel {
	@Type(ProgramManagementDataUserModel_ClassTransform_Type)
	by: ProgramManagementDataUserModel;
	@Transform(dateTransformer)
	on: Date;
	reason: string;
}

export function resolutionRequestModel_ClassTransform_Type() {
	return ResolutionRequestModel;
}

export class ProgramEditRequestModel {
	id: number;
	status: ProgramEditRequestStatus;
	entity_type: string;
	@Transform(integerTransformer)
	entity_id: number;
	@Type(ProgramEditRequestFieldsModel_ClassTransform_Type)
	fields: ProgramManagementDataModel;
	@Transform(dateTransformer)
	requested_on: Date;
	@Type(ProgramManagementDataUserModel_ClassTransform_Type)
	assigned_to: ProgramManagementDataUserModel;
	@Type(ProgramManagementDataUserModel_ClassTransform_Type)
	requested_by: ProgramManagementDataUserModel;
	requested_reason: string;
	@Type(resolutionRequestModel_ClassTransform_Type)
	resolution: ResolutionRequestModel;
}

export class ProgramEditRequestValidateModel {
	success: boolean;
	error_messages?: string[];
}

export class ProgramEditRequestListModel extends ProgramEditRequestModel {
	entity_title: string;
}

export enum ProgramEditRequestStatus {
	pending = 0,
	completed = 1,
	cancelled = 2,
}
