import { Injectable, OnDestroy } from '@angular/core';
import { AsyncSubject, combineLatest } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import spacetime from 'spacetime';
import { getEnvironment } from 'src/lib/environment/environment';
import {
	ChannelParticipantModel,
	externalChannelParticipantRoles,
} from 'src/lib/services/api/gabby/channels/channel-participant.model';
import { MessagePlatform } from 'src/lib/services/api/gabby/messages/message-platform.enum';
import { MessageModel } from 'src/lib/services/api/gabby/messages/message.model';
import { ChannelStoreService } from 'src/lib/services/stores/gabby/channel-store/channel-store.service';
import { MessageStoreService } from 'src/lib/services/stores/gabby/message-store/message-store.service';
import { OnlineStatusStoreService } from 'src/lib/services/stores/online-status-store/online-status-store.service';
import { OnlineStatus } from 'src/lib/services/stores/online-status-store/online-status.enum';
import { GabbyWampEventFactoryService } from 'src/lib/services/wamp/wamp-event-factory/gabby/gabby-wamp-event-factory.service';
import { isNullOrEmptyString } from 'src/lib/utilities/compare';

@Injectable({
	providedIn: 'root',
})
export class GabbyOfflineResponderService implements OnDestroy {
	private _unsubscribe$ = new AsyncSubject<null>();

	private _handledChannels = new Map<string | number, Date>();

	constructor(
		private wefs: GabbyWampEventFactoryService,
		private channelStore: ChannelStoreService,
		private messageStore: MessageStoreService,
		private onlineStatusStore: OnlineStatusStoreService,
	) {}

	public init$ = (studentLinkId: number, directoryId: string) => {
		return this.wefs.newMessage$().pipe(
			filter((nm) => {
				const studentCreated =
					nm.message.message_sender_link_id === studentLinkId;
				const inStudentDirectory = nm.message.directory === directoryId;

				return studentCreated && inStudentDirectory;
			}),
			mergeMap((nm) => {
				return this.channelStore
					.channel$(nm.channelId, directoryId)
					.pipe(take(1));
			}),
			mergeMap((channel) => {
				const viableParticipants = channel.participants.filter(
					(p) =>
						p.link_id !== studentLinkId &&
						!isNullOrEmptyString(p.offline_message) &&
						externalChannelParticipantRoles.indexOf(p.role_id) === -1,
				);

				const possibleReplies = viableParticipants.map((p) =>
					this.onlineStatusStore.userOnlineStatusVerified$(p.link_id),
				);

				return combineLatest(possibleReplies).pipe(
					take(1),
					map((statuses) => {
						const someoneOnline = statuses.some(
							(s) => s !== OnlineStatus.offline,
						);

						if (!someoneOnline) {
							const now = new Date();
							const shouldSend = this._handledChannels.has(channel.id)
								? spacetime(this._handledChannels.get(channel.id))
										.add(
											getEnvironment().settings.views.gabby.chat
												.autoResponseTimeout,
											'millisecond',
										)
										.isBefore(now)
								: true;

							if (shouldSend) {
								this.sendOfflineMessage(
									directoryId,
									channel.id,
									viableParticipants[0],
								);
							}
						}
					}),
				);
			}),
			takeUntil(this._unsubscribe$),
		);
	};

	private sendOfflineMessage = (
		directoryId: string,
		channelId: number,
		participant: ChannelParticipantModel,
	) => {
		const message = new MessageModel();
		this._handledChannels.set(channelId, new Date());

		message.channel_id = channelId;
		message.directory = directoryId;
		message.message_id = -1;
		message.created = new Date();
		message.message_sender_link_id = 1;
		message.message_read = true;
		message.message_send_medium = MessagePlatform.system;

		message.message_attachments = [];
		message.delivery_data = [];
		message.sms_to_numbers = [];
		message.message = `<div class="border-bottom mb-3"><div class="fs-xs text-red">Automated Response</div><div>${participant.name}</div></div>${participant.offline_message}`;
		message.html_message = true;

		this.messageStore.addFakeMessage(directoryId, channelId, message);
	};

	ngOnDestroy() {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
