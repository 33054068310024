import { Expose, Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	percentageTransformer,
} from 'src/lib/utilities/api/class-transformer-types';
import {
	PhoneModel,
	PhoneModel_ClassTransformType,
} from '../relationships/student-relationships.model';
import { CourseStatus } from './student-course-status.enum';

export class StudentCourseAssignmentsModel {
	completed: number;
	total: number;
}
export function StudentCourseAssignmentsModel_ClassTransform_Type() {
	return StudentCourseAssignmentsModel;
}

export class StudentCourseLmsModel {
	id: number;
	title: string;
	short: string;
	lms_registration_link?: string;
}
export function StudentCourseLmsModel_ClassTransform_Type() {
	return StudentCourseLmsModel;
}

export class StudentCourseTeacherModel {
	uid: number;
	name: string;
	email: string[];
	@Type(PhoneModel_ClassTransformType)
	phone: PhoneModel[];
	calendar: string[] | string;
	picture_path: string;
	relationship_type_name?: string = 'Teacher';
}
export function StudentCourseTeacherModel_ClassTransform_Type() {
	return StudentCourseTeacherModel;
}

export enum StudentRegistrationTypes {
	Regular = 0,
	Persistent = 1,
}

export class StudentCourseModel {
	registration_id: number;
	registration_type: StudentRegistrationTypes;
	title: string;
	@Transform(dateTransformer)
	requested_date: Date;
	notes: string;
	course_id: number;
	course_number: string;
	version_id: number;
	version_code: string;
	section_id: number;
	section_type: number;
	credits_possible: number;
	@Transform(dateTransformer)
	register_date: Date;
	@Transform(dateTransformer)
	counted_date: Date;
	@Transform(dateTransformer)
	completed_date: Date;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	target_end_date: Date;

	@Expose({ name: 'official_start_date' })
	@Transform(dateTransformer)
	official_start_date_actual: Date;

	@Expose({ name: 'official_due_date' })
	@Transform(dateTransformer)
	official_due_date_actual: Date;

	@Expose({ name: 'scheduled_start' })
	@Transform(dateTransformer)
	schedule_start_actual: Date;

	official_weeks: number;
	days_in_class: number;
	final_grade: string;
	final_locked: boolean;
	final_outstanding: boolean;
	current_grade: string;
	current_grade_percent: number;
	current_grade_percent_zero: number;
	@Transform(percentageTransformer)
	current_progress: number;
	status: CourseStatus;
	subsection: string;
	credits_earned: number;
	has_passing_grade: boolean;
	proctor_password: string;
	certificate_path?: string;

	@Type(StudentCourseLmsModel_ClassTransform_Type)
	lms: StudentCourseLmsModel;

	@Type(StudentCourseTeacherModel_ClassTransform_Type)
	teacher: StudentCourseTeacherModel;

	@Type(StudentCourseAssignmentsModel_ClassTransform_Type)
	assignments: StudentCourseAssignmentsModel;

	@Type(StudentCourseTeacherModel_ClassTransform_Type)
	communicator: StudentCourseTeacherModel;
}

export function StudentCourseModel_ClassTransform_Type() {
	return StudentCourseModel;
}

export class StudentCourseProgressAssignmentModel {
	completed: number;
	total: number;
}

export function StudentCourseProgressAssignmentModel_ClassTransform_Type() {
	return StudentCourseProgressAssignmentModel;
}

export class StudentCourseProgressModel {
	title: string;
	description: string;
	current_progress: number;
	target_end_date: number;
	@Type(StudentCourseProgressAssignmentModel_ClassTransform_Type)
	assignments: StudentCourseProgressAssignmentModel;
	current_grade_percent: number;
	current_grade_percent_zero: number;
}

export function StudentCourseProgressModel_ClassTransform_Type() {
	return StudentCourseProgressModel;
}

export class StudentCourseActivityFolderMetadataModel {
	title: string;
	open: boolean;
	past_due: boolean;
}

export function StudentCourseActivityFolderMetadataModel_ClassTransform_Type() {
	return StudentCourseActivityFolderMetadataModel;
}

export class StudentCourseActivityViewTimeModel {
	@Transform(dateTransformer)
	date: Date;
	timezone: string;
	timezone_type: number;
}

export function StudentCourseActivityViewTimeModel_ClassTransform_Type() {
	return StudentCourseActivityViewTimeModel;
}

export class StudentCourseActivityFolderModel {
	allow_retry?: boolean;
	retry_implemented?: boolean;
	completed?: boolean;
	@Transform(dateTransformer)
	due_date?: Date;
	graded_percent?: number;
	is_excused?: boolean;
	is_passing?: boolean;
	pending_grade?: boolean;
	teacher_feedback?: string;
	uncompleted_assignment?: boolean;

	link_type: string;
	embed_link: string;
	folder_open: boolean;
	id: string;
	link: string;
	locked: boolean;
	name: string;
	start_assignment: boolean;
	type: 'lesson' | 'assignment';
	actual_type: string;
	past_due: boolean;

	points_possible?: number;
	points_earned?: number;

	@Transform(dateTransformer)
	submitted_date?: Date;

	@Type(StudentCourseActivityViewTimeModel_ClassTransform_Type)
	last_view_time?: StudentCourseActivityViewTimeModel;

	is_teacher_graded: boolean;
}

export function StudentCourseActivityFolderModel_ClassTransform_Type() {
	return StudentCourseActivityFolderModel;
}

export class StudentCourseActivitiesFolderModel {
	@Type(StudentCourseActivityFolderMetadataModel_ClassTransform_Type)
	metadata: StudentCourseActivityFolderMetadataModel;
	@Type(StudentCourseActivityFolderModel_ClassTransform_Type)
	activities: StudentCourseActivityFolderModel[];
}

export function StudentCourseActivitiesFolderModel_ClassTransform_Type() {
	return StudentCourseActivitiesFolderModel;
}

export class StudentCourseActivityTeacherModel {
	id: number;
	name: string;
	picture_path: string;
	intro_video: string;
}

export function StudentCourseActivityTeacherModel_ClassTransform_Type() {
	return StudentCourseActivityTeacherModel;
}

export class StudentCourseActivitiesModel {
	message?: string;
	success?: boolean;
	token_url: string;
	next_token_time: number;
	final_locked?: boolean;
	external_id?: string;
	@Type(StudentCourseProgressModel_ClassTransform_Type)
	course: StudentCourseProgressModel;
	@Type(StudentCourseActivityTeacherModel_ClassTransform_Type)
	teacher: StudentCourseActivityTeacherModel;
	@Type(StudentCourseActivitiesFolderModel_ClassTransform_Type)
	folders: StudentCourseActivitiesFolderModel[];
}

export function StudentCourseActivitiesModel_ClassTransform_Type() {
	return StudentCourseActivitiesModel;
}
