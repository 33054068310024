import { ErrorHandler, Injectable } from '@angular/core';
import { ExpectedError } from 'src/lib/types/expected-error';
import { SessionLogger } from 'src/lib/utilities/logging/session-logger';

@Injectable()
export class LoggingErrorHandler extends ErrorHandler {
	constructor() {
		super();
	}

	handleError(error) {
		if (error instanceof ExpectedError) {
			return;
		}

		if (error != null) {
			SessionLogger.log(
				'UNHANDLED EXCEPTION',
				error,
				error.zoneAwareStack || error.stack,
			);
		}

		super.handleError(error);
	}
}
