export const meetupTitles = {
	community_event: 'Community Meetup',
	meetup: 'Social Meetup',
	study_session: 'Study Meetup',
	tutoring: 'Tutoring Meetup',
	strong_start: 'Strong Start Meetup',
	one_one: '1:1 Meetup',
	one_one_strong_start: '1:1 Strong Start Meetup',
};

export const meetupFrequencyTitles = {
	one_time: 'One Time',
	weekly: 'Weekly',
	bi_weekly: 'Bi-Weekly',
};

export const meetupFrequencyType = {
	one_time: 'one_time',
	weekly: 'weekly',
	bi_weekly: 'bi_weekly',
};

export const meetupPurposeOptions = [
	{ value: 1, label: 'Instruction' },
	{ value: 2, label: 'Academic Counseling' },
	{ value: 3, label: 'Career Counseling' },
	{ value: 4, label: 'Case Management' },
];
