import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentAssetShipmentModel {
	id: string;
	shipment_method: string;
	@Expose({ name: 'shipped_timestamp' })
	@Transform(dateTransformer)
	shipped_date: Date;
	@Expose({ name: 'received_timestamp' })
	@Transform(dateTransformer)
	received_date: Date;
}

export function StudentAssetShipmentModel_ClassTransform_Type() {
	return StudentAssetShipmentModel;
}

export class StudentAssetModel {
	@Transform(dateTransformer)
	date_assigned: Date;
	type_of_asset: string;
	status_id: number;
	status: string;
	serial_number: string;
	imei: number;
	wep_key: string;
	asset_panda_link: string;
	model: string;
	manufacturer: string;
	tracking: number;
}

export function StudentAssetModel_ClassTransform_Type() {
	return StudentAssetModel;
}

export class StudentPhysicalAssetsModel {
	@Type(StudentAssetModel_ClassTransform_Type)
	assigned_assets: StudentAssetModel[];
	@Type(StudentAssetModel_ClassTransform_Type)
	shipping_assets: StudentAssetModel[];
}
