import { Type } from 'class-transformer';

export class MeetupHostOptionModel {
	uid: number;
	name: string;
	roles: string[];
}

export function MeetupHostOptionModel_ClassTransform_Type() {
	return MeetupHostOptionModel;
}

export class MeetupManagementOptionsModel {
	@Type(MeetupHostOptionModel_ClassTransform_Type)
	host: MeetupHostOptionModel[];
}
