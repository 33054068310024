<ng-select
	bindValue="id"
	loadingText="Loading Programs"
	[appendTo]="'body'"
	class="{{ class }}"
	[clearable]="clearable"
	[formControl]="orgControl"
	[items]="_organizations"
	labelForId="program.single.select.component.{{ id }}"
	[loading]="organizations == null || reloadingOrgs"
	[placeholder]="placeholder"
	[searchFn]="orgsSearchFn"
	[virtualScroll]="true"
>
	<ng-template let-item="item" ng-label-tmp>
		<div>
			<div
				class="program-title"
				[ngClass]="
					item.is_active === false ? 'text-decoration-line-through' : ''
				"
			>
				{{ item.title }}
			</div>
			@if (showFullLabel) {
				<div class="fs-sm">
					{{ item.parent_title }}
				</div>
			}
			@if (showFullLabel) {
				<div class="fs-xs fw-bold">
					{{ item.subtitle }}
				</div>
			}
		</div>
	</ng-template>
	<ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
		<div>
			<div
				class="program-title"
				[ngClass]="
					item.is_active === false ? 'text-decoration-line-through' : ''
				"
			>
				{{ item.title }}
			</div>
			<div class="fs-sm">
				{{ item.parent_title }}
			</div>
			<div class="fs-xs fw-bold">
				{{ item.subtitle }}
			</div>
		</div>
	</ng-template>
</ng-select>
