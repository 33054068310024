import { Type } from 'class-transformer';
import {
	OrganizationStudentEditFieldsModel,
	OrganizationStudentEditFieldsModel_ClassTransform_Type,
} from './organization-student-edit-fields.model';

export class OrganizationStudentEditFieldsConfigurationModel extends OrganizationStudentEditFieldsModel {
	@Type(OrganizationStudentEditFieldsModel_ClassTransform_Type)
	tier_fields: Map<string, OrganizationStudentEditFieldsModel>;
}
