import { Transform } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';

export class StudentCareerPlanModel {
	plan_attend_college: boolean;
	plan_want_help: boolean;
	plan_want_help_reason: string;
	@Transform(dateTransformer)
	completed_industry_credentials: Date;
	notes: string;
	@Transform(integerTransformer)
	plan_desired_career: number;
	plan_desired_career_name: string;
	@Transform(integerTransformer)
	career_pathway: number;
	career_pathway_name: string;
}

export interface StudentCareerPlanArgument {
	plan_attend_college: boolean;
	plan_want_help: boolean;
	plan_want_help_reason: string;
	completed_industry_credentials: string;
	notes: string;
	plan_desired_career: number;
}
