import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	PagingSearchFieldArgument,
	PagingSearchFieldArrayArgument,
} from 'src/lib/utilities/api/patterns/pagination/paging-search-field.argument';

export class PaperworkManagerCurrentModel {
	first_name: string;
	last_name: string;
	uid: number;
	institute_id: number;
	institute_title: string;
	institute_type: string;
	institute_state: string;
	enrollment_status: number;
	enrolled_date: string;
	activated_date: string;
	withdrawn_date: string;
	file_is_enrollment_requirement: boolean;
	file_id: string;
	file_description: string;
	@Transform(dateTransformer)
	file_expires_date: Date;
	@Transform(dateTransformer)
	file_uploaded_date: Date;
	file_status: string;
	file_url: string;
	paperwork_request_id: number;
	paperwork_request_verified_by_name: string;
	paperwork_request_verified_by_uid: number;
}

export function PaperworkManagerCurrentModel_ClassTransform_Type() {
	return PaperworkManagerCurrentModel;
}

export class PaperworkManagerCurrentResponseModel {
	@Type(PaperworkManagerCurrentModel_ClassTransform_Type)
	results: PaperworkManagerCurrentModel[];
	count: number;
}

export interface PaperworkManagerCurrentSearchArgument {
	first_name: PagingSearchFieldArgument;
	last_name: PagingSearchFieldArgument;
	uid: PagingSearchFieldArgument;
	institute_id: PagingSearchFieldArrayArgument;
	institute_title: PagingSearchFieldArgument;
	institute_type: PagingSearchFieldArrayArgument;
	institute_state: PagingSearchFieldArrayArgument;
	enrolled_date: PagingSearchFieldArgument;
	file_description: PagingSearchFieldArgument;
	file_expires_date: PagingSearchFieldArgument;
	file_is_enrollment_requirement: { value: boolean } | null;
	file_is_current: { value: boolean } | null;
	enrollment_status: PagingSearchFieldArrayArgument;
}

export interface PaperworkManagerExpireArgument {
	file_ids: string[];
	expiration_timestamp?: number;
}

export interface PaperworkManagerNotifyStaffArgument {
	file_ids: string[];
	relationship_types: number[];
	message: string;
}
