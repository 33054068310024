import { Injectable, SecurityContext } from '@angular/core';
import { Observable, map } from 'rxjs';
import { NgDompurifySanitizer } from 'src/lib/thirdparty/ng-dompurify/ng-dompurify.service';
import { BehaviorCache } from 'src/lib/utilities/cache';
import { isNonEmptyString } from 'src/lib/utilities/compare';
import { firstBy } from 'thenby';
import { PermissionByRoleModel } from '../../api/permission-manager/permission-by-role.model';
import {
	PermissionEditOptionsModel,
	PermissionModel,
} from '../../api/permission-manager/permission-edit-options.model';
import { PermissionManagerService } from '../../api/permission-manager/permission-manager.service';
import {
	PermissionOverrideType,
	PermissionProgramOverrideModel,
} from '../../api/permission-manager/permission-program-override.model';

@Injectable({
	providedIn: 'root',
})
export class PermissionManagerStoreService {
	private _editOptionsCache = new BehaviorCache<1, PermissionEditOptionsModel>(
		() =>
			this.permissionManagerService.getEditOptions().pipe(
				map((r) => {
					r.permissions = r.permissions
						.map((x: PermissionModel) => {
							x.title = this.ngDompurifySanitizer.sanitize(
								SecurityContext.HTML,
								x.title,
								{ ALLOWED_TAGS: [] },
							);

							x.description = isNonEmptyString(x.description)
								? this.ngDompurifySanitizer.sanitize(
										SecurityContext.HTML,
										x.description,
										{ ALLOWED_TAGS: [] },
									)
								: null;

							return x;
						})
						.sort(firstBy((x) => x.title));
					r.roles.sort(firstBy((x) => x.title));
					r.institute_itypes.sort(firstBy((x) => x.title));
					return r;
				}),
			),
		'PermissionManagerStoreService EditOptionsCache',
		() => null,
	);

	private _permissionByRoleCache = new BehaviorCache<
		1,
		PermissionByRoleModel[]
	>(
		() => this.permissionManagerService.getPermissionsByRole(),
		'PermissionManagerStoreService permissionByRoleCache',
		() => [],
	);

	private _programOverrideCache = new BehaviorCache<
		1,
		PermissionProgramOverrideModel[]
	>(
		() =>
			this.permissionManagerService.getProgramOverrides(
				PermissionOverrideType.institute,
			),
		'PermissionManagerStoreService permissionByRoleCache',
		() => [],
	);

	private _programTypeOverrideCache = new BehaviorCache<
		1,
		PermissionProgramOverrideModel[]
	>(
		() =>
			this.permissionManagerService.getProgramOverrides(
				PermissionOverrideType.itype,
			),
		'PermissionManagerStoreService permissionByRoleCache',
		() => [],
	);

	constructor(
		private permissionManagerService: PermissionManagerService,
		private ngDompurifySanitizer: NgDompurifySanitizer,
	) {}

	/**
	 * Options
	 */
	public editOptions$ = (): Observable<PermissionEditOptionsModel> => {
		return this._editOptionsCache.getCache(1);
	};

	public refreshEditOptions = (): Promise<boolean> => {
		return this._editOptionsCache.fetchData(1, true);
	};

	/**
	 * PermissionsByRole
	 */
	public permissionsByRole$ = (): Observable<PermissionByRoleModel[]> => {
		return this._permissionByRoleCache.getCache(1);
	};

	public refreshPermissionsByRole = (): Promise<boolean> => {
		return this._permissionByRoleCache.fetchData(1, true);
	};

	/**
	 * ProgramOverrides
	 */
	public programOverrides$ = (): Observable<
		PermissionProgramOverrideModel[]
	> => {
		return this._programOverrideCache.getCache(1);
	};

	public refreshProgramOverrides = (): Promise<boolean> => {
		return this._programOverrideCache.fetchData(1, true);
	};

	/**
	 * ProgramTypeOverrides
	 */
	public programTypeOverrides$ = (): Observable<
		PermissionProgramOverrideModel[]
	> => {
		return this._programTypeOverrideCache.getCache(1);
	};

	public refreshProgramTypeOverrides = (): Promise<boolean> => {
		return this._programTypeOverrideCache.fetchData(1, true);
	};
}
