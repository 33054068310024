import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BehaviorSubject,
	Observable,
	Subject,
	combineLatest,
	filter,
	of,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';
import { Permits } from 'src/lib/constants/permissions';
import { ExternalAccountModel } from 'src/lib/services/api/external-accounts/external-account.model';
import { ExternalAccountsService } from 'src/lib/services/api/external-accounts/external-accounts.service';
import { ParentStudentModel } from 'src/lib/services/api/parents/parent-students.model';
import { UserUpdateRequestOptions } from 'src/lib/services/api/users/update-request/users-update-request.model';
import { UsersUpdateRequestsService } from 'src/lib/services/api/users/update-request/users-update-requests.service';
import {
	UserDataGAModel,
	UserDataModel,
	UserDataProfileModel,
	UserDataUserModel,
} from 'src/lib/services/api/users/user-data.model';
import { UsersService } from 'src/lib/services/api/users/users.service';
import { ParentsStoreService } from 'src/lib/services/stores/parents/parents-store.service';
import { PermissionStoreService } from 'src/lib/services/stores/permission-store/permission-store.service';
import { StringEnumOption } from 'src/lib/utilities/api/patterns/dynamic-fields/string-enum-option.model';
import { isNullOrEmptyString } from 'src/lib/utilities/compare';
import { convertToInt } from 'src/lib/utilities/convert';
import { firstBy } from 'thenby';
import { SpinWhileDirective } from '../../../../templates/layout/spin-while/spin-while.directive';
import { ProfileAccountEditComponent } from './profile-account-edit/profile-account-edit.component';
import { ProfileAccountViewComponent } from './profile-account-view/profile-account-view.component';

@Component({
	selector: 'ae-profile-account',
	templateUrl: './profile-account.component.html',
	styleUrls: ['./profile-account.component.scss'],
	standalone: true,
	imports: [
		SpinWhileDirective,
		ProfileAccountViewComponent,
		ProfileAccountEditComponent,
	],
})
export class ProfileAccountComponent implements OnInit, OnDestroy {
	private _unsubscribe$ = new Subject<null>();

	private _permissionsLoaded = new BehaviorSubject<boolean>(false);

	public loading: boolean = true;
	public errorLoading: boolean = false;
	public savingInfo: boolean = false;

	public editEnabled: boolean = false;
	public isAdminAccount: boolean = false;

	public currentUserId: number;

	public accountData: UserDataUserModel;
	public profileData: UserDataProfileModel;
	public gaData: UserDataGAModel;
	public accountOptions: UserUpdateRequestOptions;
	public externalAccounts: ExternalAccountModel[];

	public formattedAdminTypeList: StringEnumOption[] = [];
	public formattedAccessType: string[] = [];
	public assignedStudents: string[] = null;

	public existingPendingChange: boolean = false;

	public canViewEmployeeID: boolean = false;
	public canEditEmployeeID: boolean = false;
	public canViewLinkedAccounts: boolean = false;
	public canEditLinkedAccounts: boolean = false;
	public canEditRoles: boolean = false;

	constructor(
		private userService: UsersService,
		private route: ActivatedRoute,
		private userRequestService: UsersUpdateRequestsService,
		private permissionService: PermissionStoreService,
		private parentsStoreService: ParentsStoreService,
		private externalAccountsService: ExternalAccountsService,
	) {}

	ngOnInit() {
		this.route.params.subscribe((p) => {
			this.currentUserId = convertToInt(p.userId);
			this.getAccountInfo();
		});

		this.permissionService
			.getFieldSet$()
			.pipe(
				tap((r) => {
					this.canViewEmployeeID = r.canDo(Permits['ga_user|view employee id']);
					this.canEditEmployeeID = r.canDo(Permits['ga_user|edit employee id']);
					this.canViewLinkedAccounts = r.canDo(
						Permits['ga_lms|view linked accounts'],
					);
					this.canEditLinkedAccounts = r.canDo(
						Permits['ga_lms|create linked account'],
					);
					this.isAdminAccount = r.canDo(
						Permits['ga_user|bypass user update request'],
					);
					this.canEditRoles = r.canDoAny(
						Permits['administer permissions'],
						Permits['ga_user|edit standard permissions'],
					);
				}),
			)
			.subscribe(() => this._permissionsLoaded.next(true));
	}

	public enableEdit = () => {
		this.editEnabled = !this.editEnabled;
	};

	public accountSaveListener = (forceRefresh: boolean = true) => {
		this.editEnabled = false;
		if (forceRefresh) {
			this.getAccountInfo();
		}
	};

	public getAccountInfo = () => {
		this.loading = true;
		this.formattedAdminTypeList = [];
		this.formattedAccessType = null;
		this.assignedStudents = null;

		this._permissionsLoaded
			.pipe(
				filter((x) => x),
				take(1),
				switchMap(() =>
					combineLatest([
						this.userService.getUserData(this.currentUserId).pipe(
							switchMap((userData) => {
								const obs$: Observable<UserDataModel | ParentStudentModel[]>[] =
									[of(userData)];
								if (
									userData?.user?.roles &&
									userData.user.roles.indexOf('parent') !== -1
								) {
									this.assignedStudents = [];
									obs$.push(
										this.parentsStoreService.students$(this.currentUserId).pipe(
											tap((students) => {
												this.assignedStudents = (
													students as ParentStudentModel[]
												).map((s) => s.name);
											}),
										),
									);
								}
								return combineLatest(obs$);
							}),
						),
						this.userRequestService.getPendingRequests(this.currentUserId),
						this.userRequestService.getRequestsOptions(this.currentUserId),
						this.canViewLinkedAccounts
							? this.externalAccountsService.getExternalAccounts(
									this.currentUserId,
								)
							: of(null),
					]),
				),
				takeUntil(this._unsubscribe$),
			)
			.subscribe({
				next: ([
					userDataParentStudentModels,
					requests,
					requestOptions,
					externalAccounts,
				]) => {
					const userData = userDataParentStudentModels[0] as UserDataModel;
					this.accountData = userData.user;
					this.profileData = userData.profile;
					this.gaData = userData.ga;
					this.accountOptions = requestOptions.allowed_updates;
					this.existingPendingChange =
						requests.pending_update_requests?.length > 0;

					this.accountData?.admin_type?.forEach((type) => {
						const splitStrings = type.split('_');
						let formattedString = '';
						splitStrings.forEach((word, index, arr) => {
							if (!isNullOrEmptyString(word)) {
								formattedString = formattedString.concat(
									word.charAt(0).toUpperCase() +
										word.slice(1) +
										(arr.length - 1 === index ? '' : ' '),
								);
							}
						});
						this.formattedAdminTypeList.push({
							key: type,
							title: formattedString,
						});
					});

					this.formattedAccessType = this.accountData?.roles
						?.sort(firstBy((x) => x))
						.map((type) => {
							const splitStrings = type.split('_');
							let formattedString = '';
							splitStrings.forEach((word, index, arr) => {
								if (!isNullOrEmptyString(word)) {
									formattedString = formattedString.concat(
										word.charAt(0).toUpperCase() +
											word.slice(1) +
											(arr.length - 1 === index ? '' : ' '),
									);
								}
							});
							return formattedString;
						});

					this.externalAccounts = externalAccounts;

					this.loading = false;
				},
				error: (errors) => {
					console.error(errors);
					this.errorLoading = true;
				},
			});
	};

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
