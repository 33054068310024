import {
	AfterContentInit,
	ContentChildren,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	SimpleChanges,
} from '@angular/core';
import {
	AsyncSubject,
	Observable,
	Subject,
	Subscription,
	interval,
	merge,
	of,
	race,
} from 'rxjs';
import {
	catchError,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	skip,
	skipUntil,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { getEnvironment } from 'src/lib/environment/environment';
import { isNumber } from 'src/lib/utilities/compare';
import { scrollTo$ } from 'src/lib/utilities/html';
import { GabbyStateModel } from '../../gabby-state.model';
import { PostMessageRenderType } from '../gabby-chat-component-definitions';
import { GabbyChatMessagePostRenderDirective } from '../gabby-chat-message-row/gabby-chat-message-postrender.directive';
import { GabbyChatMessageRowComponent } from '../gabby-chat-message-row/gabby-chat-message-row.component';

export enum GabbyChatScrollManagerState {
	user = 'user',
	bottom = 'bottom',
	maintain = 'maintain',
}

@Directive({
	selector: '[aeGabbyChatScrollManager]',
	standalone: true,
})
export class GabbyChatScrollManagerDirective
	implements OnInit, OnChanges, AfterContentInit, OnDestroy
{
	private _unsubscribe$ = new AsyncSubject<any>();

	private static lastScrollTarget = new Map<string, [number, number]>();
	private static lastUnreadIndex = new Map<string, string>();

	private scrollEvent = new Subject<any>();

	private browserScroll$: Observable<any>;
	private curatedScrollSubject = new Subject<any>();
	private currentCuration: Subscription;

	@Input('aeGabbyChatScrollManager') stateModel: GabbyStateModel;
	@Input() hasInitialized: boolean;
	@Output() topReached = new EventEmitter<null>();

	@ContentChildren(GabbyChatMessageRowComponent)
	private messageRows: QueryList<GabbyChatMessageRowComponent>;

	private maintainState: GabbyChatScrollManagerState;
	private maintainTarget: number | [HTMLElement, number];
	private initializationComplete: boolean;
	private previousScrollHeight: number = 0;

	constructor(
		private elemRef: ElementRef<HTMLElement>,
		private renderer: Renderer2,
	) {}

	private maintainScroll = () => {
		if (this.maintainState === GabbyChatScrollManagerState.bottom) {
			this.scrollToCurated(
				this.elemRef.nativeElement.scrollHeight + 1,
				!this.initializationComplete,
			);
		} else if (
			this.maintainState === GabbyChatScrollManagerState.maintain &&
			this.maintainTarget != null
		) {
			this.scrollToCurated(this.maintainTarget, true);
		}
	};

	private scrollToCurated = (
		target: number | [HTMLElement, number],
		instant: boolean,
	) => {
		let scrollTarget: number;

		if (isNumber(target)) {
			scrollTarget = target;
		} else {
			scrollTarget = target[0].offsetTop - target[1];
		}

		const duration = instant ? 0 : 1000;
		this.scrollEvent.next(null);

		this.updateCuration(
			scrollTo$(this.elemRef.nativeElement, scrollTarget, duration).pipe(
				catchError(() => of(true)),
				takeUntil(race(this.scrollEvent, this._unsubscribe$)),
			),
		);
	};

	private updateCuration = (skipUntil$?: Observable<any>) => {
		if (this.currentCuration != null) {
			this.currentCuration.unsubscribe();
		}

		skipUntil$ = skipUntil$ || of(true);

		this.currentCuration = this.browserScroll$
			.pipe(
				skipUntil(skipUntil$),
				skip(1), // We skip one always to avoid next scroll errors, user scroll is usually multiple events anyway
				debounceTime(50),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.curatedScrollSubject.next(null);
			});
	};

	private calculateScrollState = () => {
		this.maintainState = GabbyChatScrollManagerState.user;

		const scrollBottom =
			this.elemRef.nativeElement.scrollTop +
			this.elemRef.nativeElement.clientHeight;

		if (
			this.elemRef.nativeElement.scrollHeight - scrollBottom <
				getEnvironment().settings.views.gabby.chat.scrollEdgeBuffer ||
			this.previousScrollHeight - scrollBottom <
				getEnvironment().settings.views.gabby.chat.scrollEdgeBuffer
		) {
			this.maintainState = GabbyChatScrollManagerState.bottom;
		}

		if (this.maintainState === GabbyChatScrollManagerState.user) {
			this.captureLastScrollTarget();
		} else {
			GabbyChatScrollManagerDirective.lastScrollTarget.set(
				this.getStateModelId(),
				null,
			);
		}

		if (
			this.elemRef.nativeElement.scrollTop <
			getEnvironment().settings.views.gabby.chat.scrollEdgeBuffer
		) {
			this.captureLastScrollTarget();
			this.topReached.next(null);
			this.lockonLastScrollTarget();
		}
	};

	private getStateModelId = () => {
		return this.stateModel == null
			? ''
			: `${this.stateModel.channelId}${this.stateModel.directoryId}`;
	};

	private captureLastScrollTarget = () => {
		const messageRows = this.messageRows.toArray();

		let messageRow: GabbyChatMessageRowComponent;
		for (const mr of messageRows) {
			if (
				mr.elemRef.nativeElement.offsetTop >=
				this.elemRef.nativeElement.scrollTop
			) {
				messageRow = mr;
				break;
			}
		}

		let scrollTarget: [number, number] = null;
		if (messageRow != null) {
			scrollTarget = [
				messageRow.message.message_id,
				messageRow.elemRef.nativeElement.offsetTop -
					this.elemRef.nativeElement.scrollTop,
			];
		}

		GabbyChatScrollManagerDirective.lastScrollTarget.set(
			this.getStateModelId(),
			scrollTarget,
		);
	};

	private lockonLastScrollTarget = () => {
		const lastScrollTarget =
			GabbyChatScrollManagerDirective.lastScrollTarget.get(
				this.getStateModelId(),
			);

		if (lastScrollTarget == null) return;
		const [scrollMessageId, scrollOffset] = lastScrollTarget;

		const scrollMessageRow = this.messageRows
			.toArray()
			.find((x) => x.message.message_id === scrollMessageId);

		if (scrollMessageRow != null) {
			this.maintainState = GabbyChatScrollManagerState.maintain;
			this.maintainTarget = [
				scrollMessageRow.elemRef.nativeElement,
				scrollOffset,
			];
		}
	};

	ngOnInit(): void {
		// Attach to scroll
		const browserScrollSubject = new Subject<any>();
		this.browserScroll$ = browserScrollSubject.asObservable();

		const scrollHandler = () => browserScrollSubject.next(null);
		this.elemRef.nativeElement.addEventListener('scroll', scrollHandler);

		this._unsubscribe$.subscribe(() => {
			this.elemRef.nativeElement.removeEventListener('scroll', scrollHandler);
		});

		this.curatedScrollSubject
			.asObservable()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => this.calculateScrollState());

		this.updateCuration();

		// Listen for sizing changes
		interval(5)
			.pipe(
				map(() => this.elemRef.nativeElement.scrollHeight),
				distinctUntilChanged(),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				this.previousScrollHeight = this.elemRef.nativeElement.scrollHeight;
				this.maintainScroll();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.stateModel) {
			if (this.stateModel != null) {
				this.maintainState = GabbyChatScrollManagerState.bottom;
			}
		}

		if (changes.hasInitialized) {
			this.initializationComplete = false;
			this.renderer.removeClass(this.elemRef.nativeElement, 'scroller-ready');

			if (changes.hasInitialized.currentValue) {
				interval(50)
					.pipe(
						tap(() => {
							this.renderer.addClass(
								this.elemRef.nativeElement,
								'scroller-ready',
							);
						}),
						map(() => {
							const messageRows = this.messageRows.toArray();

							for (const mr of messageRows) {
								let allReady = true;

								mr.postRendersActive.forEach((r) => {
									if (!r.isLoaded()) {
										allReady = false;
									}
								});

								if (!allReady) {
									return false;
								}
							}

							return true;
						}),
						filter((x) => x),
						take(1),
						delay(50),
						takeUntil(this._unsubscribe$),
					)
					.subscribe(() => {
						this.initializationComplete = true;
					});
			} else {
				this.renderer.addClass(this.elemRef.nativeElement, 'scroller-ready');
			}
		}
	}

	ngAfterContentInit(): void {
		merge(this.messageRows.changes, of(true))
			.pipe(
				filter(() => this.messageRows.length !== 0),
				take(1),
				takeUntil(this._unsubscribe$),
			)
			.subscribe(() => {
				const messageRows = this.messageRows.toArray();
				let unreadMarker: GabbyChatMessagePostRenderDirective;

				for (const mr of messageRows) {
					unreadMarker = mr.postRendersActive.find(
						(r) => r.renderType === PostMessageRenderType.UnreadMarker,
					);
					if (unreadMarker != null) {
						break;
					}
				}

				const unreadIndex = GabbyChatScrollManagerDirective.lastUnreadIndex.get(
					this.getStateModelId(),
				);
				const scrollIndex =
					GabbyChatScrollManagerDirective.lastScrollTarget.get(
						this.getStateModelId(),
					);

				if (
					(scrollIndex != null && unreadMarker == null) ||
					(scrollIndex != null &&
						(unreadIndex == null || unreadIndex === unreadMarker.renderId))
				) {
					this.lockonLastScrollTarget();
				} else if (unreadMarker != null) {
					this.maintainState = GabbyChatScrollManagerState.maintain;
					this.maintainTarget = [unreadMarker.elemRef.nativeElement, -1]; // The -1 sets us directly on the target
				}

				GabbyChatScrollManagerDirective.lastUnreadIndex.set(
					this.getStateModelId(),
					(unreadMarker || ({} as GabbyChatMessagePostRenderDirective))
						.renderId,
				);
			});

		this.messageRows.changes
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(() => {
				this.maintainScroll();
			});
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(null);
		this._unsubscribe$.complete();
		this._unsubscribe$ = null;
	}
}
