<ng-select
	labelForId="component.timezone.select"
	[clearable]="clearable"
	[formControl]="ctrl"
	[groupBy]="groupByFn"
	[groupValue]="groupValueFn"
	[items]="timezones"
	[loading]="timezones == null"
	[multiple]="false"
	[placeholder]="placeholder"
	[searchFn]="customSearchFn"
	[virtualScroll]="true"
>
	<ng-template let-item="item" ng-label-tmp>
		{{ item[labelName] ?? item }}
	</ng-template>
	<ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
		{{ item[labelName] ?? item }}
	</ng-template>
	<ng-template let-item="item" ng-optgroup-tmp>
		<div class="option-group-container">
			<span class="group-name fw-bold">
				{{ item.name }}
			</span>
		</div>
	</ng-template>
</ng-select>
