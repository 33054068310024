import { Transform, Type } from 'class-transformer';
import { integerTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentRelationshipsModel {
	uid: number;
	name: string;
	type: string;
	type_id: number;
	email: string[];
	@Type(PhoneModel_ClassTransformType)
	phone: PhoneModel[];
	calendar: string[];
	picture_path: string;
	is_active: boolean;
}

export class PhoneModel {
	@Transform(integerTransformer)
	value: number;
	type: string;
	sms: boolean;
}

export function PhoneModel_ClassTransformType() {
	return PhoneModel;
}
