import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import {
	CurriculumMappingTeacherEditDataArgument,
	CurriculumMappingTeacherEditDataModel,
	CurriculumMappingTeacherListItemModel,
	CurriculumMappingTeacherModel,
} from './curriculum-mapping-teacher.model';

@Injectable({
	providedIn: 'root',
})
export class CurriculumMappingTeachersService {
	constructor(private httpClient: HttpClient) {}

	public getTeacherList = (): Observable<
		CurriculumMappingTeacherListItemModel[]
	> => {
		return this.httpClient
			.get<any>(`/api/v1/teacher/mapping/teacher_list`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							CurriculumMappingTeacherListItemModel,
							r.teachers as any[],
						),
					{ errorCode: 'BF567DCA' },
				),
			);
	};

	public getTeacherListDetails = (
		uid: number,
		errMsg: string = 'There was an issue loading',
	): Observable<CurriculumMappingTeacherModel> => {
		return this.httpClient
			.get<any>(`/api/v1/teacher/mapping/teacher_list/${uid}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(CurriculumMappingTeacherModel, r.teacher),
					{ errorMessage: errMsg, errorCode: '61E87002' },
				),
			);
	};

	public getTeacherEditData = (
		uid: number,
	): Observable<CurriculumMappingTeacherEditDataModel> => {
		return this.httpClient
			.get<any>(`/api/v1/teacher/${uid}/data`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingTeacherEditDataModel, r.teacher),
					{ errorCode: 'F5D0E305' },
				),
			);
	};

	public editTeacherData = (
		uid: number,
		args: CurriculumMappingTeacherEditDataArgument,
	): Observable<CurriculumMappingTeacherEditDataModel> => {
		return this.httpClient
			.post<any>(`/api/v1/teacher/${uid}/data/edit`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(CurriculumMappingTeacherEditDataModel, r.teacher),
					{ errorCode: '4B011D99' },
				),
			);
	};
}
