import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class WithdrawRequestModel {
	request_id: number;
	@Transform(dateTransformer)
	requested_on: Date;
	requested_by: string;
	requested_by_name: string;
	exit_code: string;
	exit_text: string;
	exit_code_description: string;
	@Transform(dateTransformer)
	graduated_timestamp: Date;
	@Transform(dateTransformer)
	scheduled_withdrawal_timestamp: Date;
}

export function WithdrawRequestModel_ClassTransformType() {
	return WithdrawRequestModel;
}

export class WithdrawRequestResponseModel {
	success: boolean;

	@Type(WithdrawRequestModel_ClassTransformType)
	withdraw_request: WithdrawRequestModel;
}

export function WithdrawRequestResponseModel_ClassTransformType() {
	return WithdrawRequestResponseModel;
}
