import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentEnrollmentActivationItemModel {
	passed: boolean;
	title: string;
	type: string;
	type_title: string;
	can_waive: boolean;
	requires_file: boolean;
	file_url: string;
}

export function StudentEnrollmentActivationItemModel_ClassTransform_Type() {
	return StudentEnrollmentActivationItemModel;
}

export class StudentEnrollmentActivationItemResponseModel {
	@Type(StudentEnrollmentActivationItemModel_ClassTransform_Type)
	requirements: StudentEnrollmentActivationItemModel[];
	pending_activate: boolean;
	can_set_date: boolean;
}

export class CustomRequirementsModel {
	id: number;
	reason: string;
	@Transform(dateTransformer)
	created: Date;
}

export function CustomRequirementsModel_ClassTransform_Type() {
	return CustomRequirementsModel;
}

export class CustomActivationRequirementsModel {
	@Type(CustomRequirementsModel_ClassTransform_Type)
	custom_requirements: CustomRequirementsModel[];
	unactivate_available: boolean;
}
