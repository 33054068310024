import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { OrganizationTagModel } from './organization-tag.model';

@Injectable({
	providedIn: 'root',
})
export class OrganizationTagsService {
	constructor(private httpClient: HttpClient) {}

	/** Tags */

	public getAllTags = (): Observable<OrganizationTagModel[]> => {
		return this.httpClient
			.get<OrganizationTagModel[]>(`/api/v1/organizations/tags/options`)
			.pipe(mapResponse((r) => r, { errorCode: '29669FE6' }));
	};

	public addTag = (labelName: string): Observable<OrganizationTagModel> => {
		return this.httpClient
			.post<OrganizationTagModel>(`/api/v1/organizations/tags/add`, {
				label_name: labelName,
			})
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F60121AE',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
