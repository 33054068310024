import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { PermissionStoreService } from '../services/stores/permission-store/permission-store.service';
import { UserStoreService } from '../services/stores/users/user/user-store.service';

@Injectable({
	providedIn: 'root',
})
export class HasPermissionGuard {
	constructor(
		private userService: UserStoreService,
		private permissionService: PermissionStoreService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkPermissions(route);
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkPermissions(route);
	}

	private checkPermissions = (route: ActivatedRouteSnapshot) => {
		const permissions: [] = route.data?.permissions ?? [];

		return this.permissionService
			.getFieldSet$({ UserId: this.userService.currentUserUid })
			.pipe(
				take(1),
				map((p) => {
					if (p) {
						const hasPermission = p.canDoAll(...permissions);
						return (
							hasPermission || this.router.createUrlTree(['/nopermission'])
						);
					} else {
						return true;
					}
				}),
			);
	};
}
