<!-- eslint-disable @angular-eslint/template/mouse-events-have-key-events -->
<div (mouseover)="loadData()">
	@if (canUsePopover && userData) {
		@if (!popoverEnabled) {
			<span
				class="popover-container btn-icon rounded"
				(mouseover)="enablePopover()"
			>
				<ng-container *ngTemplateOutlet="contentTemplate" />
			</span>
		}
		@if (popoverEnabled) {
			<span
				#popover="ngbPopover"
				class="popover-container btn-icon rounded"
				container="body"
				triggers="click"
				[closeDelay]="300"
				[ngbPopover]="staffData"
				[popoverClass]="popoverClass"
			>
				<ng-container *ngTemplateOutlet="contentTemplate" />
			</span>
		}
	} @else {
		<span>
			<ng-container *ngTemplateOutlet="contentTemplate" />
		</span>
	}
</div>

<!-- https://github.com/angular/angular/issues/22972#issuecomment-407358396 -->
<ng-template #contentTemplate>
	<ng-content />
</ng-template>

<ng-template #staffData>
	<div
		*aeSpinWhile="!userData; spinClass: 'fa-lg'"
		class="staff-viewer-container"
	>
		<div class="d-flex">
			<ae-profile-picture
				class="flex-grow-0"
				[src]="userData.profile_picture"
			/>
			<div class="flex-grow-1 ps-1">
				<div class="fw-bold">
					{{ userData.name }}
				</div>
				<div>
					{{ userData.administrator_title }}
				</div>
			</div>
			<div class="text-nowrap flex-grow-0">
				<i aria-hidden="true" class="fal fa-user"></i>
				{{ userData.account_age }}
			</div>
		</div>

		<div class="mt-2">
			<div>{{ userData.city_state }}</div>
		</div>
		<div class="mt-1">{{ userData.about_me }}</div>
		<div class="text-center mt-2">
			<a
				class="btn btn-outline-white btn-block"
				target="_blank"
				href="{{ userData.profile_link }}"
			>
				Profile
			</a>
		</div>
	</div>
</ng-template>
