export interface UserPageTrackingEvent {
	name: string;
	page_path: string;
	timestamp: number;
	student_uid?: number;
	event_type: UserPageTrackingEventType;
}

export enum UserPageTrackingEventType {
	lunchbox_page = 'lunchbox_page',
	lunchbox_widget = 'lunchbox_widget',
	state_change = 'state_change',
	sap_menu = 'sap_menu',
}
