<span container="body" [ngbPopover]="colorButtons"
	><ng-content></ng-content
></span>
<ng-template #colorButtons>
	@for (colorSet of colorOptions; track colorSet; let r = $index) {
		<div>
			@for (color of colorSet; track color; let c = $index) {
				<button
					class="color-option-button"
					title="Select Color"
					type="button"
					id="color.option.{{ r }}.{{ c }}"
					[ngStyle]="{
						'background-color': color,
					}"
					(click)="colorSelected(color)"
				></button>
			}
		</div>
	}
</ng-template>
