import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	ProgramManagementDataBrandingModel,
	ProgramManagementDataBrandingModel_ClassTransform_Type,
} from '../organizations/management/program-management-data.model';
import {
	MeetupMinutesModel,
	MeetupMinutesModel_ClassTransform_Type,
} from '../organizations/meetups/meetup-minutes.model';
import {
	StudentPauseModel,
	StudentPauseModel_ClassTransform_Type,
} from './pause/students-pause.model';

export enum StudentOverviewMessageLevel {
	success = 'success',
	info = 'info',
	warning = 'warning',
	danger = 'danger',
}

export class StudentOverviewCoursesModel {
	counted: number;
}

export class StudentOverviewTrackModel {
	id: number;
	title: string;
}

export class StudentOverviewEnrollmentModel {
	institute_id: number;
	title: string;
	parent_title: string;
	subdetails: string;
}

export class StudentOverviewGraduationPacingModel {
	@Transform(dateTransformer)
	estimate_graduation_date: Date;
	@Transform(dateTransformer)
	target_end_date: Date;
	monthly_credit_rate: number;
	status: string;
	type: 'cohort_year' | 'end_date';
}

export class StudentOverviewGraduationModel {
	credits_required: number;
	credits_complete: number;
	credits_remain: number;
	@Transform(dateTransformer)
	expected_date: Date;
	@Transform(dateTransformer)
	actual_date: Date;
	@Type(StudentOverviewGraduationPacingModel_ClassTransform_Type)
	pacing: StudentOverviewGraduationPacingModel;

	credits_complete_converted: number;
	credits_remain_converted: number;
	credits_required_converted: number;
	credits_conversion_rate: number;
}

export class StudentOverviewMessageModel {
	level: StudentOverviewMessageLevel;
	message: string;
}

export class StudentOverviewModel {
	uid: number;
	email: string;
	phone: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	preferred_name: string;
	name_suffix: string;
	gender: string;
	@Transform(dateTransformer)
	last_online: Date;
	last_institute_id: number;
	picture_path: string;
	@Transform(dateTransformer)
	birthday: Date;
	age: number;
	iep_504: string;
	tier_level: string;
	timezone: string;
	institute_timezone: string;
	user_link_id: number;
	cohort_year: string;
	@Type(MeetupMinutesModel_ClassTransform_Type)
	meetup_minutes: MeetupMinutesModel;
	career_pathway?: string;
	forecasted_college_start_term?: string;
	gpa: number;
	@Transform(dateTransformer)
	safe_funding_expiration: Date;

	@Type(StudentOverviewTrackModel_ClassTransform_Type)
	track: StudentOverviewTrackModel;

	@Type(StudentOverviewEnrollmentModel_ClassTransform_Type)
	current_enrollment: StudentOverviewEnrollmentModel;

	@Type(StudentOverviewGraduationModel_ClassTransform_Type)
	graduation: StudentOverviewGraduationModel;

	@Type(StudentOverviewCoursesModel_ClassTransform_Type)
	courses: StudentOverviewCoursesModel;

	@Type(StudentOverviewMessageModel_ClassTransform_Type)
	messages: StudentOverviewMessageModel[];

	@Type(StudentPauseModel_ClassTransform_Type)
	pause_status?: StudentPauseModel;

	@Type(ProgramManagementDataBrandingModel_ClassTransform_Type)
	branding: ProgramManagementDataBrandingModel;
}

export function StudentOverviewCoursesModel_ClassTransform_Type() {
	return StudentOverviewCoursesModel;
}
export function StudentOverviewEnrollmentModel_ClassTransform_Type() {
	return StudentOverviewEnrollmentModel;
}
export function StudentOverviewGraduationModel_ClassTransform_Type() {
	return StudentOverviewGraduationModel;
}
export function StudentOverviewMessageModel_ClassTransform_Type() {
	return StudentOverviewMessageModel;
}
export function StudentOverviewTrackModel_ClassTransform_Type() {
	return StudentOverviewTrackModel;
}

export function StudentOverviewGraduationPacingModel_ClassTransform_Type() {
	return StudentOverviewGraduationPacingModel;
}
