import { Injectable } from '@angular/core';
import { combineLatest, Observable, tap } from 'rxjs';
import { StudentPerformanceHistoryDetailedModel } from 'src/lib/services/api/students/performance/student-performance-history-detailed.model';
import { StudentPerformanceHistoryModel } from 'src/lib/services/api/students/performance/student-performance-history.model';
import { StudentsPerformanceService } from 'src/lib/services/api/students/performance/students-performance.service';
import { BehaviorCache, subscribeAndPromise } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentPerformanceStoreService {
	private _historiesCache = new BehaviorCache<
		[number, number, number],
		StudentPerformanceHistoryModel[]
	>(
		([studentUid, skip, take]) =>
			this.studentsPerformanceService
				.getHistories(studentUid, {
					skip: skip,
					take: take,
				})
				.pipe(
					tap((histories) => {
						histories.forEach((h) =>
							this._tryRefreshHistoryDetails(studentUid, h.id),
						);
					}),
				),
		'StudentPerformanceStoreService HistoriesCache',
	);

	private _historyDetailsCache = new BehaviorCache<
		[number, number],
		StudentPerformanceHistoryDetailedModel
	>(
		([studentUid, historyId]) =>
			this.studentsPerformanceService.getHistoryDetails(studentUid, historyId),
		'StudentPerformanceStoreService HistoryDetailsCache',
	);

	constructor(private studentsPerformanceService: StudentsPerformanceService) {}

	/**
	 * Histories
	 */
	public histories$ = (
		studentUid: number,
		skip: number = 0,
		take: number = 100,
	): Observable<StudentPerformanceHistoryModel[]> => {
		return this._historiesCache.getCache([studentUid, skip, take]);
	};

	public refreshHistories = (studentUid: number): Promise<boolean> => {
		const keys = this._historiesCache
			.getStoredKeys()
			.filter((x) => x[0] === studentUid);

		return subscribeAndPromise(
			combineLatest(keys.map((k) => this._historiesCache.fetchData(k, true))),
		);
	};

	/**
	 * History Details
	 */
	public historyDetails$ = (
		studentUid: number,
		historyId: number,
	): Observable<StudentPerformanceHistoryDetailedModel> => {
		return this._historyDetailsCache.getCache([studentUid, historyId]);
	};

	public refreshHistoryDetails = (
		studentUid: number,
		historyId: number,
	): Promise<boolean> => {
		return this._historyDetailsCache.fetchData([studentUid, historyId], true);
	};

	private _tryRefreshHistoryDetails = (
		studentUid: number,
		historyId: number,
	): Promise<boolean> => {
		return this._historyDetailsCache.tryRefreshData([studentUid, historyId]);
	};
}
