import { PaginationTableMarginSize } from '../types/pagination-table-margin-size';
import { PaginationTableStyleRuleComparison } from '../types/pagination-table-style-rule-comparison';

export const minimumColumnWidth = 30;
export const warningColumnWidth = 50;

export const columnStyleRuleComparisonSet: {
	value: PaginationTableStyleRuleComparison;
	label: string;
}[] = [
	{
		value: PaginationTableStyleRuleComparison.Always,
		label: 'Always',
	},
	{
		value: PaginationTableStyleRuleComparison.Contains,
		label: 'When it contains',
	},
	{
		value: PaginationTableStyleRuleComparison.NotContains,
		label: `When it doesn't contain`,
	},
	{
		value: PaginationTableStyleRuleComparison.Equals,
		label: 'When it is',
	},
	{
		value: PaginationTableStyleRuleComparison.NotEquals,
		label: `When it isn't`,
	},
	{
		value: PaginationTableStyleRuleComparison.GreaterEquals,
		label: `When it is >=`,
	},
	{
		value: PaginationTableStyleRuleComparison.LesserEquals,
		label: `When it is <=`,
	},
	{
		value: PaginationTableStyleRuleComparison.WithinXDays,
		label: 'When it is within X days',
	},
	{
		value: PaginationTableStyleRuleComparison.OlderXDays,
		label: 'When it is older than X days',
	},
];

export const PaginationTableMarginSizeConst = {
	large: PaginationTableMarginSize.large,
	medium: PaginationTableMarginSize.medium,
	small: PaginationTableMarginSize.small,
	zero: PaginationTableMarginSize.zero,
};

export const PaginationTableStyleRuleComparisonConst = {
	Always: PaginationTableStyleRuleComparison.Always,
	Contains: PaginationTableStyleRuleComparison.Contains,
	NotContains: PaginationTableStyleRuleComparison.NotContains,
	Equals: PaginationTableStyleRuleComparison.Equals,
	NotEquals: PaginationTableStyleRuleComparison.NotEquals,
	GreaterEquals: PaginationTableStyleRuleComparison.GreaterEquals,
	LesserEquals: PaginationTableStyleRuleComparison.LesserEquals,
	WithinXDays: PaginationTableStyleRuleComparison.WithinXDays,
	OlderXDays: PaginationTableStyleRuleComparison.OlderXDays,
};

export interface ColumnStyleRuleClass {
	name: string;
	group: string;
	class: string;
	additionalSelectClasses?: string;
}

export const columnStyleRuleClassSet: ColumnStyleRuleClass[] = [
	// Text Styles
	{
		name: 'Bold',
		class: 'fw-bold',
		group: 'Text Styles',
	},
	{
		name: 'Italic',
		class: 'fst-italic',
		group: 'Text Styles',
	},
	{
		name: 'Strike Through',
		class: 'text-decoration-line-through',
		group: 'Text Styles',
	},
	{
		name: 'All Caps',
		class: 'text-uppercase',
		group: 'Text Styles',
	},

	// Text Colors
	{
		name: 'Blue Text',
		class: 'text-blue',
		group: 'Text Colors',
	},
	{
		name: 'Indigo Text',
		class: 'text-indigo',
		group: 'Text Colors',
	},
	{
		name: 'Purple Text',
		class: 'text-purple',
		group: 'Text Colors',
	},
	{
		name: 'Pink Text',
		class: 'text-pink',
		group: 'Text Colors',
	},
	{
		name: 'Red Text',
		class: 'text-red',
		group: 'Text Colors',
	},
	{
		name: 'Orange Text',
		class: 'text-orange',
		group: 'Text Colors',
	},
	{
		name: 'Yellow Text',
		class: 'text-yellow',
		group: 'Text Colors',
	},
	{
		name: 'Green Text',
		class: 'text-green',
		group: 'Text Colors',
	},
	{
		name: 'Teal Text',
		class: 'text-teal',
		group: 'Text Colors',
	},
	{
		name: 'Cyan Text',
		class: 'text-cyan',
		group: 'Text Colors',
	},
	{
		name: 'Black Text',
		class: 'text-black',
		group: 'Text Colors',
	},
	{
		name: 'White Text',
		class: 'text-white',
		group: 'Text Colors',
		additionalSelectClasses: 'bg-black',
	},

	// Background Colors
	{
		name: 'Blue Background',
		class: 'bg-blue',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Indigo Background',
		class: 'bg-indigo',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate text-white',
	},
	{
		name: 'Purple Background',
		class: 'bg-purple',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Pink Background',
		class: 'bg-pink',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Red Background',
		class: 'bg-red',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Orange Background',
		class: 'bg-orange',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Yellow Background',
		class: 'bg-yellow',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Green Background',
		class: 'bg-green',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Teal Background',
		class: 'bg-teal',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Cyan Background',
		class: 'bg-cyan',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
	{
		name: 'Black Background',
		class: 'bg-black',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate text-white',
	},
	{
		name: 'White Background',
		class: 'bg-white',
		group: 'Background Colors',
		additionalSelectClasses: 'p-1 text-truncate',
	},
];
