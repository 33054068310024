import { Type } from 'class-transformer';

export class ActivityBoardAvailableProgramModel {
	id: number;
	title: string;
}

export function ActivityBoardAvailableProgramModel_ClassTransformType() {
	return ActivityBoardAvailableProgramModel;
}

export class ActivityBoardAvailableItemModel {
	uid: number;
	name: string;
	@Type(ActivityBoardAvailableProgramModel_ClassTransformType)
	programs: ActivityBoardAvailableProgramModel[];
	showing: boolean;
}

export function ActivityBoardAvailableItemModel_ClassTransformType() {
	return ActivityBoardAvailableItemModel;
}

export enum ActivityBoardTypes {
	Teacher = 'Teacher',
	AC = 'Academic Coach',
	LA = 'Local Advocate',
	Transcript = 'Transcript',
}
