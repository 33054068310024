import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class ProgramFileModel {
	file_type_key: string;
	url: string;
	@Transform(dateTransformer)
	expiration_date: Date;
	@Transform(dateTransformer)
	uploaded_date: Date;
	@Transform(dateTransformer)
	removed_date: Date;
	notes: string;
	uploaded_by: number;
	uploaded_name: string;
}

export class ProgramFileTypeModel {
	file_type_key: string;
	title: string;
}
