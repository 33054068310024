import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentOverviewModel } from 'src/lib/services/api/students/student-overview.model';
import { StudentProgramContact } from 'src/lib/services/api/students/student-program-contact.model';
import { StudentsService } from 'src/lib/services/api/students/students.service';
import { BehaviorCache } from 'src/lib/utilities/cache';

@Injectable({
	providedIn: 'root',
})
export class StudentStoreService {
	private _programContactsCache = new BehaviorCache<
		number,
		StudentProgramContact[]
	>(
		(uid) => this.studentsService.getProgramContacts(uid),
		'StudentStore ProgramContactsCache',
		() => [],
	);

	private _overviewCache = new BehaviorCache<number, StudentOverviewModel>(
		(uid) => this.studentsService.getStudentOverview(uid),
		'StudentStore OverviewCache',
	);

	constructor(private studentsService: StudentsService) {}

	/**
	 * Program Contacts
	 */
	public programContacts$ = (
		uid: number,
	): Observable<StudentProgramContact[]> => {
		return this._programContactsCache.getCache(uid);
	};

	public refreshProgramContacts = (uid: number): Promise<boolean> => {
		return this._programContactsCache.fetchData(uid, true);
	};

	/**
	 * Overview
	 */
	public overview$ = (uid: number): Observable<StudentOverviewModel> => {
		return this._overviewCache.getCache(uid);
	};

	public refreshOverview = (uid: number): Promise<boolean> => {
		return this._overviewCache.fetchData(uid, true);
	};
}
