import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentEventModel {
	id: number;
	category: EventCategory | string;
	medium: string;
	subject: string;
	body: string;
	creator_uid: number;
	creator_name: string;
	student_uid: number;
	student_name: string;
	@Transform(dateTransformer)
	created: Date;
	@Transform(dateTransformer)
	updated: Date;
	@Transform(dateTransformer)
	complete_date: Date;
	@Transform(dateTransformer)
	due_date: Date;
	twoway_contact: boolean;
	registration_id: number;
	registration_name: string;
	initiated_by: string;
	assigned_uid: number;
	assigned_name: string;
	completed_task: boolean;
	tags: string[];
	@Type(StudentEventFile_ClassTransform_Type)
	files: StudentEventFile[];
	permission_level: string;
	@Type(StudentEventLineItem_ClassTransform_Type)
	line_items: StudentEventLineItem[];
	purposes: Record<string, string>;
	relationship: string;
	is_auto_logged: boolean;
}

export function StudentEventModel_ClassTransform_Type() {
	return StudentEventModel;
}

export class StudentEventFile {
	description: string;
	path: string;
	fid: number;
}

export function StudentEventFile_ClassTransform_Type() {
	return StudentEventFile;
}

export class StudentEventLineItem {
	@Transform(dateTransformer)
	date: Date;
	note: string;
}

export function StudentEventLineItem_ClassTransform_Type() {
	return StudentEventLineItem;
}

export enum EventCategory {
	task = 'Task',
	note = 'Note',
	administrative = 'Administrative',
	registration = 'Registration',
}
