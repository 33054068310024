import { Type } from 'class-transformer';
import { MessagePlatform } from './message-platform.enum';

export class MessageDeliveryMethodModel {
	id: MessagePlatform;
	value: any;
	success: boolean;
}
export function MessageDeliveryMethodModel_ClassTransform_Type() {
	return MessageDeliveryMethodModel;
}

export class MessageDeliveryModel {
	participant_link_id: number;
	read: boolean;
	@Type(MessageDeliveryMethodModel_ClassTransform_Type)
	delivery_methods: MessageDeliveryMethodModel[];
}

export function MessageDeliveryModel_ClassTransform_Type() {
	return MessageDeliveryModel;
}
