<form (ngSubmit)="save()">
	<section #rootElement class="billboard" tabindex="-1">
		<div class="billboard-header">
			<h2 class="billboard-title">General Info</h2>
			<div class="billboard-actions">
				<div class="btn-toolbar">
					<button
						class="btn btn-secondary"
						id="profile.contact.edit.cancel"
						type="button"
						(click)="resetProfileInfoForm(false)"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						id="profile.contact.edit.enable"
						type="submit"
						[disabled]="
							savingInfo ||
							(!profileInfoFormGroup.valid && hasChanges) ||
							!hasChanges
						"
					>
						Save Changes
					</button>
				</div>
			</div>
		</div>
		<div class="billboard-body" [formGroup]="profileInfoFormGroup">
			<!-- Name Row-->
			<h3 class="fs-5 fw-bold">Demographics</h3>
			<div class="row">
				<div aeGroupValidation class="col-xl-4 col-lg-6 form-group">
					<label
						class="d-flex align-items-center"
						for="profile.info.edit.first_name"
					>
						<span class="flex-grow-1">First Name*</span>

						<ae-icon-tooltip type="help" [message]="legalNameExplain" />
						<ng-template #legalNameExplain>
							We require your legal name to be able to fill out paperwork. If
							you have another name you prefer to go by then enter it in the
							preferred name field. We'll only use your legal name when
							required.
						</ng-template>
					</label>
					<input
						aeInputTrim
						autocomplete="given-name"
						class="form-control"
						formControlName="first_name"
						id="profile.info.edit.first_name"
						type="text"
					/>
					<ae-group-validation-display [maxDisplay]="1">
						<ng-template aeValidationError="required">
							First name is required
						</ng-template>
					</ae-group-validation-display>
				</div>

				<div aeGroupValidation class="col-xl-3 col-lg-6 form-group">
					<label for="profile.info.edit.middle_name"> Middle Name </label>
					<input
						aeInputTrim
						autocomplete="additional-name"
						class="form-control"
						formControlName="middle_name"
						id="profile.info.edit.middle_name"
						type="text"
					/>
				</div>

				<div aeGroupValidation class="col-xl-4 col-lg-6 form-group">
					<label for="profile.info.edit.last_name"> Last Name* </label>
					<input
						aeInputTrim
						autocomplete="family-name"
						class="form-control"
						formControlName="last_name"
						id="profile.info.edit.last_name"
						type="text"
					/>
					<ae-group-validation-display [maxDisplay]="1">
						<ng-template aeValidationError="required">
							Last name is required
						</ng-template>
					</ae-group-validation-display>
				</div>

				<div aeGroupValidation class="col-xl-1 col-lg-6 form-group">
					<label for="profile.info.edit.suffix"> Suffix </label>
					<input
						autocomplete="honorific-suffix"
						class="form-control"
						formControlName="suffix"
						id="profile.info.edit.suffix"
						type="text"
					/>
				</div>
				<div aeGroupValidation class="col-xl-4 col-lg-6 form-group">
					<label for="profile.info.edit.preferred_name"> Preferred Name </label>
					<input
						aeInputTrim
						autocomplete="nickname"
						class="form-control"
						formControlName="preferred_name"
						id="profile.info.edit.preferred_name"
						placeholder="If different from first name"
						type="text"
					/>
				</div>

				<div aeGroupValidation class="col-xl-4 col-lg-6 form-group">
					<label for="profile.info.edit.maiden_name"> Maiden Name </label>
					<input
						aeInputTrim
						autocomplete="off"
						class="form-control"
						formControlName="maiden_name"
						id="profile.info.edit.maiden_name"
						type="text"
					/>
				</div>
			</div>
			<!-- Birthdate Row -->
			<div class="row">
				@if (birthdateAllowed) {
					<div aeGroupValidation class="col-xl-4 col-lg-6 form-group">
						<label for="profile.info.edit.birthdate"> Birthdate </label>
						<ae-single-date-picker
							autocomplete="bday"
							formControlName="birthdate"
							format="MM/dd/yyyy"
							id="profile.info.edit.birthdate"
							[maxDate]="currentDate"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required">
								Birthdate is required
							</ng-template>
							<ng-template aeValidationError="minimum_age" let-error>
								{{ error }}
							</ng-template>
						</ae-group-validation-display>
					</div>
				}
				<div class="col-xl-4 col-lg-6 form-group">
					<label for="profile.info.edit.gender"> Gender </label>
					<ng-select
						bindLabel="title"
						bindValue="key"
						formControlName="gender"
						labelForId="profile.info.edit.gender"
						[clearable]="true"
						[items]="dropdownGender"
					/>
				</div>
				<div class="col-xl-4 col-lg-6 form-group">
					<label for="profile.info.edit.race"> Race </label>
					<ng-select
						bindLabel="title"
						bindValue="key"
						formControlName="race"
						labelForId="profile.info.edit.race"
						[clearable]="true"
						[items]="dropdownRace"
					/>
				</div>
			</div>

			@if (
				hasSomeKey(profileData, [
					'profile_about',
					'profile_city',
					'profile_state',
					'profile_intro_video',
				])
			) {
				<div class="row">
					<div class="col-12">
						<h3 class="fs-5 fw-bold">Social</h3>
					</div>
					@if (hasKey(profileData, 'profile_about')) {
						<div class="col-xl-8 col-lg-12 form-group">
							<label for="profile.info.edit.about_me"> About Me </label>
							<textarea
								autocomplete="off"
								class="form-control"
								formControlName="about_me"
								id="profile.info.edit.about_me"
								maxlength="300"
								type="text"
							></textarea>
						</div>
					}
				</div>
				<!--City Row-->
				<div class="row">
					@if (hasKey(profileData, 'profile_city')) {
						<div class="col-xl-5 col-lg-6 form-group">
							<label for="profile.info.edit.city"> City </label>
							<input
								autocomplete="off"
								class="form-control"
								formControlName="city"
								id="profile.info.edit.city"
								maxlength="100"
								type="text"
							/>
						</div>
					}
					@if (hasKey(profileData, 'profile_state')) {
						<div class="col-xl-3 col-lg-6 form-group">
							<label for="profile.info.edit.state"> State </label>
							<ng-select
								bindLabel="title"
								bindValue="key"
								formControlName="state"
								labelForId="profile.info.edit.state"
								[clearable]="true"
								[items]="dropdownState"
							/>
						</div>
					}
				</div>
				<div class="row">
					@if (hasKey(profileData, 'profile_intro_video')) {
						<div aeGroupValidation class="col-lg-12 form-group">
							<label for="profile.info.edit.intro_video"> Welcome Video </label>
							<input
								autocomplete="off"
								class="form-control"
								formControlName="intro_video"
								id="profile.info.edit.intro_video"
								maxlength="100"
								type="text"
							/>
							<ae-group-validation-display [maxDisplay]="1">
								<ng-template aeValidationError="valid_link" let-error>
									{{ error }}
								</ng-template>
							</ae-group-validation-display>
						</div>
					}
				</div>
			}
		</div>
	</section>
</form>
