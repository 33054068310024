import { Expose, Transform, Type } from 'class-transformer';
import {
	dateTransformer,
	floatTransformer,
} from 'src/lib/utilities/api/class-transformer-types';
import {
	TrackerUserPerformance,
	TrackerUserPerformance_ClassTransform_Type,
} from '../students/performance/student-performance-history-detailed.model';
import {
	WorkQueueTrackerResponseModel,
	WorkQueueTrackerResponseModel_ClassTransform_Type,
} from '../work-queue/work-queue-entity.model';

export class AdminTrackerClassModel {
	id: number;
	title: string;
	assignment_count: number;
	assignment_completed: number;
	@Expose({ name: 'success' })
	grade: number;
	progress: number;
	notes: string;
	@Transform(dateTransformer)
	end_date: Date;
	registration_type: string;
	@Expose({ name: 'success_zero' })
	grade_zero: number;
	lms: string;
}

export function AdminTrackerClassModel_ClassTransform_Type() {
	return AdminTrackerClassModel;
}

export class AdminTrackerItemModel {
	cohort_year: string;
	credits: number;
	credits_converted: number;
	@Transform(floatTransformer)
	credits_remain: number;
	credits_remain_converted: number;
	uid: number;
	@Transform(dateTransformer)
	access: Date;
	@Expose({ name: 'mail' })
	email: string;
	institute_id: number;
	timezone: string;
	title: string;
	profile_first_name: string;
	profile_preferred_name: string;
	profile_last_name: string;
	profile_student_year: string;
	phone: number;
	phone_type: string;
	@Transform(dateTransformer)
	last_attempt: Date;
	@Transform(dateTransformer)
	last_contact: Date;
	state_program: string;
	@Expose({ name: 'title' })
	program_title: string;
	@Expose({ name: 'parent_title' })
	program_parent_title: string;
	@Type(TrackerUserPerformance_ClassTransform_Type)
	performance_data: TrackerUserPerformance;
	is_1418: number;
	weekly_assignments: number;
	@Type(AdminTrackerClassModel_ClassTransform_Type)
	classes: AdminTrackerClassModel[];
	tier: string;
	note: string;
	persistent_courses: number;
	@Type(WorkQueueTrackerResponseModel_ClassTransform_Type)
	queues: WorkQueueTrackerResponseModel[];
	@Transform(dateTransformer)
	dob: Date;
	@Transform(dateTransformer)
	activated: Date;
	iep_504: string;
	student_location: string;
	last_weekly_assignments: number;
	star_math_percentile: number;
	star_reading_percentile: number;
}

export function AdminTrackerItemModel_ClassTransform_Type() {
	return AdminTrackerItemModel;
}

export class AdminTrackerModel {
	count: number;
	skip: number;
	take: number;
	@Type(AdminTrackerItemModel_ClassTransform_Type)
	results: AdminTrackerItemModel[];
}

export interface AdminTrackerNoteArgument {
	uid: number;
	note: string;
}
