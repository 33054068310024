import { Type } from 'class-transformer';
import { AbstractActivityBoardResponseModel } from '../abstract-activity-board-response.model';

export class ActivityBoardAcModel {
	academic_coach_uid: number;
	academic_coach_name: string;
	login_days: number;
	students_count: number;
	students_with_no_classes: number;
	students_with_no_contact: number;
	student_activity_views: number;
	gradchat_messages_sent: number;
	transcripts_mapped_count: number;
}

export function ActivityBoardAcademicCoachModel_ClassTransformType() {
	return ActivityBoardAcModel;
}

export class ActivityBoardAcResponseModel extends AbstractActivityBoardResponseModel<ActivityBoardAcModel> {
	@Type(ActivityBoardAcademicCoachModel_ClassTransformType)
	activity_board: ActivityBoardAcModel[];
}
