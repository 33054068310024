import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DirectoryModel } from './directory.model';

@Injectable({
	providedIn: 'root',
})
export class DirectoriesService {
	constructor(private httpClient: HttpClient) {}

	public getDirectories = (userUid: number): Observable<DirectoryModel[]> => {
		return this.httpClient
			.get<DirectoryModel[]>(`/api/v1/gradchat/${userUid}/directories`)
			.pipe(map((r) => plainToInstance(DirectoryModel, r)));
	};
}
