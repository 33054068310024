import { Pipe, PipeTransform } from '@angular/core';
import { camelCaseSplit } from '../utilities/string';

@Pipe({
	name: 'camelCaseSplit',
	standalone: true,
})
export class CamelCaseSplitPipe implements PipeTransform {
	transform(value: string | null): unknown {
		return camelCaseSplit(value);
	}
}
