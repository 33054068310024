<div class="input-group validate-include-children">
	<input
		autocomplete="new-password"
		class="form-control squared-end"
		[formControl]="ctrl"
		id="{{ id }}"
		[placeholder]="placeholder"
		[title]="title"
		[type]="fieldType"
	/>
	@if (hidePassword) {
		<button
			class="btn btn-outline-secondary squared-all"
			ngbTooltip="Show/Hide password"
			type="button"
			(click)="toggleFieldType()"
		>
			<span class="screen-reader-text">Show/Hide password</span>
			<i
				class="far"
				[ngClass]="{
					'fa-eye': fieldType === 'text',
					'fa-eye-slash': fieldType === 'password',
				}"
			></i>
		</button>
	}
	<button
		class="btn btn-primary squared-start"
		ngbTooltip="Make me a random password"
		type="button"
		(click)="generatePassword()"
	>
		<span class="screen-reader-text">Make me a random password</span>
		<i class="fal fa-dice"></i>
	</button>
</div>
