import { Type } from 'class-transformer';
import { AbstractPagingResponseModel } from '../../../../../utilities/api/patterns/pagination/abstract-paging-response.model';
import {
	StudentCurrentCourseModel,
	StudentCurrentCourseModel_ClassTransform_Type,
} from './student-current-course.model';

export class StudentCurrentCoursesResponseModel extends AbstractPagingResponseModel<StudentCurrentCourseModel> {
	@Type(StudentCurrentCourseModel_ClassTransform_Type)
	results: StudentCurrentCourseModel[];
}
