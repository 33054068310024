<div class="modal-header">
	<h1 class="modal-title">Reschedule Meetup</h1>
	<button
		class="btn btn-outline-white"
		id="sap.meetup.reschedule.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<ng-container *aeSpinWhile="loading">
		<div>Are you sure you want to reschedule the following meetup?</div>

		<div class="fw-bold mt-2">
			{{ meetupTitles[meetup.gathering_type] }}
		</div>
		<div>
			{{
				(meetup.planned_arrival_date
					? meetup.planned_arrival_date
					: meetup.start_date
				)
					| spaceMap
					| spaceFormat: '{day} {month} {date}'
			}}
		</div>

		<div>
			{{
				(meetup.planned_arrival_date
					? meetup.planned_arrival_date
					: meetup.start_date
				) | date: 'hh:mm a'
			}}
			-
			{{
				(meetup.planned_departure_date
					? meetup.planned_departure_date
					: meetup.end_date
				) | date: 'hh:mm a'
			}}
		</div>

		<div class="fs-sm">
			{{ meetup.type === 'virtual' ? 'Virtual' : 'In Person' }}
			with
			{{ meetup.host.name }}
		</div>
	</ng-container>
</div>
<div class="modal-footer">
	<button
		class="btn btn-outline-primary"
		id="sap.meetup.reschedule.modal.component.cancel"
		type="button"
		(click)="close()"
	>
		Cancel
	</button>

	<button
		class="btn btn-primary"
		id="sap.meetup.reschedule.modal.component.save"
		type="button"
		[disabled]="saving || loading"
		(click)="save()"
	>
		<ae-wait-spinner [saving]="saving">Reschedule</ae-wait-spinner>
	</button>
</div>
