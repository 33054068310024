export function getCurrentSchoolYear(changeOverMonth = 6) {
	const now = new Date();
	const year = now.getFullYear();
	const month = now.getMonth();

	const startYear = month >= changeOverMonth ? year : year - 1;
	const endYear = startYear + 1;

	const startDate = new Date(startYear, changeOverMonth, 1); // First day of the changeover month of the start year

	// Last day of the month before the changeover month of the end year
	const endDate = new Date(endYear, changeOverMonth, 0);

	return {
		start: startDate,
		end: endDate,
	};
}

export function hasSuperMasquerade() {
	return document.querySelector('#block-switchuser') != null;
}

export function superMasqueradeAsUser(userId: number) {
	try {
		(
			document.querySelector(
				'#block-switchuser input[data-drupal-selector="edit-userid"]',
			) as HTMLInputElement
		).value = `a (${userId})`;
		(
			document.querySelector(
				'#block-switchuser button[data-drupal-selector="edit-submit"]',
			) as HTMLButtonElement
		).click();
	} catch {
		alert('Failed to Super Masquerade');
	}
}
