import { AbstractControl } from '@angular/forms';
import { isNumber } from 'src/lib/utilities/compare';
import { getFileExtension, isFileAllowed } from 'src/lib/utilities/file';

export function createValidFileExtension(
	extensionsAllowed: string[],
): (_: AbstractControl) => any {
	return (ctrl: AbstractControl) => {
		let valid = true;

		let fileExtension: string;
		if (ctrl && ctrl.value && ctrl.value.name) {
			fileExtension = getFileExtension(ctrl.value);
			valid = isFileAllowed(ctrl.value, extensionsAllowed);
		}
		return valid
			? null
			: { extension: { given: fileExtension, allowed: extensionsAllowed } };
	};
}

export function createFileSize(maxSizeKB: number): (_: AbstractControl) => any {
	if (!isNumber(maxSizeKB)) throw new Error('maxSizeKB must be a number');

	return (ctrl: AbstractControl) => {
		let valid = true;
		if (ctrl && ctrl.value && ctrl.value.size) {
			valid = ctrl.value.size / 1000 <= maxSizeKB;
		}
		return valid
			? null
			: {
					max: {
						given: Math.ceil(ctrl.value.size / 1000),
						maxAllowed: maxSizeKB,
					},
				};
	};
}
