import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UserModalModel } from 'src/lib/services/api/users/modals/user-modal.model';
import { PromptPasswordModalComponent } from './prompt-password-modal.component';

@Injectable({
	providedIn: 'root',
})
export class PromptPasswordModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(data?: UserModalModel) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				scrollable: true,
				windowClass: 'modal-info',
			};
			const modal = this.modalService.open(
				PromptPasswordModalComponent,
				ngbModalOptions,
			);

			(modal.componentInstance as PromptPasswordModalComponent).bindModalData({
				data: data,
			});

			modal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
