import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class JIRAUserModel {
	emailAddress: string;
	displayName: string;
}

export function JIRAUserModel_ClassTransform_Type() {
	return JIRAUserModel;
}

export class JIRACommentModel {
	@Type(JIRAUserModel_ClassTransform_Type)
	author: JIRAUserModel;
	body: string;
	@Transform(dateTransformer)
	created: Date;
}

export function JIRACommentModel_ClassTransform_Type() {
	return JIRACommentModel;
}

export class JiraTicketComment {
	@Type(JIRACommentModel_ClassTransform_Type)
	comments: JIRACommentModel[];
}

export function JiraTicketComment_ClassTransform_Type() {
	return JiraTicketComment;
}

export class JiraStatusModel {
	name: string;
}

export function JiraStatusModel_ClassTransform_Type() {
	return JiraStatusModel;
}

export class JiraTicketResolutionModel {
	id: string;
	self: string;
	name: string;
	description: string;
}

export function JiraTicketResolutionModel_ClassTransform_Type() {
	return JiraTicketResolutionModel;
}

export class JiraFieldModel {
	summary: string;
	description: string;
	@Transform(dateTransformer)
	updated: Date;
	@Type(JIRAUserModel_ClassTransform_Type)
	reporter: JIRAUserModel;
	@Type(JiraTicketComment_ClassTransform_Type)
	comment: JiraTicketComment;
	@Type(JiraStatusModel_ClassTransform_Type)
	status: JiraStatusModel;
	@Type(JiraTicketResolutionModel_ClassTransform_Type)
	resolution: JiraTicketResolutionModel;
}

export function JiraFieldModel_ClassTransform_Type() {
	return JiraFieldModel;
}

export class JiraTicketModel {
	id: string;
	self: string;
	key: string;
	@Type(JiraFieldModel_ClassTransform_Type)
	fields: JiraFieldModel;
	@Type(JiraFieldModel_ClassTransform_Type)
	renderedFields: JiraFieldModel;
}
