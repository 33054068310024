import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { buildFormData, buildQueryString } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { ProgramFileArgument } from './program-file.argument';
import { ProgramFileModel, ProgramFileTypeModel } from './program-file.model';

@Injectable({
	providedIn: 'root',
})
export class ProgramFilesService {
	constructor(private httpClient: HttpClient) {}

	public getOrganizationFileList = (
		orgId: number,
		show_removed: boolean = false,
	): Observable<ProgramFileModel[]> => {
		const query = buildQueryString({
			show_removed,
		});

		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/files${query}`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramFileModel, r.files as any[]),
					{
						errorCode: '81C2DD97',
					},
				),
			);
	};

	public getOrganizationFileOptions = (
		orgId: number,
	): Observable<ProgramFileTypeModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/files/options`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramFileTypeModel, r.file_types as any[]),
					{
						errorCode: '6B672BC8',
					},
				),
			);
	};

	public uploadOrganizationFile = (
		orgId: number,
		file: File,
		args: Partial<ProgramFileArgument>,
	): Observable<any> => {
		const bodyArgs = {};
		bodyArgs['jsonBody'] = args;

		if (file) {
			bodyArgs['file'] = file;
		}

		const formData = buildFormData(bodyArgs);

		return this.httpClient
			.post<any>(`/api/v1/organizations/${orgId}/files`, formData)
			.pipe(
				mapResponse((r) => plainToInstance(ProgramFileModel, r.file), {
					errorCode: 'B2E26D21',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
