import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { MeetupManagementListItemModel } from 'src/lib/services/api/organizations/meetups/meetup-management.model';
import { MeetupRsvpModalComponent } from './meetup-rsvp-modal.component';

@Injectable({
	providedIn: 'root',
})
export class MeetupRsvpModalService {
	constructor(private modalService: NgbModal) {}

	public openModal$(meetup: MeetupManagementListItemModel) {
		return new Observable<boolean>((o) => {
			const ngbModalOptions: NgbModalOptions = {
				backdrop: 'static',
				scrollable: true,
				windowClass: 'modal-primary',
			};
			const modal = this.modalService.open(
				MeetupRsvpModalComponent,
				ngbModalOptions,
			);

			(modal.componentInstance as MeetupRsvpModalComponent).bindModalData({
				meetup: meetup,
			});

			modal.result
				.then(() => {
					o.next(true);
					o.complete();
				})
				.catch(() => {
					o.next(false);
					o.complete();
				});
		});
	}
}
