import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { mapResponse } from 'src/lib/utilities/api/map-response';
import { StudentStatusModel } from './student-status.model';

@Injectable({
	providedIn: 'root',
})
export class StudentsStatusService {
	constructor(private httpClient: HttpClient) {}

	public getStatus = (studentId: number): Observable<StudentStatusModel> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/status`)
			.pipe(
				mapResponse((r) => plainToInstance(StudentStatusModel, r.result), {
					errorCode: '4571D228',
				}),
			);
	};

	public refreshStatus = (studentId: number): Observable<string> => {
		return this.httpClient
			.get<any>(`/api/v1/students/${studentId}/status/refresh`)
			.pipe(mapResponse((r) => r, { errorCode: 'CC054FEB' }));
	};
}
