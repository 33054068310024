import { Type } from 'class-transformer';
import {
	OrganizationStudentSearchItemFieldsModel,
	OrganizationStudentSearchItemFieldsModel_ClassTransform_Type,
} from './organization-student-search-item-fields.model';
import {
	OrganizationStudentSearchItemModel,
	OrganizationStudentSearchItemModel_ClassTransform_Type,
} from './organization-student-search-item.model';

export class OrganizationStudentsSearchModel {
	@Type(OrganizationStudentSearchItemModel_ClassTransform_Type)
	students: OrganizationStudentSearchItemModel[];
	total_students: number;

	@Type(OrganizationStudentSearchItemFieldsModel_ClassTransform_Type)
	fields: OrganizationStudentSearchItemFieldsModel;
}
