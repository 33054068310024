import { A11yModule } from '@angular/cdk/a11y';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {
	LoggingErrorHandler,
	withCredentialsInterceptor,
} from 'src/public.index';
import { AppComponent } from './app/app.component';
import './environments/environment';

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			// Angular Modules
			FormsModule,
			ReactiveFormsModule,
			ScrollingModule,
			BrowserModule,
			A11yModule,

			// Third Party Modules
			ToastrModule.forRoot({
				timeOut: 15000,
				maxOpened: 3,
			}),
			NgbModule,
		),

		{ provide: ErrorHandler, useClass: LoggingErrorHandler },
		provideHttpClient(withInterceptors([withCredentialsInterceptor])),
		provideAnimations(),
		NgbActiveModal,
	],
}).catch((err) => console.error(err));
