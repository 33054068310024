import { ChangeDetectorRef, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileAccessibilityStatementComponent } from '../../common/profile/profile-accessibility-statement/profile-accessibility-statement.component';

@Component({
	selector: 'ae-profile-accessibility-statement-modal',
	standalone: true,
	imports: [ProfileAccessibilityStatementComponent],
	templateUrl: './profile-accessibility-statement-modal.component.html',
	styleUrl: './profile-accessibility-statement-modal.component.scss',
})
export class ProfileAccessibilityStatementModalComponent {
	constructor(
		public activeModal: NgbActiveModal,
		private cdref: ChangeDetectorRef,
	) {}

	// Bug Workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2645
	public bindModalData = (): void => {
		// SET DATA

		// DETECT CHANGES
		this.cdref.detectChanges();

		// NOW ALLOWED TO DO BUSINESS LOGIC
	};
}
