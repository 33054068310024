import { Transform } from 'class-transformer';
import {
	dateTransformer,
	integerTransformer,
} from 'src/lib/utilities/api/class-transformer-types';

export class StudentRelationshipHistoryModel {
	uid: number;
	name: string;
	picture_path: string;
	type: string;
	type_id: number;
	email: string[];
	@Transform(integerTransformer)
	phone: number[];
	@Transform(dateTransformer)
	assigned_time: Date;
	@Transform(dateTransformer)
	unassigned_time: Date;
}
