import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCallCache } from 'src/lib/utilities/api/http';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import {
	WorkQueueEntityArgument,
	WorkQueueEntityModel,
} from './work-queue-entity.model';
import { WorkQueueArgument, WorkQueueModel } from './work-queue.model';

@Injectable({
	providedIn: 'root',
})
export class WorkQueueService {
	constructor(private httpClient: HttpClient) {}

	private httpCallCache_getQueues = new HttpCallCache<number, WorkQueueModel[]>(
		5 * 60 * 1000,
	);
	private httpCallCache_getQueueEntities = new HttpCallCache<
		number,
		WorkQueueEntityModel[]
	>(5000);

	/**************/
	/*** Queues ***/
	/**************/

	public getQueues = (
		useCache: boolean = true,
	): Observable<WorkQueueModel[]> => {
		if (!useCache) this.httpCallCache_getQueues.flushAll();

		return this.httpCallCache_getQueues.fetch$(
			null,
			this.httpClient.get<WorkQueueModel[]>(`/api/v1/tracker/queues`).pipe(
				mapResponse((r) => plainToInstance(WorkQueueModel, r), {
					errorCode: 'EE9A097E',
				}),
			),
		);
	};

	public createQueue = (
		args: WorkQueueArgument,
	): Observable<WorkQueueModel> => {
		return this.httpClient
			.post<WorkQueueModel>(`/api/v1/tracker/queues`, args)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueModel, r), {
					errorCode: 'DF3C7B44',
					errorMessage: DefaultErrorMessage.Saving,
				}),
				tap(() => this.httpCallCache_getQueues.flushAll()),
			);
	};

	public updateQueue = (
		queueId: number,
		args: WorkQueueArgument,
	): Observable<WorkQueueModel> => {
		return this.httpClient
			.post<WorkQueueModel>(`/api/v1/tracker/queue/${queueId}`, args)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueModel, r), {
					errorCode: '1ADDC849',
					errorMessage: DefaultErrorMessage.Saving,
				}),
				tap(() => this.httpCallCache_getQueues.flushAll()),
			);
	};

	public deleteQueue = (queueId: number): Observable<WorkQueueModel[]> => {
		return this.httpClient
			.delete<WorkQueueModel[]>(`/api/v1/tracker/queue/${queueId}`)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueModel, r), {
					errorCode: '7EF93D57',
					errorMessage: DefaultErrorMessage.Saving,
				}),
				tap(() => this.httpCallCache_getQueues.flushAll()),
			);
	};

	/**********************/
	/*** Queue Entities ***/
	/**********************/

	public getQueueEntities = (
		queueId: number,
		useCache: boolean = true,
	): Observable<WorkQueueEntityModel[]> => {
		if (!useCache) this.httpCallCache_getQueues.flushAll();

		return this.httpCallCache_getQueueEntities.fetch$(
			queueId,
			this.httpClient
				.get<WorkQueueEntityModel[]>(`/api/v1/tracker/queue/${queueId}`)
				.pipe(
					mapResponse((r) => plainToInstance(WorkQueueEntityModel, r), {
						errorCode: '353D18CA',
					}),
				),
		);
	};

	public addEntitiesToQueue = (
		queueId: number,
		args: WorkQueueEntityArgument[],
	): Observable<WorkQueueEntityModel[]> => {
		return this.httpClient
			.post<
				WorkQueueEntityModel[]
			>(`/api/v1/tracker/queue/${queueId}/add`, args)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueEntityModel, r), {
					errorCode: 'F9E8854F',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public removeEntitiesFromQueue = (
		queueId: number,
		args: WorkQueueEntityArgument[],
	): Observable<WorkQueueEntityModel[]> => {
		return this.httpClient
			.post<
				WorkQueueEntityModel[]
			>(`/api/v1/tracker/queue/${queueId}/remove`, args)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueEntityModel, r), {
					errorCode: 'e6c57c67',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public clearEntitiesFromQueue = (
		queueId: number,
		completedOnly: boolean = false,
	): Observable<WorkQueueEntityModel[]> => {
		let body = {};
		if (completedOnly) {
			body = { completed: completedOnly };
		}
		return this.httpClient
			.post<
				WorkQueueEntityModel[]
			>(`/api/v1/tracker/queue/${queueId}/clear`, body)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueEntityModel, r), {
					errorCode: 'FA926798',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public toggleQueueEntityComplete = (
		queueId: number,
		uid: number,
		completed: boolean,
	): Observable<WorkQueueEntityModel[]> => {
		return this.httpClient
			.post<
				WorkQueueEntityModel[]
			>(`/api/v1/tracker/queue/${queueId}/user/${uid}`, { completed: completed })
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueEntityModel, r), {
					errorCode: '31159EDB',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public deleteQueueEntity = (
		queueId: number,
		uid: number,
	): Observable<WorkQueueEntityModel[]> => {
		return this.httpClient
			.delete<
				WorkQueueEntityModel[]
			>(`/api/v1/tracker/queue/${queueId}/user/${uid}`)
			.pipe(
				mapResponse((r) => plainToInstance(WorkQueueEntityModel, r), {
					errorCode: 'FDAB2E6C',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};
}
