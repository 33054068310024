import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { ActivityBoardAdminItemModel } from '../activity-board-admin.model';
import { ActivityBoardAvailableItemModel } from '../activity-board-available.model';
import { ActivityBoardChangeArgument } from '../activity-board-change.model';
import { ActivityBoardTeacherResponseModel } from './activity-board-teacher.model';

@Injectable({
	providedIn: 'root',
})
export class ActivityBoardTeacherService {
	constructor(private httpClient: HttpClient) {}

	public getActivityBoard = (
		currentUserUid: number,
	): Observable<ActivityBoardTeacherResponseModel> => {
		return this.httpClient
			.get<ActivityBoardTeacherResponseModel>(
				`/api/v1/activity-boards/teacher/${currentUserUid}`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ActivityBoardTeacherResponseModel, r),
					{
						errorCode: '12D9DA0B',
						validateSuccess: false,
						checkPreMap: false,
					},
				),
			);
	};

	public getAvailableTeachers = (
		uid: number,
	): Observable<ActivityBoardAvailableItemModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/teacher/${uid}/manage/available`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAvailableItemModel,
							r.teachers as any[],
						),
					{ errorCode: '54577B3C' },
				),
			);
	};

	public changeTeachers = (
		uid: number,
		args: ActivityBoardChangeArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/activity-boards/teacher/${uid}/manage`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'F6C69275',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getTeacherAdminList = (): Observable<
		ActivityBoardAdminItemModel[]
	> => {
		return this.httpClient
			.get<any>(`/api/v1/activity-boards/teacher/all`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ActivityBoardAdminItemModel,
							r.activity_boards as any[],
						),
					{ errorCode: '6A3E45B4' },
				),
			);
	};
}
