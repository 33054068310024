import { Type } from 'class-transformer';
import {
	ChannelParticipantPhoneModel,
	ChannelParticipantPhoneModel_ClassTransform_Type,
} from './channel-participant-phone.model';

export enum ChannelParticipantRole {
	LocalAdvocate = 1,
	Parent = 2,
	Student = 3,
	Teacher = 4,
	AcademicCoach = 5,
	StudentContact = 6,
	CareerCounselor = 7,
	TestingCoordinator = 8,
	Principal = 9,
	TechSupport = 10,
	SystemMessage = 11,
	Substitute = 12,
	Specialist = 13,
	GradCoordinator = 14,
	ReEntryCoach = 15,
	ReEntryCoordinator = 16,
	Tutor = 17,
}

export const externalChannelParticipantRoles = [
	ChannelParticipantRole.Parent,
	ChannelParticipantRole.Student,
	ChannelParticipantRole.StudentContact,
];

export class ChannelParticipantModel {
	public link_id: number;
	public uid: number;
	public name: string;

	@Type(ChannelParticipantPhoneModel_ClassTransform_Type)
	public phones: ChannelParticipantPhoneModel[];
	public emails: string[];
	public primary_user: boolean;
	public link: string;
	public role_id: ChannelParticipantRole;
	public role_name: string;
	public offline_message: string;
	public relationship: string;

	public display_image: string | null;
}

export function ChannelParticipantModel_ClassTransform_Type() {
	return ChannelParticipantModel;
}
