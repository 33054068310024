<section class="billboard">
	<div class="billboard-header">
		<h2 class="billboard-title">Login Credentials</h2>
	</div>
	<div class="billboard-body">
		<!-- SSO Email -->
		@if (ssoEmails?.length > 0) {
			<div class="mb-3">
				<div class="fw-bold">Single Sign-On Email</div>
				@for (ssoEmail of ssoEmails; track ssoEmail) {
					<div class="mb-1">
						<div class="text-truncate" [title]="ssoEmail">
							{{ ssoEmail }}
						</div>
					</div>
				}
			</div>
		}

		@if (!ssoErrorLoading) {
			<div *aeSpinWhile="SSOLoading; spinClass: 'fa-2x'">
				@if (canSSO) {
					<div class="mb-3">
						<div class="fw-bold">Single Sign-On</div>
						@if (SSOEnabled) {
							<div>
								<div class="d-flex flex-wrap align-items-center">
									<img alt="Google" [src]="loadingImageUrl" />
									<span class="p-2"> Connected </span>
									<button
										class="btn btn-danger"
										title="This will disconnect your Google account and you will no longer be able to log in with Google"
										type="button"
										(click)="removeSSO()"
									>
										Disconnect SSO
									</button>
								</div>
							</div>
						}
						@if (!SSOEnabled) {
							<div>
								<div class="d-flex flex-wrap align-items-center">
									<img alt="Google" [src]="loadingImageUrl" />
									<span class="p-2"> Not connected </span>
									@if (isOwnProfile) {
										<a
											class="btn btn-primary"
											title="This will redirect you to Google to add SSO"
											[href]="SSOLink"
										>
											Link Google SSO
										</a>
									}
								</div>
							</div>
						}
					</div>
				}
			</div>
		} @else {
			<div class="text-center text-danger my-3">
				There was an issue loading SSO Info, if the problem persists please
				contact support
			</div>
			<div class="d-flex justify-content-center mb-2">
				<button
					class="btn btn-default px-3"
					id="lunchbox.widgets.sso.form.error.retry"
					type="button"
					(click)="tryRefreshSSO()"
				>
					Retry
				</button>
			</div>
		}

		@if (!errorLoading) {
			<form
				*aeSpinWhile="loading; spinClass: 'fa-2x'"
				id="profile.security.email.form"
				[formGroup]="emailForm"
				(submit)="updateEmail()"
			>
				<!-- Account Email -->
				@if (isEditingEmail) {
					<div aeGroupValidation class="form-group">
						<label for="profile.security.email.newEmail">Account Email </label>
						<input
							aeAutofocus
							aeInputTrim
							autocomplete="username"
							class="form-control email-word-break"
							id="profile.security.email.newEmail"
							type="email"
							[formControl]="emailForm.controls.email"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required" let-error>
								Email is required
							</ng-template>
							<ng-template aeValidationError="email" let-error>
								{{ error }}
							</ng-template>
						</ae-group-validation-display>
					</div>
					@if (isOwnProfile) {
						<div aeGroupValidation class="form-group">
							<label for="profile.security.email.oldPassword">
								Current Account Password
							</label>
							<input
								autocomplete="current-password"
								class="form-control"
								id="profile.security.email.oldPassword"
								type="password"
								[formControl]="emailForm.controls.oldPassword"
							/>
							<ae-group-validation-display [maxDisplay]="1">
								<ng-template aeValidationError="passwordNotValid" let-error>
									Incorrect Password
								</ng-template>
								<ng-template aeValidationError="required" let-error>
									Current password is required
								</ng-template>
							</ae-group-validation-display>
						</div>
					}
				}
				@if (!isEditingEmail) {
					<div class="form-group">
						<div class="fw-bold">Account Email</div>
						<div
							class="email-text-wrap"
							id="lunchbox.widgets.password.form.ChangeAccountEmail.view"
						>
							{{ this.accountEmail }}
						</div>
					</div>
				}
				@if (isOwnProfile) {
					<div class="btn-toolbar">
						<button
							class="btn btn-default"
							id="profile.security.email.editEmail"
							type="button"
							(click)="toggleEmailEdit()"
						>
							@if (!isEditingEmail) {
								<span>Change Email</span>
							}
							@if (isEditingEmail) {
								<span>Cancel</span>
							}
						</button>
						@if (isEditingEmail) {
							<button
								class="btn btn-primary"
								id="profile.security.email.updateEmail"
								type="submit"
								[disabled]="!emailForm.valid || emailForm.pristine"
							>
								<span>Update Email</span>
							</button>
						}
					</div>
				}
			</form>
			<!-- Password Form -->
			<form
				*aeSpinWhile="loading; spinClass: 'fa-2x'"
				class="mt-3"
				id="profile.security.password.form"
				[formGroup]="passwordForm"
				(submit)="updatePassword()"
			>
				<!-- Current Password -->
				@if (isEditingPassword) {
					<div aeGroupValidation class="form-group">
						<label for="profile.security.password.oldPassword">
							Current Account Password
						</label>
						<input
							aeAutofocus
							autocomplete="current-password"
							class="form-control"
							id="profile.security.password.oldPassword"
							type="password"
							[formControl]="passwordForm.controls.oldPassword"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="passwordNotValid" let-error>
								Incorrect Password
							</ng-template>
							<ng-template aeValidationError="required" let-error>
								Current password is required
							</ng-template>
						</ae-group-validation-display>
					</div>
				}
				<!-- New Password -->
				@if (isEditingPassword) {
					<div aeGroupValidation class="form-group">
						<label for="profile.security.password.newPassword">
							New Account Password
						</label>
						<ae-password-randomizer
							id="profile.security.password.newPassword"
							[formControl]="passwordForm.controls.newPassword"
							[hidePassword]="true"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="forbiddenValue">
								New Password cannot be that value
							</ng-template>
							<ng-template aeValidationError="required" let-error>
								New password is required
							</ng-template>
							<ng-template aeValidationError="password" let-error>
								{{ error.requirements }}
							</ng-template>
						</ae-group-validation-display>
						@if (passwordForm.hasError('sameAsCurrent')) {
							<div class="text-danger">
								{{ passwordForm.getError('sameAsCurrent') }}
							</div>
						}
					</div>
				}
				<!-- Confirm Password -->
				@if (isEditingPassword) {
					<div aeGroupValidation class="form-group">
						<label for="profile.security.password.confirmPassword">
							Confirm New Password
						</label>
						<input
							autocomplete="new-password"
							class="form-control"
							id="profile.security.password.confirmPassword"
							type="password"
							[formControl]="passwordForm.controls.confirmPassword"
						/>
						<ae-group-validation-display [maxDisplay]="1">
							<ng-template aeValidationError="required" let-error>
								Confirm password is required
							</ng-template>
						</ae-group-validation-display>
						@if (passwordForm.hasError('passwordMismatch')) {
							<div class="text-danger">
								{{ passwordForm.getError('passwordMismatch') }}
							</div>
						}
					</div>
				}
				@if (!isEditingPassword) {
					<div class="form-group">
						<div class="fw-bold">Account Password</div>
						<div class="text-muted">*********</div>
					</div>
				}
				@if (isOwnProfile) {
					<div class="btn-toolbar">
						<button
							class="btn btn-default"
							id="profile.security.password.editPassword"
							type="button"
							(click)="togglePasswordEdit()"
						>
							@if (!isEditingPassword) {
								<span>Change Password</span>
							}
							@if (isEditingPassword) {
								<span>Cancel</span>
							}
						</button>
						@if (isEditingPassword) {
							<button
								class="btn btn-primary"
								id="profile.security.password.updatePassword"
								type="submit"
								[disabled]="
									!passwordForm.valid || passwordForm.pristine || processing
								"
							>
								<span>Update Password</span>
							</button>
						}
					</div>
				}
			</form>
			<div class="pt-2">
				@if (status && canDeactivateAccount) {
					<button
						class="btn btn-danger"
						type="button"
						(click)="deactivateAccount()"
					>
						Deactivate Account
					</button>
				}
				@if (!status && canReactivateAccount) {
					<button
						class="btn btn-blue"
						type="button"
						(click)="reactivateAccount()"
					>
						Reactivate Account
					</button>
				}
			</div>
		} @else {
			<div class="text-center text-danger my-3">
				There was an issue loading Account Info, if the problem persists please
				contact support
			</div>
			<div class="d-flex justify-content-center mb-2">
				<button
					class="btn btn-default px-3"
					id="lunchbox.widgets.password.form.error.retry"
					type="button"
					(click)="getStudentInfo()"
				>
					Retry
				</button>
			</div>
		}
	</div>
</section>
