import { MessageType } from '../messages/message-type.enum';

export class ChannelParticipantPhoneModel {
	public phone: number;
	public is_primary: boolean;
	public sms_block_status: Record<MessageType, boolean>;
	public primary_status: Record<MessageType, boolean>;
}

export function ChannelParticipantPhoneModel_ClassTransform_Type() {
	return ChannelParticipantPhoneModel;
}
