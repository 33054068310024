import { Directive, Input, TemplateRef } from '@angular/core';

export declare class PaginationTableStateErrorDirectiveContext {
	$implicit: any;
	defaultTemplate: TemplateRef<any>;
}

@Directive({
	selector: 'ng-template[aePgtStateError]',
	standalone: true,
})
export class PaginationTableStateErrorDirective {
	@Input() disabled = false;

	constructor(
		public template: TemplateRef<PaginationTableStateErrorDirectiveContext>,
	) {}

	static ngTemplateGuard_aePgtSearch: 'binding';
	static ngTemplateContextGuard(
		_dir: PaginationTableStateErrorDirective,
		_ctx: any,
	): _ctx is PaginationTableStateErrorDirectiveContext {
		return true;
	}
}
