import { Expose, Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';
import {
	StudentStatusEnrollmentEventsModel,
	StudentStatusEnrollmentEventsModel_ClassTransform_Type,
} from './student-status-enrollment-events.model';

// Sub Models
export class StudentStatusExitModel {
	code: string;
	code_title: string;
	text: string;
	category_id: number;
	category_title: string;
}

export class StudentStatusEnrollmentModel {
	institute_id: number;
	/*
	ENROLLMENT STATUS KEY:
	0 => INACTIVE
	1 => ACTIVE
	2 => PENDING
	*/
	status: number;

	program_status: string;

	@Expose({ name: 'enrolled_timestamp' })
	@Transform(dateTransformer)
	enrolled_date: Date;

	@Expose({ name: 'activated_timestamp' })
	@Transform(dateTransformer)
	activated_date: Date;

	@Expose({ name: 'withdrawal_timestamp' })
	@Transform(dateTransformer)
	withdrawal_date: Date;

	@Type(StudentStatusExitModel_ClassTransform_Type)
	exit: StudentStatusExitModel;
}

export class StudentStatusPauseTimestampModel {
	@Transform(dateTransformer)
	on_timestamp: Date;
	by: string;
	by_name: string;
}

export class StudentStatusPauseRequestModel {
	id: number;
	status: string;

	@Type(StudentStatusPauseTimestampModel_ClassTransform_Type)
	requested: StudentStatusPauseTimestampModel;

	@Type(StudentStatusPauseTimestampModel_ClassTransform_Type)
	started: StudentStatusPauseTimestampModel;

	@Type(StudentStatusPauseTimestampModel_ClassTransform_Type)
	ended: StudentStatusPauseTimestampModel;

	@Transform(dateTransformer)
	updated_on_timestamp: Date;
	exit_code: number;
	log_id: number;
	is_scheduled: boolean;
}

// Main Model
export class StudentStatusModel {
	@Type(StudentStatusEnrollmentModel_ClassTransform_Type)
	enrollment: StudentStatusEnrollmentModel;

	@Type(StudentStatusEnrollmentEventsModel_ClassTransform_Type)
	enrollment_events: StudentStatusEnrollmentEventsModel;

	@Transform(dateTransformer)
	graduated_date: Date;

	@Type(StudentStatusPauseRequestModel_ClassTransform_Type)
	pause_status: StudentStatusPauseRequestModel;

	@Type(StudentStatusPauseRequestModel_ClassTransform_Type)
	pause_request: StudentStatusPauseRequestModel;
}

// API Model

export function StudentStatusModel_ClassTransform_Type() {
	return StudentStatusModel;
}

export function StudentStatusEnrollmentModel_ClassTransform_Type() {
	return StudentStatusEnrollmentModel;
}
export function StudentStatusExitModel_ClassTransform_Type() {
	return StudentStatusExitModel;
}
export function StudentStatusPauseRequestModel_ClassTransform_Type() {
	return StudentStatusPauseRequestModel;
}
export function StudentStatusPauseTimestampModel_ClassTransform_Type() {
	return StudentStatusPauseTimestampModel;
}
