import { Transform } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class StudentAnnouncementModel {
	id: number;
	subject: string;
	subject_plain: string;
	body: string;
	body_plain: string;
	is_gate: AnnouncementGateType;
	@Transform(dateTransformer)
	start_date: Date;
	@Transform(dateTransformer)
	end_date: Date;
	groups: string[];
	creator_uid: number;
	creator_name: string;
	student_specific: boolean;
	@Transform(dateTransformer)
	last_read: Date;
}

export enum AnnouncementGateType {
	normal = 0,
	gate = 1,
	speedbump = 2,
}
