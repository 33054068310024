import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import {
	DefaultErrorMessage,
	mapResponse,
} from 'src/lib/utilities/api/map-response';
import { PagingArgument } from 'src/lib/utilities/api/patterns/pagination/paging.argument';
import {
	ProgramManagementDataAdministrativeDetailsModel,
	ProgramManagementDataUserAssignmentsModel,
} from '../program-management-data.model';
import {
	ProgramAdminDeactivateArgument,
	ProgramAdministratorDeactivateOptionsModel,
} from './program-administrator-deactivate-options.model';

import {
	AdminNotificationHistorySearchArgument,
	ProgramAdminNotificationListModel,
} from './program-administrator-notification-history.model';
import {
	ProgramAdminStudentAssignmentUpdateArgument,
	ProgramAdminStudentListSearchArgument,
	ProgramAdministratorRelationshipModel,
	ProgramAdministratorStudentAssignmentListModel,
	ProgramAdministratorStudentAssignmentModel,
} from './program-administrator-student-assignment.model';
import { ProgramAdministratorsHistoryModel } from './program-administrators-history.model';
import {
	ProgramAdministratorsRelationshipOptionsModel,
	ProgramAdministratorsUpsertOptionsModel,
} from './program-administrators-relationship-options.model';
import {
	ProgramAdministratorAddArgument,
	ProgramAdministratorFieldsArgument,
	ProgramAdministratorsRelationshipArgument,
} from './program-administrators-relationship.argument';

@Injectable({
	providedIn: 'root',
})
export class ProgramAdministratorsService {
	constructor(private httpClient: HttpClient) {}

	// POSTMAN: Organization Admins List
	public getAdminList = (
		orgId: number,
	): Observable<ProgramManagementDataUserAssignmentsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/admins?show_deactive=true`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramManagementDataUserAssignmentsModel, r),
					{
						errorCode: '0C013C4E',
					},
				),
			);
	};

	// POSTMAN: Organization Admin - Bulk Relationship Update Options
	public getRelationshipUpdateOptions = (
		orgId: number,
	): Observable<ProgramAdministratorsRelationshipOptionsModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/admins/bulk_relationships`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramAdministratorsRelationshipOptionsModel,
							r.relationship_types as any[],
						),
					{
						errorCode: '7F04DCBF',
					},
				),
			);
	};

	// POSTMAN: Organization Admin - Bulk Relationship Update
	public bulkRelationshipUpdate = (
		orgId: number,
		args: ProgramAdministratorsRelationshipArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(
				`/api/v1/organizations/${orgId}/admins/bulk_relationships`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '97D8767B',
					errorMessage: DefaultErrorMessage.Saving,
				}),
			);
	};

	public getRelationshipHistory = (
		orgId: number,
	): Observable<ProgramAdministratorsHistoryModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/organizations/${orgId}/admin/default-relationship-history`,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramAdministratorsHistoryModel, r as any[]),
					{
						errorCode: '2CAB55EB',
					},
				),
			);
	};

	public getAdminInfo = (
		orgId: number,
		adminId: number,
	): Observable<ProgramManagementDataAdministrativeDetailsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/admins/${adminId}`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramManagementDataAdministrativeDetailsModel,
							r.admin_details,
						),
					{
						errorCode: '68391077',
					},
				),
			);
	};

	public addAdmin = (
		orgId: number,
		args: ProgramAdministratorAddArgument,
	): Observable<ProgramManagementDataAdministrativeDetailsModel> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/${orgId}/admins`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramManagementDataAdministrativeDetailsModel,
							r.admin_details,
						),
					{
						errorCode: '40B690DA',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public editAdmin = (
		orgId: number,
		adminId: number,
		args: Partial<ProgramAdministratorFieldsArgument>,
	): Observable<ProgramManagementDataAdministrativeDetailsModel> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/${orgId}/admins/${adminId}`, {
				admin: { fields: args },
			})
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramManagementDataAdministrativeDetailsModel,
							r.admin_details,
						),
					{
						errorCode: 'A8023814',
						errorMessage: DefaultErrorMessage.Saving,
					},
				),
			);
	};

	public deactivateAdmin = (
		adminId: number,
		args: ProgramAdminDeactivateArgument,
	): Observable<any> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/admin/${adminId}/deactivate`, args)
			.pipe(
				mapResponse((r) => r, {
					errorCode: '83CF7668',
				}),
			);
	};

	public getDeactivateAdminOptions = (
		adminId: number,
	): Observable<ProgramAdministratorDeactivateOptionsModel[]> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/admin/${adminId}/deactivate`)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramAdministratorDeactivateOptionsModel,
							r.active_enrollments as any[],
						),
					{
						errorCode: '11E2CEBC',
					},
				),
			);
	};

	public getUpsertAdminOptions = (
		orgId: number,
	): Observable<ProgramAdministratorsUpsertOptionsModel> => {
		return this.httpClient
			.get<any>(`/api/v1/organizations/${orgId}/admins/options`)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramAdministratorsUpsertOptionsModel, r),
					{
						errorCode: 'B3DDAEE1',
					},
				),
			);
	};

	public getNotificationHistory = (
		orgId: number,
		uid: number,
		args: PagingArgument<AdminNotificationHistorySearchArgument>,
	): Observable<ProgramAdminNotificationListModel> => {
		return this.httpClient
			.post<ProgramAdminNotificationListModel>(
				`/api/v1/organizations/${orgId}/admins/${uid}/logs`,
				args,
			)
			.pipe(
				mapResponse(
					(r) => plainToInstance(ProgramAdminNotificationListModel, r),
					{
						errorCode: 'DB1979C4',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public getStudentAssignments = (
		uid: number,
		args: PagingArgument<ProgramAdminStudentListSearchArgument>,
	): Observable<ProgramAdministratorStudentAssignmentListModel> => {
		return this.httpClient
			.post<any>(`/api/v1/organizations/admin/${uid}/student_assignments`, args)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(ProgramAdministratorStudentAssignmentListModel, r),
					{
						errorCode: 'F147AF38',
						checkPreMap: false,
						validateSuccess: false,
					},
				),
			);
	};

	public getStudentAssignmentOptions = (
		uid: number,
	): Observable<ProgramAdministratorRelationshipModel[]> => {
		return this.httpClient
			.get<any>(
				`/api/v1/organizations/admin/${uid}/student_assignments/options`,
			)
			.pipe(
				mapResponse(
					(r) =>
						plainToInstance(
							ProgramAdministratorRelationshipModel,
							r.options.allowed_relationships as any[],
						),
					{
						errorCode: '12ECA272',
					},
				),
			);
	};

	public updateAdminStudentAssignments = (
		uid: number,
		args: ProgramAdminStudentAssignmentUpdateArgument,
	): Observable<unknown> => {
		return this.httpClient
			.post<unknown>(
				`/api/v1/organizations/admin/${uid}/student_assignments/update`,
				args,
			)
			.pipe(
				mapResponse((r) => r, {
					errorCode: 'D01E897A',
				}),
			);
	};

	public getAllStudentAssignments = (
		uid: number,
	): Observable<ProgramAdministratorStudentAssignmentModel[]> => {
		return this.httpClient
			.get<ProgramAdministratorStudentAssignmentListModel>(
				`/api/v1/organizations/admin/${uid}/all_student_assignments`,
			)
			.pipe(
				mapResponse((r) => r.assigned_students, {
					errorCode: 'D07C76BB',
				}),
			);
	};
}
