import { Type } from 'class-transformer';

export class StudentEnrollmentOrientationSelections {
	id: number;
	title: string;
}

export class StudentEnrollmentData {
	@Type(StudentEnrollmentOrientationSelections_ClassTransform_Type)
	orientation_sections: StudentEnrollmentOrientationSelections[];
}

export class StudentEnrollmentChecklistItemModel {
	key: string;
	title: string;
	is_required: boolean;
	is_completed: boolean;

	@Type(StudentEnrollmentData_ClassTransform_Type)
	data: StudentEnrollmentData;
}

// Transformers
export function StudentEnrollmentData_ClassTransform_Type() {
	return StudentEnrollmentData;
}

export function StudentEnrollmentOrientationSelections_ClassTransform_Type() {
	return StudentEnrollmentOrientationSelections;
}

export function StudentEnrollmentChecklistItemModel_ClassTransform_Type() {
	return StudentEnrollmentChecklistItemModel;
}
