import { Transform, Type } from 'class-transformer';
import { dateTransformer } from 'src/lib/utilities/api/class-transformer-types';

export class ProgramKickoffSectionItemModel {
	title: string;
	id: number;
	item_id: string;
	@Transform(dateTransformer)
	checked_date: Date;
	checked_by_uid: number;
	checked_by_first: string;
	checked_by_last: string;
}

export function ProgramKickoffSectionItemModel_ClassTransform_Type() {
	return ProgramKickoffSectionItemModel;
}

export class ProgramKickoffSectionModel {
	title: string;
	@Type(ProgramKickoffSectionItemModel_ClassTransform_Type)
	section_items: ProgramKickoffSectionItemModel[];
}

export function ProgramKickoffSectionModel_ClassTransform_Type() {
	return ProgramKickoffSectionModel;
}

export class ProgramKickoffSectionsModel {
	@Type(ProgramKickoffSectionModel_ClassTransform_Type)
	registrar: ProgramKickoffSectionModel;
	@Type(ProgramKickoffSectionModel_ClassTransform_Type)
	program_manager: ProgramKickoffSectionModel;
	@Type(ProgramKickoffSectionModel_ClassTransform_Type)
	autocheck: ProgramKickoffSectionModel;
	@Type(ProgramKickoffSectionModel_ClassTransform_Type)
	buttons: ProgramKickoffSectionModel;
}

export function ProgramKickoffSectionsModel_ClassTransform_Type() {
	return ProgramKickoffSectionsModel;
}

export class ProgramKickoffMangerModel {
	uid: number;
	first_name: string;
	last_name: string;
}

export function ProgramKickoffMangerModel_ClassTransform_Type() {
	return ProgramKickoffMangerModel;
}

export class ProgramKickoffModel {
	id: number;
	@Transform(dateTransformer)
	creation_date: Date;
	@Transform(dateTransformer)
	last_updated_date: Date;
	institute_id: number;
	institute_itype: string;
	institute_name: string;
	institute_parent: string;
	institute_state: string;
	@Transform(dateTransformer)
	complete_date: Date;
	@Type(ProgramKickoffSectionsModel_ClassTransform_Type)
	items: ProgramKickoffSectionsModel;
	@Type(ProgramKickoffMangerModel_ClassTransform_Type)
	admins: ProgramKickoffMangerModel[];
}

export function ProgramKickoffModel_ClassTransform_Type() {
	return ProgramKickoffModel;
}

export interface ProgramKickoffTrackerArgument {
	include_inactive_institutes: boolean;
	include_student_ready_checklists: boolean;
}
