import { Injectable, NgZone } from '@angular/core';
import { SizeMonitor } from './size-monitor.directive';
import { SizeMonitorService } from './size-monitor.service';

@Injectable({
	providedIn: 'root',
})
export class PageSizeMonitorService extends SizeMonitorService {
	constructor(ngZone: NgZone) {
		super();

		const sizeMonitor = new SizeMonitor(this, document.body, ngZone);
		sizeMonitor.setup();
	}
}
