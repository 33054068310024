<div class="modal-header">
	@if (!loading) {
		<h1 class="modal-title">
			{{ meetupTitles[meetup.gathering_type] }}
		</h1>
	}
	<button
		class="btn btn-outline-white"
		id="sap.meetup.rsvp.modal.component.times.close"
		title="Close"
		type="button"
		(click)="activeModal.dismiss()"
	>
		<i aria-hidden="true" class="far fa-times"></i>
	</button>
</div>

<div class="modal-body">
	<ng-container *aeSpinWhile="loading">
		<div class="border-bottom pb-2 mb-2">
			<div class="fw-bold">
				{{ startSpace | spaceFormat: '{day} {month} {date}' }}
			</div>

			<div>
				{{ meetup.start_date | date: 'hh:mm a' }}
				-
				{{ meetup.end_date | date: 'hh:mm a' }}
			</div>

			<div class="fs-sm">
				{{ meetup.type === 'virtual' ? 'Virtual' : 'In Person' }}
				with
				{{ meetup.host.name }}
			</div>
		</div>
		<div [formGroup]="rsvpFormGroup">
			<div aeGroupValidation class="form-group">
				<label
					class="mb-0 fw-bold"
					for="sap.meetup.rsvp.modal.component.form.arrival_time"
				>
					When will you arrive?*
				</label>

				<input
					class="form-control"
					formControlName="arrival_time"
					id="sap.meetup.rsvp.modal.component.form.arrival_time"
					type="time"
				/>
				<ae-group-validation-display [maxDisplay]="1">
					<ng-template aeValidationError="required">
						Arrival Time is required
					</ng-template>
					<ng-template aeValidationError="invalidArrival">
						Arrival Time must be within the meetup time
					</ng-template>
				</ae-group-validation-display>
			</div>

			@if (rsvpFormGroup.controls.how_long.enabled) {
				<div aeGroupValidation class="form-group">
					<label for="sap.meetup.rsvp.modal.component.form.minutes">
						How long will you attend?*
					</label>
					<select
						class="form-select"
						formControlName="how_long"
						id="sap.meetup.rsvp.modal.component.form.minutes"
					>
						@for (item of availableMinuteOptions; track item) {
							<option [ngValue]="item.value">
								{{ item.label }}
							</option>
						}
					</select>
				</div>
			}

			@if (rsvpFormGroup.hasError('invalidTime')) {
				<div class="text-danger mb-2 fs-sm">
					This will run past the end of the meetup, either reduce how long you
					plan to attend or arrive earlier
				</div>
			}

			@if (rsvpFormGroup.controls.study_plan.enabled) {
				<div aeGroupValidation class="form-group">
					<label for="sap.meetup.rsvp.modal.component.form.study_plan">
						What do you plan to work on?{{
							'*'
								| if
									: meetup.gathering_type === MeetupGatheringTypes.study_session
						}}
					</label>
					<textarea
						class="form-control"
						formControlName="study_plan"
						id="sap.meetup.rsvp.modal.component.form.study_plan"
						rows="3"
					></textarea>
					<ae-group-validation-display [maxDisplay]="1">
						<ng-template aeValidationError="required">
							Work plan is required
						</ng-template>
					</ae-group-validation-display>
				</div>
			}
		</div>
	</ng-container>
</div>
@if (!loading) {
	<div class="modal-footer">
		<button
			class="btn btn-secondary"
			id="student.meetup.rsvp.modal.component.cancel"
			type="button"
			(click)="activeModal.dismiss()"
		>
			Cancel
		</button>
		<button
			id="student.meetup.rsvp.modal.component.save"
			type="button"
			class="btn btn-{{ meetupUtilitiesService.rsvpBSLevel(meetup) }}"
			[disabled]="loading || saving || !rsvpFormGroup.valid"
			(click)="save()"
		>
			<ae-wait-spinner [saving]="saving">
				{{ meetupUtilitiesService.rsvpText(meetup) }}
			</ae-wait-spinner>
		</button>
	</div>
}
